import React, { useEffect, useState } from 'react';
import { template as tmpl } from './ServiceItems.tmpl';
import _ from 'lodash';
import { getSummaryTotalInvoice } from 'utils';
import { BOOKING_TYPES, CREATE_QUOTATION_STEP } from 'config/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { updateCustomServicesForQuote, updateStepForQuote } from 'redux/actions/quoteAction';
import { UPDATE_QUOTE_STEP } from 'redux/Constants';

const getLegIndex = (type = '', leg = '') => {
    let currentLeg = 0;
    switch (type) {
        case BOOKING_TYPES.ONEWAY:
            currentLeg = 0;
            break;
        case BOOKING_TYPES.RETURN:
            currentLeg = leg === 'RETURN' ? 1 : 0;
            break;
        case BOOKING_TYPES.MULTI_LEG:
            currentLeg = parseInt(leg[4]);
            break;
        default:
            break;
    }
    return currentLeg;
};

const CreateQuoteServiceItems = (props) => {
    const dispatch = useDispatch();
    const { quotes = [] } = useSelector(state => state.quoteReducer);

    const { requestCode = '' } = props;
    const [quoteDetail, setQuoteDetail] = useState({});
    const [legItem, setLegItem] = useState({});
    const [legIndex, setLegIndex] = useState(0);
    const [focusedLeg, setFocusedLeg] = useState('');
    const [type, setType] = useState(BOOKING_TYPES.ONEWAY);
    const [legs, setLegs] = useState([]);
    const [created_at, setCreate_at] = useState('');

    useEffect(() => {
        if (!_.isEmpty(quotes)) {
            const result = quotes.find(item => item.request_code === requestCode);
            if (!_.isEmpty(result)) {
                setQuoteDetail(result);
                setLegItem(result.legs[legIndex]);
            }
        }
    }, [quotes]);

    useEffect(() => {
        if (focusedLeg) {
            let index = getLegIndex(quoteDetail.type, focusedLeg);
            setLegIndex(index);
            if (
                Array.isArray(quoteDetail.legs) &&
                quoteDetail.legs.length > 0
            ) {
                setLegItem(quoteDetail.legs[index]);
            }
        }
        const { type = BOOKING_TYPES.ONEWAY, legs = [], created_at } = quoteDetail;
        setType(type);
        setLegs(legs);
        setCreate_at(created_at);
    }, [focusedLeg, quoteDetail]);

    const onAddCatering = (item) => {
        try {
            let newQuote = quoteDetail;
            newQuote.legs[legIndex].services.push(item);
            setQuoteDetail({ ...newQuote });

            // update packages
            const { packages = [] } = quoteDetail;
            if (packages.length === 0) return;

            packages.map((legs = [], index) => {
                let newLegs = legs;
                let newPrice = newLegs[legIndex].price || {};
                newPrice = getSummaryTotalInvoice(
                    newPrice,
                    newQuote.legs[legIndex].services
                );

                newLegs[legIndex].services = newQuote.legs[legIndex].services;
                newLegs[legIndex].price = newPrice;
                return newLegs;
            });
        } catch (e) {}
    };

    const onUpdateCatering = (newCatering = [], item = {}) => {
        try {
            let newQuote = quoteDetail;
            newQuote.legs[legIndex].services = newCatering;
            setQuoteDetail({ ...newQuote });

            // update packages
            const { packages = [] } = quoteDetail;
            if (packages.length === 0) return;

            packages.map((legs = [], index) => {
                let newLegs = legs;
                let leg = newLegs[legIndex];
                let itemIndex = leg.services.findIndex(
                    (v) => v.code === item.code
                );
                if (itemIndex > -1) {
                    let newPrice = newLegs[legIndex].price || {};
                    newPrice = getSummaryTotalInvoice(newPrice, newCatering);

                    newLegs[legIndex].services = newCatering;
                    newLegs[legIndex].price = newPrice;
                }
                return newLegs;
            });
        } catch (e) {}
    };

    const onRemoveCatering = (id) => {
        try {
            let newQuote = quoteDetail;
            let index = newQuote.legs[legIndex].services.findIndex(
                (v) => v.id === id
            );
            newQuote.legs[legIndex].services.splice(index, 1);
            setQuoteDetail({ ...newQuote });
        } catch (e) {}
    };

    const updateCustomServices = async () => {
        if (quoteDetail && quoteDetail.legs) {
            let legs = quoteDetail.legs.map((item = {}, i) => {
                const { id, services = [] } = item;
                return {
                    leg_id: id,
                    services: services
                };
            });

            await dispatch(updateCustomServicesForQuote(quoteDetail.id, { legs: legs }));
        }
    };

    const onGoBack = async () => {
        await updateCustomServices();
        dispatch(updateStepForQuote(quoteDetail?.id, CREATE_QUOTATION_STEP.AIRCRAFT));

        dispatch({ 
            type: UPDATE_QUOTE_STEP,
            data: {
                id: quoteDetail.id,
                step: CREATE_QUOTATION_STEP.AIRCRAFT
            }
        });
    };

    const onGoNext = async () => {
        await updateCustomServices();
        dispatch(updateStepForQuote(quoteDetail?.id, CREATE_QUOTATION_STEP.INVOICE));
        
        dispatch({ 
            type: UPDATE_QUOTE_STEP,
            data: {
                id: quoteDetail.id,
                step: CREATE_QUOTATION_STEP.INVOICE
            }
        });
    };

    const checkValidNext = () => {
        let isValid = true;
        try {
            let legs = quoteDetail.legs || [];
            legs.map((leg = {}, i) => {
                let services = leg.services || [];
                let isExist = services.some(
                    (sv) =>
                        sv.type === 'CUSTOM' &&
                        sv.status === 'ACCEPTED' &&
                        sv.price.value <= 0
                );
                if (isExist) {
                    isValid = false;
                }
            });
        } catch (e) {}

        return isValid;
    };

    const navigate = (route, search, state) => {
        props.history.push({
            pathname: route,
            search: search,
            state: state ? { data: state } : null
        });
    };

    const initProps = {
        created_at: created_at,
        setFocusedLeg: setFocusedLeg,
        legs: legs,
        type: type,
        legItem: legItem,
        navigate: navigate,
        onAddCatering: onAddCatering,
        onUpdateCatering: onUpdateCatering,
        onRemoveCatering: onRemoveCatering,
        onGoBack: onGoBack,
        onGoNext: onGoNext,
        isValidNext: checkValidNext()
    };
    return tmpl && tmpl({ ...initProps });
};

export default CreateQuoteServiceItems;
