import React from 'react';
import styled from 'styled-components';
import { Avatar, Image, Col, Row, Divider } from 'antd';
import { HeaderInfo } from 'components/Cards';
import { InvoiceStatus } from 'components/Commons';
import { aircraftIcon, invoiceIcon } from 'assets/images/icons';
import { numberFormat, renderAddress } from 'utils';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { INVOICE_TYPE } from 'redux/Constants';

const InvoiceInfoCard = ({
    addressee = {},
    userCompany = {},
    invoiceCode = '',
    bookingCode = '',
    createdAt = '',
    finalisedAt = '',
    totals = {},
    status = '',
    operatorInvoice,
    type = '' // partner || customer
}) => {
    const { t, i18n } = useTranslation();
    const {
        firstname = '',
        lastname = '',
        address = {},
        image,
        company_name = ''
    } = addressee;
    const { 
        total_inc_vat = 0,
        total_payout = 0,
        booking_fee_total = 0,
        total_vat = 0,
        broker_payout = 0,
        operator_payout = 0,
        administrative_fee_total = 0
    } = totals;

    const name =
        operatorInvoice === true
            ? company_name
            : `${firstname} ${lastname}`;

    return (
        <DetailWrapper style={{ marginTop: 20 }}>
            <HeaderInfo
                title={i18n.t('INVOICE_DETAILS').toUpperCase()}
                icon={<img style={{ width: '20px', height: '20px' }} src={invoiceIcon} />}
            />

            <InfoCardWrapper>
                <Col
                    xl={24} lg={24} md={24} sm={24} xs={24}
                    style={{ backgroundColor: '#f7f8fb', padding: '20px' }}
                >
                    <Row justify="space-between">
                        <Col
                            // span={10}
                            xl={12} lg={12} md={8} sm={8} xs={24}
                        >
                            <div>
                                <Title>
                                    {type === INVOICE_TYPE.PARTNER
                                        ? t('PARTNER')
                                        : t('NAME')}
                                </Title>
                            </div>
                            <div
                                style={{
                                    marginTop: 3,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                {image ? (
                                    <Avatar
                                        className="ant-avatar-custom"
                                        src={image}
                                    />
                                ) : (
                                    <Avatar className="ant-avatar-custom">
                                        {(type === INVOICE_TYPE.PARTNER
                                            ? name.charAt(0)
                                            : firstname.charAt(0) +
                                              lastname.charAt(0)
                                        ).toUpperCase()}
                                    </Avatar>
                                )}
                                <Value style={{ marginLeft: 10 }}>
                                    {name.toUpperCase()}
                                </Value>
                            </div>
                            { !operatorInvoice && 
                                <>
                                    <div style={{ marginTop: 15}}>
                                        <Title>{t('COMPANY')}</Title>
                                    </div>
                                    <div style={{ marginTop: 3, display: 'flex', alignItems: 'center' }}>
                                        <Value>
                                            {userCompany?.company_name?.toUpperCase() || '_'}
                                        </Value>
                                    </div>
                                </>
                            }
                            <div style={{ marginTop: 15 }}>
                                <Title>{t('BILLING_ADDRESS')}</Title>
                            </div>
                            <div style={{ marginTop: 3 }}>
                                <Value>
                                    {renderAddress(address).toUpperCase()}
                                </Value>
                            </div>
                        </Col>
                        <Col
                            // span={7}
                            xl={4} lg={5} md={6} sm={6} xs={12}
                        >
                            { !operatorInvoice && (
                                <>
                                    <div>
                                        <Title>{t('BOOKING')}</Title>
                                    </div>
                                    <div
                                        style={{
                                            marginTop: 3,
                                            display: 'flex'
                                        }}
                                    >
                                        <Value>{`#${bookingCode || ''}`}</Value>
                                    </div>
                                </>
                            )}

                            <div style={{ marginTop: 10 }}>
                                <Title>{t('INVOICE')}</Title>
                            </div>
                            <div
                                style={{
                                    marginTop: 3,
                                    display: 'flex'
                                }}
                            >
                                <Value>{`#${invoiceCode || ''}`}</Value>
                            </div>

                            <div style={{ marginTop: 10 }}>
                                <Title>{t('STATUS')}</Title>
                            </div>
                            <div
                                style={{
                                    marginTop: 3,
                                    display: 'flex'
                                }}
                            >
                                {status ? (
                                    <InvoiceStatus status={status} />
                                ) : (
                                    '_'
                                )}
                            </div>

                            <div style={{ marginTop: 10 }}>
                                <Title>{t('CREATED_AT')}</Title>
                            </div>
                            <div
                                style={{
                                    marginTop: 3,
                                    display: 'flex'
                                }}
                            >
                                <Value>
                                    {createdAt
                                        ? moment(createdAt)
                                              .format('dddd DD MMM, YYYY')
                                              .toUpperCase()
                                        : '_'}
                                </Value>
                            </div>

                            <div style={{ marginTop: 10 }}>
                                <Title>{t('DUE_DATE')}</Title>
                            </div>
                            <div
                                style={{
                                    marginTop: 3,
                                    display: 'flex'
                                }}
                            >
                                <Value>
                                    {finalisedAt
                                        ? moment(finalisedAt)
                                              .format('dddd DD MMM, YYYY')
                                              .toUpperCase()
                                        : '_'}
                                </Value>
                            </div>
                        </Col>
                        <Col
                            // span={7}
                            xl={8} lg={7} md={10} sm={10} xs={12}
                        >
                            <div>
                                <Title>{!operatorInvoice ? t('CUSTOMER_PAYOUT') : t('PAYOUT')}</Title>
                            </div>
                            <div
                                style={{
                                    marginTop: 3,
                                    display: 'flex'
                                }}
                            >
                                <Value
                                    style={{
                                        fontSize: '14px',
                                        fontFamily: 'AvenirNext-Bold',
                                        letterSpacing: '0.4px',
                                        lineHeight: '17px'
                                    }}
                                >
                                    {numberFormat(total_payout, 'USD', 2)}
                                </Value>
                            </div>

                            <Divider style={{ color: '#65686F', width: 2 }} plain={false} />

                            { !operatorInvoice && 
                                <>
                                    <Row justify="space-between">
                                        <Col span={12}>
                                            <div>
                                                <Title>{t('OPERATOR_RECEIVED')}</Title>
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: 3,
                                                    display: 'flex'
                                                }}
                                            >
                                                <Value
                                                    style={{
                                                        fontSize: '14px',
                                                        fontFamily: 'AvenirNext-Bold',
                                                        letterSpacing: '0.4px',
                                                        lineHeight: '17px'
                                                    }}
                                                >
                                                    {numberFormat(operator_payout, 'USD', 2)}
                                                </Value>
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <div>
                                                <Title>{t('TOTAL_VAT')}</Title>
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: 3,
                                                    display: 'flex'
                                                }}
                                            >
                                                <Value
                                                    style={{
                                                        fontSize: '14px',
                                                        fontFamily: 'AvenirNext-Bold',
                                                        letterSpacing: '0.4px',
                                                        lineHeight: '17px'
                                                    }}
                                                >
                                                    {numberFormat(total_vat, 'USD', 2)}
                                                </Value>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row justify="space-between" style={{ marginTop: 10 }}>
                                        <Col span={12}>
                                            <div>
                                                <Title>{t('BROKER_RECEIVED')}</Title>
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: 3,
                                                    display: 'flex'
                                                }}
                                            >
                                                <Value
                                                    style={{
                                                        fontSize: '14px',
                                                        fontFamily: 'AvenirNext-Bold',
                                                        letterSpacing: '0.4px',
                                                        lineHeight: '17px'
                                                    }}
                                                >
                                                    {numberFormat(broker_payout, 'USD', 2)}
                                                </Value>
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <div>
                                                <Title>{t('ADMINISTRATOR_FEE')}</Title>
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: 3,
                                                    display: 'flex'
                                                }}
                                            >
                                                <Value
                                                    style={{
                                                        fontSize: '14px',
                                                        fontFamily: 'AvenirNext-Bold',
                                                        letterSpacing: '0.4px',
                                                        lineHeight: '17px'
                                                    }}
                                                >
                                                    {numberFormat(administrative_fee_total, 'USD', 2)}
                                                </Value>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: 10 }}>
                                        <Col span={12}>
                                            <div>
                                                <Title>{t('BOOKING_FEE')}</Title>
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: 3,
                                                    display: 'flex'
                                                }}
                                            >
                                                <Value
                                                    style={{
                                                        fontSize: '14px',
                                                        fontFamily: 'AvenirNext-Bold',
                                                        letterSpacing: '0.4px',
                                                        lineHeight: '17px'
                                                    }}
                                                >
                                                    {numberFormat(booking_fee_total, 'USD', 2)}
                                                </Value>
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </Col>
                    </Row>
                </Col>
            </InfoCardWrapper>
        </DetailWrapper>
    );
};

const DetailWrapper = styled.div`
    background-color: #fff;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
`;

const InfoCardWrapper = styled(Row)`
    margin-left: 30px;
    padding: 20px;

    .ant-divider {
        border-top: 2px solid rgba(56, 56, 65, 0.05);
    }
`;

const Title = styled.span`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 11px;
    line-height: 13px;
    text-align: left;
`;

const Value = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    letter-spacing: 0.7px;
    line-height: 18px;
    text-align: left;
`;

export default InvoiceInfoCard;
