import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { LoadingOutlined } from '@ant-design/icons';
import { Row, Col, Image } from 'antd';

import {
    Text,
    ButtonCustom,
    SearchInput,
    DropDownInput,
    Popup
} from 'components';
import { amenitiesList, fallbackImage } from 'config/Constants';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
    removeIcon,
    iconWifi,
    airplaneSeat,
    bathroomSink,
    iconLavatory,
    sleep,
    checkedIcon
} from 'assets/images/icons';
import { AircraftDetailCardComponent } from 'components/Cards';
import { GoBackAndNext } from 'views/QuoteRequest/components';
import {
    createCategoryOption,
    getLowresUrl,
    numberFormat,
    createOperatorsOption
} from 'utils';
import { FLIGHT_TYPES } from 'config/Constants';
import { useSelector } from 'react-redux';

const template = (props) => {
    const { t, i18n } = useTranslation();
    const { expandedSidebar } = useSelector(
        (state) => state.settingReducer || false
    );
    const {
        activeGoNext = false,
        focusedLeg,
        currentPage = 1,
        totalPage = 1,
        loading = false,
        quoteDetail = {},
        navigate,
        aircraftList = [],
        addAircraft,
        onLoadMore,
        removeSelectedAircraft,
        onGoNext,
        onGoBack,
        cancelModal,
        confirmRemoveAircraft,
        visibleModal = false,
        onSearch,
        onChangeCategory,
        searchCategory,
        onChangeOperator,
        searchOperator,
        onClearOperator,
        onClearCategory,
        companies
    } = props;

    const { type, legs = [] } = quoteDetail;

    const getTotalAircraft = () => {
        let totalAircrafts = 0;
        legs.map((leg, index) => {
            totalAircrafts += leg.select_aircrafts.length;
        });
        return totalAircrafts;
    };

    const AircraftCardSelected = ({ style, aircraft = {}, key, legIndex }) => {
        const { images, name, operator_name, model, id, tail_number } =
            aircraft;
        return (
            <AircraftCardContainer style={style}>
                <ImageContainer>
                    <Image
                        style={{ width: 170, height: 106, borderRadius: 4 }}
                        src={
                            (images && images[0]?.lowres?.url) || fallbackImage
                        }
                    />
                    <RemoveButton
                        onClick={() => removeSelectedAircraft(id, legIndex)}
                    >
                        <RemoveIcon src={removeIcon} />
                    </RemoveButton>
                </ImageContainer>
                <NameSection>
                    <Text textStyle="heading6">{name || model || '-'}</Text>
                </NameSection>
                <NameSection>
                    <TailorCode textStyle="heading6">
                        {tail_number || '-'}
                    </TailorCode>
                </NameSection>
                <CompanySection>
                    <Text
                        textStyle="fieldLabel"
                        style={{
                            color: '#19C0FF',
                            letterSpacing: 0.3,
                            fontSize: 12
                        }}
                    >
                        {operator_name || '-'}
                    </Text>
                </CompanySection>
            </AircraftCardContainer>
        );
    };

    const renderAmenities = (item, index) => {
        switch (item) {
            case amenitiesList.EXTRA_LEG_ROOM:
                return (
                    <>
                        <FeatureIcon src={airplaneSeat}></FeatureIcon>
                        <FeatureTitle>
                            <Text textStyle="tableText1">
                                {t('EXTRA_LEG_ROOM')}
                            </Text>
                        </FeatureTitle>
                    </>
                );
            case amenitiesList.WIFI:
                return (
                    <>
                        <FeatureIcon src={iconWifi}></FeatureIcon>
                        <FeatureTitle>
                            <Text textStyle="tableText1">{t('WIFI')}</Text>
                        </FeatureTitle>
                    </>
                );
            case amenitiesList.BED:
                return (
                    <>
                        <FeatureIcon src={sleep}></FeatureIcon>
                        <FeatureTitle>
                            <Text textStyle="tableText1">{t('SLEEPS')}</Text>
                        </FeatureTitle>
                    </>
                );
            case amenitiesList.SINK:
                return (
                    <>
                        <FeatureIcon src={bathroomSink}></FeatureIcon>
                        <FeatureTitle>
                            <Text textStyle="tableText1">
                                {t('SINK_AND_LIGHT')}
                            </Text>
                        </FeatureTitle>
                    </>
                );
            case amenitiesList.LAVATORY:
                return (
                    <>
                        <FeatureIcon src={iconLavatory}></FeatureIcon>
                        <FeatureTitle>
                            <Text textStyle="tableText1">{t('LAVATORY')}</Text>
                        </FeatureTitle>
                    </>
                );
            default:
                return;
        }
    };

    const AircraftCard = ({
        aircraft,
        // key,
        addAircraft,
        added,
        expandedSidebar
    }) => {
        const { amenities = [], price_components = {} } = aircraft;
        return (
            <EachAircraft expandedSidebar={expandedSidebar}>
                <LeftSection>
                    <AircraftDetailCardComponent aircraft={aircraft} />
                </LeftSection>
                <CreateQuoteSection
                    className="create-quote-section"
                    expandedSidebar={expandedSidebar}
                >
                    <FeaturedAmenitiesAndPrice>
                        <CreateQuoteTop>
                            <CreateQuoteTopLeft>
                                <Text textStyle="nameNormal">
                                    {t('PRICE').toUpperCase()}
                                </Text>
                                <Text
                                    textStyle="heading3"
                                    style={{
                                        fontFamily: 'AvenirNext-Bold',
                                        marginLeft: 10
                                    }}
                                >
                                    {numberFormat(
                                        price_components.revenue_fee,
                                        'USD',
                                        1
                                    )}
                                </Text>
                            </CreateQuoteTopLeft>
                        </CreateQuoteTop>
                        <FeaturedAmenities>
                            <FeaturedAmenitiesTitle>
                                <Text textStyle="heading6">
                                    {t('FEATURED_AMENITIES')}
                                </Text>
                            </FeaturedAmenitiesTitle>
                            <FeaturedAmenitiesList
                                expandedSidebar={expandedSidebar}
                            >
                                {amenities.map((item, index) => {
                                    return (
                                        <EachAmenities key={index}>
                                            {renderAmenities(item, index)}
                                        </EachAmenities>
                                    );
                                })}
                            </FeaturedAmenitiesList>
                        </FeaturedAmenities>
                    </FeaturedAmenitiesAndPrice>
                    <AircraftDetail
                        style={{ paddingLeft: '10px', paddingRight: '10px' }}
                    >
                        {added ? (
                            <ButtonCustom
                                btnType="secondary"
                                icon={checkedIcon}
                                title={t('ADDED').toUpperCase()}
                                width="260px"
                                onClick={() => {
                                    switch (type) {
                                        case FLIGHT_TYPES.ONEWAY:
                                            removeSelectedAircraft(
                                                aircraft._id,
                                                0
                                            );
                                            break;
                                        case FLIGHT_TYPES.RETURN:
                                            removeSelectedAircraft(
                                                aircraft._id,
                                                focusedLeg === 'RETURN' ? 1 : 0
                                            );
                                            break;
                                        case FLIGHT_TYPES.MULTI_LEG:
                                            removeSelectedAircraft(
                                                aircraft._id,
                                                parseInt(focusedLeg[4])
                                            );
                                            break;
                                    }
                                }}
                            />
                        ) : (
                            <ButtonCustom
                                btnType="primary"
                                title={t('ADD_TO_LIST').toUpperCase()}
                                width="260px"
                                onClick={() => {
                                    addAircraft(aircraft);
                                    // navigate(AIRCRAFT_DETAIL, id, id);
                                }}
                            />
                        )}
                    </AircraftDetail>
                </CreateQuoteSection>
            </EachAircraft>
        );
    };

    return (
        <SelectAircraftContainer>
            <SelectAircraftSection>
                <Text textStyle="heading3">
                    {t('SELECT_AIRCRAFT').toUpperCase()}
                </Text>
                {getTotalAircraft() > 0 && (
                    <SelectedAircrafts>
                        <Row>
                            <Text
                                style={{
                                    color: '#8DD64F',
                                    fontSize: '16px',
                                    paddingLeft: 20,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    borderLeft: '2px solid #8DD64F',
                                    fontFamily: 'AvenirNext-Demibold'
                                }}
                            >
                                {getTotalAircraft() +
                                    ' ' +
                                    t('AIRCRAFTS_ADDED')}
                            </Text>
                        </Row>
                        {type === FLIGHT_TYPES.ONEWAY && legs[0] && (
                            <Row style={{ paddingLeft: 20 }}>
                                <Col>
                                    <Row style={{ marginTop: 10 }}>
                                        {legs[0].select_aircrafts &&
                                            Array.isArray(
                                                legs[0].select_aircrafts
                                            ) &&
                                            legs[0].select_aircrafts.length >
                                                0 &&
                                            legs[0].select_aircrafts.map(
                                                (aircraft, index) => {
                                                    return (
                                                        <AircraftCardSelected
                                                            aircraft={
                                                                aircraft.aircraft
                                                            }
                                                            key={index}
                                                            legIndex={0}
                                                            style={{
                                                                marginRight: 30
                                                            }}
                                                        />
                                                    );
                                                }
                                            )}
                                    </Row>
                                </Col>
                            </Row>
                        )}
                        {type === FLIGHT_TYPES.RETURN &&
                            legs.length > 0 &&
                            legs.map((leg, index) => {
                                const legIndex = index;
                                return (
                                    <Row
                                        style={{
                                            paddingLeft: 20,
                                            paddingBottom: 20
                                        }}
                                        key={index}
                                    >
                                        <Col>
                                            <Row>
                                                <Text
                                                    textStyle="heading5"
                                                    style={{ marginTop: 10 }}
                                                >
                                                    {index === 1
                                                        ? t(
                                                              'RETURN'
                                                          ).toUpperCase()
                                                        : t(
                                                              'DEPARTURE'
                                                          ).toUpperCase()}
                                                </Text>
                                            </Row>
                                            <Row style={{ marginTop: 10 }}>
                                                {leg.select_aircrafts &&
                                                    Array.isArray(
                                                        leg.select_aircrafts
                                                    ) &&
                                                    leg.select_aircrafts
                                                        .length > 0 &&
                                                    leg.select_aircrafts.map(
                                                        (aircraft, index) => {
                                                            return (
                                                                <AircraftCardSelected
                                                                    aircraft={
                                                                        aircraft.aircraft
                                                                    }
                                                                    key={index}
                                                                    legIndex={
                                                                        legIndex
                                                                    }
                                                                    style={{
                                                                        marginRight: 30
                                                                    }}
                                                                />
                                                            );
                                                        }
                                                    )}
                                            </Row>
                                        </Col>
                                    </Row>
                                );
                            })}
                        {type === FLIGHT_TYPES.MULTI_LEG &&
                            legs.length > 0 &&
                            legs.map((leg, index) => {
                                const legIndex = index;
                                return (
                                    <Row
                                        style={{
                                            paddingLeft: 20,
                                            paddingBottom: 20
                                        }}
                                        key={index}
                                    >
                                        <Col>
                                            <Row>
                                                <Text
                                                    textStyle="heading5"
                                                    style={{ marginTop: 10 }}
                                                >
                                                    {'LEG ' + (index + 1)}
                                                </Text>
                                            </Row>
                                            <Row style={{ marginTop: 10 }}>
                                                {leg.select_aircrafts &&
                                                    Array.isArray(
                                                        leg.select_aircrafts
                                                    ) &&
                                                    leg.select_aircrafts
                                                        .length > 0 &&
                                                    leg.select_aircrafts.map(
                                                        (aircraft, index) => {
                                                            return (
                                                                <AircraftCardSelected
                                                                    aircraft={
                                                                        aircraft.aircraft
                                                                    }
                                                                    key={index}
                                                                    legIndex={
                                                                        legIndex
                                                                    }
                                                                    style={{
                                                                        marginRight: 30
                                                                    }}
                                                                />
                                                            );
                                                        }
                                                    )}
                                            </Row>
                                        </Col>
                                    </Row>
                                );
                            })}
                    </SelectedAircrafts>
                )}
            </SelectAircraftSection>
            <SearchSection>
                <SortByCategory>
                    <DropDownInput
                        placeholder={t('CATEGORY')}
                        data={createCategoryOption()}
                        onChange={(value) => {
                            onChangeCategory(value);
                        }}
                        pickedData={searchCategory}
                        onClear={onClearCategory}
                    />
                </SortByCategory>
                <SortByOperator>
                    <DropDownInput
                        placeholder={t('ALL_OPERATOR')}
                        data={createOperatorsOption(companies)}
                        onChange={(value) => {
                            onChangeOperator(value);
                        }}
                        pickedData={searchOperator}
                        onClear={onClearOperator}
                    />
                </SortByOperator>
                <SearchInputSection>
                    <SearchInput
                        placeholder={t('SEARCH_AIRCRAFT_NAME')}
                        onSearch={(e) => onSearch(e)}
                    />
                </SearchInputSection>
            </SearchSection>
            <AircraftList>
                {Array.isArray(aircraftList) &&
                    aircraftList.length > 0 &&
                    aircraftList.map((aircraft = {}, index) => {
                        let currentLeg = 0;
                        switch (type) {
                            case FLIGHT_TYPES.ONEWAY:
                                currentLeg = 0;
                                break;
                            case FLIGHT_TYPES.RETURN:
                                currentLeg = focusedLeg === 'RETURN' ? 1 : 0;
                                break;
                            case FLIGHT_TYPES.MULTI_LEG:
                                currentLeg = parseInt(focusedLeg[4]);
                                break;
                            default:
                                break;
                        }
                        const found = legs[currentLeg].select_aircrafts.some(
                            (el = {}) => {
                                return el?.aircraft?.id === aircraft?._id;
                            }
                        );
                        return (
                            <div key={index}>
                                <AircraftCard
                                    aircraft={aircraft}
                                    // key={index}
                                    addAircraft={addAircraft}
                                    added={found}
                                    expandedSidebar={expandedSidebar}
                                />
                            </div>
                        );
                    })}
            </AircraftList>
            <LoadMoreSection>
                {!loading ? (
                    <>
                        {currentPage < totalPage ? (
                            <ButtonCustom
                                btnType="loadMore"
                                title={t('LOAD_MORE').toUpperCase()}
                                width="270px"
                                onClick={() => onLoadMore()}
                            />
                        ) : null}
                    </>
                ) : (
                    <LoadingOutlined
                        style={{ fontSize: '24px', color: '#ffffff' }}
                    />
                )}
            </LoadMoreSection>
            <GoBackAndNext
                onGoNext={onGoNext}
                onGoBack={onGoBack}
                activeGoNext={activeGoNext}
                activeGoBack={true}
            />
            <Popup
                cancelModal={() => {
                    cancelModal();
                }}
                onOk={() => {
                    confirmRemoveAircraft();
                }}
                visibleModal={visibleModal}
                successText={t('WARNING')}
                successSecondLine={t('WARNING_REMOVE_AIRCRAFT')}
                buttonTitle={t('REMOVE_AIRCRAFT').toUpperCase()}
            />
        </SelectAircraftContainer>
    );
};

export { template };

const TailorCode = styled.div`
    border: 1px solid #dadadb;
    border-radius: 2px;
    width: 58px;
    height: 18px;
    color: #19c0ff;
    font-family: AvenirNext-Bold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    width: 56px;
    text-align: center;
    padding: 3px;
`;

const LoadMoreSection = styled.div`
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
`;

const FeatureTitle = styled.div`
    margin-top: 5px;
`;

const FeatureIcon = styled.img`
    margin-top: 10px;
    margin-right: 10px;
`;

const FeaturedAmenitiesAndPrice = styled.div`
    /* height: 80%; */
`;

const FeaturedAmenities = styled.div`
    padding: 20px;
`;

const CreateQuoteTopLeft = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
`;

const FeaturedAmenitiesTitle = styled.div`
    margin-bottom: 5px;
`;

const EachAmenities = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 1024px) {
        margin-right: 20px;
    }
`;

const AircraftDetail = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CreateQuoteTop = styled.div`
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(14, 14, 18, 0.05);
    padding-bottom: 20px;
`;

const LeftSection = styled.div`
    display: flex;
    flex-direction: row;
    /* padding-right: 30px; */
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */
    /* border: 1px solid #000; */
    max-width: 1520px;
    /* width: 80%; */
`;

const FeaturedAmenitiesList = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    @media (max-width: 1440px) {
        flex-direction: ${(props) =>
            props.expandedSidebar ? 'row' : 'column'};
    }
    @media (max-width: 1024px) {
        flex-direction: row;
    }
`;

const CreateQuoteSection = styled.div`
    width: 20%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid rgba(14, 14, 18, 0.1);
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: space-between;
    width: 15.625vw;
    max-width: 300px;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    border-left: 1px solid rgba(14, 14, 18, 0.1);
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: space-between;
    @media (max-width: 1024px) {
        border-left: none;
        width: 100%;
        max-width: 100%;
        padding-right: 20px;
        padding-top: 0;
    }
    @media (max-width: 768px) {
        border-left: none;
    }
`;

const EachAircraft = styled.div`
    background-color: #fff;
    margin-bottom: 20px;
    padding-left: 20px;
    /* padding-bottom: 20px; */
    /* padding-top: 10px; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 1440px) {
        ${(props) => props.expandedSidebar && 'flex-wrap: wrap'};
    }
    @media (max-width: 1024px) {
        flex-wrap: wrap;
    }
`;

const AircraftList = styled.div``;

const SearchInputSection = styled(Col)`
    padding-left: 20px;
`;

const SortByCategory = styled(Col)`
    /* .ant-select-selector {
        background-color: transparent !important;
        width: 248px !important;
    } */
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: transparent;
        height: 40px;
    }
`;

const SortByOperator = styled(Col)`
    padding-left: 20px;
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: transparent;
        height: 40px;
    }
`;

const SearchSection = styled(Row)`
    padding-top: 30px;
    padding-bottom: 30px;
`;

const CompanySection = styled.div``;

const NameSection = styled.div``;

const RemoveIcon = styled.img`
    position: absolute;
    top: 0;
    left: 0;
`;

const RemoveButton = styled.button`
    position: absolute;
    border: none;
    background-color: #f0f3f9;
    border-radius: 100%;
    width: 24px;
    height: 24px;
    text-align: center;
    top: -10px;
    left: 158px;
`;

const ImageContainer = styled.div`
    position: relative;
    padding-bottom: 10px;
`;

const AircraftCardContainer = styled.div``;

const SelectedAircrafts = styled.div`
    background-color: #ffffff;
    border-radius: 4px;
    margin-top: 30px;
    padding-bottom: 15px;
`;

const SelectAircraftSection = styled.div`
    margin-top: 30px;
`;

const SelectAircraftContainer = styled.div`
    padding-top: 20px;
    .ant-steps-item-tail {
        height: 2px !important;
    }
    .ant-steps-item-tail::after {
        height: 2px !important;
        /* background-color: #19c0ff !important; */
    }
    .ant-steps-item-finish
        > .ant-steps-item-container
        > .ant-steps-item-tail::after {
        background-color: #19c0ff;
    }
`;
