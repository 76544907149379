import React from 'react';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

/**
 *
 * @param {string} props.title - the text is shown on the button
 * @param {function} onClick - handle function for Click event
 * @param {string} height - height of the button
 * @param {string} width - width of the button
 * @param {string} borderColor - border color of the button
 * @param {string} backgroundColor - Background Color of the button
 * @param {string} textColor - Text Color of the button
 * @returns {JSX}
 */
const Button = (props) => {
    const { title = '' } = props;
    const handleOnClick = () => {
        if (props.onClick && typeof props.onClick === 'function') {
            props.onClick();
        }
    };

    return (
        <ButtonContainer
            onClick={handleOnClick}
            width={props.width}
            height={props.height}
            borderColor={props.borderColor}
            backgroundColor={props.backgroundColor}
        >
            <ButtonBackground backgroundColor={props.backgroundColor}>
                {props.loading ? (
                    <LoadingOutlined
                        style={{ fontSize: '24px', color: '#ffffff' }}
                    />
                ) : (
                    <ButtonText
                        textColor={props.textColor}
                        fontSize={props.fontSize}
                    >
                        {title}
                    </ButtonText>
                )}
            </ButtonBackground>
        </ButtonContainer>
    );
};

const ButtonContainer = styled.button`
    width: ${(props) => (props.width ? props.width : '190px')};
    border: none;
    height: ${(props) => (props.height ? props.height : '40px')};
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : '#ffffff'};
    background-repeat: no-repeat;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    border: ${(props) =>
        props.borderColor ? props.borderColor : ' 1px solid #19c0ff'};
    border-radius: 2px;
`;

const ButtonText = styled.span`
    color: ${(props) => (props.textColor ? props.textColor : '#16161b')};
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    font-size: ${(props) => (props.fontSize ? props.fontSize : '11px')};

    letter-spacing: 0.7px;
    line-height: 13px;
    text-align: center;
`;

const ButtonBackground = styled.div`
    /* background-color: #ffffff; */
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : '#ffffff'};
`;
export default Button;
