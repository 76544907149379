import React, { useEffect, useState } from 'react';
import { ButtonCustom } from 'components';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const GoBackAndNext = (props) => {
    const {
        onGoNext,
        onGoBack,
        activeGoNext = false,
        activeGoBack = false,
        titleGoNext,
        titleGoBack
    } = props;
    const { t, i18n } = useTranslation();

    return (
        <GoNextButton>
            <div style={{ width: 300 }}>
                <ButtonCustom
                    btnType="goBack"
                    title={titleGoBack || t('GO_BACK').toUpperCase()}
                    size="medium"
                    disabled={!activeGoBack}
                    onClick={() => {
                        onGoBack && onGoBack();
                    }}
                />
            </div>
            <div
                style={{
                    width: 300,
                    justifyContent: 'center',
                    display: 'flex'
                }}
            >
                <ButtonCustom
                    btnType="primary"
                    title={titleGoNext || t('GO_NEXT').toUpperCase()}
                    width="270px"
                    size="large"
                    disabled={!activeGoNext}
                    onClick={() => {
                        onGoNext && onGoNext();
                    }}
                />
            </div>
            <div style={{ width: 300 }}></div>
        </GoNextButton>
    );
};

export default GoBackAndNext;

const GoNextButton = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;
