import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { Text } from 'components';
import { IconDeparture, IconLand, jetIcon } from 'assets/images/icons';
import { useTranslation } from 'react-i18next';
import FBOInfoCard from './FBOInfoCard';
import { getFlightTime, getPaymentStatus } from 'utils';
import moment from 'moment';
import { PaymentStatus, BookingStatus } from 'components/Commons';
import { QUOTE_REQUEST_DETAIL } from 'routes/main';

const FlightDetailStatusCard = (props) => {
    const { t, i18n } = useTranslation();
    const iconStyle = {
        width: '32px',
        height: '25px'
    };
    const {
        brokerView = true,
        flightId = '',
        bookingType = '',
        reqeustSendBy = '',
        payment_status = '',
        bookingStatus = '',
        to_airport,
        from_airport,
        onAddFBO,
        arrival = {},
        departure = {},
        aircraft = {},
        operator = {},
        booking_code = '',
        quoteRequestId = '',
        quoteRequestCode = '',
        flightTimeMinutes = 0,
        navigate,
        flightCode
    } = props;

    const onSelectFBO = (fboId, type) => {
        onAddFBO(flightId, fboId, type);
    };

    const times = getFlightTime(flightTimeMinutes);

    return (
        <FlightStatusContainer>
            <FlightInformation>
                <Col>
                    <Row style={{ justifyContent: 'flex-start' }}>
                        {brokerView && (
                            <Col style={{ marginLeft: 10, marginRight: 40 }}>
                                <Text textStyle="tableText1">
                                    {t('BOOKING_TYPE')}
                                </Text>
                                <div>
                                    <Text
                                        textStyle="tableText2"
                                        style={{
                                            color: '#19C0FF',
                                            fontSize: '11px'
                                        }}
                                    >
                                        {bookingType || '-'}
                                    </Text>
                                </div>
                            </Col>
                        )}
                        {brokerView && (
                            <>
                                {quoteRequestCode && (
                                    <Col style={{ marginRight: 40 }}>
                                        <Text textStyle="tableText1">
                                            {t('QUOTE') + ' #'}
                                        </Text>
                                        <div>
                                            <a
                                                onClick={() =>
                                                    navigate(
                                                        QUOTE_REQUEST_DETAIL,
                                                        quoteRequestId
                                                    )
                                                }
                                            >
                                                <Text
                                                    textStyle="tableText2"
                                                    style={{
                                                        // color: '#19C0FF',
                                                        fontSize: '11px'
                                                    }}
                                                >
                                                    {quoteRequestCode || '_'}
                                                </Text>
                                            </a>
                                        </div>
                                    </Col>
                                )}
                            </>
                        )}
                        {brokerView && (
                            <Col style={{ marginRight: 40 }}>
                                <Text textStyle="tableText1">
                                    {t('BOOKING') + ' #'}
                                </Text>
                                <div>
                                    <Text
                                        textStyle="tableText2"
                                        style={{ fontSize: '11px' }}
                                    >
                                        {booking_code || '_'}
                                    </Text>
                                </div>
                            </Col>
                        )}

                        {flightCode && (
                            <Col style={{ marginRight: 40 }}>
                                <Text textStyle="tableText1">
                                    {t('FLIGHT') + ' #'}
                                </Text>
                                <div>
                                    <Text
                                        textStyle="tableText2"
                                        style={{ fontSize: '11px' }}
                                    >
                                        {flightCode || '_'}
                                    </Text>
                                </div>
                            </Col>
                        )}
                        {brokerView && (
                            <Col style={{ marginRight: 40 }}>
                                <Text textStyle="tableText1">
                                    {t('BOOKED_BY')}
                                </Text>
                                <div>
                                    <Text
                                        textStyle="heading5"
                                        style={{ fontSize: '12px' }}
                                    >
                                        {reqeustSendBy}
                                    </Text>
                                </div>
                            </Col>
                        )}
                        {!brokerView && (
                            <Col style={{ marginRight: 40 }}>
                                <Text textStyle="tableText1">
                                    {t('FLIGHT_STATUS')}
                                </Text>
                                <div>
                                    <BookingStatus
                                        status={bookingStatus}
                                        type="BOOKING"
                                    />
                                </div>
                            </Col>
                        )}
                    </Row>
                </Col>
                <Col>
                    <Row style={{ justifyContent: 'flex-end' }}>
                        {brokerView && (
                            <Col style={{ marginRight: 40 }}>
                                <Text textStyle="tableText1">
                                    {t('FLIGHT_STATUS')}
                                </Text>
                                <div>
                                    <BookingStatus
                                        status={bookingStatus}
                                        type="BOOKING"
                                    />
                                </div>
                            </Col>
                        )}
                        <Col style={{ marginRight: 40 }}>
                            <Text textStyle="tableText1">{t('OPERATOR')}</Text>
                            <div>
                                <Text
                                    textStyle="tableText2"
                                    style={{
                                        color: '#19C0FF',
                                        fontSize: '12px'
                                    }}
                                >
                                    {operator.name || '_'}
                                </Text>
                            </div>
                        </Col>
                        <Col style={{ marginRight: 10 }}>
                            <Text textStyle="tableText1">{t('JET_INFO')}</Text>
                            <Row>
                                <Col>
                                    <Text textStyle="heading5">
                                        {aircraft
                                            ? aircraft?.name?.toUpperCase()
                                            : '_'}
                                    </Text>
                                </Col>

                                <Col>
                                    <TailorCode>
                                        {aircraft
                                            ? aircraft?.tail_number?.toUpperCase()
                                            : '_'}
                                    </TailorCode>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </FlightInformation>
            <Row style={{ paddingTop: 20, backgroundColor: '#ffffff' }}>
                <Col span={10}>
                    <Row justify="center">
                        <IconDeparture fill="#19C0FF" style={iconStyle} />
                    </Row>
                    <Row justify="center">
                        <Text textStyle="heading1">
                            {from_airport?.icao_code || '-'}
                        </Text>
                    </Row>
                    <Row justify="center">
                        <Text textStyle="nameHighlight">
                            {from_airport?.name?.toUpperCase() || '-'}
                        </Text>
                    </Row>
                    <Row justify="center">
                        <Text textStyle="heading2">
                            {moment(departure?.date + ' ' + departure?.time)
                                .format('hh:mm A')
                                .toUpperCase()}
                        </Text>
                    </Row>
                    <Row justify="center" style={{ marginTop: -8 }}>
                        <Text textStyle="dateTimeBold">
                            {` (GMT ${departure.utc_offset > 0 ? '+' : ''}${
                                departure.utc_offset / 60
                            })`}
                        </Text>
                    </Row>
                    <Row justify="center">
                        <Text textStyle="dateTimeBold">
                            {moment(departure?.date)
                                .format('dddd, MMM DD YYYY')
                                .toUpperCase()}
                        </Text>
                    </Row>
                    <Row justify="center">
                        <FBOInfoCard
                            type="departure"
                            bookingStatus={bookingStatus}
                            onSelectFBO={onSelectFBO}
                            airport={from_airport}
                            title={t('DEPARTURE_FBO_INFO').toUpperCase()}
                            fbo={departure.fbo}
                        />
                    </Row>
                </Col>
                <Col span={4}>
                    <Row justify="center" style={{ marginTop: 65 }}>
                        <Col style={{ width: '100%' }}>
                            <Row
                                justify="center"
                                align="middle"
                                style={{ position: 'relative' }}
                            >
                                <Col style={{ width: '100%' }}>
                                    <Dashed
                                        style={{
                                            marginRight: 5
                                        }}
                                    />
                                    <img
                                        src={jetIcon}
                                        style={{
                                            display: 'flex',
                                            width: 32,
                                            height: 25,
                                            position: 'absolute',
                                            bottom: -12,
                                            justifyContent: 'center',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            left: 0,
                                            right: 0,
                                            backgroundColor: '#FFF'
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row justify="center" align="middle">
                                <Hours>{`${
                                    times.hour > 0 ? times.hour + ' HRS' : ''
                                } ${times.minute || '00'} MINS`}</Hours>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={10}>
                    <Row justify="center">
                        <IconLand fill="#19C0FF" style={iconStyle} />
                    </Row>
                    <Row justify="center">
                        <Text textStyle="heading1">
                            {to_airport?.icao_code || '-'}
                        </Text>
                    </Row>
                    <Row justify="center">
                        <Text textStyle="nameHighlight">
                            {to_airport?.name?.toUpperCase() || '-'}
                        </Text>
                    </Row>
                    <Row justify="center">
                        <Text textStyle="heading2">
                            {moment(arrival?.date + ' ' + arrival?.time)
                                .format('hh:mm A')
                                .toUpperCase()}
                        </Text>
                    </Row>
                    <Row justify="center" style={{ marginTop: -8 }}>
                        <Text textStyle="dateTimeBold">
                            {` (GMT ${departure.utc_offset > 0 ? '+' : ''}${
                                departure.utc_offset / 60
                            })`}
                        </Text>
                    </Row>
                    <Row justify="center">
                        <Text textStyle="dateTimeBold">
                            {moment(arrival?.date)
                                .format('dddd, MMM DD YYYY')
                                .toUpperCase()}
                        </Text>
                    </Row>
                    <Row justify="center">
                        <FBOInfoCard
                            type="arrival"
                            bookingStatus={bookingStatus}
                            onSelectFBO={onSelectFBO}
                            airport={to_airport}
                            title={t('ARRIVAL_FBO_INFO').toUpperCase()}
                            fbo={arrival.fbo}
                        />
                    </Row>
                </Col>
            </Row>
        </FlightStatusContainer>
    );
};

export default FlightDetailStatusCard;

const Dashed = styled.div`
    border: 1px dashed rgba(14, 14, 18, 0.2);
    width: 100%;
`;

const ButtonWrapper = styled.button`
    border: none;
    background-color: transparent;
`;

const FlightStatusContainer = styled.div`
    background-color: #fcfcfe;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 20px;
`;

const FlightInformation = styled(Row)`
    background-color: #fcfcfe;
    border-radius: 0 4px 0 0;
    padding-bottom: 10px;
    justify-content: space-between;
`;

const Hours = styled.span`
    color: #a9aeb6;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.7px;
    line-height: 18px;
    text-align: left;
    margin-top: 15px;
`;

const RequestStatus = styled.span`
    color: ${(props) => props.color || '#f5a623'};
    font-family: AvenirNext-DemiBold;
    font-size: ${(props) => props.size || '#12px'};
`;

const TailorCode = styled.div`
    border: 1px solid #dadadb;
    border-radius: 2px;
    min-width: 58px;
    height: 20px;
    color: #19c0ff;
    font-family: AvenirNext-Bold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: center;
    padding: 3px;
    margin-left: 5px;
`;
