import React from 'react';
import styled from 'styled-components';
import { Menu, Avatar } from 'antd';
import { useTranslation } from 'react-i18next';
import {
    profileIcon,
    avianPay,
    settingIcon,
    signoutIcon
} from 'assets/images/icons';

import personImage from 'assets/images/random-person.png';
const { SubMenu } = Menu;

const DropdownMenu = (props) => {
    const { t, i18n } = useTranslation();
    const {
        userCompanies = [],
        onChangeCompany,
        logout,
        onClickSettings
    } = props;
    return (
        <BoardSection>
            <SwitchCompanySection>
                <Menu defaultOpenKeys={['sub1']} mode="inline">
                    <SubMenu
                        key="sub1"
                        icon={<IconStyle src={profileIcon} />}
                        title={<ActingTitle />}
                    >
                        {userCompanies.map((company, index) => {
                            return (
                                <Menu.Item key={index}>
                                    <EachItem
                                        onClick={() => {
                                            onChangeCompany(company);
                                        }}
                                    >
                                        <Avatar
                                            src={
                                                company.image
                                                    ? company.image
                                                    : personImage
                                            }
                                        />
                                        <ItemName>
                                            {company.name ? company.name : ''}
                                        </ItemName>
                                    </EachItem>
                                </Menu.Item>
                            );
                        })}
                    </SubMenu>
                    <Menu.Item key="3" icon={<IconStyle src={avianPay} />}>
                        <DropDownItemText
                            title={t('AVIAN_PAY').toUpperCase()}
                            onClick={() => {}}
                        />
                    </Menu.Item>
                    <Menu.Item key="4" icon={<IconStyle src={settingIcon} />}>
                        <DropDownItemText
                            title={t('SETTINGS').toUpperCase()}
                            onClick={onClickSettings}
                        />
                    </Menu.Item>
                    <Menu.Item key="5" icon={<IconStyle src={signoutIcon} />}>
                        <SignOut logout={logout} />
                    </Menu.Item>
                </Menu>
            </SwitchCompanySection>
        </BoardSection>
    );
};

export default DropdownMenu;

const ItemName = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 24px;
    padding-left: 10px;
`;

const EachItem = styled.button`
    background-color: Transparent;
    background-repeat: no-repeat;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    border: none;
`;

const SwitchCompanySection = styled.div`
    background-color: #f7f8fb;
    width: 315px;
    min-height: 100px;
`;

const BoardSection = styled.div`
    height: auto;
    width: 315px;
    /* position: absolute; */
    background-color: #ffffff;
    /* top: 80px;
    right: 45px; */
    z-index: 10;

    .ant-menu-root.ant-menu-inline {
        box-shadow: 0 2px 3px 0px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 0%),
            0 9px 28px 8px rgb(0 0 0 / 2%);
    }
`;

const IconStyle = styled.img`
    // display: block;
    // margin: auto;
    // width: auto;
    // margin-left: 10px;
`;

const ActingAsText = styled.span`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    letter-spacing: 0.65px;
    line-height: 16px;
`;

const ItemButton = styled.button`
    background-color: Transparent;
    background-repeat: no-repeat;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    border: none;
`;

const ActingTitle = () => {
    const { t, i18n } = useTranslation();

    return <ActingAsText>{t('ACTING_AS').toUpperCase()}</ActingAsText>;
};

const DropDownItemText = (props) => {
    const { title, onClick } = props;

    return (
        <ItemButton onClick={() => onClick()}>
            <ActingAsText>{title}</ActingAsText>
        </ItemButton>
    );
};

const SignOut = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <ItemButton
            onClick={() => {
                props.logout();
            }}
        >
            <ActingAsText>{t('SIGN_OUT').toUpperCase()}</ActingAsText>
        </ItemButton>
    );
};
