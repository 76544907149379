import React, { useEffect, useState } from 'react';
import { template as tmpl } from './BookingDetail.tmpl';
import {
    getBookingById,
    addCrew,
    removeCrew,
    addFbo,
    confirmBooking
} from 'redux/actions/bookingAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BOOKING_STATUS, SHOW_LOADING, HIDE_LOADING } from 'redux/Constants';

const BookingDetailLogic = (props) => {
    const {
        activeCompany,
        employees,
        location = {},
        addCrew,
        removeCrew,
        addFbo,
        confirmBooking
    } = props;

    const { isLoading } = useSelector((state) => state.appReducer || {});

    const [bookingDetail, setBookingDetail] = useState({});

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { search } = location;

    /**
     * Get booking detail from backend
     */
    useEffect(() => {
        async function fetchBookingDetail() {
            dispatch({ type: SHOW_LOADING });

            let id = search;
            if (id && id[0] === '?') {
                id = id.substring(1);
            }

            const result = await props.getBookingById(id);
            if (result.data) {
                setBookingDetail(result.data);
            }

            setTimeout(() => {
                dispatch({ type: HIDE_LOADING });
            }, 450);
        }
        fetchBookingDetail();
    }, [props.location.search]);

    const onAddCrew = async (flightId, crew) => {
        if (crew && crew.user_id) {
            await addCrew(flightId, { user_id: crew.user_id, role: crew.role });
        }
    };

    const onRemoveCrew = async (flightId, crew) => {
        if (crew && crew.user_id) {
            let rs = await removeCrew(flightId, {
                user_id: crew.user_id
            });
            return rs;
        }
    };

    const onAddFBO = async (flightId, fboId, type) => {
        if (flightId && fboId) {
            let params = {};
            if (type === 'departure') {
                params.departure_fbo = fboId;
            } else {
                params.arrival_fbo = fboId;
            }
            await addFbo(flightId, params);
        }
    };

    const onConfirmBooking = async (
        bookingId = '',
        date = new Date(),
        markAsPaid = false
    ) => {
        if (isLoading) {
            return;
        };

        // show loading
        dispatch({ type: SHOW_LOADING });

        let body = {
            date: date,
            marked_as_paid: markAsPaid
        };
        let rs = await confirmBooking(bookingId, body);
        let modal = {};
        if (rs.success) {
            modal = {
                show: true,
                type: 'success',
                description: t('BOOKING_HAVE_BEEN_CONFIRMED'),
                title: t('CONFIRM_BOOKING_SUCCESS'),
                buttonTitle: t('VIEW_ITINERARIES').toUpperCase()
            };

            // update status booking CONFIRMED
            setBookingDetail({
                ...bookingDetail,
                status: BOOKING_STATUS.CONFIRMED
            });
        } else {
            modal = {
                show: true,
                type: 'error',
                title: t('CONFIRM_BOOKING_FAILED'),
                buttonTitle: 'OK'
            };
        }

        setTimeout(() => {
            dispatch({ type: HIDE_LOADING });
            dispatch({ type: 'SETTING_SHOW_MODAL', data: modal });
        }, 300);
    };

    const navigate = (route, search, state) => {
        props.history.push({
            pathname: route,
            search: search,
            state: state ? { data: state } : null
        });
    };

    const initProps = {
        activeCompany: activeCompany,
        bookingDetail: bookingDetail,
        navigate: navigate,
        addCrew: onAddCrew,
        removeCrew: onRemoveCrew,
        onAddFBO: onAddFBO,
        onConfirmBooking: onConfirmBooking
    };
    return tmpl && tmpl({ ...initProps });
};

class BookingDetailContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <BookingDetailLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { employees, activeCompany } = companyReducer;
    return {
        employees: employees,
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getBookingById: (id) => dispatch(getBookingById(id)),
        addCrew: (id, data) => dispatch(addCrew(id, data)),
        removeCrew: (id, data) => dispatch(removeCrew(id, data)),
        addFbo: (flight_id, data) => dispatch(addFbo(flight_id, data)),
        confirmBooking: (bookingId, data) =>
            dispatch(confirmBooking(bookingId, data))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(BookingDetailContainer)
);
