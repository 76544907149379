import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { Input, Table, Switch, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { ButtonCustom, localeEmptyTable } from 'components';
import { searchIcon, plusIcon } from 'assets/images/icons';
import { ADD_TOP_DESTINATION, TOP_DESTINATION_DETAIL } from 'routes/main';
import {
    NameSection,
    DetailSection,
    ColumnHeader,
    TableSection,
    SearchSection,
    AvailabilitySection,
    AvailableText,
    ImageCustom
} from '../../styles';
import styled from 'styled-components';

const template = (props) => {
    const { t, i18n } = useTranslation();
    const SearchIcon = <img src={searchIcon} />;
    const { topDestinations = {}, navigate, total = 0 } = props;

    const columns = [
        {
            key: 'photo',
            fixed: 'left',
            title: function titleHeader() {
                return <ColumnHeader>{t('DISPLAY_PHOTO')}</ColumnHeader>;
            },
            dataIndex: 'photo',
            width: '16%',
            render: function eachPhoto(photo, row = {}) {
                const { airport = {}, image = {} } = row;
                return (
                    <div style={{ paddingTop: '7px' }}>
                        <ImageCustom
                            src={
                                image?.lowres?.url
                                    ? row.image.lowres.url
                                    : null
                            }
                        />
                    </div>
                );
            }
        },
        {
            key: 'name',
            title: function titleHeader() {
                return <ColumnHeader>{t('DESTINATION')}</ColumnHeader>;
            },
            dataIndex: 'name',
            width: '20%',
            render: function eachItemName(name, row = {}) {
                const { to_airport = {} } = row;
                const { location = {} } = to_airport;
                return (
                    <NameSection>
                        <DestinationName>{name ? name.toUpperCase() : '_'}</DestinationName>
                    </NameSection>
                );
            }
        },
        {
            key: 'airport_info',
            width: '15%',
            title: function titleHeader() {
                return <ColumnHeader>{t('AIRPORT_INFO')}</ColumnHeader>;
            },
            dataIndex: 'airport_info',
            render: function eachItemRole(icao_iata, row) {
                const { to_airport = {} } = row;
                const { iata_code = '', icao_code = '', name = '' } = to_airport;
                return (
                    <>
                        <ICAOCode>{icao_code || '_'}</ICAOCode>
                        <AirportName>{name || '_'}</AirportName>
                    </>
                );
            }
        },
        {
            key: 'country',
            width: '15%',
            title: function titleHeader() {
                return <ColumnHeader>{t('COUNTRY')}</ColumnHeader>;
            },
            dataIndex: 'country',
            render: function eachItemRole(country, row) {
                const { to_airport = {} } = row;
                const { location = {} } = to_airport;
                return <LocationName>{location?.country?.english_name || '_'}</LocationName>;
            }
        },
        {
            key: 'enable',
            width: '18%',
            title: function titleHeader() {
                return <ColumnHeader>{t('DISPLAY')}</ColumnHeader>;
            },
            dataIndex: 'enable',
            render: function eachItemPhone(enable, row, index) {
                return (
                    <AvailabilitySection>
                        <AvailableText style={{ marginRight: '10px' }}>
                            {enable ? t('ENABLE').toUpperCase() : t('DISABLE').toUpperCase()}
                        </AvailableText>
                        <Switch
                            checked={enable}
                            style={{ marginLeft: 5 }}
                            onChange={() => {
                                props.changeDisplay(row);
                            }}
                        />
                    </AvailabilitySection>
                );
            }
        },
        {
            key: 'id',
            width: '15%',
            dataIndex: 'id',
            render: function eachItem(id) {
                return (
                    <DetailSection>
                        <ButtonCustom
                            btnType="secondary"
                            size="small"
                            title={t('VIEW_DETAILS').toUpperCase()}
                            width="120px"
                            onClick={(val) => navigate && navigate(TOP_DESTINATION_DETAIL, id)}
                        />
                    </DetailSection>
                );
            }
        }
    ];

    return (
        <Container>
            <TopAirportTotal>{`${t('TOP_DESTINATIONS').toUpperCase()} (${total})`}</TopAirportTotal>
            <SearchSection>
                <Row style={{ width: '100%' }}>
                    <Col xl={7} lg={8} md={10} sm={12} xs={12}>
                        <Input
                            placeholder={t('SEARCH_TOP_DESTINATIONS')}
                            allowClear
                            suffix={SearchIcon}
                            style={{ width: '100%' }}
                            onChange={(e) => props.onSearch(e)}
                        />
                    </Col>
                </Row>
                <ButtonCustom 
                    btnType="secondary"
                    onClick={() => navigate && navigate(ADD_TOP_DESTINATION)}
                    title={t('ADD_NEW_DESTINATION').toUpperCase()}
                    width="230px"
                    icon={plusIcon}
                />
            </SearchSection>
            <TableSection>
                <Table
                    scroll={{ x: 1000 }}
                    columns={columns}
                    pagination={{ showSizeChanger: false }}
                    dataSource={topDestinations}
                    locale={localeEmptyTable()}
                ></Table>
            </TableSection>
        </Container>
    );
};

export { template };

const Container = styled.div``;

const DestinationName = styled.span`
    color: #0E0E12;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.8px;
    line-height: 12px;
    text-align: left;
`;

const LocationName = styled.span`
    color: #65686F;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 20px;
    text-align: left;
`;

const ICAOCode = styled.div`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 13px;
    line-height: 16px;
    text-align: left;
`;

const AirportName = styled.div`
    color: #65686F;
    font-family: AvenirNext-Medium;
    font-size: 11px;
    line-height: 16px;
    text-align: left;
    margin-top: 8px;
`;

const TopAirportTotal = styled.div`
    color: #0E0E12;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.7px;
    line-height: 16px;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 20px;
`;