import React, { useState, useEffect } from 'react';
import { template as tmpl } from './FBOContainer.tmpl';
import {
    getFBOs as getCompaniesAction,
    searchFBO
} from '../../redux/actions/companyAction';
import { connect } from 'react-redux';

const FBOLogic = (props) => {
    const { getCompanies, searchFBO } = props;
    const [fboSearch, setFBOSearch] = useState([]);
    const [fboList, setFBOList] = useState([]);
    const [key, setKey] = useState('');

    /**
     * Get FBO list from backend
     */
    useEffect(() => {
        const initData = async () => {
            let query = { sort_column: 'created_at', sort_direction: 'desc' };
            const rs = await getCompanies(query);
            if (rs && rs.success) {
                setFBOList(rs.data || []);
                setFBOSearch(rs.data || []);
            }
        };

        initData();
    }, []);

    /**
     * Function handles searching when user is typing
     * @param {*} e event when user is typing
     */
    const onSearch = async (e) => {
        const input = e.target.value.toLowerCase();
        setKey(input);
        if (!input) {
            setFBOList(fboSearch);
            return;
        }

        const rs = await searchFBO(input);
        if (rs && rs.success) {
            setFBOList(rs.data || []);
        }
    };

    const navigate = (route, id, state) => {
        props.history.push({
            pathname: route,
            search: id,
            state: state
        });
    };

    const initProps = {
        fboList: fboList,
        onSearch: onSearch,
        navigate: navigate,
        key: key,
        setKey: setKey
    };

    return tmpl && tmpl({ ...initProps });
};

class FBOContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <FBOLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { customers } = companyReducer;
    return {
        customers: customers
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCompanies: (data) => dispatch(getCompaniesAction(data)),
        searchFBO: (key) => dispatch(searchFBO(key))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FBOContainer);
