import React, { useEffect, useState } from 'react';
import { template as tmpl } from './WhiteLabelSetting.tmpl';
import { createWhitelabel } from 'redux/actions/appActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { SHOW_LOADING, HIDE_LOADING } from 'redux/Constants';
import { EDIT_WHITELABEL } from 'routes/main';
import { message } from 'antd';
import _ from 'lodash';

const WhiteLabelSettingLogic = (props) => {
    const { location } = props;
    const { search } = location;

    const dispatch = useDispatch();
    const { activeCompany } = useSelector(
        (state) => state.companyReducer || {}
    );

    const createWhitelabelPage = async (data) => {
        const result = await dispatch(createWhitelabel(activeCompany.id, data));
        if (result.success) {
            navigate(EDIT_WHITELABEL, result.data.id);
        } else {
            message.error('Create Whitelabel fail');
        }
    };

    const navigate = (route, search, state) => {
        props.history.push({
            pathname: route,
            search: search,
            state: state ? { data: state } : null
        });
    };

    const initProps = {
        activeCompany: activeCompany,
        navigate: navigate,
        createWhitelabelPage: createWhitelabelPage
    };
    return tmpl && tmpl({ ...initProps });
};

class WhiteLabelSetting extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <WhiteLabelSettingLogic {...initProps} />;
    }
}

export default WhiteLabelSetting;
