import React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { ButtonCustom, Text, localeEmptyTable } from 'components';
import { ButtonType } from 'components/Antd/Button';
import {
    DetailSection,
    BookingCode,
    TimeText,
    TypeText,
    ItemText
} from '../styles';
import { ColumnHeader } from 'styles/shareStyles';
import { formatDepartureTime, numberFormat } from 'utils';
import {
    BookingStatus,
    JetInfo,
    FlightInfo,
    Booker,
    FlightStatus
} from 'components/Commons';
import { FLIGHT_DETAIL } from 'routes/main';

const CompletedTable = (props) => {
    const { t, i18n } = useTranslation();
    const {
        navigate,
        list = {},
        key,
        setKey,
        loading = false,
        onChangePageCompletedFlights
    } = props;
    const { docs = [], limit, page, pages, total = 0 } = list;

    const columns = [
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('FLIGHTS') + ' #'}</ColumnHeader>;
            },
            width: 80,
            dataIndex: 'flight_code',
            key: 'flight_code',
            fixed: 'left',
            render: function eachItemFlight(flight_code) {
                return <BookingCode>{flight_code}</BookingCode>;
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('DEPARTURE_TIME')}</ColumnHeader>;
            },
            width: 120,
            dataIndex: 'departure',
            key: 'departure',
            render: function eachItemDepartureTime(departure) {
                const { date = '', time = '', utc_offset = 0 } = departure;
                return (
                    <TimeText>
                        {formatDepartureTime(date, time, utc_offset)}
                    </TimeText>
                );
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('LANDED_AT')}</ColumnHeader>;
            },
            width: 120,
            dataIndex: 'arrival',
            key: 'arrival',
            render: function eachItemLandedAt(arrival) {
                const { date = '', time = '', utc_offset = 0 } = arrival;
                return (
                    <TimeText>
                        {formatDepartureTime(date, time, utc_offset)}
                    </TimeText>
                );
            }
            // sorter: (a, b) => a.updated_at.localeCompare(b.updated_at)
        },

        {
            title: function titleHeader() {
                return (
                    <ColumnHeader>
                        {t('ORIGINAL_BOOKER') + ' / ' + t('PARTNER')}
                    </ColumnHeader>
                );
            },
            width: 150,
            dataIndex: 'user_basic_info',
            key: 'user_basic_info',
            render: function eachType(user, record) {
                let isCompany = false;
                let userInformation = {
                    user_id: '',
                    firstname: '',
                    lastname: '',
                    company_id: ''
                };

                if (record.user_basic_info) {
                    userInformation = record.user_basic_info;
                } else if (record.company_basic_info) {
                    userInformation = {
                        firstname:
                            record.company_basic_info?.company_name || '',
                        company_id: record.company_basic_info?.company_id || ''
                    };
                    isCompany = true;
                }
                return <Booker user={userInformation} isCompany={isCompany} />;
            }
        },

        {
            title: function titleHeader() {
                return <ColumnHeader>{t('FLIGHT_INFO')}</ColumnHeader>;
            },
            dataIndex: 'from_to',
            key: 'from_to',
            width: 180,
            render: function eachItemFromTo(from_to, record) {
                const leg = {
                    from_airport: record.from_airport || {},
                    to_airport: record.to_airport || {}
                };
                return (
                    <div>
                        <FlightInfo leg={leg} />
                    </div>
                );
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('OPERATED_BY')}</ColumnHeader>;
            },
            dataIndex: 'operator_basic_info',
            key: 'operator_basic_info',
            width: 120,
            render: function eachItemFromTo(operator_basic_info) {
                return (
                    <Text
                        textStyle="tableText2"
                        style={{
                            color: '#19C0FF',
                            fontSize: '12px'
                        }}
                    >
                        {operator_basic_info.company_name}
                    </Text>
                );
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('JET_INFO')}</ColumnHeader>;
            },
            dataIndex: 'aircraft',
            key: 'aircraft',
            width: 80,
            render: function eachItemJetInfo(aircraft) {
                const leg = { aircraft: aircraft };
                return (
                    <div>
                        <JetInfo leg={leg} />
                    </div>
                );
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('STATUS')}</ColumnHeader>;
            },
            dataIndex: 'status',
            key: 'status',
            width: 150,
            render: function eachItemStatus(status) {
                return <FlightStatus status={status} />;
            }
        },

        {
            key: 'action',
            width: 145,
            render: function setAction(val) {
                return (
                    <DetailSection>
                        <ButtonCustom
                            btnType={ButtonType.PRIMARY}
                            title={t('VIEW_DETAIL').toUpperCase()}
                            size="small"
                            borderColor="1px solid rgba(18, 18, 22, 0.2)"
                            width="115px"
                            onClick={(e) => navigate(FLIGHT_DETAIL, val.id)}
                        />
                    </DetailSection>
                );
            }
        }
    ];

    return (
        <>
            <Text textStyle="heading5">{`${t(
                'COMPLETED'
            ).toUpperCase()} (${total})`}</Text>

            <Table
                columns={columns}
                dataSource={docs}
                style={{ marginTop: 20 }}
                scroll={{ x: 1200 }}
                loading={loading}
                locale={localeEmptyTable()}
                pagination={{
                    defaultPageSize: 5,
                    total: pages * 5 - 1,
                    hideOnSinglePage: false,
                    showSizeChanger: false
                }}
                onChange={(e) => onChangePageCompletedFlights(e)}
            />
        </>
    );
};

export default CompletedTable;
