import React, { useState, useEffect } from 'react';
import { template as tmpl } from './AddTopRatedAirport.tmpl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { uploadImage } from 'redux/actions/appActions';
import { addTopRatedAirport } from 'redux/actions/airportAction';
import { UPLOAD_MODEL } from 'redux/Constants';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FLIGHT_PROMOTION_LIST } from 'routes/main';

const AddTopRatedAirportLogic = (props) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const { addTopRatedAirport, uploadImage } = props;

    const navigate = (route) => {
        props.history.push(route);
    };

    const onSubmit = async (data) => {
        let rs = await addTopRatedAirport(data);
        if (!rs.success) {
            let modal = {
                show: true,
                type: 'error',
                title: t('ADD_NEW_TOP_RATED_ERROR'),
                buttonTitle: 'OK',
            };
            dispatch({ type: 'SETTING_SHOW_MODAL', data: modal });
            return;
        }

        rs = await uploadImage(rs.data.id, data.photo, UPLOAD_MODEL.TOP_RATE_AIRPORT);
        if (!rs.success) {
            let modal = {
                show: true,
                type: 'error',
                title: t('ADD_NEW_TOP_RATED_ERROR'),
                buttonTitle: 'OK',
            };
            dispatch({ type: 'SETTING_SHOW_MODAL', data: modal });
            return;
        }

        let modal = {
            show: true,
            title: t('ADD_NEW_TOP_RATED_SUCCESS'),
            buttonTitle: 'OK',
            onOk: FLIGHT_PROMOTION_LIST,
            onCancel: FLIGHT_PROMOTION_LIST
        };
        dispatch({ type: 'SETTING_SHOW_MODAL', data: modal });
    };

    const initProps = {
        onSubmit: onSubmit,
        navigate: navigate
    };

    return tmpl && tmpl({ ...initProps });
};

class AddTopRatedAirportContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <AddTopRatedAirportLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { companies } = companyReducer;
    return {
        companies: companies
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTopRatedAirport: (data) => dispatch(addTopRatedAirport(data)),
        uploadImage: (id, photo, model) =>
            dispatch(uploadImage(id, photo, model))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AddTopRatedAirportContainer)
);
