import React from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingOutlined } from '@ant-design/icons';
import { role } from 'config/Constants';
import { Text, SecondaryButton } from 'components';
import { editIcon, deleteIcon, iconCancel } from 'assets/images/icons';
import { isUserAdminOrOperator } from 'redux/selectors/user';
import { useSelector } from 'react-redux';
import {
    QuickToolBarContainer,
    CreateQuoteTopRight,
    IconUpdateFleetWrapper,
    CancelEditButton,
    CancelIcon,
    IconBorder
} from '../style';

const QuickToolBarComponent = (props) => {
    const { t } = useTranslation();
    const havePermission = useSelector(isUserAdminOrOperator());
    const {
        isEdit,
        onCancelEdit,
        onClickEdit,
        handleDeleteButton,
        loading,
        activeCompany,
        aircraft,
        onOpenEstimateAircraftModal
    } = props;

    return (
        <>
            <QuickToolBarContainer>
                <CreateQuoteTopRight>
                    <SecondaryButton
                        wrapStyle={{ width: 'fit-content' }}
                        size="small"
                        title={t('ESTIMATE_AIRCRAFT').toUpperCase()}
                        onClick={onOpenEstimateAircraftModal}
                    />
                    {activeCompany?.id &&
                        aircraft?.company?.id &&
                        havePermission &&
                        activeCompany.id === aircraft?.company.id && (
                            <>
                                <IconUpdateFleetWrapper>
                                    {isEdit ? (
                                        <CancelEditButton
                                            onClick={onCancelEdit}
                                        >
                                            <Text textStyle="tableText2">
                                                {t('CANCEL_EDIT').toUpperCase()}
                                            </Text>
                                            <CancelIcon src={iconCancel} />
                                        </CancelEditButton>
                                    ) : (
                                        <IconBorder onClick={onClickEdit}>
                                            <img src={editIcon} />
                                        </IconBorder>
                                    )}
                                </IconUpdateFleetWrapper>
                                <IconBorder
                                    onClick={() =>
                                        handleDeleteButton &&
                                        handleDeleteButton()
                                    }
                                >
                                    {loading ? (
                                        <LoadingOutlined
                                            style={{
                                                fontSize: '24px',
                                                color: '#ffffff'
                                            }}
                                        />
                                    ) : (
                                        <img src={deleteIcon} />
                                    )}
                                </IconBorder>
                            </>
                        )}
                </CreateQuoteTopRight>
            </QuickToolBarContainer>
        </>
    );
};

export default QuickToolBarComponent;
