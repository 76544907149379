import React from 'react';
import styled from 'styled-components';
import background from '../../../assets/images/BG-image.jpg';
import 'antd/dist/antd.css';
import { Card, Form } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import logo from '../../../assets/images/logo/Logo_JetOS_04_horizontal.png';
import '../../../assets/fonts/Avenir-Light/Avenir-Light.css';
import OtpInput from 'react-otp-input';

const template = (props) => {
    const { t, i18n } = useTranslation();
    const {
        loading,
        payload = {},
        errors,
        handleSubmitOTP,
        otp,
        handleChangeOtp
    } = props;

    const { type, email, phone } = payload;

    return (
        <StyledBackground>
            <HeaderSection>
                <LogoSection>
                    <Logo src={logo} />
                </LogoSection>
                <CreateAccountSection></CreateAccountSection>
            </HeaderSection>
            <ContentSection>
                <Card className="ant-card-login">
                    <WelcomeSection>
                        <WelcomeText>
                            {t('VERIFICATION').toUpperCase()}
                        </WelcomeText>
                    </WelcomeSection>
                    <InstructionSection>
                        <InstructionText>
                            {`${t('ENTER_6_DIGITS')} ${type === 'sms' ? phone || '_' : email || '_'}`}
                        </InstructionText>
                    </InstructionSection>

                    <LoginInformationSection>
                        <Form
                            layout="vertical"
                            style={{ width: '100%', maxWidth: 470 }}
                        >
                            <OtpInput
                                value={otp}
                                onChange={handleChangeOtp}
                                numInputs={6}
                                containerStyle={{ justifyContent: 'center', alignItems: 'center' }}
                                focusStyle={focusStyle}
                                inputStyle={inputStyle}
                                separator={<span></span>}
                            />
                            {errors && errors.otp && (
                                <ErrorText>
                                    {t(errors.otp)}
                                </ErrorText>
                            )}

                            <Form.Item
                                style={{
                                    justifyContent: 'center',
                                    display: 'flex',
                                    marginTop: '40px'
                                }}
                            >
                                <StyledButton
                                    disabled={otp.length < 6 ? true : false}
                                    inactive={otp.length < 6 ? true : false}
                                    onClick={() => {
                                        handleSubmitOTP &&
                                        handleSubmitOTP();
                                    }}
                                >
                                    {loading ? (
                                        <LoadingOutlined
                                            style={{
                                                fontSize: '24px',
                                                color: '#ffffff'
                                            }}
                                        />
                                    ) : (
                                        <SubmitButtonText>
                                            {t('SUBMIT').toUpperCase()}
                                        </SubmitButtonText>
                                    )}
                                </StyledButton>
                            </Form.Item>
                        </Form>
                    </LoginInformationSection>
                </Card>
            </ContentSection>

            <FooterSection></FooterSection>
        </StyledBackground>
    );
};

const StyledBackground = styled.div`
    background-image: url(${background});
    height: 110vh;
    /* min-width: 100%; */
    min-height: 100%;
    background-size: cover;
    background-position: center;
    /* display: flex;
    flex-direction: column; */
    /* justify-content: space-between; */
    flex-wrap: wrap;
    .ant-row-center {
        padding-left: 3rem;
        padding-top: 1rem;
        padding-right: 3rem;
    }
`;

const HeaderSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 3rem;
    padding-top: 1rem;
    padding-right: 3rem;
    margin-bottom: 50px;
    width: 100%;
`;

const FooterSection = styled.div``;

const LogoSection = styled.div``;

const ErrorText = styled.span`
    padding-top: 20px;
    color: #f42020;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
`;

const CreateAccountSection = styled.div`
    margin-top: auto;
    margin-bottom: auto;
`;

const Logo = styled.img`
    height: 50px;
    width: auto;
`;

const ContentSection = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    .ant-card-login {
        border-radius: 8px;
        width: 35vw;
        min-width: 500px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .ant-form-item-control {
        min-width: 550px;
    }
    @media (max-width: 1680px) {
        .ant-form-item-control {
            min-width: 400px;
        }
    }
    .ant-form-vertical .ant-form-item-label > label {
        width: 100%;
    }
`;

const StyledButton = styled.button`
    width: 50%;
    min-width: 110px;
    background-color: ${props => props.inactive ? '#8A99A9' : '#19c0ff'};
    border-radius: 2px;
    border: none;
    height: 50px;
    margin-bottom: 32px;
`;

const SubmitButtonText = styled.span`
    color: #ffffff;
    font-family: AvenirNext-DemiBold;
    font-size: 13px;
    letter-spacing: 1px;
    line-height: 16px;
    text-align: center;
`;

const WelcomeSection = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 50px;
`;

const WelcomeText = styled.span`
    font-size: 20px;
    letter-spacing: 1.5px;
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 24px;
    letter-spacing: 2px;
    line-height: 32px;
    text-align: center;
`;

const InstructionSection = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    padding-top: 10px;
`;

const InstructionText = styled.span`
    color: #65686f;
    font-family: Lato;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
`;

const LoginInformationSection = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    .ant-form-item-control-input-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
`;

const inputStyle = {
    padding: '10px',
    width: '50px',
    height: '50px',
    maginLeft: '15px',
    marginRight: '15px',
    fontSize: '24px',
    fontWeight: '600',
    borderRadius: '8px',
};

const focusStyle = {
    outlineColor: '#19c0ff',
    color: '#19c0ff'
}

export { template };
