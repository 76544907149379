import React, { useEffect, useState } from 'react';
import { template as tmpl } from './QuoteRequestContainer.tmpl';
import { getQuotes, searchQuote } from '../../redux/actions/quoteAction';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { QUOTE_REQUEST_STATUS } from '../../redux/Constants';

const QuoteRequestContainerLogic = (props) => {
    const { activeCompany, getQuotes, searchQuote } = props;
    const [quotes, setQuotes] = useState({});
    const [searchBy, setSearchBy] = useState(QUOTE_REQUEST_STATUS.ALL);
    const [key, setKey] = useState(''); // key input search with flight number, ...
    const [newList, setNewList] = useState([]);
    const [awaitingList, setAwaitingList] = useState([]);
    const [acceptedList, setAcceptedList] = useState([]);
    const [declinedList, setDeclinedList] = useState([]);
    const [searchList, setSearchList] = useState([]);

    useEffect(() => {
        async function searchByCategory() {
            if (key) {
                let rs = await searchQuote(
                    key,
                    5,
                    1,
                    !searchBy || searchBy === QUOTE_REQUEST_STATUS.ALL
                        ? undefined
                        : searchBy.toLowerCase()
                );
                setSearchList(rs || []);
            }
        }
        searchByCategory();
    }, [searchBy]);

    /**
     * Function handles searching when user is typing
     * @param {*} e event when user is typing
     */
    const onSearch = async (e) => {
        const input = e.target.value.toLowerCase();
        setKey(input);
        if (!input) return;

        let rs = await searchQuote(
            input,
            5,
            1,
            !searchBy || searchBy === QUOTE_REQUEST_STATUS.ALL
                ? undefined
                : searchBy.toLowerCase()
        );
        setSearchList(rs || []);
    };

    const onChangePageSearch = async (pagination = {}) => {
        const { current, pageSize } = pagination;
        let rs = await searchQuote(
            key,
            pageSize,
            current,
            !searchBy || searchBy === QUOTE_REQUEST_STATUS.ALL
                ? undefined
                : searchBy.toLowerCase()
        );
        setSearchList(rs || []);
    };

    const initQuotes = async () => {
        onChangePageNewQuotes({ current: 1, pageSize: 5 });
        onChangePageAwaiting({ current: 1, pageSize: 5 });
        onChangePageAccepted({ current: 1, pageSize: 5 });
        onChangePageDeclined({ current: 1, pageSize: 5 });
    };

    const onChangePageNewQuotes = async (pagination = {}) => {
        const { current, pageSize } = pagination;
        let newQuotes = await getQuotes(
            QUOTE_REQUEST_STATUS.ACTIVE,
            pageSize,
            current
        );
        if (newQuotes.success && newQuotes.isConnected && newQuotes.data) {
            setNewList(newQuotes.data);
        }
    };

    const onChangePageAwaiting = async (pagination = {}) => {
        const { current, pageSize } = pagination;
        let awaitingQuotes = await getQuotes(
            QUOTE_REQUEST_STATUS.WAITING,
            pageSize,
            current,
            'updated_at'
        );
        if (
            awaitingQuotes.success &&
            awaitingQuotes.isConnected &&
            awaitingQuotes.data
        ) {
            setAwaitingList(awaitingQuotes.data);
        }
    };

    const onChangePageAccepted = async (pagination = {}) => {
        const { current, pageSize } = pagination;
        let acceptedQuotes = await getQuotes(
            QUOTE_REQUEST_STATUS.ACCEPTED,
            pageSize,
            current,
            'updated_at'
        );
        if (
            acceptedQuotes.success &&
            acceptedQuotes.isConnected &&
            acceptedQuotes.data
        ) {
            setAcceptedList(acceptedQuotes.data);
        }
    };

    const onChangePageDeclined = async (pagination = {}) => {
        const { current, pageSize } = pagination;
        let declinedQuotes = await getQuotes(
            QUOTE_REQUEST_STATUS.EXPIRED,
            pageSize,
            current,
            'updated_at'
        );
        if (
            declinedQuotes.success &&
            declinedQuotes.isConnected &&
            declinedQuotes.data
        ) {
            setDeclinedList(declinedQuotes.data);
        }
    };

    const onSelectCategory = (value) => {
        setSearchBy(value);
    };
    /**
     * Get Booking list
     */
    useEffect(() => {
        initQuotes();
    }, [activeCompany]);

    const navigate = (route, search, state) => {
        props.history.push({
            pathname: route,
            search: search,
            state: state ? { data: state } : null
        });
    };

    const initProps = {
        key: key,
        searchBy: searchBy,
        quotes: quotes,
        newList: newList,
        awaitingList: awaitingList,
        acceptedList: acceptedList,
        declinedList: declinedList,
        searchList: searchList,
        activeCompany: activeCompany,
        navigate: navigate,
        onChangePageNewQuotes: onChangePageNewQuotes,
        onChangePageAwaiting: onChangePageAwaiting,
        onChangePageAccepted: onChangePageAccepted,
        onChangePageDeclined: onChangePageDeclined,
        onChangePageSearch: onChangePageSearch,
        onSearch: onSearch,
        onSelectCategory: onSelectCategory
    };
    return tmpl && tmpl({ ...initProps });
};

class QuoteRequestContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <QuoteRequestContainerLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { employees, activeCompany } = companyReducer;
    return {
        employees: employees,
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getQuotes: (status, limit, page, sortColumn) =>
            dispatch(getQuotes(status, limit, page, sortColumn)),
        searchQuote: (key, limit, page, status) =>
            dispatch(searchQuote(key, limit, page, status))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QuoteRequestContainer);
