import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Avatar, message } from 'antd';
import { Text, ButtonCustom, TextArea } from 'components';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { addNote } from 'redux/actions/bookingAction';
import { updateQuoteRequest } from 'redux/actions/quoteAction';

/**
 *
 * @param {String} id - flightId (type: flight) | (type: quote)
 * @param {String} legId - requied with type: quote
 * @param {String} label
 * @param {Array} notes
 * @param {String} type - flight | quote
 * @returns
 */
const FlightNotes = (props) => {
    const { id, legId = '', label = '', notes = [], type = '' } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const userReducer = useSelector((state) => state.userReducer || {});
    const { activeCompany } = useSelector(
        (state) => state.companyReducer || {}
    );

    const [noteList, setNoteList] = useState([]);

    const [content, setContent] = useState('');

    useEffect(() => {
        if (!_.isEmpty(notes)) {
            setNoteList(notes);
        }
    }, [notes]);

    const onAddNote = async () => {
        if (!id || !content) {
            message.error(t('ERROR_ADDING_NOTES'));
            return;
        }

        let data = {
            category: 'GENERAL', // default
            content: content
        };

        let rs;
        switch (type) {
            case 'flight':
                rs = await dispatch(addNote(id, data));
                break;
            case 'quote':
                const payload = {
                    type: 'NOTE',
                    leg_id: legId,
                    note: {
                        content: data.content,
                        category: data.category || 'GENERAL'
                    }
                };
                rs = await dispatch(updateQuoteRequest(id, payload));
                break;
            default:
                break;
        }

        if (rs && rs.success) {
            // add note to noteList
            data.created_at = Date.now();
            data.user = userReducer || {};
            setNoteList([...noteList, data]);

            // reset content
            setContent('');
        } else {
            message.error(t('ERROR_ADDING_NOTES'));
        }
    };

    return (
        <Container>
            <Label>
                <Text textStyle="heading5">{label}</Text>
            </Label>
            <NoteList>
                {noteList.length > 0 &&
                    noteList.map((item = {}, index) => {
                        const {
                            user = {},
                            category = '',
                            content = '',
                            firstname = '',
                            lastname = '',
                            company_name
                        } = item;
                        return (
                            <WrapperItem
                                key={index}
                                style={{ marginTop: index > 0 ? 20 : 10 }}
                            >
                                <ItemContainer>
                                    <Col>
                                        <Avatar size={40} src={user?.image} />
                                    </Col>
                                    <Col style={{ marginLeft: 15 }}>
                                        <Row>
                                            <Text textStyle="heading6">
                                                {firstname || lastname
                                                    ? firstname + ' ' + lastname
                                                    : user?.firstname +
                                                      ' ' +
                                                      user?.lastname}
                                            </Text>
                                        </Row>
                                        <Row>
                                            <Operator
                                                textStyle="tableText1"
                                                style={{ marginTop: 5 }}
                                            >
                                                {`${
                                                    company_name ||
                                                    activeCompany.name ||
                                                    'Avian Jets'
                                                }`.toUpperCase()}
                                            </Operator>
                                        </Row>
                                    </Col>
                                </ItemContainer>
                                <ItemContainer style={{ marginTop: 5 }}>
                                    <Text textStyle="paragraphMedium">
                                        {content}
                                    </Text>
                                </ItemContainer>
                            </WrapperItem>
                        );
                    })}

                <div style={{ marginTop: noteList.length > 0 ? 20 : 10 }}>
                    <TextArea
                        onChange={(val) => setContent(val)}
                        numberOfRow={3}
                        placeholder={t('WRITE_SOME_NOTES')}
                        value={content}
                    />
                </div>
                <AddMoreSection>
                    <ButtonCustom
                        size="small"
                        btnType={'secondary'}
                        title={t('ADD_NOTES').toUpperCase()}
                        onClick={() => onAddNote()}
                    />
                </AddMoreSection>
            </NoteList>
        </Container>
    );
};

export default FlightNotes;

const AddMoreSection = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 20px;
`;

const WrapperItem = styled.div`
    background-color: #f7f8fb;
    border-radius: 4px;
    padding: 20px;
`;

const ItemContainer = styled(Row)`
    align-items: center;
`;

const NoteList = styled.div`
    background-color: #ffffff;
    border-radius: 4px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;
    height: 100%;
`;

const Container = styled.div`
    width: 100%;
    height: 90%;
`;

const Operator = styled.span`
    color: #19c0ff;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
`;

const Label = styled.div``;
