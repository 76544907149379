const reportWebVitals = (onPerfEntry) => {
    if (onPerfEntry && onPerfEntry instanceof Function) {
        import('web-vitals').then(
            ({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
                getCLS(onPerfEntry);
                getFID(onPerfEntry);
                getFCP(onPerfEntry);
                getLCP(onPerfEntry);
                getTTFB(onPerfEntry);
            }
        );
    }

    // register serviceWorker
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker
            .register('firebase-messaging-sw.js')
            .then(function (registration) {
                // eslint-disable-next-line no-console
                console.log('[SW]: SCOPE: ', registration.scope);
                return registration.scope;
            })
            .catch(function (err) {
                return err;
            });
    }
};

export default reportWebVitals;
