import React, { useState, useEffect } from 'react';
import { template as tmpl } from '../AutoComplete.tmpl';
import { connect } from 'react-redux';
import { DropdownWrapper, DropdownText } from 'styles/shareStyles';

const allowed_roles = ['PILOT', 'COPILOT', 'HOST'];

const SearchEmployeesLogic = (props) => {
    const {
        label = '',
        employee = {},
        clearAfterSearch = true,
        placeholder = 'Enter Name of Crew Member',
        onSelectEmployee,
        dropdownStyle,
        inputStyle,
        employees
    } = props;

    const [crewEmployees, setCrewEmployees] = useState([]);

    useEffect(() => {
        let values = employees.filter((employee) =>
            allowed_roles.includes(employee.role)
        );
        setCrewEmployees(values);
    }, employees);

    const onSearchEmployees = async (key = '') => {
        try {
            if (!key) return [];

            const input = key.toLowerCase();
            const result =
                crewEmployees &&
                crewEmployees.filter((item) => {
                    if (
                        item.name.toLowerCase().includes(input) ||
                        item.email.toLowerCase().includes(input)
                    ) {
                        return item;
                    }
                });
            return result || [];
        } catch (e) {}
    };

    const [options, setOptions] = useState([]);
    const [items, setItems] = useState([]);
    const [inputSearch, setInputSearch] = useState('');

    useEffect(() => {
        setInputSearch(employee ? employee.english_name : '');
    }, [employee]);

    const searchResult = (results = []) =>
        results.map((val, i) => {
            const category = `${val.name}`;
            return {
                key: i,
                value: category,
                label: (
                    <DropdownWrapper>
                        <DropdownText>{`${val.name} - ${val.role}`}</DropdownText>
                    </DropdownWrapper>
                )
            };
        });

    const onSelect = (value) => {
        let data = items.find((v) => v.name === value);
        // select employee
        onSelectEmployee && onSelectEmployee(data);

        if (clearAfterSearch) {
            setInputSearch('');
        } else {
            setInputSearch(value);
        }
    };

    const handleSearch = async (value) => {
        const result = await onSearchEmployees(value);
        setInputSearch(value);
        setItems(result);
        setOptions(result ? searchResult(result) : []);
    };

    return (
        tmpl &&
        tmpl({
            label,
            employee,
            placeholder,
            inputStyle,
            onSelect,
            handleSearch,
            options,
            inputSearch
        })
    );
};

class SearchEmployees extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <SearchEmployeesLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { employees = [] } = companyReducer;
    return {
        employees: employees
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        //
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchEmployees);
