import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { template as tmpl } from './RequestAccountCard.tmpl';
import { requestAccount } from '../../../redux/actions/appActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const RequestAccountCardLogic = (props) => {
    const [visibleModal, setVisibleModal] = useState(false);
    const initProps = {
        visibleModal,
        setVisibleModal,
        ...props
    };
    return tmpl && tmpl({ ...initProps });
};

class RequestAccountCard extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    render() {
        const initProps = {
            title: 'Login',
            ...this.props
        };
        return <RequestAccountCardLogic {...initProps} />;
    }
}
const mapStateToProps = (state) => {
    return {};
};
const mapDispatchToProps = (dispatch) => {
    return {
        requestAccount: (data) => dispatch(requestAccount(data))
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(RequestAccountCard)
);
