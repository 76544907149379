import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { Col, Row, Avatar } from 'antd';
import '../customer.css';
import {
    Container,
    Title,
    TitleDescription,
    Name,
    InfoDetail,
    InfoDetailSection,
    GeneralSection,
    AvatarSection,
    NameSection,
    EmailAndRole,
    PhoneAndCompany,
    EachButton,
    AddressAndSince,
    Component,
    ButtonSection,
    ButtonIcon,
    Icon
} from '../styles';
import { editIcon } from 'assets/images/icons';
import { renderAddress } from 'utils';
import { EDIT_CUSTOMER } from 'routes/main';
import { ButtonCustom } from 'components';
import moment from 'moment';
import _ from 'lodash';

const EachInfo = (props) => {
    return (
        <>
            <Title
                style={{
                    marginBottom: 3,
                    marginTop: 10
                }}
            >
                {props.label}
            </Title>
            <TitleDescription color={props.color}>
                {props.detail}
            </TitleDescription>
        </>
    );
};

const General = (props) => {
    const { navigate, user = {} } = props;
    const {
        id,
        name = '',
        firstname = '',
        lastname = '',
        address = {},
        company = {},
        image = '',
        email = '',
        gender = '',
        phone = '',
        phone_verification = false,
        membership = {}
    } = user;

    const { t, i18n } = useTranslation();

    const onClickEdit = () => navigate(EDIT_CUSTOMER, id);

    const _renderDetailView = () => {
        return (
            <Component className="wrapper">
                <Row>
                    <GeneralSection>
                        <AvatarSection>
                            {image ? (
                                <Avatar
                                    className="ant-avatar-custom ant-avatar-custom-big"
                                    src={image}
                                    size={100}
                                />
                            ) : (
                                <Avatar
                                    className="ant-avatar-custom ant-avatar-custom-big"
                                    size={100}
                                >
                                    {(
                                        firstname.charAt(0) + lastname.charAt(0)
                                    ).toUpperCase()}
                                </Avatar>
                            )}
                        </AvatarSection>
                        <InfoDetailSection>
                            <NameSection>
                                <Name>
                                    {(firstname + ' ' + lastname).toUpperCase()}
                                </Name>
                            </NameSection>
                            <InfoDetail>
                                <EmailAndRole>
                                    <EachInfo
                                        label={t('EMAIL_ADDRESS')}
                                        detail={
                                            email ? email.toUpperCase() : '_'
                                        }
                                    />
                                    <EachInfo
                                        label={t('COMPANY')}
                                        detail={
                                            company && company.name
                                                ? company.name.toUpperCase()
                                                : '_'
                                        }
                                        color={'#19C0FF'}
                                    />
                                </EmailAndRole>
                                <PhoneAndCompany>
                                    <EachInfo
                                        label={t('PHONE_NUMBER')}
                                        detail={phone || '-'}
                                    />
                                    <EachInfo
                                        label={t('MEMBERSHIP')}
                                        detail={
                                            membership?.name?.toUpperCase() ||
                                            '_'
                                        }
                                        color={'#19C0FF'}
                                    />
                                </PhoneAndCompany>
                                <AddressAndSince>
                                    <EachInfo
                                        label={t('ADDRESS')}
                                        detail={
                                            !_.isEmpty(address)
                                                ? renderAddress(
                                                      address
                                                  ).toUpperCase()
                                                : '_'
                                        }
                                    />
                                    <EachInfo
                                        label={t('MEMBERSHIP_SINCE')}
                                        detail={
                                            membership?.started_at
                                                ? moment(membership?.started_at)
                                                      .format('MMMM DD, YYYY')
                                                      .toUpperCase()
                                                : '_'
                                        }
                                    />
                                </AddressAndSince>
                            </InfoDetail>
                        </InfoDetailSection>
                    </GeneralSection>
                    <Col>
                        <ButtonSection>
                            <EachButton>
                                <ButtonCustom
                                    btnType="primary"
                                    size="small"
                                    title={t('SEND_MESSAGE').toUpperCase()}
                                    width={'150px'}
                                />
                            </EachButton>
                            <EachButton>
                                {/* <ButtonCustom
                                    btnType="secondary"
                                    size="small"
                                    title={t('EDIT_INFORMATION').toUpperCase()}
                                    width={'150px'}
                                    onClick={() => {
                                        onClickEdit();
                                    }}
                                /> */}
                                <ButtonIcon
                                    style={{ marginLeft: '25px' }}
                                    onClick={() => onClickEdit()}
                                >
                                    <Icon src={editIcon} />
                                </ButtonIcon>
                            </EachButton>
                        </ButtonSection>
                    </Col>
                </Row>
            </Component>
        );
    };

    return <Container>{_renderDetailView()}</Container>;
};

export default General;
