import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { Text } from 'components';
import { useTranslation } from 'react-i18next';
import { PaymentStatus } from 'components/Commons';
import { getPaymentStatus } from 'utils';
import { PAYMENT_STATUS } from 'redux/Constants';
import moment from 'moment';

const getTimer = (time) => {
    const countdown = time;

    let days = Math.floor(countdown / (1000 * 60 * 60 * 24));
    let hours = Math.floor((countdown / (1000 * 60 * 60)) % 24);

    let divisor_for_minutes = countdown % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    const timer = { days, hours, minutes, seconds };
    return timer;
};

const BookingCancelTime = ({ legs = [] }) => {
    const { t, i18n } = useTranslation();

    const [timeLeft, setTimeLeft] = useState(-1);
    const [timeShow, setTimeShow] = useState({});

    useEffect(() => {
        try {
            if (!_.isEmpty(legs)) {
                let leg = legs[0] || {};
                let departure = leg.departure || {};
                const then = moment.utc(departure.utc_datetime).valueOf();
                const now = moment.utc().valueOf();
                const time = then - now - 1800; // 30 minutes before departure
    
                if (time > 0) {
                    setTimeLeft(time);
                }
            }
        } catch (e) {}
    }, [legs]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (timeLeft > 0) {
                const newTimeLeft = timeLeft - 1
                setTimeLeft(newTimeLeft);

                // get timer to show
                const timerStr = getTimer(newTimeLeft);
                setTimeShow({ ...timerStr });
            }
        }, 1000);

        // Clear timeout if the component is unmounted
        return () => clearTimeout(timer);
      });

    const _rednerTimer = () => {
        const { days = 0, hours = 0, minutes = 0, seconds = 0 } = timeShow;
        return (
            <CanceledText size="10px">
                {`${days} DAYS : ${hours || '00'} HRS : ${minutes || '00'} MINS : ${seconds || '00'} SECS`}
            </CanceledText>
        );
    };

    return (
        <>
            <CanceledWrap>
                <CanceledText size="10px" color="#121216">
                    {t('BOOKING_CANCEL_IN').toUpperCase()}
                </CanceledText>
            </CanceledWrap>
            <CanceledWrap background="rgba(255, 102, 121, 0.13)">
                {_rednerTimer()}
            </CanceledWrap>
        </>
    );
};

const InvoicePaymentCard = (props) => {
    const {
        type = '',
        booking_code = '',
        name = '',
        payment_status = '',
        legs = [],
        operatorInvoice = false
    } = props;
    const { t, i18n } = useTranslation();

    return (
        <BookingStatusWrapper>
            <Col span={12}>
                <Row style={{ width: '100%', justifyContent: 'flex-start' }}>
                    <Col
                        xxl={5}
                        xl={6}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={24} 
                    >
                        <Text textStyle="tableText1">{t('BOOKING_TYPE')}</Text>
                        <div>
                            <Text
                                textStyle="tableText2"
                                style={{
                                    color: '#19C0FF',
                                    fontSize: '11px'
                                }}
                            >
                                {type ? t(type).toUpperCase() : '_'}
                            </Text>
                        </div>
                    </Col>
                    { !operatorInvoice && (
                        <>
                            <Col
                                xxl={5}
                                xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={24} 
                            >
                                <Text textStyle="tableText1">
                                    {t('BOOKING') + ' #'}
                                </Text>
                                <div>
                                    <Text
                                        textStyle="tableText2"
                                        style={{
                                            fontSize: '11px'
                                        }}
                                    >
                                        {booking_code
                                            ? booking_code.toUpperCase()
                                            : '_'}
                                    </Text>
                                </div>
                            </Col>
                            <Col
                                xxl={5}
                                xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={24} 
                            >
                                <Text textStyle="tableText1">{t('BOOKED_BY')}</Text>
                                <div>
                                    <Text textStyle="heading5">{name}</Text>
                                </div>
                            </Col>
                        </>
                    )}
                    <Col
                        xxl={5}
                        xl={6}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={24} 
                    >
                        <Text textStyle="tableText1">
                            {t('PAYMENT_STATUS')}
                        </Text>
                        <div>
                            <PaymentStatus
                                status={getPaymentStatus(payment_status)}
                            />
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col span={12} style={{ alignSelf: 'center' }}>
                <Row
                    style={{
                        justifyContent: 'flex-end'
                    }}
                >
                    {payment_status &&
                        payment_status !== PAYMENT_STATUS.PAID && (
                            <BookingCancelTime legs={legs} />
                        )}
                </Row>
            </Col>
        </BookingStatusWrapper>
    );
};

export default InvoicePaymentCard;

const BookingStatusWrapper = styled(Row)`
    background-color: #fcfcfe;
    border-radius: 0 4px 0 0;
    padding: 10px;
    padding-left: 20px;
    margin-top: 20px;
`;

const CanceledWrap = styled.div`
    background-color: ${(props) => props.background || '#f8f9fe'};
    border-radius: ${(props) => props.borderRadius || '2px 0 0 2px'};
    margin: 5px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    height: 33px;
`;

const CanceledText = styled.span`
    color: ${(props) => props.color || '#dd4558'};
    font-family: AvenirNext-DemiBold;
    font-size: ${(props) => props.size || '14px'};
    letter-spacing: 1px;
    line-height: 18px;
`;
