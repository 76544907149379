import React from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { Input, DatePicker, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const template = (props) => {
    const { t, i18n } = useTranslation();
    const {
        label,
        fontFamily = 'Lato',
        color = '#121216',
        required = false,
        optional = false,
        width = '330px',
        height = '50px',
        placeholder = '',
        min = 0,
        // suffix,
        datePicker,
        value = '',
        errorMsg,
        allowClear = false,
        containerStyle = {},
        isNumber = false,
        isError = false,
        minWidth = '330px',
        disabled = false
    } = props;
    // props are passed from Logic is in here
    const inputStyle = {
        height: height,
        width: width,
        fontFamily: fontFamily,
        color: color,
        border: `1px solid ${isError ? '#f00' : 'rgba(36, 36, 45, 0.15)'}`,
        borderRadius: '3px',
        marginTop: '13px',
        maxWidth: '773px'
        // minWidth: minWidth
    };
    return (
        <InputContainer
            style={containerStyle}
            className="custom-input-container"
        >
            {label ? (
                <LabelText>
                    {label + (optional ? ' (' + t('OPTIONAL').toUpperCase() + ')' : '')}
                    {required && (
                        <span style={{ color: '#DD4558' }}>
                            {' *'}
                        </span>
                    )}
                </LabelText>
            ) : (
                <Buffer></Buffer>
            )}
            {datePicker ? (
                <DatePicker
                    style={inputStyle}
                    placeholder={placeholder}
                    value={value ? moment.utc(value) : null}
                    onChange={(date, dateString) => {
                        props.onChange(date, dateString);
                    }}
                />
            ) : (
                <>
                    {isNumber ? (
                        <InputNumber
                            disabled={disabled}
                            style={inputStyle}
                            placeholder={placeholder}
                            min={min}
                            value={value}
                            allowClear={allowClear}
                            onChange={(value) => {
                                if (props.onChange) {
                                    props.onChange(value || 0);
                                }
                            }}
                        />
                    ) : (
                        <Input
                            disabled={disabled}
                            className="custom-input"
                            style={inputStyle}
                            placeholder={placeholder}
                            value={value}
                            allowClear={allowClear}
                            onChange={(e) => {
                                if (props.onChange) {
                                    props.onChange(e.target.value);
                                }
                            }}
                        />
                    )}
                </>
            )}
            {errorMsg && <ErrorSection>{errorMsg}</ErrorSection>}
        </InputContainer>
    );
};

const ErrorSection = styled.span`
    color: #e2253d;
    font-family: AvenirNext-Medium;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    margin-top: 7px;
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    .ant-input::placeholder {
        color: #8e929a;
        font-family: Lato;
        font-size: 14px;
        line-height: 17px;
    }
    .ant-picker-input > input::placeholder {
        color: #8e929a;
        font-family: Lato;
        font-size: 14px;
        line-height: 17px;
    }
    .ant-input-number {
        display: flex;
        align-items: center;
    }
    .ant-input-number-input-wrap {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
    }
`;

const LabelText = styled.span`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
`;

const Buffer = styled.div`
    height: 12px;
`;

export { template };
