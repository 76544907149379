import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
const { TextArea } = Input;

/**
 *
 * @param {*} props
 * @returns
 */
const TextAreaComponent = (props) => {
    const { children, placeholder, value, style, onChange, numberOfRow } =
        props;
    return (
        <TextAreaSection>
            <TextArea
                onChange={(val) => {
                    if (onChange && typeof onChange === 'function')
                        onChange(val.target.value);
                }}
                style={style}
                rows={numberOfRow}
                placeholder={placeholder}
                value={value}
            />
        </TextAreaSection>
    );
};

export default TextAreaComponent;

const TextAreaSection = styled.div`
    /* textarea::-webkit-input-placeholder {
        padding-top: 10px;
    } */

    /* Firefox 18- */
    /* textarea:-moz-placeholder {
        padding-top: 10px;
    } */

    /* Firefox 19+ */
    /* textarea::-moz-placeholder {
        padding-top: 10px;
    } */

    /* textarea:-ms-input-placeholder {
        padding-top: 10px;
    } */

    /* textarea::placeholder {
        padding-top: 10px;
    } */

    textarea {
        padding-top: 10px;
        font-family: Lato;
        font-size: 14px;
    }
`;
