import 'antd/dist/antd.css';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Text } from 'components';
import { Carousel } from 'react-responsive-carousel';
import { numberFormat } from 'utils';
import { AIRCRAFT_DETAIL } from 'routes/main';
import _ from 'lodash';
import {
    DetailPropertiesLine1,
    DetailPropertiesLine2,
    Description,
    EachRateProperty,
    RateValue,
    RateLabel,
    RateSection,
    OperatedBy,
    OperatedBySection,
    PlaneCode,
    PlaneName,
    PlaneNameSection,
    EachProperty,
    ImageSection,
    DetailInformationSection,
    PropertyLabel,
    PropertyValue,
    OperatedByText
} from '../style';
import { fallbackImage } from 'config/Constants';
import { useSelector } from 'react-redux';
import { getWindowDimensions } from 'utils';
/**
 *
 * @param {*} props
 * @param {*} textStyle fieldLabel
 * @returns
 */
const AircraftDetailCardComponent = (props) => {
    const { expandedSidebar } = useSelector(
        (state) => state.settingReducer || false
    );
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const { t, i18n } = useTranslation();
    const {
        aircraft = {},
        navigate,
        from,
        activeCompany,
        showThumbs = true,
        showInfo = true
    } = props;
    let thumbImageWidth = 110;
    let thumbImageHeight = '70px';
    let mainImageWidth = '350px';
    let showDescription = true;
    let imageWidth = '350px';
    let imageHeight = '220px';
    const arrowStyles = {
        position: 'absolute',
        top: '.7em',
        bottom: 'auto',
        padding: '.4em',
        zIndex: 2
    };

    switch (from) {
        case AIRCRAFT_DETAIL:
            thumbImageWidth = 85;
            thumbImageHeight = '55px';
            showDescription = false;
            mainImageWidth = '14.0625vw';
            imageWidth = '270px';
            imageHeight = '170px';
            break;
        case 'INVOICE':
            thumbImageWidth = 85;
            thumbImageHeight = '55px';
            showDescription = false;
            mainImageWidth = '14.0625vw';
            imageWidth = '270px';
            imageHeight = '165px';
            break;
        default:
            break;
    }
    const {
        id,
        model = '',
        name = '',
        tail_number = '',
        description = '',
        company = {},
        price_parameters = {},
        classtype,
        year_of_manufacture,
        last_refurbished_at,
        properties = {},
        amenities = [],
        images,
        operator_name,
        home_airport = {}
    } = aircraft;

    const EachPropertySection = ({ label, value }) => {
        return (
            <EachProperty>
                <PropertyLabel>
                    <Text textStyle="tableText1">{label}</Text>
                </PropertyLabel>
                <PropertyValue>
                    <Text textStyle="tableText2">{value}</Text>
                </PropertyValue>
            </EachProperty>
        );
    };

    const {
        currency = 'USD',
        minimum_booking_time_hours,
        positioning_flight_per_hour_fee,
        revenue_flight_per_hour_fee,
        fees = {}
    } = price_parameters;

    const {
        cruising_speed_in_knots,
        max_range_in_nautical_miles,
        luggage_capacity_ft3,
        max_passengers
    } = properties;

    const { overnight_stay_fee } = fees;

    return (
        <AircraftDetailCard
            className="aircraft-detail-card"
            expandedSidebar={expandedSidebar}
        >
            <ImageSection
                width={mainImageWidth}
                thumbImageHeight={thumbImageHeight}
                thumbImageWidth={thumbImageWidth}
                expandedSidebar={expandedSidebar}
            >
                <Carousel
                    className="carousel-custom"
                    thumbWidth={thumbImageWidth}
                    showStatus={false}
                    showIndicators={false}
                    showThumbs={showThumbs}
                >
                    {images &&
                        images.map((eachImage, index) => {
                            return (
                                <div
                                    key={index}
                                    className="image-carousel-custom"
                                >
                                    <img
                                        src={
                                            eachImage.lowres?.url ||
                                            fallbackImage
                                        }
                                        style={{
                                            objectFit: 'cover',
                                            borderRadius: '2px',
                                            width: imageWidth,
                                            height: imageHeight
                                        }}
                                    />
                                </div>
                            );
                        })}
                </Carousel>
            </ImageSection>
            <DetailInformationSection
                className="aircraft-detail-information"
                expandedSidebar={expandedSidebar}
            >
                <PlaneNameSection>
                    <PlaneName>
                        <Text textStyle="heading3">
                            {name ? name.toUpperCase() : model.toUpperCase()}
                        </Text>
                    </PlaneName>
                    <PlaneCode>
                        <Text textStyle="jetCode">{tail_number}</Text>
                    </PlaneCode>
                </PlaneNameSection>
                <OperatedBySection style={{ display: 'flex' }}>
                    <OperatedBy>
                        <OperatedByText>
                            {t('OPERATED_BY').toUpperCase() + ' '}
                        </OperatedByText>
                        <Text textStyle="nameHighlight">
                            {operator_name
                                ? operator_name.toUpperCase()
                                : company && company.name
                                ? company.name.toUpperCase()
                                : ''}
                        </Text>
                    </OperatedBy>
                    {!_.isEmpty(home_airport) && (
                        <OperatedBy style={{ marginLeft: '40px' }}>
                            <OperatedByText>
                                {t('HOME_PAGE_AIRPORT').toUpperCase() + ' '}
                            </OperatedByText>
                            <Text textStyle="nameHighlight">
                                {home_airport?.icao_code?.toUpperCase() || '_'}
                            </Text>
                        </OperatedBy>
                    )}
                </OperatedBySection>
                {activeCompany?._id &&
                company?.id &&
                activeCompany._id === company.id ? (
                    <RateSection
                        className="aircraft-rate-section"
                        expandedSidebar={expandedSidebar}
                    >
                        <EachRateProperty>
                            <RateLabel>
                                <Text textStyle="fieldLabel">
                                    {t('HOURLY_RATES').toUpperCase()}
                                </Text>
                            </RateLabel>
                            <RateValue>
                                <Text textStyle="heading6">
                                    {numberFormat(
                                        revenue_flight_per_hour_fee,
                                        currency
                                    ) || '-'}
                                </Text>
                            </RateValue>
                        </EachRateProperty>
                        <EachRateProperty>
                            <RateLabel>
                                <Text textStyle="fieldLabel">
                                    {t('OVERNIGHT_FEES').toUpperCase()}
                                </Text>
                            </RateLabel>
                            <RateValue>
                                <Text textStyle="heading6">
                                    {numberFormat(
                                        overnight_stay_fee,
                                        currency
                                    ) || '-'}
                                </Text>
                            </RateValue>
                        </EachRateProperty>
                        <EachRateProperty>
                            <RateLabel>
                                <Text textStyle="fieldLabel">
                                    {t('DAILY_MINIMUM').toUpperCase()}
                                </Text>
                            </RateLabel>
                            <RateValue>
                                <Text textStyle="heading6">
                                    {minimum_booking_time_hours + 'HRS'}
                                </Text>
                            </RateValue>
                        </EachRateProperty>
                    </RateSection>
                ) : null}
                {from !== AIRCRAFT_DETAIL ? (
                    <Description>
                        {description || t('NO_DESCRIPTION')}
                    </Description>
                ) : null}
                <DetailPropertiesLine1>
                    <EachPropertySection
                        label={t('AIRCRAFT_TYPE')}
                        value={classtype ? t(classtype).toUpperCase() : '-'}
                    />
                    <EachPropertySection
                        label={t('YEAR')}
                        value={year_of_manufacture || '-'}
                    />
                    <EachPropertySection
                        label={t('LAST_REFURBISHED')}
                        value={last_refurbished_at?.substring(0, 4) || '-'}
                    />
                    <EachPropertySection
                        label={t('NUMBER_OF_SEATS')}
                        value={max_passengers || '-'}
                    />
                </DetailPropertiesLine1>
                <DetailPropertiesLine2>
                    {/* <EachProperty>
                        <PropertyLabel>
                            <Text textStyle="tableText1">
                                {t('CRUISE_SPEED')}
                            </Text>
                        </PropertyLabel>
                        <PropertyValue>
                            <Text textStyle="tableText2">
                                {cruising_speed_in_knots
                                    ? cruising_speed_in_knots
                                    : '-'}
                            </Text>
                        </PropertyValue>
                    </EachProperty> */}
                    <EachPropertySection
                        label={t('CRUISE_SPEED')}
                        value={
                            cruising_speed_in_knots
                                ? cruising_speed_in_knots
                                : '-'
                        }
                    />
                    <EachPropertySection
                        label={t('RANGE')}
                        value={max_range_in_nautical_miles || '-'}
                    />
                    <EachPropertySection
                        label={t('LUGGAGE_CAPACITY')}
                        value={luggage_capacity_ft3 || '-'}
                    />
                </DetailPropertiesLine2>
            </DetailInformationSection>
        </AircraftDetailCard>
    );
};

export default AircraftDetailCardComponent;

const AircraftDetailCard = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-right: 30px;
    padding-top: 20px;
    /* padding-bottom: 20px; */
    /* border: 1px solid #000; */
    max-width: 1520px;
    @media (max-width: 1830px) {
        padding-right: 10px;
        .aircraft-detail-information {
            max-width: ${(props) =>
                props.expandedSidebar ? '600px' : '700px'};
        }
    }
    @media (max-width: 1600px) {
        padding-right: 0;
        .aircraft-detail-information {
            max-width: ${(props) =>
                props.expandedSidebar ? '500px' : '700px'};
        }
    }
    @media (max-width: 1366px) {
        padding-right: 0;
        .aircraft-detail-information {
            ${(props) => props.expandedSidebar && 'width: 600px'};
            padding-bottom: 20px;
        }
    }
    @media (max-width: 1024px) {
        padding-right: 0;
        .aircraft-detail-information {
            padding-bottom: 20px;
            width: ${(props) => (props.expandedSidebar ? '600px' : '550px')};
        }
    }
    @media (max-width: 768px) {
        .aircraft-detail-information {
            padding-bottom: 20px;
            /* width: ${(props) =>
                props.expandedSidebar ? '300px' : '650px'}; */
            width: 650px;
        }
    }
`;

// const Image = (props) => {
//     const { src, key } = props;
//     const [imageSrc, setImageSrc] = useState(src);

//     const loadImageFail = () => {
//         setImageSrc(imageIcon);
//     };

//     return (
//         <div key={key}>
//             <img
//                 src={imageSrc}
//                 onError={(e) => {
//                     loadImageFail();
//                 }}
//             />
//             {/* <p className="legend">Legend 2</p> */}
//         </div>
//     );
// };
