import React, { useState, useEffect } from 'react';
import { template as tmpl } from './CompanyInvoices.tmpl';
import { connect } from 'react-redux';

const InvoiceListLogic = (props) => {
    const { activeCompany } = props;

    useEffect(() => {
        //
    }, [activeCompany]);

    const navigate = (route, id, state) => {
        props.history.push({
            pathname: route,
            search: id,
            state: state
        });
    };

    const initProps = {
        navigate: navigate
    };

    return tmpl && tmpl({ ...initProps });
};

class InvoiceListContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <InvoiceListLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { customers, activeCompany } = companyReducer;
    return {
        customers: customers,
        activeCompany: activeCompany // TODO : fetch booking list on change company
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        //
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoiceListContainer);
