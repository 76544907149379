import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Select, Tooltip } from 'antd';
import { iconArrowDown, infoIosIcon } from 'assets/images/icons';
import { useTranslation } from 'react-i18next';
import WebFont from 'webfontloader';
const { Option, OptGroup } = Select;
/**
 *
 * @param {*} props
 * @param {*} textStyle fieldLabel
 * @returns
 */
const CustomDropDown = (props) => {
    const {
        children,
        placeholder,
        width,
        data = [],
        onChange,
        required = false,
        pickedData,
        onClear,
        height = '50px',
        isError = false,
        showSearch = false
    } = props;

    const { t, i18n } = useTranslation();
    const [selectedValue, setSelectedValue] = useState(null);

    useEffect(() => {
        if (pickedData && pickedData.length === 0) {
            setSelectedValue(null);
        } else {
            setSelectedValue(pickedData);
        }
    }, [pickedData]);

    const inputStyle = {
        border: isError && required ? '1px solid #f00' : 'none',
        borderRadius: 4
    };

    return (
        <SearchSection width={width} height={height}>
            <Select
                showSearch={showSearch}
                className="ant-select-custom"
                suffixIcon={<DropDownIcon src={iconArrowDown} />}
                style={{ ...inputStyle }}
                placeholder={
                    <SelectPlaceholder className="SelectPlaceholder">
                        {placeholder}
                    </SelectPlaceholder>
                }
                onChange={(value) => {
                    if (value) {
                        let obj = data.find((o) => o.value === value);
                        setSelectedValue(obj.text);
                        onChange && onChange(value);
                    } else {
                        setSelectedValue(null);
                    }
                }}
                onClear={() => {
                    if (onClear && typeof onClear === 'function') {
                        onClear();
                    }
                }}
                value={selectedValue || null}
                allowClear={true}
            >
                {data.map((item, index) => {
                    let text = '';
                    switch (item.text) {
                        case 'Avenir Next':
                            text = 'Avenir Next (Default)';
                            break;
                        case 'Lato':
                            text = 'Lato (Default)';
                            break;
                        default:
                            text = item.text || item.value?.family || '';
                            break;
                    }
                    return (
                        <Option
                            value={item.value}
                            key={index}
                            label={item.value}
                        >
                            <div
                                onMouseEnter={(e) => {
                                    if (item.value !== 'Avenir Next') {
                                        WebFont.load({
                                            google: {
                                                families: [item.value]
                                            },
                                            timeout: 100 // Set the timeout
                                        });
                                    }
                                }}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '8px 0'
                                }}
                            >
                                <span style={{ fontSize: 16 }}>{text}</span>
                                <Tooltip
                                    placement="topLeft"
                                    title={() => {
                                        return (
                                            <span
                                                style={{
                                                    fontFamily:
                                                        item.value !==
                                                        'Avenir Next'
                                                            ? item.value
                                                            : 'AvenirNext-Medium',
                                                    fontSize: 30
                                                }}
                                            >
                                                ABCabc
                                            </span>
                                        );
                                    }}
                                    arrowPointAtCenter
                                >
                                    <Icon src={infoIosIcon} />
                                </Tooltip>
                            </div>
                        </Option>
                    );
                })}
            </Select>
        </SearchSection>
    );
};

export default CustomDropDown;

const SearchSection = styled.div`
    .ant-select-custom .ant-select-selector {
        height: ${(props) => (props.height ? props.height : '50px')};
        border-radius: 3px;
        ${(props) => props.width && `width:${props.width};`}
    }
    .ant-input {
        background-color: transparent;
    }
    .ant-input-affix-wrapper {
        border: 1px solid #dadadb;
        border-radius: 3px;
        width: ${(props) => (props.width ? props.width : '20vw')};
        height: 40px;
        background-color: transparent;
    }
    .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
    }
    .ant-select-show-search.ant-select:not(.ant-select-customize-input)
        .ant-select-selector
        input {
        height: 100%;
    }
    .ant-select-selection-item {
        height: 50px;
    }
`;

const SelectPlaceholder = styled.span`
    color: #8e929a;
    font-family: Lato;
    font-size: 14px;
    line-height: 17px;
`;

const DropDownIcon = styled.img`
    height: 15px;
    width: 15px;
`;

const Icon = styled.img`
    width: 16px;
    height: 16px;
`;
