import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { template as tmpl } from './ActivateAccount.tmpl';
import { validateActivation, activateAccount } from 'redux/actions/appActions';
import { LOGIN } from 'routes/main';
import { withRouter } from 'react-router-dom';

const ActivateAccountLogic = (props) => {
    const dispatch = useDispatch();
    const { match = {} } = props;
    const { params = {} } = match;
    const { uid, activeId } = params;
    const [isValidLink, setIsValidLink] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorPassword, setErrorPassword] = useState('');
    const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const validateLink = async () => {
        const rs = await dispatch(validateActivation(uid, activeId));
        if (rs && rs.success) {
            setIsValidLink(true);
        } else {
            setIsValidLink(false);
        }
    };

    const validatePassword = (p) => {
        // let errors = '';
        if (p.length < 8) {
            return 'PASSWORD_AT_LEAST_8_CHARACTERS';
        }
        if (p.search(/[a-z]/i) < 0) {
            return 'PASSWORD_AT_LEAST_CONTAIN_1_LETTER';
        }
        if (p.search(/[0-9]/) < 0) {
            return 'PASSWORD_AT_LEAST_CONTAIN_1_DIGIT';
        }
        return '';
    };

    useEffect(() => {
        validateLink();
    }, []);

    // validate password rule
    useEffect(() => {
        if (validatePassword(password)) {
            setErrorPassword(validatePassword(password));
        } else {
            setErrorPassword('');
        }
    }, [password]);

    // validate confirmPassword is match with password
    // useEffect(() => {}, [confirmPassword]);

    const handleActivateAccount = async () => {
        setLoading(true);
        if (password === confirmPassword) {
            const rs = await dispatch(
                activateAccount(uid, {
                    password
                })
            );
            setLoading(false);
            if (rs.success) {
                props.history.push(LOGIN);
            } else {
                setErrorPassword('ERROR_ACCOUNT_VALIDATION');
            }
            setErrorConfirmPassword('');
        } else {
            setErrorConfirmPassword('CONFIRM_PASSWORD_DOES_NOT_MATCH');
            setLoading(false);
        }
    };

    const initProps = {
        loading: loading,
        isValidLink: isValidLink,
        password: password,
        confirmPassword: confirmPassword,
        setPassword: setPassword,
        setConfirmPassword: setConfirmPassword,
        errorPassword: errorPassword,
        errorConfirmPassword: errorConfirmPassword,
        handleActivateAccount: handleActivateAccount
    };
    return tmpl && tmpl({ ...initProps });
};

class ActivateAccount extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <ActivateAccountLogic {...initProps} />;
    }
}

export default withRouter(ActivateAccount);
