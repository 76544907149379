import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { imageIcon, IconBack, IconForward } from '../../assets/images/icons';
import { useTranslation } from 'react-i18next';
import { Text } from 'components';
import { Carousel } from 'react-responsive-carousel';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
    Navigation,
    Thumbs,
    EffectCoverflow,
    Pagination
} from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { numberFormat } from 'utils';
import { AIRCRAFT_DETAIL } from 'routes/main';
import _ from 'lodash';
import { Carousel as AntCarousel, List, Image } from 'antd';
import { fallbackImage } from 'config/Constants';
import { useSelector } from 'react-redux';
import { getWindowDimensions } from 'utils';

/**
 *
 * @param {*} props
 * @param {*} textStyle fieldLabel
 * @returns
 */
const AircraftDetailCardComponent = (props) => {
    const { expandedSidebar } = useSelector(
        (state) => state.settingReducer || false
    );
    // const swiper = new Swiper('.swiper', {
    //     freeMode: true
    // });
    SwiperCore.use([Navigation, Thumbs, EffectCoverflow, Pagination]);
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [smallSizeSwiper, setSmallSizeSwiper] = useState(null);

    // useEffect(() => {
    //     if (smallSizeSwiper) {
    //         const timer = setTimeout(() => {
    //             smallSizeSwiper.slideTo(0);
    //         }, 500);
    //         return () => clearTimeout(timer);
    //     }
    // }, [smallSizeSwiper]);
    const { t, i18n } = useTranslation();
    const {
        aircraft = {},
        navigate,
        from,
        activeCompany,
        showThumbs = false,
        showInfo = true
    } = props;
    let thumbImageWidth = 110;
    let thumbImageHeight = '70px';
    let mainImageWidth = '350px';
    let showDescription = true;
    let imageWidth = '350px';
    let imageHeight = '220px';
    const arrowStyles = {
        position: 'absolute',
        top: '.7em',
        bottom: 'auto',
        padding: '.4em',
        zIndex: 2
    };

    switch (from) {
        case AIRCRAFT_DETAIL:
            thumbImageWidth = 85;
            thumbImageHeight = '55px';
            showDescription = false;
            mainImageWidth = '270px';
            imageWidth = '270px';
            imageHeight = '170px';
            break;
        case 'INVOICE':
            thumbImageWidth = 85;
            thumbImageHeight = '55px';
            showDescription = false;
            mainImageWidth = '250px';
            imageWidth = '250px';
            imageHeight = '157px';
            break;
        default:
            break;
    }
    const {
        id,
        model = '',
        name = '',
        tail_number = '',
        description = '',
        company = {},
        price_parameters = {},
        classtype,
        year_of_manufacture,
        last_refurbished_at,
        properties = {},
        amenities = [],
        images,
        operator_name,
        home_airport = {}
    } = aircraft;

    const EachPropertySection = ({ label, value }) => {
        return (
            <EachProperty>
                <PropertyLabel>
                    <Text textStyle="tableText1">{label}</Text>
                </PropertyLabel>
                <PropertyValue>
                    <Text textStyle="tableText2">{value}</Text>
                </PropertyValue>
            </EachProperty>
        );
    };

    const {
        currency = 'USD',
        minimum_booking_time_hours,
        positioning_flight_per_hour_fee,
        revenue_flight_per_hour_fee,
        fees = {}
    } = price_parameters;

    const {
        cruising_speed_in_knots,
        max_range_in_nautical_miles,
        luggage_capacity_ft3,
        max_passengers
    } = properties;

    const { overnight_stay_fee } = fees;

    return (
        <AircraftDetailCard
            className="aircraft-detail-card"
            expandedSidebar={expandedSidebar}
        >
            {windowDimensions.width > 768 ? (
                <ImageSection
                    windowDimensions={windowDimensions}
                    width={mainImageWidth}
                    thumbImageHeight={thumbImageHeight}
                    thumbImageWidth={thumbImageWidth}
                    imageHeight={imageHeight}
                    expandedSidebar={expandedSidebar}
                >
                    <Swiper
                        style={{
                            '--swiper-navigation-color': '#8E929A',
                            '--swiper-pagination-color': '#8E929A',
                            '--swiper-navigation-size': '14px'
                        }}
                        spaceBetween={10}
                        navigation={true}
                        // navigation={{ prevEl, nextEl }}
                        // onBeforeInit={onBeforeInit}
                        thumbs={{ swiper: thumbsSwiper }}
                        className="mySwiper2"
                    >
                        {images &&
                            images.map((eachImage, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <Image
                                            src={
                                                eachImage.lowres?.url ||
                                                fallbackImage
                                            }
                                            style={{
                                                objectFit: 'cover',
                                                borderRadius: '2px',
                                                width: imageWidth,
                                                height: imageHeight
                                            }}
                                        />
                                    </SwiperSlide>
                                );
                            })}
                    </Swiper>

                    <Swiper
                        onSwiper={setThumbsSwiper}
                        spaceBetween={10}
                        slidesPerView={3}
                        // freeMode={true}
                        watchSlidesProgress={true}
                        className="mySwiper"
                    >
                        {images &&
                            images.map((eachImage, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <img
                                            src={
                                                eachImage.lowres?.url ||
                                                fallbackImage
                                            }
                                            style={{
                                                height: thumbImageHeight
                                            }}
                                        />
                                    </SwiperSlide>
                                );
                            })}
                    </Swiper>
                </ImageSection>
            ) : (
                <ImageSectionSmallScreen
                    windowDimensions={windowDimensions}
                    expandedSidebar={expandedSidebar}
                >
                    <Swiper
                        onSwiper={setSmallSizeSwiper}
                        onInit={(swiper) => setThumbsSwiper(null)}
                        // need to set the thumbs swiper to null. If not, it will reference to the wrong swiper
                        centeredSlides={true}
                        slidesPerView={2}
                        loop={true}
                        pagination={{
                            clickable: true
                        }}
                        spaceBetween={60}
                        className="mySwiper"
                        style={{
                            '--swiper-theme-color': '#19c0ff'
                        }}
                    >
                        {images &&
                            images.map((eachImage, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <Image
                                            src={
                                                eachImage.lowres?.url ||
                                                fallbackImage
                                            }
                                            style={{
                                                display: 'block',
                                                width: 350,
                                                height: 220,
                                                objectFit: 'cover'
                                            }}
                                        />
                                    </SwiperSlide>
                                );
                            })}
                    </Swiper>
                </ImageSectionSmallScreen>
            )}
            <DetailInformationSection
                className="aircraft-detail-information"
                expandedSidebar={expandedSidebar}
            >
                <PlaneNameSection>
                    <PlaneName>
                        <Text textStyle="heading3">
                            {name ? name.toUpperCase() : model.toUpperCase()}
                        </Text>
                    </PlaneName>
                    <PlaneCode>
                        <Text textStyle="jetCode">{tail_number}</Text>
                    </PlaneCode>
                </PlaneNameSection>
                <OperatedBySection style={{ display: 'flex' }}>
                    <OperatedBy>
                        <OperatedByText>
                            {t('OPERATED_BY').toUpperCase() + ' '}
                        </OperatedByText>
                        <Text textStyle="nameHighlight">
                            {operator_name
                                ? operator_name.toUpperCase()
                                : company && company.name
                                ? company.name.toUpperCase()
                                : ''}
                        </Text>
                    </OperatedBy>
                    {!_.isEmpty(home_airport) && (
                        <OperatedBy style={{ marginLeft: '40px' }}>
                            <OperatedByText>
                                {t('HOME_PAGE_AIRPORT').toUpperCase() + ' '}
                            </OperatedByText>
                            <Text textStyle="nameHighlight">
                                {home_airport?.icao_code?.toUpperCase() || '_'}
                            </Text>
                        </OperatedBy>
                    )}
                </OperatedBySection>
                {activeCompany?._id &&
                company?.id &&
                activeCompany._id === company.id ? (
                    <RateSection
                        className="aircraft-rate-section"
                        expandedSidebar={expandedSidebar}
                    >
                        <EachRateProperty>
                            <RateLabel>
                                <Text textStyle="fieldLabel">
                                    {t('HOURLY_RATES').toUpperCase()}
                                </Text>
                            </RateLabel>
                            <RateValue>
                                <Text textStyle="heading6">
                                    {numberFormat(
                                        revenue_flight_per_hour_fee,
                                        currency
                                    ) || '-'}
                                </Text>
                            </RateValue>
                        </EachRateProperty>
                        <EachRateProperty>
                            <RateLabel>
                                <Text textStyle="fieldLabel">
                                    {t('OVERNIGHT_FEES').toUpperCase()}
                                </Text>
                            </RateLabel>
                            <RateValue>
                                <Text textStyle="heading6">
                                    {numberFormat(
                                        overnight_stay_fee,
                                        currency
                                    ) || '-'}
                                </Text>
                            </RateValue>
                        </EachRateProperty>
                        <EachRateProperty>
                            <RateLabel>
                                <Text textStyle="fieldLabel">
                                    {t('DAILY_MINIMUM').toUpperCase()}
                                </Text>
                            </RateLabel>
                            <RateValue>
                                <Text textStyle="heading6">
                                    {minimum_booking_time_hours + 'HRS'}
                                </Text>
                            </RateValue>
                        </EachRateProperty>
                    </RateSection>
                ) : null}
                {from !== AIRCRAFT_DETAIL ? (
                    <Description>
                        {description || t('NO_DESCRIPTION')}
                    </Description>
                ) : null}
                <DetailPropertiesLine1>
                    <EachPropertySection
                        label={t('AIRCRAFT_TYPE')}
                        value={classtype ? t(classtype).toUpperCase() : '-'}
                    />
                    <EachPropertySection
                        label={t('YEAR')}
                        value={year_of_manufacture || '-'}
                    />
                    <EachPropertySection
                        label={t('LAST_REFURBISHED')}
                        value={last_refurbished_at?.substring(0, 4) || '-'}
                    />
                    <EachPropertySection
                        label={t('NUMBER_OF_SEATS')}
                        value={max_passengers || '-'}
                    />
                </DetailPropertiesLine1>
                <DetailPropertiesLine2>
                    {/* <EachProperty>
                        <PropertyLabel>
                            <Text textStyle="tableText1">
                                {t('CRUISE_SPEED')}
                            </Text>
                        </PropertyLabel>
                        <PropertyValue>
                            <Text textStyle="tableText2">
                                {cruising_speed_in_knots
                                    ? cruising_speed_in_knots
                                    : '-'}
                            </Text>
                        </PropertyValue>
                    </EachProperty> */}
                    <EachPropertySection
                        label={t('CRUISE_SPEED')}
                        value={
                            cruising_speed_in_knots
                                ? cruising_speed_in_knots
                                : '-'
                        }
                    />
                    <EachPropertySection
                        label={t('RANGE')}
                        value={max_range_in_nautical_miles || '-'}
                    />
                    <EachPropertySection
                        label={t('LUGGAGE_CAPACITY')}
                        value={luggage_capacity_ft3 || '-'}
                    />
                </DetailPropertiesLine2>
            </DetailInformationSection>
        </AircraftDetailCard>
    );
};

export default AircraftDetailCardComponent;

const AircraftDetailCard = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 10px;
    /* border: 1px solid #000; */
    max-width: 1520px;
    /* @media (max-width: 1830px) {
        padding-right: 10px;
        .aircraft-detail-information {
            max-width: ${(props) =>
        props.expandedSidebar ? '600px' : '700px'};
        }
    }
    @media (max-width: 1600px) {
        padding-right: 0;
        .aircraft-detail-information {
            max-width: ${(props) =>
        props.expandedSidebar ? '500px' : '700px'};
        }
    }
    @media (max-width: 1366px) {
        padding-right: 0;
        .aircraft-detail-information {
            ${(props) => props.expandedSidebar && 'width: 600px'};
            padding-bottom: 20px;
        }
    }
    @media (max-width: 1024px) {
        padding-right: 0;
        .aircraft-detail-information {
            padding-bottom: 20px;
            width: ${(props) => (props.expandedSidebar ? '600px' : '550px')};
        }
    }
    @media (max-width: 768px) {
        .aircraft-detail-information {
            padding-bottom: 20px;
            width: 650px;
        }
    } */
    @media (max-width: 1024px) {
        padding-right: 0;
    }
`;

const PropertyLabel = styled.div`
    height: 18px;
`;

const PropertyValue = styled.div`
    height: 18px;
`;

const EachProperty = styled.div`
    display: flex;
    flex-direction: column;
    width: 25%;
    max-width: 170px;
`;

const DetailPropertiesLine1 = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
`;

const DetailPropertiesLine2 = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
`;

const Description = styled.span`
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
    color: #65686f;
    font-family: Lato;
    font-size: 14px;
    line-height: 24px;
`;

const EachRateProperty = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: 40px;
    align-items: center;
`;

const RateValue = styled.div``;

const RateLabel = styled.div`
    margin-right: 5px;
`;

const RateSection = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    @media (max-width: 768px) {
        ${(props) => props.expandedSidebar && 'flex-wrap: wrap'};
    }
`;

const OperatedBy = styled.div``;
const OperatedBySection = styled.div`
    margin-top: 10px;
`;
const PlaneCode = styled.div`
    margin-left: 15px;
`;

const PlaneName = styled.div``;

const PlaneNameSection = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
`;

const ImageSection = styled.div`
    width: ${(props) => props.width || '18.3vw'};
    max-width: 350px;
    margin-right: 30px;
    
    @media (max-width: 768px) {
        ${(props) => props.expandedSidebar && 'max-width: 320px'};
    }

    
    .swiper {
        width: 100%;
        height: 300px;
        margin-left: auto;
        margin-right: auto;
    }

    .swiper-slide {
        background-size: cover;
        background-position: center;
        box-sizing: border-box;
    }

    .mySwiper2 {
        height: 72%;
        width: 100%;
        max-height: ${(props) => props.imageHeight || '220px'};
    }

    .mySwiper {
        height: 50%;
        max-height: 70px;
        box-sizing: border-box;
        /* padding: 10px 0; */
        margin-top: 10px;
    }

    .mySwiper .swiper-slide {
        width: 25%;
        height: ${(props) => props.thumbImageHeight || '70px'};
        opacity: 0.8;
    }

    .mySwiper .swiper-slide-thumb-active {
        opacity: 1;
        border: 1px solid #19c0ff;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 70px;
        object-fit: cover;
    }
    .swiper-button-next {
        background-color: #f0f3f9;
        height: 30px;
        width: 30px;
        border-radius: 100%;
        background-image: url(${IconForward});
    }
    .swiper-button-prev {
        background-color: #f0f3f9;
        height: 30px;
        width: 30px;
        border-radius: 100%;
        background-image: url(${IconBack});

    /* .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    } */

    /* .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
   
`;

const ImageSectionSmallScreen = styled.div`
    width: 100%;
    max-width: 686px;
    padding-right: 20px;
    height: 220px;
    /* margin-right: 30px; */

    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
    .mySwiper {
        height: 100%;
    }

    /* .swiper-slide {
        width: 80%;
    }

    .swiper-slide:nth-child(2n) {
        width: 60%;
    }

    .swiper-slide:nth-child(3n) {
        width: 40%;
    } */
`;

const DetailInformationSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 41.875vw;
    /* width: 60vw; */
    max-width: 700px;
    /* max-width: ${(props) => (props.expandedSidebar ? '600px' : '800px')}; */
    /* padding-left: 30px; */
    padding-right: min(30px, 3%);
    padding-bottom: 20px;
    @media (max-width: 1680px) {
        max-width: ${(props) => (props.expandedSidebar ? '550px' : '800px')};
    }
    @media (max-width: 1600px) {
        max-width: ${(props) => (props.expandedSidebar ? '490px' : '800px')};
    }
    @media (max-width: 1440px) {
        max-width: ${(props) => (props.expandedSidebar ? '550px' : '500px')};
    }
    @media (max-width: 1366px) {
        /* max-width: ${(props) =>
            props.expandedSidebar ? '450px' : '470px'}; */
        max-width: 470px;
    }

    @media (max-width: 1024px) {
        /* max-width: ${(props) =>
            props.expandedSidebar ? '450px' : '470px'}; */
        max-width: 430px;
    }
    @media (max-width: 768px) {
        /* max-width: ${(props) =>
            props.expandedSidebar ? '450px' : '470px'}; */
        max-width: 650px;
        width: 650px;
        padding-top: 20px;
    }
`;

const OperatedByText = styled.span`
    color: #8e929a;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
`;
