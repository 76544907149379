import React, { useState } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { Modal } from 'antd';
import { iconError, iconSucceed } from 'assets/images/icons';
import Button from 'components/Antd/Button/Button';

const CustomModal = (props) => {
    const {
        visibleModal,
        errorMessage,
        successText,
        buttonTitle,
        successSecondLine = '',
        cancelModal,
        onOk
    } = props;

    return (
        <Modal
            className="ant-modal-custom"
            visible={visibleModal}
            centered
            // onOk={() => {
            //     props.onOk();
            // }}
            onCancel={() => {
                cancelModal && cancelModal();
            }}
            footer={null}
        >
            {!errorMessage ? (
                <TextSection>
                    <SuccessIcon src={iconSucceed} />
                    <SuccessText>{successText}</SuccessText>
                    {successSecondLine && (
                        <SecondSuccessLine>
                            {successSecondLine}
                        </SecondSuccessLine>
                    )}
                </TextSection>
            ) : (
                <TextSection>
                    <SuccessIcon src={iconError} />
                    <SuccessText>{errorMessage.toUpperCase()}</SuccessText>
                </TextSection>
                
            )}

            <Button
                title={buttonTitle}
                backgroundColor={'#19C0FF'}
                textColor={'#FFFFFF'}
                fontSize={'13px'}
                onClick={() => {
                    onOk && onOk();
                }}
            />
        </Modal>
    );
};

const TextSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
`;

const SuccessIcon = styled.img`
    width: 64px;
    height: 64px;
    margin-bottom: 25px;
`;

const SuccessText = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 32px;
    text-align: center;
`;

const SecondSuccessLine = styled.span`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 15px;
    line-height: 26px;
`;

export default CustomModal;
