import React, { useEffect, useState } from 'react';
import { template as tmpl } from './FlightDetail.tmpl';
import {
    getBookingById,
    addCrew,
    removeCrew,
    addFbo,
    confirmBooking
} from 'redux/actions/bookingAction';
import { getFlightById, confirmFlightById } from 'redux/actions/flightAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BOOKING_STATUS, SHOW_LOADING, HIDE_LOADING } from 'redux/Constants';
import { FLIGHT_DETAIL, FLIGHT_LIST } from 'routes/main';

const FlightDetailLogic = (props) => {
    const {
        activeCompany,
        employees,
        location = {},
        addCrew,
        removeCrew,
        addFbo,
        confirmBooking
    } = props;
    const [bookingDetail, setBookingDetail] = useState({});
    const [flightDetail, setFlightDetail] = useState({});
    const [is_operator, setIs_operator] = useState(true);
    const [refreshPage, setRefreshPage] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { search } = location;

    const fetchFlightDetail = async () => {
        dispatch({ type: SHOW_LOADING });

        let id = search;
        if (id && id[0] === '?') {
            id = id.substring(1);
        }
        const result = await props.getFlightById(id);
        if (result.success && result.data) {
            setFlightDetail(result.data);
            const { _id = '' } = activeCompany;
            const { company_basic_info = {} } = result.data;
            if (_id === company_basic_info.company_id) {
                setIs_operator(false);
            } else {
                setIs_operator(true);
            }
        }

        setTimeout(() => {
            dispatch({ type: HIDE_LOADING });
        }, 450);
    };

    /**
     * Get flight detail from backend
     */
    useEffect(() => {
        fetchFlightDetail();
    }, [activeCompany, props.location.search]);

    useEffect(() => {
        if (refreshPage) {
            fetchFlightDetail();
            setRefreshPage(false);
        }
    }, [refreshPage]);

    const changeAvailable = async (id) => {
        let index = serviceList.findIndex((obj) => {
            return obj.id === id;
        });
        let tempServiceList = [...serviceList];
        tempServiceList[index].available = !tempServiceList[index].available;
        const result = await props.updateServiceItemById(
            id,
            tempServiceList[index]
        );
        if (result.isConnected) {
            setServiceList(tempServiceList);
        } else {
            //TODO: show dialog error when fail at calling API
        }
    };

    const onAddCrew = async (flightId, crew) => {
        if (crew && crew.user_id) {
            await addCrew(flightId, { user_id: crew.user_id, role: crew.role });
        }
    };

    const onRemoveCrew = async (flightId, crew) => {
        if (crew && crew.user_id) {
            let rs = await removeCrew(flightId, {
                user_id: crew.user_id
            });
            return rs;
        }
    };

    const onAddFBO = async (flightId, fboId, type) => {
        if (flightId && fboId) {
            let params = {};
            if (type === 'departure') {
                params.departure_fbo = fboId;
            } else {
                params.arrival_fbo = fboId;
            }
            await addFbo(flightId, params);
        }
    };

    const onConfirmFlight = async () => {
        dispatch({ type: SHOW_LOADING });
        let id = search;
        if (id && id[0] === '?') {
            id = id.substring(1);
        }
        const result = await props.confirmFlightById(id);
        let modal = {};
        if (result && result.success) {
            modal = {
                show: true,
                type: 'success',
                title: 'Flight has been confirmed!'.toUpperCase(),
                buttonTitle: 'View All Flights'.toUpperCase(),
                onOk: FLIGHT_LIST,
                style: { width: '250px' }
            };
            setRefreshPage(true);
        } else {
            modal = {
                show: true,
                type: 'error',
                // errors: null, // default null
                title: 'Error'.toUpperCase(),
                description: 'There is an error occured. Please try again',
                buttonTitle: 'View All Flights',
                style: { width: '250px' }
            };
        }
        dispatch({ type: HIDE_LOADING });
        dispatch({ type: 'SETTING_SHOW_MODAL', data: modal });
    };

    const navigate = (route, search, state) => {
        props.history.push({
            pathname: route,
            search: search,
            state: state ? { data: state } : null
        });
    };

    const initProps = {
        visibleModal: visibleModal,
        is_operator: is_operator,
        flightDetail: flightDetail,
        activeCompany: activeCompany,
        navigate: navigate,
        changeAvailable: changeAvailable,
        addCrew: onAddCrew,
        removeCrew: onRemoveCrew,
        onAddFBO: onAddFBO,
        onConfirmFlight: onConfirmFlight
    };
    return tmpl && tmpl({ ...initProps });
};

class FlightDetailContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <FlightDetailLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { employees, activeCompany } = companyReducer;
    return {
        employees: employees,
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getBookingById: (id) => dispatch(getBookingById(id)),
        addCrew: (id, data) => dispatch(addCrew(id, data)),
        removeCrew: (id, data) => dispatch(removeCrew(id, data)),
        addFbo: (flight_id, data) => dispatch(addFbo(flight_id, data)),
        confirmBooking: (bookingId, data) =>
            dispatch(confirmBooking(bookingId, data)),
        getFlightById: (id) => dispatch(getFlightById(id)),
        confirmFlightById: (id) => dispatch(confirmFlightById(id))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(FlightDetailContainer)
);
