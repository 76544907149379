import React, { useState } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { Col, Row, Input, Table } from 'antd';
import { EditOutlined, FileImageOutlined } from '@ant-design/icons';
import '../customer.css';

const Identification = (props) => {
    const {} = props;
    const { t, i18n } = useTranslation();

    return (
        <>
            <Container>
                <MenuTitle>{t('IDENTIFICATION').toUpperCase()}</MenuTitle>
                <Row className="identity-none">
                    <Col lg="12" sm="12" xs="12">
                        <InfoIcon>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 512 512"
                            >
                                <g className="nc-icon-wrapper" fill="#8e929a">
                                    <path d="M235.4 172.2c0-11.4 9.3-19.9 20.5-19.9 11.4 0 20.7 8.5 20.7 19.9s-9.3 20-20.7 20c-11.2 0-20.5-8.6-20.5-20zm1.4 35.7H275V352h-38.2V207.9z" />
                                    <path d="M256 76c48.1 0 93.3 18.7 127.3 52.7S436 207.9 436 256s-18.7 93.3-52.7 127.3S304.1 436 256 436c-48.1 0-93.3-18.7-127.3-52.7S76 304.1 76 256s18.7-93.3 52.7-127.3S207.9 76 256 76m0-28C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z" />
                                </g>
                            </svg>
                        </InfoIcon>

                        <Title style={{ textAlign: 'center' }}>
                            {t('EMPLOYEE_HAVE_NO_IDEN')}
                        </Title>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

const InfoIcon = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 13px;
`;

const Container = styled.div``;

const MenuTitle = styled.div`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.7px;
    line-height: 16px;
    text-align: left;
    margin-top: 35px;
    margin-bottom: 25px;
`;

const Title = styled.div`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 14px;
    /* line-height: 17px; */
    text-align: center;
`;

const UploadButton = styled.button`
    border: 1px solid rgba(18, 18, 22, 0.2);
    border-radius: 2px;
    width: 242px;
    height: 32px;
    background-color: transparent;
    margin-top: 17px;
`;

const ButtonText = styled.span`
    color: #16161b;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: center;
`;

export default Identification;
