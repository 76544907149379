import React from 'react';
import styled from 'styled-components';
import { Row, Col, Image } from 'antd';
import { Text } from 'components';
import { useTranslation } from 'react-i18next';
import { numberFormat } from 'utils';

const AircraftTypeCard = (props) => {
    const { t, i18n } = useTranslation();
    const { aircraft_categories = [] } = props;

    return (
        <AircraftTypeContainer>
            <Row style={{ paddingBottom: 20 }}>
                <Text textStyle="heading5">
                    {t('AIRCRAFT_TYPE_REQUEST').toUpperCase() +
                        ' (' +
                        aircraft_categories.length +
                        ')'}
                </Text>
            </Row>
            <AircraftList>
                {aircraft_categories.length > 0 &&
                    aircraft_categories.map((aircraft, index) => {
                        const { price_range = {} } = aircraft;

                        return (
                            <AircraftInfo key={index}>
                                <Image
                                    src={aircraft.image}
                                    width={345}
                                    height={221}
                                />
                                <AircraftDetailInfo>
                                    <Col>
                                        <Row style={{ marginBottom: 10 }}>
                                            <AircraftName>
                                                {aircraft.name || '-'}
                                            </AircraftName>
                                        </Row>
                                        <Row
                                            align="middle"
                                            style={{ marginBottom: 10 }}
                                        >
                                            <Text
                                                textStyle="tableText1"
                                                style={{ width: 100 }}
                                            >
                                                {t('NUMBER_OF_SEATS')}
                                            </Text>
                                            <Text textStyle="tableText2">
                                                {(aircraft.max_passengers ||
                                                    '-') +
                                                    ' ' +
                                                    t('PAX').toUpperCase()}
                                            </Text>
                                        </Row>
                                        <Row align="middle">
                                            <Text
                                                textStyle="tableText1"
                                                style={{ width: 100 }}
                                            >
                                                {t('PRICE_RANGE')}
                                            </Text>
                                            <Text textStyle="heading6">
                                                {numberFormat(
                                                    price_range?.min_price
                                                        ?.value
                                                ) +
                                                    ' - ' +
                                                    numberFormat(
                                                        price_range?.max_price
                                                            ?.value
                                                    )}
                                            </Text>
                                        </Row>
                                    </Col>
                                </AircraftDetailInfo>
                            </AircraftInfo>
                        );
                    })}
            </AircraftList>
        </AircraftTypeContainer>
    );
};

export default AircraftTypeCard;

const AircraftDetailInfo = styled(Row)`
    padding: 20px;
`;

const AircraftInfo = styled(Col)`
    background-color: #f7f8fb;
    border-radius: 4px;
    margin-right: 30px;
`;

const AircraftName = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 16px;
`;

const AircraftTypeContainer = styled.div`
    width: 100%;
`;

const AircraftList = styled(Row)`
    background-color: #ffffff;
    border-radius: 4px;
    padding: 20px;
`;
