import React from 'react';
import 'antd/dist/antd.css';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
    waitingIcon,
    cancelIcon,
    confirmedIcon
} from '../../assets/images/icons';
import { BOOKING_STATUS, QUOTE_REQUEST_STATUS } from '../../redux/Constants';

const BookingStatus = ({ type, status }) => {
    const { t, i18n } = useTranslation();

    let section = null;

    switch (type) {
        case 'BOOKING':
            switch (status) {
                case BOOKING_STATUS.AWAITING_CONFIRMATION:
                    section = (
                        <>
                            <Icon src={waitingIcon} />
                            <ItemText className="status">
                                {i18n.t(status)}
                            </ItemText>
                        </>
                    );
                    break;
                case BOOKING_STATUS.CANCELLED:
                    section = (
                        <>
                            <Icon src={cancelIcon} />
                            <ItemText className="status">
                                {i18n.t(status)}
                            </ItemText>
                        </>
                    );
                    break;
                case BOOKING_STATUS.CREATED:
                case BOOKING_STATUS.CONFIRMED:
                case BOOKING_STATUS.EXECUTED:
                    section = (
                        <>
                            <Icon src={confirmedIcon} />
                            <ItemText className="status">
                                {i18n.t(status)}
                            </ItemText>
                        </>
                    );
                    break;
            }
            break;
        case 'QUOTE_REQUEST':
            switch (status) {
                case QUOTE_REQUEST_STATUS.ACTIVE:
                    section = (
                        <>
                            <AwaitingStatus>
                                {i18n.t(status).toUpperCase()}
                            </AwaitingStatus>
                        </>
                    );
                    break;
                case QUOTE_REQUEST_STATUS.WAITING:
                    section = (
                        <>
                            <AwaitingStatus>
                                {i18n.t(status).toUpperCase()}
                            </AwaitingStatus>
                        </>
                    );
                    break;

                case QUOTE_REQUEST_STATUS.ACCEPTED:
                case QUOTE_REQUEST_STATUS.COMPLETED:
                    section = (
                        <>
                            <CompletedStatus>
                                {i18n.t(status).toUpperCase()}
                            </CompletedStatus>
                        </>
                    );
                    break;

                case QUOTE_REQUEST_STATUS.EXPIRED:
                case QUOTE_REQUEST_STATUS.REJECTED:
                    section = (
                        <>
                            <DeclineStatus>
                                {i18n.t(status).toUpperCase()}
                            </DeclineStatus>
                        </>
                    );
                    break;
            }
            break;
        default:
    }

    return <StatusContianer>{section}</StatusContianer>;
};

const StatusContianer = styled.div`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`;

const ItemText = styled.span`
    color: #35383f;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    align-self: center;
`;

const AwaitingStatus = styled.span`
    color: #f5a623;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    line-height: 12px;
`;

const DeclineStatus = styled.span`
    color: #dd4558;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    line-height: 12px;
`;

const CompletedStatus = styled.span`
    color: #7ed321;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    line-height: 12px;
`;

const Icon = styled.img``;

export default BookingStatus;
