import React, { useEffect, useState } from 'react';
import { template as tmpl } from './ServicesContainer.tmpl';
import {
    getServieItems,
    updateServiceItemById
} from '../../redux/actions/appActions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const ServicesContainerLogic = (props) => {
    const { activeCompany, employees } = props;
    const [originServiceList, setOriginServiceList] = useState([]);
    const [serviceList, setServiceList] = useState([]);

    /**
     * Get Service list from backend
     */
    useEffect(() => {
        async function fetchServices() {
            const result = await props.getServieItems();
            if (result.data) {
                setOriginServiceList(result.data);
                setServiceList(result.data);
            }
        }
        fetchServices();
    }, []);

    /**
     * Function handles searching when user is typing
     * @param {*} e event when user is typing
     */
    const onSearch = (e) => {
        const input = e.target.value.toLowerCase();
        const result = originServiceList.filter((item) => {
            if (item.name.toLowerCase().includes(input)) {
                return item;
            }
        });
        setServiceList(result);
    };

    const changeAvailable = async (id) => {
        let index = serviceList.findIndex((obj) => {
            return obj.id === id;
        });
        let tempServiceList = [...serviceList];
        tempServiceList[index].available = !tempServiceList[index].available;
        const result = await props.updateServiceItemById(
            id,
            tempServiceList[index]
        );
        if (result.isConnected) {
            setServiceList(tempServiceList);
        } else {
            //TODO: show dialog error when fail at calling API
        }
    };

    const navigate = (route, search, state) => {
        props.history.push({
            pathname: route,
            search: search,
            state: state ? { data: state } : null
        });
    };

    const initProps = {
        activeCompany: activeCompany,
        serviceList: serviceList,
        onSearch: onSearch,
        navigate: navigate,
        changeAvailable: changeAvailable
    };
    return tmpl && tmpl({ ...initProps });
};

class ServicesContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <ServicesContainerLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { employees, activeCompany } = companyReducer;
    return {
        employees: employees,
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getServieItems: () => dispatch(getServieItems()),
        updateServiceItemById: (id, data) =>
            dispatch(updateServiceItemById(id, data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesContainer);
