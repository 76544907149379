import React, { useState } from 'react';
import {
    Header,
    ButtonCustom,
    Text,
    SearchInput as SearchInputComponent,
    DropDownInput,
    Tag,
    Slider,
    AutoComplete
} from '../../components';
import {
    CloseOutlined,
    PlusOutlined,
    LoadingOutlined
} from '@ant-design/icons';
import {
    filterIcon,
    iconWifi,
    airplaneSeat,
    bathroomSink,
    iconLavatory,
    sleep,
    iconCancel
} from '../../assets/images/icons';
import { useTranslation } from 'react-i18next';
import { role, aircraftCategory, amenitiesList } from '../../config/Constants';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { AIRCRAFT_DETAIL, ADD_ARICRAFT } from '../../routes/main';
import AircraftDetailCardComponent from './components/AircraftDetailCard';
import { AircraftDetailComponent } from 'components/Commons';
import { isUserAdminOrOperator } from 'redux/selectors/user';
import { useSelector } from 'react-redux';
import {
    LoadMoreSection,
    ResultFound,
    TagSection,
    FeatureIcon,
    FeatureTitle,
    CreateQuoteTopLeft,
    CreateQuoteTitle,
    LeftSection,
    EachAmenities,
    FeaturedAmenitiesTitle,
    AircraftDetail,
    CreateQuoteTop,
    FeaturedAmenities,
    CreateQuoteSection,
    EachPlaneSection,
    ClearFilter,
    ClearFilterText,
    ApplyFilterButton,
    ByCategoryEachField,
    SliderField,
    ApplyFilter,
    HourlyRates,
    ByOperator,
    ByCategory,
    Container,
    SearchAndFilterSection,
    FilterDetailSection,
    FilterSection,
    SearchSection,
    SearchInput,
    SearchLabel,
    SearchInputSection,
    FilterButtonSection,
    FilterButton,
    FilterLabel,
    CancelIcon,
    AddNewAircraftSection
} from './style';

const template = (props) => {
    const {
        searchInfo = [],
        aircraftList = [],
        filterAircraft,
        activeCompany = {},
        expandFilter = false,
        expandFilterHandler,
        onCategoryAdd,
        pickedCategory = [],
        onCloseTag,
        onAfterChangeSlider,
        marks = {},
        onSearchOperator,
        onSearch,
        hourRate,
        onClickApplyFilter,
        onSelectOperator,
        applyFilter = false,
        onClickClearFilter,
        pickedOperator,
        onClickLoadMore,
        currentPage,
        totalPage,
        loading = false,
        searching = false,
        searchText = '',
        navigate,
        total = 0
    } = props;
    const { t, i18n } = useTranslation();

    const havePermission = useSelector(isUserAdminOrOperator());

    const createCategoryOption = () => {
        let tempArr = [];
        let key;
        for (key in aircraftCategory) {
            tempArr.push({
                text: t(aircraftCategory[key]),
                value: aircraftCategory[key]
            });
        }
        return tempArr;
    };

    return (
        <Container>
            <Header
                title={t('FLEET')}
                description={t('BROKER_CONNECTIONS')}
            />

            <SearchAndFilterSection>
                <SearchSection>
                    <SearchInputSection>
                        <SearchLabel>
                            <Text textStyle={'fieldLabel'}>
                                {t('SEARCH_KEYWORD').toUpperCase()}
                            </Text>
                        </SearchLabel>
                        <SearchInput>
                            <SearchInputComponent
                                placeholder={t('SEARCH_BROKER_CONNECTIONS')}
                                allowClear
                                value={searchText}
                                onSearch={(e) => onSearch(e)}
                                width={'370px'}
                                height={'44px'}
                            />
                        </SearchInput>
                    </SearchInputSection>
                    <FilterButtonSection>
                        <FilterLabel>
                            <Text textStyle={'fieldLabel'}>
                                {t('FILTER').toUpperCase()}
                            </Text>
                        </FilterLabel>
                        <FilterButton onClick={expandFilterHandler}>
                            <img src={filterIcon} />
                        </FilterButton>
                    </FilterButtonSection>
                </SearchSection>
            </SearchAndFilterSection>
            {/* Conditional based on filter button to open this section */}
            {expandFilter ? (
                <FilterDetailSection>
                    <FilterSection>
                        <ByCategory>
                            <ByCategoryEachField>
                                <Text textStyle={'heading6'}>
                                    {t('FILTER')}
                                </Text>
                            </ByCategoryEachField>
                            {searchInfo.length >= 1 ? (
                                <ByCategoryEachField style={{ maxWidth: 400 }}>
                                    {searchInfo.map((item, index) => {
                                        let label = '';
                                        switch (item.type) {
                                            case 'CATEGORY':
                                                label = t(item.value);
                                                break;
                                            case 'OPERATOR':
                                                label = item.value.name;
                                                break;
                                        }
                                        return (
                                            <TagSection key={index}>
                                                <Tag
                                                    label={label}
                                                    closable
                                                    onClose={() =>
                                                        onCloseTag(item)
                                                    }
                                                />
                                            </TagSection>
                                        );
                                    })}
                                </ByCategoryEachField>
                            ) : null}
                            <ByCategoryEachField>
                                <Text textStyle={'fieldLabel'}>
                                    {t('BY_CATEGORY').toUpperCase()}
                                </Text>
                            </ByCategoryEachField>
                            <ByCategoryEachField>
                                <DropDownInput
                                    placeholder={t(
                                        'SEARCH_AIRCRAFTS_AND_TAIL_NUMBER'
                                    )}
                                    data={createCategoryOption()}
                                    onChange={onCategoryAdd}
                                    pickedData={pickedCategory}
                                />
                            </ByCategoryEachField>
                        </ByCategory>
                        <ByOperator>
                            <ByCategoryEachField>
                                <Text textStyle={'fieldLabel'}>
                                    {t('BY_OPERATOR').toUpperCase()}
                                </Text>
                            </ByCategoryEachField>
                            <ByCategoryEachField>
                                <AutoComplete
                                    onSearchItems={onSearchOperator}
                                    onSelectItem={onSelectOperator}
                                    // defaultValue={pickedOperator}
                                />
                            </ByCategoryEachField>
                        </ByOperator>
                        <HourlyRates>
                            <ByCategoryEachField>
                                <Text textStyle={'fieldLabel'}>
                                    {t('HOUR_RATES').toUpperCase()}
                                </Text>
                            </ByCategoryEachField>
                            <SliderField>
                                <Slider
                                    range
                                    marks={marks}
                                    defaultValue={hourRate}
                                    onAfterChange={onAfterChangeSlider}
                                />
                            </SliderField>
                        </HourlyRates>
                        <ApplyFilter>
                            <ClearFilter onClick={onClickClearFilter}>
                                <ClearFilterText>
                                    {t('CLEAR_FILTER').toUpperCase()}
                                </ClearFilterText>
                                <CancelIcon src={iconCancel} />
                            </ClearFilter>
                            <ApplyFilterButton>
                                <ButtonCustom
                                    btnType="primary"
                                    width="190px"
                                    title={t('APPLY_FILTER').toUpperCase()}
                                    onClick={() => onClickApplyFilter()}
                                />
                            </ApplyFilterButton>
                        </ApplyFilter>
                    </FilterSection>
                </FilterDetailSection>
            ) : null}

            {applyFilter ? (
                <ResultFound>
                    <Text textStyle="heading3">
                        {aircraftList.length +
                            ' ' +
                            t('RESULT_FOUND').toUpperCase()}
                    </Text>
                </ResultFound>
            ) : null}

            <AddNewAircraftSection>
                <Text textStyle="heading5" style={{ fontSize: 20 }}>
                    {(
                        t('EXTERNAL_PARTNERS') +
                        ' (' +
                        total +
                        ')'
                    ).toUpperCase()}
                </Text>
                {havePermission ? (
                    <ButtonCustom
                        btnType="secondary"
                        width="190px"
                        onClick={() => navigate(ADD_ARICRAFT)}
                        title={t('ADD_NEW_AIRCRAFT').toUpperCase()}
                    />
                ) : null}
            </AddNewAircraftSection>

            {/* map each card for each plane here */}
            {aircraftList &&
                aircraftList.map((item, index) => {
                    return (
                        <AircraftDetailComponent
                            aircraft={item}
                            key={index}
                            navigate={navigate}
                            activeCompany={activeCompany}
                            detailPage={AIRCRAFT_DETAIL}
                        />
                    );
                })}
            {/* {!searching ? ( */}
            <LoadMoreSection>
                {currentPage < totalPage ? (
                    loading ? (
                        <LoadingOutlined
                            style={{ fontSize: '24px', color: '#19C0FF' }}
                        />
                    ) : (
                        <ButtonCustom
                            btnType="loadMore"
                            width="190px"
                            title={t('LOAD_MORE').toUpperCase()}
                            onClick={onClickLoadMore}
                        />
                    )
                ) : null}
            </LoadMoreSection>
            {/* ) : null} */}
        </Container>
    );
};

export { template };
