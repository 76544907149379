import React from 'react';
import { template as tmpl } from './InputComponent.tmpl';

const InputComponentLogic = (props) => {
    const initProps = props;
    return tmpl && tmpl({ ...initProps });
};

class InputComponent extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    render() {
        const initProps = {
            ...this.props
        };
        return <InputComponentLogic {...initProps} />;
    }
}

export { InputComponent };
