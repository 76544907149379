import moment from 'moment';
import { PAYMENT_STATUS } from 'redux/Constants';
import { imageIcon } from 'assets/images/icons';
import { aircraftCategory } from 'config/Constants';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const renderAddress = (address = {}) => {
    const { number, street, city, country } = address;
    let addressLine = `${number || ''} ${street ? street + ', ' : ''} ${
        city ? city + ', ' : ''
    } ${country && country.english_name ? country.english_name : ''}`;
    if (addressLine.trim() === '') {
        return '_';
    }
    return addressLine || '_';
};

const formatTimeBooking = (time) =>
    moment(time).format('hh:mm A, MMM DD, YYYY');

const formatDepartureTime = (date = '', time = '', utc = 0) => {
    let datetime = moment(`${date} ${time}`).format('MMM DD, YYYY hh:mm A ');
    let utcOffset =
        utc >= 0 ? '+' + Math.abs(utc) / 60 : '-' + Math.abs(utc) / 60;

    return `${datetime} UTC${utcOffset}`;
};

const numberFormat = (value, currency, maximumFractionDigits) => {
    try {
        let data = new Intl.NumberFormat('us-US', {
            style: 'currency',
            currency: currency || 'USD',
            maximumFractionDigits: maximumFractionDigits || undefined
        }).format(value);

        return data;
    } catch (e) {
        return value;
    }
};

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const decodeBase64 = (buffer) => {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);

    // var blob = new Blob([buffer], { type: 'application/octet-binary' });
    // var reader = new FileReader();
    // reader.onload = function (evt) {
    //     var dataurl = evt.target.result;
    //     return dataurl.substr(dataurl.indexOf(',') + 1);
    // };
    // reader.readAsDataURL(blob);
};

const validateNumber = (value) => {
    value += '';
    const list = value.split('.');
    const prefix = list[0].charAt(0) === '-' ? '-' : '';
    let num = prefix ? list[0].slice(1) : list[0];
    let result = '';
    while (num.length > 3) {
        result = `,${num.slice(-3)}${result}`;
        num = num.slice(0, num.length - 3);
    }
    if (num) {
        result = num + result;
    }
    return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
};

const formatHttpStr = (value = '') => {
    let url = value;
    try {
        let host = new URL(value).hostname;
        let pathname = new URL(value).pathname;
        url = host + pathname;
    } catch (e) {}

    return url;
};

const groupBy = (arr, property) => {
    return arr.reduce(function (memo, x) {
        if (!memo[x[property]]) {
            memo[x[property]] = [];
        }
        memo[x[property]].push(x);
        return memo;
    }, {});
};

function isJSON(str) {
    try {
        return JSON.parse(str);
    } catch (e) {
        return str;
    }
}

async function getStorageData(key) {
    try {
        const jsonValue = await localStorage.getItem(key);
        return jsonValue != null ? isJSON(jsonValue) : null;
    } catch (e) {
        //
    }
}

async function setStorageData(key, value) {
    try {
        const jsonValue = JSON.stringify(value);
        await localStorage.setItem(key, jsonValue);
    } catch (e) {
        // saving error
    }
}

async function removeStorageData(key) {
    try {
        await localStorage.removeItem(key);
    } catch (e) {
        //
    }
}

const getTitleWithFlightType = (type, index) => {
    let title = '';
    switch (type) {
        case 'ONEWAY':
            break;
        case 'RETURN':
            if (index === 0) {
                title = 'Departure';
            } else if (index === 1) {
                title = 'Return';
            }
            break;
        case 'MULTI_LEG':
            title = `Leg ${index + 1}`;
        default:
            break;
    }
    return title;
};

const getFlightName = (
    type = '',
    index = 0,
    fromAirport = {},
    toAirport = {}
) => {
    let flightType = getTitleWithFlightType(type, index);
    let flight = `${flightType ? flightType + ': ' : ''} ${
        fromAirport.name || ''
    } - ${toAirport.name || ''}`;
    return flight;
};

const getSummaryPriceWithFlights = (flights = []) => {
    let sum = 0;
    try {
        sum = flights.reduce(function (total, flight = {}) {
            let summary = 0;
            if (flight.price_components) {
                summary = total + flight.price_components.total_inc_vat;
            }
            return summary;
        }, 0);
    } catch (e) {}

    return sum;
};

const getTotalInvoice = (
    flightPrice = 0,
    services = [],
    surcharge = 0,
    extraFees = []
) => {
    let sum = flightPrice + surcharge;
    if (!_.isEmpty(services)) {
        const servicePrice = getServiceItemsFee(services);
        sum += servicePrice;
    }
    if (!_.isEmpty(extraFees)) {
        const totalExtraFees = getTotalExtraFee(extraFees);
        sum += totalExtraFees;
    }
    return sum;
};

const getSummaryTotalInvoice = (flightPrice = {}, services = []) => {
    const {
        revenue_fee = 0,
        booking_total_ex_vat = 0,
        booking_fee_percentage = 0,
        booking_fee_total = 0,
        service_items_fee = 0,
        tax_percentage = 0,
        non_member_surcharge = 0,
        extra_fees = []
    } = flightPrice;
    let newFLightPrice = flightPrice;
    newFLightPrice.booking_total_inc_vat =
        (booking_total_ex_vat * tax_percentage) / 100 + booking_total_ex_vat;
    newFLightPrice.booking_fee_total =
        (booking_total_ex_vat * booking_fee_percentage) / 100;

    let servicePrice = 0;
    if (!_.isEmpty(services)) {
        servicePrice = getServiceItemsFee(services);
    }
    newFLightPrice.service_items_fee = Math.floor(servicePrice);

    let totalExtraFees = 0;
    if (!_.isEmpty(extra_fees)) {
        totalExtraFees = getTotalExtraFee(extra_fees);
    }

    let totalExVAT =
        newFLightPrice.booking_total_ex_vat +
        newFLightPrice.service_items_fee +
        totalExtraFees +
        non_member_surcharge;
    let vat = (totalExVAT * tax_percentage) / 100;

    newFLightPrice.total_ex_vat = totalExVAT;
    newFLightPrice.total_vat = vat;
    newFLightPrice.total_inc_vat = totalExVAT + vat;

    return newFLightPrice;
};

const getTotalExtraFee = (extraFees = []) => {
    let fee = 0;
    if (!_.isEmpty(extraFees)) {
        let totalPrice = extraFees.reduce(function (total, extra = {}) {
            const { price = 0, quantity = 1 } = extra;
            let summary = 0;
            summary = total + price * quantity;
            return summary;
        }, 0);
        fee += totalPrice;
    }
    return fee;
};

const getServiceItemsFee = (services = []) => {
    let fee = 0;
    if (!_.isEmpty(services)) {
        let servicePrice = services.reduce(function (total, service = {}) {
            const { price = {}, quantity, status, type } = service;
            let summary = 0;

            if (type === 'CUSTOM' && status !== 'ACCEPTED') {
                return total;
            }
            summary = total + price.value * quantity;
            return summary;
        }, 0);
        fee += servicePrice;
    }
    return fee;
};

const getFlightTime = (times = 0) => {
    // times : flight_time_minutes
    let days = Math.floor(times / 60 / 24);
    let hoursLeft = Math.floor(times - days * 1440);
    let hours = Math.floor(hoursLeft / 60);
    let minutesLeft = Math.floor(hoursLeft - hours * 60);
    return {
        day: days,
        hour: hours,
        minute: minutesLeft
    };
};

const getPaymentStatus = (status = '') => {
    let value = status;
    if (value === PAYMENT_STATUS.CREATED) {
        value = PAYMENT_STATUS.AWAITING_PAYMENT;
    }
    return value;
};

const getLowresUrl = (image = {}, images = []) => {
    let url = imageIcon;
    try {
        if (!_.isEmpty(image)) {
            url = image?.lowres?.url || imageIcon;
        } else {
            url = images[0].lowres?.url || imageIcon;
        }
    } catch (e) {
        //
    }

    return url;
};

const createCategoryOption = () => {
    const { t, i18n } = useTranslation();
    let tempArr = [];
    let key;
    for (key in aircraftCategory) {
        tempArr.push({
            text: t(aircraftCategory[key]),
            value: aircraftCategory[key]
        });
    }
    return tempArr;
};

const createOperatorsOption = (companyList = []) => {
    const { t, i18n } = useTranslation();
    let tempArr = [
        {
            text: t('ALL_OPERATOR'),
            value: 'all_operator'
        }
    ];
    let key;
    for (key in companyList) {
        tempArr.push({
            text: companyList[key].name,
            value: companyList[key].id
        });
    }
    return tempArr;
};

const getBrowser = (version) => {
    const browserVersion = (userAgent, regex) => {
        return userAgent.match(regex) ? userAgent.match(regex)[2] : null;
    };

    const userAgent = navigator.userAgent;
    let browser = 'unkown';
    // Detect browser name
    browser = /ucbrowser/i.test(userAgent) ? 'UCBrowser' : browser;
    browser = /edg/i.test(userAgent) ? 'Edge' : browser;
    browser = /googlebot/i.test(userAgent) ? 'GoogleBot' : browser;
    browser = /chromium/i.test(userAgent) ? 'Chromium' : browser;
    browser =
        /firefox|fxios/i.test(userAgent) && !/seamonkey/i.test(userAgent)
            ? 'Firefox'
            : browser;
    browser =
        /; msie|trident/i.test(userAgent) && !/ucbrowser/i.test(userAgent)
            ? 'IE'
            : browser;
    browser =
        /chrome|crios/i.test(userAgent) &&
        !/opr|opera|chromium|edg|ucbrowser|googlebot/i.test(userAgent)
            ? 'Chrome'
            : browser;
    browser =
        /safari/i.test(userAgent) &&
        !/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i.test(
            userAgent
        )
            ? 'Safari'
            : browser;
    browser = /opr|opera/i.test(userAgent) ? 'Opera' : browser;

    if (!version) {
        return browser;
    }
    // detect browser version
    switch (browser) {
        case 'UCBrowser':
            return `${browser}/${browserVersion(
                userAgent,
                /(ucbrowser)\/([\d\.]+)/i
            )}`;
        case 'Edge':
            return `${browser}/${browserVersion(
                userAgent,
                /(edge|edga|edgios|edg)\/([\d\.]+)/i
            )}`;
        case 'GoogleBot':
            return `${browser}/${browserVersion(
                userAgent,
                /(googlebot)\/([\d\.]+)/i
            )}`;
        case 'Chromium':
            return `${browser}/${browserVersion(
                userAgent,
                /(chromium)\/([\d\.]+)/i
            )}`;
        case 'Firefox':
            return `${browser}/${browserVersion(
                userAgent,
                /(firefox|fxios)\/([\d\.]+)/i
            )}`;
        case 'Chrome':
            return `${browser}/${browserVersion(
                userAgent,
                /(chrome|crios)\/([\d\.]+)/i
            )}`;
        case 'Safari':
            return `${browser}/${browserVersion(
                userAgent,
                /(safari)\/([\d\.]+)/i
            )}`;
        case 'Opera':
            return `${browser}/${browserVersion(
                userAgent,
                /(opera|opr)\/([\d\.]+)/i
            )}`;
        case 'IE':
            const version = browserVersion(userAgent, /(trident)\/([\d\.]+)/i);
            // IE version is mapped using trident version
            // IE/8.0 = Trident/4.0, IE/9.0 = Trident/5.0
            return version
                ? `${browser}/${parseFloat(version) + 4.0}`
                : `${browser}/7.0`;
        default:
            return `unknown/0.0.0.0`;
    }
};

const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        }
    );
};

const validateEmailFormat = (email) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
};

const formatFonts = (font) => {

    const { family, variants } = font;
    
    const tempVariants = {
        regular : variants.find(v => v === 'regular')
    };

    const medium = variants.find(v => v === '500');
    tempVariants.medium = medium ? medium : tempVariants.regular;
    const demi_bold = variants.find(v => v === '600');
    tempVariants.demi_bold = demi_bold ? demi_bold : tempVariants.medium;
    const bold = variants.find(v => v === '700');
    tempVariants.bold = bold ? bold : tempVariants.demi_bold;
    return {
        family,
        variants: tempVariants
    };     
}

export {
    formatFonts,
    getWindowDimensions,
    validateEmailFormat,
    createOperatorsOption,
    createCategoryOption,
    removeStorageData,
    isJSON,
    getStorageData,
    setStorageData,
    groupBy,
    renderAddress,
    formatTimeBooking,
    formatDepartureTime,
    numberFormat,
    getBase64,
    validateNumber,
    decodeBase64,
    formatHttpStr,
    getTitleWithFlightType,
    getFlightName,
    getSummaryPriceWithFlights,
    getTotalInvoice,
    getServiceItemsFee,
    getFlightTime,
    getPaymentStatus,
    getLowresUrl,
    getSummaryTotalInvoice,
    getBrowser,
    generateUUID
};
