import React from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { removeIcon } from '../../assets/images/icons';

const CountryItem = ({ val = {}, onRemove, isFinal = false }) => {
    const { english_name = '', short_code = '' } = val;
    const flagImg = require(`../../assets/images/flags/${short_code.toLowerCase()}.png`);

    return (
        <CountryContainer isFinal={isFinal}>
            <Flags src={flagImg.default} width="25" height="18" />
            <CountryName>{english_name.toUpperCase()}</CountryName>
            <Button onClick={() => onRemove(val)}>
                <Text>REMOVE</Text>
                <Icon src={removeIcon} />
            </Button>
        </CountryContainer>
    );
};

export default CountryItem;

const Flags = styled.img``;

const Icon = styled.img``;

const Button = styled.button`
    position: absolute;
    right: 25px;
    border: none;
    background-color: transparent;
`;

const CountryContainer = styled.div`
    width: 95%;
    position: relative;
    border-bottom: ${(props) =>
        !props.isFinal ? '1px solid rgba(14, 14, 18, 0.08)' : 'none'};
    padding-bottom: 12px;
    margin-top: 15px;
    margin-bottom: 5px;
`;

const CountryName = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    line-height: 12px;
    width: 205px;
    text-align: left;
    margin-left: 12px;
`;

const Text = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 18px;
    text-align: left;
    margin-right: 15px;
`;
