import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Text } from 'components';
import { useTranslation } from 'react-i18next';
import {
    numberFormat,
    getTitleWithFlightType,
    getSummaryPriceWithFlights,
    getLowresUrl
} from 'utils';
import { Row, Col } from 'antd';
import { IconLuggage, removeIcon, editIcon } from 'assets/images/icons';
import ModalInvoice from './ModalInvoice';

const editInitState = {
    show: false,
    leg: {},
    legIndex: 0,
    fromAirport: {},
    toAirport: {}
};

const PackageCard = (props) => {
    const { quoteId, legs = [], packagee = [], index, type, onUpdatePackage, onRemove } = props;
    const { t, i18n } = useTranslation();
    const [editing, setEditing] = useState(editInitState);

    const onClickSave = (legItem) => {
        let pks = packagee;
        pks[editing.legIndex] = legItem;

        onUpdatePackage(pks, editing.legIndex);
        setEditing(editInitState);
    };

    const onCancelModal = () => {
        setEditing(editInitState);
    };

    const onCLickOpenModal = (item = {}, index) => {
        const { leg_id } = item;

        let fromAirport = {};
        let toAirport = {};
        let leg = legs.find((val) => val.id === leg_id);
        if (leg) {
            fromAirport = leg.from_airport_basic_info;
            toAirport = leg.to_airport_basic_info;
        }

        setEditing({
            show: true,
            leg: item,
            legIndex: index,
            fromAirport: fromAirport,
            toAirport: toAirport
        });
    };

    return (
        <Container>
            <RowTitle>
                <Col
                    span={12}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around'
                    }}
                >
                    <WrapperItem>
                        <IconLuggage
                            style={{
                                width: 22,
                                height: 18,
                                marginLeft: 10,
                                marginRight: 10
                            }}
                        />
                        <Package>{`Package ${index + 1}`}</Package>
                    </WrapperItem>
                </Col>
                <Col
                    span={12}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        justifyContent: 'center'
                    }}
                >
                    <SummaryPrice style={{ marginRight: 15 }}>
                        {numberFormat(
                            getSummaryPriceWithFlights(packagee),
                            'USD',
                            1
                        )}
                    </SummaryPrice>
                </Col>
            </RowTitle>
            <Wrapper>
                <Row>
                    {packagee &&
                        packagee.map((item = {}, i) => {
                            const {
                                id,
                                leg_id,
                                aircraft = {},
                                price_components = {}
                            } = item;
                            const {
                                image = {},
                                images = [],
                                name = '',
                                tail_number = '',
                                operator_name = ''
                            } = aircraft;
                            const { total_inc_vat = 0 } = price_components;
                            return (
                                <Col span={24} key={i}>
                                    <ItemWrapper>
                                        <RowItem
                                            style={{
                                                marginBottom: 8,
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <FlightType>
                                                {`${getTitleWithFlightType(
                                                    type,
                                                    i
                                                ).toUpperCase()}`}
                                            </FlightType>
                                            <WrapIcon
                                                onClick={() =>
                                                    onCLickOpenModal(item, i)
                                                }
                                            >
                                                <Icon
                                                    style={{
                                                        width: '18px',
                                                        height: '18px'
                                                    }}
                                                    src={editIcon}
                                                />
                                            </WrapIcon>
                                        </RowItem>
                                        <RowItem key={i}>
                                            <Col span={20}>
                                                <div
                                                    style={{ display: 'flex' }}
                                                >
                                                    <Image
                                                        src={getLowresUrl(
                                                            image,
                                                            images
                                                        )}
                                                    />
                                                    <div
                                                        style={{
                                                            marginLeft: 10
                                                        }}
                                                    >
                                                        <div>
                                                            <Package>
                                                                {name || '_'}
                                                            </Package>
                                                            <TailorCode>
                                                                {tail_number
                                                                    ? tail_number.toUpperCase()
                                                                    : '_'}
                                                            </TailorCode>
                                                        </div>
                                                        <div>
                                                            <Operator color="#8e929a">
                                                                {t(
                                                                    'OPERATOR_BY'
                                                                )}
                                                            </Operator>
                                                            <Operator>
                                                                {operator_name}
                                                            </Operator>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col span={4}>
                                                <PriceWrap>
                                                    <Text
                                                        style={{
                                                            textAlign: 'right'
                                                        }}
                                                        textStyle="infoNormal"
                                                    >
                                                        {t(
                                                            'TOTAL_PRICE'
                                                        ).toUpperCase()}
                                                    </Text>
                                                    <Price>
                                                        {numberFormat(
                                                            total_inc_vat,
                                                            'USD',
                                                            1
                                                        )}
                                                    </Price>
                                                </PriceWrap>
                                            </Col>
                                        </RowItem>
                                    </ItemWrapper>
                                </Col>
                            );
                        })}
                </Row>
            </Wrapper>

            <RemoveButton onClick={() => onRemove && onRemove(index)}>
                <Icon src={removeIcon} />
            </RemoveButton>

            {/* modal edit flight */}
            <ModalInvoice
                quoteId={quoteId}
                show={editing.show}
                leg={editing.leg}
                fromAirport={editing.fromAirport}
                toAirport={editing.toAirport}
                onCancel={onCancelModal}
                onClickSave={onClickSave}
            />
        </Container>
    );
};

export default PackageCard;

const RemoveButton = styled.button`
    border: none;
    background-color: transparent;
    position: absolute;
    right: -20px;
    top: -20px;
`;

const WrapIcon = styled.button`
    border: none;
    background-color: transparent;
`;

const Icon = styled.img`
    width: 32px;
    height: 32px;
`;

const Container = styled.div`
    position: relative;
    width: 95%;
`;

const Image = styled.img`
    background-size: 100%;
    border-radius: 2px;
    width: 105px;
    height: 65px;
`;

const RowTitle = styled(Row)`
    background-color: #f8f9fe;
    border-left-color: #19c0ff;
    border-left-width: 2px;
    border-radius: 2px 2px 0 0;
`;

const ItemWrapper = styled.div`
    padding: 15px;
`;

const Wrapper = styled.div`
    background-color: #fff;
`;

const RowItem = styled(Row)``;

const WrapperItem = styled.div`
    display: flex;
    padding: 10px;
    align-items: center;
`;

const SummaryPrice = styled.span`
    color: #121216;
    font-family: AvenirNext-Bold;
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 24px;
    text-align: right;
`;

const Package = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
`;

const FlightType = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 18px;
    text-align: left;
`;

const PriceWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

const Price = styled.div`
    color: #121216;
    font-family: AvenirNext-Bold;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 18px;
    width: 87px;
    text-align: right;
    margin-top: 15px;
`;

const Operator = styled.span`
    color: ${(props) => props.color || '#19c0ff'};
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    width: 174px;
`;

const TailorCode = styled.span`
    color: #19c0ff;
    font-family: AvenirNext-Bold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: center;
    margin-left: 10px;
`;
