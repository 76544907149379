import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { Text } from '../../../components';
import { Carousel } from 'react-responsive-carousel';
import { numberFormat } from '../../../utils';
import { AIRCRAFT_DETAIL } from '../../../routes/main';
import {
    DescriptionDetail,
    DescriptionTitle,
    DescriptionSection,
    Column,
    PropertyLabelSection,
    Property,
    Value
} from '../style';
/**
 *
 * @param {*} props
 * @param {*} textStyle fieldLabel
 * @returns
 */
const Properties = (props) => {
    const { t, i18n } = useTranslation();
    const {
        label,
        firstColumn = [],
        secondColumn = [],
        thirdColumn = [],
        isEdit = false
    } = props;

    return (
        <DescriptionSection>
            {label && (
                <DescriptionTitle>
                    <Text textStyle="heading6">{label}</Text>
                </DescriptionTitle>
            )}
            <DescriptionDetail>
                <Column>
                    <Property>
                        {firstColumn.length > 0
                            ? firstColumn.map((item, index) => (
                                  <PropertyLabelSection
                                      isEdit={isEdit}
                                      key={index}
                                  >
                                      <Text textStyle="infoNormal">
                                          {item.label}
                                      </Text>
                                  </PropertyLabelSection>
                              ))
                            : null}
                    </Property>

                    <Value>
                        {firstColumn.length > 0
                            ? firstColumn.map((item, index) => (
                                  <PropertyLabelSection key={index}>
                                      {isEdit ? (
                                          <InputStyled
                                              value={
                                                  item.value === '-'
                                                      ? ''
                                                      : item.value
                                              }
                                              onChange={(e) => {
                                                  item.onChange(e.target.value);
                                              }}
                                          />
                                      ) : (
                                          <Text textStyle="tableText2">
                                              {item.value +
                                                  ' ' +
                                                  (item.unit || '')}
                                          </Text>
                                      )}
                                  </PropertyLabelSection>
                              ))
                            : null}
                    </Value>
                </Column>
                <Column>
                    <Property>
                        {secondColumn.length > 0
                            ? secondColumn.map((item, index) => (
                                  <PropertyLabelSection
                                      isEdit={isEdit}
                                      key={index}
                                  >
                                      <Text textStyle="infoNormal">
                                          {item.label}
                                      </Text>
                                  </PropertyLabelSection>
                              ))
                            : null}
                    </Property>
                    <Value>
                        {secondColumn.length > 0
                            ? secondColumn.map((item, index) => (
                                  <PropertyLabelSection key={index}>
                                      {isEdit ? (
                                          <InputStyled
                                              value={
                                                  item.value === '-'
                                                      ? ''
                                                      : item.value
                                              }
                                              onChange={(e) => {
                                                  item.onChange(e.target.value);
                                              }}
                                          />
                                      ) : (
                                          <Text textStyle="tableText2">
                                              {item.value +
                                                  ' ' +
                                                  (item.unit || '')}
                                          </Text>
                                      )}
                                  </PropertyLabelSection>
                              ))
                            : null}
                    </Value>
                </Column>
                <Column>
                    <Property>
                        {thirdColumn.length > 0
                            ? thirdColumn.map((item, index) => (
                                  <PropertyLabelSection
                                      isEdit={isEdit}
                                      key={index}
                                  >
                                      <Text textStyle="infoNormal">
                                          {item.label}
                                      </Text>
                                  </PropertyLabelSection>
                              ))
                            : null}
                    </Property>
                    <Value>
                        {thirdColumn.length > 0
                            ? thirdColumn.map((item, index) => (
                                  <PropertyLabelSection key={index}>
                                      {isEdit ? (
                                          <InputStyled
                                              value={
                                                  item.value === '-'
                                                      ? ''
                                                      : item.value
                                              }
                                              onChange={(e) => {
                                                  item.onChange(e.target.value);
                                              }}
                                          />
                                      ) : (
                                          <Text textStyle="tableText2">
                                              {item.value +
                                                  ' ' +
                                                  (item.unit || '')}
                                          </Text>
                                      )}
                                  </PropertyLabelSection>
                              ))
                            : null}
                    </Value>
                </Column>
            </DescriptionDetail>
        </DescriptionSection>
    );
};

export default Properties;

const InputStyled = styled(Input)`
    border: 1px solid rgba(36, 36, 45, 0.15);
    border-radius: 3px;
    width: 220px;
    height: 50px;
`;
