// action
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const SESSION_ID = 'SESSION_ID';
export const FCM_TOKEN = 'FCM_TOKEN';

// reducer
export const UPDATE_USER_INFORMATION = 'UPDATE_USER_INFORMATION';
export const UPDATE_USER_COMPANY_LIST = 'UPDATE_USER_COMPANY_LIST';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const UPDATE_ACTIVE_COMPANY_IMAGES = 'UPDATE_ACTIVE_COMPANY_IMAGES';
export const UPDATE_ACTIVE_COMPANY = 'UPDATE_ACTIVE_COMPANY';
export const UPDATE_EMPLOYEES_LIST = 'UPDATE_EMPLOYEES_LIST';
export const UPDATE_CUSTOMERS_LIST = 'UPDATE_CUSTOMERS_LIST';
export const UPDATE_COUNTRIES = 'UPDATE_COUNTRIES';
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const RECEIVED_NOTIFICATION = 'RECEIVED_NOTIFICATION';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';
export const READ_ALL_NOTIFICATION = 'READ_ALL_NOTIFICATION';
export const FETCH_MORE_NOTIFICATIONS = 'FETCH_MORE_NOTIFICATIONS';
export const CLEAR_DATA_APP = 'CLEAR_DATA_APP';
export const CLEAR_DATA_USER = 'CLEAR_DATA_USER';
export const CLEAR_DATA_COMPANY = 'CLEAR_DATA_COMPANY';
export const INDENTIFY_TOKEN = 'INDENTIFY_TOKEN';
export const SIGN_IN = 'SIGN_IN';
export const USER_INFO = 'USER_INFO';
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

export const SETTING_SHOW_MODAL = 'SETTING_SHOW_MODAL';
export const SETTING_HIDE_MODAL = 'SETTING_HIDE_MODAL';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export const UPDATE_PROMOTIONS_CURRENT_TAB = 'UPDATE_PROMOTIONS_CURRENT_TAB';

export const CREATE_REQUEST_QUOTE = 'CREATE_REQUEST_QUOTE';
export const UPDATE_QUOTE_SELECTED_AIRCRAFT = 'UPDATE_QUOTE_SELECTED_AIRCRAFT';
export const UPDATE_QUOTE_CUSTOM_SERVICE = 'UPDATE_QUOTE_CUSTOM_SERVICE';
export const UPDATE_QUOTE_PRICE_SELECTED_AIRCRAFT =
    'UPDATE_QUOTE_PRICE_SELECTED_AIRCRAFT';
export const CREATE_QUOTE_PACKAGES = 'CREATE_QUOTE_PACKAGES';
export const UPDATE_QUOTE_PACKAGES = 'UPDATE_QUOTE_PACKAGES';
export const DELETE_QUOTE_PACKAGES = 'DELETE_QUOTE_PACKAGES';
export const UPDATE_QUOTE_PRICE_PACKAGES = 'UPDATE_QUOTE_PRICE_PACKAGES';
export const UPDATE_QUOTE_STEP = 'UPDATE_QUOTE_STEP';

// errors
export const USER_EXISTS = 'USER_EXISTS';
export const EMAIL_EXISTS = 'EMAIL_EXISTS';
export const UNEXPECTED_TYPE = 'UNEXPECTED_TYPE';
export const NOT_LOGGED_IN = 'NOT_LOGGED_IN';

export const COMPANY_TYPE = {
    BROKER: 'BROKER',
    OPERATOR: 'OPERATOR',
    HANDLER: 'HANDLER',
    FBO: 'FBO'
};

export const HANDLER_TYPE = {
    FBO: 'FBO',
    FUEL_ONLY: 'FUEL_ONLY',
    SERVICE: 'SERVICE'
};

export const USER_TYPE = {
    CUSTOMER: 'CUSTOMER',
    EMPLOYEE: 'EMPLOYEE'
};

export const SOCKET_EVENT = {
    CONNECT: 'connect',
    DISCONNECT: 'disconnect',
    ON_MESSAGE: 'on_message',
    ERROR: 'error'
};

export const SOCKET_MESSAGE_TYPE = {
    NEW_EMPLOYEE: 'NEW_EMPLOYEE',
    NEW_CUSTOMER: 'NEW_CUSTOMER'
};

export const PARTNERSHIP_TYPE = {
    CONNECTED: 'CONNECTED',
    PENDING: 'PENDING',
    NEW: 'NEW'
};

export const UPLOAD_MODEL = {
    COMPANY: 'companies',
    HANDLER: 'handlers',
    PRODUCT: 'products',
    WHITELABEL: 'whitelabels',
    SCHEDULE: 'schedules',
    ROUTE: 'routes',
    TOP_RATE_AIRPORT: 'top_rated_airports',
    MEMBERSHIP: 'memberships',
    USER: 'users',
    PACKAGE: 'packages',
    BOOKING: 'bookings',
    NEWS: 'news',
    CONVERSATION: 'conversations',
    AIRCRAFT: 'aircrafts',
    WHITELABEL: 'whitelabels'
};

export const UPLOAD_IMAGE_TYPE = {
    MAIN: 'main',
    ICON: 'icons',
    GALLERY: 'gallery',
    BACKGROUND: 'background',
    GENERAL: 'general',
    LEGAL: 'legal',
    TERM_AND_CONDITIONS: 'terms_and_conditions',
    CHARTER_AGREEMENTS: 'charter_agreements'
};

export const SELECT_TYPE = {
    COUNTRY: 'COUNTRY',
    AIRCRAFT: 'AIRCRAFT',
    AIRPORT: 'AIRPORT',
    COMPANY: 'COMPANY'
};

// Booking Status
export const BOOKING_STATUS = {
    CREATED: 'CREATED',
    AWAITING_CONFIRMATION: 'AWAITING_CONFIRMATION',
    CONFIRMED: 'CONFIRMED',
    CANCELLED: 'CANCELLED',
    EXECUTED: 'EXECUTED'
};

// Payment Status
export const PAYMENT_STATUS = {
    CREATED: 'CREATED',
    AWAITING_PAYMENT: 'AWAITING_PAYMENT',
    AWAITING_CONFIRMATION: 'AWAITING_CONFIRMATION',
    AWAITING_REFUND: 'AWAITING_REFUND',
    PAID: 'PAID',
    CANCELLED: 'CANCELLED'
};

// Mark As Paid Type
export const MARK_AS_PAID_TYPE = {
    DIRECT_TRANSFER: 'DIRECT_TRANSFER',
    CASH: 'CASH',
    MEMBERSHIP_ACCOUNT: 'MEMBERSHIP_ACCOUNT',
    OTHER: 'OTHER'
};

// Invoice Status
export const INVOICE_STATUS = {
    CREATED: 'CREATED',
    AWAITING_PAYMENT: 'AWAITING_PAYMENT',
    PAID: 'PAID',
    AWAITING_REFUND: 'AWAITING_REFUND',
    CANCELLED: 'CANCELLED'
};

export const BOOKING_TYPE = {
    SHARED: 'SHARED',
    PRIVATE: 'PRIVATE',
    MULTILEG: 'MULTILEG',
    RETURN: 'RETURN',
    ONEWAY: 'ONEWAY'
};

export const INVOICE_PAYMENT_STATUS = {
    CREATED: 'CREATED',
    PAID: 'PAID',
    AWAITING_PAYMENT: 'AWAITING_PAYMENT',
    AWAITING_CONFIRMATION: 'AWAITING_CONFIRMATION'
};

export const FLIGHT_STATUS = {
    CREATED: 'CREATED',
    AWAITING_CONFIRMATION: 'AWAITING_CONFIRMATION',
    CONFIRMED: 'CONFIRMED',
    CANCELLED: 'CANCELLED',
    EXECUTED: 'EXECUTED'
};

export const QUOTE_REQUEST_STATUS = {
    ALL: 'ALL',
    ACTIVE: 'ACTIVE', // New Quote requests
    WAITING: 'WAITING', //Awaiting customer response
    ACCEPTED: 'ACCEPTED', // Quote accepted
    REJECTED: 'REJECTED', //Declined
    EXPIRED: 'EXPIRED', // put it in Declined
    COMPLETED: 'COMPLETED', // Put it in Quote accepted,
    CANCELLED: 'CANCELLED'
};

export const QUOTE_REQUEST_SERVICE_ITEM_TYPE = {
    STANDARD: 'STANDARD',
    CUSTOM: 'CUSTOM'
};

export const QUOTE_REQUEST_SERVICE_ITEM_STATUS = {
    WAITING: 'WAITING',
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED'
};

export const QUOTE_REQUEST_UPDATE_TYPE = {
    AIRCRAFT: 'AIRCRAFT',
    SERVICES: 'SERVICES',
    NOTE: 'NOTE',
    STATUS: 'STATUS'
};

export const CREW_ROLE = {
    PILOT: 'PILOT',
    COPILOT: 'COPILOT',
    HOST: 'HOST'
};

export const INVOICE_TYPE = {
    PARTNER: 'partner',
    CUSTOMER: 'customer'
};
