import React, { useEffect, useState } from 'react';
import { template as tmpl } from './TopDestinationList.tmpl';
import { getTopDestinations } from 'redux/actions/flightAction';
import { connect } from 'react-redux';
import { updateTopDestination } from 'redux/actions/airportAction';

const TopDestinationsLogic = (props) => {
    const { activeCompany, getTopDestinations, navigate, updateTopDestination } = props;
    const [originTopDestinations, setOriginTopDestinations] = useState([]);
    const [topDestinations, setTopDestinations] = useState([]);
    const [total, setTotal] = useState(0); 

    /**
     * Get Top Destinations
     */
    useEffect(() => {
        async function fetchTopAirports() {
            const result = await getTopDestinations();
            if (result) {
                setOriginTopDestinations(result.docs || []);
                setTopDestinations(result.docs || []);
                setTotal(result.total || 0);
            }
        }
        fetchTopAirports();
    }, []);

    /**
     * Function handles searching when user is typing
     * @param {*} e event when user is typing
     */
    const onSearch = (e) => {
        const input = e.target.value.toLowerCase();
        const result = originTopDestinations.filter((item = {}) => {
            const { to_airport = {} } = item;
            const { iata_code = '', icao_code = '', name = '', location = {} } = to_airport;
            const { city = '', country = {} } = location;
            if ((iata_code.toLowerCase().includes(input)) ||
                (icao_code.toLowerCase().includes(input)) || 
                (name.toLowerCase().includes(input)) ||
                (city.toLowerCase().includes(input)) || 
                (country?.english_name?.toLowerCase().includes(input))
            ) {
                return item;
            }
        });
        setTopDestinations([ ...result ]);
    };

    const changeDisplay = async (data) => {
        let item = {
            ...data,
            enable: !data.enable
        }
        const result = await updateTopDestination(data.id, item);
        if (result.success) {
            // update originTopDestinations
            let index = originTopDestinations.findIndex(item => item.id === data.id);
            if (index > -1) {
                let newOrigins = originTopDestinations;
                newOrigins[index] = item;
                setOriginTopDestinations([ ...newOrigins ]); 
            }

            // update topDestinations
            let index2 = topDestinations.findIndex(item => item.id === data.id);
            if (index2 > -1) {
                let newAirports = topDestinations;
                newAirports[index2] = item;
                setTopDestinations([ ...newAirports ]);
            }
        }
    };

    const initProps = {
        activeCompany: activeCompany,
        topDestinations: topDestinations,
        onSearch: onSearch,
        navigate: navigate,
        changeDisplay: changeDisplay,
        total: total
    };
    return tmpl && tmpl({ ...initProps });
};

class TopDestinationsContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <TopDestinationsLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { employees, activeCompany } = companyReducer;
    return {
        employees: employees,
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTopDestinations: () => dispatch(getTopDestinations()),
        updateTopDestination: (id, data) =>
            dispatch(updateTopDestination(id, data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopDestinationsContainer);
