import React, { useState, useEffect } from 'react';
import { template as tmpl } from './ServicesDetailContainer.tmpl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    updateServiceItemById,
    getServieItemById,
    searchCountries
} from '../../../redux/actions/appActions';
import { searchAircrafts } from '../../../redux/actions/aircarftAction';
import { useTranslation } from 'react-i18next';

const ServicesDetailLogic = (props) => {
    const {
        getServieItemById,
        updateServiceItemById,
        location,
        searchCountries,
        searchAircrafts
    } = props;
    const { search } = location;

    const { t, i18n } = useTranslation();

    const [service, setService] = useState({});
    const [successMsg, setSuccessMsg] = useState('');
    const [visibleModal, setVisibleModal] = useState(false);

    useEffect(() => {
        const initDetail = async () => {
            let id = search;
            if (id && id[0] === '?') {
                id = id.substring(1);
            }

            const rs = await getServieItemById(id);
            if (rs && rs.success) {
                setService(rs.data);
            }
        };

        // init customer detail
        initDetail();
    }, []);

    const navigate = (route) => {
        props.history.push(route);
    };

    const onSubmit = async (data) => {
        const rs = await updateServiceItemById(service.id, data);
        if (rs && rs.isConnected) {
            // create modal success or Error
            setVisibleModal(true);
            setSuccessMsg(t('SERVICE_SUCCESSFULLY_UPDATED').toUpperCase());
        }
    };

    const onSearchCountries = async (key) => {
        const rs = await searchCountries(key);
        if (rs && rs.success) {
            return rs.countries || [];
        }
    };

    const onSearchAircrafts = async (key) => {
        const rs = await searchAircrafts(key);
        if (rs && rs.success) {
            return rs.aircrafts || [];
        }
    };

    const onChangeAvailability = async () => {
        let newService = service || {};
        newService.available = !newService.available;
        const rs = await updateServiceItemById(service.id, newService);
        if (rs.success) {
            setService({ ...newService });
        }
    };

    const cancelModal = () => {
        setVisibleModal(false);
    };

    const initProps = {
        visibleModal: visibleModal,
        successMsg: successMsg,
        service: service,
        onSubmit: onSubmit,
        cancelModal: cancelModal,
        navigate: navigate,
        onSearchCountries: onSearchCountries,
        onSearchAircrafts: onSearchAircrafts,
        onChangeAvailability: onChangeAvailability
    };

    return tmpl && tmpl({ ...initProps });
};

class ServicesDetailContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <ServicesDetailLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { userReducer, companyReducer } = state;
    const { activeCompany, companies } = companyReducer;
    return {
        user: userReducer,
        companies: companies,
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateServiceItemById: (id, data) =>
            dispatch(updateServiceItemById(id, data)),
        getServieItemById: (id) => dispatch(getServieItemById(id)),
        searchCountries: (key) => dispatch(searchCountries(key)),
        searchAircrafts: (key) => dispatch(searchAircrafts(key))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ServicesDetailContainer)
);
