import React, { useEffect, useState } from 'react';
import { template as tmpl } from './EmployeesContainer.tmpl';
import { getUsers } from '../../redux/actions/appActions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const EmployeesContainerLogic = (props) => {
    const { activeCompany, employees } = props;
    const [employeesList, setEmployeesList] = useState(employees);

    /**
     * Get Employees list from backend
     */
    useEffect(() => {
        props.getEmployees({
            type: 'EMPLOYEE'
        });
    }, []);

    /**
     * Get new employees list after switching company
     */
    useEffect(() => {
        props.getEmployees({
            type: 'EMPLOYEE'
        });
    }, [activeCompany]);

    /**
     * Update employeesList state when company is changed and employees list is updated
     */
    useEffect(() => {
        setEmployeesList(employees);
    }, [employees]);

    /**
     * Function handles searching when user is typing
     * @param {*} e event when user is typing
     */
    const onSearch = (e) => {
        const input = e.target.value.toLowerCase();
        const result = employees.filter((item) => {
            if (
                item.name.toLowerCase().includes(input) ||
                item.email.toLowerCase().includes(input)
            ) {
                return item;
            }
        });
        setEmployeesList(result);
    };

    const navigate = (route, search, state) => {
        props.history.push({
            pathname: route,
            search: search,
            state: state ? { data: state } : null
        });
    };

    const initProps = {
        activeCompany: activeCompany,
        employeesList: employeesList
    };
    return (
        tmpl && tmpl({ ...initProps, onSearch: onSearch, navigate: navigate })
    );
};

class EmployeesContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <EmployeesContainerLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { employees, activeCompany } = companyReducer;
    return {
        employees: employees,
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEmployees: (data) => dispatch(getUsers(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesContainer);
