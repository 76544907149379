import React, { useEffect, useState } from 'react';
import { template as tmpl } from './SelectAircraft.tmpl';
import {
    updateStepForQuote,
    getAircraftsForQuote,
    updateSelectedAircraftForQuote
} from 'redux/actions/quoteAction';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    aircraftCategory,
    FLIGHT_TYPES,
    CREATE_QUOTATION_STEP
} from 'config/Constants';
import { QUOTE_REQUEST_DETAIL } from 'routes/main';
import { UPDATE_QUOTE_STEP, SHOW_LOADING, HIDE_LOADING } from 'redux/Constants';

const LIMIT_PAGE = 5;
const ALL_OPERATOR = 'all_operator';

const SelectAircaft = (props) => {
    const { focusedLeg } = props;
    const dispatch = useDispatch();
    const companyReducer = useSelector((state) => state.companyReducer || {});
    const { companies = [] } = companyReducer;

    const [quoteDetail, setQuoteDetail] = useState(props.quoteDetail || {});
    const [aircraftList, setAircraftList] = useState([]);
    const [activeGoNext, setActiveGoNext] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalPage, setTotalPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [visibleModal, setVisibleModal] = useState(false);
    const [pendingRemoveAircraftId, setPendingRemoveAircraftId] = useState('');
    const [
        pendingLegIndexToRemoveAircraft,
        setPendingLegIndexToRemoveAircraft
    ] = useState(null);
    const [searchKey, setSearchKey] = useState('');
    const [searchCategory, setSearchCategory] = useState(aircraftCategory.ALL);
    const [searchOperator, setSearchOperator] = useState(ALL_OPERATOR);

    useEffect(() => {
        setCurrentPage(1);
        if (quoteDetail.type === FLIGHT_TYPES.MULTI_LEG) {
            fetchAircraftsList(quoteDetail.id, focusedLeg[4]);
        } else if (quoteDetail.type === FLIGHT_TYPES.RETURN) {
            if (focusedLeg === 'DEPARTURE') {
                fetchAircraftsList(quoteDetail.id, 0);
            } else if (focusedLeg === 'RETURN') {
                fetchAircraftsList(quoteDetail.id, 1);
            }
        }
    }, [focusedLeg]);

    useEffect(() => {
        setQuoteDetail(props.quoteDetail);
        if (props.quoteDetail.type === FLIGHT_TYPES.ONEWAY) {
            fetchAircraftsList(props.quoteDetail.id, 0);
        }
    }, [props.quoteDetail]);

    useEffect(() => {
        const { legs = [] } = quoteDetail;
        let isActiveGoNext = true;
        legs.map((leg) => {
            const { select_aircrafts } = leg;
            if (!select_aircrafts || select_aircrafts.length === 0) {
                isActiveGoNext = false;
            }
        });
        setActiveGoNext(isActiveGoNext);
    }, [quoteDetail]);

    const fetchAircraftsList = async (
        id,
        legIndex,
        limit = LIMIT_PAGE,
        page = 1
    ) => {
        const company = searchOperator === ALL_OPERATOR ? '' : searchOperator;
        const result = await dispatch(
            getAircraftsForQuote(
                id,
                legIndex,
                limit,
                page,
                searchKey,
                searchCategory,
                company
            )
        );

        if (result.success && result.isConnected) {
            setAircraftList(result.data.docs);
            setTotalPage(result.data.pages);
        }
    };

    const navigate = (route, search, state) => {
        props.history.push({
            pathname: route,
            search: search,
            state: state ? { data: state } : null
        });
    };

    const addAircraft = (aircraft) => {
        const { legs = [] } = quoteDetail;
        if (quoteDetail.type && quoteDetail.type === FLIGHT_TYPES.MULTI_LEG) {
            if (legs.length > 0 && legs[focusedLeg[4]]) {
                let temp_quoteDetail = { ...quoteDetail };
                let tempLeg = legs[focusedLeg[4]];
                const { select_aircrafts } = legs[focusedLeg[4]];

                const found = select_aircrafts.some((el) => {
                    return el.aircraft.id === aircraft._id;
                });
                if (!found) {
                    if (select_aircrafts && Array.isArray(select_aircrafts)) {
                        tempLeg.select_aircrafts = [
                            ...tempLeg.select_aircrafts,
                            {
                                aircraft: { ...aircraft, id: aircraft._id },
                                price_components: aircraft.price_components
                            }
                        ];
                    } else {
                        tempLeg['select_aircrafts'] = [aircraft];
                    }
                    temp_quoteDetail.legs[focusedLeg[4]] = tempLeg;
                    setQuoteDetail(temp_quoteDetail);
                }
            }
        } else if (
            quoteDetail.type &&
            quoteDetail.type === FLIGHT_TYPES.RETURN
        ) {
            const legIndex = focusedLeg === 'DEPARTURE' ? 0 : 1;
            let temp_quoteDetail = { ...quoteDetail };
            let tempLeg = legs[legIndex];
            const { select_aircrafts } = legs[legIndex];

            const found = select_aircrafts.some((el) => {
                return el.aircraft.id === aircraft._id;
            });
            if (!found) {
                if (select_aircrafts && Array.isArray(select_aircrafts)) {
                    tempLeg.select_aircrafts = [
                        ...tempLeg.select_aircrafts,
                        {
                            aircraft: { ...aircraft, id: aircraft._id },
                            price_components: aircraft.price_components
                        }
                    ];
                } else {
                    tempLeg['select_aircrafts'] = [aircraft];
                }
                temp_quoteDetail.legs[legIndex] = tempLeg;
                setQuoteDetail(temp_quoteDetail);
            }
        } else if (
            quoteDetail.type &&
            quoteDetail.type === FLIGHT_TYPES.ONEWAY
        ) {
            let temp_quoteDetail = { ...quoteDetail };
            let tempLeg = legs[0];
            const { select_aircrafts } = legs[0];
            const found = select_aircrafts.some((el) => {
                return el.aircraft.id === aircraft._id;
            });
            if (!found) {
                if (select_aircrafts && Array.isArray(select_aircrafts)) {
                    tempLeg.select_aircrafts = [
                        ...tempLeg.select_aircrafts,
                        {
                            aircraft: { ...aircraft, id: aircraft._id },
                            price_components: aircraft.price_components
                        }
                    ];
                } else {
                    tempLeg['select_aircrafts'] = [aircraft];
                }
                temp_quoteDetail.legs[0] = tempLeg;
                setQuoteDetail(temp_quoteDetail);
            }
        }
    };

    const isPackageContainAircraft = (
        aircraftId,
        eachPackage = [],
        legIndex
    ) => {
        const { aircraft = {} } = eachPackage[legIndex];
        const { _id = '' } = aircraft;
        const found = _id === aircraftId;

        return found;
    };

    const removeSelectedAircraft = (id, legIndex) => {
        const { legs = [], type, packages = [] } = quoteDetail;
        let isAircraftInAnyPackage = false;
        packages.map((eachPackage) => {
            if (isPackageContainAircraft(id, eachPackage, legIndex)) {
                isAircraftInAnyPackage = true;
            }
        });

        if (!isAircraftInAnyPackage) {
            if (type === FLIGHT_TYPES.ONEWAY) {
                let temp_quoteDetail = { ...quoteDetail };
                let tempLeg = legs[0];
                const { select_aircrafts } = legs[0];
                const found = select_aircrafts.some((el) => {
                    return el.aircraft.id === id;
                });
                if (found) {
                    if (select_aircrafts && Array.isArray(select_aircrafts)) {
                        let temp_select_aircrafts =
                            tempLeg.select_aircrafts || [];
                        let filtered_temp_select_aircrafts =
                            temp_select_aircrafts.filter(
                                (el) => el.aircraft.id !== id
                            );
                        tempLeg.select_aircrafts =
                            filtered_temp_select_aircrafts;
                    } else {
                        tempLeg['select_aircrafts'] = [];
                    }
                    temp_quoteDetail.legs[0] = tempLeg;
                    setQuoteDetail(temp_quoteDetail);
                }
            } else if (type === FLIGHT_TYPES.RETURN) {
                let temp_quoteDetail = { ...quoteDetail };
                let tempLeg = legs[legIndex];
                const { select_aircrafts } = legs[legIndex];
                const found = select_aircrafts.some((el) => {
                    return el.aircraft.id === id;
                });
                if (found) {
                    if (select_aircrafts && Array.isArray(select_aircrafts)) {
                        let temp_select_aircrafts =
                            tempLeg.select_aircrafts || [];
                        let filtered_temp_select_aircrafts =
                            temp_select_aircrafts.filter(
                                (el) => el.aircraft.id !== id
                            );
                        tempLeg.select_aircrafts =
                            filtered_temp_select_aircrafts;
                    } else {
                        tempLeg['select_aircrafts'] = [];
                    }
                    temp_quoteDetail.legs[legIndex] = tempLeg;
                    setQuoteDetail(temp_quoteDetail);
                }
            } else if (type === FLIGHT_TYPES.MULTI_LEG) {
                if (legs.length > 0 && legs[legIndex]) {
                    let temp_quoteDetail = { ...quoteDetail };
                    let tempLeg = legs[legIndex];
                    const { select_aircrafts } = legs[legIndex];

                    const found = select_aircrafts.some((el) => {
                        return el.aircraft.id === id;
                    });
                    if (found) {
                        if (
                            select_aircrafts &&
                            Array.isArray(select_aircrafts)
                        ) {
                            let temp_select_aircrafts =
                                tempLeg.select_aircrafts || [];
                            let filtered_temp_select_aircrafts =
                                temp_select_aircrafts.filter(
                                    (el) => el.aircraft.id !== id
                                );
                            tempLeg.select_aircrafts =
                                filtered_temp_select_aircrafts;
                        } else {
                            tempLeg['select_aircrafts'] = [aircraft];
                        }
                        temp_quoteDetail.legs[legIndex] = tempLeg;
                        setQuoteDetail(temp_quoteDetail);
                    }
                }
            }
        } else {
            setPendingRemoveAircraftId(id);
            setPendingLegIndexToRemoveAircraft(legIndex);
            setVisibleModal(true);
        }
    };

    const removePackageByAircraftID = (oldPackages, aircraftId) => {
        let newPackage = [];
        oldPackages.map((eachPackage) => {
            if (
                !isPackageContainAircraft(
                    aircraftId,
                    eachPackage,
                    pendingLegIndexToRemoveAircraft
                )
            ) {
                newPackage.push(eachPackage);
            }
        });

        return newPackage;
    };

    const confirmRemoveAircraft = () => {
        const { legs = [], type, packages = [] } = quoteDetail;
        if (type === FLIGHT_TYPES.ONEWAY) {
            let temp_quoteDetail = { ...quoteDetail };
            let tempLeg = legs[0];
            const { select_aircrafts } = legs[0];
            const found = select_aircrafts.some((el) => {
                return el.aircraft.id === pendingRemoveAircraftId;
            });
            if (found) {
                if (select_aircrafts && Array.isArray(select_aircrafts)) {
                    let temp_select_aircrafts = tempLeg.select_aircrafts || [];
                    let filtered_temp_select_aircrafts =
                        temp_select_aircrafts.filter(
                            (el) => el.aircraft.id !== pendingRemoveAircraftId
                        );
                    tempLeg.select_aircrafts = filtered_temp_select_aircrafts;
                } else {
                    tempLeg['select_aircrafts'] = [];
                }
                temp_quoteDetail.legs[0] = tempLeg;

                setQuoteDetail({
                    ...temp_quoteDetail,
                    packages: removePackageByAircraftID(
                        packages,
                        pendingRemoveAircraftId
                    )
                });
            }
        } else if (type === FLIGHT_TYPES.RETURN) {
            let temp_quoteDetail = { ...quoteDetail };
            let tempLeg = legs[pendingLegIndexToRemoveAircraft];
            const { select_aircrafts } = legs[pendingLegIndexToRemoveAircraft];

            const found = select_aircrafts.some((el) => {
                return el.aircraft.id === pendingRemoveAircraftId;
            });

            if (found) {
                if (select_aircrafts && Array.isArray(select_aircrafts)) {
                    let temp_select_aircrafts = tempLeg.select_aircrafts || [];
                    let filtered_temp_select_aircrafts =
                        temp_select_aircrafts.filter(
                            (el) => el.aircraft.id !== pendingRemoveAircraftId
                        );
                    tempLeg.select_aircrafts = filtered_temp_select_aircrafts;
                } else {
                    tempLeg['select_aircrafts'] = [];
                }
                temp_quoteDetail.legs[pendingLegIndexToRemoveAircraft] =
                    tempLeg;
                setQuoteDetail({
                    ...temp_quoteDetail,
                    packages: removePackageByAircraftID(
                        packages,
                        pendingRemoveAircraftId
                    )
                });
            }
        } else if (type === FLIGHT_TYPES.MULTI_LEG) {
            if (legs.length > 0 && legs[pendingLegIndexToRemoveAircraft]) {
                let temp_quoteDetail = { ...quoteDetail };
                let tempLeg = legs[pendingLegIndexToRemoveAircraft];
                const { select_aircrafts } =
                    legs[pendingLegIndexToRemoveAircraft];

                const found = select_aircrafts.some((el) => {
                    return el.aircraft.id === pendingRemoveAircraftId;
                });
                if (found) {
                    if (select_aircrafts && Array.isArray(select_aircrafts)) {
                        let temp_select_aircrafts =
                            tempLeg.select_aircrafts || [];
                        let filtered_temp_select_aircrafts =
                            temp_select_aircrafts.filter(
                                (el) =>
                                    el.aircraft.id !== pendingRemoveAircraftId
                            );
                        tempLeg.select_aircrafts =
                            filtered_temp_select_aircrafts;
                    } else {
                        tempLeg['select_aircrafts'] = [aircraft];
                    }
                    temp_quoteDetail.legs[pendingLegIndexToRemoveAircraft] =
                        tempLeg;
                    setQuoteDetail({
                        ...temp_quoteDetail,
                        packages: removePackageByAircraftID(
                            packages,
                            pendingRemoveAircraftId
                        )
                    });
                }
            }
        }
        setVisibleModal(false);
        setPendingRemoveAircraftId('');
        setPendingLegIndexToRemoveAircraft(null);
    };

    const cancelModal = () => {
        setPendingRemoveAircraftId('');
        setPendingLegIndexToRemoveAircraft(null);
        setVisibleModal(false);
    };

    const sortAircraftList = (newAircraftList) => {
        let mergeList  = [...aircraftList, ...newAircraftList];
        const newList = mergeList.sort((a, b) => a.price_components.total_inc_vat - b.price_components.total_inc_vat);
        setAircraftList(newList);
    }

    const onLoadMore = async () => {
        if (currentPage + 1 <= totalPage) {
            const company =
                searchOperator === ALL_OPERATOR ? '' : searchOperator;
            setLoading(true);
            setCurrentPage(currentPage + 1);
            if (quoteDetail.type === FLIGHT_TYPES.MULTI_LEG) {
                const result = await dispatch(
                    getAircraftsForQuote(
                        quoteDetail.id,
                        focusedLeg[4],
                        LIMIT_PAGE,
                        currentPage + 1,
                        searchKey,
                        searchCategory,
                        company
                    )
                );
                setLoading(false);
                if (result.success && result.isConnected) {
                    sortAircraftList(result.data.docs);
                    setTotalPage(result.data.pages);
                }
            } else if (quoteDetail.type === FLIGHT_TYPES.RETURN) {
                if (focusedLeg === 'DEPARTURE') {
                    const result = await dispatch(
                        getAircraftsForQuote(
                            quoteDetail.id,
                            0,
                            LIMIT_PAGE,
                            currentPage + 1,
                            searchKey,
                            searchCategory,
                            company
                        )
                    );
                    setLoading(false);
                    if (result.success && result.isConnected) {
                        sortAircraftList(result.data.docs);
                        setTotalPage(result.data.pages);
                    }
                } else if (focusedLeg === 'RETURN') {
                    const result = await dispatch(
                        getAircraftsForQuote(
                            quoteDetail.id,
                            1,
                            LIMIT_PAGE,
                            currentPage + 1,
                            searchKey,
                            searchCategory,
                            company
                        )
                    );
                    setLoading(false);
                    if (result.success && result.isConnected) {
                        sortAircraftList(result.data.docs);
                    }
                }
            } else if (quoteDetail.type === 'ONEWAY') {
                const result = await dispatch(
                    getAircraftsForQuote(
                        quoteDetail.id,
                        0,
                        LIMIT_PAGE,
                        currentPage + 1,
                        searchKey,
                        searchCategory,
                        company
                    )
                );
                setLoading(false);
                if (result.success && result.isConnected) {
                    sortAircraftList(result.data.docs);
                    setTotalPage(result.data.pages);
                }
            }
        }
    };

    const onGoNext = () => {
        // save selected aircrafts
        if (quoteDetail && quoteDetail.legs) {
            let legs = quoteDetail.legs.map((item = {}, i) => {
                const { id, select_aircrafts = [] } = item;
                let selectedAircraft = select_aircrafts;
                selectedAircraft = selectedAircraft.map(
                    (selectedItem = {}, index) => {
                        const { aircraft = {}, price_components } =
                            selectedItem;
                        return {
                            aircraft_id: aircraft?.id,
                            price_components: price_components
                        };
                    }
                );

                return {
                    leg_id: id,
                    select_aircrafts: selectedAircraft
                };
            });

            dispatch(
                updateSelectedAircraftForQuote(quoteDetail.id, { legs: legs })
            );
        }

        dispatch(
            updateStepForQuote(quoteDetail?.id, CREATE_QUOTATION_STEP.SERVICES)
        );
        dispatch({
            type: UPDATE_QUOTE_STEP,
            data: {
                id: quoteDetail.id,
                step: CREATE_QUOTATION_STEP.SERVICES
            }
        });
    };

    const onGoBack = () => {
        navigate(QUOTE_REQUEST_DETAIL, quoteDetail?.id);
    };

    async function fetchAircraftListByKey(legIndex = 0) {
        const company = searchOperator === ALL_OPERATOR ? '' : searchOperator;
        const result = await dispatch(
            getAircraftsForQuote(
                quoteDetail.id,
                legIndex,
                LIMIT_PAGE,
                1,
                searchKey,
                searchCategory,
                company
            )
        );
        setLoading(false);
        if (result.success && result.isConnected) {
            setAircraftList(result.data.docs);
            setTotalPage(result.data.pages);
        }
    }

    useEffect(() => {
        setCurrentPage(1);
        if (quoteDetail && quoteDetail.id) {
            if (quoteDetail.type === FLIGHT_TYPES.MULTI_LEG) {
                fetchAircraftListByKey(focusedLeg[4]);
            } else if (quoteDetail.type === FLIGHT_TYPES.RETURN) {
                if (focusedLeg === 'DEPARTURE') {
                    fetchAircraftListByKey(0);
                } else if (focusedLeg === 'RETURN') {
                    fetchAircraftListByKey(1);
                }
            } else {
                fetchAircraftListByKey(0);
            }
        }
    }, [searchKey, searchCategory, searchOperator]);

    const onSearch = (value) => {
        setSearchKey(value);
    };

    const onChangeCategory = (value) => {
        setSearchCategory(value);
    };

    const onChangeOperator = (value) => {
        setSearchOperator(value);
    };

    const onClearCategory = async () => {
        setSearchCategory(aircraftCategory.ALL);
    };

    const onClearOperator = async () => {
        setSearchOperator(ALL_OPERATOR);
    };

    const initProps = {
        companies: companies,
        onClearCategory: onClearCategory,
        searchCategory: searchCategory,
        onChangeCategory: onChangeCategory,
        searchOperator: searchOperator,
        onChangeOperator: onChangeOperator,
        onClearOperator: onClearOperator,
        onSearch: onSearch,
        visibleModal: visibleModal,
        confirmRemoveAircraft: confirmRemoveAircraft,
        cancelModal: cancelModal,
        loading: loading,
        activeGoNext: activeGoNext,
        onGoNext: onGoNext,
        onGoBack: onGoBack,
        onLoadMore: onLoadMore,
        currentPage: currentPage,
        totalPage: totalPage,
        removeSelectedAircraft: removeSelectedAircraft,
        focusedLeg: focusedLeg,
        addAircraft: addAircraft,
        aircraftList: aircraftList,
        quoteDetail: quoteDetail,
        navigate: navigate
    };
    return tmpl && tmpl({ ...initProps });
};

export default withRouter(SelectAircaft);
