import React from 'react';
import styled from 'styled-components';
import { Avatar, Image } from 'antd';
import { useSelector } from 'react-redux';
import { getCustomerInfo, getCompanyInfo } from 'redux/selectors/company';

// user_id : userId or companyId
const Booker = ({ user = {}, isCompany = false }) => {
    const {
        user_id = '',
        firstname = '',
        lastname = '',
        onClick,
        company_id = ''
    } = user;
    let image = '';
    let name = '';
    if (!isCompany) {
        const customer = useSelector((state) =>
            getCustomerInfo(state, user_id)
        );
        if (customer) {
            image = customer.image;
            name = customer.firstname + ' ' + customer.lastname;
        }
    } else {
        const company = useSelector((state) =>
            getCompanyInfo(state, company_id)
        );
        if (company) {
            image = company.image;
            name = company.name;
        }
    }

    return (
        <a onClick={() => onClick && onClick(_id)}>
            <NameSection>
                    {image ? (
                        <div>
                            <Avatar className="ant-avatar-custom" src={image} />
                        </div>
                    ) : (
                        <div>
                            <Avatar className="ant-avatar-custom">
                                {!isCompany
                                    ? (
                                        firstname.charAt(0) + lastname.charAt(0)
                                    ).toUpperCase()
                                    : name.charAt(0)}
                            </Avatar>
                        </div>
                    )}
                    <UserName color={!isCompany ? '#65686f' : '#19C0FF'}>
                        {name}
                    </UserName>
            </NameSection>
        </a>
    );
};

const NameSection = styled.div`
    display: flex;
    flex-direction: row;
    border: none;
    background: none;
`;

const UserName = styled.span`
    color: ${(props) => props.color || '#65686f'};
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    margin-left: 10px;
    align-self: center;
`;

export default Booker;
