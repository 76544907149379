import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { Input, Table, Switch, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { ButtonCustom, localeEmptyTable } from 'components';
import { searchIcon, plusIcon } from 'assets/images/icons';
import { ADD_TOP_RATED_AIRPORT, TOP_RATED_AIRPORT_DETAIL } from 'routes/main';
import {
    NameSection,
    DetailSection,
    ColumnHeader,
    TableSection,
    SearchSection,
    IcaoIataCode,
    AvailabilitySection,
    AvailableText,
    ImageCustom
} from '../../styles';
import styled from 'styled-components';

const template = (props) => {
    const { t, i18n } = useTranslation();
    const SearchIcon = <img src={searchIcon} />;
    const { topAirports = [], navigate, changeDisplay, total = 0 } = props;

    const columns = [
        {
            key: 'photo',
            fixed: 'left',
            title: function titleHeader() {
                return <ColumnHeader>{t('DISPLAY_PHOTO')}</ColumnHeader>;
            },
            dataIndex: 'name',
            width: '20%',
            render: function eachItemPhoto(photo, row = {}) {
                const { airport = {}, image = {} } = row;
                return (
                    <div style={{ paddingTop: '7px' }}>
                        <ImageCustom
                            src={
                                image?.lowres?.url ? row.image.lowres.url : null
                            }
                        />
                    </div>
                );
            }
        },
        {
            key: 'name',
            title: function titleHeader() {
                return <ColumnHeader>{t('DISPLAY_NAME')}</ColumnHeader>;
            },
            dataIndex: 'name',
            width: '20%',
            render: function eachItemName(name, row = {}) {
                const { airport = {} } = row;
                return (
                    <NameSection>
                        <AirportName>
                            {name ? name.toUpperCase() : '_'}
                        </AirportName>
                    </NameSection>
                );
            }
        },
        {
            key: 'airport',
            title: function titleHeader() {
                return <ColumnHeader>{t('AIRPORT_NAME')}</ColumnHeader>;
            },
            dataIndex: 'name',
            width: '25%',
            render: function eachItemName(name, row = {}) {
                const { airport = {} } = row;
                return (
                    <NameSection>
                        <AirportName>
                            {airport.name ? airport.name.toUpperCase() : '_'}
                        </AirportName>
                    </NameSection>
                );
            }
        },
        {
            key: 'icao_iata',
            width: '15%',
            title: function titleHeader() {
                return <ColumnHeader>{t('ICAO_IATA_CODE')}</ColumnHeader>;
            },
            dataIndex: 'item_type',
            render: function eachItemICAO(icao_iata, row) {
                const { airport = {} } = row;
                const { iata_code = '', icao_code = '' } = airport;
                return (
                    <IcaoIataCode>
                        {`${icao_code || '_'} / ${
                            iata_code || '_'
                        }`.toUpperCase()}
                    </IcaoIataCode>
                );
            }
        },
        {
            key: 'city',
            width: '15%',
            title: function titleHeader() {
                return <ColumnHeader>{t('CITY')}</ColumnHeader>;
            },
            dataIndex: 'item_type',
            render: function eachItemCity(city, row) {
                const { airport = {} } = row;
                const { location = {} } = airport;
                return <LocationName>{location?.city || '_'}</LocationName>;
            }
        },
        {
            key: 'country',
            width: '15%',
            title: function titleHeader() {
                return <ColumnHeader>{t('COUNTRY')}</ColumnHeader>;
            },
            dataIndex: 'item_type',
            render: function eachItemCountry(country, row) {
                const { airport = {} } = row;
                const { location = {} } = airport;
                return (
                    <LocationName>
                        {location?.country?.english_name || '_'}
                    </LocationName>
                );
            }
        },
        {
            key: 'enable',
            width: '18%',
            title: function titleHeader() {
                return <ColumnHeader>{t('DISPLAY')}</ColumnHeader>;
            },
            dataIndex: 'enable',
            render: function eachItemEnable(enable, row, index) {
                return (
                    <AvailabilitySection>
                        <AvailableText style={{ marginRight: '10px' }}>
                            {enable
                                ? t('ENABLE').toUpperCase()
                                : t('DISABLE').toUpperCase()}
                        </AvailableText>
                        <Switch
                            checked={enable}
                            style={{ marginLeft: 5 }}
                            onChange={() => changeDisplay(row)}
                        />
                    </AvailabilitySection>
                );
            }
        },
        {
            key: 'id',
            width: '15%',
            dataIndex: 'id',
            render: function eachItem(id) {
                return (
                    <DetailSection>
                        <ButtonCustom
                            btnType="secondary"
                            size="small"
                            title={t('VIEW_DETAILS').toUpperCase()}
                            width="120px"
                            onClick={(val) => {
                                navigate &&
                                    navigate(TOP_RATED_AIRPORT_DETAIL, id);
                            }}
                        />
                    </DetailSection>
                );
            }
        }
    ];

    return (
        <Container>
            <TopAirportTotal>{`${t(
                'TOP_RATED_AIRPORTS'
            ).toUpperCase()} (${total})`}</TopAirportTotal>
            <SearchSection>
                <Row style={{ width: '100%' }}>
                    <Col xl={7} lg={8} md={10} sm={12} xs={12}>
                        <Input
                            placeholder={t('SEARCH_TOP_RATED_AIRPORTS')}
                            allowClear
                            suffix={SearchIcon}
                            onChange={(e) => props.onSearch(e)}
                            style={{ width: '100%' }}
                        />
                    </Col>
                </Row>
                <ButtonCustom
                    btnType="secondary"
                    onClick={() => navigate && navigate(ADD_TOP_RATED_AIRPORT)}
                    title={t('ADD_NEW_AIRPORT').toUpperCase()}
                    width="192px"
                    icon={plusIcon}
                />
            </SearchSection>
            <TableSection>
                <Table
                    scroll={{ x: 1000 }}
                    columns={columns}
                    pagination={{ showSizeChanger: false }}
                    dataSource={topAirports}
                    locale={localeEmptyTable()}
                ></Table>
            </TableSection>
        </Container>
    );
};

export { template };

const Container = styled.div``;

const AirportName = styled.span`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.8px;
    line-height: 12px;
    text-align: left;
`;

const LocationName = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 20px;
    text-align: left;
`;

const TopAirportTotal = styled.div`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.7px;
    line-height: 16px;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 20px;
`;
