import API from '../../modules/httpModule';

/**
 * Get aircrafts from company, its partners or all
 * @param {Object} data - query data
 * @param {Array} data.category - list aircraft category which belong to this category
 * @param {String} data.operator - id of a operator which is to find aircrafts
 * @param {Number} data.from_rate - mininum price by hour
 * @param {Number} data.to_rate - maximum price by hour
 * @param {Boolean} data.partner - get all aircrafts which are belong to current company's partners
 * @param {Boolean} data.mine - get current company aircrafts
 * @returns
 */
export function getAircrafts(data) {
    return async (dispatch) => {
        try {
            const response = await API.post('/v2/aircrafts', data);
            if (response && response.status === 200) {
                const {
                    page = 1,
                    pages = 1,
                    limit = 1,
                    total = 0,
                    docs = []
                } = response.data;
                return {
                    success: true,
                    isConnected: true,
                    aircrafts: docs,
                    page: page,
                    pages: pages,
                    limit: limit,
                    total: total
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * search aircraft by name,id,address
 * @param {String} input - aircraft name, tail number
 * @returns - aircrafts as pagination
 */
export function searchAircrafts(input) {
    return async (dispatch) => {
        try {
            const query = { key: input };
            const response = await API.get('/search/aircrafts', query);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    isConnected: true,
                    aircrafts: response.data
                };
            }
        } catch (err) {
            return {
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * search aircraft by name,id,address
 * @param {String} input - aircraft name, tail number
 * @returns - aircrafts as pagination
 */
export function searchGenericAircrafts(input) {
    return async (dispatch) => {
        try {
            const query = { key: input };
            const response = await API.get('/search/generic_aircrafts', query);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    isConnected: true,
                    aircrafts: response.data
                };
            }
        } catch (err) {
            return {
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * get aircraft by id
 * @param {String} id - aircraft id
 * @returns {Object} aircraft detail object
 */
export function getAircraftById(id) {
    return async (dispatch) => {
        try {
            const response = await API.get(`/aircrafts/${id}`);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    isConnected: true,
                    aircraft: response.data
                };
            }
        } catch (err) {
            return {
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * get aircraft by id
 * @param {String} id - aircraft id
 * @returns {Object} aircraft detail object
 */
export function updateAircraft(id, data) {
    return async (dispatch) => {
        try {
            const response = await API.put(`/aircrafts/${id}`, data);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                };
            }
        } catch (err) {
            return {
                isConnected: false,
                error: err
            };
        }
    };
}
/**
 * add new aircraft
 * @param {Object} data - aircraft object
 * @returns {Object} - sttatus success
 */
export function addAircraft(data, query = {}) {
    return async (dispatch) => {
        try {
            let params = '';
            if (query) {
                params = Object.keys(query).reduce((q, key) => {
                    return q + `&${key}=${query[key]}`;
                }, '?');
            }
            const response = await API.post(`/aircrafts${params}`, data);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    isConnected: true,
                    aircraft: response.data
                };
            }
        } catch (err) {
            return {
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * Search airport by city, name and icao code
 * @param {String} input - airport by city, name and icao code
 * @returns airport data
 */
export function searchAirport(input) {
    return async (dispatch) => {
        try {
            const query = { key: input };
            const response = await API.get('/search/airports', query);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    isConnected: true,
                    airports: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * Search airport by city, name and icao code
 * @param {String} input - airport by city, name and icao code
 * @returns airport data
 */
 export function searchEstimateAircraft(id, data) {
    return async (dispatch) => {
        try {
            const response = await API.post(`/aircrafts/${id}/estimate`, data);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    isConnected: true,
                    estimateAircraft: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

export function removeAircraft(id) {
    return async (dispatch) => {
        try {
            const response = await API.delete(`/aircrafts/${id}`);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true
                };
            }
        } catch (err) {
            return {
                success: false,
                error: err
            };
        }
    };
}
