import React, { useState } from 'react';
import { template as tmpl } from './SearchCompanies.tmpl';
import { connect } from 'react-redux';
import { searchCompanies } from '../../../redux/actions/companyAction';

const SearchCompaniesLogic = (props) => {
    const {
        type, // HANDLER, OPERATPR ,...
        label = '',
        company = {},
        onSelectCompany,
        dropdownStyle,
        inputStyle,
        searchCompanies
    } = props;

    const onSearchCompanies = async (key = '') => {
        let companies = [];
        if (!key) return companies;

        let rs = await searchCompanies(key, type);
        if (rs && rs.success) {
            companies = rs.companies;
        }
        return companies;
    };

    return (
        tmpl &&
        tmpl({
            label,
            company,
            onSelectCompany,
            onSearchCompanies,
            dropdownStyle,
            inputStyle
        })
    );
};

class SearchCompanies extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <SearchCompaniesLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { companies } = companyReducer;
    return {
        companies: companies
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        searchCompanies: (key, type) => dispatch(searchCompanies(key, type))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchCompanies);
