import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { Input, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { ButtonCustom, Text, localeEmptyTable } from 'components';
import { COMPANY_INVOICE_DETAIL } from 'routes/main';
import { searchIcon } from 'assets/images/icons';
import {
    TableSection,
    ColumnHeader,
    NameSectionButton,
    SearchSection,
    DetailSection
} from '../../styles';
import _ from 'lodash';
import moment from 'moment';
import { numberFormat } from 'utils';
import { InvoiceStatus } from 'components/Commons';
import { Booker } from 'components';

const template = (props) => {
    const { navigate, invoices } = props;
    const { t, i18n } = useTranslation();
    const SearchIcon = <img src={searchIcon} />;

    const onClickDetail = (val = {}) => {
        navigate(COMPANY_INVOICE_DETAIL, val.id, val);
    };

    const columns = [
        {
            dataIndex: 'invoice_code',
            width: '15%',
            key: 'invoice_code',
            fixed: 'left',
            sorter: (a, b) => {
                if (
                    a.invoice_code.toUpperCase() < b.invoice_code.toUpperCase()
                ) {
                    return -1;
                }
                if (
                    a.invoice_code.toUpperCase() > b.invoice_code.toUpperCase()
                ) {
                    return 1;
                }
                return 0;
            },
            title: function titleHeader() {
                return <ColumnHeader>{t('Invoice') + ' #'}</ColumnHeader>;
            },
            render: function eachItemName(invoice_code, row) {
                return (
                    <NameSectionButton onClick={() => onClickDetail(row)}>
                        <Text textStyle="tableText2">{invoice_code}</Text>
                    </NameSectionButton>
                );
            }
        },
        {
            dataIndex: 'created_at',
            width: '20%',
            key: 'created_at',
            title: function titleHeader() {
                return <ColumnHeader>{t('INVOICE_CREATE_AT')}</ColumnHeader>;
            },
            render: function eachItemMembership(created_at) {
                return (
                    <Text textStyle="tableText1">
                        {created_at
                            ? moment(created_at).format('MMMM DD, YYYY')
                            : '_'}
                    </Text>
                );
            }
        },
        {
            dataIndex: 'addressee',
            width: '20%',
            key: 'addressee',
            title: function titleHeader() {
                return <ColumnHeader>{t('PARTNER')}</ColumnHeader>;
            },
            render: function eachItemName(addressee = {}, row) {
                const { company_name = '', company_id = '' } = addressee;
                return (
                    <Booker
                        user={{
                            company_id: company_id,
                            firstname: addressee?.company_name
                        }}
                        isCompany={true}
                    />
                );
            }
        },
        {
            dataIndex: 'totals',
            key: 'totals',
            width: '15%',
            title: function titleHeader() {
                return <ColumnHeader>{t('TOTAL')}</ColumnHeader>;
            },
            width: '15%',
            render: function eachItemEmail(totals) {
                return (
                    <Text textStyle="dateTimeBold">
                        {numberFormat(totals?.total_payout || 0, 'USD', 2)}
                    </Text>
                );
            }
        },
        {
            dataIndex: 'status',
            key: 'status',
            width: '20%',
            title: function titleHeader() {
                return <ColumnHeader>{t('INVOICE_STATUS')}</ColumnHeader>;
            },
            render: function eachItemAddress(status) {
                return <InvoiceStatus status={status} />;
            }
        },
        {
            key: 'action',
            title: '',
            width: '15%',
            render: function setAction(val) {
                return (
                    <DetailSection>
                        <ButtonCustom
                            btnType="secondary"
                            size="small"
                            title={t('VIEW_INVOICE').toUpperCase()}
                            width="120px"
                            onClick={(e) => onClickDetail(val)}
                        />
                    </DetailSection>
                );
            }
        }
    ];

    return (
        <>
            <SearchSection>
                <Input
                    placeholder={t('SEARCH')}
                    allowClear
                    suffix={SearchIcon}
                    onChange={(e) => props.onSearch(e)}
                />
            </SearchSection>

            <TableSection>
                <Table
                    columns={columns}
                    dataSource={invoices}
                    pagination={{ showSizeChanger: false }}
                    style={{ marginTop: 10 }}
                    scroll={{ x: 1000 }}
                    locale={localeEmptyTable()}
                />
            </TableSection>
        </>
    );
};

export { template };
