import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { Row, Col, Steps } from 'antd';
import { Tabs, Text, Header, FlightInformation } from 'components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { jetIcon } from 'assets/images/icons';
import moment from 'moment';
import SelectAircaft from './SelectedAircraft';
import CreateQuoteServiceItems from './ServiceItems';
import CreateQuoteInvoice from './Invoices';
import Summary from './Summary';
import { CREATE_QUOTATION_STEP, FLIGHT_TYPES } from 'config/Constants';
import { useSelector } from 'react-redux';
import { QuoteGeneralInfo } from '../components';
import { GLOBAL_STYLE_CONSTANTS } from 'config/Constants';

const { Step } = Steps;

const template = (props) => {
    const { expandedSidebar } = useSelector(
        (state) => state.settingReducer || false
    );
    const { t, i18n } = useTranslation();
    const {
        currentStep,
        focusedLeg,
        currentPage = 1,
        totalPage = 1,
        quoteDetail = {},
        navigate,
        aircraftList = [],
        setFocusedLeg
    } = props;

    const { user = {}, type, legs = [], created_at } = quoteDetail;
    const { firstname = '', lastname = '' } = user;

    const renderTabs = () => {
        let tabRenderer = [];
        if (type === FLIGHT_TYPES.RETURN) {
            tabRenderer = [
                {
                    key: 'DEPARTURE',
                    label: t('DEPARTURE').toUpperCase(),
                    leg: legs[0],
                    render: (
                        <QuoteGeneralInfo
                            leg={legs[0]}
                            created_at={created_at}
                        />
                    )
                },
                {
                    key: 'RETURN',
                    label: t('RETURN').toUpperCase(),
                    leg: legs[1],
                    render: (
                        <QuoteGeneralInfo
                            leg={legs[1]}
                            created_at={created_at}
                        />
                    )
                }
            ];
        } else if (type === FLIGHT_TYPES.MULTI_LEG) {
            tabRenderer = legs.map((leg, index) => {
                return {
                    key: 'LEG_' + index,
                    label: 'LEG ' + (index + 1),
                    leg: leg,
                    render: (
                        <QuoteGeneralInfo leg={leg} created_at={created_at} />
                    )
                };
            });
        }
        return tabRenderer;
    };

    const renderStepContent = () => {
        switch (currentStep) {
            case CREATE_QUOTATION_STEP.AIRCRAFT:
                return (
                    <SelectAircaft
                        quoteDetail={quoteDetail}
                        aircraftList={aircraftList}
                        focusedLeg={focusedLeg}
                        currentPage={currentPage}
                        totalPage={totalPage}
                        navigate={navigate}
                    />
                );
            case CREATE_QUOTATION_STEP.SERVICES:
                return (
                    <CreateQuoteServiceItems
                        requestCode={quoteDetail?.request_code}
                        focusedLeg={focusedLeg}
                        navigate={navigate}
                    />
                );
            case CREATE_QUOTATION_STEP.INVOICE:
                return (
                    <CreateQuoteInvoice
                        requestCode={quoteDetail?.request_code}
                        focusedLeg={focusedLeg}
                        navigate={navigate}
                    />
                );
            case CREATE_QUOTATION_STEP.SUMMARY:
                return (
                    <Summary quoteDetail={quoteDetail} navigate={navigate} />
                );
            default:
                return (
                    <SelectAircaft
                        quoteDetail={quoteDetail}
                        aircraftList={aircraftList}
                        focusedLeg={focusedLeg}
                        currentPage={currentPage}
                        totalPage={totalPage}
                        navigate={navigate}
                    />
                );
        }
    };

    const ActiveStep = ({ stepNumber }) => {
        let active = false;

        switch (currentStep) {
            case CREATE_QUOTATION_STEP.AIRCRAFT:
                active = stepNumber <= 1;
                break;
            case CREATE_QUOTATION_STEP.SERVICES:
                active = stepNumber <= 2;
                break;
            case CREATE_QUOTATION_STEP.INVOICE:
                active = stepNumber <= 3;
                break;
            case CREATE_QUOTATION_STEP.SUMMARY:
                active = stepNumber <= 4;
                break;
            default:
                active = stepNumber <= 1;
                break;
        }

        return (
            <StepWrapper active={active}>
                <StepIndex active={active}>{stepNumber}</StepIndex>
            </StepWrapper>
        );
    };

    const renderQuoteInfo = () => {
        if (currentStep === CREATE_QUOTATION_STEP.AIRCRAFT) {
            return (
                <>
                    {type && type !== FLIGHT_TYPES.ONEWAY ? (
                        <TabsSection>
                            <Tabs
                                tabRenderer={renderTabs()}
                                onChange={setFocusedLeg}
                            />
                        </TabsSection>
                    ) : (
                        <div style={{ marginTop: 40 }}>
                            <QuoteGeneralInfo
                                leg={legs[0]}
                                created_at={created_at}
                            />
                        </div>
                    )}
                </>
            );
        }

        return (
            <>
                {currentStep === CREATE_QUOTATION_STEP.INVOICE ||
                currentStep === CREATE_QUOTATION_STEP.SUMMARY ? (
                    <div style={{ marginTop: 40 }}>
                        <FlightStatusStep3>
                            <FlightInformation
                                flightType={quoteDetail.type}
                                requestCode={quoteDetail.request_code || ''}
                                reqeustSendBy={firstname + ' ' + lastname}
                                backgroundColor={'#ffffff'}
                            />
                        </FlightStatusStep3>
                        {Array.isArray(legs) &&
                            legs.length > 0 &&
                            legs.map((leg, index) => {
                                return (
                                    <div
                                        key={index}
                                        style={{ marginBottom: 10 }}
                                    >
                                        <QuoteGeneralInfo
                                            leg={leg}
                                            created_at={created_at}
                                        />
                                    </div>
                                );
                            })}
                    </div>
                ) : null}
            </>
        );
    };

    return (
        <CreateQuoteContainer>
            <Header
                title={t('CREATE_QUOTATION')}
                description={t('SELECT_AIRCRAFT')}
            />
            <ProgressSection>
                <StepSection>
                    <Steps
                        current={currentStep}
                        direction="horizontal"
                        labelPlacement="vertical"
                    >
                        <Step
                            icon={<ActiveStep stepNumber={1} />}
                            title={
                                <Text textStyle="infoNormal">
                                    {t('SELECT_AIRCRAFT').toUpperCase()}
                                </Text>
                            }
                        />
                        <Step
                            icon={<ActiveStep stepNumber={2} />}
                            title={
                                <Text textStyle="infoNormal">
                                    {t('CUSTOM_SERVICE_ITEM').toUpperCase()}
                                </Text>
                            }
                        />
                        <Step
                            icon={<ActiveStep stepNumber={3} />}
                            title={
                                <Text textStyle="infoNormal">
                                    {t('VIEW_INVOICE').toUpperCase()}
                                </Text>
                            }
                        />
                        <Step
                            icon={<ActiveStep stepNumber={4} />}
                            title={
                                <Text textStyle="infoNormal">
                                    {t('FINISH').toUpperCase()}
                                </Text>
                            }
                        />
                    </Steps>
                </StepSection>
            </ProgressSection>

            {renderQuoteInfo()}
            {renderStepContent()}
        </CreateQuoteContainer>
    );
};

export { template };

const AirportName = styled(Row)`
    align-items: center;
    flex-wrap: wrap;
    min-width: 150px;
    @media (max-width: 1366px) {
        ${(props) => props.expandedSidebar && 'max-width: 240px'};
    }
`;

const FlightStatusStep3 = styled.div`
    margin-bottom: 10px;
`;

const FlightInformationContainer = styled(Row)`
    background-color: #ffffff;
    border-radius: 4px;
    .request-info-left {
        border-right: 1px solid rgba(14, 14, 18, 0.1);
    }
    @media (max-width: 1200px) {
        .request-info-left {
            border-right: none;
        }
    }
`;

const TabsSection = styled.div`
    margin-top: 30px;
`;

const StepSection = styled.div`
    width: 80%;
    .ant-steps-item-title {
        width: 130px;
    }
`;

const CreateQuoteContainer = styled.div`
    padding: ${GLOBAL_STYLE_CONSTANTS.padding};
    .ant-steps-item-tail {
        height: 2px !important;
    }
    .ant-steps-item-tail::after {
        height: 2px !important;
        /* background-color: #19c0ff !important; */
    }
    .ant-steps-item-finish
        > .ant-steps-item-container
        > .ant-steps-item-tail::after {
        background-color: #19c0ff;
    }
`;

const ProgressSection = styled.div`
    background-color: #ffffff;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
`;

const Dashed = styled.div`
    border: 1px dashed rgba(14, 14, 18, 0.2);
    width: 6vw;
    max-width: 120px;
    min-width: 40px;
`;

const StepIndex = styled.span`
    color: ${(props) => (props.active ? '#ffffff' : 'rgb(101, 104, 111)')};
    font-family: AvenirNext-DemiBold;
    font-size: 16px;
`;

const StepWrapper = styled.div`
    background-color: ${(props) => (props.active ? '#19C0FF' : '#F7F8FB')};
    border-radius: 100%;
    width: 36px;
    height: 36px;
    align-items: center;
    display: flex;
    justify-content: center;
`;
