import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { CustomerContainer } from '../styles';
import { Header, Tabs } from 'components';
import { aboutIcon, flightPostIcon } from 'assets/images/icons';
import MyBookings from './owner';
import PartnerBookings from './partner';

const template = (props) => {
    const { navigate } = props;
    const { t, i18n } = useTranslation();

    const _renderBookingWithType = () => {
        const tabRenderer = [
            {
                key: 'MY_BOOKINGS',
                label: t('MY_BOOKINGS').toUpperCase(),
                render: <MyBookings />,
                icon: aboutIcon
            },
            {
                key: 'PARTNER_BOOKINGS',
                label: t('PARTNER_BOOKINGS').toUpperCase(),
                render: <PartnerBookings />,
                icon: flightPostIcon
            }
        ];
        return <Tabs tabRenderer={tabRenderer} />;
    };

    return (
        <CustomerContainer>
            <Header
                title={t('BOOKINGS')}
                description={t('BOOKINGS_SUBTITLE')}
            />
              
            <div style={{ marginTop: '20px' }}>
                { _renderBookingWithType() }
            </div>
        </CustomerContainer>
    );
};

export { template };
