import API from '../../modules/httpModule';
import { BOOKING_STATUS, CREW_ROLE } from '../Constants';

/**
 * get company booking
 * @returns
 */
export function fetchBookings(
    status,
    limit = 5,
    page = 1,
    partnerBooking = false
) {
    return async (dispatch) => {
        try {
            let result = {};
            let query = {
                limit: limit,
                page: page,
                sort_column: 'updated_at',
                sort_direction: 'desc'
            };
            if (status) query['status'] = status;
            if (partnerBooking) query['partner_booking'] = true;

            const response = await API.get('/v2/bookings', query);
            if (response && response.status === 200 && response.data) {
                result = response.data;
            }
            return result;
        } catch (err) {
            return {}; // empty
        }
    };
}

export function fetchDashboardBookings(limit = 5, page = 1) {
    return async (dispatch) => {
        try {
            let result = {};
            let query = {
                limit: limit,
                page: page,
                sort_column: 'updated_at',
                sort_direction: 'desc'
            };

            const response = await API.get('/dashboard/bookings', query);
            if (response && response.status === 200 && response.data) {
                result = response.data;
            }
            return result;
        } catch (err) {
            return []; // empty
        }
    };
}

/**
 * Get booking detail by id
 * @param {String} id - booking id in string
 * @returns
 */
export function getBookingById(id) {
    return async (dispatch) => {
        try {
            const response = await API.get(`/bookings/${id}`);
            if (response && response.status === 200) {
                return {
                    success: true,
                    isConnected: true,
                    data: response.data
                };
            }
        } catch (err) {
            // dispatch errors
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * Search Booking by booking code or booker name
 * @param {String} key - input search
 * @param {String} status - type of BOOKING_STATUS
 * @returns bookings
 */
export function searchBooking(
    key,
    limit = 5,
    page = 1,
    status,
    partnerBooking = false
) {
    return async (dispatch) => {
        try {
            let query = {
                key: key,
                limit: limit,
                page: page,
                sort_column: 'updated_at',
                sort_direction: 'desc'
            };
            if (status) query['status'] = status;
            if (partnerBooking) query['partner_booking'] = true;

            const response = await API.get(`/search/bookings`, query);
            if (response && response.status === 200) {
                return response.data;
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * Add fbo for a flight
 * @param {String} id - flight id
 * @param {Object} data - request body
 * @param {String} data.departure_fbo - fbo id
 * @param {String} data.arrival_fbo - fbo id
 * @returns
 */
export function addFbo(id, data) {
    try {
        return async (dispatch) => {
            await API.put(`/flights/${id}`, data);
        };
    } catch (err) {
        return {
            success: false,
            isConnected: false,
            error: err
        };
    }
}

/**
 * Add note for a flight
 * @param {String} id - flight id
 * @param {Object} data - request body
 * @param {String} data.category - default 'GENERAL'
 * @param {String} data.content - note content
 * @returns
 */
export function addNote(id, data) {
    try {
        return async (dispatch) => {
            const response = await API.post(`/flights/${id}/note`, data);
            if (response && response.status === 200) {
                return {
                    success: true
                };
            }
        };
    } catch (err) {
        return {
            success: false,
            error: err
        };
    }
}

/**
 * new crew to a flight
 * @param {String} id - flight id
 * @param {Object} data - requeset body
 * @param {String} data.user_id - crew id to be added
 * @param {String} data.role - CREW_ROLE
 * @returns
 */
export function addCrew(id, data) {
    try {
        return async (dispatch) => {
            await API.post(`/flights/${id}/crew`, data);
        };
    } catch (err) {
        return {
            success: false,
            isConnected: false,
            error: err
        };
    }
}

/**
 * remove onboard crew of a flight
 * @param {String} id - flight id
 * @param {Object} data - requeset body
 * @param {String} data.user_id - crew id to be added
 * @returns
 */
export function removeCrew(id, data) {
    try {
        // /p/flights/:id/crew
        return async (dispatch) => {
            await API.delete(`/flights/${id}/crew`, data);
        };
    } catch (err) {
        return {
            success: false,
            isConnected: false,
            error: err
        };
    }
}

/**
 * Confirm booking
 * @param {String} bookingId
 * @param {String} date
 * @param {Boolean} marked_as_paid
 * @returns
 */
export function confirmBooking(bookingId, data) {
    return async (dispatch) => {
        try {
            const response = await API.put(
                `/bookings/${bookingId}/confirm`,
                data
            );
            if (response && response.status === 200) {
                return {
                    success: true
                };
            }
        } catch (err) {
            return {
                success: false,
                error: err
            };
        }
    };
}
