import React from 'react';
import 'antd/dist/antd.css';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
    waitingIcon,
    cancelIcon,
    confirmedIcon
} from '../../assets/images/icons';
import { FLIGHT_STATUS } from '../../redux/Constants';

const FlightStatus = ({ status }) => {
    const { t, i18n } = useTranslation();

    let section = null;
    if (status === FLIGHT_STATUS.CANCELLED) {
        section = (
            <>
                <Icon src={cancelIcon} />
                <ItemText className="status">{i18n.t(status)}</ItemText>
            </>
        );
    }
    if (status === FLIGHT_STATUS.AWAITING_CONFIRMATION) {
        section = (
            <>
                <Icon src={waitingIcon} />
                <ItemText className="status">{i18n.t(status)}</ItemText>
            </>
        );
    }
    if (
        status === FLIGHT_STATUS.CONFIRMED ||
        status === FLIGHT_STATUS.CREATED ||
        status === FLIGHT_STATUS.EXECUTED
    ) {
        section = (
            <>
                <Icon src={confirmedIcon} />
                <ItemText className="status">{i18n.t(status)}</ItemText>
            </>
        );
    }

    return <StatusContianer>{section}</StatusContianer>;
};

const StatusContianer = styled.div`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`;

const ItemText = styled.span`
    color: #35383f;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    align-self: center;
`;

const Icon = styled.img``;

export default FlightStatus;
