// @flow ưeak

export const appConfig = {
    DEV_MODE: true,
    API_URL: process.env.REACT_APP_API_URL,
    DOMAIN: process.env.REACT_APP_PREFIX_WHITELABEL_DOMAIN
};

// default language
export const DEFAULT_LANGUAGE = 'en';

// Moment js date formats as per application requirements.
export const dateConfig = {
    format1: 'dddd, MMM Do', // shows date like Monday, June 1st
    format2: 'DD/MM', // shows date like 09/06
    format3: 'DD/MM/YYYY', // day month year
    format4: 'ddd, MMM DD', // eg. "Mon, Sep 07"
    format5: 'YYYY-MM-DD'
};

// socket
export const SOCKET_URL = process.env.REACT_APP_API_CONVERSATION_URL;

// fireabse config
export const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSEGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
};
