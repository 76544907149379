import React, { useState } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { Col, Row, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { HeaderInfo } from 'components/Cards';
import { ButtonCustom } from 'components';
import {
    Container,
    InfoWrapper,
    TypeTitle,
    Label,
    BottomContainer,
    Wrapper,
    TitleSection,
    TextSection
} from '../styles';

const Payment = (props) => {
    const { payment = {}, onSubmitPayment } = props;
    const { t, i18n } = useTranslation();

    const [isUpdate, setIsUpdate] = useState(false);

    // payment
    const [paymentMethod, setPaymentMethod] = useState('');
    const [cardHolder, setCardHolder] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [cardValid, setCardValid] = useState('');
    const [markPrimary, setMArkPrimary] = useState(true);

    // billing
    const [billingName, setBillingName] = useState('');
    const [billingAddress, setBillingAddress] = useState('');

    const onClickEdit = () => {
        setIsUpdate(!isUpdate);
    };

    const _renderEditView = () => {
        return (
            <WrapperUpdate>
                <InfoWrapper style={{ marginTop: 15 }}>
                    <TypeTitle>{i18n.t('PRIMARY_PAYMENT')}</TypeTitle>
                </InfoWrapper>
                <Row style={{ marginTop: 5 }}>
                    <Col lg="3" sm="3" xs="12">
                        <Label>{i18n.t('PAYMENT_METHOD').toUpperCase()}</Label>
                        <Input
                            onChange={(val) =>
                                setPaymentMethod(val.target.value)
                            }
                            className="base"
                            placeholder={i18n.t('PAYMENT_METHOD')}
                            value={paymentMethod}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: 5 }}>
                    <Col lg="3" sm="3" xs="12">
                        <Label>{i18n.t('CARD_HOLDER').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setCardHolder(val.target.value)}
                            className="base"
                            placeholder={i18n.t('CARD_HOLDER')}
                            value={cardHolder}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: 5 }}>
                    <Col lg="3" sm="3" xs="12">
                        <Label>{i18n.t('CARD_NUMBER').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setCardNumber(val.target.value)}
                            className="base left"
                            placeholder={i18n.t('CARD_NUMBER')}
                            value={cardNumber}
                        />
                    </Col>
                    <Col lg="3" sm="3" xs="12">
                        <Label>{i18n.t('VALID_TO').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setCardValid(val.target.value)}
                            className="base"
                            placeholder={i18n.t('VALID_TO')}
                            value={cardValid}
                        />
                    </Col>
                </Row>

                <InfoWrapper style={{ marginTop: 25 }}>
                    <TypeTitle>{i18n.t('BILLING_ADDRESS')}</TypeTitle>
                </InfoWrapper>
                <Row style={{ marginTop: 5 }}>
                    <Col lg="6" sm="6" xs="12">
                        <Label>{i18n.t('NAME').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setBillingName(val.target.value)}
                            className="address"
                            placeholder={i18n.t('NAME')}
                            value={billingName}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: 5 }}>
                    <Col lg="6" sm="6" xs="12">
                        <Label>{i18n.t('BILLING_ADDRESS').toUpperCase()}</Label>
                        <Input
                            onChange={(val) =>
                                setBillingAddress(val.target.value)
                            }
                            className="address"
                            placeholder={i18n.t('BILLING_ADDRESS')}
                            value={billingAddress}
                        />
                    </Col>
                </Row>
                <BottomContainer>
                    <ButtonCustom
                        btnType="primary"
                        width="190px"
                        onClick={() => onSubmitPayment()}
                        title={i18n.t('SAVE_CHANGES').toUpperCase()}
                    />
                </BottomContainer>
            </WrapperUpdate>
        );
    };

    const _renderDetailView = () => {
        return (
            <WrapperDetail>
                <Wrapper>
                    <InfoWrapper>
                        <TypeTitle>{i18n.t('PRIMARY_PAYMENT')}</TypeTitle>
                    </InfoWrapper>
                    <InfoWrapper className="label-detail">
                        <TitleSection>{i18n.t('PAYMENT_METHOD')}</TitleSection>
                        <TextSection>{paymentMethod || '_'}</TextSection>
                    </InfoWrapper>
                    <InfoWrapper className="label-detail">
                        <TitleSection>{i18n.t('CARD_HOLDER')}</TitleSection>
                        <TextSection>{cardHolder || '_'}</TextSection>
                    </InfoWrapper>
                    <InfoWrapper className="label-detail">
                        <TitleSection>{i18n.t('CARD_NUMBER')}</TitleSection>
                        <TextSection>{cardNumber || '_'}</TextSection>
                    </InfoWrapper>
                    <InfoWrapper className="label-detail">
                        <TitleSection>{i18n.t('VALID_DATE')}</TitleSection>
                        <TextSection>{cardValid || '_'}</TextSection>
                    </InfoWrapper>
                </Wrapper>
                <Wrapper>
                    <InfoWrapper className="label-detail">
                        <TypeTitle>{i18n.t('BILLING_ADDRESS')}</TypeTitle>
                    </InfoWrapper>
                    <InfoWrapper className="label-detail">
                        <TitleSection>{i18n.t('NAME')}</TitleSection>
                        <TextSection>{billingName || '_'}</TextSection>
                    </InfoWrapper>
                    <InfoWrapper className="label-detail">
                        <TitleSection>{i18n.t('ADDRESS')}</TitleSection>
                        <TextSection>{billingAddress || '_'}</TextSection>
                    </InfoWrapper>
                </Wrapper>
            </WrapperDetail>
        );
    };

    return (
        <Container>
            <HeaderInfo
                title={i18n.t('BILLING_AND_PAYMENT').toUpperCase()}
                actionText={i18n.t('EDIT').toUpperCase()}
                isUpdate={isUpdate}
                onClick={onClickEdit}
            />
            {!isUpdate ? _renderDetailView() : _renderEditView()}
        </Container>
    );
};

export default Payment;

const WrapperDetail = styled.div`
    padding-left: 20px;
    padding-bottom: 20px;
`;

const WrapperUpdate = styled.div`
    padding-left: 45px;
`;
