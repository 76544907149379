import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import ConfirmedBooking from '../ConfirmedBooking';
import AwaitingBooking from '../AwaitingPayment';
import NewBooking from '../NewBooking';
import BookingCancelled from '../BookingCancelled';
import SearchResult from '../SearchResult';

import { SearchBooking } from 'components/Commons';
import { bookingCategory } from 'config/Constants';

const template = (props) => {
    const {
        navigate,
        onSearch,
        loading = false,
        awaitingList = {},
        newList = {},
        confirmedList = {},
        cancelledList = {},
        searchList = {},
        onSelectCategory,
        onChangePageNewBooking,
        onChangePageSearch,
        onChangePageAwaiting,
        onChangePageConfirmed,
        onChangePageCancelled,
        searchBy = '',
        key = ''
    } = props;
    const { t, i18n } = useTranslation();

    const createCategoryOption = () => {
        let tempArr = [];
        let key;
        for (key in bookingCategory) {
            tempArr.push({
                text: t(bookingCategory[key]),
                value: bookingCategory[key]
            });
        }
        return tempArr;
    };

    return (
        <>
            <SearchBooking
                data={createCategoryOption()}
                onSearch={onSearch}
                onSelectCategory={onSelectCategory}
                placeholder={t('PLACEHOLDER_SEARCH_BOOKING')}
            />

            {!key ? (
                <>
                    {/* New Bookings */}
                    {(searchBy === bookingCategory.ALL ||
                        searchBy === bookingCategory.NEW_BOOKING) && (
                        <NewBooking
                            onChangePageNewBooking={onChangePageNewBooking}
                            list={newList}
                            laoding={loading}
                            navigate={navigate}
                        />
                    )}

                    {/* Awaiting Confirmation */}
                    {(searchBy === bookingCategory.ALL ||
                        searchBy === bookingCategory.AWAITING_PAYMENT) && (
                        <AwaitingBooking
                            onChangePageAwaiting={onChangePageAwaiting}
                            list={awaitingList}
                            laoding={loading}
                            navigate={navigate}
                        />
                    )}

                    {/* Booking Confirmed */}
                    {(searchBy === bookingCategory.ALL ||
                        searchBy === bookingCategory.BOOKING_CONFIRMED) && (
                        <ConfirmedBooking
                            onChangePageConfirmed={onChangePageConfirmed}
                            list={confirmedList}
                            laoding={loading}
                            navigate={navigate}
                        />
                    )}

                    {/* Booking Cancelled */}
                    {(searchBy === bookingCategory.ALL ||
                        searchBy === bookingCategory.BOOKING_CANCELLED) && (
                        <BookingCancelled
                            onChangePageCancelled={onChangePageCancelled}
                            list={cancelledList}
                            laoding={loading}
                            navigate={navigate}
                        />
                    )}
                </>
            ) : (
                <SearchResult
                    onChangePageSearch={onChangePageSearch}
                    list={searchList}
                    laoding={loading}
                    navigate={navigate}
                />
            )}
        </>
    );
};

export { template };
