import React from 'react';
import { boxIcon } from 'assets/images/icons';
import styled from 'styled-components';

/**
 * @param width
 * @param height
 * @param size - size of icon, default: 64
 * @param description - default : 'No data'
 * @returns {JSX}
 */
const EmptyComponent = (props) => {
    const {
        description = 'NO DATA',
        iconSize = 48,
        padding,
        styleContainer = {}
    } = props;

    return (
        <EmptyContainer padding={padding} style={styleContainer}>
            <EmptyWrapper>
                <Icon
                    style={{ width: iconSize, height: iconSize }}
                    src={boxIcon}
                />
            </EmptyWrapper>
            <EmptyWrapper>
                <Description>{description}</Description>
            </EmptyWrapper>
        </EmptyContainer>
    );
};

export default EmptyComponent;

const EmptyContainer = styled.div`
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    margin-top: 20px;
    padding-top: ${(props) => props.padding || '75px'};
    padding-bottom: ${(props) => props.padding || '75px'};
    width: 100%;
    height: 90%;
`;

const Icon = styled.img`
    width: 48px;
    height: 48px;
`;

const EmptyWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
`;

const Description = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    letter-spacing: 0.4px;
    line-height: 13px;
    text-align: left;
    margin-top: 15px;
`;
