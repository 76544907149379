import React from 'react';
import styled from 'styled-components';
import { Col, Row, Avatar, Upload, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import {
    AboutWrapper,
    MenuTitle,
    PhotoWrapper,
    Photo,
    BottomContainer
} from '../styles';
import {
    callIcon,
    emailIcon,
    plusIcon,
    removeIcon
} from 'assets/images/icons';
import { ADD_NEW_EMPLOYEE } from 'routes/main';
import { EmptyComponent, ButtonCustom } from 'components';

const { TextArea } = Input;

const AboutComponent = (props) => {
    const {
        setDescription,
        description = '',
        employees = [],
        edit = false,
        images = [],
        addPhotoImages,
        onSubmitEdit,
        onRemoveImage,
        navigate
    } = props;
    const { t, i18n } = useTranslation();

    return (
        <AboutWrapper>
            <MenuTitle>{i18n.t('ABOUT_US').toUpperCase()}</MenuTitle>
            {edit ? (
                <TextArea
                    onChange={(val) => setDescription(val.target.value)}
                    autoSize={{ minRows: 1, maxRows: 100 }}
                    style={{
                        color: '#65686f',
                        marginTop: 20,
                        borderRadius: 4,
                        padding: 20,
                        border: 'none',
                        fontFamily: 'Lato'
                    }}
                    placeholder={t('DESCRIPTION')}
                    value={description}
                />
            ) : description ? (
                <TextArea
                    style={{
                        color: '#65686f',
                        marginTop: 20,
                        borderRadius: 4,
                        padding: 20,
                        border: 'none',
                        fontFamily: 'Lato'
                    }}
                    autoSize={{ minRows: 1, maxRows: 100 }}
                    value={description}
                />
            ) : (
                <EmptyComponent />
            )}

            <MenuTitle style={{ marginTop: 35 }}>{`${i18n
                .t('OUR_TEAM')
                .toUpperCase()} (${employees.length})`}</MenuTitle>
            {(employees && employees.length > 0) || edit ? (
                <PhotoWrapper>
                    <Row>
                        {employees.map((v, i) => (
                            <Col xxl={6} xl={8} lg={12} md={12} sm={18} xs={24} key={i}>
                                <TeamWrapper>
                                    <Col style={{ alignSelf: 'center', marginRight: '15px' }}>
                                        {v.image ? (
                                            <Avatar
                                                className="ant-avatar-custom avatar-item"
                                                src={v.image}
                                                size={86}
                                            />
                                        ) : (
                                            <Avatar
                                                className="ant-avatar-custom avatar-item"
                                                size={86}
                                            >
                                                {(
                                                    v.firstname.charAt(0) +
                                                    v.lastname.charAt(0)
                                                ).toUpperCase()}
                                            </Avatar>
                                        )}
                                    </Col>
                                    <Col style={{ alignSelf: 'center' }}>
                                        <div>
                                            <TeamName>
                                                {v.name
                                                    ? v.name.toUpperCase()
                                                    : '_'}
                                            </TeamName>
                                        </div>
                                        <div>
                                            <TeamRole>{v.role}</TeamRole>
                                        </div>
                                        <Wrapper>
                                            <Icon
                                                style={{
                                                    width: 12,
                                                    height: 12,
                                                    marginLeft: 0
                                                }}
                                                src={callIcon}
                                            />
                                            <TeamItem>{v.phone}</TeamItem>
                                        </Wrapper>
                                        <Wrapper>
                                            <Icon
                                                style={{
                                                    width: 12,
                                                    height: 12,
                                                    marginLeft: 0
                                                }}
                                                src={emailIcon}
                                            />
                                            <TeamItem>{v.email}</TeamItem>
                                        </Wrapper>
                                    </Col>
                                </TeamWrapper>
                            </Col>
                        ))}
                        {edit && (
                            <Col xxl={6} xl={8} lg={12} md={12} sm={18} xs={24}>
                                <PlusWrapper
                                    key="add"
                                    onClick={() => navigate(ADD_NEW_EMPLOYEE)}
                                >
                                    <Icon
                                        src={plusIcon}
                                        style={{
                                            width: 12,
                                            height: 12,
                                            marginRight: 10
                                        }}
                                    />
                                    <TeamItem font="AvenirNext-DemiBold">
                                        {t('ADD_NEW_TEAM_MEMBER').toUpperCase()}
                                    </TeamItem>
                                </PlusWrapper>
                            </Col>
                        )}
                    </Row>
                </PhotoWrapper>
            ) : (
                <EmptyComponent />
            )}

            <MenuTitle style={{ marginTop: 35 }}>{`${i18n
                .t('PHOTOS')
                .toUpperCase()} (${images.length})`}</MenuTitle>
            {(images && images.length > 0) || edit ? (
                <>
                    <PhotoWrapper>
                        <Row>
                            {images.map((v, i) => (
                                <Col xxl={6} xl={8} lg={12} md={12} sm={18} xs={24} key={i}>
                                    <div style={{ position: 'relative' }} className="img-wrapper">
                                        <Photo key={i} url={v?.lowres?.url} className="hover-zoom" />
                                        { edit && (
                                            <RemoveButton onClick={() => onRemoveImage && onRemoveImage(v)}>
                                                <Icon src={removeIcon} style={{ width: 25, height: 25 }} />
                                            </RemoveButton>
                                        )}
                                    </div>
                                </Col>
                            ))}
                            {edit && (
                                <Col xxl={6} xl={8} lg={12} md={12} sm={18} xs={24}>
                                    <PlusWrapper minHeight="220px" key="add">
                                        <Upload
                                            showUploadList={false}
                                            beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                                            onChange={addPhotoImages}
                                            multiple={false}
                                            key="add"
                                        >
                                            <UploadWrapper>
                                                <Icon
                                                    src={plusIcon}
                                                    style={{
                                                        width: 12,
                                                        height: 12
                                                    }}
                                                />
                                                <TeamItem
                                                    width="auto"
                                                    font="AvenirNext-DemiBold"
                                                    style={{ textAlign: 'center' }}
                                                >
                                                    {t(
                                                        'UPLOAD_PHOTO'
                                                    ).toUpperCase()}
                                                </TeamItem>
                                            </UploadWrapper>
                                        </Upload>
                                    </PlusWrapper>
                                </Col>
                            )}
                        </Row>
                    </PhotoWrapper>
                </>
            ) : (
                <EmptyComponent />
            )}

            {edit && (
                <BottomContainer>
                    <ButtonCustom
                        btnType="primary"
                        width="190px"
                        onClick={() => onSubmitEdit && onSubmitEdit()}
                        title={i18n.t('SAVE_CHANGES').toUpperCase()}
                    />
                </BottomContainer>
            )}
        </AboutWrapper>
    );
};

export default AboutComponent;

const Icon = styled.img`
    margin: 5px;
`;

const TeamWrapper = styled.div`
    background-color: #ffffff;
    border-radius: 4px;
    min-width: 320px;
    min-height: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    margin-top: 20px;
    position: relative;
`;

const RemoveButton = styled.button`
    border: none;
    background-color: transparent;
    position: absolute;
    right: 15px;
    top: 0px;
    cursor: pointer;
`;

const TeamName = styled.span`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.8px;
    line-height: 12px;
    width: 199px;
    text-align: left;
`;

const TeamRole = styled.span`
    color: #19c0ff;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    line-height: 13px;
    width: 199px;
    text-align: left;
`;

const TeamItem = styled.span`
    color: #65686f;
    font-family: ${(props) => props.font || 'AvenirNext-Medium'};
    font-size: 11px;
    line-height: 14px;
    width: ${(props) => props.width || '180px'};
    text-align: left;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PlusWrapper = styled.a`
    background-color: transparent;
    border: 1px solid #65686f;
    border-radius: 4px;
    border-style: dashed;
    min-width: 320px;
    min-height: ${(props) => props.minHeight || '128px'};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    margin-top: 20px;
`;

const UploadWrapper = styled.div`
    min-width: 320px;
    min-height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
`;