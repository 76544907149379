import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';

const BackgroundProfile = styled.div`
    position: relative;
    background-color: rgba(52, 52, 52, 0.8);
    opacity: 1;
    background-image: url(${(props) => props.background});
    height: 355px;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-wrap: wrap;
    .ant-row-center {
        padding-left: 3rem;
        padding-top: 1rem;
        padding-right: 3rem;
    }
    .overlay-background {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: ${(props) =>
            !props.edit ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.65)'};
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    @media (max-width: 1830px) {
        .fbo-name-custom {
            max-width: 1000px;
        }
    }
    @media (max-width: 1600px) {
        .fbo-name-custom {
            max-width: 800px;
        }
    }
    @media (max-width: 1366px) {
        .fbo-name-custom {
            max-width: 650px;
        }
    }
    @media (max-width: 1024px) {
        .fbo-name-custom {
            max-width: 500px;
        }
    }
    @media (max-width: 768px) {
        .fbo-name-custom {
            max-width: 350px;
        }
    }
`;

const CustomerContainer = styled.div`
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    .ant-header-custom-employees {
        background-color: #f2f4f7;
    }
`;

const SearchAndSort = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const CustomerDetailContainer = styled.div`
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 40px;
`;

const ContainerWrapper = styled.div`
    background-color: #fff;
    border-radius: 4px;
    margin-left: 40px;
    margin-right: 40px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 40px;
`;

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 35px;
`;

const WrapperText = styled.div`
    justify-content: flex-start;
    align-self: flex-start;
    background-color: orange;
    flex-wrap: wrap;
`;

const Label = styled.div`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 12px;
    margin-left: ${(props) => (props.rightInput ? '30px' : '0px')};
`;

const UploadText = styled.div`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
    margin: 15px;
`;

const WrapperComponent = styled.div`
    display: flex;
    flex-direction: column;
`;

const MenuTitle = styled.div`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.7px;
    line-height: 16px;
    text-align: left;
    margin-top: 10px;
`;

const Title = styled.div`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    line-height: 13px;
    text-align: left;
`;

const Component = styled.div`
    background-color: #ffffff;
    border-radius: 4px;
    padding: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
`;

const UploadButton = styled.button`
    border: 1px solid rgba(18, 18, 22, 0.2);
    border-radius: 2px;
    width: 242px;
    height: 32px;
    background-color: transparent;
    margin-top: 17px;
`;

const ButtonText = styled.span`
    color: #16161b;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: center;
`;

const Container = styled.div``;

const TitleDescription = styled.div`
    color: ${(props) => (props.color ? props.color : '#121216')};
    font-family: AvenirNext-DemiBold;
    font-size: ${(props) => props.size || '10px'};
    letter-spacing: 0.7px;
    line-height: 18px;
    text-align: left;
`;

const Name = styled.span`
    color: ${(props) => (props.color ? props.color : '#121216')};
    font-family: AvenirNext-DemiBold;
    font-size: 20px;
    letter-spacing: 0.8px;
    line-height: 27px;
    text-align: left;
`;

const SearchSection = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
    .ant-input {
        background-color: transparent;
    }
    .ant-input-affix-wrapper {
        border: 1px solid #dadadb;
        border-radius: 3px;
        height: 40px;
        background-color: transparent;
    }
`;

const MembershipText = styled.span`
    color: #19c0ff;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
`;

const ItemText = styled.span`
    color: #35383f;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    align-self: center;
`;

const NameSectionButton = styled.button`
    display: flex;
    flex-direction: row;
    border: none;
    background-color: transparent;
`;

const DetailSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 5px;
`;

const PhoneAndCompany = styled(Col)`
    padding-left: 60px;
    padding-right: 60px;
`;

const AddressAndSince = styled(Col)`
    padding-left: 60px;
`;

const EmailAndRole = styled(Col)`
    padding-right: 60px;
`;

const InfoDetail = styled(Row)``;

const NameSection = styled(Row)``;

const InfoDetailSection = styled(Col)`
    margin-left: 20px;
`;

const AvatarSection = styled(Col)``;

const GeneralSection = styled(Col)`
    flex: 1 1 200px;
    display: flex;
    align-items: center;
    margin-left: 5px;
`;

const EachButton = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
`;

const ButtonSection = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-right: 5px;
`;

const AboutContent = styled.span`
    font-family: AvenirNext-DemiBold;
    background-color: #fff;
    font-size: 14px;
    line-height: 22px;
    padding: 20px;
    border-radius: 5px;
    margin-top: 20px;
`;

const AboutText = styled.div`
    color: #65686f;
    font-family: Lato;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
`;

const AboutWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const AvatarWrapper = styled.div`
    height: 148px;
    position: absolute;
    bottom: -64px;
    left: 15px;
`;

const Comapy = styled.span`
    color: #19c0ff;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
`;

const EditButton = styled.button`
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 2px;
    width: 180px;
    height: 32px;
    margin-bottom: 25px;
    position: absolute;
    right: 25px;
`;

const EditText = styled.div`
    color: #ffffff;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: center;
`;

const ExpandButton = styled.button`
    background-color: #fff;
    border: 1px solid rgba(18, 18, 22, 0.2);
    border-radius: 2px;
    width: 220px;
    height: 32px;
    margin: 25px;
`;

const ExpandText = styled.span`
    color: #16161b;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: center;
`;

const ExpandWrapper = styled.div`
    height: 321;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-self: center;
`;

const Photo = styled.div`
    background-image: url(${(props) => props.url});
    border-radius: 4px;
    min-width: 320px;
    min-height: 220px;
    margin-right: 20px;
    margin-top: 20px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const PhotoWrapper = styled.div`
    display: flex;
    align-self: center;
    width: 100%;
`;

const ProfileInfo = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
    padding-bottom: 20px;
    background-color: #ffffff;
    min-height: 65px;
    padding-left: 225px;
`;

const TabBarNavigator = styled.div`
    display: flex;
    align-items: center;
    margin: 40px;
    margin-top: 20px;

    .ant-tabs-tab {
        padding-bottom: 6px;
    }
    .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 0;
    }

    .ant-tabs-top > .ant-tabs-nav::before {
        border-color: rgba(56, 56, 65, 0.05);
        border-width: 2px;
    }
`;

const TabText = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    margin-top: 15px;
    margin-left: 8px;
`;

export {
    AddressAndSince,
    EachButton,
    EmailAndRole,
    PhoneAndCompany,
    ButtonSection,
    NameSection,
    GeneralSection,
    AvatarSection,
    InfoDetail,
    InfoDetailSection,
    Container,
    CustomerContainer,
    SearchAndSort,
    CustomerDetailContainer,
    ContainerWrapper,
    BottomContainer,
    WrapperText,
    Label,
    UploadText,
    WrapperComponent,
    MenuTitle,
    Title,
    Component,
    UploadButton,
    ButtonText,
    TitleDescription,
    Name,
    SearchSection,
    MembershipText,
    ItemText,
    DetailSection,
    NameSectionButton,
    AboutContent,
    AboutText,
    AboutWrapper,
    AvatarWrapper,
    BackgroundProfile,
    Comapy,
    EditButton,
    EditText,
    ExpandButton,
    ExpandText,
    ExpandWrapper,
    Photo,
    PhotoWrapper,
    ProfileInfo,
    TabBarNavigator,
    TabText
};
