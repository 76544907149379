import React, { useState, useEffect } from 'react';
import { template as tmpl } from './NotificationList.tmpl';
import {
    onReadAllNotification,
    onReadNotification,
    fetchMoreNotifications
} from 'redux/actions/appActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
    BOOKING_DETAIL,
    FLIGHT_DETAIL,
    COMPANY_INVOICE_DETAIL,
    QUOTE_REQUEST_DETAIL
} from 'routes/main';
import _ from 'lodash';
import { NOTIFICATION_TYPES } from 'config/Constants';

const NotificationListLogic = (props) => {
    const dispatch = useDispatch();
    const companyReducer = useSelector((state) => state.companyReducer);
    const { notifications = {} } = companyReducer;

    const [key, setKey] = useState('');
    const [sortBy, setSortBy] = useState('week'); // default all
    const [notiItem, setNotiItems] = useState({});

    useEffect(() => {
        if (!_.isEmpty(notifications)) {
            setNotiItems(notifications);
        }
    }, [notifications]);

    /**
     * Function handles searching when user
     * @param {*} e event when user is typing
     */
    const onSearch = async (e) => {
        //
    };

    const onLoadMore = async (next, limit, page, pages) => {
        if (next) {
            dispatch(fetchMoreNotifications(next, limit));
        }
    };

    const onClickMarkAllRead = async () => {
        dispatch(onReadAllNotification());
    };

    const onClickReadNoti = async (notiId, seen, _onRef, _on) => {
        // if (!seen) {
        dispatch(onReadNotification(notiId));
        // }

        switch (_onRef) {
            case NOTIFICATION_TYPES.FLIGHT:
                navigate(FLIGHT_DETAIL, _on);
                break;
            case NOTIFICATION_TYPES.BOOKING:
                navigate(BOOKING_DETAIL, _on);
            case NOTIFICATION_TYPES.COMPANY:
                // navigate();
                break;
            case NOTIFICATION_TYPES.INVOICE:
                navigate(COMPANY_INVOICE_DETAIL, _on);
                break;
            case NOTIFICATION_TYPES.QUOTE_REQUEST:
                navigate(QUOTE_REQUEST_DETAIL, _on);
                break;
            default:
                break;
        }
    };

    const navigate = (route, id, state) => {
        props.history.push({
            pathname: route,
            search: id,
            state: state
        });
    };

    const initProps = {
        notifications: notiItem,
        onSearch: onSearch,
        navigate: navigate,
        onLoadMore: onLoadMore,
        onClickMarkAllRead: onClickMarkAllRead,
        onClickReadNoti: onClickReadNoti,
        key: key,
        sortBy: sortBy
    };

    return tmpl && tmpl({ ...initProps });
};

class NotificationListContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <NotificationListLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { notifications } = companyReducer;
    return {
        notifications: notifications
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        //
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationListContainer);
