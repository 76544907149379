import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { InputNumber, Input, message, Button } from 'antd';
import { Text, ButtonCustom } from 'components';
import { useTranslation } from 'react-i18next';
import { numberFormat, getFlightName } from 'utils';
import _ from 'lodash';
import { checkedIcon, plusIcon } from 'assets/images/icons';
// import AircraftDetailCard from 'views/BrokerConnections/components/AircraftDetailCard';
import { AircraftDetailCardComponent as AircraftDetailCard } from 'components/Cards';

const InvoiceItemDetail = ({
    index = 0,
    type = '',
    title = '',
    subtitle = '',
    price = 0,
    quantity = 1,
    onEditItem,
    onSaveItem,
    onCancelAddItem,
    allowEdit = false,
    isTitle = false,
    adding = false
}) => {
    const { t, i18n } = useTranslation();
    const [editing, setEditing] = useState(false);
    const [item, setItem] = useState({});

    useEffect(() => {
        setItem({
            title: title,
            subtitle: subtitle,
            price: price,
            quantity: quantity,
            allowEdit: allowEdit,
            isTitle: isTitle,
            adding: adding
        });
        setEditing(false);
    }, [title, subtitle, price, quantity, allowEdit, isTitle, adding]);

    const onSave = async () => {
        let rs;
        if (item.adding) {
            rs = await onSaveItem(index, item);
            if (rs && rs.success) {
                setItem({ ...item, adding: false });
            } else {
                // reset total price
                setItem({ ...item, price: price });
                message.error(t('ERROR_PRICE_COMPONENTS'));
            }
            return;
        }

        if (editing) {
            rs = await onEditItem(type, item.price, index);
            if (rs && rs.success) {
                setItem({ ...item });
            } else {
                // reset total price
                setItem({ ...item, price: price });
                message.error(t('ERROR_PRICE_COMPONENTS'));
            }
            setEditing(false);
            return;
        }
    };

    const onCancel = () => {
        setEditing(false);
        if (item.adding) {
            onCancelAddItem && onCancelAddItem(index);
        }
        setItem({ ...item, price: price });
    };

    const validateSaveBtn = () => {
        let valid = false;
        if (
            (item.title || item.subtitle) &&
            item.price > 0 &&
            item.quantity > 0
        ) {
            valid = true;
        }
        return valid;
    };

    if (item.isTitle) {
        return (
            <tr>
                <td
                    className="invoice-item"
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center'
                    }}
                >
                    {item?.title ? (
                        <Title>{item?.title.toUpperCase()}</Title>
                    ) : (
                        <SubTitle>{item?.subtitle}</SubTitle>
                    )}
                </td>
            </tr>
        );
    }

    const _renderRow = () => {
        return (
            <tr className="invoice-row-item">
                <td
                    className="invoice-item"
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center'
                    }}
                >
                    {item.title ? (
                        <Title>{item.title.toUpperCase()}</Title>
                    ) : !item.adding ? (
                        <SubTitle>{item.subtitle}</SubTitle>
                    ) : (
                        <Input
                            placeholder={t('ENTER_NAME_EXTRA_FEE')}
                            value={item?.subtitle || ''}
                            style={{
                                width: '320px',
                                height: '32px'
                            }}
                            onChange={(val) =>
                                setItem({
                                    ...item,
                                    subtitle: val.target.value
                                })
                            }
                        />
                    )}
                </td>
                <td className="invoice-price pricing-invoice-cell">
                    {!editing && !item.adding ? (
                        <Price>{numberFormat(price, 'USD', 1)}</Price>
                    ) : (
                        <InputNumber
                            min={0}
                            formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(val) => setItem({ ...item, price: val })}
                            style={{ width: '120px', height: '32px' }}
                            value={item?.price || 0}
                        />
                    )}
                </td>
                <td className="invoice-quantity pricing-invoice-cell">
                    <Quantity>{quantity}</Quantity>
                </td>
                <td className="invoice-total-price pricing-invoice-cell">
                    <TotalPrice>
                        {numberFormat(
                            (item.price || 0) * (item.quantity || 1),
                            'USD',
                            1
                        )}
                    </TotalPrice>
                </td>
                {(item.allowEdit || item.adding) && (
                    <td className="invoice-action pricing-invoice-cell">
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            {(item.adding || editing) && (
                                <div style={{ margin: 5 }}>
                                    <ButtonCustom
                                        btnType="primary"
                                        size="small"
                                        width="65px"
                                        title={t('SAVE').toUpperCase()}
                                        onClick={() => onSave()} // onSave
                                        disabled={!validateSaveBtn()}
                                    />
                                </div>
                            )}

                            {(item.adding || editing) && (
                                <div style={{ margin: 5 }}>
                                    <ButtonCustom
                                        btnType="secondary"
                                        size="small"
                                        width="65px"
                                        title={t('CANCEL').toUpperCase()}
                                        onClick={() => onCancel()}
                                    />
                                </div>
                            )}

                            {!item.adding && !editing && (
                                <div style={{ margin: 5 }}>
                                    <ButtonCustom
                                        btnType="secondary"
                                        size="small"
                                        width="65px"
                                        title={t('EDIT').toUpperCase()}
                                        onClick={() => setEditing(true)}
                                    />
                                </div>
                            )}
                        </div>
                    </td>
                )}
            </tr>
        );
    };
    return _renderRow();
};

const InvoiceCard = (props) => {
    const {
        selectAircraft = {},
        services = [],
        leg_index = 0,
        invoice_index = 0,
        onSavePrice,
        added = false,
        onAddPackage,
        onRemovePackage,
        legId,
        type = '',
        fromAirport = {},
        toAirport = {},
        showAddBtn = true
    } = props;
    const { t, i18n } = useTranslation();

    const [aircraftItem, setAircraftItem] = useState({});
    const [isAdding, setIsAdding] = useState(false);

    const [flightPrice, setFlightPrice] = useState({}); // for editing

    useEffect(() => {
        if (!_.isEmpty(selectAircraft)) {
            setAircraftItem({ ...selectAircraft });
            setFlightPrice({ ...selectAircraft?.price_components });
        }
    }, [selectAircraft, selectAircraft.price_components]);

    const addExtraFee = () => {
        try {
            let item = {
                name: '',
                price: 0,
                quantity: 1,
                description: '',
                total: 0,
                adding: true
            };
            let newFlightPrice = flightPrice || {};
            if (!_.isEmpty(newFlightPrice.extra_fees)) {
                newFlightPrice.extra_fees.push(item);
            } else {
                newFlightPrice['extra_fees'] = [item];
            }

            setIsAdding(true);
            setFlightPrice({ ...newFlightPrice });
        } catch (e) {}
    };

    const onCancelAddItem = (index) => {
        try {
            let newFlightPrice = flightPrice || {};
            newFlightPrice.extra_fees.splice(index, 1);

            setIsAdding(false);
            setFlightPrice({ ...newFlightPrice });
        } catch (e) {
            setIsAdding(false);
        }
    };

    const onSaveItem = async (index, item = {}) => {
        try {
            let newItem = {
                name: item.subtitle,
                price: item.price,
                quantity: item.quantity || 1,
                description: '',
                total: 0
            };

            let newFlightPrice = selectAircraft.price_components || {};
            newFlightPrice.extra_fees[index] = newItem;

            setIsAdding(false);

            // update price in total invoice
            let rs = await onSavePrice(
                leg_index,
                invoice_index,
                newFlightPrice
            );
            if (rs && rs.success) {
                //
            } else {
                newFlightPrice.extra_fees.splice(index, 1);
                setFlightPrice({ ...newFlightPrice });
            }

            return rs;
        } catch (e) {
            setIsAdding(false);
            return;
        }
    };

    // type : FLIGHTS or EXTRA_FEE (update with index)
    // index : index of extra_fees
    const onEditItem = async (type, price = 0, index) => {
        try {
            const oldPrice = flightPrice?.extra_fees[index]?.price || 0;
            let newFlightPrice = { ...flightPrice };

            if (type === 'FLIGHTS') {
                newFlightPrice.revenue_fee = price;
            } else if (
                type === 'EXTRA_FEE' &&
                newFlightPrice.extra_fees[index]
            ) {
                newFlightPrice.extra_fees[index].price = price;
            }

            // update price in total invoice
            let rs = await onSavePrice(
                leg_index,
                invoice_index,
                newFlightPrice
            );
            if (rs && rs.success) {
                //
            } else {
                newFlightPrice.extra_fees[index].price = oldPrice;
                setFlightPrice({ ...newFlightPrice });
            }

            return rs;
        } catch (e) {
            return;
        }
    };

    const renderTableHeader = () => {
        return (
            <>
                <th style={{ textAlign: 'left' }}>{t('ITEM').toUpperCase()}</th>
                <th className="pricing-invoice-cell">
                    {t('PRICE').toUpperCase()}
                </th>
                <th className="pricing-invoice-cell">
                    {t('QTT').toUpperCase()}
                </th>
                <th className="pricing-invoice-cell">
                    {t('TOTAL_PRICE').toUpperCase()}
                </th>
                <th className="pricing-invoice-cell"></th>
            </>
        );
    };

    const renderTableData = () => {
        const {
            revenue_fee = 0,
            non_member_surcharge = 0,
            broker_fee = 0,
            extra_fees = []
        } = flightPrice;

        const flightName = getFlightName(
            type,
            leg_index,
            fromAirport,
            toAirport
        );
        return (
            <>
                {/* FLIGHTS */}
                <InvoiceItemDetail
                    title={t('FLIGHTS').toUpperCase()}
                    isTitle={true}
                />
                <InvoiceItemDetail
                    subtitle={flightName}
                    price={revenue_fee}
                    quantity={1}
                    allowEdit={true}
                    type="FLIGHTS"
                    onEditItem={onEditItem}
                />

                {/* SERVICE ITEMS */}
                <InvoiceItemDetail
                    title={t('SERVICE_ITEMS').toUpperCase()}
                    isTitle={true}
                />
                {services &&
                    services.map((service = {}, standardIndex) => {
                        const {
                            type = '',
                            code = '',
                            name = '',
                            note = '',
                            quantity = 0,
                            price = {}
                        } = service;
                        if (type === 'STANDARD') {
                            return (
                                <InvoiceItemDetail
                                    subtitle={name}
                                    price={price?.value}
                                    quantity={quantity}
                                    allowEdit={false}
                                />
                            );
                        }
                    })}

                {/* CUSTOM SERVICE ITEMS */}
                <InvoiceItemDetail
                    title={t('CUSTOM_SERVICE_ITEMS').toUpperCase()}
                    isTitle={true}
                />
                {services &&
                    services.map((service = {}, customIndex) => {
                        const {
                            type,
                            status,
                            code = '',
                            name = '',
                            note = '',
                            quantity = 0,
                            price = {}
                        } = service;
                        if (type === 'CUSTOM' && status === 'ACCEPTED') {
                            return (
                                <InvoiceItemDetail
                                    subtitle={name}
                                    price={price?.value}
                                    quantity={quantity}
                                    allowEdit={false}
                                />
                            );
                        }
                    })}

                {/* NON-MEMBER SURCHARGE */}
                <InvoiceItemDetail
                    title={t('NON_MEMBER_SURCHARGE').toUpperCase()}
                    price={non_member_surcharge}
                    quantity={1}
                    allowEdit={false}
                />

                {/* BROKER FEE */}
                <InvoiceItemDetail
                    title={t('BROKER_FEE').toUpperCase()}
                    price={broker_fee}
                    quantity={1}
                    allowEdit={false}
                />

                {/* EXTRA FEE */}
                <InvoiceItemDetail
                    title={t('EXTRA_FEE').toUpperCase()}
                    isTitle={true}
                />
                {extra_fees &&
                    extra_fees.map((extraItem = {}, index) => {
                        const {
                            name = '',
                            quantity = 1,
                            total = 0,
                            price = 0,
                            adding = false
                        } = extraItem;
                        return (
                            <InvoiceItemDetail
                                key={index}
                                type="EXTRA_FEE"
                                index={index}
                                subtitle={name}
                                price={price}
                                quantity={quantity}
                                allowEdit={true}
                                adding={adding}
                                onCancelAddItem={onCancelAddItem}
                                onSaveItem={onSaveItem}
                                onEditItem={onEditItem}
                            />
                        );
                    })}

                {!isAdding && (
                    <Button type="text" onClick={() => addExtraFee()}>
                        <Icon src={plusIcon} style={{ marginRight: 10 }} />
                        <Text textStyle="nameNormal" style={{ fontSize: 10 }}>
                            {t('ADD_EXTRA_FEE').toUpperCase()}
                        </Text>
                    </Button>
                )}
            </>
        );
    };

    const _renderTotal = () => {
        const {
            total_ex_vat = 0,
            total_vat = 0,
            total_inc_vat,
            tax_percentage = 0
        } = flightPrice || {};
        return (
            <Wrapper>
                <PriceLine>
                    <RowWrapper>
                        <LabelLight>{t('TOTAL').toUpperCase()}</LabelLight>
                        <ValueItem>
                            {numberFormat(total_ex_vat, 'USD', 1)}
                        </ValueItem>
                    </RowWrapper>
                </PriceLine>
                <PriceLine>
                    <RowWrapper style={{ marginTop: 10 }}>
                        <LabelLight>{`${t(
                            'VAT'
                        ).toUpperCase()} (${tax_percentage}%)`}</LabelLight>
                        <ValueItem>
                            {numberFormat(total_vat, 'USD', 1)}
                        </ValueItem>
                    </RowWrapper>
                </PriceLine>

                <Divider />
                <PriceLine>
                    <RowWrapper style={{ marginTop: 10 }}>
                        <LabelLight size="16px">
                            {t('TOTAL_PRICE').toUpperCase()}
                        </LabelLight>
                        <ValueItem size="16px">
                            {numberFormat(total_inc_vat, 'USD', 1)}
                        </ValueItem>
                    </RowWrapper>
                </PriceLine>
            </Wrapper>
        );
    };

    return (
        <Container>
            <ImageContainer className="create-quote-invoice-image-container">
                <AircraftDetailCard
                    aircraft={aircraftItem?.aircraft}
                    showThumbs={false}
                    from="INVOICE"
                />
            </ImageContainer>
            <div
                style={{
                    display: 'flex',
                    paddingBottom: 10,
                    justifyContent: 'space-between'
                }}
            >
                <Text textStyle="heading4">{t('INVOICES_ITEM')}</Text>
            </div>

            <table id="invoices">
                <tbody>
                    <tr>{renderTableHeader()}</tr>
                    {renderTableData()}
                </tbody>
            </table>

            {_renderTotal()}

            {showAddBtn && (
                <AddButtonWrap>
                    {!added ? (
                        <ButtonCustom
                            btnType="primary"
                            size="medium"
                            width="250px"
                            title={t('ADD_TO_PACKAGE').toUpperCase()}
                            onClick={() =>
                                onAddPackage(aircraftItem, services, legId)
                            }
                        />
                    ) : (
                        <ButtonCustom
                            icon={checkedIcon}
                            btnType="secondary"
                            size="medium"
                            width="250px"
                            title={t('ADDED').toUpperCase()}
                            onClick={() => onRemovePackage(aircraftItem)}
                        />
                    )}
                </AddButtonWrap>
            )}

            {/* modal */}
            {/* <ModalExtraFee
                show={adding?.show}
                onCancelExtraFee={onCancelExtraFee}
                onSaveExtraFee={onSaveExtraFee}
            /> */}
        </Container>
    );
};

export default InvoiceCard;

const ImageContainer = styled.div``;

const PriceLine = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

const Container = styled.div`
    width: 100%;
    margin-right: 20px;

    #invoices {
        width: 100%;
        margin-right: 20px;
    }

    #invoices td,
    #invoices th {
        padding: 8px;
    }

    #invoices tr:hover {
        background-color: #f7f8fb;
    }

    #invoices th {
        padding-top: 12px;
        font-family: AvenirNext-DemiBold;
        font-size: 10px;
        letter-spacing: 0.7px;
        line-height: 18px;
        background-color: #f7f8fb;
        color: #65686f;
    }

    .pricing-invoice-cell {
        text-align: right;
        margin-right: 1em;
    }

    .invoice-row-item {
        border-bottom: 1px solid rgba(14, 14, 18, 0.1);
    }
    .invoice-item {
        width: 40%;
    }
    .invoice-price {
        width: 15%;
    }
    .invoice-quantity {
        width: 15%;
    }
    .invoice-total-price {
        width: 15%;
    }
    .invoice-action {
        width: 15%;
    }

    .invoice-title {
        padding: 20px;
    }
`;

const EditButton = styled.button`
    background-color: transparent;
    border: none;
`;

const EditText = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.84px;
    line-height: 14px;
    width: 31px;
    text-align: left;
    margin-right: 8px;
`;

const AddButtonWrap = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: center;
`;

const Title = styled.div`
    color: #121216;
    font-family: AvenirNext-Bold;
    font-size: 10px;
    line-height: 24px;
    letter-spacing: 0.8px;
`;

const SubTitle = styled.div`
    color: #65686f;
    font-family: Lato;
    font-size: 14px;
    line-height: 24px;
    margin-left: 15px;
`;

const Price = styled.span`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 14px;
    line-height: 17px;
`;

const Quantity = styled.span`
    color: #65686f;
    font-family: Lato;
    font-size: 14px;
    line-height: 17px;
`;

const TotalPrice = styled.span`
    color: #121216;
    font-family: AvenirNext-Medium;
    font-size: 14px;
    line-height: 17px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const RowWrapper = styled.div`
    justify-content: space-between;
    width: 50%;
    display: flex;
    align-items: center;
    min-width: 240px;
    max-width: 550px;
    padding-right: 15%;
    margin-right: 8px;
`;

const LabelLight = styled.div`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: ${(props) => props.size || '11px'};
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
`;

const ValueItem = styled.div`
    color: #121216;
    font-family: AvenirNext-Bold;
    font-size: ${(props) => props.size || '14px'};
    letter-spacing: 0.4px;
    line-height: 24px;
    text-align: right;
    margin-left: 25px;
`;

const Divider = styled.div`
    background-color: #0e0e12;
    opacity: 0.1;
    margin-top: 10px;
    width: 100%;
    height: 2px;
`;

const AddMoreButton = styled.button`
    border: none;
    background-color: transparent;
    justify-content: center;
    align-items: center;
`;

const Icon = styled.img`
    width: 14px;
    height: 14px;
`;
