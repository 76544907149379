import React, { useState, useEffect } from 'react';
import { template as tmpl } from './CustomerContainer.tmpl';
import { getUsers } from '../../redux/actions/appActions';
import { connect } from 'react-redux';

const CustomerLogic = (props) => {
    const { companies = [] } = props;
    const { activeCompany, customers } = props;
    const [customerList, setCustomerList] = useState(customers);

    /**
     * Get Customers list from backend
     */
    useEffect(() => {
        props.getCustomers({
            type: 'CUSTOMER'
        });
    }, []);

    /**
     * Get new customers list after switching company
     */
    useEffect(() => {
        props.getCustomers({
            type: 'CUSTOMER'
        });
    }, [activeCompany]);

    /**
     * Update customerList state when company is changed
     */
    useEffect(() => {
        setCustomerList(customers);
    }, [customers]);

    /**
     * Function handles searching when user is typing
     * @param {*} e event when user is typing
     */
    const onSearch = (e) => {
        const input = e.target.value.toLowerCase();
        const result = customers.filter((item) => {
            if (
                item.name.toLowerCase().includes(input) ||
                item.email.toLowerCase().includes(input)
            ) {
                return item;
            }
        });
        setCustomerList(result);
    };

    const navigate = (route, id, state) => {
        props.history.push({
            pathname: route,
            search: id,
            state: state
        });
    };

    const initProps = {
        companies: companies,
        activeCompany: activeCompany,
        customerList: customerList,
        onSearch: onSearch,
        navigate: navigate
    };

    return tmpl && tmpl({ ...initProps });
};

class CustomerContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <CustomerLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { customers, activeCompany } = companyReducer;
    return {
        customers: customers,
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCustomers: (data) => dispatch(getUsers(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerContainer);
