import axios from 'axios';
import { appConfig } from '../config';
import { ACCESS_TOKEN } from '../redux/Constants';
import { getStorageData, isJSON } from '../utils';

let API_URL = appConfig.API_URL;

async function buildRequest(method, headerProps, anonymous = false) {
    let request = {
        headers: {
            Accept: 'application/json',
            // 'Access-Control-Allow-Origin': '*',
            'Content-Type':
                headerProps && headerProps['Content-Type']
                    ? headerProps['Content-Type']
                    : 'application/json',
            ...headerProps
        }
    };

    if (!anonymous) {
        const access_token = await getStorageData(ACCESS_TOKEN);
        if (access_token) {
            // const access_token = values && values.access_token;
            request.headers['authorization'] = 'Bearer ' + access_token;
        }
    }
    return request;
}

function parseErrors(error) {
    throw error.response.data;
}

const API = {
    get: async function (resource, query, headers) {
        try {
            let params = '';
            if (query) {
                params = Object.keys(query).reduce((q, key) => {
                    return q + `&${key}=${query[key]}`;
                }, '?');
            }

            let options = await buildRequest('GET', headers);
            return await axios.get(`${API_URL}/p${resource}${params}`, options);
        } catch (err) {
            if (err && err.response && err.response.status === 401) {
                throw {
                    error: 'SESSION_NOT_FOUND'
                };
            } else {
                throw parseErrors(err);
            }
        }
    },
    get_b: async function (resource, query, headers) {
        try {
            let params = '';
            if (query) {
                params = Object.keys(query).reduce((q, key) => {
                    return q + `&${key}=${query[key]}`;
                }, '?');
            }

            let options = await buildRequest('GET', headers);
            return await axios.get(`${API_URL}/b${resource}${params}`, options);
        } catch (err) {
            throw parseErrors(err);
        }
    },
    get_origin: async function (resource, query, headers) {
        try {
            let params = '';
            if (query) {
                params = Object.keys(query).reduce((q, key) => {
                    return q + `&${key}=${query[key]}`;
                }, '?');
            }

            let options = await buildRequest('GET', headers);
            return await axios.get(`${API_URL}${resource}${params}`, options);
        } catch (err) {
            throw parseErrors(err);
        }
    },
    get_next: async function (resource, query, headers) {
        try {
            let params = '';
            if (query) {
                params = Object.keys(query).reduce((q, key) => {
                    return q + `&${key}=${query[key]}`;
                }, '?');
            }

            let options = await buildRequest('GET', headers);
            return await axios.get(`${resource}${params}`, options);
        } catch (err) {
            throw parseErrors(err);
        }
    },
    get_blob: async function (resource, query, headers) {
        try {
            let params = '';
            if (query) {
                params = Object.keys(query).reduce((q, key) => {
                    return q + `&${key}=${query[key]}`;
                }, '?');
            }

            let options = await buildRequest('GET', headers);
            return await RNFetchBlob.config({
                // add this option that makes response data to be stored as a file,
                // this is much more performant.
                fileCache: true
            }).fetch(
                'GET',
                `${API_URL}/p${resource}${params}`,
                options.headers
            );
        } catch (err) {
            throw parseErrors(err);
        }
    },
    post: async function (resource, data, headers, anonymous) {
        try {
            let options = await buildRequest('POST', headers, anonymous);
            return await axios.post(`${API_URL}/p${resource}`, data, options);
        } catch (err) {
            throw parseErrors(err);
        }
    },
    post_origin: async function (resource, data, headers, anonymous) {
        try {
            let options = await buildRequest('POST', headers, anonymous);
            return await axios.post(`${API_URL}${resource}`, data, options);
        } catch (err) {
            throw parseErrors(err);
        }
    },
    put: async function (resource, data, headers) {
        try {
            return await axios.put(
                `${API_URL}/p${resource}`,
                data,
                await buildRequest('PUT', headers)
            );
        } catch (err) {
            throw parseErrors(err);
        }
    },
    put_origin: async function (resource, data, headers) {
        try {
            return await axios.put(
                `${API_URL}${resource}`,
                data,
                await buildRequest('PUT', headers)
            );
        } catch (err) {
            throw parseErrors(err);
        }
    },
    delete: async function (resource, data, headers) {
        try {
            let options = await buildRequest('DELETE', headers);
            if (data) {
                options.params = data;
                options.data = data;
            }

            return await axios.delete(API_URL + '/p' + resource, options);
        } catch (err) {
            throw parseErrors(err);
        }
    },
    identify: async function (resource, query, headers) {
        try {
            let params = '';
            if (query) {
                params = Object.keys(query).reduce((q, key) => {
                    return q + `&${key}=${query[key]}`;
                }, '?');
            }
            //set anonymous to avoid getting token
            let options = await buildRequest('GET', headers, true);
            return await axios.get(`${API_URL}${resource}${params}`, options);
        } catch (err) {
            throw parseErrors(err);
        }
    }
};

export default API;
