import React from 'react';
import 'antd/dist/antd.css';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Text, ButtonCustom } from 'components';
import { Col, Row } from 'antd';
import {
    airplaneSeat,
    bathroomSink,
    iconLavatory,
    sleep,
    iconWifi,
    imageIcon
} from 'assets/images/icons';
import { Carousel } from 'react-responsive-carousel';
import { AIRCRAFT_DETAIL } from 'routes/main';

function ImageDefault() {
    return (
        <BackgroundDefault>
            <img width={90} height={60} src={imageIcon}></img>
        </BackgroundDefault>
    );
}

const AircraftInfo = ({ aircraft = {}, navigate }) => {
    const { t, i18n } = useTranslation();
    const {
        amenities = [], // TODO :
        description = '',
        id = '',
        images = [],
        label = '',
        name = '',
        tail_number = '',
        year_of_manufacture = '',
        status = '',
        last_refurbished = '',
        properties = {},
        classtype = ''
    } = aircraft;
    const { max_passengers = 0, crew = 0 } = properties;

    return (
        <Container style={{ paddingLeft: 3, height: '95%' }}>
            <Label>
                <Text textStyle="heading5">
                    {t('AIRCRAFT_INFO').toUpperCase()}
                </Text>
            </Label>
            <Wrapper style={{ height: '100%' }}>
                <InfoWrap>
                    <PartWrap
                        style={{ flex: 0.5, minWidth: 290 }}
                        className="aircraft-info-properties-wrapper"
                    >
                        <div style={{ display: 'flex' }}>
                            <Text textStyle="heading6">
                                {name ? name : '_'}
                            </Text>
                            {tail_number && (
                                <TailorCode>{tail_number}</TailorCode>
                            )}
                        </div>
                        <div style={{ marginTop: 5 }}>
                            <Text textStyle="description">{description}</Text>
                        </div>
                        <AircraftItemWrap>
                            <AircrafItem>
                                <div>
                                    <Text textStyle="tableText1">
                                        {t('AIRCRAFT_TYPE')}
                                    </Text>
                                </div>
                                <div>
                                    <Text textStyle="tableText2">
                                        {t(classtype).toUpperCase()}
                                    </Text>
                                </div>
                            </AircrafItem>
                            <AircrafItem>
                                <div>
                                    <Text textStyle="tableText1">
                                        {t('YEAR')}
                                    </Text>
                                </div>
                                <div>
                                    <Text textStyle="tableText2">
                                        {year_of_manufacture || '_'}
                                    </Text>
                                </div>
                            </AircrafItem>
                            <AircrafItem>
                                <div>
                                    <Text textStyle="tableText1">
                                        {t('LAST_REFURBISHED')}
                                    </Text>
                                </div>
                                <div>
                                    <Text textStyle="tableText2">
                                        {last_refurbished || '_'}
                                    </Text>
                                </div>
                            </AircrafItem>
                            <AircrafItem>
                                <div>
                                    <Text textStyle="tableText1">
                                        {t('NUMBER_OF_SEATS')}
                                    </Text>
                                </div>
                                <div>
                                    <Text textStyle="tableText2">
                                        {max_passengers}
                                    </Text>
                                </div>
                            </AircrafItem>
                        </AircraftItemWrap>

                        <div style={{ marginTop: 15 }}>
                            <Text textStyle="heading6">
                                {t('FEATURED_AMENITIES')}
                            </Text>
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <Row style={{ justifyContent: 'flex-start' }}>
                                <Col>
                                    <AmenitiesItem>
                                        <Icon src={airplaneSeat} />
                                        <Text textStyle="tableText1">
                                            {t('LEGROOM')}
                                        </Text>
                                    </AmenitiesItem>
                                </Col>
                                <Col>
                                    <AmenitiesItem>
                                        <Icon src={sleep} />
                                        <Text textStyle="tableText1">
                                            {t('SLEEPS')}
                                        </Text>
                                    </AmenitiesItem>
                                </Col>
                                <Col>
                                    <AmenitiesItem>
                                        <Icon src={iconWifi} />
                                        <Text textStyle="tableText1">
                                            {t('WIFI')}
                                        </Text>
                                    </AmenitiesItem>
                                </Col>
                                <Col>
                                    <AmenitiesItem>
                                        <Icon src={airplaneSeat} />
                                        <Text textStyle="tableText1">
                                            {t('VIDEO_MONITOR')}
                                        </Text>
                                    </AmenitiesItem>
                                </Col>
                                <Col>
                                    <AmenitiesItem>
                                        <Icon src={bathroomSink} />
                                        <Text textStyle="tableText1">
                                            {t('GRANITE_COUNTERS')}
                                        </Text>
                                    </AmenitiesItem>
                                </Col>
                                <Col>
                                    <AmenitiesItem>
                                        <Icon src={iconLavatory} />
                                        <Text textStyle="tableText1">
                                            {t('LAVATORY')}
                                        </Text>
                                    </AmenitiesItem>
                                </Col>
                            </Row>
                        </div>
                    </PartWrap>
                    <PartWrap
                        className="aircraft-info-photos-wrapper"
                        style={{
                            flex: 0.5,
                            marginLeft: 15
                        }}
                    >
                        <Row
                            style={{
                                justifyContent: 'flex-end',
                                marginRight: 20
                            }}
                        >
                            <Col>
                                <div>
                                    <Text textStyle="heading6">
                                        {t('PHOTOS')}
                                    </Text>
                                </div>
                                <ImageSection>
                                    <Carousel
                                        className="carousel-custom"
                                        thumbWidth={84}
                                        showStatus={false}
                                    >
                                        {images && images.length > 0 ? (
                                            images.map((eachImage, index) => {
                                                const { lowres = {} } =
                                                    eachImage;
                                                return (
                                                    <div key={index}>
                                                        <img src={lowres.url} />
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <ImageDefault />
                                        )}
                                    </Carousel>
                                </ImageSection>
                            </Col>
                        </Row>
                    </PartWrap>
                </InfoWrap>
                <ButtonWrap>
                    <ButtonCustom
                        onClick={() => navigate(AIRCRAFT_DETAIL, id)}
                        size="small"
                        btnType={'secondary'}
                        width="160px"
                        title={t('VIEW_JET_PROFILE').toUpperCase()}
                    />
                </ButtonWrap>
            </Wrapper>
        </Container>
    );
};

const InfoWrap = styled.div`
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
`;

const PartWrap = styled.div`
    flex-wrap: wrap;
`;

const ButtonWrap = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: center;
`;

const Wrapper = styled.div`
    position: relative;
    background-color: #ffffff;
    min-height: 300px;
    justify-content: center;
    align-self: center;
    border-radius: 4px;
    padding: 10px 10px 0 10px;
    padding-left: 25px;
    margin-top: 20px;
`;

const Container = styled.div`
    width: 100%;
`;

const AircraftItemWrap = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
`;

const AircrafItem = styled.div`
    align-items: center;
`;

const AmenitiesItem = styled.div`
    padding-right: 15px;
`;

const Label = styled.div``;

const Icon = styled.img`
    width: 32px;
    height: 32px;
    padding: 5px;
`;

const BackgroundDefault = styled.div`
    display: flex;
    width: 270px;
    height: 170px;
    background-color: #f0f3f9;
    justify-content: center;
    align-items: center;
`;

const ImageSection = styled.div`
    max-width: 350px;
    margin-top: 10px;
    .carousel-custom .carousel .thumbs-wrapper {
        margin-top: 20px;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        overflow: hidden;
        ul {
            padding-inline-start: 0;
        }
    }
    .thumb {
        transition: border 0.15s ease-in;
        display: inline-block;
        margin-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        border: 3px solid #fff;
        padding: 0;
        width: 84px;
        height: 53px;
    }
    .carousel .thumb.selected,
    .carousel .thumb:hover {
        border: 1px solid #19c0ff;
    }
    .carousel .slide {
        width: 270px;
        height: 170px;
    }
`;

const TailorCode = styled.div`
    border: 1px solid #dadadb;
    border-radius: 2px;
    height: 20px;
    color: #19c0ff;
    font-family: AvenirNext-Bold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: center;
    padding: 3px;
    margin-left: 10px;
`;

export default AircraftInfo;
