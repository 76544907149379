import {
    SETTING_SHOW_MODAL,
    SETTING_HIDE_MODAL,
    UPDATE_PROMOTIONS_CURRENT_TAB,
    TOGGLE_SIDEBAR
} from '../Constants';

const INITIAL_STATE = {
    modal: {
        show: false,
        type: '', // success <> error
        errors: null, // default null
        title: '',
        description: '',
        buttonTitle: '',
        onOk: '', // route navigator
        onCancel: '', // route navigator
        style: {}
    },
    promotionsCurrentTab: 'TOP_RATED_AIRPORTS', // TOP_RATED_AIRPORTS | TOP_DESTINATIONS | SPECAIL_ROUTES
    expandedSidebar: false
};

export default function settingReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SETTING_SHOW_MODAL:
            return {
                ...state,
                modal: action.data
            };
        case SETTING_HIDE_MODAL:
            return {
                ...state,
                modal: INITIAL_STATE.modal
            };
        case UPDATE_PROMOTIONS_CURRENT_TAB:
            return {
                ...state,
                promotionsCurrentTab: action.data
            };
        case TOGGLE_SIDEBAR:
            return {
                ...state,
                expandedSidebar: action.data
            };
        default:
            return { ...state };
    }
}
