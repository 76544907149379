import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import {
    IconLand,
    IconClose,
    BluePlaneIcon,
    CircleBlue,
    CircleGreen,
    IconDepartureDashboard,
    IconLandDashboard
} from 'assets/images/icons';
import { Text, DashboardMap } from 'components';
import { useTranslation } from 'react-i18next';
import { DashboardTabContainer } from '../../style';
import UpcomingFlightTable from '../../Tables/UpComingFlightTable';
import NewQuoteRequestTable from '../../Tables/NewQuoteRequestTable';
import NewBookingTable from '../../Tables/NewBookingTable';
import { useSelector } from 'react-redux';
import { getWindowDimensions } from 'utils';

const template = (props) => {
    const { t, i18n } = useTranslation();
    const {
        navigate,
        upcomingFlightsList,
        newQuotes,
        newBookings,
        reports,
        listAircraftsPosition = []
    } = props;

    const {
        cancelled_flights = 0,
        completed_flights = 0,
        new_bookings = 0,
        upcoming_flights = 0,
        cancelled_bookings = 0
    } = reports;

    const { expandedSidebar } = useSelector(
        (state) => state.settingReducer || false
    );
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <DashboardTabContainer>
            <StatusCardsection expandedSidebar={expandedSidebar}>
                <div className="dashboard-two-cards dashboard-two-cards-first">
                    <EachCard className="dashboard-status-card booking-card">
                        <DashboardStatusCard
                            icon={<BluePlaneIcon />}
                            title={'New Bookings'}
                            number={new_bookings}
                            style={{
                                backgroundImage:
                                    'linear-gradient(216.33deg, #5EC3FF 0%, #299AF9 100%)'
                            }}
                        />
                    </EachCard>
                    <EachCard className="dashboard-status-card upcoming-flight-card">
                        <DashboardStatusCard
                            title={'Upcoming Flights'}
                            icon={
                                <IconDepartureDashboard
                                // fill="#FFFFFF"
                                // style={{ width: '46px', height: '32px' }}
                                />
                            }
                            number={upcoming_flights}
                            style={{
                                backgroundImage:
                                    'linear-gradient(216.33deg, #26D9B0 0%, #0CD38A 100%)'
                            }}
                        />
                    </EachCard>
                </div>
                <div className="dashboard-two-cards dashboard-two-cards-second">
                    <EachCard className="dashboard-status-card flight-completed-card">
                        <DashboardStatusCard
                            icon={<IconLandDashboard />}
                            title={'Flight Completed'}
                            number={completed_flights}
                            style={{
                                backgroundImage:
                                    'linear-gradient(216.33deg, #19E3FF 0%, #0AB5E9 100%)'
                            }}
                        />
                    </EachCard>
                    <EachCard className="dashboard-status-card cancelled-card">
                        <DashboardStatusCard
                            icon={<IconClose />}
                            title={'Cancelled'}
                            number={cancelled_flights}
                            style={{
                                backgroundImage:
                                    'linear-gradient(216.33deg, #FF7E70 0%, #EF607A 100%)'
                            }}
                        />
                    </EachCard>
                </div>
            </StatusCardsection>
            {windowDimensions.width > 1024 ? (
                <>
                    <FlightRow>
                        <UpcomingFlightTableSection>
                            <UpcomingFlightTable
                                navigate={navigate}
                                // onChangePageUpcomingFlights={
                                //     onChangePageUpcomingFlights
                                // }
                                list={upcomingFlightsList}
                                // loading={loading}
                            />
                        </UpcomingFlightTableSection>
                        <FleetMap>
                            <TableHeader>
                                <Text textStyle="heading5">
                                    {t('FLEET').toUpperCase()}
                                </Text>
                                <ViewAllButton>
                                    <OurFleetTitle>
                                        <CircleBlue />
                                        <Text
                                            textStyle="heading5"
                                            style={{
                                                letterSpacing: 0,
                                                marginLeft: 5
                                            }}
                                        >
                                            {t('OUR_FLEET')}
                                        </Text>
                                    </OurFleetTitle>
                                    <PartnerFleetTitle>
                                        <CircleGreen />
                                        <Text
                                            textStyle="heading5"
                                            style={{
                                                letterSpacing: 0,
                                                marginLeft: 5
                                            }}
                                        >
                                            {t('PARTNER_FLEET')}
                                        </Text>
                                    </PartnerFleetTitle>
                                </ViewAllButton>
                            </TableHeader>
                            <DashboardMap
                                listAircraftsPosition={listAircraftsPosition}
                            />
                        </FleetMap>
                    </FlightRow>
                    <BookingRow>
                        <NewQuoteRequestTableSection>
                            <NewQuoteRequestTable
                                navigate={navigate}
                                // onChangePageUpcomingFlights={
                                //     onChangePageUpcomingFlights
                                // }
                                list={newQuotes}
                                // loading={loading}
                            />
                        </NewQuoteRequestTableSection>
                        <NewBookingSection>
                            <NewBookingTable
                                navigate={navigate}
                                // onChangePageUpcomingFlights={
                                //     onChangePageUpcomingFlights
                                // }
                                list={newBookings}
                                // loading={loading}
                            />
                        </NewBookingSection>
                    </BookingRow>
                </>
            ) : (
                <>
                    <EachTableSection>
                        <UpcomingFlightTable
                            navigate={navigate}
                            list={upcomingFlightsList}
                        />
                    </EachTableSection>
                    <EachTableSection>
                        <NewQuoteRequestTable
                            navigate={navigate}
                            list={newQuotes}
                        />
                    </EachTableSection>
                    <EachTableSection>
                        <NewBookingTable
                            navigate={navigate}
                            list={newBookings}
                        />
                    </EachTableSection>
                    <EachTableSection>
                        <TableHeader>
                            <Text textStyle="heading5">
                                {t('FLEET').toUpperCase()}
                            </Text>
                            <ViewAllButton>
                                <OurFleetTitle>
                                    <CircleBlue />
                                    <Text
                                        textStyle="heading5"
                                        style={{
                                            letterSpacing: 0,
                                            marginLeft: 5
                                        }}
                                    >
                                        {t('OUR_FLEET')}
                                    </Text>
                                </OurFleetTitle>
                                <PartnerFleetTitle>
                                    <CircleGreen />
                                    <Text
                                        textStyle="heading5"
                                        style={{
                                            letterSpacing: 0,
                                            marginLeft: 5
                                        }}
                                    >
                                        {t('PARTNER_FLEET')}
                                    </Text>
                                </PartnerFleetTitle>
                            </ViewAllButton>
                        </TableHeader>
                        <DashboardMap
                            listAircraftsPosition={listAircraftsPosition}
                        />
                    </EachTableSection>
                </>
            )}
        </DashboardTabContainer>
    );
};

export { template };

const DashboardStatusCard = (props) => {
    const { icon, title = '', number = 0, style = {} } = props;
    return (
        <CardContainer style={style}>
            {icon ? (
                icon
            ) : (
                <IconLand
                    fill="#FFFFFF"
                    style={{ width: '46px', height: '32px' }}
                />
            )}
            <Text
                style={{
                    color: '#FFFFFF',
                    fontFamily: 'AvenirNext-DemiBold',
                    fontSize: '44px'
                }}
            >
                {number}
            </Text>
            <Text
                style={{
                    color: '#FFFFFF',
                    fontFamily: 'AvenirNext-DemiBold',
                    fontSize: '14px',
                    marginLeft: 10
                }}
            >
                {title}
            </Text>
        </CardContainer>
    );
};

const EachTableSection = styled.div`
    margin-top: 40px;
`;

const PartnerFleetTitle = styled.div`
    display: flex;
    align-items: center;
`;

const OurFleetTitle = styled.div`
    display: flex;
    padding-right: 40px;
    align-items: center;
`;

const ViewAllButton = styled.div`
    /* border: none;
    background-color: transparent;
    cursor: pointer; */
    display: flex;
    flex-direction: row;
`;

const TableHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 25px;
    align-items: center;
`;

const NewBookingSection = styled.div`
    width: 50%;
    padding-left: 20px;
    /* flex: 1; */
`;

const NewQuoteRequestTableSection = styled.div`
    width: 50%;
    /* border: 1px solid #000; */
    padding-right: 20px;
    /* flex: 1; */
`;

const UpcomingFlightTableSection = styled.div`
    width: 50%;
    padding-right: 20px;
    /* border: 1px solid #000; */
`;

const FleetMap = styled.div`
    /* border: 1px solid #000; */
    width: 50%;
    padding-left: 20px;
`;

const CardIcon = styled.img``;

const CardContainer = styled.div`
    border-radius: 8px;
    min-width: 250px;
    /* width: 18.75vw; */
    width: 100%;
    height: 97px;
    /* border: 1px solid #000; */
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    /* padding-left: 15px; */
`;

const EachCard = styled.div`
    /* width: 25%; */
    min-width: 300px;
`;

const FlightRow = styled.div`
    padding-top: 40px;
    display: flex;
    flex-direction: row;
`;

const BookingRow = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 40px;
`;

const StatusCardsection = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    .dashboard-status-card {
        min-width: 250px;
        padding-top: 10px;
        width: 50%;
    }
    .dashboard-two-cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 50%;
        min-width: 200px;
    }
    .dashboard-two-cards-first {
        padding-right: 20px;
    }
    .dashboard-two-cards-second {
        padding-left: 20px;
    }
    .booking-card {
        padding-right: 20px;
    }
    .upcoming-flight-card {
        padding-left: 20px;
    }
    .flight-completed-card {
        padding-right: 20px;
    }
    .cancelled-card {
        padding-left: 20px;
    }

    @media (max-width: 1440px) {
        .dashboard-status-card {
            width: ${(props) => (props.expandedSidebar ? '100%' : '50%')};
            /* width: 100%; */
            padding-right: 0;
            padding-left: 0;
        }
        .booking-card {
            padding-right: ${(props) =>
                props.expandedSidebar ? '0px' : '20px'};
        }
        .upcoming-flight-card {
            padding-left: ${(props) =>
                props.expandedSidebar ? '0px' : '20px'};
        }
        .flight-completed-card {
            padding-right: ${(props) =>
                props.expandedSidebar ? '0px' : '20px'};
        }
        .cancelled-card {
            padding-left: ${(props) =>
                props.expandedSidebar ? '0px' : '20px'};
        }
    }

    @media (max-width: 1024px) {
        .dashboard-status-card {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
    }

    @media (max-width: 768px) {
        /* .dashboard-two-cards-first {
            padding-right: ${(props) =>
            props.expandedSidebar ? '0px' : '10px'};
        }
        .dashboard-two-cards-second {
            padding-left: ${(props) =>
            props.expandedSidebar ? '0px' : '10px'};
        } */
    }
`;
