// @flow

import decode from 'jwt-decode';
import moment from 'moment';

const TOKEN_KEY = 'ACCESS_TOKEN';
const USER_INFO = 'USER_INFO';
const FCM_TOKEN = 'FCM_TOKEN';
const COUNTRIES_LIST = 'COUNTRIES_LIST';

const APP_PERSIST_STORES_TYPES = ['localStorage', 'sessionStorage'];

const parse = JSON.parse;
const stringify = JSON.stringify;

/*
  auth object
  -> store "TOKEN_KEY"
  - default storage is "localStorage"
  - default token key is 'token'
 */
export const auth = {
    /**
     * get token from localstorage
     *
     * @param {'localStorage' | 'sessionStorage'} [fromStorage='localStorage'] specify storage
     * @param {any} [tokenKey=TOKEN_KEY]  optionnal parameter to specify a token key
     * @returns {string} token value
     */
    getToken(fromStorage = APP_PERSIST_STORES_TYPES[0], tokenKey = TOKEN_KEY) {
        // localStorage:
        if (fromStorage === APP_PERSIST_STORES_TYPES[0]) {
            return (localStorage && localStorage.getItem(tokenKey)) || null;
        }
        // sessionStorage:
        if (fromStorage === APP_PERSIST_STORES_TYPES[1]) {
            return (sessionStorage && sessionStorage.getItem(tokenKey)) || null;
        }
        // default:
        return null;
    },

    /**
     * get fcm token from localstorage
     *
     * @param {'localStorage'} [fromStorage='localStorage'] specify storage
     * @param {any} [tokenKey=TOKEN_KEY]  optionnal parameter to specify a token key
     * @returns {string} token value
     */
    getFCMToken(
        fromStorage = APP_PERSIST_STORES_TYPES[0],
        tokenKey = FCM_TOKEN
    ) {
        try {
            if (fromStorage === APP_PERSIST_STORES_TYPES[0]) {
                return (localStorage && parse(localStorage.getItem(tokenKey))) || null;
            }
            return null;
        } catch (e) {
            return null;
        }
    },

    /**
     * set the fcm token value into localstorage
     *
     * @param {string} [value=''] token value
     * @param {'localStorage'} [toStorage='localStorage'] specify storage
     * @param {any} [tokenKey='token'] token key
     * @returns {boolean} success/failure flag
     */
    setFCMToken(
        value = {},
        toStorage = APP_PERSIST_STORES_TYPES[0],
        tokenKey = FCM_TOKEN
    ) {
        if (!value || value.length <= 0) {
            return;
        }

        // localStorage:
        if (toStorage === APP_PERSIST_STORES_TYPES[0]) {
            if (localStorage) {
                localStorage.setItem(tokenKey, stringify(value));
            }
        }
    },

    /**
     * set the token value into localstorage (managed by localforage)
     *
     * @param {string} [value=''] token value
     * @param {'localStorage' | 'sessionStorage'} [toStorage='localStorage'] specify storage
     * @param {any} [tokenKey='token'] token key
     * @returns {boolean} success/failure flag
     */
    setToken(
        value = '',
        toStorage = APP_PERSIST_STORES_TYPES[0],
        tokenKey = TOKEN_KEY
    ) {
        if (!value || value.length <= 0) {
            return;
        }
        // localStorage:
        if (toStorage === APP_PERSIST_STORES_TYPES[0]) {
            if (localStorage) {
                localStorage.setItem(tokenKey, value);
            }
        }
        // sessionStorage:
        if (toStorage === APP_PERSIST_STORES_TYPES[1]) {
            if (sessionStorage) {
                sessionStorage.setItem(tokenKey, value);
            }
        }
    },

    /**
     * - if token key contains a valid token value (defined and not an empty value)
     * - if the token expiration date is passed
     *
     * Note: 'isAuthenticated' just checks 'tokenKey' on store (localStorage by default or sessionStorage)
     * @param {'localStorage' | 'sessionStorage'} [fromStorage='localStorage'] specify storage
     * @param {any} [tokenKey=TOKEN_KEY] token key
     * @returns {bool} is authenticed response
     */
    isAuthenticated(
        fromStorage = APP_PERSIST_STORES_TYPES[0],
        tokenKey = TOKEN_KEY
    ) {
        // localStorage:
        if (fromStorage === APP_PERSIST_STORES_TYPES[0]) {
            if (localStorage && localStorage.getItem(tokenKey)) {
                return true;
            } else {
                return false;
            }
        }
        // sessionStorage:
        if (fromStorage === APP_PERSIST_STORES_TYPES[1]) {
            if (sessionStorage && sessionStorage.getItem(tokenKey)) {
                return true;
            } else {
                return false;
            }
        }
        // default:
        return false;
    },

    /**
     * delete token
     *
     * @param {any} [tokenKey='token'] token key
     * @returns {bool} success/failure flag
     */
    clearToken(storage = APP_PERSIST_STORES_TYPES[0], tokenKey = TOKEN_KEY) {
        // localStorage:
        if (localStorage && localStorage[tokenKey]) {
            localStorage.removeItem(tokenKey);
            return true;
        }
        // sessionStorage:
        if (sessionStorage && sessionStorage[tokenKey]) {
            sessionStorage.removeItem(tokenKey);
            return true;
        }

        return false;
    },

    /**
     * return expiration date from token
     *
     * @param {string} encodedToken - base 64 token received from server and stored in local storage
     * @returns {date | null} returns expiration date or null id expired props not found in decoded token
     */
    getTokenExpirationDate(encodedToken) {
        if (!encodedToken) {
            return new Date(0); // is expired
        }

        const token = decode(encodedToken);
        if (!token.exp) {
            return new Date(0); // is expired
        }

        const expirationDate = new Date(token.exp * 1000);
        return expirationDate;
    },

    /**
     * tell is token is expired (compared to now)
     *
     * @param {string} encodedToken - base 64 token received from server and stored in local storage
     * @returns {bool} returns true if expired else false
     */
    isExpiredToken(encodedToken) {
        const expirationDate = this.getTokenExpirationDate(encodedToken);
        const rightNow = moment();
        const isExpiredToken = moment(rightNow).isAfter(moment(expirationDate));

        return isExpiredToken;
    },

    /**
     * get user info from localstorage
     *
     * @param {'localStorage' | 'sessionStorage'} [fromStorage='localStorage'] specify storage
     * @param {any} [userInfoKey='userInfo']  optionnal parameter to specify a token key
     * @returns {string} token value
     */
    getUserInfo(
        fromStorage = APP_PERSIST_STORES_TYPES[0],
        userInfoKey = USER_INFO
    ) {
        // localStorage:
        if (fromStorage === APP_PERSIST_STORES_TYPES[0]) {
            return (
                (localStorage && parse(localStorage.getItem(userInfoKey))) ||
                null
            );
        }
        // sessionStorage:
        if (fromStorage === APP_PERSIST_STORES_TYPES[1]) {
            return (
                (sessionStorage &&
                    parse(sessionStorage.getItem(userInfoKey))) ||
                null
            );
        }

        // default:
        return null;
    },

    /**
     * set the userInfo value into localstorage
     *
     * @param {object} [value=''] token value
     * @param {'localStorage' | 'sessionStorage'} [toStorage='localStorage'] specify storage
     * @param {any} [userInfoKey='userInfo'] token key
     * @returns {boolean} success/failure flag
     */
    setUserInfo(
        value = '',
        toStorage = APP_PERSIST_STORES_TYPES[0],
        userInfoKey = USER_INFO
    ) {
        if (!value || value.length <= 0) {
            return;
        }
        // localStorage:
        if (toStorage === APP_PERSIST_STORES_TYPES[0]) {
            if (localStorage) {
                localStorage.setItem(userInfoKey, stringify(value));
            }
        }
        // sessionStorage:
        if (toStorage === APP_PERSIST_STORES_TYPES[1]) {
            if (sessionStorage) {
                sessionStorage.setItem(userInfoKey, stringify(value));
            }
        }
    },

    /**
     * get countries list from localstorage
     *
     * @param {'localStorage' | 'sessionStorage'} [fromStorage='localStorage'] specify storage
     * @param {any} [countriesKey='COUNTRIES_LIST']  optionnal parameter to specify a token key
     * @returns {string} token value
     */
    getCountries(
        fromStorage = APP_PERSIST_STORES_TYPES[0],
        countriesKey = COUNTRIES_LIST
    ) {
        // localStorage:
        if (fromStorage === APP_PERSIST_STORES_TYPES[0]) {
            return (
                (localStorage && parse(localStorage.getItem(countriesKey))) ||
                null
            );
        }
        // sessionStorage:
        if (fromStorage === APP_PERSIST_STORES_TYPES[1]) {
            return (
                (sessionStorage &&
                    parse(sessionStorage.getItem(countriesKey))) ||
                null
            );
        }

        // default:
        return null;
    },

    /**
     * set the countries list into localstorage
     *
     * @param {object} [value=''] token value
     * @param {'localStorage' | 'sessionStorage'} [toStorage='localStorage'] specify storage
     * @param {any} [countriesKey='COUNTRIES_LIST'] token key
     * @returns {boolean} success/failure flag
     */
    setCountries(
        value = '',
        toStorage = APP_PERSIST_STORES_TYPES[0],
        countriesKey = COUNTRIES_LIST
    ) {
        if (!value || value.length <= 0) {
            return;
        }
        // localStorage:
        if (toStorage === APP_PERSIST_STORES_TYPES[0]) {
            if (localStorage) {
                localStorage.setItem(countriesKey, stringify(value));
            }
        }
        // sessionStorage:
        if (toStorage === APP_PERSIST_STORES_TYPES[1]) {
            if (sessionStorage) {
                sessionStorage.setItem(countriesKey, stringify(value));
            }
        }
    },

    /**
     * delete userInfo
     *
     * @param {string} [userInfoKey='userInfo'] token key
     * @returns {bool} success/failure flag
     */
    clearUserInfo(userInfoKey = USER_INFO) {
        // localStorage:
        if (localStorage && localStorage[userInfoKey]) {
            localStorage.removeItem(userInfoKey);
        }
        // sessionStorage:
        if (sessionStorage && sessionStorage[userInfoKey]) {
            sessionStorage.removeItem(userInfoKey);
        }
    },

    /**
     * forget me method: clear all
     * @returns {bool} success/failure flag
     */
    clearAllAppStorage() {
        if (localStorage) {
            localStorage.clear();
        }
        if (sessionStorage) {
            sessionStorage.clear();
        }
    },

    checkRole(role) {
        let rs = false;
        const userInfo = decode(this.getToken());

        if (userInfo) {
            const roles = userInfo.roles.findIndex((v) => v.roleId === role);
            if (roles > -1) {
                rs = true;
            }
        }
        return rs;
    },

    checkIsRole(role) {
        let rs = false;
        const userInfo = decode(this.getToken());

        if (userInfo) {
            rs = userInfo.role === role;
        }
        return rs;
    }
};

export default auth;
