import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { jetIcon } from 'assets/images/icons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'antd/dist/antd.css';
import { Row, Col, Steps } from 'antd';
import { Text } from 'components';

const QuoteGeneralInfo = (props) => {
    const { leg = {}, created_at } = props;
    const {
        from_airport = {},
        to_airport = {},
        passengers = 0,
        departure = {},
        arrival = {}
    } = leg || {};
    const { expandedSidebar } = useSelector(
        (state) => state.settingReducer || false
    );
    const { t, i18n } = useTranslation();

    return (
        <FlightInformationContainer>
            <Col xl={6} lg={24}>
                <Row
                    className="request-info-left"
                    style={{
                        padding: 10,
                        // borderRight: '1px solid rgba(14, 14, 18, 0.1)',
                        height: '100%',
                        alignItems: 'center'
                    }}
                >
                    <Col style={{ marginLeft: 10, marginRight: 30 }}>
                        <Row>
                            <Text textStyle="tableText1">
                                {t('REQUEST_SENT_AT')}
                            </Text>
                        </Row>
                        <Row>
                            <Text
                                textStyle="tableText2"
                                style={{ letterSpacing: 0, marginTop: 5 }}
                            >
                                {moment(created_at)
                                    .format('MMM Do YYYY, hh:mm A')
                                    .toUpperCase()}
                            </Text>
                        </Row>
                    </Col>
                    <Col
                        style={{
                            marginLeft: 10
                            // paddingRight: 40
                        }}
                    >
                        <Row>
                            <Text textStyle="tableText1">
                                {t('NUMBER_OF_PASSENGER')}
                            </Text>
                        </Row>
                        <Row>
                            <Text
                                textStyle="tableText2"
                                style={{ letterSpacing: 0, marginTop: 5 }}
                            >
                                {passengers + ' ' + t('PAX').toUpperCase()}
                            </Text>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col xl={18} lg={24}>
                <Row style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <Col style={{ paddingLeft: 20, flex: 1 }}>
                        <AirportName expandedSidebar={expandedSidebar}>
                            <Text
                                textStyle="heading3"
                                style={{
                                    letterSpacing: 1.5,
                                    marginRight: 10,
                                    minWidth: 60
                                }}
                            >
                                {from_airport.icao_code?.toUpperCase() || '-'}
                            </Text>

                            <Text
                                textStyle="tableText1"
                                style={{ minWidth: '160px' }}
                            >
                                {from_airport.name || '-'}
                            </Text>
                        </AirportName>
                        <Row>
                            <Text
                                textStyle="tableText2"
                                style={{ letterSpacing: 0 }}
                            >
                                {departure.date
                                    ? moment(
                                          departure?.date +
                                              ' ' +
                                              departure?.time
                                      )
                                          .format('dddd, MMM DD YYYY / hh:mm A')
                                          .toUpperCase()
                                    : ' '}
                            </Text>
                        </Row>
                    </Col>
                    <Col
                        style={{
                            // paddingLeft: 30,
                            // paddingRight: 30,
                            display: 'flex',
                            alignItems: 'center',
                            minWidth: 200,
                            flex: 1
                        }}
                    >
                        <Row
                            justify="center"
                            align="middle"
                            style={{ width: '100%' }}
                        >
                            <Col>
                                <Dashed style={{ marginRight: 5 }} />
                            </Col>
                            <Col>
                                <img
                                    src={jetIcon}
                                    style={{ height: 25, width: 26 }}
                                />
                            </Col>
                            <Col>
                                <Dashed style={{ marginLeft: 5 }} />
                            </Col>
                        </Row>
                    </Col>
                    <Col style={{ paddingLeft: 20, flex: 1 }}>
                        <AirportName expandedSidebar={expandedSidebar}>
                            <Text
                                textStyle="heading3"
                                style={{
                                    letterSpacing: 1.5,
                                    marginRight: 10,
                                    minWidth: 60
                                }}
                            >
                                {to_airport.icao_code?.toUpperCase() || '-'}
                            </Text>
                            <Text
                                textStyle="tableText1"
                                style={{ minWidth: '160px' }}
                            >
                                {to_airport.name || '-'}
                            </Text>
                        </AirportName>
                        <Row>
                            <Text
                                textStyle="tableText2"
                                style={{ letterSpacing: 0 }}
                            >
                                {arrival.date
                                    ? moment(
                                          arrival?.date + ' ' + arrival?.time
                                      )
                                          .format('dddd, MMM DD YYYY / hh:mm A')
                                          .toUpperCase()
                                    : ' '}
                            </Text>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </FlightInformationContainer>
    );
};

export default QuoteGeneralInfo;

const FlightInformationContainer = styled(Row)`
    background-color: #ffffff;
    border-radius: 4px;
    .request-info-left {
        border-right: 1px solid rgba(14, 14, 18, 0.1);
    }
    @media (max-width: 1200px) {
        .request-info-left {
            border-right: none;
        }
    }
`;

const AirportName = styled(Row)`
    align-items: center;
    flex-wrap: wrap;
    min-width: 150px;
    @media (max-width: 1366px) {
        ${(props) => props.expandedSidebar && 'max-width: 240px'};
    }
`;

const Dashed = styled.div`
    border: 1px dashed rgba(14, 14, 18, 0.2);
    width: 6vw;
    max-width: 120px;
    min-width: 40px;
`;
