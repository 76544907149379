import React, { useState, useEffect } from 'react';
import { template as tmpl } from './BookingInvoice.tmpl';
import { getBookingById } from 'redux/actions/bookingAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bookingCategory } from 'config/Constants';

const BookingLInvoiceLogic = (props) => {
    const { location = {} } = props;

    const { search } = location;

    const [bookingDetail, setBookingDetail] = useState({});
    /**
     * Get booking detail from backend
     */
    useEffect(() => {
        async function fetchBookingDetail() {
            let id = search;
            if (id && id[0] === '?') {
                id = id.substring(1);
            }

            const result = await props.getBookingById(id);
            if (result.success) {
                setBookingDetail(result.data || {});
            }
        }
        fetchBookingDetail();
    }, []);

    const navigate = (route, id, state) => {
        props.history.push({
            pathname: route,
            search: id,
            state: state
        });
    };

    const initProps = {
        bookingDetail: bookingDetail,
        navigate: navigate
    };

    return tmpl && tmpl({ ...initProps });
};

class BookingInvoiceContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <BookingLInvoiceLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { customers, activeCompany } = companyReducer;
    return {
        customers: customers,
        activeCompany: activeCompany // TODO : fetch booking list on change company
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getBookingById: (id) => dispatch(getBookingById(id))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(BookingInvoiceContainer)
);
