import React from 'react';
import styled from 'styled-components';
import {
    EditOutlined,
    CloseOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../styles/shareStyles';
import { infoIcon } from '../../assets/images/icons';

/**
 *
 * @param {string} title - the title of the page
 * @param {string} actionText - the title action button (default = EDIT)
 * @param {JSX} icon - the left icon
 * @param {func} onClick - handle click action button
 * @returns {JSX}
 */
const HeaderInfo = (props) => {
    const { t, i18n } = useTranslation();
    const { title = '', actionText, onClick, isUpdate, icon } = props;

    return (
        <Header>
            <HeaderLeft>
                <TitleSection>
                    <InfoCircleBackground>
                        {icon ? (
                            icon
                        ) : (
                            <Icon
                                style={{ width: 20, height: 20 }}
                                src={infoIcon}
                            />
                        )}
                    </InfoCircleBackground>
                    <Title>{title}</Title>
                </TitleSection>
            </HeaderLeft>
            <HeaderRight>
                {actionText &&
                    (!isUpdate ? (
                        <EditButton onClick={() => onClick()}>
                            <EditText>{actionText}</EditText>
                            <EditOutlined />
                        </EditButton>
                    ) : (
                        <EditButton onClick={() => onClick()}>
                            <EditText>
                                {i18n.t('CANCEL_EDIT').toUpperCase()}
                            </EditText>
                            <CloseOutlined />
                        </EditButton>
                    ))}
            </HeaderRight>
        </Header>
    );
};

const TitleSection = styled.div`
    display: flex;
    flex-direction: row;
`;

const InfoCircleBackground = styled.div`
    background-color: #f0f3f9;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 20px;
`;

const HeaderLeft = styled.div`
    margin-top: auto;
    margin-bottom: auto;
`;
const HeaderRight = styled.div`
    margin-top: auto;
    margin-bottom: auto;
`;

const Title = styled.span`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.7px;
    line-height: 16px;
    text-align: left;
    margin-left: 18px;
    margin-top: auto;
    margin-bottom: auto;
`;

const EditButton = styled.button`
    background-color: transparent;
    border: none;
`;

const EditText = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    width: 26px;
    text-align: left;
    margin-right: 8px;
`;

export default HeaderInfo;
