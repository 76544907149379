import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { GLOBAL_STYLE_CONSTANTS } from 'config/Constants';

const DashboardContainer = styled.div`
    padding: ${GLOBAL_STYLE_CONSTANTS.padding};
    .ant-header-custom-employees {
        background-color: #f2f4f7;
    }
`;

const DashboardTabContainer = styled.div`
    margin-bottom: 60px;
`;

const DetailSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 5px;
`;

const TitleList = styled.span`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.7px;
    line-height: 16px;
    text-align: left;
`;

const BookingCode = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    letter-spacing: 0.88px;
    line-height: 13px;
    text-align: left;
`;

const TimeText = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    line-height: 12px;
    width: 147px;
    text-align: left;
`;

const TypeText = styled.span`
    color: #19c0ff;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
`;

const ItemText = styled.span`
    color: #35383f;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    align-self: center;
`;

const IcaoCode = styled.div``;

const AirportName = styled.div``;

export {
    IcaoCode,
    AirportName,
    DashboardContainer,
    // StatusCardsection,
    DashboardTabContainer,
    DetailSection,
    TitleList,
    BookingCode,
    TimeText,
    TypeText,
    ItemText
};
