import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, ButtonCustom } from 'components';
import { Row, Col, Image, Switch, Upload, Input } from 'antd';
import { editIcon, deleteIcon, iconCancel } from 'assets/images/icons';
import {
    NameSection,
    AvailabilitySection,
    AvailableText,
    Label
} from '../../styles';
import styled from 'styled-components';
import { WarningModal } from 'components/Modals';
import { SearchAirports } from 'components/Search';
import { GLOBAL_STYLE_CONSTANTS } from 'config/Constants';

const imageSizes = {
    width: '270px',
    height: '170px'
};

const { TextArea } = Input;

const template = (props) => {
    const { t, i18n } = useTranslation();
    const {
        detail = {},
        image = '',
        enabled = false,
        onChangeEnable,
        onDeleteDestination,
        onSaveChanges,
        updateImage
    } = props;

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [topDestinationInfo, setTopDestinationInfo] = useState({});

    useEffect(() => {
        if (!_.isEmpty(detail)) {
            setTopDestinationInfo({ ...detail });
        }
    }, [detail]);

    const onPressEdit = () => {
        if (isEdit) {
            setTopDestinationInfo(detail);
        }
        setIsEdit(!isEdit);
    };

    const onPressSubmitUpdate = () => {
        setIsEdit(false);
        onSaveChanges(topDestinationInfo);
    };

    const onRemove = () => {
        setShowDeleteModal(false);

        // delete action
        onDeleteDestination();
    };

    const onCancelWarning = () => {
        setShowDeleteModal(false);
    };

    const onSelectAirport = (value) => {
        let newDes = topDestinationInfo || {};
        newDes.to_airport = value;
        setTopDestinationInfo({ ...newDes });
    };

    return (
        <DetailContainer>
            <Header
                title={t('PROMOTIONS')}
                description={t('TOP_DESTINATION_DETAIL_SUBTITLE')}
            />

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <AvailabilitySection>
                    <AvailableText
                        enable={enabled}
                        style={{ marginRight: '10px' }}
                    >
                        {enabled
                            ? t('ENABLE').toUpperCase()
                            : t('DISABLE').toUpperCase()}
                    </AvailableText>
                    <Switch
                        checked={enabled}
                        style={{ marginLeft: 5 }}
                        onChange={() => {
                            onChangeEnable && onChangeEnable();
                        }}
                    />

                    {/* edit */}
                    <ButtonIcon
                        style={{ marginLeft: '40px' }}
                        onClick={() => onPressEdit()}
                    >
                        <Icon src={!isEdit ? editIcon : iconCancel} />
                    </ButtonIcon>

                    {/* delete */}
                    <ButtonIcon onClick={() => setShowDeleteModal(true)}>
                        <Icon src={deleteIcon} />
                    </ButtonIcon>
                </AvailabilitySection>
            </div>

            <GeneralWrapper>
                <Row>
                    <ImageWrapper edit={isEdit}>
                        <Upload
                            listType="picture-card"
                            showUploadList={false}
                            beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                            onChange={updateImage}
                            multiple={false}
                            disabled={!isEdit}
                        >
                            <Image
                                style={{
                                    width: imageSizes.width,
                                    height: imageSizes.height
                                }}
                                preview={!isEdit}
                                src={image}
                            />
                            {isEdit && (
                                <OverlayWrapper
                                    width={imageSizes.width}
                                    height={imageSizes.height}
                                >
                                    <OverlayContent>
                                        <UploadAvatar>
                                            {t(
                                                'CHANGE_DISPLAY_IMAGE'
                                            ).toUpperCase()}
                                        </UploadAvatar>
                                    </OverlayContent>
                                </OverlayWrapper>
                            )}
                        </Upload>
                    </ImageWrapper>
                    <InfoDetailSection>
                        <NameSection>
                            <ItemName color="#121216">
                                {topDestinationInfo?.name
                                    ? topDestinationInfo?.name?.toUpperCase()
                                    : '_'}
                            </ItemName>
                        </NameSection>
                        <InfoDetail>
                            <AirportName>
                                {`${
                                    topDestinationInfo?.to_airport?.name?.toUpperCase() ||
                                    ''
                                } - ${
                                    topDestinationInfo?.to_airport?.icao_code ||
                                    '_'
                                }`}
                            </AirportName>
                        </InfoDetail>
                    </InfoDetailSection>
                </Row>
            </GeneralWrapper>

            {isEdit && (
                <NameWrapper>
                    <Row>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Label style={{ marginTop: 0 }}>
                                {i18n.t('CHANGE_NAME').toUpperCase()}
                            </Label>
                            <Input
                                onChange={(val) =>
                                    setTopDestinationInfo({
                                        ...topDestinationInfo,
                                        name: val.target.value
                                    })
                                }
                                className="address"
                                style={{ fontFamily: 'Lato', color: '#121216' }}
                                placeholder={i18n.t('CHANGE_NAME')}
                                value={topDestinationInfo?.name || ''}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Label style={{ marginTop: 0 }}>
                                {i18n.t('AIRPORT').toUpperCase()}
                            </Label>
                            <SearchAirports
                                airport={topDestinationInfo?.to_airport}
                                onSelectAirport={onSelectAirport}
                                inputStyle={{ width: '100%' }}
                            />
                        </Col>
                    </Row>
                </NameWrapper>
            )}

            <AboutTitle>{t('ABOUT').toUpperCase()}</AboutTitle>
            <Component>
                <InfoWrapper>
                    <TypeTitle>{i18n.t('DESCRIPTION')}</TypeTitle>
                </InfoWrapper>
                <Row style={{ marginLeft: 25, marginTop: 15 }}>
                    {!isEdit ? (
                        <DescriptionText>
                            {topDestinationInfo?.description || ''}
                        </DescriptionText>
                    ) : (
                        <TextArea
                            onChange={(val) =>
                                setTopDestinationInfo({
                                    ...topDestinationInfo,
                                    description: val.target.value
                                })
                            }
                            rows={4}
                            style={{ marginRight: 25, marginBottom: 15 }}
                            placeholder={i18n.t('DESCRIPTION')}
                            value={topDestinationInfo?.description || ''}
                        />
                    )}
                </Row>
            </Component>

            <FooterWrapper>
                {isEdit && (
                    <ButtonCustom
                        title={t('SAVE_CHANGES').toUpperCase()}
                        btnType="primary"
                        size="large"
                        disabled={false}
                        onClick={() => onPressSubmitUpdate()}
                    />
                )}
            </FooterWrapper>

            {/* modal */}
            <WarningModal
                show={showDeleteModal}
                title={t('CONFIRM_DELETE_AIRPORT').toUpperCase()}
                okText={t('YES_REMOVE_IT').toUpperCase()}
                cancelText={t('NO_DISREGARD').toUpperCase()}
                onOk={onRemove}
                onCancel={onCancelWarning}
            />
        </DetailContainer>
    );
};

export { template };

const OverlayWrapper = styled.div`
    position: absolute;
    background-color: rgba(0, 0, 0, 0.35);
    width: ${(props) => props.width || '270px'};
    height: ${(props) => props.height || '170px'};
`;

const OverlayContent = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
`;

const ButtonIcon = styled.a`
    border: 1px solid #cdd1d8;
    border-radius: 2px;
    width: 36px;
    height: 36px;
    margin-left: 20px;
    padding: 7px;
`;

const Icon = styled.img`
    width: 16px;
    height: 16px;
`;

const GeneralWrapper = styled.div`
    background-color: #ffffff;
    border-radius: 4px;
    padding: 20px;
    margin-top: 20px;
`;

const InfoDetailSection = styled(Col)`
    margin-left: 25px;
    height: 100%;
`;

const ItemName = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 20px;
    letter-spacing: 0.8px;
    line-height: 25px;
    text-align: left;
    margin-bottom: 15px;
`;

const InfoDetail = styled(Row)``;

const AboutTitle = styled.div`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.7px;
    line-height: 16px;
    text-align: left;
    margin-top: 40px;
`;

const Component = styled.div`
    flex: row;
    background-color: #ffffff;
    border-radius: 4px;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
`;

const InfoWrapper = styled.div`
    background-color: #f6f9fc;
    border-radius: 4px 4px 0 0;
    height: 50px;
    padding-left: 25px;
    padding-top: 15px;
`;

const TypeTitle = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 17px;
    text-align: left;
`;

const DescriptionText = styled.div`
    color: #65686f;
    font-family: Lato;
    font-size: 14px;
    line-height: 24px;
    width: 1465px;
    text-align: left;
    margin-bottom: 20px;
    margin-right: 20px;
    margin-top: 5px;
`;

const DetailContainer = styled.div`
    padding: ${GLOBAL_STYLE_CONSTANTS.padding};
    min-height: 100vh;
`;

const AirportName = styled.div`
    color: #19c0ff;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
`;

const UploadAvatar = styled.span`
    color: #ffffff;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: right;
    position: absolute;
    align-self: center;
`;

const ImageWrapper = styled.div`
    position: relative;
`;

const NameWrapper = styled.div`
    flex: row;
    background-color: #ffffff;
    border-radius: 4px;
    margin-top: 40px;
    padding: 25px;
`;

const FooterWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 40px;
`;
