import React, { useState } from 'react';
import { Drawer, Button } from 'antd';
import styled from 'styled-components';
import { Text } from 'components';
import { HeaderInfo } from 'components/Cards';
import { aircraftIcon } from 'assets/images/icons';
import moment from 'moment';
import _ from 'lodash';
import { renderAddress } from 'utils';
import { WrapperListNoti } from '../../styles';

const Reminder = (props) => {
    const { ref = {}, content = '', createAt = '', address = {} } = props;

    return (
        <Container>
            <WrapperListNoti>
                <HeaderInfo
                    title={'EVENT'.toUpperCase()}
                    icon={<img src={aircraftIcon} />}
                />
                <div style={{ marginLeft: 50 }}>
                    <TextItem>
                        {createAt
                            ? moment(createAt).format(
                                  'dddd, hh:mm A DD MMM YYYY'
                              )
                            : ''}
                    </TextItem>

                    <TextTitle style={{ marginTop: '8px' }}>
                        {content}
                    </TextTitle>

                    {!_.isEmpty(address) && (
                        <TextItem style={{ marginTop: '8px' }}>
                            {renderAddress(address)}
                        </TextItem>
                    )}
                </div>
            </WrapperListNoti>
        </Container>
    );
};

export default Reminder;

const Container = styled.div`
    margin-top: 20px;
    &:hover {
        background-color: #f2f8fc;
        border: 1px solid #dadadb;
    }
`;

const TextItem = styled.div`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 11px;
    line-height: 16px;
    text-align: left;
    margin-top: 3px;
`;

const TextTitle = styled.div`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    letter-spacing: 0.46px;
    line-height: 13px;
    text-align: left;
`;

const ButtonWrapper = styled.a``;

const TextShowAll = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    letter-spacing: 0.4px;
    line-height: 13px;
    text-align: right;
`;
