import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { template as tmpl } from './ForgotPasswordConfirm.tmpl';
import { verifyOTPCode } from 'redux/actions/appActions';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { FORGOT_PASSWORD_RESET } from 'routes/main';

const ForgotPasswordConfirmLogic = (props) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [payload, setPayload] = useState({});
    const [otp, setOtp] = useState('');
    const [uid, setUid] = useState('');

    useEffect(() => {
        if (props.location && props.location.state) {
            const { uid, payload } = props.location.state;
            setPayload(payload);
            setUid(uid);
        }
    }, [props.location?.state]);

    const handleSubmitOTP = async () => {
        if (loading) return;

        setLoading(true);

        const { type } = payload;

        let data = {
            type: type,
            code: otp,
        };
        let result = await dispatch(verifyOTPCode(uid, data));
        if (result && result.success) {
            let uid = result.uid;
            let avt = result.avt;
            
            props.history.push({
                pathname: FORGOT_PASSWORD_RESET,
                state: { payload: payload, uid, avt }
            });
        } else if (result.errors) {
            setErrors({ otp: result.errors });
        }

        setTimeout(() => {
            setLoading(false);
        }, 350);
    };

    const handleChangeOtp = (otp) => {
        setOtp(otp);
    };

    const initProps = {
        loading: loading,
        errors: errors,
        handleSubmitOTP: handleSubmitOTP,
        payload: payload,
        otp: otp,
        setOtp: setOtp,
        handleChangeOtp: handleChangeOtp
    };
    return tmpl && tmpl({ ...initProps });
};

class ForgotPasswordConfirm extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <ForgotPasswordConfirmLogic {...initProps} />;
    }
}

export default withRouter(ForgotPasswordConfirm);
