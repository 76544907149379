import React, { useState, useEffect } from 'react';
import { template as tmpl } from './AddTopDestination.tmpl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { uploadImage } from 'redux/actions/appActions';
import { addTopDestination } from 'redux/actions/airportAction';
import { UPLOAD_MODEL } from 'redux/Constants';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FLIGHT_PROMOTION_LIST } from 'routes/main';

const AddTopDestinationLogic = (props) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const { addTopDestination, uploadImage } = props;

    const navigate = (route) => {
        props.history.push(route);
    };

    const onSubmit = async (data) => {
        let rs = await addTopDestination(data);
        if (!rs.success) {
            let modal = {
                show: true,
                title: t('ADD_NEW_TOP_DESTINATION_ERROR'),
                buttonTitle: 'OK',
            };
            dispatch({ type: 'SETTING_SHOW_MODAL', data: modal });
            return;
        }

        rs = await uploadImage(rs.data.id, data.photo, UPLOAD_MODEL.ROUTE);
        if (!rs.success) {
            let modal = {
                show: true,
                title: t('ADD_NEW_TOP_DESTINATION_ERROR'),
                buttonTitle: 'OK',
            };
            dispatch({ type: 'SETTING_SHOW_MODAL', data: modal });
            return;
        }

        let modal = {
            show: true,
            title: t('ADD_NEW_TOP_DESTINATION_SUCCESS'),
            buttonTitle: 'OK',
            onOk: FLIGHT_PROMOTION_LIST,
            onCancel: FLIGHT_PROMOTION_LIST
        };
        dispatch({ type: 'SETTING_SHOW_MODAL', data: modal });
    };

    const initProps = {
        onSubmit: onSubmit,
        navigate: navigate
    };

    return tmpl && tmpl({ ...initProps });
};

class AddTopDestinationContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <AddTopDestinationLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { companies } = companyReducer;
    return {
        companies: companies
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTopDestination: (data) => dispatch(addTopDestination(data)),
        uploadImage: (id, photo, model) =>
            dispatch(uploadImage(id, photo, model))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AddTopDestinationContainer)
);
