import React, { useState } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { Header } from '../../../components';

import General from '../components/general';
import Identification from '../components/identification';
import { GLOBAL_STYLE_CONSTANTS } from 'config/Constants';

const template = (props) => {
    return (
        <EmployeeDetailContainer>
            <Header title={'Employees'} description={'Employee Information'} />
            <ContainerWrapper>
                <General {...props} />
            </ContainerWrapper>
            <ContainerWrapper>
                <Identification {...props} />
            </ContainerWrapper>
        </EmployeeDetailContainer>
    );
};

const EmployeeDetailContainer = styled.div`
    padding: ${GLOBAL_STYLE_CONSTANTS.padding};
    height: 100vh;
    .ant-header-custom-employees {
        background-color: #f2f4f7;
    }
`;

const ContainerWrapper = styled.div``;

export { template };
