import React from 'react';
import 'antd/dist/antd.css';
import { Input } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { searchIcon } from 'assets/images/icons';
import { bookingCategory } from 'config/Constants';
import { DropDownInput } from 'components';

const SearchIcon = <img src={searchIcon} />;

const SearchBooking = ({
    onSearch,
    onSelectCategory,
    placeholder = '',
    data
}) => {
    const { t, i18n } = useTranslation();

    // const createCategoryOption = () => {
    //     let tempArr = [];
    //     let key;
    //     for (key in bookingCategory) {
    //         tempArr.push({
    //             text: t(bookingCategory[key]),
    //             value: bookingCategory[key]
    //         });
    //     }
    //     return tempArr;
    // };

    return (
        <SearchSection>
            <LabelLight>{t('SEARCH_BY').toUpperCase()}</LabelLight>
            <div style={{ display: 'flex' }}>
                <DropDownInput
                    width="240px"
                    height="50px"
                    placeholder={t(bookingCategory.ALL)}
                    data={data}
                    onChange={onSelectCategory}
                />
                <Input
                    style={{ marginLeft: 25 }}
                    placeholder={placeholder}
                    allowClear
                    suffix={SearchIcon}
                    onChange={(e) => onSearch && onSearch(e)}
                />
            </div>
        </SearchSection>
    );
};

const SearchSection = styled.div`
    background-color: #fff;
    min-height: 102px;
    margin-top: 30px;
    padding: 20px;
    margin-bottom: 30px;
    .ant-input {
        background-color: transparent;
    }
    .ant-input-affix-wrapper {
        border: 1px solid #dadadb;
        border-radius: 3px;
        width: 20vw;
        height: 50px;
        min-width: 306px;
        background-color: transparent;
    }
`;

const LabelLight = styled.div`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
    margin-bottom: 10px;
`;

export default SearchBooking;
