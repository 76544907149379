import React, { useState, useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';

import { store } from './redux/store';
import ScrollToTop from './components/Cards/ScrollToTop';
import { ProvideAuth } from './navigation/auth/ProvideAuth';
import AppContainer from './containers/app';
import Login from './views/Login';
import io from 'socket.io-client';
import { SocketContext } from './modules/socket';
import { withTranslation } from 'react-i18next';
// import './App.css';
import './App.less';
import { SOCKET_URL } from './config';
import auth from './services/auth';
import { SOCKET_EVENT, SOCKET_MESSAGE_TYPE } from './redux/Constants';
import { ActivateAccount } from 'views/ActivateAccount';
import { ForgotPassword } from 'views/ForgotPassword';
import { ResetPassword } from 'views/ResetPassword';
import { ForgotPasswordConfirm, ForgotPasswordReset } from 'views/Auths';
import Register from 'views/Register';
import {
    REGISTER,
    ACTIVATE_ACCOUNT,
    LOGIN,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_CONFIRM,
    FORGOT_PASSWORD_RESET,
    RESET_PASSWORD
} from 'routes/main';

function App() {
    const [socketInstance, setSocketInstance] = useState(null);
    const [user] = useState(auth.getUserInfo());
    const [access_token] = useState(auth.getToken());

    useEffect(() => {
        if (user && access_token) {
            console.log('connect socket now', SOCKET_URL);

            const socket = io(SOCKET_URL, {
                jsonp: false,
                query: { session_id: JSON.parse(access_token) },
                transports: ['websocket']
            });

            socket.on(SOCKET_EVENT.CONNECT, function () {
                console.log('socket connect successfully ', socket.id);
            });

            socket.on(SOCKET_EVENT.ERROR, function (error) {
                console.log('socket connect error ', error);
            });

            socket.on(SOCKET_EVENT.DISCONNECT, function (id) {
                console.log('on disconnect', id);
            });

            socket.on(SOCKET_EVENT.ON_MESSAGE, onMessageHandler);

            setSocketInstance(socket);
        }
    }, [user]);

    const onMessageHandler = (message) => {
        console.log('new messae', message);
        switch (message.type) {
            case SOCKET_MESSAGE_TYPE.NEW_CUSTOMER:
                // handle new employee -> update customer list
                break;
            case SOCKET_MESSAGE_TYPE.NEW_EMPLOYEE:
                // handle new employee -> update employee list

                break;
            default:
                break;
        }
    };

    return (
        <SocketContext.Provider value={socketInstance}>
            <ProvideAuth>
                <ReduxProvider store={store}>
                    <BrowserRouter>
                        <ScrollToTop>
                            <Switch>
                                <Route exact path={LOGIN} component={Login} />
                                <Route
                                    exact
                                    path={FORGOT_PASSWORD}
                                    component={ForgotPassword}
                                />
                                <Route
                                    exact
                                    path={FORGOT_PASSWORD_CONFIRM}
                                    component={ForgotPasswordConfirm}
                                />
                                <Route
                                    exact
                                    path={FORGOT_PASSWORD_RESET}
                                    component={ForgotPasswordReset}
                                />
                                <Route
                                    exact
                                    path={RESET_PASSWORD}
                                    component={ResetPassword}
                                />
                                <Route
                                    exact
                                    // path="/activate/:uid/:activeId"
                                    path={ACTIVATE_ACCOUNT}
                                    component={ActivateAccount}
                                />
                                <Route
                                    exact
                                    path={REGISTER}
                                    component={Register}
                                />
                                <AppContainer />
                                {/* <Route exact path='/logout' component={Logout} /> */}

                                {/* 404: Inside and Outside Screen */}
                                {/* TODO */}
                            </Switch>
                        </ScrollToTop>
                    </BrowserRouter>
                </ReduxProvider>
            </ProvideAuth>
        </SocketContext.Provider>
    );
}

export default withTranslation()(App);
