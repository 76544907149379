import React from 'react';
import styled from 'styled-components';
import background from '../../../assets/images/BG-image.jpg';
import 'antd/dist/antd.css';
import { Card, Form, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import logo from '../../../assets/images/logo/Logo_JetOS_04_horizontal.png';
import '../../../assets/fonts/Avenir-Light/Avenir-Light.css';

const template = (props) => {
    const { t, i18n } = useTranslation();
    const {
        payload = {},
        loading,
        password,
        setPassword,
        confirmPassowrd,
        setConfirmPassword,
        errorPassword,
        errorConfirmPassword,
        handleResetPassword,
    } = props;
    const { type, email, phone } = payload;

    return (
        <StyledBackground>
            <HeaderSection>
                <LogoSection>
                    <Logo src={logo} />
                </LogoSection>
                <CreateAccountSection></CreateAccountSection>
            </HeaderSection>

            <ContentSection>
                <Card className="ant-card-login">
                    <WelcomeSection>
                        <WelcomeText>
                            {`${t('HI, ')} ${type === 'sms' ? phone : email}`}
                        </WelcomeText>
                    </WelcomeSection>
                    <InstructionSection>
                        <InstructionText>
                            {t('INSTRUCTION_RESET_PASSWORD')}
                        </InstructionText>
                    </InstructionSection>
                    <LoginInformationSection>
                        <Form layout="vertical">
                            <Form.Item
                                label={
                                    <LabelText>
                                        {t('NEW_PASSWORD').toUpperCase()}
                                    </LabelText>
                                }
                            >
                                <Input.Password
                                    style={inputStyle}
                                    value={password}
                                    placeholder={t('NEW_PASSWORD')}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                                {errorPassword && (
                                    <ErrorText>
                                        {t(errorPassword)}
                                    </ErrorText>
                                )}
                            </Form.Item>
                            <Form.Item
                                label={
                                    <PasswordLabelSection>
                                        <LabelText>
                                            {t(
                                                'CONFIRM_PASSWORD'
                                            ).toUpperCase()}
                                        </LabelText>
                                    </PasswordLabelSection>
                                }
                            >
                                <Input.Password
                                    style={inputStyle}
                                    value={confirmPassowrd}
                                    placeholder={t('CONFIRM_PASSWORD')}
                                    onChange={(e) =>
                                        setConfirmPassword(e.target.value)
                                    }
                                />
                                {errorConfirmPassword && (
                                    <ErrorText>
                                        {t(errorConfirmPassword)}
                                    </ErrorText>
                                )}
                            </Form.Item>
                            <Form.Item>
                                <StyledButton
                                    onClick={() => {
                                        handleResetPassword &&
                                            handleResetPassword();
                                    }}
                                >
                                    {loading ? (
                                        <LoadingOutlined
                                            style={{
                                                fontSize: '24px',
                                                color: '#ffffff'
                                            }}
                                        />
                                    ) : (
                                        <SubmitButtonText>
                                            {t('RESET').toUpperCase()}
                                        </SubmitButtonText>
                                    )}
                                </StyledButton>
                            </Form.Item>
                        </Form>
                    </LoginInformationSection>
                </Card>
            </ContentSection>

            <FooterSection></FooterSection>
        </StyledBackground>
    );
};

const StyledBackground = styled.div`
    background-image: url(${background});
    height: 100vh;
    min-width: 100%;
    min-height: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    .ant-row-center {
        padding-left: 3rem;
        padding-top: 1rem;
        padding-right: 3rem;
    }
`;

const HeaderSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 3rem;
    padding-top: 1rem;
    padding-right: 3rem;
`;

const FooterSection = styled.div``;

const LogoSection = styled.div``;

const CreateAccountSection = styled.div`
    margin-top: auto;
    margin-bottom: auto;
`;

const Logo = styled.img`
    height: 50px;
    width: auto;
`;

const ErrorText = styled.span`
    padding-top: 5px;
    color: #f42020;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
`;

const ContentSection = styled.div`
    display: flex;
    justify-content: center;
    .ant-card-login {
        border-radius: 8px;
        width: 40vw;
        min-width: 300px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .ant-card-expired {
        border-radius: 8px;
        width: 35vw;
        min-width: 300px;
        /* padding-left: 30px;
        padding-right: 30px; */
    }
`;

const StyledButton = styled.button`
    width: 100%;
    background-color: #19c0ff;
    border-radius: 4px;
    border: none;
    height: 50px;
`;

const SubmitButtonText = styled.span`
    color: #ffffff;
    font-family: AvenirNext-DemiBold;
    font-size: 13px;
    letter-spacing: 1px;
    line-height: 16px;
    text-align: center;
`;

const WelcomeSection = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 40px;
`;

const WelcomeText = styled.span`
    font-size: 20px;
    letter-spacing: 1.5px;
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 24px;
    letter-spacing: 2px;
    line-height: 32px;
    text-align: center;
`;

const InstructionSection = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
`;

const InstructionText = styled.span`
    color: #65686f;
    font-family: Lato;
    font-size: 13px;
    line-height: 24px;
`;

const LoginInformationSection = styled.div`
    display: flex;
    justify-content: center;
`;

const LabelText = styled.span`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
`;

const PasswordLabelSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 29.6875vw;
    .forgot-password-button {
        float: right;
    }
    .forgot-password-text {
        text-align: right;
    }
    /* Specific for iPhone 12 Pro Max and  devices have the view port width above */
    @media (max-width: 700px) {
        .forgot-password-button {
            float: left;
            width: 29.6875vw;
            padding-left: 0;
            align-items: flex-start;
            display: flex;
        }
        .forgot-password-text {
            text-align: left;
        }
    }
`;

const inputStyle = {
    height: '50px',
    border: '1px solid rgba(36, 36, 45, 0.15)',
    borderRadius: '3px'
};

export { template };
