import React from 'react';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import { ButtonStyle } from 'styles/variable';

/**
 *
 * @param {string} title - the text is shown on the button
 * @param {string} size - small - medium - large
 * @param {function} onClick - handle function for Click event
 * @param {boolean} disabled - disabled button
 * @param {boolean} loading - loading button
 * @returns {JSX}
 */
const SecondaryButton = (props) => {
    const {
        title = '',
        size = 'medium',
        onClick,
        disabled,
        loading,
        width,
        icon,
        background,
        wrapStyle = {},
        borderStyle
    } = props;
    const handleOnClick = () => {
        if (onClick && typeof onClick === 'function') {
            onClick();
        }
    };

    const styles = ButtonStyle[size] || {};
    return (
        <ButtonContainer
            disabled={disabled}
            onClick={handleOnClick}
            width={width || styles.width}
            height={styles.height}
            border={borderStyle || styles.border}
            inactive={disabled}
            background={background}
            style={wrapStyle}
        >
            <ButtonBackground>
                {loading ? (
                    <LoadingOutlined
                        style={{ fontSize: '20px', color: '#16161B' }}
                    />
                ) : (
                    <>
                        {icon && <Icon src={icon} />}
                        <ButtonText
                            className="text-secondary-btn"
                            fontSize={styles.fontSize}
                            letterSpacing={styles.letterSpacing}
                            lineHeight={styles.lineHeight}
                        >
                            {title}
                        </ButtonText>
                    </>
                )}
            </ButtonBackground>
        </ButtonContainer>
    );
};

const Icon = styled.img`
    width: 12px;
    height: 12px;
    margin-right: 6px;
`;

const ButtonContainer = styled.button`
    width: ${(props) => (props.width ? props.width : '190px')};
    border: ${(props) =>
        !props.inactive ? props.border || '1px solid #19c0ff' : 'none'};
    border-radius: 2px;
    height: ${(props) => (props.height ? props.height : '40px')};
    background-color: ${(props) => (!props.inactive ? (props.background || '#fff') : '#8A99A9')};
    background-repeat: no-repeat;
    overflow: hidden;
    outline: none;
    cursor: pointer;
    &:hover {
        background-color: ${(props) => !props.inactive && '#F0FBFF'};
    };
`;

const ButtonText = styled.span`
    color: ${(props) => (!props.inactive ? '#16161B' : '#fff')};
    font-family: AvenirNext-DemiBold;
    font-size: ${(props) => props.fontSize || '11px'};
    letter-spacing: ${(props) => props.letterSpacing || '0.7px'};
    line-height: ${(props) => props.lineHeight || '13px'};
    text-align: center;
`;

const ButtonBackground = styled.div`
    background-color: transparent;
    display: flex;
    justify-content: center;
    padding-top: 1.5px;
`;
export default SecondaryButton;
