import {
    CREATE_REQUEST_QUOTE,
    UPDATE_QUOTE_SELECTED_AIRCRAFT,
    UPDATE_QUOTE_CUSTOM_SERVICE,
    UPDATE_QUOTE_PRICE_SELECTED_AIRCRAFT,
    UPDATE_QUOTE_PRICE_PACKAGES,
    UPDATE_QUOTE_STEP,
    CREATE_QUOTE_PACKAGES,
    UPDATE_QUOTE_PACKAGES,
    DELETE_QUOTE_PACKAGES
} from '../Constants';
import { CREATE_QUOTATION_STEP } from 'config/Constants';
import _ from 'lodash';

const INITIAL_STATE = {
    quotes: [] // quote list: Object - { data: {}, step: CREATE_QUOTATION_STEP }
};

export default function quoteReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case CREATE_REQUEST_QUOTE:
            const { quote = {} } = action.data;
            let newQuotes = state.quotes || [];
            let index = newQuotes.findIndex(item => item.id === action.data.id);
            if (index > -1) {
                newQuotes[index] = { ...quote, step: newQuotes[index].step };
            } else {
                newQuotes.push({ 
                    ...quote, 
                    step: quote.step || CREATE_QUOTATION_STEP.AIRCRAFT
                });
            }

            return {
                ...state,
                quotes: [ ...newQuotes ]
            };
        case UPDATE_QUOTE_STEP:
            let newQuotes1 = state.quotes || [];
            let index1 = newQuotes1.findIndex(item => item.id === action.data.id);
            if (index1 > -1 && action.data.step) {
                newQuotes1[index1]['step'] = action.data.step;
                return {
                    ...state,
                    quotes: [ ...newQuotes1 ]
                }
            }
        case UPDATE_QUOTE_SELECTED_AIRCRAFT:
            const { legs = [] } = action.data;
            let newQuotes2 = state.quotes || [];

            try {
                let index2 = newQuotes2.findIndex(item => item.id === action.data.id);
                if (index2 > -1 && legs.length > 0) {
                    let newLegs0 = newQuotes2[index2].legs.map((legItem = {}, index) => {
                        let newLegItem = legItem;
                        let legData = legs.find(item => item.leg_id === newLegItem?.id);
                        if (legData && legData.select_aircrafts) {
                            newLegItem.select_aircrafts = newLegItem.select_aircrafts.map((selectItem = {}) => {
                                let newSelectItem = selectItem;
                                let selectedAircraft = legData.select_aircrafts.find(item => item.aircraft_id === selectItem?.aircraft?.id);
                                if (selectedAircraft) {
                                    newSelectItem.price_components = selectedAircraft.price_components;
                                }
                                return newSelectItem;
                            });
                        }
    
                        return newLegItem;
                    });
                    newQuotes2[index2].legs = newLegs0;
    
                    return {
                        ...state,
                        quotes: [ ...newQuotes2 ]
                    }
                }
            } catch (e) {};
        case UPDATE_QUOTE_CUSTOM_SERVICE: 
            const legs1 = action.data.legs || [];
            let newQuotes3 = state.quotes || [];

            try {
                let index3 = newQuotes3.findIndex(item => item.id === action.data.id);
                if (index3 > -1 && legs1.length > 0) {
                    let newLegs = newQuotes3[index3].legs.map((legItems = {}, index) => {
                        let newLegItem = legItems;
                        let legData = legs1.find(item => item.leg_id === newLegItem?.id);
                        if (legData) {
                            newLegItem.services = legData.services;
                            
                            newLegItem.select_aircrafts = newLegItem.select_aircrafts.map((selectItem = {}) => {
                                let newSelectItem = selectItem;
                                let selectedAircraft = legData.select_aircrafts.find(item => item._aircraft === selectItem?.aircraft?.id);
                                if (selectedAircraft) {
                                    newSelectItem.price_components = selectedAircraft.price_components;
                                }
                                return newSelectItem;
                            });
                        }
                        return newLegItem;
                    });
    
                    newQuotes3[index3].legs = newLegs;
                    if (action.data.packages && action.data.packages.length > 0) {
                        newQuotes3[index3].packages = action.data.packages;
                    }
    
                    return {
                        ...state,
                        quotes: [ ...newQuotes3 ]
                    }
                }
            } catch (e) {};
        case CREATE_QUOTE_PACKAGES:
            try {
                let newQuotes4 = state.quotes || [];
                let packages = action.data.packages || [];
                if (!_.isEmpty(packages) && packages.length > 0) {
                    let index4 = newQuotes4.findIndex(item => item.id === action.data.id);
                    if (index4 > -1) {
                        newQuotes4[index4].packages.push(packages);
                    }

                    return {
                        ...state,
                        quotes: [ ...newQuotes4 ]
                    };
                }
            } catch (e) {}
        case UPDATE_QUOTE_PACKAGES:
            try {
                let newQuotes4 = state.quotes || [];
                let packages = action.data.packages || [];
                if (!_.isEmpty(packages) && packages.length > 0) {
                    let index4 = newQuotes4.findIndex(item => item.id === action.data.id);
                    if (index4 > -1) {
                        // update packages
                    }

                    return {
                        ...state,
                        quotes: [ ...newQuotes4 ]
                    };
                }
            } catch (e) {}
        case DELETE_QUOTE_PACKAGES:
            try {
                let newQuotes5 = state.quotes || [];
                let index5 = newQuotes5.findIndex(item => item.id === action.data.id);
                if (index5 > -1 && action.data.index >= 0) {
                    // find index of packages and remove it.
                    newQuotes5[index5].packages.splice(action.data.index, 1);
                }

                return {
                    ...state,
                    quotes: [ ...newQuotes5 ]
                };
            } catch (e) {}
        case UPDATE_QUOTE_PRICE_SELECTED_AIRCRAFT:
            try {
                const { id, legIndex, selectedIndex, price } = action.data;
                let newQuotes6 = state.quotes || [];
                let index6 = newQuotes6.findIndex(item => item.id === id);
                if (index6 > -1 && price) {
                    newQuotes6[index6].legs[legIndex].select_aircrafts[selectedIndex].price_components = { ...price };
                    return {
                        ...state,
                        quotes: [ ...newQuotes6 ]
                    };
                }
            } catch (e) {}
        case UPDATE_QUOTE_PRICE_PACKAGES:
            try {
                const { id, pkIndex, packages = [] } = action.data;
                let newQuotes6 = state.quotes || [];
                let index6 = newQuotes6.findIndex(item => item.id === id);
                if (index6 > -1) {
                    let newPk = newQuotes6[index6].packages[pkIndex];
                    newPk = newPk.map((leg, i) => {
                        let newLeg = leg;
                        let pk = packages.find(v => v.leg_id === newLeg.leg_id);
                        if (pk) {
                            newLeg.price_components = pk.price_components;
                        }
                        return newLeg;
                    });
                    newQuotes6[index6].packages[pkIndex] = newPk;

                    return {
                        ...state,
                        quotes: [ ...newQuotes6 ]
                    };
                }
            } catch (e) {}
        default:
            return { ...state };
    }
}
