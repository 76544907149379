import { UPDATE_USER_INFORMATION, CLEAR_DATA_USER } from '../Constants';

const INITIAL_STATE = {
    id: '',
    address: {
        address_line1: '',
        address_line2: '',
        city: '',
        postalcode: '',
        state: '',
        country: {
            english_name: '',
            native_name: '',
            short_code: ''
        }
    },
    email: '',
    firstname: '',
    lastname: '',
    image: '',
    background: '',
    device_tokens: [],
    identity: {},
    role: '',
    settings: '',
    meta: {},
    companies: [],
    gender: null,
    phone: '',
    weight_lb: '',
    is_online: false,
    language: {},
    identification: [],
    membership: {},
    style: {
        images: []
    }
};

export default function userReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case UPDATE_USER_INFORMATION:
            return {
                ...state,
                ...action.payload
            };
        case CLEAR_DATA_USER:
            return {
                ...state,
                INITIAL_STATE
            };
        default:
            return { ...state };
    }
}
