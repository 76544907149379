import React from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { removeIcon } from '../../assets/images/icons';

const AircraftItem = ({ val = {}, onRemove, isFinal = false }) => {
    return (
        <AircraftContainer isFinal={isFinal}>
            {val.image && <AircraftImage image={val.image.url} />}
            <AircraftName>{val.name && val.name.toUpperCase()}</AircraftName>
            <RetailCode>{val.tail_number}</RetailCode>
            <Button onClick={() => onRemove(val)}>
                <Text>REMOVE</Text>
                <Icon src={removeIcon} />
            </Button>
        </AircraftContainer>
    );
};

export default AircraftItem;

const Icon = styled.img``;

const Button = styled.button`
    position: absolute;
    top: 15px;
    right: 25px;
    border: none;
    background-color: transparent;
`;

const AircraftContainer = styled.div`
    width: 95%;
    position: relative;
    border-bottom: ${(props) =>
        !props.isFinal ? '1px solid rgba(14, 14, 18, 0.08)' : 'none'};
    padding-bottom: 12px;
    margin-top: 15px;
    margin-bottom: 5px;
    /* margin-left: 15px; */
`;

const AircraftImage = styled.img`
    background-image: url(${(props) => props.image});
    background-size: 100%;
    border: none;
    width: 70px;
    height: 44px;
`;

const AircraftName = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    line-height: 12px;
    width: 205px;
    text-align: left;
    margin-left: 12px;
`;

const RetailCode = styled.span`
    color: #19c0ff;
    font-family: AvenirNext-Bold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    width: 53px;
    text-align: center;
    border: 1px solid #dadadb;
    border-radius: 2px;
    width: 58px;
    height: 20px;
    margin-left: 15px;
    padding: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
`;

const Text = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 18px;
    text-align: left;
    margin-right: 15px;
`;
