import React, { useEffect, useState } from 'react';
import { template as tmpl } from './QuoteRequestDetail.tmpl';
import { getQuoteById } from 'redux/actions/quoteAction';
import { connect, useDispatch, useSelector } from 'react-redux';
import { SHOW_LOADING, HIDE_LOADING } from 'redux/Constants';

const QuoteRequestDetailLogic = (props) => {
    const { activeCompany, location } = props;
    const { search } = location;
    const [quoteDetail, setQuoteDetail] = useState({});
    const [refreshPage, setRefreshPage] = useState(false);

    const dispatch = useDispatch();
    const { isLoading } = useSelector(state => state.appReducer || {});

    /**
     * Get Quote detail from backend
     */
    useEffect(() => {
        fetchQuotedetail();
    }, [props.location.search]);

    useEffect(() => {
        if (refreshPage) {
            fetchQuotedetail();
            setRefreshPage(false);
        }
    }, [refreshPage]);

    const fetchQuotedetail = async () => {
        // if (isLoading) return;
        dispatch({ type: SHOW_LOADING });
        
        let id = search;
        if (id && id[0] === '?') {
            id = id.substring(1);
        }
        const result = await props.getQuoteById(id);
        if (result.data) {
            setQuoteDetail(result.data);
        }

        setTimeout(() => {
            dispatch({ type: HIDE_LOADING });
        }, 450);
    };

    const changeAvailable = async (id) => {
        let index = serviceList.findIndex((obj) => {
            return obj.id === id;
        });
        let tempServiceList = [...serviceList];
        tempServiceList[index].available = !tempServiceList[index].available;
        const result = await props.updateServiceItemById(
            id,
            tempServiceList[index]
        );
        if (result.isConnected) {
            setServiceList(tempServiceList);
        } else {
            //TODO: show dialog error when fail at calling API
        }
    };

    const navigate = (route, search, state) => {
        props.history.push({
            pathname: route,
            search: search,
            state: state ? { data: state } : null
        });
    };

    const initProps = {
        setRefreshPage: setRefreshPage,
        activeCompany: activeCompany,
        quoteDetail: quoteDetail,
        navigate: navigate,
        changeAvailable: changeAvailable
    };
    return tmpl && tmpl({ ...initProps });
};

class QuoteRequestDetail extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <QuoteRequestDetailLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { employees, activeCompany } = companyReducer;
    return {
        employees: employees,
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getQuoteById: (id) => dispatch(getQuoteById(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuoteRequestDetail);
