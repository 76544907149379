import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { ButtonCustom, Header, Text, EmptyComponent } from 'components';
import { searchIcon } from 'assets/images/icons';
import { CustomerContainer, SearchSection } from '../styles';
import _ from 'lodash';
import styled from 'styled-components';
import {
    Bookings,
    Invoices,
    QuoteRequest,
    Reminder,
    Flights
} from './types';
import { NOTIFICATION_TYPES } from 'config/Constants';

const template = (props) => {
    const {
        navigate,
        notifications = {},
        onLoadMore,
        onClickMarkAllRead,
        onClickReadNoti
    } = props;
    const {
        docs = [],
        limit = 10,
        next = '',
        page = 1,
        pages = 1,
        total = 0
    } = notifications;

    const { t, i18n } = useTranslation();
    const SearchIcon = <img src={searchIcon} />;

    const _renderNotification = (noti = {}) => {
        const {
            ref_basic_info = {},
            content = '',
            created_at = '',
            type = '',
            user_basic_info = {},
            _id = '', // notificationId
            _on = '', // id of booking/flight/invoice/request...
            _onRef = '',
            seen = false,
            meta = {}
        } = noti;
        const { status = '' } = ref_basic_info;
        const { key = '' } = meta;
        // let title = t(`NOTI_${_onRef.toUpperCase()}_${status.toUpperCase()}`);
        let title = key ? t(key) : '';

        switch (_onRef) {
            case NOTIFICATION_TYPES.COMPANY:
                return (
                    <Reminder
                        title={title}
                        ref_basic_info={ref_basic_info}
                        content={content}
                        createAt={created_at}
                        type={type}
                        navigate={navigate}
                        user={user_basic_info}
                        notiId={_id}
                        _on={_on}
                        seen={seen}
                        _onRef={_onRef}
                    />
                );
            case NOTIFICATION_TYPES.FLIGHT:
                return (
                    <Flights
                        title={title}
                        ref_basic_info={ref_basic_info}
                        content={content}
                        createAt={created_at}
                        type={type}
                        navigate={navigate}
                        user={user_basic_info}
                        notiId={_id}
                        _on={_on}
                        seen={seen}
                        _onRef={_onRef}
                    />
                );
            case NOTIFICATION_TYPES.BOOKING:
                return (
                    <Bookings
                        title={title}
                        ref_basic_info={ref_basic_info}
                        content={content}
                        createAt={created_at}
                        type={type}
                        navigate={navigate}
                        user={user_basic_info}
                        notiId={_id}
                        _on={_on}
                        seen={seen}
                        _onRef={_onRef}
                    />
                );
            case NOTIFICATION_TYPES.INVOICE:
                return (
                    <Invoices
                        title={title}
                        ref_basic_info={ref_basic_info}
                        content={content}
                        createAt={created_at}
                        type={type}
                        navigate={navigate}
                        user={user_basic_info}
                        notiId={_id}
                        _on={_on}
                        seen={seen}
                        _onRef={_onRef}
                    />
                );
            case NOTIFICATION_TYPES.QUOTE_REQUEST:
                return (
                    <QuoteRequest
                        title={title}
                        ref_basic_info={ref_basic_info}
                        content={content}
                        createAt={created_at}
                        type={type}
                        navigate={navigate}
                        user={user_basic_info}
                        notiId={_id}
                        _on={_on}
                        seen={seen}
                        _onRef={_onRef}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <CustomerContainer>
            <Header
                title={t('NOTIFICATION')}
                description={t('VIEW_ALL_NOTIFICATION')}
            />
            <SearchSection>
                <Input
                    placeholder={t('SEARCH')}
                    allowClear
                    suffix={SearchIcon}
                    onChange={(e) => props.onSearch(e)}
                />
            </SearchSection>

            <ViewAllWrap>
                <ViewAll>{`${t(
                    'ALL_NOTIFICATION'
                ).toUpperCase()} (${total})`}</ViewAll>
                <a onClick={() => onClickMarkAllRead && onClickMarkAllRead()}>
                    <MarlAllRead>{t('MARK_ALL_AS_READ')}</MarlAllRead>
                </a>
            </ViewAllWrap>

            {docs && docs.length > 0 ? (
                docs.map((noti = {}, index) => {
                    const {
                        _id = '',
                        seen = false,
                        _onRef = '',
                        _on = ''
                    } = noti;
                    return (
                        <a
                            key={index}
                            onClick={() =>
                                onClickReadNoti &&
                                onClickReadNoti(_id, seen, _onRef, _on)
                            }
                        >
                            {_renderNotification(noti)}
                        </a>
                    );
                })
            ) : (
                <EmptyComponent />
            )}

            <FooterWrap>
                {next && (
                    <ButtonCustom
                        btnType="loadMore"
                        size="large"
                        title={t('LOAD_MORE').toUpperCase()}
                        onClick={() =>
                            onLoadMore && onLoadMore(next, limit, page, pages)
                        }
                    />
                )}
            </FooterWrap>
        </CustomerContainer>
    );
};

export { template };

const ViewAllWrap = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ViewAll = styled.div`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 20px;
    letter-spacing: 0.8px;
    line-height: 32px;
`;

const FooterWrap = styled.div`
    display: flex;
    justify-content: center;
    margin: 40px;
`;

const MarlAllRead = styled.div`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 16px;
    line-height: 26px;
`;
