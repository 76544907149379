import React from 'react';
import styled from 'styled-components';
import { Upload, Input } from 'antd';
import { searchIcon, chatIcon } from 'assets/images/icons';
import { useTranslation } from 'react-i18next';

/**
 *
 * @param {*} props
 * @param {*} textStyle fieldLabel
 * @returns
 */
const SearchInput = (props) => {
    const { children, placeholder, width, height, value, onSearch } = props;
    const SearchIcon = <img src={searchIcon} />;
    return (
        <SearchSection width={width} height={height}>
            <Input
                placeholder={placeholder}
                allowClear
                value={value}
                suffix={SearchIcon}
                onChange={(e) => {
                    if (onSearch && typeof onSearch === 'function') {
                        onSearch(e.target.value);
                    }
                }}
            />
        </SearchSection>
    );
};

export default SearchInput;

const SearchSection = styled.div`
    .ant-input {
        background-color: transparent;
    }
    .ant-input-affix-wrapper {
        border: 1px solid #dadadb;
        border-radius: 3px;
        width: ${(props) => (props.width ? props.width : '20vw')};
        height: ${(props) => (props.height ? props.height : '40px')};
        background-color: transparent;
    }
`;
