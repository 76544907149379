import React, { useState, useEffect } from 'react';
import { template as tmpl } from './AutoComplete.tmpl';
import { useTranslation } from 'react-i18next';
import { DropdownWrapper, DropdownText } from 'styles/shareStyles';
import { SELECT_TYPE } from 'redux/Constants';
import _ from 'lodash';

const getCategory = (value = {}, type = '') => {
    let category = '';
    switch (type) {
        case SELECT_TYPE.COUNTRY:
            category = `${value.english_name}`;
            break;
        case SELECT_TYPE.AIRCRAFT:
            category = `${value.name}`;
            break;

        case SELECT_TYPE.AIRPORT:
            category = `${value.name}`;
            break;

        case SELECT_TYPE.COMPANY:
            category = `${value.name}`;
            break;

        default:
            category = `${value.name}`;
            break;
    }
    return category;
};

/**
 *
 * @param {*} props
 * @returns
 */
const AutoComplete = (props) => {
    const {
        label = '',
        defaultValue, // optional
        clearAfterSearch = false,
        placeholder = 'Search',
        onSelectItem,
        dropdownStyle,
        inputStyle,
        type, // required,
        onSearchItems, // function search values getting options,
        customOptions = null, // optional
        onClear,
        debounceTime
    } = props;

    const [options, setOptions] = useState([]);
    const [items, setItems] = useState([]);
    const [inputSearch, setInputSearch] = useState('');

    const searchResult = (results = []) =>
        results.map((val, i) => {
            const category = getCategory(val, type);
            return {
                key: i,
                value: category,
                label: customOptions ? (
                    customOptions
                ) : (
                    <DropdownWrapper>
                        {/* current version category typeof string */}
                        <DropdownText>{category}</DropdownText>
                    </DropdownWrapper>
                )
            };
        });

    const onCustomClear = () => {
        setInputSearch('');
        if (onClear && typeof onClear === 'function') {
            onClear();
        } else {
            onSelectItem({});
        }
    };

    const onSearch = async (key = '') => {
        if (!key) return [];

        let result = onSearchItems(key);
        return result || [];
    };

    useEffect(() => {
        setInputSearch(
            !_.isEmpty(defaultValue) ? getCategory(defaultValue) : ''
        );
    }, [defaultValue]);

    const onSelect = (value, options) => {
        const { key } = options;
        let data = items[key];
        onSelectItem(data);

        let inputValue = getCategory(data, type);
        return inputValue;
    };

    const handleSearch = async (value) => {
        const result = await onSearch(value);
        setItems(result);
        setOptions(result ? searchResult(result) : []);
    };

    return (
        tmpl &&
        tmpl({
            label,
            defaultValue,
            placeholder,
            inputStyle,
            onSelect,
            handleSearch,
            options,
            inputSearch,
            onCustomClear,
            debounceTime,
            clearAfterSearch
        })
    );
};

export default AutoComplete;
