import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { Modal, Row, Col } from 'antd';
import { ButtonCustom, Text } from 'components';
import { Select } from 'antd';
import { iconArrowDown, imageIcon } from 'assets/images/icons';
import { numberFormat, getTitleWithFlightType, getLowresUrl } from 'utils';

const { Option } = Select;
const DropDownInput = (props) => {
    const {
        placeholder,
        width,
        data = [],
        onChange,
        pickedData,
        disabled = false
    } = props;

    const { t, i18n } = useTranslation();
    const [selectedValue, setSelectedValue] = useState(null);

    useEffect(() => {
        if (!pickedData) {
            setSelectedValue(null);
        } else {
            const { aircraft = {} } = pickedData || {};
            setSelectedValue(`${aircraft?.name} - (${aircraft?.tail_number})`);
        }
    }, [pickedData]);

    return (
        <SearchSection width={width}>
            <Select
                disabled={disabled}
                suffixIcon={<DropDownIcon src={iconArrowDown} />}
                style={{ height: '50px', border: 'none', width: '450px' }}
                placeholder={
                    <SelectPlaceholder>{placeholder}</SelectPlaceholder>
                }
                onChange={(index) => {
                    let selected = data[index];
                    onChange && onChange(selected);
                }}
                value={selectedValue}
                allowClear={false}
            >
                {data.map((item, index) => {
                    const {
                        leg_id = '',
                        aircraft = {},
                        price_components = {},
                        services = []
                    } = item;
                    const {
                        name,
                        tail_number,
                        operator_name,
                        image = {},
                        images = []
                    } = aircraft;
                    const { total_inc_vat } = price_components;
                    return (
                        <Option value={item.id} key={index}>
                            <RowItem>
                                <Col span={20}>
                                    <div style={{ display: 'flex' }}>
                                        <Image
                                            src={getLowresUrl(image, images)}
                                        />
                                        <div style={{ marginLeft: 10 }}>
                                            <div>
                                                <Package>{name || '_'}</Package>
                                                <TailorCode>
                                                    {tail_number || '_'}
                                                </TailorCode>
                                            </div>
                                            <div>
                                                <Operator color="#8e929a">
                                                    {t('OPERATOR_BY')}
                                                </Operator>
                                                <Operator>
                                                    {operator_name || '_'}
                                                </Operator>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={4}>
                                    <PriceWrap>
                                        <Text textStyle="infoNormal">
                                            {t('TOTAL_PRICE').toUpperCase()}
                                        </Text>
                                        <Price>
                                            {numberFormat(
                                                total_inc_vat,
                                                'USD',
                                                2
                                            )}
                                        </Price>
                                    </PriceWrap>
                                </Col>
                            </RowItem>
                        </Option>
                    );
                })}
            </Select>
        </SearchSection>
    );
};

const ModalCreatePackage = ({
    legs = [],
    index,
    show = false,
    onOk,
    onCancel,
    onSubmit,
    type
}) => {
    const { t, i18n } = useTranslation();

    const [packages, setPackages] = useState([]);

    const createPackage = () => {
        onSubmit && onSubmit(packages);

        // reset
        setPackages([]);
    };

    const onSelect = (data = {}) => {
        try {
            const { leg_id, aircraft, price_components = {}, services } = data;
            let rs = {
                aircraft: aircraft,
                leg_id: leg_id,
                price_components: price_components,
                services: services
            };

            if (packages.some((item) => item.leg_id === leg_id)) {
                let newPackages = packages.map((item, v) => {
                    let value = item;
                    if (item.leg_id === leg_id) {
                        value = rs;
                    }
                    return value;
                });
                setPackages(newPackages);
            } else {
                setPackages([...packages, rs]);
            }
        } catch (e) {}
    };

    const createCategoryOption = (item, i) => {
        const { id, select_aircrafts = [], services = [] } = item;
        let tempArr = [];
        select_aircrafts.forEach((element = {}) => {
            tempArr.push({
                aircraft: element.aircraft,
                price_components: element.price_components,
                leg_id: id,
                services: services
            });
        });
        return tempArr;
    };

    const onPressCancelModal = () => {
        // reset
        setPackages([]);

        onCancel(false);
    };

    return (
        <Modal
            centered
            visible={show}
            onOk={() => onOk()}
            onCancel={() => onPressCancelModal()}
            footer={null}
            style={{ margin: 60 }}
            width={'650px'}
        >
            <ModalContainer>
                <Title>{i18n.t('CREATE_PACKAGE').toUpperCase()}</Title>

                {legs &&
                    legs.map((item, i) => {
                        return (
                            <LegContainer key={i}>
                                <LegTitle>{`${getTitleWithFlightType(
                                    type,
                                    i
                                ).toUpperCase()}`}</LegTitle>
                                <SelectAircraft>
                                    {t('SELECT_AIRCRAFT').toUpperCase()}
                                </SelectAircraft>
                                <DropDownInput
                                    width="450px"
                                    height="40px"
                                    placeholder={`${t(
                                        'SELECT_AIRCRAFT_FOR'
                                    )} ${'flight'}`}
                                    data={createCategoryOption(item, i)}
                                    onChange={onSelect}
                                    disabled={packages.length - i < 0}
                                    pickedData={
                                        packages.length >= i + 1
                                            ? packages[i]
                                            : null
                                    }
                                />
                            </LegContainer>
                        );
                    })}
            </ModalContainer>
            <div style={{ marginTop: 45, marginBottom: 25 }}>
                <ButtonCustom
                    title={t('CREATE_PACKAGE').toUpperCase()}
                    btnType="primary"
                    size="medium"
                    width="250px"
                    onClick={createPackage}
                    disabled={legs.length !== packages.length}
                />
            </div>
        </Modal>
    );
};

export default ModalCreatePackage;

const PriceWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Price = styled.div`
    color: #121216;
    font-family: AvenirNext-Bold;
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 18px;
    min-width: 87px;
    text-align: right;
    margin-top: 8px;
    margin-right: 15px;
`;

const RowItem = styled(Row)``;

const Image = styled.img`
    background-size: 100%;
    border-radius: 2px;
    max-width: 85px;
    height: 55px;
`;

const Package = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
`;

const LegContainer = styled.div`
    margin-top: 25px;
`;

const SelectAircraft = styled.div`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
`;

const LegTitle = styled.div`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
`;

const ModalContainer = styled.div`
    margin-top: 15px;
`;

const Title = styled.div`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 32px;
    text-align: center;
`;

const SearchSection = styled.div`
    .ant-select-selector {
        height: 50px !important;
    }
    .ant-input {
        background-color: transparent;
    }
    .ant-input-affix-wrapper {
        border: 1px solid #dadadb;
        border-radius: 3px;
        width: ${(props) => (props.width ? props.width : '20vw')};
        height: 40px;
        background-color: transparent;
    }
    .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
    }
    .ant-modal-body {
        padding-left: 50px !important;
        padding-right: 50px !important;
    }
`;

const SelectPlaceholder = styled.span`
    color: #8e929a;
    font-family: Lato;
    font-size: 14px;
    line-height: 17px;
`;

const DropDownIcon = styled.img`
    height: 15px;
    width: 15px;
`;

const TailorCode = styled.span`
    color: #19c0ff;
    font-family: AvenirNext-Bold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: center;
    margin-left: 10px;
`;

const Operator = styled.span`
    color: ${(props) => props.color || '#19c0ff'};
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    min-width: 174px;
`;
