import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { Row, Col, Input as AntInput, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { uploadDocument } from 'redux/actions/appActions';
import { useDispatch, useSelector } from 'react-redux';
import { uploadMorePhotosIcon, iconPdfFile } from 'assets/images/icons';
import CustomModal from '../CustomModal';
import { UPLOAD_IMAGE_TYPE } from 'redux/Constants';
const { TextArea } = AntInput;

const UploadDocument = (props) => {
    const {
        documentName = '',
        setDocumentName,
        legalLink = '',
        setLegalLink,
        whitelabelId = '',
        termsAndConditionsLink = '',
        setTermsAndConditionsLink,
        termsAndConditionsName = '',
        setTermsAndConditionsName,
        charterAgreementsLink = '',
        setCharterAgreementsLink,
        charterAgreementsName = '',
        setCharterAgreementsName
    } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const [showError, setShowError] = useState(false);

    const UploadLegalDocument = async (file) => {
        const result = await dispatch(uploadDocument(whitelabelId, file));
        if (result.success) {
            setLegalLink(result.data);
            return true;
        } else {
            setShowError(true);
            return false;
        }
    };

    const UploadTermsAndConditionsDocument = async (file) => {
        const result = await dispatch(
            uploadDocument(
                whitelabelId,
                file,
                UPLOAD_IMAGE_TYPE.TERM_AND_CONDITIONS
            )
        );
        if (result.success) {
            setTermsAndConditionsLink(result.data);
            return true;
        } else {
            setShowError(true);
            return false;
        }
    };

    const UploadCharterAgreementDocument = async (file) => {
        const result = await dispatch(
            uploadDocument(
                whitelabelId,
                file,
                UPLOAD_IMAGE_TYPE.CHARTER_AGREEMENTS
            )
        );
        if (result.success) {
            setCharterAgreementsLink(result.data);
            return true;
        } else {
            setShowError(true);
            return false;
        }
    };

    const handleChangeLegalDoc = async ({ file, fileList }) => {
        const result = await UploadLegalDocument(file);
        const index = fileList.length;
        if (result) {
            setDocumentName(fileList[index - 1].name);
        }
    };

    const handleChangeTermsAndConditionsDoc = async ({ file, fileList }) => {
        const result = await UploadTermsAndConditionsDocument(file);
        const index = fileList.length;
        if (result) {
            setTermsAndConditionsName(fileList[index - 1].name);
        }
    };

    const handleChangeCharterAgreementDoc = async ({ file, fileList }) => {
        const result = await UploadCharterAgreementDocument(file);
        const index = fileList.length;
        if (result) {
            setCharterAgreementsName(fileList[index - 1].name);
        }
    };

    const uploadLegalButton = (
        <UploadSection>
            {documentName ? (
                <>
                    <img
                        style={{ height: 24, width: 18, marginLeft: 20 }}
                        src={iconPdfFile}
                    />
                    <UploadText style={{ marginTop: 8, marginLeft: 20 }}>
                        {documentName}
                    </UploadText>
                </>
            ) : (
                <>
                    <img
                        style={{ height: 16, width: 18, marginLeft: 20 }}
                        src={uploadMorePhotosIcon}
                    />
                    <UploadText style={{ marginTop: 8, marginLeft: 20 }}>
                        {t('UPLOAD_FILE_DRAG_DROP').toUpperCase()}
                    </UploadText>
                </>
            )}
        </UploadSection>
    );

    const uploadTermsAndConditionsButton = (
        <UploadSection>
            {termsAndConditionsName ? (
                <>
                    <img
                        style={{ height: 24, width: 18, marginLeft: 20 }}
                        src={iconPdfFile}
                    />
                    <UploadText style={{ marginTop: 8, marginLeft: 20 }}>
                        {termsAndConditionsName}
                    </UploadText>
                </>
            ) : (
                <>
                    <img
                        style={{ height: 16, width: 18, marginLeft: 20 }}
                        src={uploadMorePhotosIcon}
                    />
                    <UploadText style={{ marginTop: 8, marginLeft: 20 }}>
                        {t('UPLOAD_FILE_DRAG_DROP').toUpperCase()}
                    </UploadText>
                </>
            )}
        </UploadSection>
    );

    const uploadCharterAgreementButton = (
        <UploadSection>
            {charterAgreementsName ? (
                <>
                    <img
                        style={{ height: 24, width: 18, marginLeft: 20 }}
                        src={iconPdfFile}
                    />
                    <UploadText style={{ marginTop: 8, marginLeft: 20 }}>
                        {charterAgreementsName}
                    </UploadText>
                </>
            ) : (
                <>
                    <img
                        style={{ height: 16, width: 18, marginLeft: 20 }}
                        src={uploadMorePhotosIcon}
                    />
                    <UploadText style={{ marginTop: 8, marginLeft: 20 }}>
                        {t('UPLOAD_FILE_DRAG_DROP').toUpperCase()}
                    </UploadText>
                </>
            )}
        </UploadSection>
    );

    return (
        <ContainerWrapper>
            <PayoutSection>
                <WhitelabelContainer>
                    {/* <EachRow>
                        <Input
                            label={t('DOCUMENT_NAME').toUpperCase()}
                            placeholder={t('ENTER_DOCUMENT_NAME')}
                            width="370px"
                            value={documentName}
                            onChange={setDocumentName}
                        />
                    </EachRow> */}
                    <EachRow style={{ flexDirection: 'column' }}>
                        <TitleText>{t('UPLOAD_LEGAL_FILE')}</TitleText>
                        <UploadDoc
                            borderSolid={documentName ? true : false}
                            accept=".pdf"
                            listType="picture-card"
                            showUploadList={false}
                            beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                            onChange={handleChangeLegalDoc}
                            multiple={false}
                        >
                            {uploadLegalButton}
                        </UploadDoc>
                    </EachRow>
                    <EachRow style={{ flexDirection: 'column' }}>
                        <TitleText>
                            {t('UPLOAD_TERMS_AND_CONDITIONS')}
                        </TitleText>
                        <UploadDoc
                            borderSolid={termsAndConditionsName ? true : false}
                            accept=".pdf"
                            listType="picture-card"
                            showUploadList={false}
                            beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                            onChange={handleChangeTermsAndConditionsDoc}
                            multiple={false}
                        >
                            {uploadTermsAndConditionsButton}
                        </UploadDoc>
                    </EachRow>
                    <EachRow style={{ flexDirection: 'column' }}>
                        <TitleText>{t('UPLOAD_CHARTER_AGREEMENTS')}</TitleText>
                        <UploadDoc
                            borderSolid={charterAgreementsName ? true : false}
                            accept=".pdf"
                            listType="picture-card"
                            showUploadList={false}
                            beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                            onChange={handleChangeCharterAgreementDoc}
                            multiple={false}
                        >
                            {uploadCharterAgreementButton}
                        </UploadDoc>
                    </EachRow>
                </WhitelabelContainer>
            </PayoutSection>
            <CustomModal
                visibleModal={showError}
                errorMessage={'Upload document fail. Please try again!'}
                buttonTitle={t('OK').toUpperCase()}
                onOk={() => setShowError(false)}
                cancelModal={() => setShowError(false)}
            />
        </ContainerWrapper>
    );
};

export default UploadDocument;

const UploadText = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.84px;
    line-height: 14px;
`;

const UploadSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    align-items: center;
`;

const TitleText = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 18px;
    text-transform: uppercase;
`;

const Image = styled.img`
    object-fit: cover;
`;

const UploadDoc = styled(Upload)`
    border: ${(props) =>
        props.borderSolid ? '1px solid #DADADB' : '1px dashed #65686f'};
    border-radius: 6px;
    width: 370px;
    height: 64px;
    margin-top: 10px;
`;

const WhitelabelContainer = styled.div`
    padding-left: 51px;
`;

const EachRow = styled(Row)`
    margin-top: 20px;
`;

const ContainerWrapper = styled.div`
    padding-bottom: 40px;
    border-radius: 4px;
    /* margin-top: 14px; */
    .ant-divider {
        border-top: 1px solid rgba(14, 14, 18, 0.08);
    }
    .ant-divider-horizontal {
        margin: 20px 0;
    }
`;

const PayoutSection = styled.div`
    padding-right: 20px;
    background-color: #ffffff;
    border-radius: 4px;
`;
