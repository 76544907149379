import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { template as tmpl } from './Whitelabel.tmpl';
import { getWhitelabelInfomation, updateWhitelabelInfomation } from 'redux/actions/appActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { SHOW_LOADING, HIDE_LOADING } from 'redux/Constants';
import { message } from 'antd';

const WhitelabelLogic = (props) => {
    const { t, i18n } = useTranslation();
    const { activeCompany, location } = props;
    const dispatch = useDispatch();
    const [whitelabelList, setWhitelabelList] = useState([]);
    const [visibleModal, setVisibleModal] = useState(false);
    const [whitelabelSelected, setWhitelabelSelected] = useState({});

    useEffect(() => {
        fetchWhitelabelInfo();
    }, []);

    const fetchWhitelabelInfo = async () => {
        const result = await dispatch(getWhitelabelInfomation());
        if (result.success) {
            setWhitelabelList(result.data);
        } else {
            message.error('Fail at fetching Whitelabel list');
        }
    }

    const fetchChangeWhitelabelStatus = async (whitelabelId, currentStatus) => {
        const result = await dispatch(updateWhitelabelInfomation(whitelabelId, { active: !currentStatus }));
        if (result.success) {
            const whitelabelIdx = whitelabelList.findIndex(((whl) => whl.id === whitelabelId));
            whitelabelList[whitelabelIdx].active = !currentStatus;
        } else {
            message.error('Fail at update Whitelabel status');
        }
        setVisibleModal(false);
    }

    const openModal = (whitelabelId, whitelabelName, whitelabelStatus) => {
        let warningModalTextProps = {
            title: `${t('DISABLE_WHITELABEL_CONFIRMATION')} ${whitelabelName}?`,
            okText: t('YES_DISABLE_IT'),
            cancelText: t('NO_DISREGARD'),
        };
        if (!whitelabelStatus) {
            warningModalTextProps = {
                title: `${t('ENABLE_WHITELABEL_CONFIRMATION')} ${whitelabelName}?`,
                okText: t('YES_ENABLE_IT'),
                cancelText: t('NO_DISREGARD'),
            }
        }

        const warningModalProps = {
            whitelabelId,
            whitelabelStatus,
            ...warningModalTextProps,
        }
        setWhitelabelSelected({...warningModalProps});
        setVisibleModal(true);
    }
    
    const onCancelWarning = () => {
        setVisibleModal(false);
    }

    const initProps = {
        ...props,
        whitelabelList,
        onChangeWhitelabelStatus: fetchChangeWhitelabelStatus,
        showWhitelabelStatusModal: visibleModal,
        openModal,
        onCancelWarning,
        whitelabelSelected,
    };
    return tmpl && tmpl({ ...initProps });
};

class Whitelabel extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <WhitelabelLogic {...initProps} />;
    }
}

export default Whitelabel;
