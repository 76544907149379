import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Row, Col, Switch, Input as AntInput } from 'antd';
import {
    Input,
} from 'components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
const { TextArea } = AntInput;

const ContactUs = (props) => {
    const {
        activeContactUs = false,
        setActiveContactUs,
        contactUsDescription = '',
        setContactUsDescription,
        companyEmail = '',
        setCompanyEmail,
        companyPhone = '',
        setCompanyPhone
    } = props;
    const { t, i18n } = useTranslation();

    return (
        <ContainerWrapper>
            <PayoutSection>
                {/* <HeaderInfo title={t('GENERAL_INFO').toUpperCase()} /> */}
                <WhitelabelContainer>
                    <EachRow align={'middle'}>
                        <TitleText>{t('SHOW_SECTION')}</TitleText>
                        <Switch
                            checked={activeContactUs}
                            style={{ marginLeft: 10 }}
                            onChange={setActiveContactUs}
                        />
                    </EachRow>
                    <EachRow gutter={32} style={{marginTop: 0}}>
                        <GeneralSettingsCol span={12}>
                            <Row>
                                <GeneralSetting>
                                    {t('SHORT_DESCRIPTION')}
                                </GeneralSetting>
                            </Row>
                            <Row style={{ marginTop: 2 }}>
                                <IntroText>{t('CONTACT_US_INTRO')}</IntroText>
                            </Row>
                            <Row style={{ marginTop: 10 }}>
                                <DescriptionArea
                                    placeholder={t('WRITE_DESCRIPTION')}
                                    autoSize={{ minRows: 6, maxRows: 100 }}
                                    onChange={(e) => {
                                        setContactUsDescription(e.target.value);
                                    }}
                                    value={contactUsDescription}
                                />
                            </Row>
                        </GeneralSettingsCol>
                        <GeneralSettingsCol span={12}>
                            <Row>
                                <GeneralSetting>
                                    {t('CONTACT_INFO')}
                                </GeneralSetting>
                            </Row>
                            <EachRow style={{ marginTop: 20 }}>
                                <Input
                                    label={t('EMAIL').toUpperCase()}
                                    placeholder={t('ADD_COMPANY_EMAIL')}
                                    width="100%"
                                    value={companyEmail}
                                    onChange={setCompanyEmail}
                                />
                            </EachRow>
                            <EachRow>
                                <Input
                                    label={t('PHONE_HOTLINE').toUpperCase()}
                                    placeholder={t('ADD_PHONE_HOTLINE')}
                                    width="100%"
                                    value={companyPhone}
                                    onChange={setCompanyPhone}
                                />
                            </EachRow>
                        </GeneralSettingsCol>
                    </EachRow>
                </WhitelabelContainer>
            </PayoutSection>
        </ContainerWrapper>
    );
};

export default ContactUs;

const GeneralSettingRow = styled(Row)`
    width: 370px;
    padding-right: 20px;
    /* height: 46px; */
`;

const GeneralSettingsCol = styled(Col)`
    margin-top: 20px;
`;

const GeneralSetting = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 17px;
`;

const DescriptionArea = styled(TextArea)`
    border: 1px solid rgba(36, 36, 45, 0.15);
    border-radius: 4px;
    margin-top: 10px;
    width: 100%;
    max-width: 1300px !important;
    min-height: 145px !important;
`;

const TitleText = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 18px;
    text-transform: uppercase;
`;

const WhitelabelContainer = styled.div`
    padding-left: 51px;
`;

const EachRow = styled(Row)`
    margin-top: 20px;
`;

const IntroText = styled.span`
    color: #65686f;
    font-family: Lato;
    font-size: 13px;
    line-height: 23px;
    max-width: 1089px;
    white-space: pre-wrap;
`;

const Icon = styled.img`
    width: 16px;
    height: 16px;
    margin-top: 5px;
    margin-right: 15px;
`;

const ConnectStripeIntro = styled.div`
    margin: 25px 25px 0 47px;
    background-color: #f8f9fe;
    border-radius: 4px;
    padding: 15px 20px;
    display: flex;
`;

const ContainerWrapper = styled.div`
    border-radius: 4px;
    /* margin-top: 14px; */
    .ant-divider {
        border-top: 1px solid rgba(14, 14, 18, 0.08);
    }
    .ant-divider-horizontal {
        margin: 20px 0;
    }
`;

const PayoutSection = styled.div`
    /* padding-left: 20px; */
    padding-right: 20px;
    padding-bottom: 40px;
    background-color: #ffffff;
    border-radius: 4px;
`;
