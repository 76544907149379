/// main routes

export const DASHBOARD = '/';
export const LOGIN = '/login';
export const REGISTER = '/register';

export const SETTING_PROFILE = '/setting';
export const EMPLOYEES = '/employees';
export const ADD_NEW_EMPLOYEE = '/employees/add';
export const EMPLOYEE_DETAIL = '/employees/detail';
export const EDIT_SETTING_PROFILE = '/setting/edit';
export const CUSTOMER_LIST = '/customer';
export const CUSTOMER_DETAIL = '/customer/detail';
export const ADD_NEW_CUSTOMER = '/customer/add';
export const EDIT_CUSTOMER = '/customer/edit';
export const SERVICES = '/services';
export const SERVICES_DETAIL = '/services/detail';
export const ADD_NEW_SERVICES = '/services/add';
export const FLEET = '/fleet';
export const BROKER_CONNECTIONS = '/fleet/broker-connections';
export const AIRCRAFT_DETAIL = '/fleet/broker-connections/detail';
export const ADD_ARICRAFT = '/fleet/broker-connections/add';

export const PARTNERSHIP_FLEET = '/fleet/partnership-fleet';
export const PARTNERSHIP_FLEET_AIRCRAFT_DETAIL =
    '/fleet/partnership-fleet/detail';
export const PARTNERSHIP_FLEET_ADD_ARICRAFT = '/fleet/partnership-fleet/add';

export const COMPANY_FLEET = '/fleet/company-fleet';
export const COMPANY_FLEET_AIRCRAFT_DETAIL = '/fleet/company-fleet/detail';
export const COMPANY_FLEET_ADD_ARICRAFT = '/fleet/company-fleet/add';

export const FBO_LIST = '/fbo';
export const FBO_DETAIL = '/fbo/detail';
export const ADD_FBO = '/fbo/add';
export const PARTNERS_LIST = '/partners';
export const PARTNERS_DETAIL = '/partners/detail';
export const ADD_PARTNERS = '/partners/add';

export const BOOKING_LIST = '/booking';
export const BOOKING_DETAIL = '/booking/detail';
export const BOOKING_INVOICE = '/booking/invoice';

export const COMPANY_INVOICES = '/company/invoices';
export const COMPANY_INVOICE_DETAIL = '/company/invoices/detail';

export const COMPANY_PROFILE = '/company/profile';
export const QUOTE_REQUEST_DETAIL = '/quote-request/detail';
export const QUOTE_REQUEST = '/quote-request';
export const CREATE_QUOTATION = '/quote-request/detail/create-quotation';

export const FLIGHT_LIST = '/flight';
export const FLIGHT_DETAIL = '/flight/detail';
export const EMPTY_LEG = '/empty-leg';
export const EMPTY_LEG_DETAIL = '/empty-leg/detail';
export const FLIGHT_PROMOTION_LIST = '/flight/promotions';
export const TOP_RATED_AIRPORT_DETAIL = '/flight/top-rated-airport/detail';
export const ADD_TOP_RATED_AIRPORT = '/flight/top-rated-airport/add';
export const TOP_DESTINATION_DETAIL = '/flight/top-destination/detail';
export const ADD_TOP_DESTINATION = '/flight/top-destination/add';
export const SPECIAL_ROUTE_DETAIL = '/flight/special-route/detail';

export const NOTIFICATION_LIST = '/notification';
export const ACTIVATE_ACCOUNT = '/activate/:uid/:activeId';
export const FORGOT_PASSWORD = '/forgot-password/send';
export const FORGOT_PASSWORD_CONFIRM = '/forgot-password/confirm';
export const FORGOT_PASSWORD_RESET = '/forgot-password/reset';
export const RESET_PASSWORD = '/reset/:uid/:activeId';

export const COMPANY_SETTING = '/company-setting';
export const WHITELABEL_SETTING = '/whitelabel-setting';
export const EDIT_WHITELABEL = '/edit-whitelabel';