import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { Header, Popup } from 'components';
import { CUSTOMER_LIST } from 'routes/main';

import General from '../components/general';
import Identification from '../components/identification';
import Bookings from '../components/bookings';
import { CustomerDetailContainer } from '../styles';

const template = (props) => {
    const { t, i18n } = useTranslation();
    const {
        customer = {},
        navigate,
        bookings,
        getAuthenIdentity,
        uploadIdentification,
        image,
        visibleModal,
        successMsg
    } = props;
    const { user = {}, identity = {} } = customer;

    return (
        <CustomerDetailContainer>
            <Header
                title={i18n.t('CUSTOMERS')}
                description={i18n.t('CUSTOMER_INFORMATION')}
            />
            <General navigate={navigate} user={user} />
            <Identification
                getAuthenIdentity={getAuthenIdentity}
                uploadIdentification={uploadIdentification}
                identity={identity}
                image={image}
            />
            <Bookings navigate={navigate} {...bookings} />

            <Popup
                cancelModal={() => {
                    props.cancelModal();
                }}
                onOk={() => {
                    props.navigate(CUSTOMER_LIST);
                }}
                visibleModal={visibleModal}
                successText={successMsg}
                buttonTitle={t('VIEW_CUSTOMER_LIST').toUpperCase()}
            />
        </CustomerDetailContainer>
    );
};

export { template };
