import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { numberFormat } from 'utils';
import { ButtonCustom, Text } from 'components';
import { PAYMENT_STATUS } from 'redux/Constants';
import { plusIcon, checkedIcon } from 'assets/images/icons';
import MarkAsPaidModal from './MarkAsPaidModal';
import _ from 'lodash';

const RowItemComponent = ({
    title = '',
    subtitle = '',
    price = 0,
    quantity = 1,
    total = 0
}) => {
    return (
        <tr className="invoice-row-item">
            <td className="invoice-item">
                <CellTitle>{title}</CellTitle>
                <SubTitle>{subtitle}</SubTitle>
            </td>
            <td className="invoice-price">
                <Price>{numberFormat(price, 'USD', 2)}</Price>
            </td>
            <td className="invoice-quantity">
                <Quantity>{quantity}</Quantity>
            </td>
            <td className="invoice-total-price">
                <TotalPrice>{numberFormat(total, 'USD', 2)}</TotalPrice>
            </td>
        </tr>
    );
};

const InvoiceDetailsCard = ({
    status = '',
    totals = {},
    invoiceItems = [],
    operatorName = '',
    invoiceCode = '',
    allowEdit = false,
    isPartner = false,
    sentTitle = '',
    showPayout = false,
    flightCode = '',
    tailNumber = '',
    showHeader = true
}) => {
    const { t, i18n } = useTranslation();

    const [showMarkPaid, setShowMarkPaid] = useState(false);

    const onCancelMarkAsPaid = () => {
        setShowMarkPaid(false);
    };

    const onSubmitMarkAsPaid = (type = '', description = '') => {
        // onSubmitMarkAsPaid
        setShowMarkPaid(false);
    };

    const renderTableHeader = () => {
        return (
            <>
                <th style={{ paddingLeft: 22 }}>
                    {t('DESCRIPTION').toUpperCase()}
                </th>
                <th>{t('PRICE').toUpperCase()}</th>
                <th>{t('QUANTITY').toUpperCase()}</th>
                <th>{t('TOTAL').toUpperCase()}</th>
            </>
        );
    };

    const renderTableData = () => {
        if (!_.isEmpty(invoiceItems)) {
            return invoiceItems.map((item = {}, i) => {
                const {
                    id = '',
                    description = '',
                    quantity = 1,
                    amount = 0,
                    total = 0
                } = item;
                return (
                    <RowItemComponent
                        key={i}
                        title={t(id).toUpperCase()}
                        subtitle={description || '_'}
                        price={amount}
                        quantity={quantity}
                        total={total}
                    />
                );
            });
        }
    };

    const _renderTotal = () => {
        const {
            total_ex_vat = 0,
            total_inc_vat = 0,
            total_payout = 0,
            vat = [],
            administrative_fee_total = 0
        } = totals;
        const vatItem = vat && vat.length > 0 ? vat[0] : {};
        const { percentage = 0, total = 0 } = vatItem;

        if (isPartner) {
            return (
                <TotalWrapper>
                    <div style={{ width: '35%' }}>
                        <RowWrapper style={{ marginTop: 10 }}>
                            <LabelLight size="14px">
                                {t('TOTAL_PAYOUT').toUpperCase()}
                            </LabelLight>
                            <ValueItem size="14px">
                                {numberFormat(total_payout, 'USD', 2)}
                            </ValueItem>
                        </RowWrapper>
                    </div>
                </TotalWrapper>
            );
        }
    };

    return (
        <CardContainer>
            {showHeader && (
                <InvoiceCodeWrap>
                    <InvoiceCode style={{ marginTop: '20px' }}>
                        {`${t('INVOICE')} #${invoiceCode}`.toUpperCase()}
                    </InvoiceCode>
                    {allowEdit && (
                        <div>
                            {status === PAYMENT_STATUS.AWAITING_PAYMENT && (
                                <ButtonCustom
                                    btnType="secondary"
                                    onClick={() => setShowMarkPaid(true)}
                                    icon={checkedIcon}
                                    size="small"
                                    width="145px"
                                    title={t('MARK_AS_PAID').toUpperCase()}
                                    wrapStyle={{ marginRight: '20px' }}
                                />
                            )}
                            <ButtonCustom
                                btnType="secondary"
                                onClick={() => {}}
                                icon={plusIcon}
                                size="small"
                                width="162px"
                                title={t('ADD_NEW_INVOICE').toUpperCase()}
                            />
                        </div>
                    )}
                </InvoiceCodeWrap>
            )}

            <OperatorWrap style={{ marginTop: '20px' }}>
                <div style={{ marginLeft: '10px' }}>
                    <div>
                        <InvoiceSentText>
                            {`${sentTitle || 'Invoice'}`}
                        </InvoiceSentText>
                    </div>
                    <div>
                        <InvoiceSentText
                            color={'#121216'}
                            font={'AvenirNext-DemiBold'}
                        >
                            {operatorName || '_'}
                        </InvoiceSentText>
                    </div>
                </div>
                <div style={{ marginLeft: '40px' }}>
                    <div>
                        <InvoiceSentText>{`${t('FLIGHT')} #`}</InvoiceSentText>
                    </div>
                    <div>
                        <InvoiceSentText
                            color={'#121216'}
                            font={'AvenirNext-DemiBold'}
                        >
                            {flightCode || '_'}
                        </InvoiceSentText>
                    </div>
                </div>
                <div style={{ marginLeft: '40px' }}>
                    <div>
                        <InvoiceSentText>
                            {`${t('TAIL_NUMBER')} #`}
                        </InvoiceSentText>
                    </div>
                    <div>
                        <InvoiceSentText
                            color={'#19c0ff'}
                            font={'AvenirNext-DemiBold'}
                        >
                            {tailNumber || '_'}
                        </InvoiceSentText>
                    </div>
                </div>
            </OperatorWrap>

            <table id="invoices" style={{ marginTop: '10px' }}>
                <tbody>
                    <tr>{renderTableHeader()}</tr>
                    {renderTableData()}
                </tbody>
            </table>
            {_renderTotal()}

            {/* Mark As Paid Modal */}
            <MarkAsPaidModal
                show={showMarkPaid}
                onCancel={onCancelMarkAsPaid}
                onSave={onSubmitMarkAsPaid}
            />
        </CardContainer>
    );
};

const CardContainer = styled.div`
    #invoices {
        width: 100%;
        margin-right: 20px;
        background-color: #fff;
    }

    #invoices td,
    #invoices th {
        padding: 8px;
    }

    #invoices tr:hover {
        background-color: #f7f8fb;
    }

    #invoices th {
        padding-top: 12px;
        font-family: AvenirNext-DemiBold;
        font-size: 10px;
        letter-spacing: 0.7px;
        line-height: 18px;
        background-color: #f7f8fb;
        color: #65686f;
        text-align: left;
    }

    .invoice-row-item {
        border-bottom: 1px solid rgba(14, 14, 18, 0.1);
    }
    .invoice-item {
        width: 40%;
    }
    .invoice-price {
        width: 15%;
    }
    .invoice-quantity {
        width: 15%;
    }
    .invoice-total-price {
        width: 15%;
    }
`;

const CellTitle = styled.div`
    color: #121216;
    margin-left: 15px;
    font-family: AvenirNext-Bold;
    font-size: 10px;
    letter-spacing: 0.8px;
    line-height: 12px;
    margin-top: 5px;
    margin-bottom: 10px;
`;

const SubTitle = styled.div`
    color: #65686f;
    font-family: Lato;
    font-size: 14px;
    line-height: 24px;
    margin-left: 15px;
`;

const Price = styled.span`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 14px;
    line-height: 17px;
`;

const Quantity = styled.span`
    color: #65686f;
    font-family: Lato;
    font-size: 14px;
    line-height: 17px;
`;

const TotalPrice = styled.span`
    color: #121216;
    font-family: AvenirNext-Medium;
    font-size: 14px;
    line-height: 17px;
`;

const TotalWrapper = styled.div`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 3px;
    margin-bottom: 20px;
    padding: 20px;
`;

const RowWrapper = styled.div`
    justify-content: space-between;
    width: 65%;
    display: flex;
    align-items: center;
`;

const LabelLight = styled.div`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: ${(props) => props.size || '10px'};
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
`;

const ValueItem = styled.div`
    color: #121216;
    font-family: AvenirNext-Bold;
    font-size: ${(props) => props.size || '12px'};
    letter-spacing: 0.4px;
    line-height: ${(props) => props.lineHeight || '24px'};
    text-align: right;
    margin-left: 25px;
`;

const Divider = styled.div`
    background-color: #0e0e12;
    opacity: 0.1;
    margin-top: 10px;
    width: 100%;
    height: 2px;
`;

const InvoiceCodeWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const InvoiceCode = styled.span`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.7px;
    line-height: 16px;
    text-align: left;
`;

const OperatorWrap = styled.div`
    display: flex;
    background-color: #ffffff;
    border-radius: 2px;
    padding: 10px;
`;

const InvoiceSentText = styled.span`
    color: ${(props) => props.color || '#65686F'};
    font-family: ${(props) => props.font || 'AvenirNext-Medium'};
    font-size: 13px;
    line-height: 16px;
    text-align: left;
`;

const Icon = styled.img`
    width: 24px;
    height: 24px;
    margin-left: 10px;
    margin-right: 10px;
`;

export default InvoiceDetailsCard;
