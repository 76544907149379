import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { amenitiesList } from 'config/Constants';
import 'antd/dist/antd.css';
import { Text, ButtonCustom } from 'components';
import { AircraftDetailCardComponent } from '../Cards';
import {
    iconWifi,
    airplaneSeat,
    bathroomSink,
    iconLavatory,
    sleep,
    iconCancel
} from 'assets/images/icons';

const AircraftDetailComponent = (props) => {
    const { t, i18n } = useTranslation();
    const {
        aircraft = {},
        navigate,
        activeCompany,
        isView,
        detailPage
    } = props;
    const { expandedSidebar } = useSelector(
        (state) => state.settingReducer || false
    );
    const {
        id,
        model = '',
        tail_number = '',
        description = '',
        company = {},
        price_parameters = {},
        classtype,
        year_of_manufacture,
        properties,
        amenities = [],
        images
    } = aircraft;
    // const amenities = ['EXTRA_LEG_ROOM', 'WIFI'];

    const {
        currency = 'USD',
        minimum_booking_time_hours,
        positioning_flight_per_hour_fee,
        revenue_flight_per_hour_fee,
        fees = {}
    } = price_parameters;

    // const {
    //     cruising_speed_in_knots,
    //     max_range_in_nautical_miles,
    //     luggage_capacity_ft3,
    //     max_passengers
    // } = properties;

    const { overnight_stay_fee } = fees;

    const renderAmenities = (item, index) => {
        switch (item) {
            case amenitiesList.EXTRA_LEG_ROOM:
                return (
                    <>
                        <FeatureIcon src={airplaneSeat}></FeatureIcon>
                        <FeatureTitle>
                            <Text textStyle="tableText1">
                                {t('EXTRA_LEG_ROOM')}
                            </Text>
                        </FeatureTitle>
                    </>
                );
            case amenitiesList.WIFI:
                return (
                    <>
                        <FeatureIcon src={iconWifi}></FeatureIcon>
                        <FeatureTitle>
                            <Text textStyle="tableText1">{t('WIFI')}</Text>
                        </FeatureTitle>
                    </>
                );
            case amenitiesList.BED:
                return (
                    <>
                        <FeatureIcon src={sleep}></FeatureIcon>
                        <FeatureTitle>
                            <Text textStyle="tableText1">{t('SLEEPS')}</Text>
                        </FeatureTitle>
                    </>
                );
            case amenitiesList.SINK:
                return (
                    <>
                        <FeatureIcon src={bathroomSink}></FeatureIcon>
                        <FeatureTitle>
                            <Text textStyle="tableText1">
                                {t('SINK_AND_LIGHT')}
                            </Text>
                        </FeatureTitle>
                    </>
                );
            case amenitiesList.LAVATORY:
                return (
                    <>
                        <FeatureIcon src={iconLavatory}></FeatureIcon>
                        <FeatureTitle>
                            <Text textStyle="tableText1">{t('LAVATORY')}</Text>
                        </FeatureTitle>
                    </>
                );
            default:
                return;
        }
    };

    return (
        <EachPlaneSection expandedSidebar={expandedSidebar}>
            <LeftSection>
                <AircraftDetailCardComponent
                    aircraft={aircraft}
                    activeCompany={activeCompany}
                />
            </LeftSection>
            <CreateQuoteSection
                className="create-quote-section"
                expandedSidebar={expandedSidebar}
            >
                <div>
                    {/* <CreateQuoteTop>
                        <CreateQuoteTopLeft>
                            <PlusOutlined style={{ color: '#8E929A' }} />
                            <CreateQuoteTitle>
                                <Text textStyle="nameNormal">
                                    {t('CREATE_QUOTE').toUpperCase()}
                                </Text>
                            </CreateQuoteTitle>
                        </CreateQuoteTopLeft>
                       
                    </CreateQuoteTop> */}
                    <FeaturedAmenities>
                        <FeaturedAmenitiesTitle>
                            <Text textStyle="heading6">
                                {t('FEATURED_AMENITIES')}
                            </Text>
                        </FeaturedAmenitiesTitle>
                        <FeaturedAmenitiesList
                            expandedSidebar={expandedSidebar}
                        >
                            {amenities.map((item, index) => {
                                return (
                                    <EachAmenities key={index}>
                                        {renderAmenities(item, index)}
                                    </EachAmenities>
                                );
                            })}
                        </FeaturedAmenitiesList>
                    </FeaturedAmenities>
                </div>
                <AircraftDetail
                    style={{ paddingLeft: '10px', paddingRight: '10px' }}
                >
                    <ButtonCustom
                        btnType="secondary"
                        title={t('AIRCRAFT_DETAIL').toUpperCase()}
                        width="100%"
                        onClick={() => {
                            navigate(detailPage, id, id);
                        }}
                    />
                </AircraftDetail>
            </CreateQuoteSection>
        </EachPlaneSection>
    );
};

export default AircraftDetailComponent;

const FeaturedAmenitiesList = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    @media (max-width: 1440px) {
        flex-direction: ${(props) =>
            props.expandedSidebar ? 'row' : 'column'};
    }
    @media (max-width: 1024px) {
        flex-direction: row;
    }
`;

const FeatureIcon = styled.img`
    margin-top: 10px;
    margin-right: 10px;
`;

const FeatureTitle = styled.div`
    margin-top: 5px;
`;

const LeftSection = styled.div`
    display: flex;
    flex-direction: row;
    /* padding-right: 30px; */
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */
    /* border: 1px solid #000; */
    max-width: 1520px;
`;

const EachAmenities = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 1024px) {
        margin-right: 20px;
    }
`;

const FeaturedAmenitiesTitle = styled.div`
    margin-bottom: 5px;
`;

const AircraftDetail = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FeaturedAmenities = styled.div`
    padding: 20px;
    @media (max-width: 1024px) {
        padding-top: 0;
    }
`;

const CreateQuoteSection = styled.div`
    width: 15.625vw;
    max-width: 300px;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    border-left: 1px solid rgba(14, 14, 18, 0.1);
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: space-between;
    @media (max-width: 1024px) {
        border-left: none;
        width: 100%;
        max-width: 100%;
        padding-right: 20px;
        padding-top: 0;
    }
    @media (max-width: 768px) {
        border-left: none;
    }
`;

const EachPlaneSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
    padding-left: 20px;
    background-color: #ffffff;
    /* padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px; */
    @media (max-width: 1440px) {
        ${(props) => props.expandedSidebar && 'flex-wrap: wrap'};
    }
    @media (max-width: 1024px) {
        flex-wrap: wrap;
    }
`;
