import React, { useState, useEffect } from 'react';
import { template as tmpl } from '../AutoComplete.tmpl';
import { connect } from 'react-redux';
import { DropdownWrapper, DropdownText, Image } from 'styles/shareStyles';
import { searchServiceItem } from 'redux/actions/appActions';

const allowed_roles = ['PILOT', 'COPILOT', 'HOST'];

const SearchServiceItemsLogic = (props) => {
    const {
        label = '',
        clearAfterSearch = false,
        placeholder = 'Search',
        onSelectItem,
        dropdownStyle,
        inputStyle,
        searchServiceItem
    } = props;

    const onSearchServiceItems = async (key = '') => {
        try {
            if (!key) return [];
            let items = [];

            const input = key.toLowerCase();
            const result = await searchServiceItem(input, true);
            if (result.success) items = result.serviceList || [];

            return items;
        } catch (e) {}
    };

    const [options, setOptions] = useState([]);
    const [items, setItems] = useState([]);
    const [inputSearch, setInputSearch] = useState('');

    const searchResult = (results = []) =>
        results.map((val, i) => {
            const { name = '', image = {}, id } = val;
            const category = `${name}`;
            return {
                key: id,
                value: category,
                label: (
                    <DropdownWrapper justify="flex-start">
                        <Image
                            src={image?.lowres?.url}
                            style={{ width: 45, height: 32, borderRadius: 3 }}
                        />
                        <DropdownText
                            style={{ marginLeft: 10 }}
                        >{`${val.name}`}</DropdownText>
                    </DropdownWrapper>
                )
            };
        });

    const onSelect = (value) => {
        let data = items.find((v) => v.name === value);
        // select service items
        onSelectItem && onSelectItem(data);

        if (clearAfterSearch) {
            setInputSearch('');
        } else {
            setInputSearch(value);
        }
    };

    const handleSearch = async (value) => {
        const result = await onSearchServiceItems(value);
        setInputSearch(value);
        setItems(result);
        setOptions(result ? searchResult(result) : []);
    };

    return (
        tmpl &&
        tmpl({
            label,
            placeholder,
            inputStyle,
            onSelect,
            handleSearch,
            options,
            inputSearch
        })
    );
};

class SearchServiceItems extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <SearchServiceItemsLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    return {
        //
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        searchServiceItem: (input) => dispatch(searchServiceItem(input))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchServiceItems);
