import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { Modal } from 'antd';
import { iconSucceed, iconError } from '../assets/images/icons';
import { useTranslation } from 'react-i18next';
import { Button, PrimaryButton } from '../components';
import { useSelector, useDispatch } from 'react-redux';
import { SETTING_HIDE_MODAL } from '../redux/Constants';

const ModalListener = ({ navigate }) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const { modal = {} } = useSelector((state) => state.settingReducer);
    const {
        show,
        type,
        errors,
        title,
        description,
        buttonTitle,
        onOk,
        onCancel,
        style = {}
    } = modal;

    const [showModal, setShowModal] = useState(false);
    const [typeModal, setTypeModal] = useState('');
    const [titleModal, setTitleModal] = useState('');
    const [descriptionModal, setDesModal] = useState('');
    const [buttonTitleModal, setButtonTitleModal] = useState('');
    const [errorsModal, setErrorsModal] = useState(null);
    const [onOkModal, setOkModal] = useState(null); // route navigator
    const [onCancelModal, setCancelModal] = useState(null); // route navigator

    useEffect(() => {
        setShowModal(show);
        setTypeModal(type);
        setTitleModal(title);
        setDesModal(description);
        setButtonTitleModal(buttonTitle);
        setErrorsModal(errors);
        setCancelModal(onCancel);

        // action
        setOkModal(onOk);
    }, [show, type, errors, title, description, buttonTitle, onOk, onCancel]);

    const onClickBtn = () => {
        if (onOkModal && typeof navigate === 'function') {
            navigate(onOkModal);
        }

        // close modal
        dispatch({ type: SETTING_HIDE_MODAL });
    };

    const onPressCancelModal = () => {
        if (onCancelModal && typeof navigate === 'function') {
            navigate(onOkModal);
        }

        dispatch({ type: SETTING_HIDE_MODAL });
    };

    return (
        <Modal
            className="ant-modal-custom"
            visible={showModal}
            centered
            footer={null}
            onCancel={onPressCancelModal}
        >
            {!errorsModal ? (
                <TextSection>
                    <SuccessIcon
                        src={typeModal === 'error' ? iconError : iconSucceed}
                    />
                    <SuccessText>{titleModal}</SuccessText>
                    {descriptionModal && (
                        <SecondSuccessLine>
                            {descriptionModal}
                        </SecondSuccessLine>
                    )}
                </TextSection>
            ) : (
                <SuccessText>{errorsModal.toUpperCase()}</SuccessText>
            )}

            <PrimaryButton
                title={buttonTitleModal}
                backgroundColor={'#19C0FF'}
                textColor={'#FFFFFF'}
                fontSize={'13px'}
                onClick={() => onClickBtn()}
                width={style?.width}
            />
        </Modal>
    );
};

const TextSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
`;

const SuccessIcon = styled.img`
    width: 64px;
    height: 64px;
    margin-bottom: 25px;
`;

const SuccessText = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 32px;
`;

const SecondSuccessLine = styled.span`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 15px;
    line-height: 26px;
`;

export default ModalListener;
