import React, { useState } from 'react';
import { Drawer, Button, Row, Col } from 'antd';
import styled from 'styled-components';

import moment from 'moment';
import { jetIcon, checkMarkIcon, infoIcon } from 'assets/images/icons';
import { ContainerDrawer, WrapperDrawer } from '../../styles';

const getIconWithStatus = (status) => {
    let icon = infoIcon;
    if (status === 'EXECUTED') {
        icon = checkMarkIcon;
    }
    return icon;
}

const Flights = (props) => {
    const {
        title = '',
        createAt = '',
        content = '',
        _onRef = '',
        _on = {},
        ref_basic_info = {},
        seen = false
    } = props;
    const {
        status = '',
        code = '',
        aircraft = {},
        from_airport = {},
        to_airport = {},
        departure = {},
        arrival = {}
    } = ref_basic_info;

    return (
        <ContainerDrawer>
            <WrapperDrawer>
                { title && 
                    <div style={{ display: 'flex' }}>
                        <Icon src={getIconWithStatus(status)} />
                        <TitleHeader seen={!seen}>
                            {title.toUpperCase()}
                        </TitleHeader>
                    </div>
                }
                
                <TextItem seen={!seen}>{content || ''}</TextItem>

                <FlightWrap>
                    <Row justify="space-between" style={{ padding: '10px' }}>
                        <Col span={12}>
                            <TextTitle>#{code}</TextTitle>
                        </Col>
                        <Col span={12}>
                            <TailorCode>
                                {aircraft.tail_number || ''}
                            </TailorCode>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            height: '1px'
                        }}
                    >
                        <Divider
                            style={{
                                backgroundColor: '#0e0e12',
                                opacity: 0.2
                            }}
                        />
                    </Row>
                    <Row justify="space-between" style={{ padding: '10px' }}>
                        <Col span={10} style={{ alignSelf: 'center' }}>
                            <TextTitle>{from_airport?.icao_code}</TextTitle>
                        </Col>
                        <Col span={2} style={{ alignSelf: 'center' }}>
                            <img
                                src={jetIcon}
                                style={{ width: '16px', height: '16px' }}
                            />
                        </Col>
                        <Col span={10} style={{ alignSelf: 'center' }}>
                            <TextTitle
                                style={{
                                    textAlign: 'right'
                                }}
                            >
                                {to_airport?.icao_code}
                            </TextTitle>
                        </Col>
                    </Row>
                </FlightWrap>

                <TextItem seen={!seen} style={{ marginTop: '10px' }}>
                    {createAt
                        ? moment(createAt).fromNow()
                        : ''}
                </TextItem>
            </WrapperDrawer>
        </ContainerDrawer>
    );
};

export default Flights;

const Icon = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 10px;
`;

const TextItem = styled.div`
    color: ${(props) => props.seen ? '#0E0E12' : '#65686f'};
    font-family: ${(props) => props.seen ? 'AvenirNext-DemiBold' : 'Lato'};
    font-size: 11px;
    line-height: 16px;
    text-align: left;
    margin-top: 3px;
`;

const TextTitle = styled.div`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    letter-spacing: 0.46px;
    line-height: 13px;
    text-align: left;
`;

const FlightWrap = styled.div`
    border: 1px solid rgba(14, 14, 18, 0.08);
    border-radius: 2px;
    margin-top: 10px;
`;

const Divider = styled.div`
    width: 100%;
    background-color: #0e0e12 - 8%;
`;

const TailorCode = styled.div`
    color: #19c0ff;
    font-family: AvenirNext-Bold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: right;
`;

const TitleHeader = styled.span`
    color: ${(props) => props.seen ? '#0E0E12' : '#65686f'};
    font-family: ${(props) => props.seen ? 'AvenirNext-Bold' : 'AvenirNext-DemiBold'};
    font-size: 10px;
    letter-spacing: 0.7px;
`;