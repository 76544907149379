import React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { ButtonCustom, Text, localeEmptyTable } from 'components';
import { ButtonType } from 'components/Antd/Button';
import { DetailSection, BookingCode, TimeText, TypeText } from '../styles';
import { ColumnHeader } from 'styles/shareStyles';
import { formatTimeBooking, getPaymentStatus } from 'utils';
import {
    BookingStatus,
    JetInfo,
    FlightInfo,
    Booker,
    PaymentStatus,
    Company
} from 'components/Commons';
import { BOOKING_DETAIL } from 'routes/main';

const NewBooking = (props) => {
    const {
        navigate,
        list = {},
        key,
        setKey,
        loading = false,
        onChangePageNewBooking,
        isPartner = false
    } = props;
    const { docs = [], limit, page, pages, total = 1 } = list;
    const { t, i18n } = useTranslation();

    const columns = [
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('BOOKING') + ' #'}</ColumnHeader>;
            },
            width: 80,
            dataIndex: 'booking_code',
            key: 'booking_code',
            fixed: 'left',
            render: function eachItemBooking(booking_code) {
                return <BookingCode>{booking_code}</BookingCode>;
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('UPDATED_AT')}</ColumnHeader>;
            },
            width: 120,
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: function eachItemCreatedAt(updated_at) {
                return (
                    <TimeText>
                        {updated_at ? formatTimeBooking(updated_at) : '_'}
                    </TimeText>
                );
            }
        },
        !isPartner
            ? {
                  title: function titleHeader() {
                      return (
                          <ColumnHeader>{t('ORIGINAL_BOOKER')}</ColumnHeader>
                      );
                  },
                  width: 150,
                  dataIndex: 'user_basic_info',
                  key: 'user_basic_info',
                  render: function eachType(user) {
                      return <Booker user={user} />;
                  }
              }
            : {
                  title: function titleHeader() {
                      return <ColumnHeader>{t('PARTNER')}</ColumnHeader>;
                  },
                  width: 150,
                  dataIndex: '_company',
                  key: '_company',
                  render: function eachType(_company) {
                      return <Company _company={_company} />;
                  }
              },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('TYPE')}</ColumnHeader>;
            },
            width: 70,
            dataIndex: 'type',
            key: 'type',
            render: function eachType(type) {
                return <TypeText>{t(type).toUpperCase()}</TypeText>;
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('FLIGHT_INFO')}</ColumnHeader>;
            },
            dataIndex: 'legs',
            key: 'legs',
            width: 180,
            render: function eachItemType(legs) {
                if (Array.isArray(legs)) {
                    const newLegs = legs.map((leg) => {
                        return {
                            from_airport: leg.from_airport,
                            to_airport: leg.to_airport
                        };
                    });

                    return (
                        <>
                            {newLegs.map((leg, index) => {
                                return (
                                    <div key={index}>
                                        <FlightInfo leg={leg} />
                                    </div>
                                );
                            })}
                        </>
                    );
                }
                return;
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('JET_INFO')}</ColumnHeader>;
            },
            dataIndex: 'legs',
            key: 'legs',
            width: 80,
            render: function eachItemJetInfo(legs) {
                if (Array.isArray(legs)) {
                    const newLegs = legs.map((leg) => {
                        return {
                            aircraft: leg.aircraft
                        };
                    });

                    return (
                        <>
                            {newLegs.map((leg, index) => {
                                return (
                                    <div key={index}>
                                        <JetInfo leg={leg} />
                                    </div>
                                );
                            })}
                        </>
                    );
                }
                return;
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('PAYMENT_STATUS')}</ColumnHeader>;
            },
            dataIndex: 'payment_status',
            key: 'payment_status',
            width: 150,
            render: function eachItemStatus(payment_status) {
                return (
                    <PaymentStatus status={getPaymentStatus(payment_status)} />
                );
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('BOOKING_STATUS')}</ColumnHeader>;
            },
            dataIndex: 'status',
            key: 'status',
            width: 120,
            render: function eachItemStatus(status) {
                return <BookingStatus status={status} type="BOOKING" />;
            }
        },
        {
            key: 'action',
            width: 120,
            render: function setAction(val) {
                return (
                    <DetailSection>
                        <ButtonCustom
                            btnType={ButtonType.PRIMARY}
                            title={t('VIEW_DETAIL').toUpperCase()}
                            size="small"
                            borderColor="1px solid rgba(18, 18, 22, 0.2)"
                            width="115px"
                            onClick={(e) => navigate(BOOKING_DETAIL, val._id)}
                        />
                    </DetailSection>
                );
            }
        }
    ];

    return (
        <>
            <Text textStyle="heading5">{`${t(
                'NEW_BOOKING'
            ).toUpperCase()} (${total})`}</Text>

            <Table
                columns={columns}
                dataSource={docs}
                style={{ marginTop: 20 }}
                scroll={{ x: 1200 }}
                loading={loading}
                pagination={{
                    defaultPageSize: 5,
                    total: pages * 5 - 1,
                    hideOnSinglePage: false,
                    showSizeChanger: false
                }}
                locale={localeEmptyTable()}
                onChange={(e) => onChangePageNewBooking(e)}
            />
        </>
    );
};

export default NewBooking;
