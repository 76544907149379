import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import {
    Row,
    Col,
    Switch,
    Input as AntInput,
    Image,
    Upload,
    message
} from 'antd';
import { Input } from 'components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getBase64 } from 'utils';
import { UPLOAD_MODEL, UPLOAD_IMAGE_TYPE } from 'redux/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { IMAGE_FILE_EXTENSIONS } from 'config/Constants';
import { uploadMorePhotosIcon } from 'assets/images/icons';
import { uploadImage } from 'redux/actions/appActions';
import CustomModal from './CustomModal';
import UploadWLImage from './upload/UploadWLImage';

const { TextArea } = AntInput;

const Advertisement = (props) => {
    const {
        whitelabelId,
        setNewAdvertisementBackground,
        advertisementImage = '',
        setAdvertisementImage,
        activeAdvertisement = false,
        setActiveAdvertisement,
        advertisementBackground = '',
        setAdvertisementBackground,
        advertisementDescription = '',
        setAdvertisementDescription,
        advertisementTitle = '',
        setAdvertisementTitle
    } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    // const [photo, setPhoto] = useState(null);
    const [imageUrl, setImageUrl] = useState(advertisementImage || '');
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        setImageUrl(advertisementImage);
    }, [advertisementImage]);

    const uploadAdvertisementImage = async (file) => {
        const result = await dispatch(
            uploadImage(
                whitelabelId,
                file,
                UPLOAD_MODEL.WHITELABEL,
                UPLOAD_IMAGE_TYPE.GALLERY
            )
        );
        if (result.success && result.image) {
            setNewAdvertisementBackground(result.image.url || '');
            return true;
        } else {
            setShowError(true);
            return false;
        }
    };

    const handleChange = async ({ file, fileList }) => {
        // setPhoto(file);
        const result = await uploadAdvertisementImage(file);
        if (result) {
            getBase64(file, (imageUrl) => {
                setImageUrl(imageUrl);
            });
        }
    };

    const uploadButton = (
        <UploadSection>
            <img style={{ height: 16, width: 18 }} src={uploadMorePhotosIcon} />
            <UploadText style={{ marginTop: 8 }}>
                {t('UPLOAD_PHOTO_OR_DRAG').toUpperCase()}
            </UploadText>
        </UploadSection>
    );

    return (
        <ContainerWrapper>
            <PayoutSection>
                <WhitelabelContainer>
                    <EachRow align={'middle'}>
                        <TitleText>{t('SHOW_SECTION')}</TitleText>
                        <Switch
                            checked={activeAdvertisement}
                            style={{ marginLeft: 10 }}
                            onChange={setActiveAdvertisement}
                        />
                    </EachRow>
                    {activeAdvertisement && (
                        <EachRow>
                            <GeneralSettingsCol style={{ marginRight: 160 }}>
                                <EachRow>
                                    <GeneralSetting>
                                        {t('BACKGROUND_IMAGE')}
                                    </GeneralSetting>
                                </EachRow>
                                <EachRow style={{ marginTop: 5 }}>
                                    <SectionDescription>
                                        {t('BACKGROUND_IMAGE_DESCRIPTION')}
                                    </SectionDescription>
                                </EachRow>
                                <EachRow>
                                    {imageUrl ? (
                                        <ChangePhotoContainer>
                                            <ImageContainer src={imageUrl} />
                                            <div style={{ marginTop: 20 }}>
                                                <UploadWLImage
                                                    accept={
                                                        IMAGE_FILE_EXTENSIONS
                                                    }
                                                    beforeUpload={() => false}
                                                    onChange={handleChange}
                                                    multiple={false}
                                                    showUploadList={false}
                                                    isCropImage
                                                    aspectRatio={16/9}
                                                >
                                                    <ChangePhotoButton>
                                                        <ChangePhotoText>
                                                            {t(
                                                                'CHANGE_PHOTO'
                                                            ).toUpperCase()}
                                                        </ChangePhotoText>
                                                    </ChangePhotoButton>
                                                </UploadWLImage>
                                            </div>
                                        </ChangePhotoContainer>
                                    ) : (
                                        <UploadPhoto
                                            accept={IMAGE_FILE_EXTENSIONS}
                                            listType="picture-card"
                                            showUploadList={false}
                                            beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                                            onChange={handleChange}
                                            multiple={false}
                                            isCropImage
                                            aspectRatio={16/9}
                                        >
                                            {uploadButton}
                                        </UploadPhoto>
                                    )}
                                </EachRow>
                            </GeneralSettingsCol>
                            <GeneralSettingsCol style={{ width: 670 }}>
                                <EachRow>
                                    <GeneralSetting>
                                        {t('CUSTOM_TEXT')}
                                    </GeneralSetting>
                                </EachRow>
                                <EachRow>
                                    <Input
                                        label={t('MAIN_TITLE').toUpperCase()}
                                        placeholder={t('ENTER_TITLE')}
                                        width="100%"
                                        value={advertisementTitle}
                                        onChange={setAdvertisementTitle}
                                    />
                                </EachRow>
                                <EachRow style={{ flexDirection: 'column' }}>
                                    <TitleText>
                                        {t('SHORT_DESCRIPTION')}
                                    </TitleText>
                                    <DescriptionArea
                                        placeholder={t('WRITE_DESCRIPTION')}
                                        autoSize={{ minRows: 5, maxRows: 100 }}
                                        value={advertisementDescription}
                                        onChange={(e) =>
                                            setAdvertisementDescription(
                                                e.target.value
                                            )
                                        }
                                    />
                                </EachRow>
                            </GeneralSettingsCol>
                        </EachRow>
                    )}
                </WhitelabelContainer>
            </PayoutSection>
            <CustomModal
                visibleModal={showError}
                errorMessage={'Upload image fail. Please try again!'}
                buttonTitle={t('OK').toUpperCase()}
                onOk={() => setShowError(false)}
                cancelModal={() => setShowError(false)}
            />
        </ContainerWrapper>
    );
};

export default Advertisement;

const ChangePhotoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const ChangePhotoButton = styled.button`
    background: transparent;
    border: 1px solid rgba(18, 18, 22, 0.2);
    border-radius: 2px;
`;

const ChangePhotoText = styled.span`
    color: #16161b;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
`;

const UploadPhoto = styled(UploadWLImage)`
    border: 1px dashed #65686f;
    border-radius: 4px;
    width: 470px;
    height: 183px;
    margin-top: 10px;
`;

const UploadText = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.84px;
    line-height: 14px;
`;

const UploadSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
`;

const ImageContainer = styled(Image)`
    object-fit: cover;
    width: 470px;
`;

const GeneralSettingsCol = styled(Col)``;

const GeneralSetting = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 17px;
`;

const DescriptionArea = styled(TextArea)`
    border: 1px solid rgba(36, 36, 45, 0.15);
    border-radius: 4px;
    margin-top: 10px;
    width: 770px;
`;

const TitleText = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 18px;
    text-transform: uppercase;
`;

const WhitelabelContainer = styled.div`
    padding-left: 51px;
`;

const EachRow = styled(Row)`
    margin-top: 20px;
`;

const ConnectStripeText = styled.span`
    color: #ffffff;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    letter-spacing: 0.7px;
    line-height: 13px;
    text-align: center;
`;

const SectionDescription = styled.span`
    color: #65686f;
    font-family: Lato;
    font-size: 14px;
    line-height: 24px;
`;

const Icon = styled.img`
    width: 16px;
    height: 16px;
    margin-top: 5px;
    margin-right: 15px;
`;

const ConnectStripeIntro = styled.div`
    margin: 25px 25px 0 47px;
    background-color: #f8f9fe;
    border-radius: 4px;
    padding: 15px 20px;
    display: flex;
`;

const ContainerWrapper = styled.div`
    /* padding-bottom: 40px; */
    border-radius: 4px;
    /* margin-top: 14px; */
    .ant-divider {
        border-top: 1px solid rgba(14, 14, 18, 0.08);
    }
    .ant-divider-horizontal {
        margin: 20px 0;
    }
    input[type='color' i] {
        padding: 0;
        border: none;
        background: none;
        border-radius: 6px;
    }
`;

const PayoutSection = styled.div`
    padding-right: 20px;
    padding-bottom: 40px;
    background-color: #ffffff;
    border-radius: 4px;
`;
