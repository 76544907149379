import React from 'react';
import styled from 'styled-components';
import Button from './Button';
import Colors from 'styles/Colors';
import { bookmarkIcon } from 'assets/images/icons';
import { Icon } from 'styles/shareStyles';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import { IconBack, syncIcon } from 'assets/images/icons';

export const ButtonType = {
    EDIT_BTN: 'editBtn',
    FOLLOW_BTN: 'followBtn',
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    GO_BACK: 'goBack',
    LOAD_MORE: 'loadMore'
};

/**
 *
 * @param {*} props
 * @returns
 */
const ButtonCustom = ({
    btnType,
    wrapStyle = {},
    borderStyle,
    title = '',
    onClick,
    disabled = false,
    width,
    height,
    fontSize,
    icon,
    size // size button [ small , medium , large ]
}) => {
    const renderBtn = () => {
        switch (btnType) {
            case ButtonType.EDIT_BTN:
                return (
                    <EditButton
                        width={width}
                        height={height}
                        onClick={() => onClick()}
                    >
                        <EditText>{title}</EditText>
                    </EditButton>
                );
            case ButtonType.FOLLOW_BTN:
                return (
                    <EditButton width="116px" onClick={() => onClick()}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Icon src={bookmarkIcon} />
                            <EditText style={{ marginLeft: '8px' }}>
                                {title}
                            </EditText>
                        </div>
                    </EditButton>
                );
            case ButtonType.PRIMARY:
                return (
                    <PrimaryButton
                        width={width}
                        onClick={onClick}
                        size={size}
                        title={title}
                        disabled={disabled}
                        wrapStyle={wrapStyle}
                    />
                );
            case ButtonType.SECONDARY:
                return (
                    <SecondaryButton
                        width={width}
                        onClick={onClick}
                        size={size}
                        title={title}
                        disabled={disabled}
                        icon={icon}
                        wrapStyle={wrapStyle}
                        borderStyle={borderStyle}
                    />
                );
            case ButtonType.GO_BACK:
                return (
                    <SecondaryButton
                        onClick={onClick}
                        size="small"
                        title={title}
                        icon={IconBack}
                        borderStyle="none"
                    />
                );
            case ButtonType.LOAD_MORE:
                return (
                    <SecondaryButton
                        onClick={onClick}
                        size="small"
                        title={title}
                        icon={syncIcon}
                        borderStyle="none"
                        background="transparent"
                    />
                );
            default:
                return <Button title={title} onClick={(e) => onClick()} />;
        }
    };

    return renderBtn();
};

export default ButtonCustom;

const SubmitText = styled.span`
    color: #ffffff;
    font-family: AvenirNext-DemiBold;
    font-size: 13px;
    letter-spacing: 1px;
    line-height: 16px;
    text-align: center;
`;

const EditButton = styled.button`
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 2px;
    width: ${(props) => [props.width ? props.width : '180px']};
    height: 32px;
    cursor: pointer;
`;

const EditText = styled.div`
    color: #ffffff;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: center;
`;
