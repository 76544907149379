import React from 'react';
import 'antd/dist/antd.css';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { searchIcon } from 'assets/images/icons';
import { numberFormat } from 'utils';

const TotalPayout = ({ price_components = {} }) => {
    const { t, i18n } = useTranslation();
    const {
        total_ex_vat = 0,
        total_vat = 0,
        total_inc_vat = 0,
        total_inc_charge = 0,
        tax_percentage = 0,
        total_charge = 0,
        subtotal = 0,
        subtotal_ex_vat = 0,
        administrative_fee_percentage = 0,
        administrative_fee_total = 0,
        total_payout = 0
    } = price_components;

    const totalPayout = !total_payout
        ? total_inc_vat + administrative_fee_total
        : total_payout;

    return (
        <Container>
            <Wrapper>
                <RowWrapper>
                    <LabelLight>{t('TOTAL_EX_VAT').toUpperCase()}</LabelLight>
                    <ValueItem>
                        {numberFormat(total_ex_vat, 'USD', 2)}
                    </ValueItem>
                </RowWrapper>
                <RowWrapper style={{ marginTop: 10 }}>
                    <LabelLight>{`${t(
                        'VAT'
                    ).toUpperCase()} (${tax_percentage}%)`}</LabelLight>
                    <ValueItem>{numberFormat(total_vat, 'USD', 2)}</ValueItem>
                </RowWrapper>
                <RowWrapper style={{ marginTop: 10 }}>
                    <LabelLight>{t('TOTAL_ICN_VAT').toUpperCase()}</LabelLight>
                    <ValueItem>
                        {numberFormat(total_inc_vat, 'USD', 2)}
                    </ValueItem>
                </RowWrapper>
                <RowWrapper style={{ marginTop: 10 }}>
                    <LabelLight>{`${t(
                        'ADMINISTRATOR_FEE'
                    ).toUpperCase()} (${administrative_fee_percentage}%)`}</LabelLight>
                    <ValueItem>
                        {numberFormat(administrative_fee_total, 'USD', 2)}
                    </ValueItem>
                </RowWrapper>
                <Divider />
                <RowWrapper style={{ marginTop: 10 }}>
                    <LabelLight size="18px">
                        {t('TOTAL_PAYOUT').toUpperCase()}
                    </LabelLight>
                    <ValueItem size="18px">
                        {numberFormat(totalPayout, 'USD', 2)}
                    </ValueItem>
                </RowWrapper>
            </Wrapper>
        </Container>
    );
};

const Container = styled.div`
    background-color: #fff;
    border-radius: 4px;
    margin-top: 15px;
    padding: 25px;
    margin-bottom: 30px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

const RowWrapper = styled.div`
    justify-content: space-between;
    width: 25%;
    min-width: 300px;
    max-width: 360px;
    display: flex;
    align-items: center;
`;

const LabelLight = styled.div`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: ${(props) => props.size || '11px'};
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
`;

const ValueItem = styled.div`
    color: #121216;
    font-family: AvenirNext-Bold;
    font-size: ${(props) => props.size || '14px'};
    letter-spacing: 0.4px;
    line-height: 24px;
    text-align: right;
    margin-left: 25px;
`;

const Divider = styled.div`
    background-color: #0e0e12;
    opacity: 0.1;
    margin-top: 10px;
    width: 100%;
    height: 2px;
`;

export default TotalPayout;
