import React, { useState } from 'react';
import { Drawer, Skeleton, Popover, Avatar, Row, Col, List, Divider } from 'antd';
import {
    Bookings,
    Flights,
    Reminder,
    Invoices,
    QuoteRequest
} from './types';
import { Text } from 'components';
import styled from 'styled-components';
import { moreIcon } from 'assets/images/icons';
import { NOTIFICATION_LIST } from 'routes/main';
import { useTranslation } from 'react-i18next';
import { NOTIFICATION_TYPES } from 'config/Constants';
import { ArrowRightOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';

const template = (props) => {
    const {
        visible,
        onClose,
        onClickAllRead,
        onClickReadNoti,
        onLoadMore,
        notifications = {},
        navigate
    } = props;
    const { docs = [], total = 0, next = '' } = notifications;
    const { t, i18n } = useTranslation();

    const [visibleOver, setVisibleOVer] = useState(false);

    const loadMoreData = () => {
        const { next = '', limit = 10, page = 1, pages = 1 } = notifications;
        onLoadMore(next, limit, page, pages);
    }

    const handleOnClickReadAll = () => {
        onClickAllRead && onClickAllRead();
        setVisibleOVer(false);
    }

    const content = (
        <div
            style={{
                justifyContent: 'center',
                width: '150px'
            }}
        >
            <a onClick={() => handleOnClickReadAll()}>
                <Text textStyle="tableText2" style={{ fontSize: '12px' }}>
                    {t('MARK_ALL_AS_READ')}
                </Text>
            </a>
        </div>
    );

    const _renderNotificationWithType = (noti = {}) => {
        const {
            ref_basic_info,
            content = '',
            created_at = '',
            user_basic_info = {},
            _id = '', // notificationId
            _on = '', // id of booking/flight/invoice/request...
            _onRef = '',
            seen = false,
            meta = {}
        } = noti;
        const { status = '' } = ref_basic_info;
        const { key } = meta;
        let title = key ? t(key) : '';

        switch (_onRef) {
            case NOTIFICATION_TYPES.COMPANY:
                return (
                    <Reminder
                        title={title}
                        content={content}
                        createAt={created_at}
                        ref_basic_info={ref_basic_info}
                        user={user_basic_info}
                        notiId={_id}
                        _on={_on}
                        seen={seen}
                        _onRef={_onRef}
                    />
                );
            case NOTIFICATION_TYPES.FLIGHT:
                return (
                    <Flights
                        title={title}
                        content={content}
                        createAt={created_at}
                        ref_basic_info={ref_basic_info}
                        user={user_basic_info}
                        notiId={_id}
                        _on={_on}
                        seen={seen}
                        _onRef={_onRef}
                    />
                );
            case NOTIFICATION_TYPES.BOOKING:
                return (
                    <Bookings
                        title={title}
                        content={content}
                        createAt={created_at}
                        ref_basic_info={ref_basic_info}
                        user={user_basic_info}
                        notiId={_id}
                        _on={_on}
                        seen={seen}
                        _onRef={_onRef}
                    />
                );
            case NOTIFICATION_TYPES.INVOICE:
                return (
                    <Invoices
                        title={title}
                        content={content}
                        createAt={created_at}
                        ref_basic_info={ref_basic_info}
                        user={user_basic_info}
                        notiId={_id}
                        _on={_on}
                        seen={seen}
                        _onRef={_onRef}
                    />
                );
            case NOTIFICATION_TYPES.QUOTE_REQUEST:
                return (
                    <QuoteRequest
                        title={title}
                        content={content}
                        createAt={created_at}
                        ref_basic_info={ref_basic_info}
                        user={user_basic_info}
                        notiId={_id}
                        _on={_on}
                        seen={seen}
                        _onRef={_onRef}
                    />
                );
            default:
                return null;
        }
    };

    const _renderFooter = () => {
        return (
                <Footer onClick={() => navigate(NOTIFICATION_LIST)}>
                    <ViewAllText>{t('VIEW_ALL_NOTIFICATIONS')}</ViewAllText>
                    <ArrowRightOutlined style={{ marginLeft: '10px' }} />
                </Footer>
        );
    }

    const _renderItem = (noti = {}) => {
        const {
            _id = '',
            seen = false,
            _onRef = '',
            _on = ''
        } = noti;
        return (
            <ContentItem
                onClick={() =>
                    onClickReadNoti &&
                    onClickReadNoti(_id, seen, _onRef, _on)
                }
            >
                {_renderNotificationWithType(noti)}
            </ContentItem>
        );
    }

    const handleVisibleChange = (visible) => {
        setVisibleOVer(visible);
    }

    return (
        <Drawer
            placement="right"
            width={270}
            onClose={onClose}
            visible={visible}
            closable={false}
            footer={_renderFooter()}
            footerStyle={{ 
                height: '100px', 
                marginBottom: '30px', 
                backgroundColor: '#F8F9FE', 
                boxShadow: '0 -2px 4px 0 rgba(0, 0, 0, 0.1)' 
            }}
        >
            <Row justify="space-between">
                <Col>
                    <NotiText>{t('NOTIFICATION')}</NotiText>
                </Col>
                <Col>
                    <Popover
                        placement="rightBottom"
                        content={content}
                        trigger="click"
                        visible={visibleOver}
                        style={{ borderRadius: 4 }}
                        onVisibleChange={handleVisibleChange}
                    >
                        <Icon
                            src={moreIcon}
                            style={{ width: '32px', height: '25px' }}
                        />
                    </Popover>
                </Col>
            </Row>

            <WrapperContent id="scrollableDiv">
                <InfiniteScroll
                    dataLength={docs.length || 0}
                    next={loadMoreData}
                    hasMore={!!next}
                    loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                    endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                    scrollableTarget="scrollableDiv"
                >
                    <List
                        dataSource={docs}
                        renderItem={noti => _renderItem(noti)}
                    />
                </InfiniteScroll>
            </WrapperContent>
        </Drawer>
    );
};

export { template };

const Icon = styled.img``;

const WrapperContent = styled.div`
    height: 1000px;
    overflow: auto;
`;

const ContentItem = styled.a``;

const Footer = styled.a`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 8px;
`;

const NotiText = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 14px;
    line-height: 20px;
`;

const ViewAllText = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    letter-spacing: 0.4px;
    line-height: 13px;
`;
