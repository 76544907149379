import React, { useState, useEffect } from 'react';
import { template as tmpl } from './AddCustomerComponent.tmpl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    getUserById,
    createNewCustomer,
    searchCountries,
    updateUserById
} from '../../../redux/actions/appActions';
import { CUSTOMER_LIST } from '../../../routes/main';
import { useTranslation } from 'react-i18next';

const AddCustomerLogic = (props) => {
    const {
        companies = [],
        searchCountries,
        createNewCustomer,
        updateUserById,
        location,
        getUserById
    } = props;
    const { search } = location;

    const { t, i18n } = useTranslation();

    const [isEdit, setIsEdit] = useState(false);
    const [customer, setCustomer] = useState({});
    const [visibleModal, setVisibleModal] = useState(false);
    const [error, setError] = useState([]);
    const [successMsg, setSuccessMsg] = useState('');

    useEffect(() => {
        const initDetail = async () => {
            let id = search;

            // id == null ->  create new customer
            if (!id) return;

            // id != null -> edit customer
            if (id && id[0] === '?') {
                id = id.substring(1);
            }

            const rs = await getUserById(id);
            if (rs && rs.success) {
                setIsEdit(true);
                setCustomer(rs.data?.user || {});
            }
        };

        // init customer detail
        initDetail();
    }, []);

    const navigate = (route) => {
        props.history.push(route);
    };

    const onSubmit = async (data) => {
        let rs;
        // Clear error before call api. This could occur a bug.
        // The error state is cleared after errors from API updated.
        // But in reality, the API reponse always longer than state updated.
        // Anyway, this is not good practice.
        setError([]);
        if (!isEdit) {
            rs = await createNewCustomer(data);
            if (rs && rs.success) {
                // TODO: alert modal Create Customer Success!
                // navigate(CUSTOMER_LIST);
                setVisibleModal(true);
                setSuccessMsg(t('CUSTOMER_SUCCESSFULLY_ADDED').toUpperCase());
            } else if (!rs.success && rs.error.errormsg) {
                setError(rs.error.errormsg);
            }
        } else {
            rs = await updateUserById(customer.id, data);
            if (rs && rs.success) {
                // TODO: alert modal Create Customer Success!
                // navigate(CUSTOMER_LIST);
                setVisibleModal(true);
                setSuccessMsg(t('CUSTOMER_SUCCESSFULLY_UPDATED').toUpperCase());
            } else if (!rs.success && rs.error.errormsg) {
                setError(rs.error.errormsg);
            }
        }
    };

    const cancelModal = () => {
        setVisibleModal(false);
    };

    const onSearchCountries = async (key) => {
        const rs = await searchCountries(key);
        if (rs && rs.success) {
            return rs.countries || [];
        }
    };

    const initProps = {
        successMsg: successMsg,
        error: error,
        customer: customer,
        isEdit: isEdit,
        companies: companies,
        visibleModal: visibleModal,
        cancelModal: cancelModal,
        onSubmit: onSubmit,
        onSearchCountries: onSearchCountries,
        navigate: navigate
    };

    return tmpl && tmpl({ ...initProps });
};

class AddCustomerContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <AddCustomerLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { companies } = companyReducer;
    return {
        companies: companies
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserById: (id) => dispatch(getUserById(id)),
        createNewCustomer: (data) => dispatch(createNewCustomer(data)),
        searchCountries: (key) => dispatch(searchCountries(key)),
        updateUserById: (id, data) => dispatch(updateUserById(id, data))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AddCustomerContainer)
);
