import { result } from 'lodash';
import API from '../../modules/httpModule';
// import { FLIGHT_SALE_TYPE } from 'config/Constants';

/**
 * get flights
 * @returns
 */
export function fetchReport() {
    return async (dispatch) => {
        try {
            let result = {};
            const response = await API.get('/dashboard/flights/report');
            if (response && response.status === 200 && response.data) {
                result = response.data;
            }
            return result;
        } catch (err) {
            return {}; // empty
        }
    };
}

export function fetchListAircraftsPosition(partner = false) {
    //dashboard/map/aircrafts
    return async (dispatch) => {
        let result = [];
        try {
            const query = {
                partner: partner
            };
            const response = await API.get('/dashboard/map/aircrafts', query);
            if (response && response.status === 200 && response.data) {
                result = response.data || [];
            }
            return result;
        } catch (err) {
            return result; // empty
        }
    };
}

export function fetchEvents(from_time, to_time) {
    return async (dispatch) => {
        try {
            let query = {};
            let result = [];
            if (from_time) query['from_time'] = from_time;
            if (to_time) query['to_time'] = to_time;
            const response = await API.get(
                '/dashboard/flights/calendar',
                query
            );
            if (response && response.status === 200 && response.data) {
                result = response.data;
            }
            return result;
        } catch (err) {
            return [];
        }
    };
}
