import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { template as tmpl } from './ForgotPassword.tmpl';
import { getCountries, resetPasswordWl } from 'redux/actions/appActions';
import { LOGIN } from 'routes/main';
import { withRouter } from 'react-router-dom';
import { validateEmailFormat } from 'utils';
import _ from 'lodash';
import { FORGOT_PASSWORD_CONFIRM } from 'routes/main';

const ForgotPasswordLogic = (props) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [type, setType] = useState('email'); // email or sms

    const [email, setEmail] = useState('');
    const [country, setCountry] = useState({});
    const [phone, setPhone] = useState('');

    useEffect(() => {
        const initCountries = async() => {
            dispatch(getCountries());
        };

        // init countries data because outside stack none data of companyReducer
        initCountries();
    }, []);

    const handleSubmitEmail = async () => {
        if (loading) return;

        let data = {
            type: type
        };

        if (type === "email") {
            if (!email || !validateEmailFormat(email)) {
                setErrors({ email: 'INVALID_EMAIL' });
                setLoading(false);
                return;
            }

            data.email = email;
        } else if (type === "sms") {
            if (!phone) {
                setErrors({ phone: 'INVALID_PHONE' });
                setLoading(false);
                return;
            }
            if (_.isEmpty(country)) {
                setErrors({ country: 'INVALID_COUNTRY' });
                setLoading(false);
                return;
            }

            let callingCode = country.calling_code || '';
            if (callingCode && callingCode[0] === '+' ) {
                callingCode = callingCode.substring(1);
            }
            data.country_code = country?.short_code?.toUpperCase(),
            data.phone = `${callingCode}${phone}`;
        }

        setLoading(true);

        let result = await dispatch(resetPasswordWl(data));
        if (result && result.success) {
            let uid = result.uid;
            let avt = result.avt;
            
            props.history.push({
                pathname: FORGOT_PASSWORD_CONFIRM,
                state: { payload: data, uid, avt }
            });
        } else if (result && result.errors) {
            setErrors(result.errors);
        }

        setTimeout(() => {
            setLoading(false);
        }, 350);
    };

    const backToLogin = () => {
        props.history.push(LOGIN);
    };

    const onSelectCountry = (value) => {
        setCountry(value);
    };

    const onChangeTab = (key) => {
        setType(key);
    };

    const initProps = {
        loading: loading,
        email: email,
        setEmail: setEmail,
        errors: errors,
        handleSubmitEmail: handleSubmitEmail,
        backToLogin: backToLogin,
        country: country,
        phone: phone,
        setPhone: setPhone,
        type: type,
        onChangeTab: onChangeTab,
        onSelectCountry: onSelectCountry
    };
    return tmpl && tmpl({ ...initProps });
};

class ForgotPassword extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <ForgotPasswordLogic {...initProps} />;
    }
}

export default withRouter(ForgotPassword);
