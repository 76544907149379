import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { CustomerContainer, SearchSection } from '../styles';
import { Header, SearchInput } from 'components';
import EmptyLegTable from './EmptyLegTable';
import SearchResult from './SearchResult';

import { SearchBooking } from 'components/Commons';
import { bookingCategory } from 'config/Constants';

const template = (props) => {
    const {
        navigate,
        onSearch,
        loading = false,
        upcomingFlightsList = {},
        inFlightsList = {},
        completedFlightsList = {},
        searchList = {},
        onSelectCategory,
        onChangePageUpcomingFlights,
        onChangePageCompletedFlights,
        onChangePageInFlights,
        onChangePageSearch,
        onChangePageConfirmed,
        onChangePageCancelled,
        searchBy = '',
        key = ''
    } = props;
    const { t, i18n } = useTranslation();

    const createCategoryOption = () => {
        let tempArr = [];
        let key;
        for (key in bookingCategory) {
            tempArr.push({
                text: t(bookingCategory[key]),
                value: bookingCategory[key]
            });
        }
        return tempArr;
    };

    return (
        <CustomerContainer>
            <Header
                title={t('EMPTY_LEGS')}
                description={t('EMPTY_LEG_FLIGHT')}
            />
            <SearchSection>
                <SearchInput
                    placeholder={t('PLACEHOLDER_SEARCH_BOOKING')}
                    onSearch={(e) => onSearch(e)}
                />
            </SearchSection>

            {!key ? (
                <>
                    {/* Upcoming Flights */}

                    <EmptyLegTable
                        onChangePageUpcomingFlights={
                            onChangePageUpcomingFlights
                        }
                        list={upcomingFlightsList}
                        laoding={loading}
                        navigate={navigate}
                    />
                </>
            ) : (
                <SearchResult
                    onChangePageSearch={onChangePageSearch}
                    list={searchList}
                    laoding={loading}
                    navigate={navigate}
                />
            )}
        </CustomerContainer>
    );
};

export { template };
