import React, { useEffect, useState } from 'react';
import { template as tmpl } from './CreateQuotation.tmpl';
import {
    getQuoteById,
    getAircraftsForQuote
} from 'redux/actions/quoteAction';
import { searchAircrafts } from 'redux/actions/aircarftAction';
import { connect, useDispatch } from 'react-redux';
import { FLIGHT_TYPES, CREATE_QUOTATION_STEP } from 'config/Constants';
import { CREATE_REQUEST_QUOTE } from 'redux/Constants';
import _ from 'lodash';

const LIMIT_PAGE = 5;

const CreateQuotationLogic = (props) => {
    const dispatch = useDispatch();
    const { activeCompany, location, searchAircrafts, getAircraftsForQuote, quotes = [] } =
        props;
    const { search } = location;
    const [quoteDetail, setQuoteDetail] = useState({});
    const [aircraftList, setAircraftList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [focusedLeg, setFocusedLeg] = useState('');
    const [currentStep, setCurrentStep] = useState('');

    /**
     * Get Quote detail
     */
    useEffect(() => {
        async function fetchQuotedetail() {
            let id = search;
            if (id && id[0] === '?') {
                id = id.substring(1);
            }
            
            const result = await props.getQuoteById(id);
            if (result.data) {
                setQuoteDetail(result.data);
                const { type } = result.data;
                if (type === FLIGHT_TYPES.ONEWAY) {
                    const resultAircraftList = await getAircraftsForQuote(
                        id,
                        0,
                        LIMIT_PAGE,
                        1
                    );
                    if (resultAircraftList.success) {
                        setAircraftList(resultAircraftList.data.docs);
                        setTotalPage(resultAircraftList.data.pages);
                    }
                }

                dispatch({ 
                    type: CREATE_REQUEST_QUOTE,
                    data: {
                        id: id,
                        quote: result.data
                    }
                });
            }
        }

        // initial data
        fetchQuotedetail();
    }, []);

    // get current step create quotation
    useEffect(() => {
        if (!_.isEmpty(quotes)) {
            let step = CREATE_QUOTATION_STEP.AIRCRAFT;
            let data = quotes.find(item => item.id === quoteDetail?.id);
            if (data && data.step) {
                step = data.step;
            }
            setCurrentStep(step);
        }
    }, [quotes]);

    const navigate = (route, search, state) => {
        props.history.push({
            pathname: route,
            search: search,
            state: state ? { data: state } : null
        });
    };

    const initProps = {
        currentStep: currentStep,
        focusedLeg: focusedLeg,
        currentPage: currentPage,
        totalPage: totalPage,
        setFocusedLeg: setFocusedLeg,
        aircraftList: aircraftList,
        searchAircrafts: searchAircrafts,
        activeCompany: activeCompany,
        quoteDetail: quoteDetail,
        navigate: navigate
    };
    return tmpl && tmpl({ ...initProps });
};

class CreateQuotation extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <CreateQuotationLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer, quoteReducer } = state;
    const { employees, activeCompany } = companyReducer;
    const { quotes = [] } = quoteReducer;
    return {
        employees: employees,
        activeCompany: activeCompany,
        quotes: quotes
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getQuoteById: (id, query) => dispatch(getQuoteById(id, query)),
        searchAircrafts: (input) => dispatch(searchAircrafts(input)),
        getAircraftsForQuote: (id, legIndex, limit, page) =>
            dispatch(getAircraftsForQuote(id, legIndex, limit, page))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateQuotation);
