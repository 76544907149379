import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Row, Col, Switch, Input, Divider, Upload } from 'antd';
import {
    Map2,
    AircraftTypeCard,
    PackageDetailCard,
    PassengerInfoContainer,
    ButtonCustom,
    FlightNotes,
    Text
} from 'components';
import { HeaderInfo } from 'components/Cards';
import { allowedRolesCreateQuote } from 'config/Constants';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { groupBy } from 'utils';
import { WHITELABEL_SETTING } from 'routes/main';
import { QUOTE_REQUEST_STATUS } from 'redux/Constants';
import { FLIGHT_TYPES } from 'config/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { GLOBAL_STYLE_CONSTANTS } from 'config/Constants';
import {
    IconFacebook,
    IconTwitter,
    IconLinkedin,
    IconInstagram,
    uploadMorePhotosIcon
} from 'assets/images/icons';

const Footer = (props) => {
    const {
        facebook = '',
        instagram = '',
        linkedin = '',
        twitter = '',
        setFacebook,
        setInstagram,
        setLinkedin,
        setTwitter,
        copyrightText = '',
        setCopyrightText
    } = props;
    const { t, i18n } = useTranslation();

    return (
        <ContainerWrapper>
            <PayoutSection>
                <WhitelabelContainer>
                    <EachRow>
                        <GeneralSetting>
                            {t('ADD_COMPANY_SOCIAL_PROFILE')}
                        </GeneralSetting>
                    </EachRow>
                    <EachRow>
                        <GeneralSettingsCol
                            style={{ width: 570, marginRight: 60 }}
                        >
                            <EachRow style={{ flexDirection: 'column' }}>
                                <TitleText>{t('FACEBOOK')}</TitleText>
                                <CustomInput
                                    prefix={<Image src={IconFacebook} />}
                                    value={facebook}
                                    onChange={(e) =>
                                        setFacebook &&
                                        setFacebook(e.target.value)
                                    }
                                />
                            </EachRow>
                            <EachRow style={{ flexDirection: 'column' }}>
                                <TitleText>{t('TWITTER')}</TitleText>
                                <CustomInput
                                    prefix={<Image src={IconTwitter} />}
                                    value={twitter}
                                    onChange={(e) =>
                                        setTwitter && setTwitter(e.target.value)
                                    }
                                />
                            </EachRow>
                        </GeneralSettingsCol>
                        <GeneralSettingsCol style={{ width: 570 }}>
                            <EachRow style={{ flexDirection: 'column' }}>
                                <TitleText>{t('INSTAGRAM')}</TitleText>
                                <CustomInput
                                    prefix={<Image src={IconInstagram} />}
                                    value={instagram}
                                    onChange={(e) =>
                                        setInstagram &&
                                        setInstagram(e.target.value)
                                    }
                                />
                            </EachRow>
                            <EachRow style={{ flexDirection: 'column' }}>
                                <TitleText>{t('LINKEDIN')}</TitleText>
                                <CustomInput
                                    prefix={<Image src={IconLinkedin} />}
                                    value={linkedin}
                                    onChange={(e) =>
                                        setLinkedin &&
                                        setLinkedin(e.target.value)
                                    }
                                />
                            </EachRow>
                        </GeneralSettingsCol>
                    </EachRow>
                    <EachRow style={{ flexDirection: 'column' }}>
                        <TitleText>{t('COPYRIGHT_TEXT')}</TitleText>
                        <CustomInput
                            value={copyrightText}
                            onChange={(e) =>
                                setCopyrightText && setCopyrightText(e.target.value)
                            }
                        />
                    </EachRow>
                </WhitelabelContainer>
            </PayoutSection>
        </ContainerWrapper>
    );
};

export default Footer;

const CustomInput = styled(Input)`
    border: 1px solid rgba(36, 36, 45, 0.15);
    border-radius: 3px;
    width: 570px;
    height: 50px;
    margin-top: 10px;
`;

const ColorRow = styled(Row)`
    margin-top: 20px;
    width: 470px;
`;

const UploadPhoto = styled(Upload)`
    border: 1px dashed #65686f;
    border-radius: 4px;
    width: 470px;
    height: 183px;
    margin-top: 10px;
`;

const UploadText = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.84px;
    line-height: 14px;
`;

const UploadSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
`;

const Image = styled.img`
    object-fit: cover;
    width: 24px;
    height: 24px;
    margin-right: 10px;
`;

const GeneralSettingsCol = styled(Col)``;

const GeneralSetting = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 17px;
`;

// const DescriptionArea = styled(TextArea)`
//     border: 1px solid rgba(36, 36, 45, 0.15);
//     border-radius: 4px;
//     margin-top: 10px;
//     width: 770px;
// `;

const TitleText = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 18px;
    text-transform: uppercase;
`;

const WhitelabelContainer = styled.div`
    padding-left: 51px;
`;

const EachRow = styled(Row)`
    margin-top: 20px;
`;

const ConnectStripeButton = styled.button`
    background: transparent;
    background-color: #19c0ff;
    border: none;
    background-repeat: no-repeat;
    overflow: hidden;
    outline: none;
    border-radius: 2px;
    height: 40px;
    width: 190px;
    &:hover {
        background-color: #12afeb;
    }
`;

const ConnectStripeText = styled.span`
    color: #ffffff;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    letter-spacing: 0.7px;
    line-height: 13px;
    text-align: center;
`;

const SectionDescription = styled.span`
    color: #65686f;
    font-family: Lato;
    font-size: 14px;
    line-height: 24px;
`;

const Icon = styled.img`
    width: 16px;
    height: 16px;
    margin-top: 5px;
    margin-right: 15px;
`;

const ConnectStripeIntro = styled.div`
    margin: 25px 25px 0 47px;
    background-color: #f8f9fe;
    border-radius: 4px;
    padding: 15px 20px;
    display: flex;
`;

const ContainerWrapper = styled.div`
    padding-bottom: 40px;
    border-radius: 4px;
    /* margin-top: 14px; */
    .ant-divider {
        border-top: 1px solid rgba(14, 14, 18, 0.08);
    }
    .ant-divider-horizontal {
        margin: 20px 0;
    }
    input[type='color' i] {
        padding: 0;
        border: none;
        background: none;
        border-radius: 6px;
    }
`;

const PayoutSection = styled.div`
    padding-right: 20px;
    background-color: #ffffff;
    border-radius: 4px;
`;
