import React from 'react';
import { Input, Table, Avatar, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { Header, ButtonCustom, localeEmptyTable } from '../../components';
import { PARTNERS_DETAIL } from '../../routes/main';
import { searchIcon, imageIcon } from '../../assets/images/icons';
import {
    CustomerContainer,
    NameSectionButton,
    SearchSection,
    DetailSection
} from './styles';
import {
    NameCell,
    EmailCell,
    ItemCell,
    ColumnHeader,
    TableSection,
    Icon
} from '../../styles/shareStyles';
import _ from 'lodash';
import { renderAddress } from '../../utils';
import { ButtonType } from '../../components/Antd/Button';

const logoSize = 64;

const template = (props) => {
    const { navigate, partnersList } = props;
    const { t, i18n } = useTranslation();
    const SearchIcon = <img src={searchIcon} />;

    const onClickDetail = (val) => {
        navigate(PARTNERS_DETAIL, val.id, val);
    };

    const columns = [
        {
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => {
                if (a.name?.toUpperCase() < b.name?.toUpperCase()) {
                    return -1;
                }
                if (a.name?.toUpperCase() > b.name?.toUpperCase()) {
                    return 1;
                }
                return 0;
            },
            title: function titleHeader() {
                return <ColumnHeader>{t('PARTNERS')}</ColumnHeader>;
            },
            fixed: 'left',
            width: 220,
            render: function eachItemName(name, row = {}) {
                return (
                    <NameSectionButton onClick={() => onClickDetail(row)}>
                        {row.image ? (
                            <Avatar
                                size={logoSize}
                                src={row?.image}
                                style={{
                                    marginRight: 15,
                                    minWidth: logoSize,
                                    minHeight: logoSize
                                }}
                                className="ant-avatar-custom"
                            />
                        ) : (
                            <Avatar
                                size={logoSize}
                                style={{
                                    backgroundColor: '#F0F3F9',
                                    marginRight: 15,
                                    minWidth: logoSize,
                                    minHeight: logoSize
                                }}
                            >
                                <Icon
                                    src={imageIcon}
                                    style={{
                                        width: 32,
                                        height: 28
                                    }}
                                />
                            </Avatar>
                        )}
                        <NameCell>{name ? name.toUpperCase() : '_'}</NameCell>
                    </NameSectionButton>
                );
            }
        },
        {
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => {
                if (
                    a.contact?.email?.toUpperCase() <
                    b.contact?.email?.toUpperCase()
                ) {
                    return -1;
                }
                if (
                    a.contact?.email?.toUpperCase() >
                    b.contact?.email?.toUpperCase()
                ) {
                    return 1;
                }
                return 0;
            },
            title: function titleHeader() {
                return <ColumnHeader>{t('EMAIL_ADDRESS')}</ColumnHeader>;
            },
            render: function eachItemEmail(email, row) {
                return (
                    <EmailCell>
                        {row.contact ? t(row.contact.email) : '_'}
                    </EmailCell>
                );
            }
        },
        {
            dataIndex: 'phone',
            key: 'phone',
            title: function titleHeader() {
                return <ColumnHeader>{t('PHONE_NUMBER')}</ColumnHeader>;
            },
            render: function eachItemPhone(contact, row) {
                return (
                    <ItemCell>
                        {row.contact && row.contact.phone
                            ? row.contact.phone.replace(/\s/g, '')
                            : '_'}
                    </ItemCell>
                );
            }
        },
        {
            dataIndex: 'type',
            key: 'type',
            title: function titleHeader() {
                return <ColumnHeader>{t('TYPE')}</ColumnHeader>;
            },
            render: function eachItemPhone(type, row) {
                return <ItemCell>{type || '_'}</ItemCell>;
            }
        },
        {
            dataIndex: 'location',
            key: 'location',
            title: function titleHeader() {
                return <ColumnHeader>{t('ADDRESS')}</ColumnHeader>;
            },
            render: function eachItemAddress(location) {
                return <ItemCell>{renderAddress(location)}</ItemCell>;
            }
        },
        {
            key: 'action',
            title: '',
            render: function setAction(val) {
                return (
                    <DetailSection>
                        <ButtonCustom
                            size="small"
                            btnType={ButtonType.SECONDARY}
                            title={t('VIEW_DETAILS').toUpperCase()}
                            onClick={(e) => onClickDetail(val)}
                        />
                    </DetailSection>
                );
            }
        }
    ];

    return (
        <CustomerContainer>
            <Header title={t('PARTNERS')} description={t('LIST_OF_PARTNERS')} />
            <SearchSection>
                <Row style={{ width: '100%' }}>
                    <Col xl={6} lg={7} md={8} sm={10} xs={12}>
                        <Input
                            placeholder={t('SEARCH')}
                            allowClear
                            suffix={SearchIcon}
                            onChange={(e) => props.onSearch(e)}
                            style={{ width: '100%' }}
                        />
                    </Col>
                </Row>
            </SearchSection>

            <TableSection>
                <Table
                    columns={columns}
                    dataSource={partnersList}
                    pagination={{ showSizeChanger: false }}
                    style={{ marginTop: 10 }}
                    scroll={{ x: 1000 }}
                    locale={localeEmptyTable()}
                />
            </TableSection>
        </CustomerContainer>
    );
};

export { template };
