import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Input, AutoComplete, Row } from 'antd';
import _ from 'lodash';
import debounce from 'lodash/debounce';
import { TextDemiBold, TextMedium } from 'components/Antd/Text';
import { getColorAndFontManifest } from 'redux/selectors/manifest';
import { useSelector } from 'react-redux';

const template = (props) => {
    const { t, i18n } = useTranslation();
    const {
        label = '',
        prefix,
        airport = {},
        onSelectAirport,
        onSearchAirports,
        dropdownStyle,
        inputStyle = {},
        width = 770,
        height = 50,
        allowClear,
        onClear,
        placeholder,
        defaultValue,
        disabled
    } = props;
    const { fonts = {}} = useSelector(getColorAndFontManifest());

    const style = {
        width: width,
        height: height,
        fontFamily: fonts.secondary?.family || 'Lato',
        color: '#121216',
        ...inputStyle
    };
    
    const [options, setOptions] = useState([]);
    const [airports, setAirports] = useState([]);
    const [inputSearch, setInputSearch] = useState(
        defaultValue ? `${defaultValue.icao_code}  - ${defaultValue.name}` : ''
    );

    useEffect(() => {
        if (!_.isEmpty(airport)) {
            setInputSearch(airport ? `${airport.icao_code}  - ${airport.name}` : '');
        } else {
            setInputSearch('');
            setOptions([]);
        }
    }, [airport]);

    const searchResult = (results) =>
        results.map((val, i) => {
            const category = `${val.icao_code} - ${val.name}`;
            let isActive = airport && airport.id === val.id ? true : false;

            return {
                key: val.id,
                value: category,
                label: (
                    <DropdownWrapper active={isActive}>
                        <DropdownText className="search-airport-selection-item">
                            {category}
                        </DropdownText>
                    </DropdownWrapper>
                )
            };
        });

    const onSelect = (value, options) => {
        const { key } = options;
        let data = airports.find((v) => v.id === key);
        setInputSearch(`${data.icao_code}  - ${data.name}`);
        onSelectAirport(data);
    };

    const handleSearch = async (value) => {
        const result = await onSearchAirports(value);
        setAirports(result);
        setOptions(value ? searchResult(result) : []);
    };

    const debounceSearch = (value) => {
        setInputSearch(value);
        const debouncedSave = debounce(() => {
            handleSearch(value);
        }, 500);

        // debounce
        debouncedSave();
    };

    return (
        <Container font={fonts.secondary?.family || 'Lato'}>
            {label && (
                <Row style={{ marginBottom: 12 }}>
                    <Label>{label}</Label>
                </Row>
            )}
            <AutoComplete
                dropdownMatchSelectWidth={252}
                options={options}
                onSelect={onSelect}
                onSearch={debounceSearch}
                disabled={disabled}
                value={inputSearch}
                allowClear={allowClear}
                onClear={onClear}
                dropdownStyle={{
                    minWidth: '370px',
                    borderRadius: 4,
                    padding: 0
                }}
                style={{ width: '100%', fontFamily: fonts.secondary?.family || 'Lato' }}
            >
                <Input
                    prefix={prefix}
                    style={style} // default
                    placeholder={placeholder || ''}
                />
            </AutoComplete>
        </Container>
    );
};

const Container = styled.div`
    .ant-input {
        font-family: ${props => props.font || 'Lato'};
    }
`;

const Label = styled(TextDemiBold)`
    color: #35383f;
    font-size: 11px;
    letter-spacing: 0.7px;
    line-height: 13px;
    text-align: left;
    margin-left: ${(props) => (props.rightInput ? '30px' : '0px')};
`;

const DropdownWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;

    ${({ active }) =>
        active &&
        `
        background-color: #F7F9FD;
    `}
`;

const DropdownText = styled(TextMedium)`
    color: #595b60;
    font-size: 14px;
    line-height: 17px;
`;

export { template };
