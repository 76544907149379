import React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { ButtonCustom, Text, localeEmptyTable } from 'components';
import { ButtonType } from 'components/Antd/Button';
import {
    DetailSection,
    BookingCode,
    TimeText,
    TypeText,
    ItemText
} from '../styles';
import { ColumnHeader } from 'styles/shareStyles';
import { formatTimeBooking, numberFormat } from 'utils';
import {
    JetInfo,
    FlightInfo,
    Booker,
    PaymentStatus,
    PriceRange,
    BookingStatus
} from 'components/Commons';
import { QUOTE_REQUEST_DETAIL } from 'routes/main';

const AwaitingQuote = (props) => {
    const { t, i18n } = useTranslation();

    const {
        navigate,
        list = {},
        key,
        setKey,
        loading = false,
        onChangePageAwaiting
    } = props;
    const { docs = [], limit, page, pages, total = 0 } = list;
    const marginBottom = pages > 1 ? 0 : 60;

    const columns = [
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('REQUEST_NUMBER')}</ColumnHeader>;
            },
            width: 80,
            dataIndex: 'request_code',
            key: 'request_code',
            fixed: 'left',
            render: function eachItemBooking(request_code) {
                return <BookingCode>{request_code}</BookingCode>;
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('UPDATED_AT')}</ColumnHeader>;
            },
            width: 120,
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: function eachItemCreatedAt(updated_at) {
                return (
                    <TimeText>
                        {updated_at ? formatTimeBooking(updated_at) : '_'}
                    </TimeText>
                );
            },
            sorter: (a, b) => a.updated_at.localeCompare(b.updated_at)
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('REQUESTED_BY')}</ColumnHeader>;
            },
            width: 150,
            dataIndex: 'user_basic_info',
            key: 'user_basic_info',
            render: function eachType(user) {
                return <Booker user={user} />;
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('TYPE')}</ColumnHeader>;
            },
            width: 60,
            dataIndex: 'type',
            key: 'type',
            render: function eachType(type) {
                return <TypeText>{t(type).toUpperCase()}</TypeText>;
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('FLIGHT_INFO')}</ColumnHeader>;
            },
            width: 180,
            dataIndex: 'legs',
            key: 'legs',
            render: function eachItemType(legs) {
                if (Array.isArray(legs)) {
                    const newLegs = legs.map((leg) => {
                        return {
                            from_airport: leg.from_airport_basic_info,
                            to_airport: leg.to_airport_basic_info
                        };
                    });

                    return (
                        <>
                            {newLegs.map((leg, index) => {
                                return (
                                    <div key={index}>
                                        <FlightInfo leg={leg} />
                                    </div>
                                );
                            })}
                        </>
                    );
                }
                return;
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('PRICE_RANGE')}</ColumnHeader>;
            },
            width: 160,
            dataIndex: 'price_range',
            key: 'price_range',
            render: function eachItemStatus(price_range) {
                return <PriceRange price_range={price_range} />;
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('STATUS')}</ColumnHeader>;
            },
            width: 120,
            dataIndex: 'status',
            key: 'status',
            render: function eachItemStatus(status) {
                return <BookingStatus status={status} type="QUOTE_REQUEST" />;
            }
        },

        {
            key: 'action',
            width: 100,
            render: function setAction(val) {
                return (
                    <DetailSection>
                        <ButtonCustom
                            btnType={ButtonType.PRIMARY}
                            title={t('VIEW_DETAILS').toUpperCase()}
                            size="small"
                            borderColor="1px solid rgba(18, 18, 22, 0.2)"
                            width="115px"
                            onClick={(e) => {
                                navigate(QUOTE_REQUEST_DETAIL, val._id);
                            }}
                        />
                    </DetailSection>
                );
            }
        }
    ];

    return (
        <div style={{ marginBottom: marginBottom }}>
            <Text textStyle="heading5">
                {`${t('AWAITING_CUSTOMER_RESPONSE').toUpperCase()} (${total})`}
            </Text>

            <Table
                columns={columns}
                dataSource={docs}
                style={{ marginTop: 20 }}
                scroll={{ x: 1200 }}
                loading={loading}
                locale={localeEmptyTable()}
                pagination={{
                    defaultPageSize: 5,
                    total: pages * 5 - 1,
                    hideOnSinglePage: true,
                    showSizeChanger: false
                }}
                onChange={(e) => onChangePageAwaiting(e)}
            />
        </div>
    );
};

export default AwaitingQuote;
