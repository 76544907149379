import React, { useState, useEffect } from 'react';
import { template as tmpl } from './AddServicesContainer.tmpl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    searchCountries,
    createServiceItemBy,
    uploadImage
} from '../../../redux/actions/appActions';
import { SERVICES } from '../../../routes/main';
import { searchAircrafts } from '../../../redux/actions/aircarftAction';
import { UPLOAD_MODEL, UPLOAD_IMAGE_TYPE } from '../../../redux/Constants';

const AddServicesLogic = (props) => {
    const {
        searchCountries,
        createServiceItemBy,
        uploadImage,
        searchAircrafts
    } = props;

    const [visibleModal, setVisibleModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = (route) => {
        props.history.push(route);
    };

    const onSubmit = async (data) => {
        // get data and imageUrl;
        let rs = await createServiceItemBy(data);
        if (!rs.success) {
            alert('Create new service item failed!');
            setErrorMessage('Create new service item failed!');
            return;
        }

        // add params imageUrl and update new item
        rs = await uploadImage(rs.id, data.photo, UPLOAD_MODEL.PACKAGE);
        if (!rs.success) {
            setErrorMessage('Upload service item images failed!');
            alert('Create new service item failed!');
            return;
        }

        // TODO: alert modal Create Customer Success!
        // navigate(SERVICES);
        setVisibleModal(true);
    };

    const cancelModal = () => {
        setVisibleModal(false);
    };

    const onSearchCountries = async (key) => {
        const rs = await searchCountries(key);
        if (rs && rs.success) {
            return rs.countries || [];
        }
    };

    const onSearchAircrafts = async (key) => {
        const rs = await searchAircrafts(key);
        if (rs && rs.success) {
            return rs.aircrafts || [];
        }
    };

    const initProps = {
        visibleModal: visibleModal,
        errorMessage: errorMessage,
        cancelModal: cancelModal,
        onSubmit: onSubmit,
        onSearchCountries: onSearchCountries,
        onSearchAircrafts: onSearchAircrafts,
        navigate: navigate
    };

    return tmpl && tmpl({ ...initProps });
};

class AddServicesContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <AddServicesLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { companies } = companyReducer;
    return {
        companies: companies
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createServiceItemBy: (data) => dispatch(createServiceItemBy(data)),
        uploadImage: (id, photo, model) =>
            dispatch(uploadImage(id, photo, model)),
        searchCountries: (key) => dispatch(searchCountries(key)),
        searchAircrafts: (key) => dispatch(searchAircrafts(key))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AddServicesContainer)
);
