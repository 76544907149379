import { UPDATE_USER_INFORMATION } from '../Constants';

/**
 * Update user information after API returns
 * @param {object} user - user information returned from API
 * @returns
 */
export function updateUserInformationFromAPI(user) {
    return async (dispatch, getState) => {
        try {
            const { userReducer } = getState();
            let userInformation = {
                id: '',
                address: {
                    address_line1: '',
                    address_line2: '',
                    city: '',
                    postalcode: '',
                    state: '',
                    country: {
                        english_name: '',
                        native_name: '',
                        short_code: ''
                    }
                },
                email: '',
                firstname: '',
                lastname: '',
                image: '',
                background: '',
                device_tokens: [],
                identity: {},
                role: '',
                settings: '',
                meta: {},
                companies: [],
                gender: null,
                phone: '',
                weight_lb: '',
                is_online: false,
                language: {},
                identification: [],
                membership: {},
                style: {
                    images: []
                }
            };
            userInformation = {
                id: user.id || userReducer.id,
                address: {
                    address_line1:
                        user.address && user.address.address_line1
                            ? user.address.address_line1
                            : userReducer.address.address_line1,
                    address_line2:
                        user.address && user.address.address_line2
                            ? user.address.address_line2
                            : userReducer.address.address_line2,
                    city:
                        user.address && user.address.city
                            ? user.address.city
                            : userReducer.address.city,
                    postalcode:
                        user.address && user.address.postalcode
                            ? user.address.postalcode
                            : userReducer.address.postalcode,
                    state:
                        user.address && user.address.state
                            ? user.address.state
                            : userReducer.address.state,
                    country:
                        user.address && user.address.country
                            ? user.address.country
                            : userReducer.address.country
                },
                device_tokens: user.device_tokens || userReducer.device_tokens,
                identity: user.identity || userReducer.identity,
                role: user.role || userReducer.role,
                settings: user.settings || userReducer.settings,
                meta: user.meta || userReducer.meta,
                companies: user.companies || userReducer.companies,
                email: user.email ? user.email : userReducer.email,
                firstname: user.firstname
                    ? user.firstname
                    : userReducer.firstname,
                lastname: user.lastname ? user.lastname : userReducer.lastname,
                gender: user.gender ? user.gender : userReducer.gender,
                phone: user.phone ? user.phone : userReducer.phone,
                weight_lb: user.weight_lb
                    ? user.weight_lb
                    : userReducer.weight_lb,
                is_online: user.is_online
                    ? user.is_online
                    : userReducer.is_online,
                language: user.language ? user.language : userReducer.language,
                identification: user.identification
                    ? user.identification
                    : userReducer.identification,
                membership: user.membership
                    ? user.membership
                    : userReducer.membership,
                style: user.style ? user.style : userReducer.style,
                image: user.image ? user.image : userReducer.image,
                background: user.background ? user.background : userReducer.background
            };

            dispatch({
                type: UPDATE_USER_INFORMATION,
                payload: userInformation
            });

            return true;
        } catch (err) {
            return false;
        }
    };
}
