import React from 'react';
import styled from 'styled-components';
import { Select, Tag } from 'antd';
import { iconCancel } from 'assets/images/icons';
import { useTranslation } from 'react-i18next';

/**
 *
 * @param {*} props
 * @param {*} textStyle fieldLabel
 * @returns
 */
const TagComponent = (props) => {
    const { children, label, width, data = [], closable, onClose } = props;
    const { t, i18n } = useTranslation();
    return (
        <TagSection width={width}>
            <Tag
                className="ant-tag-custom"
                //   color={value}
                //   onMouseDown={onPreventMouseDown}
                closeIcon={<CloseIcon src={iconCancel} />}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
            >
                <TagText>{label}</TagText>
            </Tag>
        </TagSection>
    );
};

export default TagComponent;

const TagSection = styled.div``;

const TagText = styled.span`
    color: #35383f;
    font-family: Lato;
    font-size: 14px;
`;

const CloseIcon = styled.img`
    height: 12px;
    width: 12px;
`;
