import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Modal, Row, Col } from 'antd';
import { ButtonCustom } from 'components';

const WarningModal = ({
    show = false,
    title = '',
    okText,
    onOk,
    cancelText,
    onCancel
}) => {
    const { t, i18n } = useTranslation();

    return (
        <Modal
            centered
            visible={show}
            onOk={() => onOk()}
            onCancel={() => onCancel(false)}
            footer={null}
            style={{ margin: 50 }}
        >
            <Row style={{ marginTop: 30, marginBottom: 30 }}>
                <div>
                    <Wrapper style={{ justifyContent: 'center' }}>
                        <Title>{title}</Title>
                    </Wrapper>
                    <Wrapper
                        style={{ marginTop: 25, justifyContent: 'center' }}
                    >
                        <Col>
                            <ButtonCustom
                                btnType="primary"
                                width="150px"
                                size="medium"
                                title={cancelText || t('CANCEL').toUpperCase()}
                                onClick={onCancel}
                            />
                        </Col>
                        <Col style={{ marginLeft: 25 }}>
                            <ButtonCustom
                                btnType="secondary"
                                width="150px"
                                size="medium"
                                title={okText || t('OK').toUpperCase()}
                                onClick={onOk}
                            />
                        </Col>
                    </Wrapper>
                </div>
            </Row>
        </Modal>
    );
};

export default WarningModal;

const Wrapper = styled(Row)``;

const Title = styled.div`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 32px;
    text-align: center;
`;
