import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import styled from 'styled-components';

import { Header } from 'components';
import InvoiceInfoCard from '../../InvoiceInfoCard';
import InvoiceDetailsCard from '../../InvoiceDetailsCard';
import { INVOICE_TYPE } from 'redux/Constants';

const template = (props) => {
    const { navigate, loading = false, invoiceDetail = {} } = props;
    const {
        addressee = {},
        booking = {},
        legs,
        id = '',
        invoice_code = '',
        status = '',
        totals = {},
        type = '',
        created_at,
        finalised_at
    } = invoiceDetail;
    const { t, i18n } = useTranslation();

    return (
        <Container>
            <Header
                title={`${t('PARTNER_INVOICES')}`}
                description={`${t('INVOICE')} #${invoice_code}`}
            />

            <InvoiceInfoCard
                invoiceCode={invoice_code}
                bookingCode={booking?.booking_code}
                addressee={addressee}
                createdAt={created_at}
                finalisedAt={finalised_at}
                totals={totals}
                status={status}
                type={INVOICE_TYPE.PARTNER}
            />

            { legs && legs.map((leg = {}, index) => {
                const { invoice_items = [], totals = {} } = leg;
                return (
                    <div key={index} style={{ marginTop: '40px' }}>
                        <InvoiceDetailsCard
                            totals={totals}
                            type={type}
                            invoiceCode={invoice_code}
                            invoiceItems={invoice_items}
                            isPartner={true}
                        />
                    </div>
                );
            })}
        </Container>
    );
};

export { template };

const Container = styled.div`
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    margin-right: 20px;
`;
