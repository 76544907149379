import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { Switch, Row, Col, Input } from 'antd';
import { HeaderInfo } from 'components/Cards';
import { ButtonCustom } from 'components';
import {
    Container,
    Label,
    BottomContainer,
    Wrapper,
    InfoWrapper,
    TitleSection,
    TextSection
} from '../styles';
import moment from 'moment';
import { getBrowser } from 'utils';
import auth from 'services/auth';
import { updateUserDeviceToken } from 'redux/actions/appActions';
import { useDispatch } from 'react-redux';
import { requestFirebaseNotificationPermission } from '../../../firebase';

const Preferences = (props) => {
    const dispatch = useDispatch();

    const { userInfo = {}, onSubmitPreferences } = props;
    const { preferences = {}, settings = {} } = userInfo;

    const { t, i18n } = useTranslation();

    const [isUpdate, setIsUpdate] = useState(false);

    const [isLooking, setIsLooking] = useState(false); // Looking for Operator
    const [isVisible, setIsVisible] = useState(false); // Visible to Operator
    const [pushNotification, setPushNotification] = useState(false); // Push Notification

    const [dateFormat, setDateFormat] = useState('');
    const [timeFormat, setTimeFormat] = useState('');

    useEffect(() => {
        setIsLooking(settings.looking_for_operator);
        setIsVisible(settings.visible_to_operators);

        setDateFormat(settings.date_format);
        setTimeFormat(settings.time_format);

        // check Notification permissions
        checkNotificationPermission();
    }, [props.userInfo]);

    const checkNotificationPermission = () => {
        const fcmToken = auth.getFCMToken();
        if (!fcmToken) {
            setPushNotification(false);
            return;
        }

        let enabled = fcmToken?.enabled;
        setPushNotification(enabled);
    };

    const onClickEdit = () => {
        setIsUpdate(!isUpdate);
    };

    const requestFCMToken = (userId) => {
        const browser = getBrowser();

        requestFirebaseNotificationPermission()
            .then(async (firebaseToken) => {
                if (!firebaseToken) {
                    console.log('Browser does not support cloud messaging');
                    return;
                }

                let params = {
                    token: firebaseToken,
                    os: 'sw', // check os - safari || sw
                    browser: browser.toLocaleLowerCase(),
                    device_id: '',
                    enabled: true
                };

                let rs = await props.updateUserDeviceToken(userId, params);
                if (rs.success) {
                    auth.setFCMToken(params);
                    setPushNotification(true);
                }
            })
            .catch((err) => {});
    };

    const onChangePushNotification = async () => {
        const user = auth.getUserInfo();
        let fcmToken = auth.getFCMToken();
        if (!user) return;

        if (!fcmToken) {
            requestFCMToken(user.id);
        } else {
            const enable = !pushNotification;
            fcmToken.enabled = enable;

            let rs = await dispatch(updateUserDeviceToken(user.id, fcmToken));
            if (rs && rs.success) {
                auth.setFCMToken(fcmToken);
            }
            setPushNotification(enable);
        }
    };

    const _renderEditView = () => {
        return (
            <WrapperUpdate>
                <Row>
                    <Col lg="3" sm="3" xs="12">
                        <Label>{i18n.t('DATE_FORMAT_STR').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setDateFormat(val.target.value)}
                            className="base left"
                            style={{ fontFamily: 'Lato', color: '#65686F' }}
                            placeholder={i18n.t('DATE_FORMAT_STR')}
                            value={moment().format(dateFormat)}
                        />
                    </Col>
                    <Col lg="3" sm="3" xs="12">
                        <Label>{i18n.t('TIME_FORMAT').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setTimeFormat(val.target.value)}
                            className="base"
                            style={{ fontFamily: 'Lato', color: '#65686F' }}
                            placeholder={i18n.t('TIME_FORMAT')}
                            value={moment().format(timeFormat)}
                        />
                    </Col>
                </Row>
                <BottomContainer>
                    <ButtonCustom
                        btnType="primary"
                        width="190px"
                        onClick={() => onSubmitPreferences()}
                        title={i18n.t('SAVE_CHANGES').toUpperCase()}
                    />
                </BottomContainer>
            </WrapperUpdate>
        );
    };

    const _renderDetailView = () => {
        return (
            <Wrapper style={{ paddingLeft: 15, paddingBottom: 15 }}>
                <InfoWrapper className="label-detail">
                    <TitleSection>{i18n.t('DATE_FORMAT_STR')}</TitleSection>
                    <TextSection>{moment().format(dateFormat)}</TextSection>
                </InfoWrapper>
                <InfoWrapper className="label-detail">
                    <TitleSection>{i18n.t('TIME_FORMAT')}</TitleSection>
                    <TextSection>{moment().format(timeFormat)}</TextSection>
                </InfoWrapper>
                <InfoWrapper className="label-detail">
                    <TitleSection>
                        {i18n.t('LOOKING_FOR_OPERATOR')}
                    </TitleSection>
                    <Switch
                        checked={isLooking}
                        onChange={() => setIsLooking(!isLooking)}
                        style={{ marginLeft: 25 }}
                    />
                </InfoWrapper>
                <InfoWrapper className="label-detail">
                    <TitleSection>{i18n.t('VISIBLE_TO_OPERATOR')}</TitleSection>
                    <Switch
                        checked={isVisible}
                        onChange={() => setIsVisible(!isVisible)}
                        style={{ marginLeft: 25 }}
                    />
                </InfoWrapper>
                <InfoWrapper className="label-detail">
                    <TitleSection>{i18n.t('PUSH_NOTIFICATION')}</TitleSection>
                    <Switch
                        checked={pushNotification}
                        onChange={() => onChangePushNotification()}
                        style={{ marginLeft: 25 }}
                    />
                </InfoWrapper>
            </Wrapper>
        );
    };

    return (
        <Container>
            <HeaderInfo
                title={i18n.t('PREFERENCES').toUpperCase()}
                actionText={i18n.t('EDIT').toUpperCase()}
                isUpdate={isUpdate}
                onClick={onClickEdit}
            />
            {!isUpdate ? _renderDetailView() : _renderEditView()}
        </Container>
    );
};

export default Preferences;

const WrapperUpdate = styled.div`
    padding-left: 45px;
`;
