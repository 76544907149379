import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import { iconArrowDown } from 'assets/images/icons';
import { useTranslation } from 'react-i18next';
const { Option } = Select;
/**
 *
 * @param {*} props
 * @param {*} textStyle fieldLabel
 * @returns
 */
const DropDownInput = (props) => {
    const {
        children,
        placeholder,
        width,
        data = [],
        onChange,
        required = false,
        pickedData,
        onClear,
        height = '50px',
        isError = false,
        showSearch = false
    } = props;

    const { t, i18n } = useTranslation();
    const [selectedValue, setSelectedValue] = useState(null);

    useEffect(() => {
        if (pickedData && pickedData.length === 0) {
            setSelectedValue(null);
        } else {
            setSelectedValue(pickedData);
        }
    }, [pickedData]);

    const inputStyle = {
        border: (isError && required) ? '1px solid #f00' : 'none',
        borderRadius: 4
    };

    return (
        <SearchSection width={width} height={height}>
            <Select
                showSearch={showSearch}
                className="ant-select-custom"
                suffixIcon={<DropDownIcon src={iconArrowDown} />}
                style={{ ...inputStyle }}
                placeholder={
                    <SelectPlaceholder className="SelectPlaceholder">
                        {placeholder}
                    </SelectPlaceholder>
                }
                onChange={(value) => {
                    if (value) {
                        let obj = data.find((o) => o.value === value);
                        setSelectedValue(obj.text);
                        onChange && onChange(value);
                    } else {
                        setSelectedValue(null);
                    }
                }}
                onClear={() => {
                    if (onClear && typeof onClear === 'function') {
                        onClear();
                    }
                }}
                value={selectedValue || null}
                allowClear={true}
            >
                {data.map((item, index) => {
                    return (
                        <Option value={item.value} key={index}>
                            {item.text || item.value?.family || ''}
                        </Option>
                    );
                })}
            </Select>
        </SearchSection>
    );
};

export default DropDownInput;

const SearchSection = styled.div`
    .ant-select-custom .ant-select-selector {
        height: ${(props) => (props.height ? props.height : '50px')};
        border-radius: 3px;
        ${props => props.width && `width:${props.width};`}
    }
    .ant-input {
        background-color: transparent;
    }
    .ant-input-affix-wrapper {
        border: 1px solid #dadadb;
        border-radius: 3px;
        width: ${(props) => (props.width ? props.width : '20vw')};
        height: 40px;
        background-color: transparent;
    }
    .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
    }
`;

const SelectPlaceholder = styled.span`
    color: #8e929a;
    font-family: Lato;
    font-size: 14px;
    line-height: 17px;
`;

const DropDownIcon = styled.img`
    height: 15px;
    width: 15px;
`;
