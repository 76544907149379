import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import {
    DASHBOARD,
    REGISTER,
    SETTING_PROFILE,
    CUSTOMER_LIST,
    CUSTOMER_DETAIL,
    ADD_NEW_CUSTOMER,
    EMPLOYEES,
    EDIT_SETTING_PROFILE,
    ADD_NEW_EMPLOYEE,
    EDIT_CUSTOMER,
    EMPLOYEE_DETAIL,
    SERVICES,
    SERVICES_DETAIL,
    ADD_NEW_SERVICES,
    BROKER_CONNECTIONS,
    PARTNERSHIP_FLEET,
    PARTNERSHIP_FLEET_AIRCRAFT_DETAIL,
    PARTNERSHIP_FLEET_ADD_ARICRAFT,
    COMPANY_FLEET,
    COMPANY_FLEET_AIRCRAFT_DETAIL,
    COMPANY_FLEET_ADD_ARICRAFT,
    FBO_LIST,
    FBO_DETAIL,
    ADD_FBO,
    PARTNERS_LIST,
    PARTNERS_DETAIL,
    ADD_PARTNERS,
    AIRCRAFT_DETAIL,
    ADD_ARICRAFT,
    COMPANY_PROFILE,
    QUOTE_REQUEST_DETAIL,
    QUOTE_REQUEST,
    BOOKING_LIST,
    BOOKING_DETAIL,
    BOOKING_INVOICE,
    CREATE_QUOTATION,
    FLIGHT_LIST,
    FLIGHT_DETAIL,
    EMPTY_LEG,
    EMPTY_LEG_DETAIL,
    COMPANY_INVOICES,
    COMPANY_INVOICE_DETAIL,
    NOTIFICATION_LIST,
    FLIGHT_PROMOTION_LIST,
    TOP_RATED_AIRPORT_DETAIL,
    ADD_TOP_RATED_AIRPORT,
    TOP_DESTINATION_DETAIL,
    ADD_TOP_DESTINATION,
    COMPANY_SETTING,
    WHITELABEL_SETTING,
    EDIT_WHITELABEL
} from './main';
import PrivateRoute from '../navigation/auth/PrivateRoute';

import { Dashboard } from '../views/Dashboard';
import { SettingProfile, EditSettingProfile } from '../views/SettingProfile';
import Register from '../views/Register';
import { Employees, AddNewEmployee, EmployeeDetail } from '../views/Employees';
import {
    CustomerList,
    CustomerDetail,
    AddNewCustomer
} from '../views/Customer';
import {
    BrokerConnections,
    AircraftDetail,
    AddAircraft
} from '../views/BrokerConnections';
import { Services, ServicesDetail, AddServices } from '../views/Services';
import { FBOList, FBODetail, AddFBO } from '../views/FBO';
import { PartnersList, PartnersDetail, AddPartners } from '../views/Partners';
import CompanyProfile from '../views/CompanyProfile/CompanyProfile';
import {
    QuoteRequestContainer,
    QuoteRequestDetail,
    CreateQuotation
} from '../views/QuoteRequest';

import { BookingList, BookingDetail, BookingInvoice } from '../views/Bookings';
import { UpcomingFLight, FlightDetail } from '../views/UpcomingFlight';
import { EmptyLeg, EmptyLegDetail } from '../views/EmptyLeg';
import { CompanyInvoiceList, CompanyInvoiceDetail } from '../views/Invoices';

import { NotificationList } from 'views/Notifications';
import {
    FlightPromotions,
    TopRatedAirportDetail,
    TopDestinationDetail,
    AddTopRatedAirport,
    AddTopDestinations
} from 'views/Promotions';
import {
    PartnershipFleet,
    AircraftDetail as PartnershipFleetAircraftDetail,
    AddAircraft as PartnershipFleetAddAircraft
} from '../views/PartnershipFleet';
import {
    CompanyFleet,
    AircraftDetail as CompanyFleetAircraftDetail,
    AddAircraft as CompanyFleetAddAircraft
} from '../views/CompanyFleet';
import { CompanySetting } from 'views/CompanySetting';
import { WhiteLabelSetting, EditWhiteLabel } from 'views/WhiteLabelSetting';

const RouterConfig = () => {
    return (
        <Switch>
            <PrivateRoute exact path={EMPTY_LEG} component={EmptyLeg} />
            <PrivateRoute
                exact
                path={EMPTY_LEG_DETAIL}
                component={EmptyLegDetail}
            />
            <PrivateRoute exact path={FLIGHT_DETAIL} component={FlightDetail} />
            <PrivateRoute exact path={FLIGHT_LIST} component={UpcomingFLight} />
            <PrivateRoute
                exact
                path={QUOTE_REQUEST}
                component={QuoteRequestContainer}
            />
            <PrivateRoute
                exact
                path={QUOTE_REQUEST_DETAIL}
                component={QuoteRequestDetail}
            />
            <PrivateRoute
                exact
                path={CREATE_QUOTATION}
                component={CreateQuotation}
            />
            <PrivateRoute exact path={DASHBOARD} component={Dashboard} />
            <PrivateRoute
                exact
                path={BROKER_CONNECTIONS}
                component={BrokerConnections}
            />

            <PrivateRoute
                exact
                path={AIRCRAFT_DETAIL}
                component={AircraftDetail}
            />
            <PrivateRoute exact path={ADD_ARICRAFT} component={AddAircraft} />
            <PrivateRoute
                exact
                path={SETTING_PROFILE}
                component={SettingProfile}
            />
            <PrivateRoute
                exact
                path={PARTNERSHIP_FLEET}
                component={PartnershipFleet}
            />
            <PrivateRoute
                exact
                path={PARTNERSHIP_FLEET_AIRCRAFT_DETAIL}
                component={PartnershipFleetAircraftDetail}
            />
            <PrivateRoute
                exact
                path={PARTNERSHIP_FLEET_ADD_ARICRAFT}
                component={PartnershipFleetAddAircraft}
            />
            <PrivateRoute exact path={COMPANY_FLEET} component={CompanyFleet} />
            <PrivateRoute
                exact
                path={COMPANY_FLEET_AIRCRAFT_DETAIL}
                component={CompanyFleetAircraftDetail}
            />
            <PrivateRoute
                exact
                path={COMPANY_FLEET_ADD_ARICRAFT}
                component={CompanyFleetAddAircraft}
            />
            <PrivateRoute exact path={SERVICES} component={Services} />
            <PrivateRoute
                exact
                path={SERVICES_DETAIL}
                component={ServicesDetail}
            />
            <PrivateRoute
                exact
                path={ADD_NEW_SERVICES}
                component={AddServices}
            />

            <PrivateRoute exact path={EMPLOYEES} component={Employees} />
            <PrivateRoute
                exact
                path={EDIT_SETTING_PROFILE}
                component={EditSettingProfile}
            />
            <PrivateRoute
                exact
                path={ADD_NEW_EMPLOYEE}
                component={AddNewEmployee}
            />
            <PrivateRoute
                exact
                path={EMPLOYEE_DETAIL}
                component={EmployeeDetail}
            />
            <PrivateRoute exact path={CUSTOMER_LIST} component={CustomerList} />
            <PrivateRoute
                exact
                path={CUSTOMER_DETAIL}
                component={CustomerDetail}
            />
            <PrivateRoute
                exact
                path={ADD_NEW_CUSTOMER}
                component={AddNewCustomer}
            />
            <PrivateRoute
                exact
                path={EDIT_CUSTOMER}
                component={AddNewCustomer}
            />
            <PrivateRoute exact path={FBO_LIST} component={FBOList} />
            <PrivateRoute exact path={FBO_DETAIL} component={FBODetail} />
            <PrivateRoute exact path={ADD_FBO} component={AddFBO} />
            <PrivateRoute exact path={PARTNERS_LIST} component={PartnersList} />
            <PrivateRoute
                exact
                path={PARTNERS_DETAIL}
                component={PartnersDetail}
            />
            <PrivateRoute
                exact
                path={COMPANY_PROFILE}
                component={CompanyProfile}
            />
            <PrivateRoute exact path={ADD_PARTNERS} component={AddPartners} />

            <PrivateRoute exact path={BOOKING_LIST} component={BookingList} />
            <PrivateRoute
                exact
                path={BOOKING_DETAIL}
                component={BookingDetail}
            />
            <PrivateRoute
                exact
                path={BOOKING_INVOICE}
                component={BookingInvoice}
            />

            {/* Invoice UI. */}
            <PrivateRoute
                exact
                path={COMPANY_INVOICES}
                component={CompanyInvoiceList}
            />
            <PrivateRoute
                exact
                path={COMPANY_INVOICE_DETAIL}
                component={CompanyInvoiceDetail}
            />

            <PrivateRoute
                exact
                path={FLIGHT_PROMOTION_LIST}
                component={FlightPromotions}
            />
            <PrivateRoute
                exact
                path={TOP_RATED_AIRPORT_DETAIL}
                component={TopRatedAirportDetail}
            />
            <PrivateRoute
                exact
                path={ADD_TOP_RATED_AIRPORT}
                component={AddTopRatedAirport}
            />
            <PrivateRoute
                exact
                path={TOP_DESTINATION_DETAIL}
                component={TopDestinationDetail}
            />
            <PrivateRoute
                exact
                path={ADD_TOP_DESTINATION}
                component={AddTopDestinations}
            />

            <PrivateRoute
                exact
                path={NOTIFICATION_LIST}
                component={NotificationList}
            />
            <PrivateRoute
                exact
                path={COMPANY_SETTING}
                component={CompanySetting}
            />
            <PrivateRoute
                exact
                path={WHITELABEL_SETTING}
                component={WhiteLabelSetting}
            />
            <PrivateRoute
                exact
                path={EDIT_WHITELABEL}
                component={EditWhiteLabel}
            />
            {/* <Route exact path={REGISTER} component={Register} /> */}
        </Switch>
    );
};

export default RouterConfig;
