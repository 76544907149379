import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import {
    Row,
    Col,
    Image,
    Input as AntInput,
    message,
    Upload,
    Select,
    Tooltip
} from 'antd';
// import { DropDownInput } from 'components';
import CustomDropDown from './CustomDropDown';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatFonts, getBase64 } from 'utils';
import _ from 'lodash';
import { UPLOAD_MODEL, UPLOAD_IMAGE_TYPE } from 'redux/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { IMAGE_FILE_EXTENSIONS } from 'config/Constants';
import { uploadMorePhotosIcon, infoIosIcon } from 'assets/images/icons';
import { getFontList, uploadImage } from 'redux/actions/appActions';
import CustomModal from './CustomModal';
import UploadWLImage from './upload/UploadWLImage';
const { TextArea } = AntInput;
const { Option } = Select;

const Appearance = (props) => {
    const {
        whitelabelId = '',
        logo = '',
        setLogo,
        setLogoNewUrl,
        app_logo = '',
        set_app_logo,
        set_app_logo_new_url,
        backgroundColor = '#000000',
        setBackgroundColor,
        textColor = '#000000',
        setTextColor,
        buttonBackgroundColor = '#000000',
        setButtonBackgroundColor,
        buttonTextColor = '#000000',
        setButtonTextColor,
        highlightColor = '#000000',
        setHighlightColor,
        showBackgoundImage = false,
        setShowBackgoundImage,
        primaryColor = '#000000',
        setPrimaryColor,
        secondaryColor = '#000000',
        setSecondaryColor,
        lightBackgroundColor = '#000000',
        setLightBackgroundColor,
        secondaryLightBackgroundColor = '#000000',
        setSecondaryLightBackgroundColor,
        header_background_color = '#F7F9FD',
        set_header_background_color,
        footer_background_color = '#F7F9FD',
        set_footer_background_color,
        primaryFont = {},
        setPrimaryFont,
        secondaryFont = {},
        setSecondaryFont,
        isValidColors = false,
        setIsvalidColors
    } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const [showError, setShowError] = useState(false);

    const [validBackgroundColor, setValidBackgroundColor] = useState(true);
    const [validTextColor, setValidTextColor] = useState(true);
    const [validButtonBackgroundColor, setValidButtonBackgroundColor] =
        useState(true);
    const [validButtonTextColor, setValidButtonTextColor] = useState(true);
    const [validHighlightColor, setValidHighlightColor] = useState(true);
    const [validPrimaryColor, setValidPrimaryColor] = useState(true);
    const [validSecondaryColor, setValidSecondaryColor] = useState(true);
    const [validLightBackgroundColor, setValidLightBackgroundColor] =
        useState(true);
    const [
        validSecondaryLightBackgroundColor,
        setValidSecondaryLightBackgroundColor
    ] = useState(true);
    const [valid_header_background_color, set_valid_header_background_color] =
        useState(true);
    const [valid_footer_background_color, set_valid_footer_background_color] =
        useState(true);

    // const [photo, setPhoto] = useState(null);
    const [imageUrl, setImageUrl] = useState(logo || '');
    const [app_logo_url, set_app_logo_url] = useState(app_logo || '');

    const [fontList, setFontList] = useState([]);
    const [selectedPrimaryFont, setSelectedPrimaryFont] =
        useState('Avenir Next');
    const [selectedSecondaryFont, setSelectedSecondaryFont] = useState('Lato');
    const [googleFonts, setGoogleGonts] = useState([]);

    useEffect(() => {
        if (
            valid_footer_background_color &&
            valid_header_background_color &&
            validSecondaryColor &&
            validPrimaryColor &&
            validTextColor &&
            validBackgroundColor
        ) {
            setIsvalidColors(true);
        } else {
            setIsvalidColors(false);
        }
    }, [
        valid_footer_background_color,
        valid_header_background_color,
        validSecondaryColor,
        validPrimaryColor,
        validTextColor,
        validBackgroundColor
    ]);

    useEffect(() => {
        fetchFontList();
    }, []);

    useEffect(() => {
        const { variants = {} } = primaryFont;
        if (!_.isEmpty(primaryFont) && !_.isEmpty(variants)) {
            setSelectedPrimaryFont(primaryFont.family);
        } else {
            setSelectedPrimaryFont('Avenir Next');
        }
    }, [primaryFont]);

    useEffect(() => {
        const { variants = {} } = secondaryFont;
        if (!_.isEmpty(secondaryFont) && !_.isEmpty(variants)) {
            setSelectedSecondaryFont(secondaryFont.family);
        } else {
            setSelectedSecondaryFont('Lato');
        }
    }, [secondaryFont]);

    useEffect(() => {
        logo && setImageUrl(logo);
    }, [logo]);

    useEffect(() => {
        app_logo && set_app_logo_url(app_logo);
    }, [app_logo]);

    const reg = /^#[0-9A-F]{6}$/i;

    const fetchFontList = async () => {
        const result = await dispatch(getFontList(2000));
        if (result.success) {
            setGoogleGonts(result.data || []);
            const convertedFontList =
                result.data &&
                result.data.map((font) => {
                    return {
                        text: font.family || '',
                        value: font.family || ''
                    };
                });

            setFontList([
                {
                    text: 'Avenir Next',
                    value: 'Avenir Next'
                },
                ...convertedFontList
            ]);
        }
    };

    const uploadCompanyLogo = async (file) => {
        const result = await dispatch(
            uploadImage(
                whitelabelId,
                file,
                UPLOAD_MODEL.WHITELABEL,
                UPLOAD_IMAGE_TYPE.GALLERY
            )
        );
        if (result.success && result.image) {
            setLogoNewUrl(result.image.favicon || result.image.url || '');
            return true;
        } else {
            // message.error('Upload image fail. Please try again!');
            setShowError(true);
            return false;
        }
    };

    const uploadFavIcon = async (file) => {
        const result = await dispatch(
            uploadImage(
                whitelabelId,
                file,
                UPLOAD_MODEL.WHITELABEL,
                UPLOAD_IMAGE_TYPE.ICON
            )
        );
        if (result.success && result.image) {
            set_app_logo_new_url(result.image.url || '');
            return true;
        } else {
            setShowError(true);
            return false;
        }
    };

    const handleChange = async ({ file, fileList }) => {
        const result = await uploadCompanyLogo(file);
        if (result) {
            getBase64(file, (imageUrl) => {
                setImageUrl(imageUrl);
            });
        }
    };

    const handleChangeFavIcon = async ({ file, fileList }) => {
        const result = await uploadFavIcon(file);
        const index = fileList.length;
        if (result) {
            getBase64(fileList[index - 1].originFileObj, (imageUrl) => {
                set_app_logo_url(imageUrl);
            });
        }
    };

    const uploadButton = (
        <UploadSection>
            <img style={{ height: 16, width: 18 }} src={uploadMorePhotosIcon} />
            <UploadText style={{ marginTop: 8 }}>
                {t('UPLOAD_PHOTO').toUpperCase()}
            </UploadText>
        </UploadSection>
    );

    const uploaFavIcondButton = (
        <UploadSection>
            <img style={{ height: 16, width: 18 }} src={uploadMorePhotosIcon} />
            {/* <UploadText style={{ marginTop: 8 }}>
                {t('UPLOAD_PHOTO').toUpperCase()}
            </UploadText> */}
        </UploadSection>
    );

    // TODO: get proper font value for back-end

    return (
        <ContainerWrapper>
            <PayoutSection>
                <WhitelabelContainer>
                    <EachRow>
                        <GeneralSetting>{t('ADD_LOGO')}</GeneralSetting>
                    </EachRow>
                    <EachRow>
                        <Col>
                            <Row
                                align="stretch"
                                justify="space-between"
                                style={{ marginTop: 20 }}
                            >
                                <Col style={{ width: 266 }}>
                                    <Row>
                                        <TitleText>
                                            {t('COMPANY_LOGO')}
                                        </TitleText>
                                    </Row>
                                    <Row>
                                        <DescriptionText>
                                            {t('COMPANY_LOGO_DESCRIPTION')}
                                        </DescriptionText>
                                    </Row>
                                    <Row>
                                        {imageUrl ? (
                                            <ChangePhotoContainer>
                                                <ImageContainer>
                                                    <Image
                                                        src={imageUrl}
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'contain'
                                                        }}
                                                    />
                                                </ImageContainer>
                                                <UploadWLImage
                                                    accept={
                                                        IMAGE_FILE_EXTENSIONS
                                                    }
                                                    beforeUpload={() => false}
                                                    onChange={handleChange}
                                                    multiple={false}
                                                    showUploadList={false}
                                                    isCropImage
                                                >
                                                    <ChangePhotoButton>
                                                        <ChangePhotoText>
                                                            {t(
                                                                'CHANGE_PHOTO'
                                                            ).toUpperCase()}
                                                        </ChangePhotoText>
                                                    </ChangePhotoButton>
                                                </UploadWLImage>
                                            </ChangePhotoContainer>
                                        ) : (
                                            <UploadLogo
                                                accept={IMAGE_FILE_EXTENSIONS}
                                                beforeUpload={() => false}
                                                listType="picture-card"
                                                showUploadList={false}
                                                onChange={handleChange}
                                                multiple={false}
                                                isCropImage
                                            >
                                                {uploadButton}
                                            </UploadLogo>
                                        )}
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <TitleText>{t('FAVICON')}</TitleText>
                                        <Tooltip
                                            title={t('FAVICON_SUGGESTION')}
                                            placement="right"
                                        >
                                            <img
                                                src={infoIosIcon}
                                                style={{
                                                    width: 16,
                                                    height: 16,
                                                    marginLeft: 10
                                                }}
                                            />
                                        </Tooltip>
                                    </Row>
                                    <Row>
                                        <DescriptionText>
                                            {t('FAVICON_DESCRIPTION')}
                                        </DescriptionText>
                                    </Row>
                                    <Row>
                                        {app_logo_url ? (
                                            <ChangePhotoContainer>
                                                <ImageContainer
                                                    style={{
                                                        height: 64,
                                                        width: 64
                                                    }}
                                                >
                                                    <Image
                                                        src={app_logo_url}
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'contain'
                                                        }}
                                                    />
                                                </ImageContainer>
                                                <Upload
                                                    accept={'.png, .ico'}
                                                    beforeUpload={() => false}
                                                    onChange={
                                                        handleChangeFavIcon
                                                    }
                                                    multiple={false}
                                                    showUploadList={false}
                                                >
                                                    <ChangePhotoButton>
                                                        <ChangePhotoText>
                                                            {t(
                                                                'CHANGE_PHOTO'
                                                            ).toUpperCase()}
                                                        </ChangePhotoText>
                                                    </ChangePhotoButton>
                                                </Upload>
                                            </ChangePhotoContainer>
                                        ) : (
                                            <UploadFavicon
                                                accept={'.png, .ico'}
                                                beforeUpload={() => false}
                                                listType="picture-card"
                                                showUploadList={false}
                                                onChange={handleChangeFavIcon}
                                                multiple={false}
                                            >
                                                {uploaFavIcondButton}
                                            </UploadFavicon>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </EachRow>
                    {/* <EachRow style={{ marginTop: 40 }}>
                        <GeneralSetting>{t('COLOR_AND_STYLE')}</GeneralSetting>
                    </EachRow> */}
                    <EachRow style={{ marginTop: 0 }}>
                        <GeneralSettingsCol style={{ marginRight: 160 }}>
                            <EachRow style={{ marginTop: 40 }}>
                                <GeneralSetting>
                                    {t('COLOR_AND_STYLE')}
                                </GeneralSetting>
                            </EachRow>
                            <ColorRow align="middle" justify="space-between">
                                <Col>
                                    <TitleText>
                                        {t('BACKGOUND_COLOR')}
                                    </TitleText>
                                </Col>
                                <Col>
                                    <Row align="middle">
                                        <ColorInput
                                            value={backgroundColor}
                                            isValid={validBackgroundColor}
                                            onChange={(e) => {
                                                setBackgroundColor(
                                                    e.target.value
                                                );
                                                reg.test(e.target.value)
                                                    ? setValidBackgroundColor(
                                                          true
                                                      )
                                                    : setValidBackgroundColor(
                                                          false
                                                      );
                                            }}
                                        />
                                        <ColorPicker
                                            type="color"
                                            value={backgroundColor}
                                            onChange={(e) =>
                                                setBackgroundColor(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Row>
                                </Col>
                            </ColorRow>
                            <ColorRow align="middle" justify="space-between">
                                <Col>
                                    <TitleText>{t('TEXT_COLOR')}</TitleText>
                                </Col>
                                <Col>
                                    <Row align="middle">
                                        <ColorInput
                                            value={textColor}
                                            isValid={validTextColor}
                                            onChange={(e) => {
                                                setTextColor(e.target.value);
                                                reg.test(e.target.value)
                                                    ? setValidTextColor(true)
                                                    : setValidTextColor(false);
                                            }}
                                        />
                                        <ColorPicker
                                            type="color"
                                            value={textColor}
                                            onChange={(e) =>
                                                setTextColor(e.target.value)
                                            }
                                        />
                                    </Row>
                                </Col>
                            </ColorRow>
                            <ColorRow align="middle" justify="space-between">
                                <Col>
                                    <TitleText>{t('PRIMARY_COLOR')}</TitleText>
                                </Col>
                                <Col>
                                    <Row align="middle">
                                        <ColorInput
                                            value={primaryColor}
                                            isValid={validPrimaryColor}
                                            onChange={(e) => {
                                                setPrimaryColor(e.target.value);
                                                reg.test(e.target.value)
                                                    ? setValidPrimaryColor(true)
                                                    : setValidPrimaryColor(
                                                          false
                                                      );
                                            }}
                                        />
                                        <ColorPicker
                                            type="color"
                                            value={primaryColor}
                                            onChange={(e) =>
                                                setPrimaryColor(e.target.value)
                                            }
                                        />
                                    </Row>
                                </Col>
                            </ColorRow>
                            <ColorRow align="middle" justify="space-between">
                                <Col>
                                    <TitleText>
                                        {t('SECONDARY_COLOR')}
                                    </TitleText>
                                </Col>
                                <Col>
                                    <Row align="middle">
                                        <ColorInput
                                            value={secondaryColor}
                                            isValid={validSecondaryColor}
                                            onChange={(e) => {
                                                setSecondaryColor(
                                                    e.target.value
                                                );
                                                reg.test(e.target.value)
                                                    ? setValidSecondaryColor(
                                                          true
                                                      )
                                                    : setValidSecondaryColor(
                                                          false
                                                      );
                                            }}
                                        />
                                        <ColorPicker
                                            type="color"
                                            value={secondaryColor}
                                            onChange={(e) =>
                                                setSecondaryColor(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Row>
                                </Col>
                            </ColorRow>
                            <ColorRow align="middle" justify="space-between">
                                <Col>
                                    <TitleText>
                                        {t('HEADER_BACKGROUND_COLOR')}
                                    </TitleText>
                                </Col>
                                <Col>
                                    <Row align="middle">
                                        <ColorInput
                                            value={header_background_color}
                                            isValid={
                                                valid_header_background_color
                                            }
                                            onChange={(e) => {
                                                set_header_background_color(
                                                    e.target.value
                                                );
                                                reg.test(e.target.value)
                                                    ? set_valid_header_background_color(
                                                          true
                                                      )
                                                    : set_valid_header_background_color(
                                                          false
                                                      );
                                            }}
                                        />
                                        <ColorPicker
                                            type="color"
                                            value={header_background_color}
                                            onChange={(e) =>
                                                set_header_background_color(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Row>
                                </Col>
                            </ColorRow>
                            <ColorRow align="middle" justify="space-between">
                                <Col>
                                    <TitleText>
                                        {t('FOOTER_BACKGROUND_COLOR')}
                                    </TitleText>
                                </Col>
                                <Col>
                                    <Row align="middle">
                                        <ColorInput
                                            value={footer_background_color}
                                            isValid={
                                                valid_footer_background_color
                                            }
                                            onChange={(e) => {
                                                set_footer_background_color(
                                                    e.target.value
                                                );
                                                reg.test(e.target.value)
                                                    ? set_valid_footer_background_color(
                                                          true
                                                      )
                                                    : set_valid_footer_background_color(
                                                          false
                                                      );
                                            }}
                                        />
                                        <ColorPicker
                                            type="color"
                                            value={footer_background_color}
                                            onChange={(e) =>
                                                set_footer_background_color(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Row>
                                </Col>
                            </ColorRow>
                        </GeneralSettingsCol>
                        <GeneralSettingsCol>
                            <EachRow style={{ marginTop: 40 }}>
                                <GeneralSetting>
                                    {t('TEXT_STYLE')}
                                </GeneralSetting>
                            </EachRow>
                            <EachRow style={{ marginTop: 5 }}>
                                <SectionIntro>
                                    {t('FONT_SELECTION_INTRO')}
                                </SectionIntro>
                            </EachRow>
                            <EachRow style={{ flexDirection: 'column' }}>
                                <Label>{t('PRIMARY_FONT').toUpperCase()}</Label>
                                <CustomDropDown
                                    showSearch={true}
                                    placeholder={t('SELECT_FONT')}
                                    data={fontList}
                                    width="370px"
                                    pickedData={selectedPrimaryFont}
                                    onChange={(value) => {
                                        if (value === 'Avenir Next') {
                                            setPrimaryFont({});
                                        } else {
                                            const selectedFont =
                                                googleFonts.find(
                                                    (font) =>
                                                        font.family === value
                                                );
                                            if (selectedFont) {
                                                const formatedFont =
                                                    formatFonts(selectedFont);
                                                setPrimaryFont(formatedFont);
                                            }
                                        }
                                    }}
                                />
                            </EachRow>
                            <EachRow style={{ flexDirection: 'column' }}>
                                <Label>
                                    {t('SECONDARY_FONT').toUpperCase()}
                                </Label>
                                <CustomDropDown
                                    showSearch={true}
                                    placeholder={t('SELECT_FONT')}
                                    data={fontList}
                                    width="370px"
                                    pickedData={selectedSecondaryFont}
                                    onChange={(value) => {
                                        if (value === 'Lato') {
                                            setSecondaryFont({});
                                        } else {
                                            const selectedFont =
                                                googleFonts.find(
                                                    (font) =>
                                                        font.family === value
                                                );
                                            if (selectedFont) {
                                                const formatedFont =
                                                    formatFonts(selectedFont);
                                                setSecondaryFont(formatedFont);
                                            }
                                        }
                                    }}
                                />
                            </EachRow>
                        </GeneralSettingsCol>
                    </EachRow>
                </WhitelabelContainer>
            </PayoutSection>
            <CustomModal
                visibleModal={showError}
                errorMessage={'Upload image fail. Please try again!'}
                buttonTitle={t('OK').toUpperCase()}
                onOk={() => setShowError(false)}
                cancelModal={() => setShowError(false)}
            />
        </ContainerWrapper>
    );
};

export default Appearance;

const DescriptionText = styled.span`
    color: #6c6f76;
    font-family: AvenirNext-medium;
    font-size: 11px;
    line-height: 13px;
    margin-top: 5px;
    margin-bottom: 5px;
`;

const Label = styled.span`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    margin-bottom: 10px;
`;

const ColorRow = styled(Row)`
    margin-top: 20px;
    width: 470px;
`;

const ColorInput = styled(AntInput)`
    border: ${(props) =>
        props.isValid ? '1px solid #dadadb' : '1px solid red'};
    /* border: 1px solid red; */
    border-radius: 2px;
    width: 150px;
    height: 44px;
`;

const ColorPicker = styled.input`
    height: 44px;
    width: 44px;
    cursor: pointer;
    margin-left: 10px;
    -webkit-appearance: none;
    border-radius: 6px;
    &::-webkit-color-swatch {
        border: none;
        border-radius: 6px;
        padding: 0;
    }
    &::-webkit-color-swatch-wrapper {
        border: none;
        border-radius: 6px;
        padding: 0;
    }
`;

const UploadLogo = styled(UploadWLImage)`
    border: 1px dashed #65686f;
    border-radius: 4px;
    width: 130px;
    height: 82px;
    margin-top: 10px;
`;

const UploadFavicon = styled(Upload)`
    border: 1px dashed #65686f;
    border-radius: 4px;
    width: 64px;
    height: 64px;
    margin-top: 10px;
`;

const UploadText = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.84px;
    line-height: 14px;
`;

const UploadSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ChangePhotoText = styled.span`
    color: #16161b;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
`;

const ChangePhotoButton = styled.button`
    background: transparent;
    border: 1px solid rgba(18, 18, 22, 0.2);
    border-radius: 2px;
    margin-top: 20px;
`;

const ChangePhotoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 10px;
`;

const ImageContainer = styled.div`
    border: 1px solid #dadadb;
    border-radius: 4px;
    /* padding: 20px 5px; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 82px;
    margin-right: 15px;
    .ant-image {
        height: 100%;
        width: 100%;
    }
`;

const GeneralSettingsCol = styled(Col)``;

const GeneralSetting = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 17px;
`;

const DescriptionArea = styled(TextArea)`
    border: 1px solid rgba(36, 36, 45, 0.15);
    border-radius: 4px;
    margin-top: 10px;
    width: 770px;
`;

const TitleText = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 18px;
    text-transform: uppercase;
`;

const WhitelabelContainer = styled.div`
    padding-left: 51px;
`;

const EachRow = styled(Row)`
    margin-top: 20px;
`;

const SectionIntro = styled.span`
    color: #65686f;
    font-family: Lato;
    font-size: 13px;
    line-height: 23px;
    max-width: 1089px;
    white-space: pre-wrap;
`;

const ContainerWrapper = styled.div`
    padding-bottom: 40px;
    border-radius: 4px;
    /* margin-top: 14px; */
    .ant-divider {
        border-top: 1px solid rgba(14, 14, 18, 0.08);
    }
    .ant-divider-horizontal {
        margin: 20px 0;
    }
    input[type='color' i] {
        padding: 0;
        border: none;
        background: none;
        border-radius: 6px;
    }
`;

const PayoutSection = styled.div`
    padding-right: 20px;
    padding-bottom: 40px;
    background-color: #ffffff;
    border-radius: 4px;
`;
