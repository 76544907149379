import React from 'react';
import { Input, Table, Avatar, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { Header, ButtonCustom, localeEmptyTable } from 'components';
import { ADD_FBO, FBO_DETAIL } from 'routes/main';
import {
    searchIcon,
    confirmedIcon,
    imageIcon,
    createdIcon
} from 'assets/images/icons';
import {
    CustomerContainer,
    NameSectionButton,
    SearchSection,
    DetailSection
} from './styles';
import {
    EmailCell,
    ItemCell,
    AirportCell,
    ColumnHeader,
    TableSection,
    Icon
} from 'styles/shareStyles';
import { PARTNERSHIP_TYPE } from 'redux/Constants';
import _ from 'lodash';
import { renderAddress } from 'utils';
import { PendingIcon } from 'components/SvgIcons';
import styled from 'styled-components';
import { ButtonType } from 'components/Antd/Button';
import moment from 'moment';

const logoSize = 64;

const PartnershipStatus = ({ val, title }) => {
    if (val === PARTNERSHIP_TYPE['PENDING']) {
        return (
            <Wrapper style={{ display: 'flex' }}>
                <PendingIcon />
                <ItemCell style={{ marginLeft: 10 }}>{title}</ItemCell>
            </Wrapper>
        );
    }

    if (val === PARTNERSHIP_TYPE['CONNECTED']) {
        return (
            <Wrapper style={{ display: 'flex' }}>
                <Icon src={confirmedIcon} />
                <ItemCell style={{ marginLeft: 10 }}>{title}</ItemCell>
            </Wrapper>
        );
    }

    return (
        <Wrapper style={{ display: 'flex' }}>
            <Icon src={createdIcon} />
            <ItemCell style={{ marginLeft: 10 }}>{title}</ItemCell>
        </Wrapper>
    );
};

const template = (props) => {
    const { navigate, fboList, key, setKey } = props;
    const { t, i18n } = useTranslation();
    const SearchIcon = <img src={searchIcon} />;

    const onClickDetail = (val) => {
        navigate(FBO_DETAIL, val.id, val);
    };

    const columns = [
        {
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: '30%',
            sorter: (a, b) => {
                if (a.name?.toUpperCase() < b.name?.toUpperCase()) {
                    return -1;
                }
                if (a.name?.toUpperCase() > b.name?.toUpperCase()) {
                    return 1;
                }
                return 0;
            },
            title: function titleHeader() {
                return <ColumnHeader>{t('FBO')}</ColumnHeader>;
            },
            render: function eachItemName(name, row) {
                return (
                    <NameSectionButton onClick={() => onClickDetail(row)}>
                        {row.image ? (
                            <Avatar
                                size={logoSize}
                                src={row.image?.lowres?.url}
                                style={{
                                    marginRight: 15,
                                    minWidth: logoSize,
                                    minHeight: logoSize
                                }}
                            />
                        ) : (
                            <Avatar
                                size={logoSize}
                                style={{
                                    backgroundColor: '#F0F3F9',
                                    marginRight: 15,
                                    minWidth: logoSize,
                                    minHeight: logoSize
                                }}
                            >
                                <Icon
                                    src={imageIcon}
                                    style={{
                                        width: 32,
                                        height: 28
                                    }}
                                />
                            </Avatar>
                        )}
                        <div
                            style={{
                                alignSelf: 'center'
                            }}
                        >
                            <FBOName>{name ? name.toUpperCase() : '_'}</FBOName>
                            {key && row.head_quarter && (
                                <HeadQuarter>
                                    {t('HEADQUATER').toUpperCase()}
                                </HeadQuarter>
                            )}
                        </div>
                    </NameSectionButton>
                );
            }
        },
        {
            dataIndex: 'email',
            key: 'email',
            width: '15%',
            sorter: (a, b) => {
                if (
                    a.contact?.email?.toUpperCase() <
                    b.contact?.email?.toUpperCase()
                ) {
                    return -1;
                }
                if (
                    a.contact?.email?.toUpperCase() >
                    b.contact?.email?.toUpperCase()
                ) {
                    return 1;
                }
                return 0;
            },
            title: function titleHeader() {
                return <ColumnHeader>{t('EMAIL_ADDRESS')}</ColumnHeader>;
            },
            render: function eachItemEmail(email, row) {
                return (
                    <EmailCell>
                        {row.contact ? t(row.contact.email) : '_'}
                    </EmailCell>
                );
            }
        },
        {
            dataIndex: 'phone',
            key: 'phone',
            width: '15%',
            title: function titleHeader() {
                return <ColumnHeader>{t('PHONE_NUMBER')}</ColumnHeader>;
            },
            render: function eachItemPhone(contact, row) {
                return (
                    <ItemCell>
                        {row.contact && row.contact.phone
                            ? row.contact.phone.replace(/\s/g, '')
                            : '_'}
                    </ItemCell>
                );
            }
        },
        {
            dataIndex: 'address',
            key: 'address',
            width: '15%',
            title: function titleHeader() {
                return <ColumnHeader>{t('ADDRESS')}</ColumnHeader>;
            },
            render: function eachItemAddress(address) {
                return <ItemCell>{renderAddress(address)}</ItemCell>;
            }
        },
        {
            dataIndex: 'airport',
            key: 'airport',
            width: '15%',
            sorter: (a, b) => {
                if (
                    a.airport?.icao_code.toUpperCase() <
                    b.airport?.icao_code.toUpperCase()
                ) {
                    return -1;
                }
                if (
                    a.airport?.icao_code.toUpperCase() >
                    b.airport?.icao_code.toUpperCase()
                ) {
                    return 1;
                }
                return 0;
            },
            title: function titleHeader() {
                return <ColumnHeader>{t('AIRPORT')}</ColumnHeader>;
            },
            render: function eachItemAirport(airport) {
                return (
                    <AirportCell style={{ minWidth: '120px' }}>
                        {airport ? airport.icao_code : '_'}
                    </AirportCell>
                );
            }
        },
        {
            dataIndex: 'partnership',
            key: 'partnership',
            width: '15%',
            title: function titleHeader() {
                return <ColumnHeader>{t('PARTNERSHIP')}</ColumnHeader>;
            },
            render: function eachItemPartnership(partnership) {
                return (
                    <PartnershipStatus
                        val={partnership}
                        title={t(`FBO_STATUS_${partnership}`)}
                    />
                );
            }
        },
        {
            dataIndex: 'created_at',
            width: '15%',
            sorter: (a, b) =>
                moment(a.created_at).unix() - moment(b.created_at).unix(),
            key: 'created_at',
            title: function titleHeader() {
                return <ColumnHeader>{t('CREATED_AT')}</ColumnHeader>;
            },
            render: function eachItemPhone(created_at, row) {
                return (
                    <ItemCell>
                        {created_at
                            ? moment(created_at).format('MMM Do, YYYY')
                            : '_'}
                    </ItemCell>
                );
            }
        },
        {
            key: 'action',
            title: '',
            width: '15%',
            render: function setAction(val) {
                return (
                    <DetailSection>
                        <ButtonCustom
                            size="small"
                            btnType={ButtonType.SECONDARY}
                            title={t('VIEW_DETAILS').toUpperCase()}
                            onClick={(e) => onClickDetail(val)}
                        />
                    </DetailSection>
                );
            }
        }
    ];

    return (
        <CustomerContainer>
            <Header
                title={t('FBO')}
                description={t('LIST_OF_FBO')}
                headerRight={() => (
                    <ButtonCustom
                        btnType={ButtonType.SECONDARY}
                        onClick={() => navigate(ADD_FBO)}
                        title={t('ADD_NEW_FBO').toUpperCase()}
                        width="190px"
                    />
                )}
            />
            <SearchSection>
                <Row>
                    <Col xl={6} lg={8} md={10} sm={12} xs={12}>
                        <Input
                            placeholder={t('SEARCH')}
                            allowClear
                            suffix={SearchIcon}
                            onChange={(e) => props.onSearch(e)}
                        />
                    </Col>
                </Row>
            </SearchSection>

            <TableSection>
                <Table
                    columns={columns}
                    dataSource={fboList}
                    pagination={{ showSizeChanger: false }}
                    style={{ marginTop: 10 }}
                    scroll={{ x: 1000 }}
                    locale={localeEmptyTable()}
                />
            </TableSection>
        </CustomerContainer>
    );
};

export { template };

const Wrapper = styled.div`
    display: 'flex';
`;

const HeadQuarter = styled.div`
    color: #19c0ff;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
    margin-top: 5px;
`;

const FBOName = styled.div`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.8px;
    line-height: 16px;
    text-align: left;
`;
