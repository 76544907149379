import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import {
    Col,
    Row,
    Input,
    AutoComplete,
    Switch,
    Select,
    InputNumber
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Header, Popup, ButtonCustom } from '../../../components';
import {
    HeaderInfo,
    CountryItem,
    AircraftItem
} from '../../../components/Cards';
import '../services.css';
import { CustomerDetailContainer, BottomContainer, Label } from '../styles';
import _ from 'lodash';
import { getBase64 } from '../../../utils';
import { itemType } from '../../../config/Constants';
import { aircraftIcon, availabilityIcon } from '../../../assets/images/icons';
import { SERVICES } from '../../../routes/main';
import { UploadComponent } from '../../../components';
import { useSelector } from 'react-redux';
import { iconArrowDown } from 'assets/images/icons';
import { getWindowDimensions } from 'utils';

const { TextArea } = Input;
const { Option } = Select;

const template = (props) => {
    const { expandedSidebar } = useSelector(
        (state) => state.settingReducer || false
    );
    const { onSubmit, onSearchCountries, onSearchAircrafts, visibleModal } =
        props;

    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const { t, i18n } = useTranslation();

    const [loading, setLoading] = useState(false);

    const [photo, setPhoto] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [itemName, setItemName] = useState('');
    const [category, setCategory] = useState('');
    const [description, setDescription] = useState('');
    const [pricing, setPricing] = useState('USD'); // USD or EUR
    const [pricePerItem, setPricePerItem] = useState(0);

    const [avaiAllAircrafts, setAvaiAllAircrafts] = useState(true);
    const [avaiAircrafts, setAvaiAircrafts] = useState([]);

    const [avaiAllCountries, setAvaiAllCountries] = useState(true);
    const [avaiCountries, setAvaiCountries] = useState([]);

    const handleChange = ({ file, fileList }) => {
        setPhoto(file);

        const index = fileList.length;
        getBase64(fileList[index - 1].originFileObj, (imageUrl) => {
            setImageUrl(imageUrl);
        });
    };

    const onValidateForm = () => {
        if (!imageUrl || !itemName || !category || !description || !pricing) {
            return false;
        }
        return true;
    };

    const onSubmitForm = () => {
        try {
            const valid = onValidateForm();
            if (!valid) {
                // TODO: create display error in Input field
                alert('Invalid data!');
                return;
            }

            let countries = avaiCountries.map((v) => v.short_code);
            let aircraftsCode = avaiAircrafts.map((v) => v.id);

            let params = {
                all_aircrafts_available: avaiAllAircrafts,
                all_countries_available: avaiAllCountries,
                photo: photo,
                serving: 'per person',
                item_type: category,
                availability: {
                    countries: countries,
                    aircrafts: aircraftsCode
                },
                available: true,
                description: description,
                name: itemName,
                price: {
                    currency: pricing,
                    value: +pricePerItem
                }
            };

            onSubmit(params);
        } catch (e) {}
    };

    const [options, setOptions] = useState([]);
    const [aircraft, setAircraft] = useState('');
    const [search, setSearch] = useState([]);

    const onSelect = (value) => {
        let isExist = avaiAircrafts.some((v) => v.name === value);
        if (isExist) return;

        let data = search.find((v) => v.name === value);
        let values = avaiAircrafts;
        values.push(data);

        setAvaiAircrafts([...values]);
        setAircraft('');
    };

    const onRemove = (val) => {
        let values = avaiAircrafts.filter((v) => v.id !== val.id);
        setAvaiAircrafts(values);
    };

    const searchResult = (results) =>
        results.map((val, i) => {
            let text = val.name.toUpperCase() + ' - ' + val.tail_number;
            return {
                value: val.name,
                label: (
                    <DropdownWrapper>
                        <DropdownText>{text}</DropdownText>
                    </DropdownWrapper>
                )
            };
        });

    const handleSearch = async (value) => {
        const result = await onSearchAircrafts(value);
        setSearch(result || []);
        setOptions(value ? searchResult(result) : []);
    };

    const [countryOptions, setCountryOptions] = useState([]);
    const [country, setCountry] = useState('');
    const [searchCountries, setSearchCountries] = useState([]);

    const onSelectCountries = (value) => {
        let isExist = avaiCountries.some((v) => v.name === value);
        if (isExist) return;

        let data = searchCountries.find((v) => v.name === value);
        let values = avaiCountries;
        values.push(data);

        setAvaiCountries([...values]);
        setCountry('');
    };

    const onRemoveCountries = (val) => {
        let values = avaiCountries.filter((v) => v.id !== val.id);
        setAvaiCountries(values);
    };

    const searchResultCountries = (results) =>
        results.map((val, i) => {
            return {
                value: val.name,
                label: (
                    <DropdownWrapper>
                        <DropdownText>{val.name}</DropdownText>
                    </DropdownWrapper>
                )
            };
        });

    const handleSearchCountries = async (value) => {
        const result = await onSearchCountries(value);
        setSearchCountries(result || []);
        setCountryOptions(value ? searchResultCountries(result) : []);
    };

    const handleChangeType = (val) => {
        setCategory(val);
    };

    return (
        <>
            <CustomerDetailContainer>
                <Header
                    title={i18n.t('SERVICE_ITEMS')}
                    description={i18n.t('ADD_SERVICE_ITEM')}
                />
            </CustomerDetailContainer>
            <ContainerWrapper expandedSidebar={expandedSidebar}>
                <HeaderInfo title={i18n.t('SERVICE_INFO').toUpperCase()} />
                <UploadSection>
                    <UploadComponent
                        imageUrl={imageUrl}
                        handleChange={handleChange}
                        loading={loading}
                    />
                </UploadSection>

                <Row className="add-service-item">
                    <Col lg="6" sm="6" xs="12">
                        <Label>
                            {i18n.t('SERVICE_ITEM_NAME').toUpperCase()}
                        </Label>
                        <Input
                            onChange={(val) => setItemName(val.target.value)}
                            className="address service-name"
                            placeholder={i18n.t('SERVICE_ITEM_NAME')}
                            value={itemName}
                        />
                    </Col>
                </Row>
                <Row className="add-customer-item">
                    <Col lg="6" sm="6" xs="12">
                        <Label>{i18n.t('TYPE').toUpperCase()}</Label>
                        <Select
                            suffixIcon={<DropDownIcon src={iconArrowDown} />}
                            className="ant-select-custom"
                            value={category || null}
                            style={{
                                height: '50px',
                                border: 'none'
                            }}
                            onChange={handleChangeType}
                            placeholder={
                                <SelectPlaceholder>
                                    {t('CHOOSE_TYPE')}
                                </SelectPlaceholder>
                            }
                        >
                            {itemType.map((v, i) => {
                                return (
                                    <Option key={i} value={v.code}>
                                        {i18n.t(v.code).toUpperCase()}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Col>
                </Row>
                <Row className="add-customer-item">
                    <Col lg="6" sm="6" xs="12">
                        <Label>{i18n.t('DESCRIPTION').toUpperCase()}</Label>
                        <TextArea
                            onChange={(val) => setDescription(val.target.value)}
                            className="address service-description-textarea"
                            rows={4}
                            placeholder={i18n.t('DESCRIPTION')}
                            value={description}
                        />
                    </Col>
                </Row>
                <Row className="add-customer-item">
                    <Col lg="3" sm="3" xs="12">
                        <Label>{i18n.t('PRICING').toUpperCase()}</Label>
                        <Input
                            className="base left input-add-item"
                            placeholder={i18n.t('PRICING')}
                            value={pricing}
                        />
                    </Col>
                    <Col lg="3" sm="3" xs="12">
                        <Label>{i18n.t('PRICE_PER_ITEM').toUpperCase()}</Label>
                        <InputNumber
                            min={0}
                            formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(val) => setPricePerItem(val)}
                            className="base input-add-item"
                            value={pricePerItem}
                        />
                    </Col>
                </Row>

                <div className="section-padding">
                    <HeaderInfo
                        title={i18n.t('AIRCRAFTS').toUpperCase()}
                        icon={<img src={aircraftIcon} />}
                    />
                </div>
                <Row style={{ marginTop: 15 }}>
                    <ItemWrapper className="add-customer-item">
                        <div style={{ width: 250, paddingRight: 50 }}>
                            <ItemTitle>
                                {i18n
                                    .t('AVAILABLE_FOR_ALL_AIRCRAFTS')
                                    .toUpperCase()}
                            </ItemTitle>
                        </div>
                        <Switch
                            checked={avaiAllAircrafts}
                            style={{ marginLeft: 5 }}
                            onChange={() =>
                                setAvaiAllAircrafts(!avaiAllAircrafts)
                            }
                        />
                    </ItemWrapper>
                </Row>

                {!avaiAllAircrafts && (
                    <Wrapper className="add-customer-item">
                        <TitleSection>
                            {i18n.t('CHOOSE_AIRCRAFTS')}
                        </TitleSection>
                        <Row>
                            <Col lg="3" sm="3" xs="12">
                                <AutoComplete
                                    dropdownMatchSelectWidth={252}
                                    style={{
                                        width:
                                            windowDimensions.width > 768
                                                ? 370
                                                : 300,
                                        margin: 15,
                                        marginLeft: 0
                                    }}
                                    options={options}
                                    onSelect={onSelect}
                                    onSearch={handleSearch}
                                    value={aircraft}
                                >
                                    <Input
                                        onChange={(val) =>
                                            setAircraft(val.target.value)
                                        }
                                        className="base"
                                        placeholder={i18n.t('SEARCH_AIRCRAFTS')}
                                    />
                                </AutoComplete>
                            </Col>
                        </Row>
                        {avaiAircrafts.map((val, i) => {
                            return (
                                <AircraftItem
                                    onRemove={onRemove}
                                    val={val}
                                    key={i}
                                    isFinal={avaiAircrafts.length === i + 1}
                                />
                            );
                        })}
                    </Wrapper>
                )}
                <div className="section-padding">
                    <HeaderInfo
                        title={i18n.t('AVAILABILITY').toUpperCase()}
                        icon={<img src={availabilityIcon} />}
                    />
                </div>
                <Row style={{ marginTop: 15 }}>
                    <ItemWrapper className="add-customer-item">
                        <div style={{ width: 250, paddingRight: 50 }}>
                            <ItemTitle>
                                {i18n
                                    .t('AVAILABLE_FOR_ALL_COUNTRIES')
                                    .toUpperCase()}
                            </ItemTitle>
                        </div>
                        <Switch
                            onChange={() =>
                                setAvaiAllCountries(!avaiAllCountries)
                            }
                            checked={avaiAllCountries}
                            style={{ marginLeft: 5 }}
                        />
                    </ItemWrapper>
                </Row>
                {!avaiAllCountries && (
                    <Wrapper className="add-customer-item">
                        <TitleSection>
                            {i18n.t('ADD_AVAILABLE_COUNTRIES')}
                        </TitleSection>
                        <Row>
                            <Col lg="3" sm="3" xs="12">
                                <AutoComplete
                                    className="ant-auto-complete-custom"
                                    dropdownMatchSelectWidth={252}
                                    style={{
                                        width:
                                            windowDimensions.width > 768
                                                ? 370
                                                : 300,
                                        margin: 15,
                                        marginLeft: 0
                                    }}
                                    options={countryOptions}
                                    onSelect={onSelectCountries}
                                    onSearch={handleSearchCountries}
                                    value={country}
                                >
                                    <Input
                                        onChange={(val) =>
                                            setCountry(val.target.value)
                                        }
                                        className="base"
                                        placeholder={i18n.t('SEARCH_COUNTRIES')}
                                    />
                                </AutoComplete>
                            </Col>
                        </Row>
                        {avaiCountries.map((val, i) => {
                            return (
                                <CountryItem
                                    onRemove={onRemoveCountries}
                                    val={val}
                                    key={i}
                                    isFinal={avaiCountries.length === i + 1}
                                />
                            );
                        })}
                    </Wrapper>
                )}
            </ContainerWrapper>
            <BottomContainer>
                <ButtonCustom
                    width="220px"
                    btnType="primary"
                    size="large"
                    title={i18n.t('SUBMIT_SERVICE_ITEM').toUpperCase()}
                    onClick={() => onSubmitForm()}
                    disabled={!onValidateForm()}
                />
            </BottomContainer>
            <Popup
                cancelModal={() => {
                    props.cancelModal();
                }}
                onOk={() => {
                    props.navigate(SERVICES);
                }}
                visibleModal={visibleModal}
                successText={t('SERVICE_SUCCESSFULLY_ADDED').toUpperCase()}
                buttonTitle={t('VIEW_SERVICE_LIST').toUpperCase()}
            />
        </>
    );
};

const DropDownIcon = styled.img`
    height: 15px;
    width: 15px;
`;

const UploadSection = styled.div`
    margin-left: 50px;
    margin-top: 20px;
`;

const DropdownWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const DropdownText = styled.span``;

const ContainerWrapper = styled.div`
    background-color: #fff;
    border-radius: 4px;
    /* margin-left: 40px;
    margin-right: 40px; */
    margin: 0 min(40px, 4%);
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 40px;
    .right {
        margin-left: 30px;
    }
    .left {
        margin-right: 30px;
    }
    .input-add-item {
        /* border: 1px solid rgba(36, 36, 45, 0.15); */
        border-radius: 3px;
        width: 370px;
        height: 50px;
        padding-top: 8px;
    }
    .base {
        /* border: 1px solid rgba(36, 36, 45, 0.15); */
        border-radius: 3px;
        width: 40vw;
        max-width: 370px;
        min-width: 300px;
        height: 50px;
        /* margin-bottom: 10px; */
    }
    .address {
        /* border: 1px solid rgba(36, 36, 45, 0.15); */
        border-radius: 3px;
        width: 770px;
        height: 50px;
        /* margin-bottom: 10px; */
    }
    .ant-select-custom {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: 'tnum', 'tnum';
        position: relative;
        display: inline-block;
        cursor: pointer;
        width: 370px;
        border: 1px solid rgba(36, 36, 45, 0.15);
        border-radius: 3px;
        height: 50px;
    }
    .ant-select-selector {
        border: none;
        height: 50px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        width: 100%;
        height: 50px;
        padding: 0 11px;
    }
    .ant-select-selection-item {
        align-self: center;
    }
    .ant-select-selection-placeholder {
        align-self: center;
    }
    .service-description-textarea {
        max-width: 770px;
    }
    @media (max-width: 1024px) {
        .service-name {
            width: 370px;
        }
        .service-description-textarea {
            width: ${(props) => (props.expandedSidebar ? '370px' : '770px')};
        }
        .right {
            margin-left: ${(props) => (props.expandedSidebar ? '0' : '30px')};
        }
    }
    @media (max-width: 768px) {
        .service-name {
            width: 300px;
        }
        .ant-select-custom {
            width: 300px;
        }
        .service-description-textarea {
            width: 300px;
        }
        .input-add-item {
            width: 300px;
        }
        .base {
            width: 300px !important;
        }
    }
`;

const ItemWrapper = styled.div`
    min-width: 182px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ItemTitle = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 18px;
    text-align: left;
    /* width: 180px; */
    /* margin-right: 50px; */
`;

const TitleSection = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 17px;
    text-align: left;
    margin-top: 40px;
    /* margin-left: 20px; */
`;

const SelectPlaceholder = styled.span`
    color: #8e929a;
    font-family: Lato;
    font-size: 14px;
    line-height: 17px;
`;

const Wrapper = styled.div`
    margin-top: 10px;
`;

export { template };
