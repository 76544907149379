import React, { useState, useEffect } from 'react';
import { template as tmpl } from './EditContainer.tmpl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    updateEmployee,
    searchCountries,
    uploadImage,
    validatePassword,
    requestDocumentAuth,
    getIdentification,
    uploadIdentification,
    updateIdentificationDoc
} from 'redux/actions/appActions';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
    UPLOAD_MODEL,
    UPLOAD_IMAGE_TYPE,
    UPDATE_USER_INFORMATION,
    SHOW_LOADING,
    HIDE_LOADING,
    SETTING_SHOW_MODAL
} from 'redux/Constants';
import _ from 'lodash';

const EditProfileLogic = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const {
        user = {},
        companies = [],
        updateEmployee,
        searchCountries,
        uploadImage,
        validatePassword,
        requestDocumentAuth,
        getIdentification,
        updateIdentificationDoc,
        uploadIdentification
    } = props;
    const [userInfo, setUserInfo] = useState({});
    const [identificationImage, setIdentificationImage] = useState('');

    useEffect(() => {
        if (!_.isEmpty(user)) {
            setUserInfo(user);
        }
    }, [user]);

    const navigate = (route) => {
        props.history.push(route);
    };

    const onSubmit = async (data) => {
        const rs = await updateEmployee(userInfo.id, data);
        if (rs && rs.isConnected) {
            let modal = {
                show: true,
                title: t('UPDATE_PROFILE_SETTING_SUCCESS'),
                buttonTitle: 'OK'
            };
            dispatch({ type: 'SETTING_SHOW_MODAL', data: modal });
        }
    };

    const onSearchCountries = async (key) => {
        const rs = await searchCountries(key);
        if (rs && rs.success) {
            return rs.countries || [];
        }
    };

    const onSubmitPreferences = () => {};

    const onSubmitPayment = () => {};

    const updateProfileImage = async ({ file, fileList }) => {
        dispatch({ type: SHOW_LOADING });

        let rs = await uploadImage(
            userInfo?.id,
            file,
            UPLOAD_MODEL.USER,
            UPLOAD_IMAGE_TYPE.MAIN
        );
        if (rs && rs.success && rs.url) {
            dispatch({
                type: UPDATE_USER_INFORMATION,
                payload: {
                    image: rs.url
                }
            });
        }
        
        setTimeout(() => {
            dispatch({ type: HIDE_LOADING });
        }, 300);
    };

    const getAuthenIdentity = async (password, getImage = false) => {
        const rs = await validatePassword(password);
        if (rs && rs.success && getImage) {
            let requestDoc = await requestDocumentAuth(user?.id, true);
            if (requestDoc && requestDoc.success) {
                const imageUrl = await getIdentification(
                    requestDoc?.data?.id
                );
                setIdentificationImage(imageUrl);
            }
        }

        return rs;
    };

    const onUploadIdentification = async (data = {}) => {
        try {
            let rs = await updateIdentificationDoc(user.id, data);
            if (!rs.success) {
                alert('Upload identification failed!');
                return;
            }
    
            // add params imageUrl and update new item
            rs = await uploadIdentification(user.id, data.photo);
            if (!rs.success) {
                alert('Upload identification failed!');
                return;
            }

            dispatch({
                type: UPDATE_USER_INFORMATION,
                payload: {
                    identity: {
                        ...user.identity,
                        identified: true,
                        identity_document: {
                            document_type: data.document_type,
                            document_number: data.document_number,
                            issue_date: data.issue_date,
                            expiration_date: data.expiration_date,
                        }
                    }
                }
            });
    
            let modal = {
                show: true,
                type: 'success',
                title: t('IDENTIFICATION_SUCCESSFULLY_UPLOAD'),
                buttonTitle: t('CONFIRM').toUpperCase()
            };
            dispatch({ type: SETTING_SHOW_MODAL, data: modal });
        } catch (e) {}
    };

    const initProps = {
        onSearchCountries: onSearchCountries,
        userInfo: userInfo,
        identificationImage: identificationImage,
        companies: companies,
        onSubmit: onSubmit,
        onSubmitPreferences: onSubmitPreferences,
        onSubmitPayment: onSubmitPayment,
        updateProfileImage: updateProfileImage,
        getAuthenIdentity: getAuthenIdentity,
        uploadIdentification: onUploadIdentification,
        navigate: navigate
    };

    return tmpl && tmpl({ ...initProps });
};

class EditProfileContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <EditProfileLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { userReducer, companyReducer } = state;
    const { activeCompany, companies } = companyReducer;
    return {
        user: userReducer,
        companies: companies,
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateEmployee: (id, data) => dispatch(updateEmployee(id, data)),
        searchCountries: (key) => dispatch(searchCountries(key)),
        validatePassword: (password) => dispatch(validatePassword(password)),
        requestDocumentAuth: (userId, showDocumentId) => dispatch(requestDocumentAuth(userId, showDocumentId)),
        uploadIdentification: (userId, photo) =>
            dispatch(uploadIdentification(userId, photo)),
        getIdentification: (id) => dispatch(getIdentification(id)),
        updateIdentificationDoc: (userId, data) =>
            dispatch(updateIdentificationDoc(userId, data)),
        uploadImage: (id, photo, model, type) =>
            dispatch(uploadImage(id, photo, model, type))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(EditProfileContainer)
);
