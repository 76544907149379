import React, { useEffect, useState } from 'react';
import { template as tmpl } from './FlightPromotions.tmpl';
import { connect } from 'react-redux';
import { updateTopDestination, updateTopRatedAirport } from 'redux/actions/airportAction';

const FlightPromotionsLogic = (props) => {
    const { activeCompany, updateTopDestination, updateTopRatedAirport } = props;
     
    const navigate = (route, search, state) => {
        props.history.push({
            pathname: route,
            search: search,
            state: state ? { data: state } : null
        });
    };

    const onUpdateTopRatedAirport = (data) => {
        // updateTopRatedAirport
    }

    const onUpdateTopDestiantion = (data) => {
        // updateTopDestination
    }

    const initProps = {
        activeCompany: activeCompany,
        navigate: navigate,
        onUpdateTopRatedAirport: onUpdateTopRatedAirport,
        onUpdateTopDestiantion: onUpdateTopDestiantion

    };
    return tmpl && tmpl({ ...initProps });
};

class FlightPromotionsContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <FlightPromotionsLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { activeCompany } = companyReducer;
    return {
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTopDestination: (data) => dispatch(updateTopDestination(data)),
        updateTopRatedAirport: (data) => dispatch(updateTopRatedAirport(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightPromotionsContainer);
