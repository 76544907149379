import React from 'react';
import 'antd/dist/antd.css';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Text } from 'components';
import { Col, Row, Avatar } from 'antd';
import {
    IconLuggage,
    IconGolfStick,
    travelIcon,
    defaultAvarta
} from 'assets/images/icons';
import { PassengerInfoContainer } from 'components/Cards';

const convertNumbertoArray = (number) => {
    let array = [];
    if (number > 0) {
        for (let i = 0; i < number; i++) {
            array.push(i + 1);
        }
    }
    return array;
};

const PassengersInfo = ({
    passengers = [],
    passengerAmount = 0,
    luggage = [],
    bookingStatus = ''
}) => {
    const { t, i18n } = useTranslation();

    if (bookingStatus === 'CREATED') {
        return (
            <Container>
                <PassengerInfoContainer
                    passengers={passengerAmount}
                    showTitle={false}
                />
            </Container>
        );
    }

    const checkExistAmount = (passengers = []) => {
        let isExist = passengers.some((item) => item.quantity > 0);
        return isExist;
    };

    return (
        <Container>
            <Wrapper>
                {passengerAmount > 0 ? (
                    <Text textStyle="heading6">{`${t(
                        'PASSENGERS'
                    )} (${passengerAmount} PAX)`}</Text>
                ) : (
                    <Text textStyle="heading6">{t('PASSENGERS')}</Text>
                )}

                {passengerAmount === 0 ? (
                    <Text>{t('NO_PASSENGER')}</Text>
                ) : (
                    <>
                        {passengers && passengers.length > 0 ? (
                            <WrapperItem>
                                <Row>
                                    {passengers.map((v, i) => {
                                        const {
                                            firstname = '',
                                            lastname = '',
                                            name = '',
                                            email = '',
                                            phone = '',
                                            image,
                                            identified = false,
                                            user_id = '',
                                            weight_lb = 0
                                        } = v;
                                        return (
                                            <TeamWrapper key={i}>
                                                <Col
                                                    style={{
                                                        marginTop: 5,
                                                        marginLeft: 10
                                                    }}
                                                >
                                                    {image ? (
                                                        <Avatar
                                                            src={image}
                                                            size={44}
                                                        />
                                                    ) : (
                                                        <Avatar size={44}>
                                                            {(
                                                                firstname.charAt(
                                                                    0
                                                                ) +
                                                                lastname.charAt(
                                                                    0
                                                                )
                                                            ).toUpperCase()}
                                                        </Avatar>
                                                    )}
                                                </Col>
                                                <Col
                                                    style={{
                                                        marginLeft: 15
                                                    }}
                                                >
                                                    <div>
                                                        <Name>
                                                            {name || '_'}
                                                        </Name>
                                                        <Icon
                                                            style={{
                                                                marginLeft: 10
                                                            }}
                                                            src={travelIcon}
                                                        />
                                                    </div>
                                                    <Wrapper>
                                                        <PassengerItem>
                                                            {`${weight_lb} lb`}
                                                        </PassengerItem>
                                                    </Wrapper>
                                                    <Wrapper>
                                                        <PassengerItem>
                                                            {phone}
                                                        </PassengerItem>
                                                    </Wrapper>
                                                    <Wrapper>
                                                        <PassengerItem>
                                                            {email}
                                                        </PassengerItem>
                                                    </Wrapper>
                                                    {identified && (
                                                        <Wrapper>
                                                            <PassengerItem font="AvenirNext-DemiBold">
                                                                {t(
                                                                    'TRAVEL_PREFERENCES'
                                                                )}
                                                            </PassengerItem>
                                                        </Wrapper>
                                                    )}
                                                </Col>
                                            </TeamWrapper>
                                        );
                                    })}
                                </Row>
                            </WrapperItem>
                        ) : (
                            <WrapperItem>
                                <Row>
                                    {convertNumbertoArray(passengerAmount).map(
                                        (ps, i) => {
                                            return (
                                                <EachCard key={i}>
                                                    <PassengerCard>
                                                        <Col>
                                                            <Avatar
                                                                src={
                                                                    defaultAvarta
                                                                }
                                                            />
                                                        </Col>
                                                        <Col
                                                            style={{
                                                                marginLeft: 20
                                                            }}
                                                        >
                                                            <Row>
                                                                <Text textStyle="heading5">
                                                                    {`Passenger ${ps}`}
                                                                </Text>
                                                            </Row>
                                                            <Row>
                                                                <Text>
                                                                    No info
                                                                    added
                                                                </Text>
                                                            </Row>
                                                        </Col>
                                                    </PassengerCard>
                                                </EachCard>
                                            );
                                        }
                                    )}
                                </Row>
                            </WrapperItem>
                        )}
                    </>
                )}
            </Wrapper>

            <Wrapper style={{ marginTop: 25 }}>
                <Text textStyle="heading6">{t('LUGGAGES_WEIGHT')}</Text>
                {!!checkExistAmount(luggage) ? (
                    <WrapperItem>
                        <Row>
                            {luggage.map((v, i) => {
                                const {
                                    nominal_weight_lb = '',
                                    quantity = 0,
                                    total_weight_lb = 0,
                                    type = ''
                                } = v;
                                if (quantity > 0) {
                                    return (
                                        <LuggageWrapper key={i}>
                                            <Col
                                                style={{
                                                    alignSelf: 'flex-start'
                                                }}
                                            >
                                                <Name>
                                                    {t(type).toUpperCase()}
                                                </Name>
                                                <Wrapper
                                                    style={{ marginTop: 5 }}
                                                >
                                                    <PassengerItem>
                                                        {t(
                                                            `LUGGAGE_SIZE_${type}`
                                                        )}
                                                    </PassengerItem>
                                                </Wrapper>
                                            </Col>
                                            <QuantityWrap>
                                                <Quantity>{quantity}</Quantity>
                                                <Wrapper
                                                    style={{ marginTop: 5 }}
                                                >
                                                    {type === 'GOLF_CLUBS' ? (
                                                        <IconGolfStick
                                                            fill="#19C0FF"
                                                            style={{
                                                                height: 22,
                                                                width: 32
                                                            }}
                                                        />
                                                    ) : (
                                                        <IconLuggage
                                                            fill="#19C0FF"
                                                            style={{
                                                                height: 22,
                                                                width: 32
                                                            }}
                                                        />
                                                    )}
                                                </Wrapper>
                                            </QuantityWrap>
                                        </LuggageWrapper>
                                    );
                                }
                            })}
                        </Row>
                    </WrapperItem>
                ) : (
                    <WrapperItem style={{ marginTop: 10 }}>
                        <Text>{t('NO_LUGGAGE_ADDED')}</Text>
                    </WrapperItem>
                )}
            </Wrapper>
        </Container>
    );
};

const Icon = styled.img`
    width: 20px;
    height: 20px;
`;

const Container = styled.div`
    background-color: #fff;
    border-radius: 4px;
    margin-top: 15px;
    padding: 20px;
    margin-bottom: 30px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const WrapperItem = styled.div`
    display: flex;
    align-self: center;
    width: 100%;
`;

const QuantityWrap = styled(Col)`
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    justify-content: flex-end;
`;

const PassengerItem = styled.span`
    color: #65686f;
    font-family: ${(props) => props.fonts || 'AvenirNext-Medium'};
    font-size: 11px;
    line-height: 16px;
    text-align: left;
    margin-top: 4px;
`;

const TeamWrapper = styled.div`
    background-color: #f8f9fe;
    border-radius: 4px;
    min-width: 310px;
    width: auto;
    max-width: 345px;
    min-height: 128px;
    display: flex;
    margin-right: 15px;
    margin-top: 15px;
    padding: 20px;
`;

const LuggageWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: #f8f9fe;
    border-radius: 4px;
    min-width: 310px;
    width: auto;
    max-width: 345px;
    min-height: 78px;
    display: flex;
    margin-right: 15px;
    margin-top: 15px;
    padding: 15px;
`;

const Name = styled.span`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
`;

const Quantity = styled.span`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
`;

const EachCard = styled(Col)`
    margin-right: 25px;
`;

const PassengerCard = styled(Row)`
    background-color: #f7f8fb;
    border-radius: 4px;
    min-width: 345px;
    padding-left: 17px;
    margin-top: 20px;
    padding-top: 12px;
    padding-bottom: 16px;
`;

export default PassengersInfo;
