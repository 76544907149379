import { allowedRolesCreateQuote } from 'config/Constants';

export const getCurrentUser = (state) => state.userReducer || {};

export const isUserAdmin = () => store => {
    const { userReducer = {}, companyReducer = {}} = store;
    const {activeCompany = {}} = companyReducer;
    const {id = '', roles = []}= activeCompany;

    let havePermission = false;
    for (let i = 0; i < roles.length; i++) {
        if (roles[i] === 'ADMIN') {
            havePermission = true;
            break;
        }
    }
    return havePermission;
}

export const isUserAdminOrOperator = () => store => {
    const { userReducer = {}, companyReducer = {}} = store;
    const {activeCompany = {}} = companyReducer;
    const {id = '', roles = []}= activeCompany;

    let havePermission = false;
    for (let i = 0; i < roles.length; i++) {
        if (allowedRolesCreateQuote.includes(roles[i])) {
            havePermission = true;
            break;
        }
    }
    return havePermission;
}
