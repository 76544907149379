import React from 'react';
import styled from 'styled-components';
import { Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { uploadIcon, uploadMorePhotosIcon } from 'assets/images/icons';

/**
 *
 */
const UploadComponent = (props) => {
    const {
        loading,
        imageUrl,
        uploadMorePhotos = false,
        style,
        multiple = false
    } = props;
    const { t, i18n } = useTranslation();

    const handleChange = ({ file, fileList }) => {
        if (props.handleChange && typeof props.handleChange === 'function') {
            props.handleChange({ file, fileList });
        }
    };

    const uploadButton = (
        <UploadSection>
            {uploadMorePhotos ? (
                <img
                    style={{ height: 16, width: 18 }}
                    src={uploadMorePhotosIcon}
                />
            ) : (
                <>
                    {loading ? (
                        <img
                            style={{ height: 16, width: 18 }}
                            src={uploadIcon}
                        />
                    ) : (
                        <img
                            style={{ height: 16, width: 18 }}
                            src={uploadIcon}
                        />
                    )}
                </>
            )}
            <UploadText style={{ marginTop: 8 }}>
                {uploadMorePhotos
                    ? t('UPLOAD_MORE_PHOTOS').toUpperCase()
                    : t('UPLOAD_PHOTO').toUpperCase()}
            </UploadText>
        </UploadSection>
    );

    return (
        <UploadContainer style={style}>
            {uploadMorePhotos ? (
                <Upload
                    className="upload-more-photo-section"
                    listType="picture-card"
                    showUploadList={false}
                    beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                    onChange={handleChange}
                    multiple={multiple}
                >
                    {uploadButton}
                </Upload>
            ) : (
                <Upload
                    className="upload-image-section"
                    listType="picture-card"
                    showUploadList={false}
                    beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                    onChange={handleChange}
                    multiple={false}
                >
                    {imageUrl ? (
                        <Image
                            src={imageUrl}
                            className="upload-image-section"
                        />
                    ) : (
                        uploadButton
                    )}
                </Upload>
            )}
        </UploadContainer>
    );
};

const Image = styled.img`
    object-fit: cover;
`;

const UploadContainer = styled.div`
    .upload-more-photo-section {
        border: 1px dashed #8e929a;
        border-radius: 2px;
        width: 225px;
        height: 142px;
    }
`;

const UploadText = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.84px;
    line-height: 14px;
`;

const UploadSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
`;

export default UploadComponent;
