import {
    UPDATE_COMPANY, // all companies in the system
    UPDATE_USER_COMPANY_LIST, // user's companies
    UPDATE_ACTIVE_COMPANY, // user active company
    UPDATE_EMPLOYEES_LIST, // employees
    UPDATE_CUSTOMERS_LIST, // customers
    CLEAR_DATA_COMPANY,
    UPDATE_COUNTRIES, // countries
    UPDATE_NOTIFICATIONS, // notifications
    FETCH_MORE_NOTIFICATIONS,
    RECEIVED_NOTIFICATION,
    READ_NOTIFICATION,
    READ_ALL_NOTIFICATION,
    UPDATE_ACTIVE_COMPANY_IMAGES
} from '../Constants';

const INITIAL_STATE = {
    companies: [],
    userCompanies: [],
    activeCompany: {},
    employees: [],
    customers: [],
    countries: [],
    notifications: {} // { docs = [], limit = 100, page = 1, pages = 10, unread = 0 }
};

export default function companyReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case UPDATE_USER_COMPANY_LIST:
            return {
                ...state,
                userCompanies: action.userCompanies
            };
        case UPDATE_COMPANY:
            return {
                ...state,
                companies: action.companies
            };
        case UPDATE_ACTIVE_COMPANY:
            return {
                ...state,
                activeCompany: action.activeCompany
            };
        case UPDATE_ACTIVE_COMPANY_IMAGES:
            let newActiveCom = state.activeCompany;
            if (action.images && action.images.length > 0) {
                newActiveCom.images = action.images;
                return {
                    ...state,
                    activeCompany: newActiveCom
                }
            }
        case UPDATE_EMPLOYEES_LIST:
            return {
                ...state,
                employees: action.employees
            };
        case UPDATE_CUSTOMERS_LIST:
            return {
                ...state,
                customers: action.customers
            };
        case CLEAR_DATA_COMPANY:
            return {
                ...state,
                INITIAL_STATE
            };
        case UPDATE_COUNTRIES:
            return {
                ...state,
                countries: action.countries
            };
        case UPDATE_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.notifications
            };
        case RECEIVED_NOTIFICATION: 
            let newNoti = state.notifications;
            newNoti?.docs?.unshift(action.data);

            return {
                ...state,
                notifications: newNoti
            }
        case READ_NOTIFICATION: 
            const notiId = action.notiId;

            let notification = state.notifications;
            let index = notification?.docs?.findIndex((item) => item._id === notiId);
            if (index > -1) {
                notification.docs[index].seen = true;
                return {
                    ...state,
                    notifications: notification
                }
            }
        case READ_ALL_NOTIFICATION: 
            let notificationAll = state.notifications;
            if (notificationAll.docs && notificationAll.docs.length > 0) {
                let newDocs = notificationAll.docs.map((item, i) => {
                    let newItem = item;
                    newItem.seen = true;
                    return newItem;
                });
                notificationAll.docs = newDocs;
                notificationAll.unread = 0;
            }
            return {
                ...state,
                notifications: notificationAll
            }
        case FETCH_MORE_NOTIFICATIONS:
            let notiData = state.notifications;
            if (notiData && notiData.docs && notiData.docs.length > 0) {
                notiData.docs = [
                    ...notiData.docs,
                    ...action.notifications.docs
                ];
                notiData.next = action.notifications.next;
                notiData.page = action.notifications.page;
                notiData.pages = action.notifications.pages;
                notiData.limit = action.notifications.limit;
                notiData.total = action.notifications.total;
            }

            return {
                ...state,
                notifications: notiData
            };
        default:
            return { ...state };
    }
}
