import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { Row, Col, Table, Button, Switch, Image, message } from 'antd';
import { Text, ButtonCustom, Tabs, localeEmptyTable } from 'components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { plusIcon, editIcon, deleteIcon, jetIcon } from 'assets/images/icons';
import { numberFormat } from 'utils';
import _ from 'lodash';
import { GoBackAndNext, QuoteGeneralInfo } from 'views/QuoteRequest/components';
import EditModal from './EditModal';
import { WarningModal } from 'components/Modals';
import { BOOKING_TYPES } from 'config/Constants';
import moment from 'moment';

const itemCode = (Math.random() + 1).toString(36).substring(6).toUpperCase();
const warningInitState = {
    show: false,
    id: '',
    legIndex: 0
};

const template = (props) => {
    const { t, i18n } = useTranslation();
    const {
        legItem = {},
        onAddCatering,
        onUpdateCatering,
        onRemoveCatering,
        onGoBack,
        onGoNext,
        isValidNext = false,
        type = BOOKING_TYPES.ONEWAY,
        setFocusedLeg,
        legs = [],
        created_at = ''
    } = props;

    const [adding, setAdding] = useState(false);
    const [warning, setWarning] = useState(warningInitState);
    const [caterings, setCaterings] = useState([]);

    const [itemType, setItemType] = useState(''); // flag UI.
    const [editItem, setEditItem] = useState({});

    useEffect(() => {
        if (!_.isEmpty(legItem)) {
            const { services = [] } = legItem;
            setCaterings([...services]);
        }
    }, [legItem]);

    const onAddService = (item = {}, type = '', isEdit = false) => {
        const {
            id = '',
            code = '',
            name = '',
            image = {},
            images = [],
            price = {},
            quantity = 0,
            remarks = ''
        } = item;
        const { currency, value = 0 } = price;

        let newItems = {
            id: id,
            code: !code ? itemCode : code,
            images: isEdit ? images : !_.isEmpty(image) ? [image] : [],
            name: name,
            quantity: quantity,
            price: {
                currency: currency || 'USD',
                value: value,
                total: value * quantity
            },
            remarks: remarks,
            status: 'ACCEPTED',
            type: type
        };

        let index = caterings.findIndex((item) => item.code === code);
        if (index > -1) {
            let rs = caterings || [];
            rs[index] = newItems;
            setCaterings([...rs]);
            onUpdateCatering(rs, newItems);
        } else {
            setCaterings([...caterings, newItems]);
            onAddCatering(newItems);
        }

        // reset
        setAdding(false);
        setEditItem({});
    };

    const onRemove = () => {
        try {
            if (warning.id && warning.legIndex >= 0) {
                let newCaterings = caterings || [];

                let index = newCaterings.findIndex(
                    (item) => item.id === warning.id
                );
                newCaterings.splice(index, 1);
                setCaterings([...newCaterings]);
                onRemoveCatering(warning.id);
            }
        } catch (e) {}

        // reset
        setWarning(warningInitState);
    };

    const onCancelWarning = () => {
        setWarning(warningInitState);
    };

    const onChangeAcceptRequest = (value, item) => {
        let rs = caterings || [];
        let index = rs.findIndex((v) => v.code === item.code);
        rs[index].status = value ? 'ACCEPTED' : 'REJECTED';
        setCaterings([...rs]);
    };

    const onCancelModal = () => {
        setAdding(false);
    };

    const columns = [
        {
            title: function titleHeader() {
                return (
                    <Text textStyle="infoNormal">
                        {t('ITEM').toUpperCase()}
                    </Text>
                );
            },
            width: '30%',
            dataIndex: 'name',
            key: 'name',
            render: function eachItemBooking(name, row) {
                return <Text textStyle="heading6">{name || '_'}</Text>;
            }
        },
        {
            title: function titleHeader() {
                return (
                    <Text textStyle="infoNormal">
                        {t('SPECIAL_NOTES').toUpperCase()}
                    </Text>
                );
            },
            width: '30%',
            dataIndex: 'remarks',
            key: 'remarks',
            render: function eachItemBooking(remarks, row) {
                return <SpecialNotes>{remarks || '_'}</SpecialNotes>;
            }
        },
        {
            title: function titleHeader() {
                return (
                    <Text textStyle="infoNormal">{t('QTT').toUpperCase()}</Text>
                );
            },
            width: '10%',
            dataIndex: 'quantity',
            key: 'quantity',
            render: function eachItemBooking(quantity, row) {
                return <Text textStyle="heading5">{quantity || '_'}</Text>;
            }
        },
        {
            title: function titleHeader() {
                return (
                    <Text textStyle="infoNormal">
                        {t('STATUS').toUpperCase()}
                    </Text>
                );
            },
            width: '20%',
            dataIndex: 'status',
            key: 'status',
            render: function eachItemBooking(status, row) {
                return (
                    <>
                        <CustomStatus
                            color={
                                status === 'ACCEPTED' ? '#7ED321' : '#DD4558'
                            }
                        >
                            {t('ACCEPT_REQUEST')}
                        </CustomStatus>
                        <Switch
                            checked={status === 'ACCEPTED' ? true : false}
                            style={{ marginLeft: 12 }}
                            onChange={(v) => onChangeAcceptRequest(v, row)}
                        />
                    </>
                );
            }
        },
        {
            title: function titleHeader() {
                return (
                    <Text textStyle="infoNormal">
                        {t('PRICE').toUpperCase()}
                    </Text>
                );
            },
            width: '15%',
            dataIndex: 'price',
            key: 'price',
            render: function eachItemBooking(price = {}, row) {
                if (price.value <= 0) {
                    return (
                        <ButtonCustom
                            btnType="secondary"
                            width="85px"
                            size="small"
                            title={t('ADD_PRICE').toUpperCase()}
                            onClick={() => {
                                setAdding(true);
                                setItemType('CUSTOM');
                                setEditItem(row);
                            }}
                        />
                    );
                }
                return (
                    <Text textStyle="heading6">
                        {numberFormat(price?.value || 0)}
                    </Text>
                );
            }
        },
        {
            width: '10%',
            key: 'action',
            render: function eachItemBooking(action, row) {
                return (
                    <EditButton
                        onClick={() => {
                            setAdding(true);
                            setItemType('CUSTOM');
                            setEditItem(row);
                        }}
                        style={{ marginLeft: 15, width: '100%' }}
                    >
                        <Icon src={editIcon} />
                    </EditButton>
                );
            }
        }
    ];

    const CateringCard = () => {
        return (
            <StandardWrapper>
                <Row>
                    {caterings.map((v, i) => {
                        const {
                            id,
                            images = [],
                            name,
                            quantity,
                            price = {},
                            type = ''
                        } = v;
                        const { value = 0, total = 0 } = price;
                        const image =
                            images.length > 0 ? images[0].lowres?.url : '';
                        if (type === 'STANDARD') {
                            return (
                                <ItemContainer key={i}>
                                    <Col style={{ margin: 10 }}>
                                        <Image
                                            width={90}
                                            height={64}
                                            src={image}
                                        />
                                    </Col>
                                    <Col
                                        style={{
                                            marginLeft: 10,
                                            marginTop: 5,
                                            marginBottom: 5,
                                            width: '100%'
                                        }}
                                    >
                                        <Row
                                            style={{
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <Text textStyle="heading6">
                                                {name}
                                            </Text>
                                            <div>
                                                <EditButton
                                                    onClick={() => {
                                                        setAdding(true);
                                                        setItemType('STANDARD');
                                                        setEditItem(v);
                                                    }}
                                                    style={{ marginLeft: 10 }}
                                                >
                                                    <Icon src={editIcon} />
                                                </EditButton>
                                                <EditButton
                                                    onClick={() =>
                                                        setWarning({
                                                            show: true,
                                                            legIndex: i,
                                                            id: id
                                                        })
                                                    }
                                                    style={{ marginLeft: 10 }}
                                                >
                                                    <Icon src={deleteIcon} />
                                                </EditButton>
                                            </div>
                                        </Row>
                                        <Row>
                                            <Text
                                                textStyle="tableText1"
                                                style={{
                                                    fontSize: '12px',
                                                    lineHeight: '14px',
                                                    marginTop: '4px'
                                                }}
                                            >
                                                {'x ' +
                                                    quantity +
                                                    ' ' +
                                                    (quantity > 1
                                                        ? t('ITEMS')
                                                        : t('ITEM'))}
                                            </Text>
                                        </Row>
                                        <Row>
                                            <Text
                                                style={{
                                                    color: '#121216',
                                                    fontFamily:
                                                        'AvenirNext-Bold',
                                                    fontSize: '14px',
                                                    lineHeight: '18px',
                                                    marginTop: '8px'
                                                }}
                                            >
                                                {numberFormat(value)}
                                            </Text>
                                        </Row>
                                    </Col>
                                </ItemContainer>
                            );
                        }
                    })}
                </Row>
            </StandardWrapper>
        );
    };

    const getCustomItems = () => {
        let customs = caterings.filter((item) => item.type === 'CUSTOM');
        return customs;
    };

    const renderTabLegInfo = () => {
        if (legs.length) {
            if (type === BOOKING_TYPES.RETURN) {
                const tabRenderer = [
                    {
                        key: 'DEPARTURE',
                        label: t('DEPARTURE').toUpperCase(),
                        leg: legs[0],
                        render: (
                            <QuoteGeneralInfo
                                leg={legs[0]}
                                created_at={created_at}
                            />
                        )
                    },
                    {
                        key: 'RETURN',
                        label: t('RETURN').toUpperCase(),
                        leg: legs[1],
                        render: (
                            <QuoteGeneralInfo
                                leg={legs[1]}
                                created_at={created_at}
                            />
                        )
                    }
                ];
                return (
                    <TabsSection>
                        <Tabs
                            tabRenderer={tabRenderer}
                            onChange={setFocusedLeg}
                        />
                    </TabsSection>
                );
            } else if (type === BOOKING_TYPES.MULTI_LEG) {
                const tabRenderer = legs.map((leg, index) => {
                    return {
                        key: 'LEG_' + index,
                        label: 'LEG ' + (index + 1),
                        leg: leg,
                        render: (
                            <QuoteGeneralInfo
                                leg={leg}
                                created_at={created_at}
                            />
                        )
                    };
                });
                return (
                    <TabsSection>
                        <Tabs
                            tabRenderer={tabRenderer}
                            onChange={setFocusedLeg}
                        />
                    </TabsSection>
                );
            }
        }
    };

    return (
        <>
            {renderTabLegInfo()}
            <Container>
                <CateringSection>
                    <Text textStyle="heading3">
                        {t('CATERING').toUpperCase()}
                    </Text>
                    <CateringWrapper>
                        <Row style={{ paddingLeft: 25, paddingTop: 15 }}>
                            <Col>
                                <Row>
                                    <Text
                                        textStyle="heading4"
                                        style={{ marginTop: 10 }}
                                    >
                                        {t('SERVICE_ITEM_SELECTED')}
                                    </Text>
                                </Row>

                                <CateringCard />

                                <Button
                                    type="text"
                                    onClick={() => {
                                        setAdding(true);
                                        setItemType('STANDARD');
                                        setEditItem({});
                                    }}
                                    style={{ marginTop: 10 }}
                                >
                                    <Icon
                                        src={plusIcon}
                                        style={{ marginRight: 10 }}
                                    />
                                    <Text textStyle="nameNormal">
                                        {t('ADD_MORE_ITEMS').toUpperCase()}
                                    </Text>
                                </Button>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                paddingLeft: 25,
                                paddingTop: 25,
                                paddingBottom: 45
                            }}
                        >
                            <Col style={{ width: '100%' }}>
                                <div>
                                    <Text
                                        textStyle="heading4"
                                        style={{ marginTop: 10 }}
                                    >
                                        {t('CUSTOM_SERVICE_ITEM')}
                                    </Text>
                                </div>
                                <Table
                                    columns={columns}
                                    dataSource={getCustomItems()}
                                    style={{
                                        marginTop: 20,
                                        marginRight: 25
                                    }}
                                    pagination={{
                                        hideOnSinglePage: true,
                                        showSizeChanger: false
                                    }}
                                    locale={localeEmptyTable()}
                                />
                                <Button
                                    type="text"
                                    onClick={() => {
                                        setAdding(true);
                                        setItemType('CUSTOM');
                                        setEditItem({});
                                    }}
                                    style={{ marginTop: 10 }}
                                >
                                    <Icon
                                        src={plusIcon}
                                        style={{ marginRight: 10 }}
                                    />
                                    <Text textStyle="nameNormal">
                                        {t('ADD_MORE_ITEMS').toUpperCase()}
                                    </Text>
                                </Button>
                            </Col>
                        </Row>
                    </CateringWrapper>
                </CateringSection>

                <div style={{ marginTop: 40, paddingBottom: 20 }}>
                    <GoBackAndNext
                        onGoNext={onGoNext}
                        onGoBack={onGoBack}
                        activeGoNext={isValidNext}
                        activeGoBack={true}
                    />
                </div>

                {/* modal */}
                <EditModal
                    show={adding}
                    onCancel={onCancelModal}
                    type={itemType}
                    serviceItem={editItem}
                    onSave={onAddService}
                />

                <WarningModal
                    show={warning.show}
                    title={t('CONFIRM_REMOVE_ITEM').toUpperCase()}
                    okText={t('YES_REMOVE_IT').toUpperCase()}
                    onOk={onRemove}
                    cancelText={t('NO_DISREGARD').toUpperCase()}
                    onCancel={onCancelWarning}
                />
            </Container>
        </>
    );
};

export { template };

const FlightInformationContainer = styled(Row)`
    background-color: #ffffff;
    border-radius: 4px;
`;

const Dashed = styled.div`
    border: 1px dashed rgba(14, 14, 18, 0.2);
    width: 100px;
`;

const TabsSection = styled.div`
    margin-top: 30px;
`;

const StandardWrapper = styled.div`
    display: flex;
    align-self: center;
    width: 100%;
`;

const Icon = styled.img`
    width: 16px;
    height: 16px;
`;

const ItemContainer = styled.div`
    display: flex;
    background-color: #f7f8fb;
    border-radius: 4px;
    min-width: 350px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
    margin-right: 15px;
`;

const CateringWrapper = styled.div`
    background-color: #ffffff;
    border-radius: 4px;
    margin-top: 30px;
`;

const CateringSection = styled.div`
    margin-top: 30px;
`;

const SpecialNotes = styled.span`
    color: #121216;
    font-family: Lato;
    font-size: 14px;
    line-height: 24px;
    width: 340px;
    text-align: left;
`;

const Container = styled.div``;

const CustomStatus = styled.span`
    color: ${(props) => props.color || '#7ed321'};
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    width: 97px;
    text-align: left;
`;

const EditButton = styled.button`
    border: none;
    background-color: transparent;
`;
