import { combineReducers } from 'redux';
import appReducer from './appReducer';
import userReducer from './userReducer';
import companyReducer from './companyReducer';
import settingReducer from './settingReducer';
import quoteReducer from './quoteReducer';

const rootReducer = combineReducers({
    appReducer,
    userReducer,
    companyReducer,
    settingReducer,
    quoteReducer
});

export default rootReducer;
