import API from '../../modules/httpModule';
import { aircraftCategory } from 'config/Constants';
import {
    UPDATE_QUOTE_SELECTED_AIRCRAFT,
    UPDATE_QUOTE_CUSTOM_SERVICE,
    DELETE_QUOTE_PACKAGES,
    CREATE_QUOTE_PACKAGES,
    UPDATE_QUOTE_PRICE_SELECTED_AIRCRAFT,
    UPDATE_QUOTE_PRICE_PACKAGES
} from 'redux/Constants';

/**
 * Get quote detail by id
 * @param {String} id - quote id in string
 * @returns
 */
export function getQuoteById(id, query = {}) {
    return async (dispatch) => {
        try {
            const response = await API.get(`/quotes/${id}`, query);
            if (response && response.status === 200) {
                return {
                    success: true,
                    isConnected: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * Get list of quote requests
 * @param {String} staus - QUOTE_REQUEST_STATUS type, its either ACTIVE, WAITING, ACCEPTED or EXPIRED
 * @param {Number} limit - default get 5 quotes
 * @param {Number} page - default page 1
 * @returns
 */
export function getQuotes(status, limit = 5, page = 1, sortColumn) {
    return async (dispatch) => {
        try {
            let query = {
                limit: limit,
                page: page,
                sort_column: sortColumn || 'created_at',
                sort_direction: 'desc'
            };
            if (status) query['status'] = status;

            const response = await API.get(`/v2/quotes`, query);
            if (response && response.status === 200) {
                return {
                    success: true,
                    isConnected: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * Search Booking by booking code or booker name
 * @param {String} key - input search
 * @param {String} status - type of QUOTE_REQUEST_STATUS
 * @returns bookings
 */
export function searchQuote(key, limit = 5, page = 1, status) {
    return async (dispatch) => {
        try {
            let query = {
                key: key,
                limit: limit,
                page: page,
                sort_column: 'created_at',
                sort_direction: 'desc'
            };
            if (status) query['status'] = status;

            const response = await API.get(`/search/quotes`, query);
            if (response && response.status === 200) {
                return response.data;
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * get aircrafts with its price for certain itinerary
 * @param {String} id -  quote id in string
 * @param {String} key -  (optional) search input for aircraft name
 * @param {aircraftCategory} category - (optional) search input for category,
 * @param {Number} legIndex - leg index to get aircraft
 * @param {Number} limit - limit
 * @param {Number} page - page
 * @returns aircrafts
 */

//TODO: implement chỗ search ở trong select aircraft, thêm query key và category
export function getAircraftsForQuote(
    id,
    legIndex,
    limit = 5,
    page = 1,
    key = '',
    category = '',
    company = ''
) {
    return async (dispatch) => {
        try {
            let query = {
                leg_index: legIndex,
                limit,
                page,
                sort_column: 'created_at',
                sort_direction: 'desc',
                key,
                category,
                company
            };

            const response = await API.get(`/quotes/${id}/aircrafts`, query);
            if (response && response.status === 200) {
                return {
                    success: true,
                    isConnected: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * update selected aircrafts
 * @param {String} id -  quote id in string
 * @param {Object} data
 * @param {String} data.leg_id -  leg id
 * @param {Array} data.select_aircrafts - selected aircrafts
 * @returns
 */
export function updateSelectedAircraftForQuote(id, data) {
    return async (dispatch) => {
        try {
            const response = await API.put(`/quotes/${id}/aircrafts`, data);
            if (response && response.status === 200) {
                dispatch({
                    type: UPDATE_QUOTE_SELECTED_AIRCRAFT,
                    data: {
                        id: id,
                        legs: data
                    }
                });
                return {
                    success: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                error: err
            };
        }
    };
}

/**
 * update custom services
 * @param {String} id -  quote id in string
 * @param {Object} data
 * @param {String} data.leg_id -  leg id
 * @param {Array} data.services - service items
 * @returns
 */
export function updateCustomServicesForQuote(id, data) {
    return async (dispatch) => {
        try {
            const response = await API.put(`/quotes/${id}/services`, data);
            if (response && response.status === 200) {
                const { legs = [], packages = [] } = response.data || {};
                dispatch({
                    type: UPDATE_QUOTE_CUSTOM_SERVICE,
                    data: {
                        id: id,
                        legs: legs,
                        packages: packages
                    }
                });
                return {
                    success: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                error: err
            };
        }
    };
}

/**
 * update step create quotation
 * @param {String} id -  quote id in string
 * @param {String} step
 * @returns
 */
export function updateStepForQuote(id, step) {
    return async (dispatch) => {
        try {
            const response = await API.put(`/quotes/${id}/step`, { step });
            if (response && response.status === 200) {
                return { success: true };
            }
        } catch (err) {
            return { success: false };
        }
    };
}

/**
 * calculate new price for selected aircraft
 * @param {String} id -  quote id in string
 * @param {Object} data
 * @param {String} data.leg_id -  leg id
 * @param {String} data.aircraft_id - aircraft_id
 * @param {Object} data.price_components - price_components
 * @returns
 */
export function calcPriceSelectedAircraft(id, data, legIndex, selectedIndex) {
    return async (dispatch) => {
        try {
            const response = await API.put(
                `/quotes/${id}/aircraft/calculate_price`,
                data
            );
            if (response && response.status === 200 && response.data) {
                dispatch({
                    type: UPDATE_QUOTE_PRICE_SELECTED_AIRCRAFT,
                    data: {
                        id,
                        legIndex,
                        selectedIndex,
                        price: response.data
                    }
                });
                return {
                    success: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                error: err
            };
        }
    };
}

/**
 * update package for quote
 * @param {String} id -  quote id in string
 * @param {Object} data
 * @param {Array} data.packages -  packages
 * @param {String} data.packages[0].aircraft_id -  aircraft_id
 * @param {String} data.packages[0].leg_id -  leg_id
 * @param {Object} data.packages[0].price_components -  price_components
 * @param {Array} data.packages[0].service_items - service_items
 * @returns
 */
export function updatePackageForQuote(id, data) {
    return async (dispatch) => {
        try {
            const response = await API.post(`/v2/quotes/${id}/package`, data);
            if (response && response.status === 200) {
                return {
                    success: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                error: err
            };
        }
    };
}

/**
 * update package for quote
 * @param {String} id -  quote id in string
 * @param {Object} data
 * @param {Array} data.packages -  packages
 * @param {String} data.packages[0].aircraft_id -  aircraft_id
 * @param {String} data.packages[0].leg_id -  leg_id
 * @param {Object} data.packages[0].price_components -  price_components
 * @param {Array} data.packages[0].service_items - service_items
 * @returns
 */
export function calcPricePackageForQuote(id, pks, pkIndex) {
    return async (dispatch) => {
        try {
            const response = await API.put(
                `/quotes/${id}/package/calculate_price`,
                {
                    package: pks
                }
            );
            if (response && response.status === 200 && response.data) {
                dispatch({
                    type: UPDATE_QUOTE_PRICE_PACKAGES,
                    data: {
                        id,
                        pkIndex,
                        packages: response.data
                    }
                });
                return {
                    success: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                error: err
            };
        }
    };
}

/**
 * update package for quote
 * @param {String} id -  quote id in string
 * @param {Object} price_components
 * @returns
 */
export function calcPriceComponents(id, price_components) {
    return async (dispatch) => {
        try {
            const response = await API.put(
                `/quotes/${id}/package/pre/calculate_price`,
                {
                    price_components
                }
            );
            if (response && response.status === 200) {
                return {
                    success: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                error: err
            };
        }
    };
}

/**
 * delete package for quote
 * @param {String} id -  quote id in string
 * @param {Object} data.package -  package
 * @param {String} data.aircraft_id -  aircraft_id
 * @param {String} data.leg_id -  leg_id
 * @returns
 */
export function deletePackageForQuote(id, data, index) {
    return async (dispatch) => {
        try {
            const response = await API.delete(`/quotes/${id}/package`, {
                package: data
            });
            if (response && response.status === 200) {
                dispatch({
                    type: DELETE_QUOTE_PACKAGES,
                    data: {
                        id: id,
                        index: index,
                        packages: data
                    }
                });
                return {
                    success: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                error: err
            };
        }
    };
}

/**
 * submit all suggestion packages for a quote
 * @param {String} id - quote request id in string
 * @param {Object} data - request body
 * @param {Array} data.packages - list of package
 * @returns
 */
export function submitPackages(id, data) {
    return async (dispatch) => {
        try {
            const response = await API.put(`/quotes/${id}/package/submit`);
            if (response && response.status === 200) {
                return {
                    success: true,
                    isConnected: true
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 *
 * @param {String} id - quote request id in string
 * @param {Object} data - request body
 * @param {Array} data.package - a package data
 * @returns
 */
export function checkPositionFee(id, data) {
    return async (dispatch) => {
        try {
            const response = await API.post(
                `/quotes/:${id}/package/check`,
                data
            );
            if (response && response.status === 200) {
                return response.data;
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 *
 * @param {String} id - quote request id
 * @param {Object} data - request body
 * @param {String} data.category - a package data
 * @param {String} data.content - a package data
 * @returns
 */
export function updateQuoteRequest(id, data = {}) {
    return async (dispatch) => {
        try {
            // let body = {
            //     type: type,
            //     leg_id: legId,
            //     note: {
            //         content: data.content,
            //         category: data.category || 'GENERAL'
            //     }
            // };
            // const body = data;
            const response = await API.put(`/quotes/${id}`, data);
            if (response && response.status === 200) {
                return {
                    success: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                error: err
            };
        }
    };
}
