import React from 'react';
import styled from 'styled-components';
import { Layout, Col, Row, Input } from 'antd';

/**
 *
 * @param {string} label
 * @param {string} placeholderText
 * @param {func} onChangeText - handle change text
 * @returns {JSX}
 */
const InputText = (props) => {
    const {
        label = '',
        onChangeText,
        placeholderText = '',
        value = ''
    } = props;

    return (
        <>
            <Label>{label}</Label>
            <Input
                onChange={(val) => onChangeText(val.target.value)}
                className="base"
                placeholder={placeholderText}
                value={value}
            />
        </>
    );
};

const Label = styled.div`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
    margin: 15px;
`;

export default InputText;
