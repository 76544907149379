import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import {
    Row,
    Col,
    Switch,
    Input as AntInput,
    Image,
    Upload,
    message
} from 'antd';
import { Input, Checkbox, Text } from 'components';
import { uploadImage } from 'redux/actions/appActions';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getBase64 } from 'utils';
import { WHITELABEL_SETTING } from 'routes/main';
import { UPLOAD_MODEL, UPLOAD_IMAGE_TYPE } from 'redux/Constants';
import { FLIGHT_TYPES } from 'config/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { IMAGE_FILE_EXTENSIONS } from 'config/Constants';
import {
    IconApple,
    uploadMorePhotosIcon,
    IconAndroid
} from 'assets/images/icons';
import CustomModal from './CustomModal';
import UploadWLImage from './upload/UploadWLImage';

const { TextArea } = AntInput;

const AppIntro = (props) => {
    const {
        whitelabelId = '',
        activeAppIntro = false,
        setActiveAppIntro,
        appIntro_android_app_link = '',
        set_appIntro_android_app_link,
        appIntro_background_image = '',
        set_appIntro_background_image,
        set_new_appIntro_background_image,
        appIntro_description = '',
        set_appIntro_description,
        appIntro_ios_app_link = '',
        set_appIntro_ios_app_link,
        appIntro_title = '',
        set_appIntro_title,
        appIntro_image = '',
        set_appIntro_image,
        new_appIntro_image = '',
        set_new_appIntro_image
    } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [showError, setShowError] = useState(false);
    const [imageUrl, setImageUrl] = useState(appIntro_background_image || '');
    const [appImageUrl, setAppImageUrl] = useState(appIntro_image || '');

    const [activeAndroid, setActiveAndroid] = useState(false);
    const [activeIos, setActiveIos] = useState(false);

    useEffect(() => {
        appIntro_android_app_link
            ? setActiveAndroid(true)
            : setActiveAndroid(false);
    }, [appIntro_android_app_link]);

    useEffect(() => {
        appIntro_ios_app_link ? setActiveIos(true) : setActiveIos(false);
    }, [appIntro_ios_app_link]);

    useEffect(() => {
        setImageUrl(appIntro_background_image);
    }, [appIntro_background_image]);

    useEffect(() => {
        setAppImageUrl(appIntro_image);
    }, [appIntro_image]);

    const uploadAppIntroBackgroundImage = async (file) => {
        const result = await dispatch(
            uploadImage(
                whitelabelId,
                file,
                UPLOAD_MODEL.WHITELABEL,
                UPLOAD_IMAGE_TYPE.GALLERY
            )
        );
        if (result.success && result.image) {
            set_new_appIntro_background_image(result.image.url || '');
            return true;
        } else {
            setShowError(true);
            return false;
        }
    };

    const uploadAppIntroImage = async (file) => {
        const result = await dispatch(
            uploadImage(
                whitelabelId,
                file,
                UPLOAD_MODEL.WHITELABEL,
                UPLOAD_IMAGE_TYPE.GALLERY
            )
        );
        if (result.success && result.image) {
            set_new_appIntro_image(result.image.url || '');
            return true;
        } else {
            setShowError(true);
            return false;
        }
    };

    const handleChange = async ({ file, fileList }) => {
        const result = await uploadAppIntroBackgroundImage(file);
        if (result) {
            getBase64(file, (imageUrl) => {
                setImageUrl(imageUrl);
            });
        }
    };

    const handleChangeAppImage = async ({ file, fileList }) => {
        const result = await uploadAppIntroImage(file);
        if (result) {
            getBase64(file, (imageUrl) => {
                setAppImageUrl(imageUrl);
            });
        }
    };

    const uploadButton = (
        <UploadSection>
            <img style={{ height: 16, width: 18 }} src={uploadMorePhotosIcon} />
            <UploadText style={{ marginTop: 8 }}>
                {t('UPLOAD_PHOTO_OR_DRAG').toUpperCase()}
            </UploadText>
        </UploadSection>
    );

    return (
        <ContainerWrapper>
            <PayoutSection>
                <WhitelabelContainer>
                    <EachRow align={'middle'}>
                        <TitleText>{t('SHOW_SECTION')}</TitleText>
                        <Switch
                            checked={activeAppIntro}
                            style={{ marginLeft: 10 }}
                            onChange={setActiveAppIntro}
                        />
                    </EachRow>
                    {activeAppIntro && (
                        <>
                            <Row
                                align="stretch"
                                // justify="space-between"
                                // style={{ marginTop: 20 }}
                            >
                                <Col style={{ width: 570, marginRight: 60 }}>
                                    <EachRow style={{marginTop: 40}}>
                                        <GeneralSetting>
                                            {t('UPLOAD_BACKGROUND')}
                                        </GeneralSetting>
                                    </EachRow>
                                    <EachRow style={{ marginTop: 5 }}>
                                        <SectionDescription>
                                            {t('BACKGROUND_IMAGE_DESCRIPTION')}
                                        </SectionDescription>
                                    </EachRow>
                                    <EachRow>
                                        {imageUrl ? (
                                            <ChangePhotoContainer>
                                                <ImageContainer>
                                                    <AntdImage src={imageUrl} />
                                                </ImageContainer>
                                                <div style={{ marginTop: 20 }}>
                                                    <UploadWLImage
                                                        accept={IMAGE_FILE_EXTENSIONS}
                                                        beforeUpload={() =>
                                                            false
                                                        }
                                                        onChange={handleChange}
                                                        multiple={false}
                                                        showUploadList={false}
                                                        isCropImage
                                                    >
                                                        <ChangePhotoButton>
                                                            <ChangePhotoText>
                                                                {t(
                                                                    'CHANGE_PHOTO'
                                                                ).toUpperCase()}
                                                            </ChangePhotoText>
                                                        </ChangePhotoButton>
                                                    </UploadWLImage>
                                                </div>
                                            </ChangePhotoContainer>
                                        ) : (
                                            <UploadPhoto
                                                accept={IMAGE_FILE_EXTENSIONS}
                                                listType="picture-card"
                                                showUploadList={false}
                                                beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                                                onChange={handleChange}
                                                multiple={false}
                                                isCropImage
                                                aspectRatio={16/9}
                                            >
                                                {uploadButton}
                                            </UploadPhoto>
                                        )}
                                    </EachRow>
                                </Col>
                                <Col style={{ maxWidth: 470 }}>
                                    <EachRow style={{marginTop: 40}}>
                                        <GeneralSetting>
                                            {t('APP_INTRO')}
                                        </GeneralSetting>
                                    </EachRow>
                                    <EachRow style={{ marginTop: 5 }}>
                                        <SectionDescription>
                                            {t('APP_IMAGE_DESCRIPTION')}
                                        </SectionDescription>
                                    </EachRow>
                                    <EachRow>
                                        {appImageUrl ? (
                                            <ChangePhotoContainer>
                                                <ImageContainer
                                                // style={{ width: 312 }}
                                                >
                                                    <AntdImage
                                                        src={appImageUrl}
                                                    />
                                                </ImageContainer>
                                                <div style={{ marginTop: 20 }}>
                                                    <UploadWLImage
                                                        accept={IMAGE_FILE_EXTENSIONS}
                                                        beforeUpload={() => false}
                                                        onChange={handleChangeAppImage}
                                                        multiple={false}
                                                        showUploadList={false}
                                                        isCropImage
                                                        aspectRatio={16/9}
                                                    >
                                                        <ChangePhotoButton>
                                                            <ChangePhotoText>
                                                                {t(
                                                                    'CHANGE_PHOTO'
                                                                ).toUpperCase()}
                                                            </ChangePhotoText>
                                                        </ChangePhotoButton>
                                                    </UploadWLImage>
                                                </div>
                                            </ChangePhotoContainer>
                                        ) : (
                                            <UploadAppImage
                                                accept={IMAGE_FILE_EXTENSIONS}
                                                listType="picture-card"
                                                showUploadList={false}
                                                beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                                                onChange={handleChangeAppImage}
                                                multiple={false}
                                                isCropImage
                                                aspectRatio={16/9}
                                            >
                                                {uploadButton}
                                            </UploadAppImage>
                                        )}
                                    </EachRow>
                                </Col>
                            </Row>
                            <EachRow>
                                <GeneralSettingsCol
                                    style={{ marginRight: 60, width: 570 }}
                                >
                                    <EachRow>
                                        <GeneralSetting>
                                            {t('CUSTOM_TEXT')}
                                        </GeneralSetting>
                                    </EachRow>
                                    <EachRow style={{ marginTop: 5 }}>
                                        <SectionDescription>
                                            {t('CUSTOM_TEXT_DESCRIPTION')}
                                        </SectionDescription>
                                    </EachRow>
                                    <EachRow>
                                        <Input
                                            label={t(
                                                'MAIN_TITLE'
                                            ).toUpperCase()}
                                            placeholder={t('ENTER_TITLE')}
                                            width="100%"
                                            value={appIntro_title}
                                            onChange={set_appIntro_title}
                                        />
                                    </EachRow>
                                    <EachRow>
                                        <Input
                                            label={t('SUB_TITLE').toUpperCase()}
                                            placeholder={t('ENTER_SUB_TITLE')}
                                            width="100%"
                                            value={appIntro_description}
                                            onChange={set_appIntro_description}
                                        />
                                    </EachRow>
                                </GeneralSettingsCol>
                                <GeneralSettingsCol>
                                    <EachRow>
                                        <GeneralSetting>
                                            {t('CUSTOM_BUTTON')}
                                        </GeneralSetting>
                                    </EachRow>
                                    <EachRow style={{ marginTop: 5 }}>
                                        <SectionDescription>
                                            {t('CUSTOM_BUTTON_DESCIPTION')}
                                        </SectionDescription>
                                    </EachRow>
                                    <EachRow style={{ marginBottom: 8 }}>
                                        <GeneralSetting
                                            style={{ fontSize: 10 }}
                                        >
                                            {t('APP_STORE').toUpperCase()}
                                        </GeneralSetting>
                                    </EachRow>
                                    <EachRow
                                        align="middle"
                                        gutter={8}
                                        style={{ marginTop: 0 }}
                                    >
                                        <Col
                                            style={{
                                                height: '100%',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                display: 'flex'
                                            }}
                                        >
                                            <Switch
                                                checked={activeIos}
                                                // style={{ marginLeft: 10 }}
                                                onChange={(value) => {
                                                    setActiveIos(value);
                                                    if (!value) {
                                                        set_appIntro_ios_app_link(
                                                            ''
                                                        );
                                                    }
                                                }}
                                            />
                                        </Col>
                                        <Col>
                                            <DownloadAppButton>
                                                <AppleLogo src={IconApple} />
                                                <DownloadAppTexts>
                                                    <span className="download-app-text">
                                                        {t('DOWNLOAD_APP')}
                                                    </span>
                                                    <span className="app-store-text">
                                                        {t('APP_STORE')}
                                                    </span>
                                                </DownloadAppTexts>
                                            </DownloadAppButton>
                                        </Col>
                                        <Col>
                                            <CustomInput
                                                placeholder={t(
                                                    'ENTER_APP_LINK'
                                                )}
                                                value={appIntro_ios_app_link}
                                                onChange={(e) => {
                                                    set_appIntro_ios_app_link(
                                                        e.target.value
                                                    );
                                                }}
                                                disabled={!activeIos}
                                            />
                                        </Col>
                                    </EachRow>
                                    <EachRow style={{ marginBottom: 8 }}>
                                        <GeneralSetting
                                            style={{ fontSize: 10 }}
                                        >
                                            {t('GOOGLE_PLAY').toUpperCase()}
                                        </GeneralSetting>
                                    </EachRow>
                                    <EachRow
                                        align="middle"
                                        gutter={8}
                                        style={{ marginTop: 0 }}
                                    >
                                        <Col>
                                            <Switch
                                                checked={activeAndroid}
                                                // style={{ marginLeft: 10 }}
                                                onChange={(value) => {
                                                    setActiveAndroid(value);
                                                    if (!value) {
                                                        set_appIntro_android_app_link(
                                                            ''
                                                        );
                                                    }
                                                }}
                                            />
                                        </Col>
                                        <Col>
                                            <DownloadAppButton>
                                                <AppleLogo src={IconAndroid} />
                                                <DownloadAppTexts>
                                                    <span className="download-app-text">
                                                        {t('DOWNLOAD_APP')}
                                                    </span>
                                                    <span className="app-store-text">
                                                        {t('GOOGLE_PLAY')}
                                                    </span>
                                                </DownloadAppTexts>
                                            </DownloadAppButton>
                                        </Col>
                                        <Col>
                                            <CustomInput
                                                // label={t(
                                                //     'ADD_LINK'
                                                // ).toUpperCase()}
                                                placeholder={t(
                                                    'ENTER_APP_LINK'
                                                )}
                                                value={
                                                    appIntro_android_app_link
                                                }
                                                onChange={(e) => {
                                                    set_appIntro_android_app_link(
                                                        e.target.value
                                                    );
                                                }}
                                                disabled={!activeAndroid}
                                            />
                                        </Col>
                                    </EachRow>
                                </GeneralSettingsCol>
                            </EachRow>
                        </>
                    )}
                </WhitelabelContainer>
            </PayoutSection>
            <CustomModal
                visibleModal={showError}
                errorMessage={'Upload image fail. Please try again!'}
                buttonTitle={t('OK').toUpperCase()}
                onOk={() => setShowError(false)}
                cancelModal={() => setShowError(false)}
            />
        </ContainerWrapper>
    );
};

export default AppIntro;

const AntdImage = styled(Image)`
    height: 183px;
    object-fit: contain;
`;

const CustomInput = styled(AntInput)`
    height: 50px;
    width: 330px;
    font-family: Lato;
    color: rgb(18, 18, 22);
    border: 1px solid rgba(36, 36, 45, 0.15);
    border-radius: 3px;
    max-width: 773px;
`;

const DownloadAppTexts = styled.div`
    display: flex;
    flex-direction: column;
    .download-app-text {
        color: #1d1d24;
        font-family: AvenirNext-Medium;
        font-size: 10px;
        line-height: 12px;
    }
    .app-store-text {
        color: #1d1d24;
        font-family: AvenirNext-Medium;
        font-size: 18px;
        line-height: 22px;
    }
`;

const AppleLogo = styled.img`
    max-width: 28px;
    max-height: 33px;
    margin-right: 15px;
    margin-left: 20px;
`;

const DownloadAppButton = styled.button`
    background: transparent;
    border: 1px solid #1d1d24;
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    width: 192px;
    height: 50px;
    align-items: center;
    cursor: pointer;
    /* margin-top: 30px; */
    /* margin-bottom: 6px; */
`;

const ChangePhotoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

const ImageContainer = styled.div`
    /* width: 470px; */
    height: 183px;
    border-radius: 4px;
    margin-right: 30px;
`;

const ChangePhotoButton = styled.button`
    background: transparent;
    border: 1px solid rgba(18, 18, 22, 0.2);
    border-radius: 2px;
`;

const ChangePhotoText = styled.span`
    color: #16161b;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
`;

const UploadPhoto = styled(UploadWLImage)`
    border: 1px dashed #65686f;
    border-radius: 4px;
    width: 470px;
    height: 183px;
    margin-top: 10px;
`;

const UploadAppImage = styled(UploadWLImage)`
    border: 1px dashed #65686f;
    border-radius: 4px;
    width: 312px;
    height: 180px;
    margin-top: 10px;
`;

const UploadText = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.84px;
    line-height: 14px;
`;

const UploadSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
`;

const GeneralSettingsCol = styled(Col)``;

const GeneralSetting = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 17px;
`;

const DescriptionArea = styled(TextArea)`
    border: 1px solid rgba(36, 36, 45, 0.15);
    border-radius: 4px;
    margin-top: 10px;
    width: 770px;
`;

const TitleText = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 18px;
    text-transform: uppercase;
`;

const WhitelabelContainer = styled.div`
    padding-left: 51px;
`;

const EachRow = styled(Row)`
    margin-top: 20px;
`;

const SectionDescription = styled.span`
    color: #65686f;
    font-family: Lato;
    font-size: 14px;
    line-height: 24px;
`;

const ContainerWrapper = styled.div`
    /* padding-bottom: 40px; */
    border-radius: 4px;
    /* margin-top: 14px; */
    .ant-divider {
        border-top: 1px solid rgba(14, 14, 18, 0.08);
    }
    .ant-divider-horizontal {
        margin: 20px 0;
    }
    input[type='color' i] {
        padding: 0;
        border: none;
        background: none;
        border-radius: 6px;
    }
`;

const PayoutSection = styled.div`
    padding-right: 20px;
    padding-bottom: 40px;
    background-color: #ffffff;
    border-radius: 4px;
`;
