import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { Input, Table, Avatar } from 'antd';
import { useTranslation } from 'react-i18next';
import { Header, ButtonCustom, localeEmptyTable } from 'components';
import { ADD_NEW_CUSTOMER, CUSTOMER_DETAIL } from 'routes/main';
import { searchIcon } from 'assets/images/icons';
import {
    CustomerContainer,
    TableSection,
    ColumnHeader,
    NameSectionButton,
    SearchSection,
    MembershipText,
    ItemText,
    DetailSection
} from './styles';
import _ from 'lodash';
import { renderAddress } from 'utils';

const template = (props) => {
    const { navigate, customerList } = props;
    const { t, i18n } = useTranslation();
    const SearchIcon = <img src={searchIcon} />;

    const onClickDetail = (val) => {
        navigate(CUSTOMER_DETAIL, val.id, val);
    };

    const columns = [
        {
            dataIndex: 'name',
            key: 'name',
            width: '25%',
            fixed: 'left',
            sorter: (a, b) => {
                if (a.name.toUpperCase() < b.name.toUpperCase()) {
                    return -1;
                }
                if (a.name.toUpperCase() > b.name.toUpperCase()) {
                    return 1;
                }
                return 0;
            },
            title: function titleHeader() {
                return <ColumnHeader>{t('NAME')}</ColumnHeader>;
            },
            render: function eachItemName(name, row) {
                return (
                    <NameSectionButton onClick={() => onClickDetail(row)}>
                        {row.image ? (
                            <Avatar
                                src={row.image}
                                className="ant-avatar-custom"
                            />
                        ) : (
                            <Avatar className="ant-avatar-custom">
                                {(
                                    row.firstname.charAt(0) +
                                    row.lastname.charAt(0)
                                ).toUpperCase()}
                            </Avatar>
                        )}
                        <ItemText style={{ marginLeft: '10px' }}>
                            {name}
                        </ItemText>
                    </NameSectionButton>
                );
            }
        },
        {
            dataIndex: 'membership',
            key: 'membership',
            width: '15%',
            title: function titleHeader() {
                return <ColumnHeader>{t('MEMBERSHIP')}</ColumnHeader>;
            },
            render: function eachItemMembership(membership = {}) {
                return (
                    <MembershipText>
                        {membership?.name
                            ? membership?.name?.toUpperCase()
                            : '_'}
                    </MembershipText>
                );
            }
        },
        {
            dataIndex: 'email',
            key: 'email',
            width: '20%',
            sorter: (a, b) => {
                if (a.email.toUpperCase() < b.email.toUpperCase()) {
                    return -1;
                }
                if (a.email.toUpperCase() > b.email.toUpperCase()) {
                    return 1;
                }
                return 0;
            },
            title: function titleHeader() {
                return <ColumnHeader>{t('EMAIL_ADDRESS')}</ColumnHeader>;
            },
            render: function eachItemEmail(email) {
                return <ItemText>{email}</ItemText>;
            }
        },
        {
            dataIndex: 'address',
            key: 'address',
            width: '25%',
            title: function titleHeader() {
                return <ColumnHeader>{t('ADDRESS')}</ColumnHeader>;
            },
            render: function eachItemAddress(address) {
                return <ItemText>{renderAddress(address)}</ItemText>;
            }
        },
        {
            key: 'action',
            title: '',
            width: '15%',
            render: function setAction(val) {
                return (
                    <DetailSection>
                        <ButtonCustom
                            btnType="secondary"
                            size="small"
                            title={t('VIEW_DETAILS').toUpperCase()}
                            width="120px"
                            onClick={(e) => onClickDetail(val)}
                        />
                    </DetailSection>
                );
            }
        }
    ];

    return (
        <CustomerContainer>
            <Header
                title={t('CUSTOMERS')}
                description={t('VIEW_ALL_CUSTOMER')}
                headerRight={() => (
                    <ButtonCustom
                        btnType="secondary"
                        width="190px"
                        onClick={() => navigate(ADD_NEW_CUSTOMER)}
                        title={t('ADD_NEW_CUSTOMER').toUpperCase()}
                    />
                )}
            />
            <SearchSection>
                <Input
                    placeholder={t('SEARCH')}
                    allowClear
                    suffix={SearchIcon}
                    onChange={(e) => props.onSearch(e)}
                />
            </SearchSection>

            <TableSection>
                <Table
                    columns={columns}
                    dataSource={customerList}
                    pagination={{ showSizeChanger: false }}
                    style={{ marginTop: 10 }}
                    scroll={{ x: 1000 }}
                    locale={localeEmptyTable()}
                />
            </TableSection>
        </CustomerContainer>
    );
};

export { template };
