/* eslint-disable react/display-name */
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Modal, Table } from 'antd';

import { ButtonCustom } from 'components';
import { SearchAirports } from 'components/Search';
import { IconDeparture, IconLand } from 'assets/images/icons';
import { TextMedium } from 'components/Antd/Text';
import { isEmpty } from 'lodash';

const ESTIMATE_AIRCRAFT_TABLE_KEYS = ['climb_cruising_descent', 'distance', 'price'];

const EstimateAircraftModal = ({
    show = false,
    okText,
    onOk,
    onCancel,
    onSelectFromAirport,
    onSelectToAirport,
    fromAirport,
    toAirport,
    estimateAircraft,
    estimateAircraftErrMsg,
}) => {
    const { t, i18n } = useTranslation();

    const columnsEstimate = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          width: "60%",
        },
        {
          title: 'Value',
          dataIndex: 'value',
          key: 'value',
          width: "40%",
        }
    ];

    const rowClassName = (record, _) => {
        if (ESTIMATE_AIRCRAFT_TABLE_KEYS.includes(record.key)) {
            return 'estimate-aicraft-hightlight'
        }
    }

    return (
        <ModalEstimate
            visible={show}
            onCancel={() => onCancel(false)}
            footer={null}
            width={700}
        >
            <SearchAirportsWrapper>
                <SearchAirports
                    label={t('FROM').toUpperCase()}
                    prefix={<IconDeparture fill='#8E929A' width={20} height={20} />}
                    placeholder={t('DEPARTURE')}
                    airport={fromAirport ?? {}}
                    allowClear={true}
                    onClear={() => onSelectFromAirport({})}
                    width={'100%'}
                    onSelectAirport={(val) => onSelectFromAirport(val)}
                />
                <SearchAirports
                    label={t('TO').toUpperCase()}
                    prefix={<IconLand fill='#8E929A' width={20} height={20} />}
                    placeholder={t('ARRIVAL')}
                    allowClear={true}
                    onClear={() => onSelectToAirport({})}
                    airport={toAirport ?? {}}
                    width={'100%'}
                    onSelectAirport={(val) => onSelectToAirport(val)}
                />
            </SearchAirportsWrapper>
            {!isEmpty(estimateAircraftErrMsg) && <TextMedium style={{ color: 'red', marginTop: '5px', width: '100%' }}>{estimateAircraftErrMsg}</TextMedium>}
            <ButtonCustom
                wrapStyle={{ marginTop: '20px' }}
                btnType="primary"
                width="150px"
                size="medium"
                title={okText}
                onClick={onOk}
                disabled={!isEmpty(estimateAircraftErrMsg) || isEmpty(fromAirport) || isEmpty(toAirport)}
            />
            {estimateAircraft?.length > 0 && 
                <Table
                    style={{ width: '100%', marginTop: '20px' }}
                    columns={columnsEstimate}
                    dataSource={estimateAircraft}
                    pagination={{ position: ['none', 'none'], defaultPageSize: 100 }}
                    bordered
                    scroll={{ y: 500 }}
                    defaultExpandAllRows={true}
                    rowClassName={rowClassName}
                />
            }
        </ModalEstimate>
    );
};

export default EstimateAircraftModal;

const ModalEstimate = styled(Modal)`
    padding: 0;
    .estimate-aicraft-hightlight {
        background-color: #F7F8FB;
    }
`;

const SearchAirportsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    & > * {
        min-width: 315px;
    }
`;
