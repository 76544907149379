import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { template as tmpl } from './ForgotPasswordReset.tmpl';
import { submitResetPassword, updateNewPassword } from 'redux/actions/appActions';
import { LOGIN, FORGOT_PASSWORD } from 'routes/main';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
 
const ForgotPasswordResetLogic = (props) => {
    const dispatch = useDispatch();

    const [uid, setUid] = useState('');
    const [avt, setAvt] = useState('');
    const [payload, setPayload] = useState({});
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorPassword, setErrorPassword] = useState('');
    const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.location && props.location.state) {
            const { uid, payload, avt } = props.location.state;
            setPayload(payload);
            setUid(uid);
            setAvt(avt);
        }
    }, [props.location?.state]);

    const validatePassword = (p) => {
        if (p.length < 8) {
            return 'PASSWORD_AT_LEAST_8_CHARACTERS';
        }
        if (p.search(/[a-z]/i) < 0) {
            return 'PASSWORD_AT_LEAST_CONTAIN_1_LETTER';
        }
        if (p.search(/[0-9]/) < 0) {
            return 'PASSWORD_AT_LEAST_CONTAIN_1_DIGIT';
        }
        return '';
    };

    useEffect(() => {
        if (password) {
            if (validatePassword(password)) {
                setErrorPassword(validatePassword(password));
            } else {
                setErrorPassword('');
            }
        }
    }, [password]);

    const handleResetPassword = async () => {
        if (loading) return;
        setLoading(true);

        if (password === confirmPassword) {
            const rs = await dispatch(
                updateNewPassword(uid, password, avt)
            );
            if (rs.success) {
                props.history.push(LOGIN);
            } else {
                setErrorPassword(rs.errors || 'ERROR_ACCOUNT_VALIDATION');
            }
            setErrorConfirmPassword('');
        } else {
            setErrorConfirmPassword('CONFIRM_PASSWORD_DOES_NOT_MATCH');
        }

        setTimeout(() => {
            setLoading(false);
        }, 350);
    };

    const handleBackToForgotPassword = () => {
        props.history.push(FORGOT_PASSWORD);
    };

    const initProps = {
        handleBackToForgotPassword: handleBackToForgotPassword,
        payload: payload,
        loading: loading,
        password: password,
        confirmPassword: confirmPassword,
        setPassword: setPassword,
        setConfirmPassword: setConfirmPassword,
        errorPassword: errorPassword,
        errorConfirmPassword: errorConfirmPassword,
        handleResetPassword: handleResetPassword
    };
    return tmpl && tmpl({ ...initProps });
};

class ForgotPasswordReset extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <ForgotPasswordResetLogic {...initProps} />;
    }
}

export default withRouter(ForgotPasswordReset);
