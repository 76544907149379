import React, { useState } from 'react';
import { template as tmpl } from './SettingProfileContainer.tmpl';
import { connect } from 'react-redux';

const SettingProfileLogic = (props) => {
    const { user, activeCompany, companies = [] } = props;

    const navigate = (route) => {
        props.history.push(route);
    };

    const initProps = {
        user: user,
        activeCompanyName: activeCompany.name ? activeCompany.name : '',
        companies: companies,
        navigate: navigate
    };

    return tmpl && tmpl({ ...initProps });
};

class SettingProfileContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <SettingProfileLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { userReducer, companyReducer } = state;
    const { activeCompany, companies } = companyReducer;
    return {
        user: userReducer,
        companies: companies,
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        //
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingProfileContainer);
