import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { Text } from 'components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const FlightInformation = (props) => {
    const { t, i18n } = useTranslation();
    const {
        flightType,
        requestCode,
        status,
        reqeustSendBy,
        backgroundColor = '#fcfcfe',
        created_at
    } = props;
    return (
        <FlightStatusContainer backgroundColor={backgroundColor}>
            <FlightInformationContainer backgroundColor={backgroundColor}>
                <Col style={{ marginRight: '40px' }}>
                    <Title>
                        <Text textStyle="tableText1">{t('FLIGHT_TYPE')}</Text>
                    </Title>
                    <Value>
                        <Text textStyle="typeOfFlight">
                            {t(flightType).toUpperCase() || '-'}
                        </Text>
                    </Value>
                </Col>
                <Col style={{ marginRight: '40px' }}>
                    <Title>
                        <Text textStyle="tableText1">
                            {t('REQUEST_NUMBER')}
                        </Text>
                    </Title>
                    <Value>
                        <Text textStyle="tableText2">{requestCode || '-'}</Text>
                    </Value>
                </Col>
                {created_at && (
                    <Col style={{ marginRight: '40px' }}>
                        <Title>
                            <Text textStyle="tableText1">
                                {t('CREATED_AT')}
                            </Text>
                        </Title>
                        <Value>
                            <Text textStyle="tableText2">
                                {created_at
                                    ? moment(created_at)
                                          .format('lll')
                                          .toUpperCase()
                                    : '-'}
                            </Text>
                        </Value>
                    </Col>
                )}
                <Col style={{ marginRight: '40px' }}>
                    <Title>
                        <Text textStyle="tableText1">
                            {t('REQUEST_SEND_BY')}
                        </Text>
                    </Title>
                    <Value>
                        <RequestSentBy textStyle="heading5">
                            {reqeustSendBy}
                        </RequestSentBy>
                        {/* <Membership>Non-Member</Membership> */}
                    </Value>
                </Col>
                {status && (
                    <Col style={{ marginRight: '40px' }}>
                        <Title>
                            <Text textStyle="tableText1">
                                {t('REQUEST_STATUS')}
                            </Text>
                        </Title>
                        <Value>
                            <RequestStatus>{t(status) || '-'}</RequestStatus>
                        </Value>
                    </Col>
                )}
            </FlightInformationContainer>
        </FlightStatusContainer>
    );
};

export default FlightInformation;

const Title = styled(Row)``;

const Value = styled(Row)`
    margin-top: 10px;
`;

const FlightStatusContainer = styled.div`
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : '#fcfcfe'};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
`;

const FlightInformationContainer = styled(Row)`
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : '#fcfcfe'};
    border-radius: 0 4px 0 0;
    padding-top: 15px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
`;

const RequestStatus = styled.span`
    color: #f5a623;
    font-family: AvenirNext-Medium;
    font-size: 12px;
    line-height: 14px;
`;

const RequestSentBy = styled.span`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
`;
