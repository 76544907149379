import React from 'react';
import styled from 'styled-components';
import { Avatar } from 'antd';
import { useSelector } from 'react-redux';
import { getCompanyInfo } from 'redux/selectors/company';

const Company = ({ _company = '', onClick }) => {
    let image = '';
    let name = '';
    const company = useSelector((state) => getCompanyInfo(state, _company));
    if (company) {
        image = company?.image;
        name = company?.name;
    }

    return (
        <NameSection onClick={() => onClick && onClick(_company)}>
            {image ? (
                <Avatar className="ant-avatar-custom" src={image} />
            ) : ( name &&
                <Avatar className="ant-avatar-custom">
                    {name.charAt(0).toUpperCase()}
                </Avatar>
            )}
            <UserName>{name || '_'}</UserName>
        </NameSection>
    );
};

const NameSection = styled.button`
    display: flex;
    flex-direction: row;
    border: none;
    background: none;
`;

const UserName = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    margin-left: 10px;
    align-self: center;
`;

export default Company;
