import React, { useState, useEffect } from 'react';
import { template as tmpl } from './InvoiceDetail.tmpl';
import { getInvoiceDetail } from 'redux/actions/companyAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const InvoiceDetailLogic = (props) => {
    const { location = {}, getInvoiceDetail } = props;

    const { search } = location;

    const [invoiceDetail, setInvoiceDetail] = useState({});

    /**
     * Get booking detail from backend
     */
    useEffect(() => {
        async function fetchInvoiceDetail() {
            let id = search;
            if (id && id[0] === '?') {
                id = id.substring(1);
            }

            const result = await getInvoiceDetail(id);
            if (result.success) {
                setInvoiceDetail(result.data || {});
            }
        }
        fetchInvoiceDetail();
    }, []);

    const navigate = (route, id, state) => {
        props.history.push({
            pathname: route,
            search: id,
            state: state
        });
    };

    const initProps = {
        invoiceDetail: invoiceDetail,
        navigate: navigate
    };

    return tmpl && tmpl({ ...initProps });
};

class InvoiceInvoiceContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <InvoiceDetailLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { customers, activeCompany } = companyReducer;
    return {
        customers: customers,
        activeCompany: activeCompany // TODO : fetch booking list on change company
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getInvoiceDetail: (id) => dispatch(getInvoiceDetail(id))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(InvoiceInvoiceContainer)
);
