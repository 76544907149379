import React from 'react';
import styled from 'styled-components';
import background from '../../assets/images/BG-image.jpg';
import 'antd/dist/antd.css';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import logo from '../../assets/images/logo/Logo_JetOS_04_horizontal.png';
import '../../assets/fonts/Avenir-Light/Avenir-Light.css';
import { LoginCard, RequestAccountCard } from '../../components';

const template = (props) => {
    const { t, i18n } = useTranslation();
    const { isRequestAccount, setIsRequestAccount } = props;
    return (
        <StyledBackground>
            <HeaderSection>
                <LogoSection>
                    <Logo src={logo} />
                </LogoSection>
                <CreateAccountSection></CreateAccountSection>
            </HeaderSection>
            <LoginCard
                {...props}
                isRequestAccount={isRequestAccount}
                setIsRequestAccount={setIsRequestAccount}
            />
            <FooterSection></FooterSection>
        </StyledBackground>
    );
};

const StyledBackground = styled.div`
    background-image: url(${background});
    height: 110vh;
    /* min-width: 100%; */
    min-height: 100%;
    background-size: cover;
    background-position: center;
    /* display: flex;
    flex-direction: column; */
    /* justify-content: space-between; */
    flex-wrap: wrap;
    .ant-row-center {
        padding-left: 3rem;
        padding-top: 1rem;
        padding-right: 3rem;
    }
`;

const HeaderSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 3rem;
    padding-top: 1rem;
    padding-right: 3rem;
    margin-bottom: 50px;
    width: 100%;
`;

const FooterSection = styled.div``;

const LogoSection = styled.div``;

const CreateAccountSection = styled.div`
    margin-top: auto;
    margin-bottom: auto;
`;

const Logo = styled.img`
    height: 50px;
    width: auto;
`;

export { template };
