import API from '../../modules/httpModule';
import { updateUserInformationFromAPI } from './userAction';
import {
    updateCompaniesList,
    updateActiveCompany,
    getCompanyById
} from './companyAction';
import {
    ACCESS_TOKEN,
    REFRESH_TOKEN,
    SESSION_ID,
    FCM_TOKEN,
    USER_INFO,
    UPDATE_EMPLOYEES_LIST,
    UPDATE_CUSTOMERS_LIST,
    CLEAR_DATA_APP,
    CLEAR_DATA_USER,
    CLEAR_DATA_COMPANY,
    UPDATE_COUNTRIES,
    UPDATE_NOTIFICATIONS,
    FETCH_MORE_NOTIFICATIONS,
    READ_NOTIFICATION,
    READ_ALL_NOTIFICATION,
    UPLOAD_MODEL,
    UPLOAD_IMAGE_TYPE,
    USER_TYPE
} from '../Constants';
import auth from 'services/auth';
import { setStorageData } from 'utils';
import { dataCountries } from 'services/country';

export function identify() {
    return async (dispatch) => {
        try {
            const response = await API.identify('/identify');
            if (response && response.status === 200 && response.data) {
                const session = response.data;

                if (session) {
                    const {
                        id = null,
                        user,
                        auth_token = null,
                        label = null
                    } = session;

                    if (id) {
                        await setStorageData(SESSION_ID, session.id);
                    }

                    // jwt token
                    if (auth_token) {
                        if (auth_token.access_token) {
                            await setStorageData(
                                ACCESS_TOKEN,
                                auth_token.access_token
                            );
                        }
                        if (auth_token.refresh_token) {
                            await setStorageData(
                                REFRESH_TOKEN,
                                auth_token.refresh_token
                            );
                        }
                    }

                    return {
                        userToken: session.id
                    };
                } else {
                    throw 'COULD_NOT_RETRIEVE_SESSION';
                }
            }
        } catch (err) {
            return {
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * Get curent user information.
 *
 * @returns {object} success is true if API reponse successfully. success is false if not
 * and the error message in false case.
 */
export function getCurrentUserInformation() {
    return (dispatch) => {
        return API.get('/user')
            .then(async (res) => {
                const response = res.data;
                const { active_company, companies } = response;

                await setStorageData(USER_INFO, response);

                // Store user iniformation before call user API
                dispatch(updateUserInformationFromAPI(response));
                if (companies) {
                    //Store user company list
                    dispatch(updateCompaniesList(response.companies));
                    if (active_company) {
                        // Store the active company
                        dispatch(updateActiveCompany(active_company));
                    }
                }

                // getUsers
                await Promise.all([
                    dispatch(getUsers({ type: USER_TYPE.CUSTOMER })),
                    dispatch(getUsers({ type: USER_TYPE.EMPLOYEE })),
                    dispatch(getCountries()),
                    dispatch(getNotifications())
                ]);

                return {
                    success: true
                };
            })
            .catch((errRes) => {
                let errors = '';
                if (errRes && errRes.errormsg) {
                    errors = errRes.errormsg[0];
                }
                return {
                    success: false,
                    errors: errors
                };
            });
    };
}

/**
 * Call API /login. If login successfully, Store access token, refresh token and session ID
 * to the local storage. Then store user information, companies list where user belong to
 * and the company that user is active on.
 * @param {object} data - username and password of user login
 * @returns
 */
export function signIn(data) {
    return (dispatch) => {
        return API.post_origin('/v2/login', data)
            .then(async (res) => {
                const response = res.data;
                const { success, user, auth_token, claims } = response;

                if (auth_token) {
                    if (auth_token.access_token) {
                        await setStorageData(
                            ACCESS_TOKEN,
                            auth_token.access_token
                        );
                    }
                    if (auth_token.refresh_token) {
                        await setStorageData(
                            REFRESH_TOKEN,
                            auth_token.refresh_token
                        );
                    }
                }

                // setup redux
                if (user) {
                    // set user info
                    await setStorageData(USER_INFO, user);
                }

                return {
                    success: true
                };
            })
            .catch((errRes) => {
                let errors = '';
                if (errRes && errRes.errormsg) {
                    errors = errRes.errormsg[0];
                }
                return {
                    success: false,
                    errors: errors
                };
            });
    };
}

export function requestAccount(data) {
    return (dispatch) => {
        return API.post_origin('/request-account', data)
            .then(async (res) => {
                return {
                    success: true
                };
            })
            .catch((errRes) => {
                let errors = '';
                if (errRes && errRes.errormsg) {
                    errors = errRes.errormsg[0];
                }
                return {
                    success: false,
                    errors: errors
                };
            });
    };
}

export function signOut() {
    return (dispatch) => {
        const fcmToken = auth.getFCMToken();
        const params = {
            device_token: { ...fcmToken }
        };
        return API.delete('/logout', params)
            .then((result) => {
                //Clear local storage
                localStorage.removeItem(USER_INFO);
                localStorage.removeItem(ACCESS_TOKEN);
                localStorage.removeItem(REFRESH_TOKEN);
                localStorage.removeItem(SESSION_ID);
                localStorage.removeItem(FCM_TOKEN);

                //Clear redux data
                dispatch({ type: CLEAR_DATA_USER });
                dispatch({ type: CLEAR_DATA_APP });
                dispatch({ type: CLEAR_DATA_COMPANY });
            })
            .catch((err) => {
                return err;
            });
    };
}
/**
 * Switch the active company by calling backend and update redux as well.
 * @param {Object} data - req body
 * @param {Object} data.company_id - company id want to switch to
 * @returns
 */
export function switchCompany(data) {
    return (dispatch, getState) => {
        return API.put('/session/company', data)
            .then(async (res) => {
                const { auth_token } = res.data;
                const { companyReducer } = getState();
                // const { companies } = companyReducer;

                await setStorageData(ACCESS_TOKEN, auth_token.access_token);
                await setStorageData(REFRESH_TOKEN, auth_token.refresh_token);

                // getUsers
                await Promise.all([
                    dispatch(getUsers({ type: USER_TYPE.CUSTOMER })),
                    dispatch(getUsers({ type: USER_TYPE.EMPLOYEE })),
                    dispatch(updateActiveCompany(data.company_id)),
                    dispatch(getNotifications())
                ]);

                return {
                    success: true
                };
            })
            .catch((errRes) => {
                let errors = {};
                if (errRes && errRes.error) {
                    let errs = errRes.error;

                    errs.forEach((err) => {
                        if (err === 'EMAIL' || err === 'PASSWORD') {
                            errors[err.toLowerCase()] = 'INCORRECT_LOGIN_INFO';
                        } else if (err === 'LOGIN' || err === 'UNAUTHORIZED') {
                            errors['email'] = 'INCORRECT_LOGIN_INFO';
                        }
                    });
                }
                return {
                    errors
                };
            });
    };
}

/**
 * get employee or customer from a company
 * @param {Object} data - req query, optional, ignore when get customer
 * @param {String} data.type - USER_TYPE, 'EMPLOYEE' to get employees, 'ALL' to get customers and employees
 * @returns - users as pagination
 */
export function getUsers(data) {
    return async (dispatch) => {
        try {
            const response = await API.get('/v2/users', data);
            if (response && response.status === 200 && response.data) {
                if (data.type && data.type === USER_TYPE.EMPLOYEE) {
                    const { docs } = response.data;
                    dispatch({ type: UPDATE_EMPLOYEES_LIST, employees: docs });
                }

                if (data.type && data.type === USER_TYPE.CUSTOMER) {
                    const { docs } = response.data;
                    // TODO: converse docs --> objects
                    dispatch({ type: UPDATE_CUSTOMERS_LIST, customers: docs });
                }
            }
        } catch (err) {
            return {
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * search countries
 * @param {string} key
 * @returns {Array}
 */
export function searchCountries(key) {
    return async (dispatch) => {
        try {
            const response = await API.get(`/search/countries?key=${key}`);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    countries: response.data || []
                };
            }
        } catch (err) {
            return {
                success: false,
                error: err,
                countries: []
            };
        }
    };
}

/**
 * create new customer
 * @param {Object} address
 * @param {string} email
 * @param {string} firstname
 * @param {string} lastname
 * @param {string} membership_id - default: null
 * @param {Object} meta - { company_name: string }
 * @param {string} phone
 * @param {string} type - value: "CLIENT" (Customer Role)
 * @param {Object} data.type - 'EMPLOYEE' to get employees, 'ALL' to get customers and employees
 * @returns {Object} - { id: string }
 */
export function createNewCustomer(data) {
    return async (dispatch) => {
        try {
            const response = await API.post('/companies/users', data);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    userId: response.data?.id
                };
            }
        } catch (err) {
            return {
                success: false,
                error: err
            };
        }
    };
}

/**
 * get employee or customer from a company
 * @param {Object} data - required to get a user's bookings , otherwise ignore
 * @param {Object} data.user_id - get user's booking
 * @returns - users as pagination
 */
export function getBookings(data) {
    return async (dispatch) => {
        try {
            // ignore data when get all booking of company
            const response = await API.get('/v2/bookings', data);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    bookings: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * get user detail infomation
 * @returns - user information
 */
export function getUserById(id, query) {
    return async (dispatch) => {
        try {
            const response = await API.get(`/users/${id}`, query);
            if (response && response.status === 200 && response.data) {
                return {
                    isConnected: true,
                    success: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * delete user by
 * @param {String} id - user id in string
 * @returns
 */
export function deleteUserById(id) {
    return async (dispatch) => {
        try {
            const response = await API.delete(`/user/${id}`, {
                date: new Date()
            });
            if (response && response.status === 200 && response.data) {
                return {
                    isConnected: true,
                    success: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * update user infomation
 * @param {Object} data - user object with modified data, only some field is editable
 * @returns - status success and code is 200
 */
export function updateUserById(id, data) {
    return async (dispatch) => {
        try {
            const response = await API.put(`/user/${id}`, data);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * validate user password
 * @param {string} password - user password
 * @returns status 200 if true, otherwise 403 or 500
 */
export function validatePassword(password) {
    return async (dispatch) => {
        try {
            const response = await API.put(`/user/validate_password`, {
                password
            });
            if (response && response.status === 200) {
                return { success: true, isConnected: true };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * get company services items
 * @returns - services items as pagination
 */
export function getServieItems() {
    return async (dispatch) => {
        try {
            const response = await API.get('/packages');
            if (response && response.status === 200 && response.data) {
                return {
                    isConnected: true,
                    success: true,
                    data: response.data.docs
                };
            } else {
                return {
                    isConnected: true,
                    success: false
                };
            }
        } catch (err) {
            return {
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * get company services items
 * @returns - services items as detail
 */
export function getServieItemById(id) {
    return async (dispatch) => {
        try {
            const response = await API.get(`/packages/${id}`);
            if (response && response.status === 200 && response.data) {
                return {
                    isConnected: true,
                    success: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                isConnected: false,
                success: false,
                error: err
            };
        }
    };
}

/**
 * create service item
 * @param {Object} data - item object
 * @returns - status success and code is 200
 */
export function createServiceItemBy(data) {
    return async (dispatch) => {
        try {
            const response = await API.post(`/packages`, data);
            if (response && response.status === 200) {
                return {
                    success: true,
                    isConnected: true,
                    id: response.data ? response.data.id : ''
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                success: false,
                error: err
            };
        }
    };
}

/**
 * upload image for service item
 * @param {string} id - id of service item
 * @param {Object} photo - file of image
 * @param {string} model - UPLOAD_MODEL type to upload
 * @param {string} type - type of UPLOAD_IMAGE_TYPE, default 'main'
 * @returns - status success and code is 200
 */
export function uploadImage(
    id,
    photo,
    model,
    type = UPLOAD_IMAGE_TYPE.MAIN,
    encrypted = true
) {
    return async (dispatch) => {
        try {
            let formData = new FormData();
            formData.append('file', photo);

            let response = await API.post(
                `/upload/image/${model}/${id}/${type}`,
                formData,
                {
                    'Content-Type': 'multipart/form-data'
                }
            );
            if (response && response.status === 200) {
                return {
                    success: true,
                    isConnected: true,
                    data: response.data,
                    url: response.data.url,
                    image: response.data.image
                };
            }
        } catch (e) {
            return {
                success: false,
                data: {},
                error: e
            };
        }
    };
}

/**
 * Upload identification
 * @param {String} userId
 * @param {Object} photo
 * @returns
 */
export function uploadIdentification(userId, photo) {
    return async (dispatch) => {
        try {
            let formData = new FormData();
            formData.append('file', photo);

            let response = await API.post(
                `/upload/image/users/${userId}/identification?encrypted=true`,
                formData,
                {
                    'Content-Type': 'multipart/form-data'
                }
            );

            if (response && response.status === 200) {
                return {
                    success: true,
                    isConnected: true,
                    data: response.data
                };
            }
        } catch (e) {}
    };
}

/**
 * Upload identification info
 * @param {String} userId
 * @param {Object} data
 * @returns
 */
export function updateIdentificationDoc(userId, data) {
    return async (dispatch) => {
        try {
            const response = await API.put(
                `/user/${userId}/identification`,
                data
            );
            if (response && response.status === 200) {
                return {
                    success: true,
                    isConnected: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * Get decrypted document
 * @param {String} id - document id
 * @returns
 */
export function getIdentification(id) {
    return async (dispatch) => {
        try {
            let response = await API.get(`/doc/${id}/display`, {
                base64: true
            });
            if (response && response.status === 200) {
                return response.data;
            }
        } catch (e) {
            return null;
        }
    };
}

/**
 * Request document authenication
 * @param {String} userId - user id
 * @param {Boolean} showDocumentId - get travel docs
 * @returns
 */
export function requestDocumentAuth(userId, showDocumentId = false) {
    return async (dispatch) => {
        try {
            let response = await API.put(
                `/users/${userId}/request_document?show_document_id=${showDocumentId}`
            );
            if (response && response.status === 200) {
                return {
                    success: true,
                    data: response.data.document || {}
                };
            }
        } catch (err) {
            return {
                success: false,
                data: {},
                error: err
            };
        }
    };
}

/**
 * update service item infomation
 * @param {Object} data - item object with modified data, only some field is editable
 * @returns - status success and code is 200
 */
export function updateServiceItemById(id, data) {
    return async (dispatch) => {
        try {
            const response = await API.put(`/packages/${id}`, data);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    isConnected: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * remove service item
 * @returns - status success and code is 200
 */
export function removeServiceItemById(id) {
    return async (dispatch) => {
        try {
            const response = await API.delete(`/packages/${id}`);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true
                };
            }
        } catch (err) {
            return {
                success: false,
                error: err
            };
        }
    };
}

/**
 * search aircraft by name, tail number, model
 * @param {String} input - country name
 * @returns - users as pagination
 */
export function searchServiceItem(input, available = false) {
    return async (dispatch) => {
        try {
            const query = { key: input, available: available };
            const response = await API.get('/search/packages', query);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    isConnected: true,
                    serviceList: response.data || []
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 *
 * @param {*} data new emplployee information: firstname, lastname, email, phone, type, address, date_of_birth.
 * In address object, it contains address_line1, address_line2, city, country object, state, postalcode
 * @returns
 */
export function addNewEmployee(data) {
    return async (dispatch) => {
        try {
            const response = await API.post('/companies/users', data);
            if (response && response.status === 200 && response.data) {
                return {
                    isConnected: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 *
 * @param {*} id delete emplployee.
 * @returns
 */
export function deleteEmployee(id) {
    return async (dispatch) => {
        try {
            const response = await API.delete('/user/' + id);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true
                };
            }
        } catch (err) {
            return {
                success: false,
                error: err
            };
        }
    };
}

/**
 * search country by name
 * @param {String} input - country name
 * @returns - users as pagination
 */
export function searchCountry(input) {
    return async (dispatch) => {
        try {
            const query = { key: input };
            const response = await API.get('/search/countries', query);
            if (response && response.status === 200 && response.data) {
                //
            }
        } catch (err) {
            return {
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 *
 * @param {*} data emplployee information to be updated: firstname, lastname, email, phone, type, address, date_of_birth.
 * @returns
 */
export function updateEmployee(id, data) {
    return async (dispatch) => {
        try {
            const response = await API.put(`/user/${id}`, data);
            if (response && response.status === 200 && response.data) {
                return {
                    isConnected: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * get all countries
 * @returns - all countries from db
 */
export function getCountries() {
    return async (dispatch) => {
        try {
            // let countries = auth.getCountries();
            let countries = dataCountries;

            if (!countries) {
                const response = await API.get('/countries');
                if (response && response.status === 200 && response.data) {
                    countries = response.data.countries || [];
                    auth.setCountries(countries);
                }
            }

            dispatch({ type: UPDATE_COUNTRIES, countries: countries });
        } catch (err) {
            return {
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * get all notifications
 * @returns - notifications
 */
export function getNotifications() {
    return async (dispatch) => {
        try {
            let notifications = {};
            const response = await API.get('/v2/notifications');
            if (response && response.status === 200 && response.data) {
                notifications = response.data || {};
            }
            if (!_.isEmpty(notifications)) {
                dispatch({
                    type: UPDATE_NOTIFICATIONS,
                    notifications: notifications
                });
            }
        } catch (err) {
            //
        }
    };
}

/**
 * get all notifications
 * @returns - notifications
 */
export function fetchMoreNotifications(next, limit) {
    return async (dispatch) => {
        try {
            let notifications = {};
            const response = await API.get_next(`${next}`);
            if (response && response.status === 200 && response.data) {
                notifications = response.data || {};
            }
            if (!_.isEmpty(notifications.docs)) {
                dispatch({
                    type: FETCH_MORE_NOTIFICATIONS,
                    notifications: notifications
                });
            }
        } catch (err) {
            //
        }
    };
}

/**
 * call api read notifications
 */
export function onReadNotification(id) {
    return async (dispatch) => {
        try {
            const response = await API.put(`/v2/notifications/read/${id}`);
            if (response && response.status === 200 && response.data) {
                dispatch({ type: READ_NOTIFICATION, notiId: id });
            }
        } catch (err) {
            //
        }
    };
}

/**
 * call api read all notifications
 */
export function onReadAllNotification() {
    return async (dispatch) => {
        try {
            const response = await API.put(`/notifications/read/all`);
            if (response && response.status === 200 && response.data) {
                dispatch({ type: READ_ALL_NOTIFICATION });
            }
        } catch (err) {
            //
        }
    };
}

/**
 * Upsert user device token
 * @param {String} id - user id
 * @param {Object} data - device token data
 * @param {String} data.token - device token
 * @param {String} data.os - jetos plaftform, 'sw' for 'chrome' or 'firefox'
 * @param {String} data.package_name - firebase FCM package name
 * @returns
 */
export function updateUserDeviceToken(id, data) {
    return async (dispatch) => {
        try {
            const response = await API.post(
                `/v2/user/${id}/device_tokens?v2=true`,
                data
            );
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

export function validateActivation(uid, activeId) {
    return async (dispatch) => {
        try {
            const data = {
                uid: uid,
                avt: activeId
            };
            const response = await API.get_origin(
                `/user/validate_activation`,
                data
            );
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

export function validateResetPasswordRequest(uid, activeId) {
    return async (dispatch) => {
        try {
            const data = {
                uid: uid,
                avt: activeId
            };
            const response = await API.get_origin(`/user/validate_reset`, data);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

export function activateAccount(uid, data) {
    return async (dispatch) => {
        try {
            const response = await API.put_origin(
                `/user/activate/${uid}`,
                data
            );
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

export function resetPasswordRequest(data) {
    return async (dispatch) => {
        try {
            const response = await API.put_origin(`/user/resetpassword`, data);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

export function resetPasswordWl(data) {
    return (dispatch) => {
        return API.put_origin('/user/resetpassword/wl', { ...data }, null)
            .then((res = {}) => {
                return {
                    success: true,
                    phone: res?.data?.phone_number,
                    email: res?.data?.email,
                    uid: res?.data?.uid,
                    avt: res?.data?.avt
                };
            })
            .catch((errRes = {}) => {
                if (errRes && errRes.error) {
                    let errs = errRes.error;
                    let errors = {};
                    let { errormsg } = errRes;

                    errs.forEach((err, index) => {
                        if (errormsg && errormsg.length) {
                            errors[err.toLowerCase()] = errormsg[index] || '';
                        }
                    });
                    return {
                        success: false,
                        errors
                    };
                }
            });
    };
}

export function updateNewPassword(uid, password, avt) {
    return (dispatch) => {
        return API.put_origin(`/user/reset/${uid}/wl`, {
            password: password,
            avt: avt
        })
            .then((response) => {
                if (response && response.status === 200 && response.data) {
                    return {
                        success: true,
                        data: response.data
                    };
                }
            })
            .catch((err) => {
                let errors = '';
                if (err && err.errormsg) {
                    errors = err.errormsg[0];
                } else {
                    errors = 'ERROR_ACCOUNT_VALIDATION';
                }
                return {
                    success: false,
                    errors: errors
                };
            });
    };
}

export function verifyOTPCode(uid, data) {
    return (dispatch) => {
        return API.put_origin(`/user/verify/${uid}/wl`, data)
            .then(async (usrRees) => {
                const response = usrRees.data;
                const { success, uid, avt } = response;

                return {
                    success: success,
                    uid: uid,
                    avt: avt
                };
            })
            .catch((err) => {
                let errors = '';
                if (err && err.errormsg) {
                    errors = err.errormsg[0];
                } else {
                    errors = 'ERROR_OTP_CAN_NOT_GET_VERIFICATION_CHECK';
                }
                return {
                    success: false,
                    errors: errors
                };
            });
    };
}

export function submitResetPassword(uid, data) {
    return async (dispatch) => {
        try {
            const response = await API.put_origin(`/user/reset/${uid}`, data);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * Get Whitelabel information.
 *
 * @returns {object} success is true if API reponse successfully. success is false if not
 * and the error message in false case.
 */
export function getWhitelabelInfomation() {
    return (dispatch) => {
        return API.get('/company/whitelabels')
            .then(async (res) => {
                return {
                    success: true,
                    data: res.data
                };
            })
            .catch((errRes) => {
                return {
                    success: false,
                    errors: errRes
                };
            });
    };
}

/**
 * Create Whitelabel.
 *
 * @returns {object} success is true if API reponse successfully. success is false if not
 * and the error message in false case.
 */
export function createWhitelabel(id, data) {
    return (dispatch) => {
        return API.post(`/companies/${id}/whitelabels`, data)
            .then(async (res) => {
                return {
                    success: true,
                    data: res.data
                };
            })
            .catch((errRes) => {
                return {
                    success: false,
                    errors: errRes
                };
            });
    };
}

/**
 * Get Whitelabel setting by Id.
 * @param {String} id - whitelabel ID
 * @returns {object} success is true if API reponse successfully. success is false if not
 * and the error message in false case.
 */
export function getWhitelabelById(id) {
    return (dispatch) => {
        return API.get(`/whitelabels/${id}`)
            .then(async (res) => {
                return {
                    success: true,
                    data: res.data
                };
            })
            .catch((errRes) => {
                return {
                    success: false,
                    errors: errRes
                };
            });
    };
}

/**
 * Get the font list.
 * @returns {object} success is true if API reponse successfully. success is false if not
 * and the error message in false case.
 */
export function getFontList(limit = 50) {
    return (dispatch) => {
        return API.get(`/companies/system/fonts`, { limit: limit })
            .then(async (res) => {
                return {
                    success: true,
                    data: res.data.data
                };
            })
            .catch((errRes) => {
                return {
                    success: false,
                    errors: errRes
                };
            });
    };
}

/**
 * upload Legal document for Whitelabel
 * @param {string} id - id of whitelabel
 * @param {Object} doc - document file
 * @param {string} type - type of UPLOAD_IMAGE_TYPE, default 'general'
 * @returns - status success and code is 200
 */
export function uploadDocument(id, doc, type = UPLOAD_IMAGE_TYPE.LEGAL) {
    return async (dispatch) => {
        try {
            let formData = new FormData();
            formData.append('file', doc);

            let response = await API.post(
                `/upload/document/whitelabels/${id}/${type}`,
                formData,
                {
                    'Content-Type': 'multipart/form-data'
                }
            );

            if (response && response.status === 200) {
                return {
                    success: true,
                    data: response.data?.url || ''
                };
            }
        } catch (e) {
            return {
                success: false,
                error: e
            };
        }
    };
}

/**
 * Update Whitelabel information.
 *
 * @returns {object} success is true if API reponse successfully. success is false if not
 * and the error message in false case.
 */
export function updateWhitelabelInfomation(id, data) {
    return (dispatch) => {
        return API.put(`/whitelabels/${id}`, data)
            .then(async (res) => {
                return {
                    success: true,
                    data: res.data
                };
            })
            .catch((errRes) => {
                return {
                    success: false,
                    errors: errRes
                };
            });
    };
}
