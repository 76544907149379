import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';

const CustomerContainer = styled.div`
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    .ant-header-custom-employees {
        background-color: #f2f4f7;
    }
`;

const SearchSection = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
    .ant-input {
        background-color: transparent;
    }
    .ant-input-affix-wrapper {
        border: 1px solid #dadadb;
        border-radius: 3px;
        width: 20vw;
        height: 40px;
        background-color: transparent;
    }
`;

const NameSectionButton = styled.button`
    display: flex;
    flex-direction: row;
    border: none;
    background-color: transparent;
`;

const DetailSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 5px;
`;

const TitleList = styled.span`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.7px;
    line-height: 16px;
    text-align: left;
`;

const BookingCode = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    letter-spacing: 0.88px;
    line-height: 13px;
    text-align: left;
`;

const TimeText = styled.span`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 11px;
    line-height: 18px;
    width: 147px;
    text-align: left;
`;

const TypeText = styled.span`
    color: #19c0ff;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
`;

const ItemText = styled.span`
    color: #35383f;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    align-self: center;
`;

const ColumnHeader = styled.span`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 11px;
    line-height: 13px;
`;

const MembershipText = styled.span`
    color: #19c0ff;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
`;

const TableSection = styled.div`
    margin-top: 20px;
`;

const TextItem = styled.div`
    color: ${(props) => props.seen ? '#0E0E12' : '#65686f'};
    font-family: ${(props) => props.seen ? 'AvenirNext-DemiBold' : 'Lato'};
    font-size: 11px;
    line-height: 16px;
    text-align: left;
    margin-top: 3px;
`;

const Title = styled.span`
    color: ${(props) => props.seen ? '#0E0E12' : '#65686f'};
    font-family: ${(props) => props.seen ? 'AvenirNext-DemiBold' : 'Lato'};
    font-size: 14px;
    line-height: 24px;
`;

const TitleHeader = styled.span`
    color: ${(props) => props.seen ? '#0E0E12' : '#65686f'};
    font-family: ${(props) => props.seen ? 'AvenirNext-Bold' : 'AvenirNext-DemiBold'};
    font-size: 12px;
    letter-spacing: 0.7px;
    line-height: 16px;
    text-align: left;
    margin-left: 18px;
    margin-top: auto;
    margin-bottom: auto;
`;

const ContainerDrawer = styled.div`
    margin-top: 20px;

    &:hover {
        background-color: #F7F8FB;
    }
`;

const WrapperDrawer = styled.div`
    border: 1px solid #dadadb;
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;

    &:hover {
        border: 1.5px solid #CDD1D8;
    }
`;

const WrapperListNoti = styled.div`
    background-color: #fff;
    border-radius: 4px;
    padding: 20px;
    &:hover {
        border: 1.5px solid #CDD1D8;
    }
`;

export {
    CustomerContainer,
    NameSectionButton,
    SearchSection,
    DetailSection,
    TitleList,
    BookingCode,
    TimeText,
    TypeText,
    ItemText,
    ColumnHeader,
    MembershipText,
    TableSection,
    TextItem,
    Title,
    TitleHeader,
    ContainerDrawer,
    WrapperDrawer,
    WrapperListNoti
};
