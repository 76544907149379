import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { Col, Row, Select, Skeleton } from 'antd';
import {
    WrapperComponent,
    MenuTitle,
    UploadButton,
    ButtonText,
    Container,
    BottomContainer,
    Label
} from '../styles';
import { InputPasswordModal } from 'components/Modals';
import { documentTypes } from 'config/Constants';
import { Input as CustomInput, UploadComponent, ButtonCustom } from 'components';
import { HeaderInfo } from 'components/Cards';
import { getBase64 } from 'utils';
import moment from 'moment';

import travelDocsFake from 'assets/images/travel-docs-fake.png';
import _ from 'lodash';

const { Option } = Select;

const Identification = (props) => {
    const {
        userInfo = {},
        getAuthenIdentity,
        uploadIdentification,
        identificationImage
    } = props;
    const { identity = {} } = userInfo;
    const { identified } = identity;

    const { t, i18n } = useTranslation();

    const [loading, setLoading] = useState(false);

    const [isEdit, setIsEdit] = useState(false);
    const [isVerify, setIsVerify] = useState(false);
    const [visible, setVisible] = useState(false);
    const [titlePaswordModal, setTitlePasswordModal] = useState('');
    const [error, setError] = useState('');

    const [documentType, setDocumentType] = useState('');
    const [documentNumber, setDocumentNumber] = useState('');
    const [issueDate, setIssueDate] = useState('');
    const [expireDate, setExpireDate] = useState('');
    const [photo, setPhoto] = useState(null);
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        if (!_.isEmpty(userInfo.identity)) {
            const { identity_document = {} } = userInfo.identity;
            setDocumentType(identity_document.document_type || '');
            setDocumentNumber(identity_document.document_number || '');
            setIssueDate(identity_document.issue_date || '');
            setExpireDate(identity_document.expiration_date || '');
        }
    }, [userInfo, userInfo.identity]);

    useEffect(() => {
        if (identificationImage) {
            setImageUrl(identificationImage ? `data:image/png;base64,${identificationImage}` : '');
        }
    }, [identificationImage]);

    const onClose = () => {
        setTitlePasswordModal('');
        setVisible(false);
    };

    const onSaveChanges = async () => {
        try {
            let data = {
                document_id: '',
                document_type: documentType,
                issue_country: '',
                issue_date: issueDate,
                document_number: documentNumber,
                expiration_date: expireDate,
                photo: photo
            };
            await uploadIdentification(data);
        } catch (e) {}
    };

    const onValidateForm = () => {
        if (
            !imageUrl ||
            !documentType ||
            !documentNumber ||
            !issueDate ||
            !expireDate
        ) {
            return false;
        }
        return true;
    };

    const onSubmit = async (val) => {
        setError('');
        const rs = await getAuthenIdentity(val, identified);
        if (rs.success) {
            setIsVerify(true);
            setTitlePasswordModal('');
            setVisible(false);
        } else {
            setError(t('ERROR_PASSWORD'));
        }
    };

    const handleChange = ({ file, fileList }) => {
        setPhoto(file);

        const index = fileList.length;
        getBase64(fileList[index - 1].originFileObj, (imageUrl) => {
            setImageUrl(imageUrl);
        });
    };

    const _renderNoIdentification = () => {
        return (
            <Container>
                <Row className="identity-none">
                    <Col lg="12" sm="12" xs="12">
                        <Title
                            style={{ marginBottom: 3, textAlign: 'center' }}
                        >
                            {i18n.t('USER_HAVE_NO_IDEN')}
                        </Title>
                        <UploadButton onClick={() => {
                            setTitlePasswordModal(t('ENTER_PASSWORD_UPLOAD_IDENTIFICATION').toUpperCase());
                            setVisible(true);
                        }}>
                            <ButtonText>
                                {i18n
                                    .t('UPLOAD_IDENTIFIACTION')
                                    .toUpperCase()}
                            </ButtonText>
                        </UploadButton>
                    </Col>
                </Row>
            </Container>
        );
    }

    const _renderTravelDocs = () => {
        return (
            <div style={{ marginBottom: 15 }}>
                <Body style={{ paddingLeft: 25 }}>
                    <IdentificationCard 
                        identified={identified}
                        url={!isVerify ? travelDocsFake : imageUrl}
                        style={{ filter: !isVerify ? 'blur(4px)' : 'blur(0)' }}
                    />
                    <Wrapper>
                        <InfoItemWrapper>
                            <TitleSection>
                                {i18n.t('TYPE_OF_DOCUMENT')}
                            </TitleSection>
                            <TextSection>{documentType ? t(documentType).toUpperCase() : '_'}</TextSection>
                        </InfoItemWrapper>
                        <InfoItemWrapper>
                            <TitleSection>{i18n.t('DOCUMENT_NUMBER')}</TitleSection>
                            <TextSection>{documentNumber || '_'}</TextSection>
                        </InfoItemWrapper>
                        <InfoItemWrapper>
                            <TitleSection>{i18n.t('ISSUE_DATE')}</TitleSection>
                            <TextSection>{issueDate ? moment(issueDate).format('DD MMM YYYY').toUpperCase() : '_'}</TextSection>
                        </InfoItemWrapper>
                        <InfoItemWrapper>
                            <TitleSection>{i18n.t('EXPIRE_DATE')}</TitleSection>
                            <TextSection>{expireDate ? moment(expireDate).format('DD MMM YYYY').toUpperCase() : '_'}</TextSection>
                        </InfoItemWrapper>
                    </Wrapper>
                </Body>

                { !isVerify && 
                    <Body style={{ paddingLeft: 25, paddingBottom: 15 }}>
                        <ButtonCustom
                            btnType="secondary"
                            size="small"
                            width="220px"
                            onClick={() => {
                                setTitlePasswordModal(t('ENTER_PASSWORD_UNCLOCK').toUpperCase());
                                setVisible(true);
                            }}
                            title={i18n.t('UNLOCK_TO_VIEW').toUpperCase()}
                        />
                    </Body>
                }
            </div>
        );
    };

    const _renderCreateTravelDocs = () => {
        return (
            <div>
                <Row style={{ marginLeft: 25, marginTop: 15 }}>
                    <Label>
                        {i18n.t('DOCUMENT_INFORMATION').toUpperCase()}
                    </Label>
                </Row>
                <Row style={{ marginLeft: 25 }}>
                    <Col lg="6" sm="6" xs="12">
                        <Select
                            className="ant-select-custom"
                            value={documentType || null}
                            style={{
                                height: '50px',
                                width: '330px'
                            }}
                            placeholder={
                                <SelectPlaceholder>
                                    {t('CHOOSE_DOCUMENT_TYPE')}
                                </SelectPlaceholder>
                            }
                            onChange={(value) => {
                                setDocumentType(value);
                            }}
                        >
                            <Option value={documentTypes.PASSPORT}>
                                {t(documentTypes.PASSPORT)}
                            </Option>
                            <Option value={documentTypes.ID_CARD}>
                                {t(documentTypes.ID_CARD)}
                            </Option>
                            <Option value={documentTypes.DRIVER_LICENSE}>
                                {t(documentTypes.DRIVER_LICENSE)}
                            </Option>
                        </Select>
                    </Col>
                </Row>
                <Row style={{ marginLeft: 25 }}>
                    <Col lg="3" sm="3" xs="12" style={{ marginRight: 20, marginTop: 20 }}>
                        <CustomInput
                            label={t('DOCUMENT_NUMBER').toUpperCase()}
                            onChange={(val) => setDocumentNumber(val)}
                            placeholder={t('DOCUMENT_NUMBER')}
                            value={documentNumber}
                            style={{ marginRight: 20, marginTop: 24 }}
                        />
                    </Col>
                    <Col lg="3" sm="3" xs="12" style={{ marginRight: 20, marginTop: 20 }}>
                        <CustomInput
                            label={t('ISSUE_DATE').toUpperCase()}
                            datePicker
                            value={issueDate}
                            className="base"
                            placeholder={t('YYYY/MM/DD')}
                            onChange={(date, dateString) =>
                                setIssueDate(dateString)
                            }
                        />
                    </Col>
                    <Col lg="3" sm="3" xs="12" style={{ marginRight: 20, marginTop: 20 }}>
                        <CustomInput
                            label={t('EXPIRE_DATE').toUpperCase()}
                            datePicker
                            value={expireDate}
                            className="base"
                            placeholder={t('YYYY/MM/DD')}
                            onChange={(date, dateString) =>
                                setExpireDate(dateString)
                            }
                        />
                    </Col>
                </Row>
                <Row style={{ marginLeft: 25, marginTop: 25 }}>
                    <Col>
                        <TitleUpload>
                            {t('UPLOAD_PHOTOS').toUpperCase()}
                        </TitleUpload>
                        <TitleUpload>{t('NOTICE_CHOOSE_FILE')}</TitleUpload>
                    </Col>
                </Row>
                <Row>
                    <UploadSection>
                        <UploadComponent
                            imageUrl={imageUrl}
                            handleChange={handleChange}
                            loading={loading}
                        />
                    </UploadSection>
                </Row>
                {isVerify && (
                    <BottomContainer style={{ paddingBottom: 0 }}>
                        <ButtonCustom
                            btnType="primary"
                            disabled={!onValidateForm()}
                            width="190px"
                            onClick={() => onSaveChanges()}
                            title={i18n.t('SUBMIT').toUpperCase()}
                        />
                    </BottomContainer>
                )}
            </div>
        );
    };

    return (
        <WrapperComponent style={{ margin: 15 }}>
            <HeaderInfo
                title={i18n.t('IDENTIFICATION').toUpperCase()}
            />
            <Component>
                {/* No identification */}
                {(!identified && !isVerify) && 
                    _renderNoIdentification()
                }

                {/* show create/update travel docs */}
                {((!identified && isVerify) || (isEdit && isVerify)) && 
                    _renderCreateTravelDocs()
                }

                {/* show travel docs identification */}
                {(identified) &&
                    _renderTravelDocs()
                }
            </Component>

            {/* modal */}
            <InputPasswordModal
                title={titlePaswordModal}
                show={visible}
                onCancel={onClose}
                onSubmit={onSubmit}
                error={error}
            />
        </WrapperComponent>
    );
};

export default Identification;

const IdentificationCard = styled.img`
    background-image: url(${(props) => props.url});
    background-size: 100%;
    background-color: Transparent;
    background-repeat: no-repeat;
    border-radius: 2px;
    width: 220px;
    height: 133px;
    margin-top: 15px;
`;

const Body = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
`;

const Wrapper = styled.div`
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    margin-top: 20px;
`;

const TitleSection = styled.div`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 11px;
    line-height: 13px;
    text-align: left;
    width: 120px;
`;

const TextSection = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    text-align: left;
    margin-left: 25px;
`;

const InfoItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    margin-bottom: 10px;
    align-self: flex-start;
    justify-content: center;
    align-items: center;
`;

const Title = styled.div`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
`;

const Component = styled.div`
    flex: row;
    background-color: #ffffff;
    border-radius: 4px;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
    min-height: 100px;

    .ant-select-custom .ant-select-selector {
        height: ${(props) => (props.height ? props.height : '50px')};
        border-radius: 3px;
    }
`;

const InfoWrapper = styled.div`
    background-color: #f6f9fc;
    border-radius: 4px 4px 0 0;
    height: 50px;
    padding-left: 25px;
    padding-top: 15px;
`;

const TitleUpload = styled.div`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 12px;
`;

const SelectPlaceholder = styled.span`
    color: #8e929a;
    font-family: Lato;
    font-size: 14px;
    line-height: 17px;
`;

const UploadSection = styled.div`
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: 25px;
`;
