import 'react-image-crop/dist/ReactCrop.css'
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'antd';
import ReactCrop from 'react-image-crop';
import { isEmpty } from 'lodash';
import { getBase64 } from 'utils';

const ReactCropImage = (props) => {
    const { 
        uploadFile,
        isVisible = false,
        onOk,
        onCancel,
        aspectRatio,
        maxWidthCrop,
    } = props;
    
    const { file, fileList } = uploadFile;
    const uploadImgRef = useRef(null);
    const [crop, setCrop] = useState();
    const [croppedImageSize, setCroppedImageSize] = useState({});
    const [croppedMaxSize, setCroppedMaxSize] = useState({});
    const [croppedFile, setcroppedFile] = useState({});
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        setcroppedFile(uploadFile);
        setCrop({});
        getBase64(file, (imageUrl) => {
            setImageUrl(imageUrl);
        });
    }, [file])

    useEffect(() => {
        if (uploadImgRef?.current) {
            const { naturalWidth, width, height } = uploadImgRef?.current;
            const maxWidth = maxWidthCrop / (naturalWidth / width);
            const maxHeight = aspectRatio ? (maxWidth / aspectRatio) : height;
            setCroppedImageSize({});
            setCroppedMaxSize({ maxWidth, maxHeight });
        }
    }, [uploadImgRef?.current]);
    
    const handleCompleteCropImage = async ({ crop, sourceImage, file }) => {
        const { uid, name, type = 'image/jpeg' } = file;
        const croppedImage = await getCroppedImage({ sourceImage, cropConfig: crop, fileName: name, fileType: type });
        if (croppedImage) {
            const { fileBlob, newWidth, newHeight } = croppedImage;
            setCroppedImageSize({ width: newWidth, height: newHeight });

            const newFileList = fileList.map((f) => f.uid === uid ? fileBlob : f);

            setcroppedFile({ file: fileBlob, fileList: newFileList });
        }
    }

    const getCroppedImage = ({ sourceImage, cropConfig, fileName, fileType }) => {
        const canvas = document.createElement('canvas');
        const scaleX = sourceImage.naturalWidth / sourceImage.width;
        const scaleY = sourceImage.naturalHeight / sourceImage.height;
        const cropConfigY = aspectRatio ? (cropConfig.y * scaleX) : (cropConfig.y * scaleY);

        const newWidth = Math.floor(cropConfig.width * scaleX);
        const newHeight = Math.floor(aspectRatio ? (newWidth / aspectRatio) : (cropConfig.height * scaleY));

        canvas.width = newWidth;
        canvas.height = newHeight;

        const ctx = canvas.getContext('2d');

        ctx.drawImage(sourceImage, cropConfig.x * scaleX, cropConfigY, newWidth, newHeight, 0, 0, newWidth, newHeight);

        if (ctx.getImageData(1, 1, 1, 1)?.data?.some((channel) => channel !== 0)) {
            return new Promise((resolve, reject) => {
                canvas.toBlob(
                    (blob) => {
                        if (!blob) {
                            reject(new Error('Canvas is empty'));
                            return;
                        }
    
                        const fileBlob = new File([blob], fileName, { type: fileType });
                        resolve({ fileBlob, newWidth, newHeight });
                    }, fileType , 1
                );
            });
        };
    }

    return (
        <Modal
            width={800}
            centered
            bodyStyle={{ maxHeight: 600, overflow: 'auto' }}
            title="Modal Crop Image"
            visible={isVisible}
            onOk={() => onOk(croppedFile)}
            onCancel={onCancel}
        >
            <ReactCrop
                style={{ overflowY: 'auto' }}
                ruleOfThirds
                aspect={aspectRatio}
                maxWidth={croppedMaxSize.maxWidth}
                maxHeight={croppedMaxSize.maxHeight}
                crop={crop}
                onComplete={(crop) => handleCompleteCropImage({ crop, sourceImage: uploadImgRef?.current, file })}
                onChange={c => setCrop(c)}
            >
                <img ref={uploadImgRef} src={imageUrl} alt="ReactCropImage"/>
            </ReactCrop>
            {!isEmpty(croppedImageSize) &&
                <div style={{ position: 'absolute', bottom: 15, left: 24 }}>
                    Width: {croppedImageSize.width} - Height: {croppedImageSize.height}
                </div>
            }
        </Modal>
    );
};

export default ReactCropImage;
