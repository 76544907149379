import React, { useState } from 'react';
import { Button, Row, Col } from 'antd';
import styled from 'styled-components';
import { Text } from 'components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { jetIcon } from 'assets/images/icons';

const FlightInfoCard = (props) => {
    const { ref_basic_info = {} } = props;
    const {
        aircraft = {},
        from_airport = {},
        to_airport = {},
        code = '',
        departure = {},
        arrival = {}
    } = ref_basic_info;
    const { name = '', tail_number = '' } = aircraft;
    const { t, i18n } = useTranslation();

    return (
        <Wrapper>
            <Col>
                <Row
                    style={{
                        borderRight: '1px solid rgba(14, 14, 18, 0.1)',
                        height: '100%',
                        alignItems: 'center'
                    }}
                >
                    <Col>
                        <Row>
                            <Text textStyle="tableText1">{t('BOOKING')}</Text>
                        </Row>
                        <Row>
                            <Text
                                textStyle="tableText2"
                                style={{ letterSpacing: 0, marginTop: 5 }}
                            >
                                #{code}
                            </Text>
                        </Row>
                    </Col>
                    <Col
                        style={{
                            marginLeft: 40,
                            paddingRight: 40
                        }}
                    >
                        <Row>
                            <Text textStyle="tableText1">{t('AIRCRAFT')}</Text>
                        </Row>
                        <Row>
                            <Text
                                textStyle="tableText2"
                                style={{ letterSpacing: 0, marginTop: 5 }}
                            >
                                {name ? name.toUpperCase() : '_'}
                            </Text>
                            {tail_number && (
                                <Text
                                    textStyle="tableText2"
                                    style={{
                                        letterSpacing: 0,
                                        marginLeft: 10,
                                        marginTop: 5,
                                        color: '#19C0FF',
                                        fontFamily: 'AvenirNext-Bold'
                                    }}
                                >
                                    {tail_number.toUpperCase()}
                                </Text>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col>
                <Row style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <Col style={{ paddingLeft: 20 }}>
                        <Row style={{ alignItems: 'center' }}>
                            <Text
                                textStyle="heading3"
                                style={{
                                    letterSpacing: 1.5,
                                    marginRight: 10
                                }}
                            >
                                {from_airport?.icao_code || ''}
                            </Text>
                            <Text textStyle="tableText1">
                                {from_airport?.name || ''}
                            </Text>
                        </Row>
                        <Row>
                            <Text
                                textStyle="tableText2"
                                style={{ letterSpacing: 0 }}
                            >
                                {departure && departure.utc_datetime
                                    ? moment(departure.utc_datetime).format(
                                          'dddd, MMM DD, YYYY / hh:mm A'
                                      )
                                    : ''}
                            </Text>
                        </Row>
                    </Col>
                    <Col
                        style={{
                            paddingLeft: 30,
                            paddingRight: 30,
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Row justify="center" align="middle">
                            <Col>
                                <Dashed style={{ marginRight: 5 }} />
                            </Col>
                            <Col>
                                <img
                                    src={jetIcon}
                                    style={{ height: 25, width: 26 }}
                                />
                            </Col>
                            <Col>
                                <Dashed style={{ marginLeft: 5 }} />
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row style={{ alignItems: 'center' }}>
                            <Text
                                textStyle="heading3"
                                style={{
                                    letterSpacing: 1.5,
                                    marginRight: 10
                                }}
                            >
                                {from_airport?.icao_code || ''}
                            </Text>
                            <Text textStyle="tableText1">
                                {from_airport?.name || ''}
                            </Text>
                        </Row>
                        <Row>
                            <Text
                                textStyle="tableText2"
                                style={{ letterSpacing: 0 }}
                            >
                                {arrival && arrival.utc_datetime
                                    ? moment(arrival.utc_datetime).format(
                                          'dddd, MMM DD, YYYY / hh:mm A'
                                      )
                                    : ''}
                            </Text>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Wrapper>
    );
};

export default FlightInfoCard;

const Wrapper = styled(Row)`
    border-radius: 4px;
    background-color: #f9fafc;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
`;

const Dashed = styled.div`
    border: 1px dashed rgba(14, 14, 18, 0.2);
    width: 100px;
`;

const TailorCode = styled.span`
    color: #19c0ff;
    font-family: AvenirNext-Bold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: center;
`;
