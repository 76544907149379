import React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { ButtonCustom, Text, localeEmptyTable } from 'components';
import { ButtonType } from 'components/Antd/Button';
// import { DetailSection, BookingCode, TimeText, TypeText } from '../styles';
import { ColumnHeader } from 'styles/shareStyles';
import { formatTimeBooking, getPaymentStatus } from 'utils';
import {
    BookingStatus,
    JetInfo,
    FlightInfo,
    Booker,
    PaymentStatus
} from 'components/Commons';
import styled from 'styled-components';
import { BOOKING_DETAIL, BOOKING_LIST } from 'routes/main';

const NewBookingTable = (props) => {
    const {
        navigate,
        list = {},
        key,
        setKey,
        loading = false,
        onChangePageNewBooking
    } = props;
    const { docs = [], limit, page, pages, total = 1 } = list;
    const haveData = docs.length ? true : false;
    const { t, i18n } = useTranslation();

    const onClickBookingList = () => {
        navigate(BOOKING_LIST);
    };

    const columns = [
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('BOOKING') + ' #'}</ColumnHeader>;
            },
            width: '15%',
            dataIndex: 'booking_code',
            key: 'booking_code',
            render: function eachItemBooking(booking_code) {
                return <BookingCode>{booking_code}</BookingCode>;
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('CREATED_AT')}</ColumnHeader>;
            },
            width: '25%',
            dataIndex: 'created_at',
            key: 'created_at',
            render: function eachItemCreatedAt(created_at) {
                return (
                    <TimeText>
                        {created_at ? formatTimeBooking(created_at) : '_'}
                    </TimeText>
                );
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('REQUESTED_BY')}</ColumnHeader>;
            },
            width: '25%',
            dataIndex: 'user_basic_info',
            key: 'user_basic_info',
            render: function eachType(user, record) {
                let owner = {};
                let isCompany = false;
                if (record.company) {
                    isCompany = true;
                    owner = {
                        ...record.company,
                        company_id: record.company.id
                    };
                } else {
                    owner = user;
                }
                return <Booker user={owner} isCompany={isCompany} />;
            }
        },

        {
            title: function titleHeader() {
                return <ColumnHeader>{t('PAYMENT_STATUS')}</ColumnHeader>;
            },
            dataIndex: 'payment_status',
            key: 'payment_status',
            width: '25%',
            render: function eachItemStatus(payment_status) {
                return (
                    <PaymentStatus status={getPaymentStatus(payment_status)} />
                );
            }
        },

        {
            key: 'action',
            width: '15%',
            render: function setAction(val) {
                return (
                    <DetailSection>
                        <ButtonCustom
                            btnType={ButtonType.SECONDARY}
                            title={t('DETAILS').toUpperCase()}
                            size="small"
                            borderColor="1px solid rgba(18, 18, 22, 0.2)"
                            width="86px"
                            onClick={(e) => navigate(BOOKING_DETAIL, val._id)}
                        />
                    </DetailSection>
                );
            }
        }
    ];

    return (
        <TableContainer haveData={haveData}>
            <TableHeader>
                <Text textStyle="heading5">{`${t(
                    'LATEST_BOOKINGS'
                ).toUpperCase()} (${total})`}</Text>
                <ViewAllButton onClick={onClickBookingList}>
                    <Text textStyle="upload">
                        {t('VIEW_ALL_BOOKINGS').toUpperCase()}
                    </Text>
                </ViewAllButton>
            </TableHeader>
            <Table
                columns={columns}
                dataSource={docs}
                style={{ marginTop: 20 }}
                scroll={{ x: 620 }}
                // loading={loading}
                pagination={false}
                locale={localeEmptyTable()}
                // pagination={{
                //     defaultPageSize: 5,
                //     total: pages * 5 - 1,
                //     hideOnSinglePage: false,
                //     showSizeChanger: false
                // }}
                onChange={(e) => onChangePageNewBooking(e)}
            />
        </TableContainer>
    );
};

export default NewBookingTable;

const TableContainer = styled.div`
    @media (min-width: 1025px) {
        .ant-table {
            height: 440px;
        }
        .ant-table-content {
            height: 440px;
        }
    }
    .ant-table-tbody > tr > td {
        border-bottom: ${(props) =>
            props.haveData ? '1px solid #f0f0f0' : '0px'};
    }
`;

const ViewAllButton = styled.button`
    border: none;
    background-color: transparent;
    cursor: pointer;
`;

const TableHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const DetailSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 5px;
`;

const BookingCode = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    letter-spacing: 0.88px;
    line-height: 13px;
    text-align: left;
`;

const TimeText = styled.span`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 11px;
    line-height: 18px;
    width: 147px;
    text-align: left;
`;

const TypeText = styled.span`
    color: #19c0ff;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
`;
