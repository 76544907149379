import React, { useState, useEffect } from 'react';
import { template as tmpl } from './PartnersDetail.tmpl';
import { connect } from 'react-redux';
import { getCompanyById } from '../../../redux/actions/companyAction';
import { withRouter } from 'react-router-dom';

const PartnersDetailLogic = (props) => {
    const { user, companies = [], getCompanyById, location = {} } = props;
    const { search } = location;

    const [partnerDetail, setPartnerDetail] = useState({});

    useEffect(() => {
        const fetchPartnerDetail = async () => {
            let id = search;
            if (id && id[0] === '?') {
                id = id.substring(1);
            }
            const rs = await getCompanyById(id);
            if (rs && rs.success) {
                setPartnerDetail(rs.data);
            }
        };

        fetchPartnerDetail();
    }, []);

    const navigate = (route) => {
        props.history.push(route);
    };

    const initProps = {
        user: user,
        companies: companies,
        navigate: navigate,
        partnerDetail: partnerDetail
    };

    return tmpl && tmpl({ ...initProps });
};

class PartnersDetailContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <PartnersDetailLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { userReducer, companyReducer } = state;
    const { companies } = companyReducer;
    return {
        user: userReducer,
        companies: companies
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCompanyById: (id) => dispatch(getCompanyById(id))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PartnersDetailContainer)
);
