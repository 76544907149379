import React, { useState, useEffect } from 'react';
import { template as tmpl } from './FBODetail.tmpl';
import { connect } from 'react-redux';
import {
    getFBOById,
    getFBOs,
    searchFBO
} from '../../../redux/actions/companyAction';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

const FBODetailLogic = (props) => {
    const {
        user,
        companies = [],
        getFBOById,
        location = {},
        getFBOs,
        searchFBO,
        activeCompanyId
    } = props;
    const { search } = location;

    const [fboDetail, setFBODetail] = useState({});
    const [fboLocations, setFBOLocations] = useState([]);
    const [companyId, setCompanyId] = useState('');

    useEffect(() => {
        const fetchFBODetail = async () => {
            let id = search;
            if (id && id[0] === '?') {
                id = id.substring(1);
                setCompanyId(id);
            }
            const rs = await getFBOById(id);
            if (rs && rs.success) {
                setFBODetail(rs.data);
            }
        };

        fetchFBODetail();
    }, [search]);

    const navigate = (route, id, state) => {
        setFBOLocations([]);
        props.history.push({
            pathname: route,
            search: id,
            state: state
        });
        window.location.reload();
    };

    const fetchFBOLocations = async (id, loadMore = false) => {
        // TODO: loadMore === true --> concat new FBOs to fboLocations
        if (!_.isEmpty(fboLocations) && !loadMore) return;

        let query = { exclude_head_quarter: true, company_id: id };
        const rs = await getFBOs(query);
        if (rs && rs.success) {
            setFBOLocations(rs.data || []);
        }
    };

    /**
     * Function handles searching when user is typing
     * @param {*} e event when user is typing
     */
    const onSearchFBO = async (id, e) => {
        const input = e.target.value.toLowerCase();
        let result = [];
        if (!input) {
            let query = { exclude_head_quarter: true, company_id: id };
            const rs = await getFBOs(query);
            if (rs && rs.success) result = rs.data;
        } else {
            let query = { company_id: id, type: 'FBO' };
            const rs = await searchFBO(input, query);
            if (rs && rs.success) result = rs.data;
        }

        setFBOLocations(result || []);
    };

    const initProps = {
        user: user,
        companies: companies,
        navigate: navigate,
        fboDetail: fboDetail,
        fetchFBOLocations: fetchFBOLocations,
        fboLocations: fboLocations,
        onSearchFBO: onSearchFBO,
        activeCompanyId: activeCompanyId
    };

    return tmpl && tmpl({ ...initProps });
};

class FBODetailContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <FBODetailLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { userReducer, companyReducer } = state;
    const { companies, activeCompany = {} } = companyReducer;
    return {
        user: userReducer,
        companies: companies,
        activeCompanyId: activeCompany.id
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFBOById: (id) => dispatch(getFBOById(id)),
        getFBOs: (data) => dispatch(getFBOs(data)),
        searchFBO: (key, query) => dispatch(searchFBO(key, query))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(FBODetailContainer)
);
