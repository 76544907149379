import React, { useState } from 'react';
import { template as tmpl } from './SearchAirports.tmpl';
import { connect } from 'react-redux';
import { searchAirport } from 'redux/actions/aircarftAction';

const SearchAirportsLogic = (props) => {
    const {
        label = '',
        prefix,
        airport = {},
        onSelectAirport,
        dropdownStyle,
        inputStyle = {},
        searchAirport,
        width,
        height,
        placeholder,
        allowClear = true,
        onClear,
        defaultValue,
        disabled = false
    } = props;

    const onSearchAirports = async (key = '') => {
        let airports = [];
        if (!key) return airports;

        let rs = await searchAirport(key);
        if (rs && rs.success) {
            airports = rs.airports;
        }
        return airports;
    };

    return (
        tmpl &&
        tmpl({
            label,
            prefix,
            airport,
            onSelectAirport,
            onSearchAirports,
            allowClear,
            onClear,
            dropdownStyle,
            inputStyle,
            width,
            height,
            placeholder,
            defaultValue,
            disabled
        })
    );
};

class SearchAirports extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <SearchAirportsLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    return {
        //
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        searchAirport: (key) => dispatch(searchAirport(key))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchAirports);
