import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Row, Col } from 'antd';
import {
    FlightStatusCard,
    ServiceItem,
    Map2,
    AircraftTypeCard,
    PackageDetailCard,
    Tabs,
    PassengerInfoContainer,
    ButtonCustom,
    FlightNotes,
    Text
} from 'components';
import { allowedRolesCreateQuote } from 'config/Constants';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { groupBy } from 'utils';
import { CREATE_QUOTATION, BOOKING_DETAIL } from 'routes/main';
import { QUOTE_REQUEST_STATUS } from 'redux/Constants';
import { FLIGHT_TYPES } from 'config/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuoteRequest } from 'redux/actions/quoteAction';
import { CancelRequestModal, ModalPackageInvoice } from '../components';
import { QuotesListContainer } from '../styles';
import { isUserAdminOrOperator } from 'redux/selectors/user';

const initPackageInvoice = {
    show: false,
    packages: [],
    type: '' // FLIGHT_TYPES
};

const template = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const companyReducer = useSelector((state) => state.companyReducer || {});
    const permissionCreateQuote = useSelector(isUserAdminOrOperator());
    const { employees = [], activeCompany = {} } = companyReducer;
    const { quoteDetail = {}, navigate, setRefreshPage } = props;
    const { role = '', roles = [] } = activeCompany;
    const {
        user = {},
        aircraft_categories = [],
        id,
        status,
        type,
        packages = [],
        legs = [],
        booking,
        created_at,
        meta
    } = quoteDetail;

    const { firstname = '', lastname = '' } = user;
    const [focusedLeg, setFocusedLeg] = useState('');
    const [listOfAircraftInPackages, setListOfAircraftInPackages] = useState(
        []
    );
    const [listOfAirports, setListOfAirports] = useState([]);
    const [visibleCancelModal, setVisibleCancelModal] = useState(false);
    const [showPackageInvoice, setShowPackageInvoice] =
        useState(initPackageInvoice);

    useEffect(() => {
        let legIndex = 0;
        let legId = '';
        let aircraftList = [];
        if (
            status === QUOTE_REQUEST_STATUS.ACCEPTED ||
            status === QUOTE_REQUEST_STATUS.COMPLETED
        ) {
            switch (type) {
                case FLIGHT_TYPES.RETURN:
                    legIndex = focusedLeg === 'RETURN' ? 1 : 0;
                    break;
                case FLIGHT_TYPES.MULTI_LEG:
                    legIndex = parseInt(focusedLeg[4]);
                    break;
                default:
                    break;
            }
            const {
                legs = [],
                packages = [],
                selected_package_index = 0
            } = quoteDetail;
            const { id = '' } = legs[legIndex] || {};
            const { aircraft = {}, price_components = {} } =
                packages[selected_package_index].find(
                    (eachPackage) => eachPackage.leg_id === id
                ) || {};
            let newAircraft = {
                ...aircraft,
                price: price_components,
                packageIndex: selected_package_index
            };
            setListOfAircraftInPackages([newAircraft]);
        } else {
            switch (type) {
                case FLIGHT_TYPES.RETURN:
                    legIndex = focusedLeg === 'RETURN' ? 1 : 0;
                    legId = legs[legIndex].id || '';
                    aircraftList = packages.map((eachPackage, index) => {
                        const legIndexInEachPackage = eachPackage.findIndex(
                            (eachLeg) => eachLeg.leg_id === legId
                        );
                        return {
                            ...eachPackage[legIndexInEachPackage].aircraft,
                            price: eachPackage[legIndexInEachPackage]
                                .price_components,
                            packageIndex: index
                        };
                    });
                    setListOfAircraftInPackages(aircraftList);
                    break;
                case FLIGHT_TYPES.MULTI_LEG:
                    legIndex = parseInt(focusedLeg[4]);
                    legId = legs[legIndex].id || '';
                    aircraftList = packages.map((eachPackage, index) => {
                        const legIndexInEachPackage = eachPackage.findIndex(
                            (eachLeg) => eachLeg.leg_id === legId
                        );
                        return {
                            ...eachPackage[legIndexInEachPackage].aircraft,
                            price: eachPackage[legIndexInEachPackage]
                                .price_components,
                            packageIndex: index
                        };
                    });
                    setListOfAircraftInPackages(aircraftList);
                    break;
                default:
                    break;
            }
        }
    }, [focusedLeg]);

    useEffect(() => {
        if (
            status === QUOTE_REQUEST_STATUS.ACCEPTED ||
            status === QUOTE_REQUEST_STATUS.COMPLETED
        ) {
            if (type === FLIGHT_TYPES.ONEWAY) {
                const { selected_package_index = 0 } = quoteDetail;

                let aircraft = {
                    ...packages[selected_package_index][0].aircraft,
                    price: packages[selected_package_index][0].price_components,
                    packageIndex: selected_package_index
                };
                setListOfAircraftInPackages([aircraft]);
            }
        } else {
            if (type === FLIGHT_TYPES.ONEWAY) {
                const aircraftList = packages.map((eachPackage, index) => {
                    return {
                        ...eachPackage[0].aircraft,
                        price: eachPackage[0].price_components,
                        packageIndex: index
                    };
                });
                setListOfAircraftInPackages(aircraftList);
            }
        }

        const { legs = [] } = quoteDetail;
        const listPositions = legs.map((leg) => {
            const { from_airport = {}, to_airport = {} } = leg;
            return {
                from_airport: from_airport,
                to_airport: to_airport
            };
        });
        setListOfAirports(listPositions);
    }, [quoteDetail]);

    const openModalPackage = (pkIndex) => {
        if (pkIndex < 0) {
            return;
        }

        // open modal
        setShowPackageInvoice({
            show: true,
            packages: packages[pkIndex],
            type: type
        });
    };

    const onCancelPackageInvoce = () => {
        setShowPackageInvoice({ ...initPackageInvoice });
    };

    const renderOneway = (leg = {}) => {
        const {
            from_airport = {},
            to_airport = {},
            services = [],
            notes = []
        } = leg;
        const seperatedServices = groupBy(services, 'type');

        return (
            <QuoteDetailContainer>
                <FlightStatusCardContainer>
                    <FlightStatusCard
                        flightType={
                            t('QUOTE_REQUEST').toUpperCase() +
                            ' / ' +
                            (quoteDetail.type
                                ? t(quoteDetail.type).toUpperCase()
                                : '')
                        }
                        requestCode={quoteDetail.request_code || ''}
                        status={quoteDetail.status || ''}
                        reqeustSendBy={firstname + ' ' + lastname}
                        from_airport={from_airport}
                        to_airport={to_airport}
                        created_at={created_at}
                        leg={leg}
                    />
                </FlightStatusCardContainer>
                <Row>
                    {status === QUOTE_REQUEST_STATUS.ACCEPTED ||
                    status === QUOTE_REQUEST_STATUS.COMPLETED ? (
                        <PackageDetailCard
                            listOfAircraftInPackages={listOfAircraftInPackages}
                            openModalPackage={openModalPackage}
                        />
                    ) : (
                        <>
                            {packages.length > 0 ? (
                                <PackageDetailCard
                                    listOfAircraftInPackages={
                                        listOfAircraftInPackages
                                    }
                                    openModalPackage={openModalPackage}
                                />
                            ) : (
                                <AircraftTypeCard
                                    aircraft_categories={aircraft_categories}
                                />
                            )}
                        </>
                    )}
                </Row>
                <div
                    style={{
                        marginTop: 40,
                        justifyContent: 'space-between',
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        flexGrow: 'inherit'
                    }}
                >
                    <EachCol style={{ marginRight: 10 }}>
                        <ServiceItem services={seperatedServices.STANDARD} />
                    </EachCol>
                    <EachCol style={{ marginLeft: 10, marginRight: 10 }}>
                        <ServiceItem
                            label={t('CUSTOM_SERVICE').toUpperCase()}
                            type="CUSTOM"
                            services={seperatedServices.CUSTOM}
                        />
                    </EachCol>
                    <EachCol style={{ marginLeft: 10 }}>
                        <FlightNotes
                            id={id}
                            legId={leg?.id}
                            notes={notes}
                            type="quote"
                            label={t('FLIGHT_NOTES').toUpperCase()}
                        />
                    </EachCol>
                </div>
                <Row style={{ marginTop: 70 }}>
                    <PassengerInfoContainer passengers={leg.passengers || 0} />
                </Row>
            </QuoteDetailContainer>
        );
    };

    const renderReturn = (legs) => {
        const tabRenderer = [
            {
                key: 'DEPARTURE',
                label: t('DEPARTURE').toUpperCase(),
                leg: legs[0],
                render: renderOneway(legs[0])
            },
            {
                key: 'RETURN',
                label: t('RETURN').toUpperCase(),
                leg: legs[1],
                render: renderOneway(legs[1])
            }
        ];
        return <Tabs tabRenderer={tabRenderer} onChange={setFocusedLeg} />;
    };

    const renderMultiLegs = (legs) => {
        const tabRenderer = legs.map((leg, index) => {
            return {
                key: 'LEG_' + index,
                label: 'LEG ' + (index + 1),
                leg: leg,
                render: renderOneway(leg)
            };
        });
        return <Tabs tabRenderer={tabRenderer} onChange={setFocusedLeg} />;
    };

    const renderContent = () => {
        const { legs } = quoteDetail;
        switch (quoteDetail.type) {
            case FLIGHT_TYPES.ONEWAY:
                return renderOneway(legs[0]);
            case FLIGHT_TYPES.RETURN:
                return renderReturn(legs);
            case FLIGHT_TYPES.MULTI_LEG:
                return renderMultiLegs(legs);
            default:
                return;
        }
    };

    const renderActionButtons = () => {
        // let havePermission = false;
        // for (let i = 0; i < roles.length; i++) {
        //     if (allowedRolesCreateQuote.includes(roles[i])) {
        //         havePermission = true;
        //         break;
        //     }
        // }
        // if (!havePermission) {
        //     return null;
        // }
        if (!permissionCreateQuote) {
            return null;
        }

        if (status === 'ACTIVE') {
            return (
                <CreateQuoteSection>
                    <div style={{ marginRight: 10 }}>
                        <ButtonCustom
                            btnType="secondary"
                            title={t('CANCEL_REQUEST').toUpperCase()}
                            size="large"
                            width="220px"
                            onClick={() => setVisibleCancelModal(true)}
                        />
                    </div>
                    <div style={{ marginLeft: 10 }}>
                        <ButtonCustom
                            btnType="primary"
                            size="large"
                            width="220px"
                            title={t('CREATE_QUOTATION').toUpperCase()}
                            onClick={() => navigate(CREATE_QUOTATION, id)}
                        />
                    </div>
                </CreateQuoteSection>
            );
        }

        if (booking) {
            return (
                <CreateQuoteSection>
                    <ButtonCustom
                        btnType="primary"
                        size="large"
                        width="220px"
                        title={t('VIEW_BOOKING').toUpperCase()}
                        onClick={() => navigate(BOOKING_DETAIL, booking)}
                    />
                </CreateQuoteSection>
            );
        }
    };

    const cancelQuote = async (reason) => {
        setVisibleCancelModal(true);
        const payload = {
            type: 'STATUS',
            status: 'CANCELLED'
            // reason: reason
        };
        if (reason) payload['reason'] = reason;
        const result = await dispatch(updateQuoteRequest(id, payload));
        if (result.success) {
            setVisibleCancelModal(false);
            setRefreshPage(true);
        }
    };

    const getUserCancel = () => {
        const userCancel = employees.find(
            (x) => x.id === meta.cancelled_by_user_id
        );
        if (userCancel) {
            return userCancel.firstname + ' ' + userCancel.lastname;
        } else {
            return activeCompany.name || '';
        }
    };

    return (
        <div>
            <Map2 listOfAirports={listOfAirports} type={quoteDetail.type} />

            <QuotesListContainer
                style={{
                    paddingTop: 40
                }}
            >
                {status === 'CANCELLED' && (
                    <div
                        style={{
                            padding: '15px 20px',
                            backgroundColor: '#ffffff',
                            borderLeft: '2px solid #DD4558',
                            marginBottom: 30
                        }}
                    >
                        <Row>
                            <Text
                                style={{
                                    fontFamily: 'AvenirNext-DemiBold',
                                    fontSize: '15px',
                                    color: '#DD4558'
                                }}
                            >
                                {t('QUOTE_DECLINED') + getUserCancel()}
                            </Text>
                        </Row>
                        <Row>
                            <Text style={{ fontSize: 14 }}>
                                {meta.cancellation_reason}
                            </Text>
                        </Row>
                    </div>
                )}
                {renderContent()}
                {renderActionButtons()}
            </QuotesListContainer>

            {/* modal */}
            <ModalPackageInvoice
                type={showPackageInvoice.type}
                show={showPackageInvoice.show}
                packages={showPackageInvoice.packages}
                onCancel={onCancelPackageInvoce}
            />

            <CancelRequestModal
                show={visibleCancelModal}
                onSave={cancelQuote}
                onCancel={() => setVisibleCancelModal(false)}
            />
        </div>
    );
};

export { template };

const CreateQuoteSection = styled(Row)`
    margin-top: 30px;
    justify-content: center;
`;

const QuoteDetailContainer = styled.div``;

const EachCol = styled(Col)`
    padding-right: 10px;
    flex: 1;
`;

const FlightStatusCardContainer = styled.div`
    padding-top: 10px;
    padding-bottom: 40px;
`;
