// All user related database operations can be defined here.

import { SYSTEM_ERROR } from "../config/Constants";
import axios from 'axios'

const GET_ALL_USERS = () => `/data/users`;
const GET_USER_DETAILS = (id) => `/data/user`;

/**
 * Function to fetch all the users.
 */
export const getAllUsers = () => {
  return new Promise((resolve, reject) => {
    try {
      // do an SDK, DB call or API endpoint axios call here and return the promise.
      axios
      .get(GET_ALL_USERS())
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject("Error in getAllUsers axios!");
      });
    } catch (error) {
      console.error("in userServices > getAllUsers, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

/**
 * Function to fetch the details of the user based on userId.
 * @param {string} userid of the user.
 * early dev example passing Skeleton(static object) as API response.
 */
export const getUserDetails = (id) => {
  return new Promise((resolve, reject) => {
    try {
      // do an SDK, DB call or API endpoint axios call here and return the promise.
      axios
      .get(GET_USER_DETAILS(id))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject("Error in getUserDetails axios!");
      });
    } catch (error) {
      console.error("in userServices > getUserDetails, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

// TODO: Dummy service - delete this.
export const getUserDetails1 = (id) => {
  return new Promise((resolve, reject) => {
    try {
      // do db call or API endpoint axios call here and return the promise.
      resolve({
          "id": "30",
          "firstName": "Joel",
          "lastName": "Joseph",
          "gender": "Male",
          "age": 33,
          "isActiveEmployee": true,
          "location": "London"
      })
    } catch (error) {
      console.error("in userServices > getUserDetails1, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

