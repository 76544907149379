import React from 'react';
import styled from 'styled-components';
import { Text } from '../index';
import { numberFormat } from '../../utils';
import { min } from 'lodash';

const PriceRange = ({ price_range = {} }) => {
    const { min_price = {}, max_price = {} } = price_range;

    return (
        <PriceRangeContainer>
            {/* <JetName>{name ? name.toUpperCase() : '_'}</JetName>
            <TailorCode>
                {tail_number ? tail_number.toUpperCase() : '_'}
            </TailorCode> */}
            <Text textStyle="dateTimeBold">
                {(min_price.value && min_price.currency
                    ? numberFormat(min_price.value, min_price.currency)
                    : '-') +
                    ' - ' +
                    (max_price.value && max_price.currency
                        ? numberFormat(max_price.value, max_price.currency)
                        : '-')}
            </Text>
        </PriceRangeContainer>
    );
};

const PriceRangeContainer = styled.div``;

const Price = styled.span``;

const JetName = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    line-height: 12px;
    width: 152px;
    text-align: left;
`;

const TailorCode = styled.div`
    border: 1px solid #dadadb;
    border-radius: 2px;
    width: 58px;
    height: 20px;
    color: #19c0ff;
    font-family: AvenirNext-Bold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    width: 56px;
    text-align: center;
    padding: 3px;
`;

export default PriceRange;
