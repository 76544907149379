import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { Row, Col, Switch } from 'antd';
import '../services.css';
import { WrapperComponent } from '../styles';
import { HeaderSerices, CountryItem } from '../../../components/Cards';
import _ from 'lodash';
import { AutoComplete } from '../../../components';
import { SELECT_TYPE } from '../../../redux/Constants';
import { useSelector } from 'react-redux';
import { getWindowDimensions } from 'utils';

const Availability = (props) => {
    const {
        isEdit,
        onClickEdit,
        all_countries_available,
        setAvailability,
        countries = []
    } = props;
    const { t, i18n } = useTranslation();
    const { companyReducer = {} } = useSelector((state) => state);

    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const onSelectCountry = (value) => {
        if (_.isEmpty(value)) return;

        let isExist = countries.some(
            (v) => v.english_name === value.english_name
        );
        if (isExist) return;

        let values = countries;
        values.push(value);

        setAvailability({
            all_countries_available: all_countries_available,
            countries: values
        });
    };

    const onSearchCountries = (key) => {
        const { countries } = companyReducer;

        const input = key.toLowerCase();
        const result = countries.filter((item) => {
            if (
                item.english_name.toLowerCase().includes(input) ||
                item.short_code.toLowerCase().includes(input)
            ) {
                return item;
            }
        });
        return result || [];
    };

    const onRemove = (val) => {
        let values = countries.filter(
            (v) => v.english_name !== val.english_name
        );
        setAvailability({
            all_countries_available: all_countries_available,
            countries: values
        });
    };

    const onChangeChecked = () => {
        setAvailability({
            all_countries_available: !all_countries_available,
            countries: countries
        });
    };

    const _renderEditView = () => {
        return (
            <Component>
                <Row className="row-edit-service">
                    <ItemWrapper>
                        <ItemTitle>
                            {i18n
                                .t('AVAILABLE_FOR_ALL_COUNTRIES')
                                .toUpperCase()}
                        </ItemTitle>
                        <Switch
                            checked={all_countries_available}
                            style={{ marginLeft: 5 }}
                            onChange={() => onChangeChecked()}
                        />
                    </ItemWrapper>
                </Row>
                {!all_countries_available && (
                    <>
                        <InfoWrapper>
                            <TypeTitle>
                                {i18n.t('ADD_AVAILABLE_COUNTRIES')}
                            </TypeTitle>
                        </InfoWrapper>
                        <CountriesWrapper>
                            <Row>
                                <Col
                                    lg="3"
                                    sm="3"
                                    xs="12"
                                    style={{ marginBottom: 10, marginTop: 25 }}
                                >
                                    <AutoComplete
                                        clearAfterSearch={true}
                                        placeholder={i18n.t('SEARCH_COUNTRIES')}
                                        inputStyle={{
                                            width:
                                                windowDimensions.width > 768
                                                    ? 370
                                                    : 300,
                                            // marginTop: 10,
                                            // marginLeft: 10,
                                            height: 50
                                        }}
                                        onSelectItem={onSelectCountry}
                                        type={SELECT_TYPE.COUNTRY}
                                        onSearchItems={onSearchCountries}
                                    />
                                </Col>
                            </Row>
                        </CountriesWrapper>
                        <CountriesList>
                            {countries.map((val, i) => {
                                return (
                                    <CountryItem
                                        onRemove={onRemove}
                                        val={val}
                                        key={i}
                                        isFinal={countries.length === i + 1}
                                    />
                                );
                            })}
                        </CountriesList>
                    </>
                )}
            </Component>
        );
    };

    const _renderDetailView = () => {
        return (
            <Component>
                <Row className="row-detail-service">
                    <ItemWrapper>
                        <ItemTitle>
                            {i18n
                                .t('AVAILABLE_FOR_ALL_COUNTRIES')
                                .toUpperCase()}
                        </ItemTitle>
                        <Switch
                            checked={all_countries_available}
                            style={{ marginLeft: 5 }}
                        />
                    </ItemWrapper>
                </Row>
                <CountriesList>
                    {!all_countries_available &&
                        countries.map((val, i) => {
                            return (
                                <CountryItem
                                    onRemove={onRemove}
                                    val={val}
                                    key={i}
                                    isFinal={countries.length === i + 1}
                                />
                            );
                        })}
                </CountriesList>
            </Component>
        );
    };
    return (
        <WrapperComponent>
            <HeaderSerices
                title={i18n.t('AVAILABILITY').toUpperCase()}
                isUpdate={isEdit}
                onClick={onClickEdit}
            />
            {!isEdit ? _renderDetailView() : _renderEditView()}
        </WrapperComponent>
    );
};

export default Availability;

const Component = styled.div`
    flex: row;
    background-color: #ffffff;
    border-radius: 4px;
    margin-top: 15px;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
    min-height: 65px;
`;

const ItemWrapper = styled.span`
    min-width: 182px;
    height: 30px;
`;

const ItemTitle = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 18px;
    text-align: left;
    margin-right: 50px;
`;

const InfoWrapper = styled.div`
    background-color: #f6f9fc;
    border-radius: 4px 4px 0 0;
    height: 50px;
    padding-left: 25px;
    padding-top: 15px;
`;

const TypeTitle = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 17px;
    text-align: left;
`;

const CountriesWrapper = styled.div`
    margin-left: 15px;
`;

const CountriesList = styled.div`
    margin-bottom: 15px;
    margin-left: 25px;
`;
