import React, { useState, useEffect } from 'react';
import { template as tmpl } from './PartnersContainer.tmpl';
import { connect } from 'react-redux';

const PartnersLogic = (props) => {
    const { activeCompany = {}, companies = [] } = props;

    const [partnersSearch, setPartnersSearch] = useState([]);
    const [partnersList, setPartnersList] = useState([]);

    const { partnerships = [] } = activeCompany;

    /**
     * Get partners list from backend
     */
    useEffect(() => {
        const initData = async () => {
            if (partnerships) {
                let rs = companies.filter((r) =>
                    partnerships.some((v) => v._company === r.id)
                );

                setPartnersSearch(rs);
                setPartnersList(rs);
            }
        };

        initData();
    }, [companies, partnerships]);

    /**
     * Function handles searching when user is typing
     * @param {*} e event when user is typing
     */
    const onSearch = (e) => {
        const input = e.target.value.toLowerCase();
        const result = partnersSearch.filter((item) => {
            if (
                (item.name && item.name.toLowerCase().includes(input)) ||
                (item.email && item.email.toLowerCase().includes(input))
            ) {
                return item;
            }
        });
        setPartnersList(result);
    };

    const navigate = (route, id, state) => {
        props.history.push({
            pathname: route,
            search: id,
            state: state
        });
    };

    const initProps = {
        partnersList: partnersList,
        onSearch: onSearch,
        navigate: navigate
    };

    return tmpl && tmpl({ ...initProps });
};

class PartnersContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <PartnersLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { activeCompany, companies } = companyReducer;
    return {
        activeCompany: activeCompany,
        companies: companies
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        //
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnersContainer);
