import React, { useState } from 'react';
import styled from 'styled-components';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import * as dates from './dates';
import { useTranslation } from 'react-i18next';
import { Text, DropDownInput } from '../../index';
import 'antd/dist/antd.css';
import moment from 'moment';
import {
    LeftOutlined,
    RightOutlined,
    DownOutlined,
    UserOutlined
} from '@ant-design/icons';
import {
    jetIcon,
    JetIcon,
    EventIcon,
    iconArrowDown,
    IconForward,
    IconBack,
    ArrowPointToRight,
    IconArrowDown
} from 'assets/images/icons';
import { Dropdown, Button, Menu, Popover, Row, Col } from 'antd';

const localizer = momentLocalizer(moment);

const now = new Date();

class CalendarToolbar extends Toolbar {
    constructor(props) {
        super(props);
        this.state = {
            currentView: 'Month'
        };
    }

    componentDidMount() {
        const view = this.props.view;
    }

    render() {
        return (
            <ToolbarContainer>
                <NavigateDate>
                    <BackButton
                        type="button"
                        onClick={() => this.navigate('PREV')}
                    >
                        <IconBackContainer src={IconBack} />
                    </BackButton>
                    <BackButton
                        type="button"
                        onClick={() => this.navigate('TODAY')}
                    >
                        <Text
                            textStyle="tableText1"
                            style={{ color: '#8E929A' }}
                        >
                            Today
                        </Text>
                    </BackButton>
                    <BackButton
                        type="button"
                        onClick={() => this.navigate('NEXT')}
                    >
                        <IconForwardContainer src={IconForward} />
                    </BackButton>
                </NavigateDate>
                <Text textStyle="dateTimeBold" style={{ fontSize: 18 }}>
                    {this.props.label.toUpperCase()}
                </Text>
                <div className="rbc-btn-group">
                    {/* <DropDownInput height={'32px'} /> */}
                    <Dropdown
                        overlay={
                            <Menu
                                onClick={(e) => {
                                    this.setState({
                                        currentView: e.key
                                    });
                                }}
                            >
                                <Menu.Item key="Month">
                                    <SelectViewButton
                                        type="button"
                                        onClick={this.view.bind(null, 'month')}
                                    >
                                        <Text
                                            textStyle="tableText1"
                                            style={{ color: '#8E929A' }}
                                        >
                                            Month
                                        </Text>
                                    </SelectViewButton>
                                </Menu.Item>
                                <Menu.Item key="Week">
                                    <SelectViewButton
                                        type="button"
                                        onClick={this.view.bind(null, 'week')}
                                    >
                                        <Text
                                            textStyle="tableText1"
                                            style={{ color: '#8E929A' }}
                                        >
                                            Week
                                        </Text>
                                    </SelectViewButton>
                                </Menu.Item>
                            </Menu>
                        }
                    >
                        <Button>
                            <Text
                                textStyle="tableText1"
                                style={{ color: '#8E929A' }}
                            >
                                {this.state.currentView}
                            </Text>
                            {/* <IconArrowDown
                                style={{
                                    height: 15,
                                    width: 15,
                                    color: '#8e929a'
                                }}
                            /> */}
                            <IconDownContainer
                                src={iconArrowDown}
                                style={{ color: '#8e929a' }}
                            />
                        </Button>
                    </Dropdown>
                </div>
            </ToolbarContainer>
        );
    }
}

class SubCalendarToolbar extends Toolbar {
    componentDidMount() {
        const view = this.props.view;
    }

    render() {
        return (
            <ToolbarContainer>
                <Text textStyle="dateTimeBold" style={{ marginBottom: 14 }}>
                    {this.props.label.toUpperCase()}
                </Text>
            </ToolbarContainer>
        );
    }
}

/**
 *
 * @param {*} props
 * @returns
 */
const BigCalendarComponent = (props) => {
    const { events = [] } = props;
    const [selectedDate, setSelectedDate] = useState(moment().toDate());
    const [currentView, setCurrentView] = useState('month');

    let allViews = Object.keys(Views).map((k) => Views[k]);

    const ColoredDateCellWrapper = ({ children, value }) => {
        let dateOnlyValue = new Date(value);
        dateOnlyValue.setHours(0, 0, 0, 0);
        let dateOnlySelectedDate = new Date(moment(selectedDate).toDate());
        dateOnlySelectedDate.setHours(0, 0, 0, 0);

        const isSelected =
            dateOnlyValue.valueOf() === dateOnlySelectedDate.valueOf();
        // const isPastDay = new Date(value).valueOf() < new Date().valueOf();

        return React.cloneElement(React.Children.only(children), {
            style: {
                ...children.style,
                backgroundColor: isSelected ? '#F7F8FB' : 'white',
                // borderRight: '1px solid #e5e9f0',
                // borderBottom: '1px solid #e5e9f0'
                borderLeft: isSelected
                    ? '1px solid rgba(18, 18, 22, 0.15)'
                    : 'none',
                borderRight: isSelected
                    ? '1px solid rgba(18, 18, 22, 0.15)'
                    : 'none'
            }
        });
    };

    const CustomEvent = ({ event }) => {
        // const { event = {} } = props;
        const { title = '', from_airport = {}, to_airport = {}, end } = event;
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        let dateOnlyValue = new Date(end);
        dateOnlyValue.setHours(0, 0, 0, 0);
        const isPastDay = dateOnlyValue.valueOf() < today.valueOf();

        return (
            <EventContainer
                isVertical={currentView === 'month' || currentView === 'week'}
                pastEvent={isPastDay}
            >
                <FlightCode>
                    {/* <img src={jetIcon} /> */}
                    <EventIcon
                        fill={isPastDay ? '#19c0ff' : '#ffffff'}
                        style={{ width: 14, height: 14 }}
                    />
                    <Text
                        style={{
                            fontFamily: 'AvenirNext-Bold',
                            color: isPastDay ? '#121216' : '#ffffff',
                            fontSize: '10px',
                            letterSpacing: '0.5px',
                            paddingLeft: 6
                        }}
                    >
                        #{title}
                    </Text>
                </FlightCode>
                <FromToAirport>
                    <Text
                        style={{
                            fontFamily: 'AvenirNext-DemiBold',
                            color: isPastDay ? '#121216' : '#ffffff',
                            fontSize: '10px',
                            letterSpacing: '0.5px',
                            paddingLeft: 6
                        }}
                    >
                        {from_airport.icao_code + ' - ' + to_airport.icao_code}
                    </Text>
                </FromToAirport>
            </EventContainer>
        );
    };
    const ContentPopup = ({ event = {} }) => {
        const { t, i18n } = useTranslation();
        const {
            title = '',
            from_airport = {},
            to_airport = {},
            aircraft = {}
        } = event;
        return (
            <div style={{ padding: '0 10px' }}>
                <Row style={{ padding: 2 }}>
                    <Text
                        style={{
                            fontSize: '11px',
                            fontFamily: 'AvenirNext-DemiBold',
                            letterSpacing: 0.37
                        }}
                    >
                        {t('FLIGHT')}
                    </Text>
                    <Text
                        style={{
                            paddingLeft: 5,
                            fontSize: '11px',
                            fontFamily: 'AvenirNext-DemiBold',
                            letterSpacing: 0.37,
                            color: '#19c0ff'
                        }}
                    >
                        {'#' + title}
                    </Text>
                </Row>
                <Row justify="space-around" style={{ padding: 2 }}>
                    <Col span={8} style={{ textAlign: 'center' }}>
                        <Text textStyle="airportName">
                            {from_airport.icao_code || ''}
                        </Text>
                    </Col>
                    <Col span={8} style={{ textAlign: 'center' }}>
                        <ArrowPointToRight
                            style={{
                                height: 12,
                                width: 18
                            }}
                        />
                    </Col>
                    <Col span={8} style={{ textAlign: 'center' }}>
                        <Text textStyle="airportName">
                            {to_airport.icao_code || ''}
                        </Text>
                    </Col>
                </Row>
                <Row style={{ padding: 2 }}>
                    <Text
                        textStyle="tableText2"
                        style={{
                            fontSize: 11,
                            letterSpacing: 0
                        }}
                    >
                        {aircraft.name || ''}
                    </Text>
                </Row>
                <Row style={{ padding: 2 }}>
                    <Text
                        textStyle="jetCode"
                        style={{
                            border: 'none',
                            padding: 0,
                            textAlign: 'left'
                        }}
                    >
                        {aircraft.tail_number || ''}
                    </Text>
                </Row>
            </div>
        );
    };

    const TitlePopup = ({ event }) => {
        const {
            title = '',
            from_airport = {},
            to_airport = {},
            end,
            start
        } = event;
        return (
            <div
                className="titlePopup"
                style={{ height: '100%', width: '100%', padding: '5px 10px' }}
            >
                <Row
                    style={{
                        borderBottom: '1px solid #D8D8D8',
                        paddingBottom: 5
                    }}
                >
                    <Text
                        style={{
                            fontFamily: 'AvenirNext-DemiBold',
                            fontSize: 11,
                            fontWeight: '600',
                            letterSpacing: 0.37,
                            color: '#121216'
                        }}
                    >
                        {moment(start).format('hh:mm A').toUpperCase() +
                            ' - ' +
                            moment(end).format('hh:mm A').toUpperCase()}
                    </Text>
                </Row>
            </div>
        );
    };

    const CustomEventInSubCalendar = ({ event }) => {
        // const { event = {} } = props;
        const { title = '', from_airport = {}, to_airport = {}, end } = event;
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        let dateOnlyValue = new Date(end);
        dateOnlyValue.setHours(0, 0, 0, 0);
        const isPastDay = dateOnlyValue.valueOf() < today.valueOf();
        return (
            <Popover
                overlayClassName="calendarPopover"
                content={<ContentPopup event={event} />}
                title={<TitlePopup event={event} />}
                trigger="click"
            >
                <EventContainerSubCalendar pastEvent={isPastDay}>
                    <FlightCode>
                        <EventIcon
                            fill={isPastDay ? '#19c0ff' : '#ffffff'}
                            style={{ width: 14, height: 14 }}
                        />
                        <Text
                            style={{
                                fontFamily: 'AvenirNext-Bold',
                                color: isPastDay ? '#121216' : '#ffffff',
                                fontSize: '10px',
                                letterSpacing: '0.5px',
                                paddingTop: 2,
                                paddingLeft: 6
                            }}
                        >
                            #{title}
                        </Text>
                    </FlightCode>
                    <FromToAirport>
                        <Text
                            style={{
                                fontFamily: 'AvenirNext-DemiBold',
                                color: isPastDay ? '#121216' : '#ffffff',
                                fontSize: '10px',
                                letterSpacing: '0.5px',
                                paddingTop: 2
                            }}
                        >
                            {from_airport.icao_code +
                                ' - ' +
                                to_airport.icao_code}
                        </Text>
                    </FromToAirport>
                </EventContainerSubCalendar>
            </Popover>
        );
    };

    return (
        <CalendarContainer>
            <MainCalendar>
                <Calendar
                    selectable={true}
                    events={events}
                    views={['month', 'week', 'work_week', 'agenda']}
                    step={30}
                    showMultiDayTimes
                    // max={dates.add(
                    //     dates.endOf(new Date(2015, 17, 1), 'day'),
                    //     -1,
                    //     'hours'
                    // )}
                    // showAllEvents={false}
                    popup={true}
                    defaultDate={moment().toDate()}
                    // defaultDate={new Date(2015, 3, 1)}
                    components={{
                        dateCellWrapper: ColoredDateCellWrapper,
                        toolbar: CalendarToolbar,
                        // event: CustomEvent
                        month: {
                            event: CustomEvent
                        },
                        week: {
                            event: CustomEvent
                        }
                    }}
                    localizer={localizer}
                    onSelectSlot={(slotInfo) => {
                        setSelectedDate(moment(slotInfo.start).toDate());
                    }}
                    onSelectEvent={(e) => {
                        setSelectedDate(moment(e.start).toDate());
                    }}
                    onView={(view) => {
                        setCurrentView(view);
                    }}
                    onShowMore={(e) => {
                        setSelectedDate(moment(e[0].start).toDate());
                    }}
                />
            </MainCalendar>
            <SubCalendar>
                <Calendar
                    // selectable={true}
                    events={events}
                    views={allViews}
                    step={30}
                    // showMultiDayTimes
                    // max={dates.add(
                    //     dates.endOf(new Date(2015, 17, 1), 'day'),
                    //     -1,
                    //     'hours'
                    // )}
                    defaultDate={moment().toDate()}
                    date={selectedDate}
                    components={{
                        // timeSlotWrapper: ColoredDateCellWrapper,
                        toolbar: SubCalendarToolbar,
                        event: CustomEventInSubCalendar
                    }}
                    defaultView={Views.DAY}
                    localizer={localizer}

                    // onSelectSlot={(slotInfo) => {
                    //     console.log('slotInfo', slotInfo);
                    // }}
                />
            </SubCalendar>
        </CalendarContainer>
    );
};

export default BigCalendarComponent;

const IconBackContainer = styled.img`
    height: 15px;
    width: 15px;
`;

const IconForwardContainer = styled.img`
    height: 15px;
    width: 15px;
`;

const IconDownContainer = styled.img`
    height: 15px;
    width: 15px;
    margin-left: 5px;
`;

const SelectViewButton = styled.button`
    background: transparent;
    border: none;
`;

const EventContainerSubCalendar = styled.div`
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    height: 100%;
    border-radius: 3px;
    padding: 0px 10px;
    background-color: ${(props) => (props.pastEvent ? '#BAECFF' : '#19c0ff')};
    .rbc-event {
        background-color: ${(props) =>
            props.pastEvent ? '#BAECFF' : '#19c0ff'};
    }
`;

const FromToAirport = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    padding-top: 2px;
`;

const FlightCode = styled.div`
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    padding-top: 2px;
`;

const EventContainer = styled.div`
    display: flex;
    /* flex-direction: column; */
    border-radius: 3px;
    padding: 2px 5px;
    flex-direction: ${(props) => (props.isVertical ? 'column' : 'row')};
    background-color: ${(props) => (props.pastEvent ? '#DBF5FF' : '#19c0ff')};
    /* opacity: ${(props) => (props.pastEvent ? '0.6' : '1')}; */
`;

const SubCalendar = styled.div`
    height: 750px;
    /* width: 320px; */
    width: 35%;
`;

const MainCalendar = styled.div`
    height: 750px;
    /* width: 1000px; */
    width: 65%;
    padding-right: 40px;
    .rbc-allday-cell {
        height: 0px;
    }
`;

const BackButton = styled.button`
    border: none;
    background-color: transparent;
    cursor: pointer;
`;

const NavigateDate = styled.div`
    border: 1px solid rgba(81, 84, 91, 0.2);
    border-radius: 2px;
    width: 132px;
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const ToolbarContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    .ant-btn {
        background: transparent;
    }
`;

const CalendarContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .ant-picker-calendar-date-value {
        text-align: left;
        color: #121216;
        font-family: AvenirNext-DemiBold;
        font-size: 12px;
        opacity: 0.4989809782608696;
    }

    .ant-picker-cell:hover {
        .ant-picker-calendar-date-value {
            opacity: 1;
        }
    }

    .ant-picker-cell-selected {
        .ant-picker-calendar-date-value {
            opacity: 1;
            color: #121216;
        }
    }

    .rbc-header {
        height: 40px;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: #f4f5f8;
    }

    .rbc-header span {
        color: #65686f;
        font-family: AvenirNext-DemiBold;
        font-size: 11px;
        text-transform: uppercase;
        padding-top: 1px;
    }

    .rbc-day-bg {
        background-color: #ffffff;
    }

    .rbc-date-cell {
        padding-left: 10px;
        text-align: left;
    }

    .rbc-date-cell a {
        color: #121216;
        font-family: AvenirNext-DemiBold;
        font-size: 12px;
        /* opacity: 0.8; */
    }

    .rbc-off-range a {
        opacity: 0.4;
    }

    .rbc-event {
        background-color: #19c0ff;
        border: none;
        padding: 0;
        height: 38px;
    }
    .rbc-time-column {
        background-color: #ffffff;
    }
    .rbc-now a {
        background-color: #19c0ff;
        border-radius: 100%;
        color: #ffffff;
        display: block;
        height: 18px;
        width: 18px;
        text-align: center;
    }

    /* .rbc-event,
    .rbc-day-slot .rbc-background-event {
        background-color: #19c0ff;
    } */

    .rbc-current-time-indicator {
        height: 2px;
    }

    .rbc-label {
        font-size: 12px;
        /* font-weight: 600; */
        color: #65686f;
        font-family: AvenirNext-DemiBold;
    }
    .rbc-event-label {
        padding-left: 10px;
        font-family: AvenirNext-DemiBold;
    }
    /* .rbc-overlay .rbc-event {
        background-color: #19c0ff;
    } */
`;
