export const ButtonStyle = {
    small: {
        width: '110px',
        height: '32px',
        fontSize: '10px',
        letterSpacing: '0.7px',
        lineHeight: '12px',
        border: '1px solid rgba(18, 18, 22, 0.2)'
    },
    medium: {
        width: '150px',
        height: '40px',
        fontSize: '11px',
        letterSpacing: '0.7px',
        lineHeight: '13px',
        borderColor: '1px solid #19C0FF'
    },
    large: {
        width: '190px',
        height: '50px',
        fontSize: '13px',
        letterSpacing: '1px',
        lineHeight: '16px',
        borderColor: '1px solid #19C0FF'
    }
};
