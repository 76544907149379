import React from 'react';
import styled from 'styled-components';
import { Row, Col, Divider } from 'antd';
import { Text } from 'components';
import { IconDeparture, IconLand, jetIcon } from 'assets/images/icons';
import { useTranslation } from 'react-i18next';
import FlightInformation from './FlightInformation';
import moment from 'moment';

const FlightStatusCard = (props) => {
    const { t, i18n } = useTranslation();
    const iconStyle = {
        width: '32px',
        height: '23px'
    };
    const {
        flightType,
        requestCode,
        status,
        reqeustSendBy,
        to_airport,
        from_airport,
        leg = {},
        created_at
    } = props;
    const { arrival = {}, departure = {} } = leg;
    return (
        <FlightStatusContainer>
            <FlightInformation
                flightType={flightType}
                requestCode={requestCode}
                reqeustSendBy={reqeustSendBy}
                status={status}
                created_at={created_at}
            />
            <Itinerary>
                <Col span={8}>
                    <Row justify="center">
                        <IconDeparture fill="#19C0FF" style={iconStyle} />
                    </Row>
                    <Row justify="center">
                        <Text textStyle="heading1">
                            {from_airport?.icao_code || '-'}
                        </Text>
                    </Row>
                    <Row justify="center">
                        <Text textStyle="nameHighlight">
                            {from_airport?.name.toUpperCase() || '-'}
                        </Text>
                    </Row>
                    <Row justify="center">
                        <Text textStyle="heading2">
                            {moment(departure?.date + ' ' + departure?.time)
                                .format('hh:mm A')
                                .toUpperCase()}
                        </Text>
                    </Row>
                    <Row justify="center" style={{ marginTop: -8 }}>
                        <Text textStyle="dateTimeBold">
                            {` (GMT ${departure.utc_offset > 0 ? '+' : ''}${
                                departure.utc_offset / 60
                            })`}
                        </Text>
                    </Row>
                    <Row justify="center">
                        <Text textStyle="dateTimeBold">
                            {moment(departure?.date)
                                .format('dddd, MMM DD YYYY')
                                .toUpperCase()}
                        </Text>
                    </Row>
                    {/* <Row justify="center">
                        <Text textStyle="heading2">
                            {departure?.time || '-'}
                        </Text>
                    </Row>
                    <Row justify="center">
                        <Text textStyle="dateTimeBold">
                            {moment(departure?.date)
                                .format('dddd, MMM DD YYYY')
                                .toUpperCase()}
                        </Text>
                    </Row> */}
                </Col>
                <Col span={8}>
                    <Row
                        justify="center"
                        align="middle"
                        style={{ height: '100%' }}
                    >
                        <Col style={{ width: '100%' }}>
                            <Row
                                justify="center"
                                align="middle"
                                style={{
                                    width: '100%',
                                    justifyContent: 'center'
                                }}
                            >
                                <Col>
                                    <Dashed />
                                </Col>
                                <Col>
                                    <img
                                        src={jetIcon}
                                        style={{
                                            height: 25,
                                            width: 26,
                                            marginLeft: 5
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Dashed style={{ marginLeft: 5 }} />
                                </Col>
                            </Row>
                            <Row justify="center" align="middle">
                                {/* <Text textStyle="infoNormal">
                                    9 hrs 30 mins
                                </Text> */}
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    <Row justify="center">
                        <IconLand fill="#19C0FF" style={iconStyle} />
                    </Row>
                    <Row justify="center">
                        <Text textStyle="heading1">
                            {to_airport?.icao_code || '-'}
                        </Text>
                    </Row>
                    <Row justify="center">
                        <Text textStyle="nameHighlight">
                            {to_airport?.name.toUpperCase() || '-'}
                        </Text>
                    </Row>
                    {arrival.date && arrival.time && arrival.utc_offset && (
                        <>
                            <Row justify="center">
                                <Text textStyle="heading2">
                                    {moment(arrival?.date + ' ' + arrival?.time)
                                        .format('hh:mm A')
                                        .toUpperCase()}
                                </Text>
                            </Row>
                            <Row justify="center" style={{ marginTop: -8 }}>
                                <Text textStyle="dateTimeBold">
                                    {` (GMT ${
                                        arrival.utc_offset > 0 ? '+' : ''
                                    }${arrival.utc_offset / 60})`}
                                </Text>
                            </Row>
                            <Row justify="center">
                                <Text textStyle="dateTimeBold">
                                    {arrival.date &&
                                        moment(arrival.date)
                                            .format('dddd, MMM DD YYYY')
                                            .toUpperCase()}
                                </Text>
                            </Row>
                        </>
                    )}
                </Col>
            </Itinerary>
        </FlightStatusContainer>
    );
};

export default FlightStatusCard;

const DepartureIcon = styled.img``;

const Dashed = styled.div`
    border: 1px dashed rgba(14, 14, 18, 0.2);
    width: 6vw;
    min-width: 60px;
    max-width: 100px;
`;

const FlightStatusContainer = styled.div`
    background-color: #fcfcfe;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
`;

const Itinerary = styled(Row)`
    padding-top: 20px;
    background-color: #ffffff;
    padding-bottom: 20px;
`;

// const FlightInformation = styled(Row)`
//     background-color: #fcfcfe;
//     border-radius: 0 4px 0 0;
//     padding-top: 10px;
//     padding-bottom: 10px;
//     padding-left: 10px;
//     padding-right: 10px;
// `;

const EachColumn = styled(Col)`
    display: flex;
`;

const Membership = styled.span`
    color: #dd4558;
    font-family: AvenirNext-Medium;
    font-size: 12px;
`;

const RequestStatus = styled.span`
    color: #f5a623;
    font-family: AvenirNext-Medium;
    font-size: 12px;
`;
