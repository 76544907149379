import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { Modal, Row, Col } from 'antd';
import { ButtonCustom, Text } from 'components';
import InvoiceCard from './InvoiceCard';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { calcPriceComponents } from 'redux/actions/quoteAction';

const ModalInvoice = ({
    quoteId,
    leg = {},
    fromAirport = {},
    toAirport = {},
    legIndex = 0,
    show = false,
    onCancel,
    onClickSave
}) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const [legItem, setLegItem] = useState({});

    useEffect(() => {
        if (!_.isEmpty(leg)) {
            setLegItem(leg);
        }
    }, [leg]);

    const onClickSaveInvoice = () => {
        onClickSave && onClickSave(legItem);
    };

    const onChangePrice = async (leg_index, selectec_index, price) => {
        let rs = await dispatch(calcPriceComponents(quoteId, price));
        if (rs && rs.success && rs.data) {
            setLegItem({ ...legItem, price_components: rs.data });
        }
    };

    const _renderInvoiceCard = () => {
        const { services, id, aircraft = {}, price_components = {} } = legItem;
        return (
            <InvoiceCard
                legId={id}
                fromAirport={fromAirport}
                toAirport={toAirport}
                leg_index={legIndex}
                services={services}
                onSavePrice={onChangePrice}
                showAddBtn={false}
                selectAircraft={{ aircraft: aircraft, price_components: price_components }}
            />
        );
    };

    return (
        <Modal
            className="modal-invoice-custom"
            centered
            visible={show}
            onCancel={() => {
                setLegItem({});
                onCancel(false);
            }}
            footer={null}
            style={{ margin: 50 }}
            width={'auto'}
        >
            <ModalContainer>
                <Row
                    style={{
                        paddingLeft: 25,
                        paddingTop: 15
                    }}
                >
                    {_renderInvoiceCard()}
                </Row>
            </ModalContainer>
            <div style={{ marginTop: 25 }}>
                <ButtonCustom
                    title={t('SAVE_CHANGES').toUpperCase()}
                    btnType="primary"
                    size="medium"
                    width="250px"
                    onClick={onClickSaveInvoice}
                />
            </div>
        </Modal>
    );
};

export default ModalInvoice;

const ModalContainer = styled.div`
    .modal-invoice-custom {
        width: auto;
    }
`;
