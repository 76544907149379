import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { Card, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const template = (props) => {
    const { t, i18n } = useTranslation();
    const {
        setIsRequestAccount,
        email = '',
        onChangeEmail,
        password = '',
        onChangePassword,
        errors
    } = props;
    const [enterPressed, setEnterPressed] = useState(false);
    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <ContentSection>
            <Card className="ant-card-login">
                <WelcomeSection>
                    <WelcomeText>
                        {t('SIGN_IN_TITLE').toUpperCase()}
                    </WelcomeText>
                </WelcomeSection>
                <InstructionSection>
                    <InstructionText>{t('ENTER_DETAIL')}</InstructionText>
                </InstructionSection>
                <LoginInformationSection>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            label={
                                <LabelText>
                                    {t('EMAIL_ADDRESS').toUpperCase()}
                                </LabelText>
                            }
                        >
                            <Input
                                style={inputStyle}
                                value={email}
                                onChange={onChangeEmail}
                                onKeyDown={(e) => {
                                    setEnterPressed(true);
                                    if (e.key === 'Enter') {
                                        props.SignIn && props.SignIn();
                                    }
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label={
                                <PasswordLabelSection className="aaaaaa">
                                    <LabelText>
                                        {t('PASSWORD').toUpperCase()}
                                    </LabelText>
                                    <ForgotPasswordSection
                                        className="forgot-password-button"
                                        onClick={() => {
                                            if (!enterPressed) {
                                                props.forgotPassword &&
                                                    props.forgotPassword();
                                            } else {
                                                setEnterPressed(false);
                                            }
                                        }}
                                    >
                                        <ForgotPasswordText className="forgot-password-text">
                                            {t('FORGOT_PASSWORD')}
                                        </ForgotPasswordText>
                                    </ForgotPasswordSection>
                                </PasswordLabelSection>
                            }
                        >
                            <Input.Password
                                style={inputStyle}
                                value={password}
                                onChange={onChangePassword}
                                onKeyDown={(e) => {
                                    setEnterPressed(true);
                                    if (e.key === 'Enter') {
                                        props.SignIn && props.SignIn();
                                    }
                                }}
                            />
                            {errors && <ErrorText>{t(errors)}</ErrorText>}
                        </Form.Item>
                        <Form.Item>
                            <StyledButton
                                onClick={() => {
                                    props.SignIn && props.SignIn();
                                }}
                            >
                                <SubmitButtonText>
                                    {t('SIGN_IN').toUpperCase()}
                                </SubmitButtonText>
                            </StyledButton>
                        </Form.Item>
                        <Form.Item>
                            <DontHaveAccount>
                                {t('DONT_HAVE_ACCOUNT')}
                            </DontHaveAccount>
                        </Form.Item>
                        <Form.Item>
                            <RequestAccountSection>
                                <CreateAccountButton
                                    onClick={() => {
                                        props.requestAccount &&
                                            props.requestAccount();
                                    }}
                                >
                                    <CreateAccountText>
                                        {t('REQUEST_ACCOUNT').toUpperCase()}
                                    </CreateAccountText>
                                </CreateAccountButton>
                            </RequestAccountSection>
                        </Form.Item>
                    </Form>
                </LoginInformationSection>
            </Card>
        </ContentSection>
    );
};

const DontHaveAccount = styled.span`
    padding-top: 30px;
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    display: flex;
    justify-content: center;
    text-align: center;
`;

const ErrorText = styled.span`
    padding-top: 5px;
    color: #f42020;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
`;

const ContentSection = styled.div`
    display: flex;
    justify-content: center;
    .ant-card-login {
        border-radius: 8px;
        width: 40vw;
        min-width: 500px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .ant-form-item-control {
        min-width: 550px;
    }
    @media (max-width: 1366px) {
        .ant-form-item-control {
            min-width: 400px;
        }
    }
    .ant-form-vertical .ant-form-item-label > label {
        width: 100%;
    }
`;

const StyledButton = styled.button`
    width: 100%;
    background-color: #19c0ff;
    border-radius: 2px;
    border: none;
    height: 50px;
`;

const SubmitButtonText = styled.span`
    color: #ffffff;
    font-family: AvenirNext-DemiBold;
    font-size: 13px;
    letter-spacing: 1px;
    line-height: 16px;
    text-align: center;
`;

const RequestAccountSection = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
`;

const CreateAccountButton = styled.button`
    width: 190px;
    border: none;
    height: 40px;
    background-color: Transparent;
    background-repeat: no-repeat;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    border: 1px solid #19c0ff;
    border-radius: 3px;
`;

const CreateAccountText = styled.span`
    color: #16161b;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    letter-spacing: 0.7px;
    line-height: 13px;
    text-align: center;
`;

const WelcomeSection = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 40px;
`;

const WelcomeText = styled.span`
    font-size: 20px;
    letter-spacing: 1.5px;
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 24px;
    letter-spacing: 2px;
    line-height: 32px;
    text-align: center;
`;

const InstructionSection = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
`;

const InstructionText = styled.span`
    color: #65686f;
    font-family: Lato;
    font-size: 13px;
    line-height: 24px;
`;

const LoginInformationSection = styled.div`
    display: flex;
    justify-content: center;
`;

const LabelText = styled.span`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
`;

const PasswordLabelSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    .forgot-password-button {
        float: right;
    }
    .forgot-password-text {
        text-align: right;
    }
    /* Specific for iPhone 12 Pro Max and  devices have the view port width above */
    @media (max-width: 700px) {
        .forgot-password-button {
            float: left;
            width: 29.6875vw;
            padding-left: 0;
            align-items: flex-start;
            display: flex;
        }
        .forgot-password-text {
            text-align: left;
        }
    }
`;

const ForgotPasswordSection = styled.button`
    background-color: Transparent;
    background-repeat: no-repeat;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    border: none;
    padding-right: 0;
    margin-bottom: 3px;
`;

const ForgotPasswordText = styled.span`
    font-family: AvenirNext-DemiBold;
    color: #65686f;
    font-size: 11px;
    line-height: 14px;
    text-align: right;
    position: relative;
`;

const inputStyle = {
    height: '50px',
    border: '1px solid rgba(36, 36, 45, 0.15)',
    borderRadius: '3px'
};
export { template };
