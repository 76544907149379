import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Input, AutoComplete } from 'antd';
import debounce from 'lodash/debounce';

/**
 *
 * @param {string} label - optional
 * @param {function} onSelect - required - select value
 * @param {function} handleSearch -
 * @param {object} inputStyle -
 * @param {string} inputSearch -
 * @param {string} placeholder -
 * @returns {JSX}
 */
const template = (props) => {
    const { t, i18n } = useTranslation();
    const {
        label = '',
        inputStyle,
        options,
        inputSearch,
        placeholder = '',
        onSelect,
        handleSearch,
        onCustomClear,
        debounceTime = 500,
        clearAfterSearch = false
    } = props;

    const [inputKey, setInputKey] = useState('');

    useEffect(() => {
        if (inputSearch) {
            setInputKey(inputSearch);
        }
    }, [inputSearch]);

    const debounceSearch = (value) => {
        setInputKey(value);
        const debouncedSave = debounce(() => {
            handleSearch(value);
        }, debounceTime);

        // debounce
		debouncedSave();
    };

    const onSelectItem = async (value, options) => {
        let rs = onSelect(value, options);
        if (clearAfterSearch) {
            setInputKey('');
        } else {
            setInputKey(rs);
        }
    };

    return (
        <>
            {label && <Label>{label}</Label>}
            <AutoComplete
                dropdownMatchSelectWidth={252}
                style={inputStyle ? inputStyle : styles}
                options={options}
                onSelect={onSelectItem}
                onSearch={debounceSearch}
                value={inputKey}
                allowClear
                onClear={onCustomClear}
            >
                <Input
                    style={inputStyle ? inputStyle : styles} // default styles
                    placeholder={placeholder}
                />
            </AutoComplete>
        </>
    );
};

const styles = {
    width: 370,
    height: 50
};

const Label = styled.div`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 12px;
    margin-left: ${(props) => (props.rightInput ? '30px' : '0px')};
`;

export { template };
