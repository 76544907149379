import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import styled from 'styled-components';

import {
    Header,
    Tabs,
    InvoicePaymentCard,
    EmptyComponent,
    ButtonCustom
} from 'components';
import InvoiceInfoCard from '../InvoiceInfoCard';
import InvoiceDetailsCard from '../InvoiceDetailsCard';
import {
    aboutIcon,
    flightPostIcon,
    checkedIcon,
    plusIcon
} from 'assets/images/icons';
import { PAYMENT_STATUS, INVOICE_TYPE } from 'redux/Constants';
import MarkAsPaidModal from '../MarkAsPaidModal';
import { numberFormat } from 'utils';
import { GLOBAL_STYLE_CONSTANTS } from 'config/Constants';

const template = (props) => {
    const {
        navigate,
        loading = false,
        invoiceDetail = {},
        onSubmitMarkAsPaid
    } = props;
    const {
        addressee = {},
        booking = {},
        legs = [],
        id = '',
        invoice_code = '',
        operator_invoice,
        status = '',
        totals = {},
        type = '',
        created_at,
        finalised_at,
        partner_invoices = []
    } = invoiceDetail;

    const { t, i18n } = useTranslation();

    const [showMarkPaid, setShowMarkPaid] = useState(false);

    const onCancelMarkAsPaid = () => {
        setShowMarkPaid(false);
    };

    const onMarkAsPaid = (type = '', description = '') => {
        onSubmitMarkAsPaid(type, description);
        setShowMarkPaid(false);
    };

    const getInvoiceTypeName = () => {
        let name = '';
        if (operator_invoice === true) {
            name = t('PARTNER_INVOICES_DETAIL');
        } else if (operator_invoice === false) {
            name = t('CUSTOMER_INVOICES_DETAIL');
        }
        return name;
    };

    const _renderCustomerTotalInvoice = () => {
        const {
            total_payout = 0,
            vat = [],
            administrative_fee_total = 0,
            total_vat = 0,
            operator_payout = 0,
            booking_fee_total = 0,
            broker_payout = 0
        } = totals;

        return (
            <TotalWrapper>
                <ItemPriceTotal>
                    <RowWrapper>
                        <LabelLight>
                            {t('OPERATOR_PAYOUT').toUpperCase()}
                        </LabelLight>
                        <ValueItem>
                            {numberFormat(operator_payout, 'USD', 2)}
                        </ValueItem>
                    </RowWrapper>
                </ItemPriceTotal>
                <ItemPriceTotal>
                    <RowWrapper style={{ marginTop: 10 }}>
                        <LabelLight>{t('BROKER_FEE').toUpperCase()}</LabelLight>
                        <ValueItem>
                            {numberFormat(broker_payout, 'USD', 2)}
                        </ValueItem>
                    </RowWrapper>
                </ItemPriceTotal>
                <ItemPriceTotal>
                    <RowWrapper style={{ marginTop: 10 }}>
                        <LabelLight>
                            {t('BOOKING_FEE').toUpperCase()}
                        </LabelLight>
                        <ValueItem>
                            {numberFormat(booking_fee_total, 'USD', 2)}
                        </ValueItem>
                    </RowWrapper>
                </ItemPriceTotal>
                <ItemPriceTotal>
                    <RowWrapper style={{ marginTop: 10 }}>
                        <LabelLight>{`${t('VAT')}`}</LabelLight>
                        <ValueItem>
                            {numberFormat(total_vat, 'USD', 2)}
                        </ValueItem>
                    </RowWrapper>
                </ItemPriceTotal>

                <ItemPriceTotal>
                    <RowWrapper style={{ marginTop: 10 }}>
                        <LabelLight>
                            {t('ADMINISTRATOR_FEE').toUpperCase()}
                        </LabelLight>
                        <ValueItem>
                            {numberFormat(administrative_fee_total, 'USD', 2)}
                        </ValueItem>
                    </RowWrapper>
                </ItemPriceTotal>
                <Divider />
                <ItemPriceTotal>
                    <RowWrapper style={{ marginTop: 10 }}>
                        <LabelLight size="14px">
                            {t('TOTAL_PAYOUT').toUpperCase()}
                        </LabelLight>
                        <ValueItem size="14px">
                            {numberFormat(total_payout, 'USD', 2)}
                        </ValueItem>
                    </RowWrapper>
                </ItemPriceTotal>
            </TotalWrapper>
        );
    };

    const _renderCustomerDetails = () => {
        if (_.isEmpty(legs)) {
            return <EmptyComponent />;
        }

        const _renderDetailInvoice = () =>
            legs.map((leg = {}, index) => {
                const {
                    invoice_items = [],
                    operator_name = '',
                    totals = {},
                    flight_code = '',
                    tail_number = ''
                } = leg;
                return (
                    <div key={index} style={{ paddingBottom: '20px' }}>
                        <InvoiceDetailsCard
                            totals={totals}
                            invoiceCode={invoice_code}
                            invoiceItems={invoice_items}
                            allowEdit={true}
                            sentTitle={t('OPERATOR')}
                            flightCode={flight_code}
                            operatorName={operator_name}
                            showHeader={false}
                            tailNumber={tail_number}
                            showPayout={true}
                        />
                    </div>
                );
            });

        return (
            <>
                <InvoiceCodeWrap>
                    <InvoiceCode style={{ marginTop: '10px' }}>
                        {`${t('INVOICE')} #${invoice_code}`.toUpperCase()}
                    </InvoiceCode>
                    <div>
                        {status === PAYMENT_STATUS.AWAITING_PAYMENT && (
                            <ButtonCustom
                                btnType="secondary"
                                onClick={() => setShowMarkPaid(true)}
                                icon={checkedIcon}
                                size="small"
                                width="145px"
                                title={t('MARK_AS_PAID').toUpperCase()}
                                wrapStyle={{ marginRight: '20px' }}
                            />
                        )}
                        <ButtonCustom
                            btnType="secondary"
                            onClick={() => {}}
                            icon={plusIcon}
                            size="small"
                            width="162px"
                            title={t('ADD_NEW_INVOICE').toUpperCase()}
                        />
                    </div>
                </InvoiceCodeWrap>

                {/* invoice details */}
                {_renderDetailInvoice()}

                {/* invoice total */}
                <div style={{ marginTop: '20px' }}>
                    {_renderCustomerTotalInvoice()}
                </div>
            </>
        );
    };

    const _renderOperatorDetails = () => {
        if (_.isEmpty(partner_invoices)) {
            return <EmptyComponent />;
        }

        return partner_invoices.map((invoice = {}, i) => {
            const { invoice_code = '', legs = [] } = invoice;
            return legs.map((leg = {}, index) => {
                const {
                    invoice_items = [],
                    totals = {},
                    operator_name = '',
                    flight_code = '',
                    tail_number = ''
                } = leg;
                return (
                    <div key={index} style={{ marginTop: '0px' }}>
                        <InvoiceDetailsCard
                            operatorName={operator_name}
                            totals={totals}
                            invoiceCode={invoice_code}
                            invoiceItems={invoice_items}
                            isPartner={true}
                            sentTitle={t('INVOICE_SENT_TO')}
                            flightCode={flight_code}
                            tailNumber={tail_number}
                        />
                    </div>
                );
            });
        });
    };

    const renderInvoiceCustomer = () => {
        const tabRenderer = [
            {
                key: 'CUSTOMER',
                label: t('CUSTOMER_INVOICE').toUpperCase(),
                render: _renderCustomerDetails(),
                icon: aboutIcon
            },
            {
                key: 'OPERATOR',
                label: t('OPERATOR_INVOICE').toUpperCase(),
                render: _renderOperatorDetails(),
                icon: flightPostIcon
            }
        ];
        return (
            <div style={{ marginTop: '40px' }}>
                <Tabs tabRenderer={tabRenderer} />
            </div>
        );
    };

    const renderInvoicePartner = () => {
        if (_.isEmpty(legs)) {
            return <EmptyComponent />;
        }

        return legs.map((leg = {}, index) => {
            const {
                invoice_items = [],
                totals = {},
                flight_code = '',
                tail_number = ''
            } = leg;
            return (
                <div key={index} style={{ marginTop: '40px' }}>
                    <InvoiceDetailsCard
                        operatorName={addressee?.company_name || '_'}
                        totals={totals}
                        invoiceCode={invoice_code}
                        invoiceItems={invoice_items}
                        isPartner={true}
                        sentTitle={t('CUSTOMER')}
                        flightCode={flight_code}
                        tailNumber={tail_number}
                    />
                </div>
            );
        });
    };

    const _renderInvoiceWithType = () => {
        switch (operator_invoice) {
            case false:
                return renderInvoiceCustomer();
            case true:
                return renderInvoicePartner();
            default:
                return null;
        }
    };

    return (
        <Container>
            <Header
                title={`${t('INVOICE')} #${invoice_code}`}
                description={getInvoiceTypeName()}
            />

            {status === PAYMENT_STATUS.AWAITING_PAYMENT ? (
                <InvoicePaymentCard
                    type={booking?.type}
                    booking_code={booking?.booking_code}
                    name={`${addressee?.firstname} ${addressee?.lastname}`}
                    payment_status={status}
                    legs={booking?.legs}
                    operatorInvoice={operator_invoice}
                />
            ) : (
                <InvoiceInfoCard
                    invoiceCode={invoice_code}
                    bookingCode={booking?.booking_code}
                    userCompany={booking?.user?.meta}
                    addressee={addressee}
                    operatorInvoice={operator_invoice}
                    createdAt={created_at}
                    finalisedAt={finalised_at}
                    totals={totals}
                    type={INVOICE_TYPE.CUSTOMER}
                    status={status}
                />
            )}

            {_renderInvoiceWithType()}

            {/* Mark As Paid Modal */}
            <MarkAsPaidModal
                show={showMarkPaid}
                onCancel={onCancelMarkAsPaid}
                onSave={onMarkAsPaid}
            />
        </Container>
    );
};

export { template };

const Container = styled.div`
    /* padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 20px; */
    padding: ${GLOBAL_STYLE_CONSTANTS.padding};
    width: 100%;
    margin-right: 20px;
`;

const InvoiceCodeWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const InvoiceCode = styled.span`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.7px;
    line-height: 16px;
    text-align: left;
`;

const TotalPrice = styled.span`
    color: #121216;
    font-family: AvenirNext-Medium;
    font-size: 14px;
    line-height: 17px;
`;

const TotalWrapper = styled.div`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 3px;
    margin-bottom: 20px;
    padding: 20px;
`;

const RowWrapper = styled.div`
    justify-content: space-between;
    width: 65%;
    min-width: 250px;
    display: flex;
    align-items: center;
`;

const LabelLight = styled.div`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: ${(props) => props.size || '10px'};
    letter-spacing: 0.7px;
    line-height: 16px;
    text-align: left;
`;

const ValueItem = styled.div`
    color: #121216;
    font-family: AvenirNext-Bold;
    font-size: ${(props) => props.size || '12px'};
    letter-spacing: 0.4px;
    line-height: ${(props) => props.lineHeight || '24px'};
    text-align: right;
    margin-left: 25px;
`;

const Divider = styled.div`
    background-color: #0e0e12;
    opacity: 0.1;
    margin-top: 10px;
    width: 100%;
    height: 2px;
`;

const ItemPriceTotal = styled.div`
    width: 35%;
    min-width: 250px;
`;
