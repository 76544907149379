import React, { useEffect, useState } from 'react';
import { template as tmpl } from './AddAircraftContainer.tmpl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    searchGenericAircrafts,
    addAircraft
} from '../../../redux/actions/aircarftAction';
import { uploadImage } from '../../../redux/actions/appActions';
import { EMAIL_EXISTS } from '../../../redux/Constants';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getBase64 } from '../../../utils';
import { UPLOAD_MODEL, UPLOAD_IMAGE_TYPE } from '../../../redux/Constants';
import _ from 'lodash';

const AddAircraftLogic = (props) => {
    const {
        activeCompany,
        companies = [],
        location,
        searchGenericAircrafts,
        uploadImage
    } = props;
    const { t, i18n } = useTranslation();

    const [visibleModal, setVisibleModal] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');

    //for upload images
    const [imageUrl, setImageUrl] = useState('');
    const [photo, setPhoto] = useState([]);
    const [temp_id, setTemp_id] = useState(0);

    const [isEdit, setIsEdit] = useState(false);

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [images, setImages] = useState([]);
    const [tempImages, setTempImages] = useState([]);
    const [description, setDescription] = useState('');
    const [current_airport, setCurrentAirport] = useState({});
    const [home_airport, setHomeAirport] = useState({});
    const [price_reflection_airport, setPriceReflectionAirport] = useState({});
    const [crew, setCrew] = useState(0);
    const [max_passengers, setMaxPassenger] = useState(0);
    const [lavatories, setLavatories] = useState(0);
    const [height_ft, setHeight_ft] = useState(0);
    const [length_ft, setLength_ft] = useState(0);
    const [wingspan_ft, setWingspan_ft] = useState(0);
    const [wing_area_ft2, setWing_area_ft2] = useState(0);
    const [empty_weight_lb, setEmpty_weight_lb] = useState(0);
    const [max_takeoff_weight_lb, setMax_takeoff_weight_lb] = useState(0);
    const [luggage_capacity_ft3, setLuggage_capacity_ft3] = useState(0);
    const [max_range_in_nautical_miles, setMax_range_in_nautical_miles] =
        useState(0);
    const [average_speed_mph, setAverage_speed_mph] = useState(0);

    const [cruising_altitude_ft, setCruising_altitude_ft] = useState(0);
    const [cruising_speed_in_knots, setCruising_speed_in_knots] = useState(0);
    const [climb_rate_fpm, setClimb_rate_fpm] = useState(0);
    const [descent_rate_fpm, setDescent_rate_fpm] = useState(0);
    const [climb_speed_in_knots, setClimb_speed_in_knots] = useState(0);
    const [descent_speed_in_knots, setDescent_speed_in_knots] = useState(0);
    const [takeoff_distance_ft, setTakeoff_distance_ft] = useState(0);
    const [landing_distance_ft, setLanding_distance_ft] = useState(0);

    const [tax_percentage, set_tax_percentage] = useState(0);
    const [minimum_booking_time_hours, set_minimum_booking_time_hours] =
        useState(0);
    const [airport_ground_time_minutes, set_airport_ground_time_minutes] =
        useState(0);
    const [
        positioning_flight_per_hour_fee,
        set_positioning_flight_per_hour_fee
    ] = useState(0);
    const [revenue_flight_per_hour_fee, set_revenue_flight_per_hour_fee] =
        useState(0);
    const [
        short_term_increment_percentage_6hrs,
        set_short_term_increment_percentage_6hrs
    ] = useState(0);
    const [
        short_term_increment_percentage_12hrs,
        set_short_term_increment_percentage_12hrs
    ] = useState(0);
    const [
        short_term_increment_percentage_24hrs,
        set_short_term_increment_percentage_24hrs
    ] = useState(0);
    const [
        short_term_increment_percentage_48hrs,
        set_short_term_increment_percentage_48hrs
    ] = useState(0);

    const [departure_fee, set_departure_fee] = useState(0);
    const [landing_fee, set_landing_fee] = useState(0);
    const [overnight_stay_fee, set_overnight_stay_fee] = useState(0);
    const [parking_fee, set_parking_fee] = useState(0);

    const [amenities_extra_leg_room, set_amenities_extra_leg_room] =
        useState(false);
    const [amenities_wifi, set_amenities_wifi] = useState(false);
    const [amenities_bed, set_amenities_bed] = useState(false);
    const [amenities_sink, set_amenities_sink] = useState(false);
    const [amenities_lavatory, set_amenities_lavatory] = useState(false);
    const [tail_number, set_tail_number] = useState('');
    const [operator_name, set_operator_name] = useState('');
    const [classtype, set_classtype] = useState('');
    const [manufacturer, set_manufacturer] = useState('');
    const [year_of_manufacture, set_year_of_manufacture] = useState('');
    const [last_refurbished_at, set_last_refurbished_at] = useState('');
    const [model, setModel] = useState('');

    const [errorMsg, setErrorMsg] = useState([]);
    const [buttonValid, setButtonValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);

    /**
     * Validate required fields
     */
    useEffect(() => {
        setErrorMsg([]);
        if (
            !model ||
            !tail_number ||
            !classtype ||
            _.isEmpty(home_airport) ||
            _.isEmpty(price_reflection_airport) ||
            !crew ||
            !max_passengers ||
            !takeoff_distance_ft ||
            !landing_distance_ft ||
            !max_range_in_nautical_miles ||
            !cruising_speed_in_knots ||
            !cruising_altitude_ft ||
            !climb_rate_fpm ||
            !descent_rate_fpm ||
            !climb_speed_in_knots ||
            !descent_speed_in_knots ||
            !positioning_flight_per_hour_fee ||
            !revenue_flight_per_hour_fee
        ) {
            setButtonValid(false);
        } else {
            setButtonValid(true);
        }
    }, [
        model,
        tail_number,
        // operator_name,
        classtype,
        home_airport,
        price_reflection_airport,
        manufacturer,
        crew,
        max_passengers,
        description,
        height_ft,
        length_ft,
        wingspan_ft,
        wing_area_ft2,
        empty_weight_lb,
        max_takeoff_weight_lb,
        luggage_capacity_ft3,
        takeoff_distance_ft,
        landing_distance_ft,
        max_range_in_nautical_miles,
        average_speed_mph,
        cruising_speed_in_knots,
        cruising_altitude_ft,
        climb_rate_fpm,
        descent_rate_fpm,
        climb_speed_in_knots,
        descent_speed_in_knots,
        revenue_flight_per_hour_fee,
        overnight_stay_fee,
        minimum_booking_time_hours,
        parking_fee,
        departure_fee,
        landing_fee,
        short_term_increment_percentage_48hrs,
        short_term_increment_percentage_24hrs,
        short_term_increment_percentage_12hrs,
        short_term_increment_percentage_6hrs,
        positioning_flight_per_hour_fee,
        airport_ground_time_minutes,
        tax_percentage
    ]);

    const navigate = (route, search, state) => {
        props.history.push({
            pathname: route,
            search: search,
            state: state ? { data: state } : null
        });
    };

    const onDeletePhoto = (item) => {
        const removedTempImages = tempImages.filter((image) => {
            return image.id !== item.id;
        });
        setTempImages(removedTempImages);
    };

    const submit = async () => {
        //TODO: do the validation after API response and show the proper message
        if (buttonValid) {
            setLoading(true);

            let data = {
                // name: name,
                tail_number: tail_number,
                classtype: classtype,
                model: model,
                description: description,
                // home_airport: home_airport,
                // home_airport_id: home_airport?.id || '',
                // price_reflection_airport: price_reflection_airport,
                // price_reflection_airport_id: price_reflection_airport?.id || '',
                manufacturer: manufacturer,
                year_of_manufacture: year_of_manufacture,
                operator_name: operator_name,
                last_refurbished_at: last_refurbished_at.toString(),
                properties: {
                    crew: crew,
                    max_passengers: max_passengers,
                    lavatories: lavatories,
                    wingspan_ft: wingspan_ft,
                    height_ft: height_ft,
                    length_ft: length_ft,
                    wing_area_ft2: wing_area_ft2,
                    empty_weight_lb: empty_weight_lb,
                    max_takeoff_weight_lb: max_takeoff_weight_lb,
                    luggage_capacity_ft3: luggage_capacity_ft3,
                    max_range_in_nautical_miles: max_range_in_nautical_miles,
                    average_speed_mph: average_speed_mph,
                    cruising_altitude_ft: cruising_altitude_ft,
                    cruising_speed_in_knots: cruising_speed_in_knots,
                    climb_rate_fpm: climb_rate_fpm,
                    descent_rate_fpm: descent_rate_fpm,
                    climb_speed_in_knots: climb_speed_in_knots,
                    descent_speed_in_knots: descent_speed_in_knots,
                    takeoff_distance_ft: takeoff_distance_ft,
                    landing_distance_ft: landing_distance_ft
                },
                price_parameters: {
                    tax_percentage: tax_percentage,
                    revenue_flight_per_hour_fee: revenue_flight_per_hour_fee,
                    airport_ground_time_minutes: airport_ground_time_minutes,
                    minimum_booking_time_hours: minimum_booking_time_hours
                }
            };

            if (home_airport.id) {
                data = {
                    ...data,
                    home_airport_id: home_airport.id
                };
            }

            if (price_reflection_airport.id) {
                data = {
                    ...data,
                    price_reflection_airport_id: price_reflection_airport.id
                };
            }

            const result = await props.addAircraft(data);
            if (result.isConnected && result.aircraft.id) {
                setId(result.aircraft.id);
                // tempImages.map(async (image, index) => {
                //     const uploadPhotoResult = await uploadImage(
                //         result.aircraft.id,
                //         image.file,
                //         UPLOAD_MODEL.AIRCRAFT,
                //         UPLOAD_IMAGE_TYPE.GALLERY
                //     );
                // });
                for (const image of tempImages) {
                    const uploadPhotoResult = await uploadImage(
                        result.aircraft.id,
                        image.file,
                        UPLOAD_MODEL.AIRCRAFT,
                        UPLOAD_IMAGE_TYPE.GALLERY
                    );
                }
                setVisibleModal(true);
                setSuccessMsg(t('SUBMMITED_SUCCESSFULLY').toUpperCase());
            } else if (!result.isConnected && result.error.errormsg) {
                // setError(result.error.errormsg);
            }
        } else {
            setButtonClicked(true);
        }
        setLoading(false);
    };

    const cancelModal = () => {
        setVisibleModal(false);
    };

    const onSearchGenericAircraft = async (value) => {
        const result = await searchGenericAircrafts(value);
        return result?.aircrafts || [];
    };

    const onSelectAircraft = (aircraft) => {
        const {
            images,
            description,
            properties = {},
            name = '',
            tail_number = '',
            operator_name = '',
            classtype = '',
            manufacturer = '',
            model
        } = aircraft;

        const {
            crew,
            max_passengers,
            lavatories,
            height_ft,
            length_ft,
            wingspan_ft,
            wing_area_ft2,
            empty_weight_lb,
            max_takeoff_weight_lb,
            luggage_capacity_ft3,
            max_range_in_nautical_miles,
            average_speed_mph,
            cruising_altitude_ft,
            cruising_speed_in_knots,
            climb_rate_fpm,
            descent_rate_fpm,
            climb_speed_in_knots,
            descent_speed_in_knots,
            takeoff_distance_ft,
            landing_distance_ft
        } = properties;
        // setId(id);
        setName(name);
        setModel(model);
        set_operator_name(operator_name);
        set_classtype(classtype);
        set_tail_number(tail_number);
        set_manufacturer(manufacturer);
        // setImages(images || []);
        // setTempImages(images || []);
        setDescription(description);

        setCrew(crew || 0);
        setMaxPassenger(max_passengers || 0);
        setLavatories(lavatories || 0);
        setHeight_ft(height_ft || 0);
        setLength_ft(length_ft || 0);
        setWingspan_ft(wingspan_ft || 0);
        setWing_area_ft2(wing_area_ft2 || 0);
        setEmpty_weight_lb(empty_weight_lb || 0);
        setMax_takeoff_weight_lb(max_takeoff_weight_lb || 0);
        setLuggage_capacity_ft3(luggage_capacity_ft3 || 0);

        setMax_range_in_nautical_miles(max_range_in_nautical_miles || 0);
        setAverage_speed_mph(average_speed_mph || 0);

        setCruising_altitude_ft(cruising_altitude_ft || 0);
        setCruising_speed_in_knots(cruising_speed_in_knots || 0);
        setClimb_rate_fpm(climb_rate_fpm || 0);
        setDescent_rate_fpm(descent_rate_fpm || 0);
        setClimb_speed_in_knots(climb_speed_in_knots || 0);
        setDescent_speed_in_knots(descent_speed_in_knots || 0);
        setTakeoff_distance_ft(takeoff_distance_ft || 0);
        setLanding_distance_ft(landing_distance_ft || 0);
    };

    const onSelectHomeAirport = (value) => {
        setHomeAirport(value);
    };

    const onSelectPriceReflectionAirport = (value) => {
        setPriceReflectionAirport(value);
    };

    const handleChange = async ({ file, fileList }) => {
        const index = fileList.length;
        getBase64(fileList[index - 1].originFileObj, (imageUrl) => {
            setImageUrl(imageUrl);
            setTempImages([
                ...tempImages,
                {
                    id: 'temp_id_' + temp_id,
                    url: imageUrl,
                    file: file
                }
            ]);
            setTemp_id(temp_id + 1);
        });
    };

    const initProps = {
        id: id,
        buttonClicked: buttonClicked,
        last_refurbished_at: last_refurbished_at,
        set_last_refurbished_at: set_last_refurbished_at,
        loading: loading,
        buttonValid: buttonValid,
        model: model,
        setModel: setModel,
        handleChange: handleChange,
        year_of_manufacture: year_of_manufacture,
        set_year_of_manufacture: set_year_of_manufacture,
        manufacturer: manufacturer,
        set_manufacturer: set_manufacturer,
        onSelectPriceReflectionAirport: onSelectPriceReflectionAirport,
        onSelectHomeAirport: onSelectHomeAirport,
        classtype,
        set_classtype,
        operator_name: operator_name,
        set_operator_name: set_operator_name,
        tail_number: tail_number,
        set_tail_number: set_tail_number,
        name: name,
        successMsg: successMsg,
        visibleModal: visibleModal,
        amenities_extra_leg_room: amenities_extra_leg_room,
        amenities_wifi: amenities_wifi,
        amenities_bed: amenities_bed,
        amenities_sink: amenities_sink,
        amenities_lavatory: amenities_lavatory,
        tempImages: tempImages,
        activeCompany: activeCompany,
        imageUrl: imageUrl,
        departure_fee: departure_fee,
        landing_fee: landing_fee,
        overnight_stay_fee: overnight_stay_fee,
        parking_fee: parking_fee,
        tax_percentage: tax_percentage,
        minimum_booking_time_hours: minimum_booking_time_hours,
        airport_ground_time_minutes: airport_ground_time_minutes,
        positioning_flight_per_hour_fee: positioning_flight_per_hour_fee,
        revenue_flight_per_hour_fee: revenue_flight_per_hour_fee,
        short_term_increment_percentage_6hrs:
            short_term_increment_percentage_6hrs,
        short_term_increment_percentage_12hrs:
            short_term_increment_percentage_12hrs,
        short_term_increment_percentage_24hrs:
            short_term_increment_percentage_24hrs,
        short_term_increment_percentage_48hrs:
            short_term_increment_percentage_48hrs,

        cruising_altitude_ft: cruising_altitude_ft,
        cruising_speed_in_knots: cruising_speed_in_knots,
        climb_rate_fpm: climb_rate_fpm,
        descent_rate_fpm: descent_rate_fpm,
        climb_speed_in_knots: climb_speed_in_knots,
        descent_speed_in_knots: descent_speed_in_knots,
        takeoff_distance_ft: takeoff_distance_ft,
        landing_distance_ft: landing_distance_ft,
        average_speed_mph: average_speed_mph,
        max_range_in_nautical_miles: max_range_in_nautical_miles,
        luggage_capacity_ft3: luggage_capacity_ft3,
        max_takeoff_weight_lb: max_takeoff_weight_lb,
        empty_weight_lb: empty_weight_lb,
        wing_area_ft2: wing_area_ft2,
        wingspan_ft: wingspan_ft,
        length_ft: length_ft,
        height_ft: height_ft,
        lavatories: lavatories,
        max_passengers: max_passengers,
        crew: crew,
        setCrew: setCrew,
        price_reflection_airport: price_reflection_airport,
        current_airport: current_airport,
        home_airport: home_airport,
        description: description,
        setDescription: setDescription,
        images: images,
        isEdit: isEdit,
        navigate: navigate,
        submit: submit,
        cancelModal: cancelModal,
        onSearchGenericAircraft: onSearchGenericAircraft,
        onSelectAircraft: onSelectAircraft,
        setName: setName,
        setLavatories: setLavatories,
        setMax_range_in_nautical_miles: setMax_range_in_nautical_miles,
        setAverage_speed_mph: setAverage_speed_mph,
        setCruising_altitude_ft: setCruising_altitude_ft,
        setCruising_speed_in_knots: setCruising_speed_in_knots,
        setClimb_rate_fpm: setClimb_rate_fpm,
        setDescent_rate_fpm: setDescent_rate_fpm,
        setClimb_speed_in_knots: setClimb_speed_in_knots,
        setDescent_speed_in_knots: setDescent_speed_in_knots,
        setTakeoff_distance_ft: setTakeoff_distance_ft,
        setLanding_distance_ft: setLanding_distance_ft,
        setHeight_ft: setHeight_ft,
        setLength_ft: setLength_ft,
        setWingspan_ft: setWingspan_ft,
        setWing_area_ft2: setWing_area_ft2,

        setEmpty_weight_lb: setEmpty_weight_lb,
        setMax_takeoff_weight_lb: setMax_takeoff_weight_lb,
        setLuggage_capacity_ft3: setLuggage_capacity_ft3,

        set_tax_percentage: set_tax_percentage,
        set_minimum_booking_time_hours: set_minimum_booking_time_hours,
        set_airport_ground_time_minutes: set_airport_ground_time_minutes,
        set_positioning_flight_per_hour_fee:
            set_positioning_flight_per_hour_fee,
        set_revenue_flight_per_hour_fee: set_revenue_flight_per_hour_fee,
        set_short_term_increment_percentage_6hrs:
            set_short_term_increment_percentage_6hrs,
        set_short_term_increment_percentage_12hrs:
            set_short_term_increment_percentage_12hrs,
        set_short_term_increment_percentage_24hrs:
            set_short_term_increment_percentage_24hrs,
        set_short_term_increment_percentage_48hrs:
            set_short_term_increment_percentage_48hrs,
        set_departure_fee: set_departure_fee,
        set_landing_fee: set_landing_fee,
        set_overnight_stay_fee: set_overnight_stay_fee,
        set_parking_fee: set_parking_fee,
        setIsEdit: setIsEdit,
        set_amenities_extra_leg_room: set_amenities_extra_leg_room,
        set_amenities_wifi: set_amenities_wifi,
        set_amenities_bed: set_amenities_bed,
        set_amenities_sink: set_amenities_sink,
        set_amenities_lavatory: set_amenities_lavatory,
        setMaxPassenger: setMaxPassenger,
        onDeletePhoto: onDeletePhoto
    };

    return tmpl && tmpl({ ...initProps });
};

class AddAircraftContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <AddAircraftLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { userReducer, companyReducer } = state;
    const { firstname, lastname } = userReducer;
    const { activeCompany, companies } = companyReducer;
    return {
        firstname: firstname,
        lastname: lastname,
        companies: companies,
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        searchGenericAircrafts: (input) =>
            dispatch(searchGenericAircrafts(input)),
        uploadImage: (id, photo, model, type) =>
            dispatch(uploadImage(id, photo, model, type)),
        addAircraft: (data) => dispatch(addAircraft(data))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AddAircraftContainer)
);
