import React from 'react';
import styled from 'styled-components';
import { Row, Col, Divider, Image, Avatar } from 'antd';
import { Text } from '../..';
import {
    IconDeparture,
    IconLand,
    jetIcon,
    iconBag,
    defaultAvarta,
    iconBoardingPass
} from '../../../assets/images/icons';
import { useTranslation } from 'react-i18next';

const convertNumbertoArray = (number) => {
    let array = [];
    if (number > 0) {
        for (let i = 0; i < number; i++) {
            array.push(i+1);
        }
    }
    return array;
}

const PassengerInfoCard = (props) => {
    const { t, i18n } = useTranslation();
    const {
        aircraft_categories = [],
        passengers = 0,
        passenger_list = [],
        showTitle = true
    } = props;

    return (
        <PassengerInfoContainer>
            {showTitle && (
                <Row style={{ paddingBottom: 20, marginTop: 20 }}>
                    <Text textStyle="heading5">
                        {t('PASSENGER_INFO').toUpperCase()}
                    </Text>
                </Row>
            )}

            <PassengerInfoInner>
                <Col>
                    <Row>
                        <Col>
                            <Row>
                                <Text textStyle="heading6">
                                    {/* Passengers (2 Pax) */}
                                    {t('PASSENGERS') +
                                        ' (' +
                                        (Array.isArray(passenger_list) &&
                                        passenger_list.length > 0
                                            ? passenger_list.length
                                            : passengers) +
                                        ' Pax)'}
                                </Text>
                            </Row>
                            <Row>
                                {/* no passenger info added */}
                                {Array.isArray(passenger_list) &&
                                passenger_list.length > 0 ? (
                                    <>
                                        {passenger_list.map(
                                            (passenger, index) => {
                                                return (
                                                    <EachCard key={index}>
                                                        <PassengerCard>
                                                            <Col>
                                                                <Avatar
                                                                    src={
                                                                        passenger.image ||
                                                                        defaultAvarta
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col
                                                                style={{
                                                                    marginLeft: 20
                                                                }}
                                                            >
                                                                <Row>
                                                                    <Text textStyle="heading5">
                                                                        {passenger.firstname +
                                                                            ' ' +
                                                                            passenger.lastname}
                                                                    </Text>
                                                                    <BoardingPass
                                                                        src={
                                                                            iconBoardingPass
                                                                        }
                                                                    />
                                                                </Row>
                                                                <Row>
                                                                    <Text textStyle="tableText1">
                                                                        {(passenger.weight_lb ||
                                                                            '-') +
                                                                            ' lb'}
                                                                    </Text>
                                                                </Row>
                                                                <Row>
                                                                    <Text textStyle="tableText1">
                                                                        {
                                                                            passenger.email
                                                                        }
                                                                    </Text>
                                                                </Row>
                                                                <Row>
                                                                    <Text textStyle="tableText1">
                                                                        {passenger.phone ||
                                                                            '-'}
                                                                    </Text>
                                                                </Row>
                                                            </Col>
                                                        </PassengerCard>
                                                    </EachCard>
                                                );
                                            }
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {convertNumbertoArray(passengers).map((ps, i) => {
                                            return (
                                                <EachCard key={i}>
                                                    <PassengerCard>
                                                        <Col>
                                                            <Avatar
                                                                src={defaultAvarta}
                                                            />
                                                        </Col>
                                                        <Col
                                                            style={{
                                                                marginLeft: 20
                                                            }}
                                                        >
                                                            <Row>
                                                                <Text textStyle="heading5">
                                                                    {`Passenger ${ps}`}
                                                                </Text>
                                                            </Row>
                                                            <Row>
                                                                <Text>
                                                                    No info added
                                                                </Text>
                                                            </Row>
                                                        </Col>
                                                    </PassengerCard>
                                                </EachCard>
                                            );
                                        })}
                                    </>
                                )}
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                        <Col>
                            <Row>
                                <Text textStyle="heading6">
                                    Luggages Weight
                                </Text>
                            </Row>
                            <Row>
                                <Text>
                                    Luggages Weight will add after accepting
                                    quotation
                                </Text>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </PassengerInfoInner>
        </PassengerInfoContainer>
    );
};

export default PassengerInfoCard;

const BoardingPass = styled.img`
    margin-left: 10px;
`;

const EachCard = styled(Col)`
    margin-right: 25px;
`;

const PassengerCard = styled(Row)`
    background-color: #f7f8fb;
    border-radius: 4px;
    min-width: 345px;
    padding-left: 17px;
    margin-top: 20px;
    padding-top: 12px;
    padding-bottom: 16px;
`;

const PassengerInfoContainer = styled.div`
    width: 100%;
`;

const PassengerInfoInner = styled(Row)`
    background-color: #ffffff;
    border-radius: 4px;
    padding: 13px 30px 30px 30px;
`;
