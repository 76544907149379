import React from 'react';
import styled from 'styled-components';
// import { Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

/**
 *
 * @param {*} props
 * @returns
 */
const Text = (props) => {
    const { children, textStyle, style } = props;

    const renderText = () => {
        switch (textStyle) {
            case 'fieldLabel':
                return <FieldLabel style={style}>{children}</FieldLabel>;
            case 'heading6':
                return <Heading6 style={style}>{children}</Heading6>;
            case 'heading5':
                return <Heading5 style={style}>{children}</Heading5>;
            case 'heading4':
                return <Heading4 style={style}>{children}</Heading4>;
            case 'heading3':
                return <Heading3 style={style}>{children}</Heading3>;
            case 'heading2':
                return <Heading2 style={style}>{children}</Heading2>;
            case 'heading1':
                return <Heading1 style={style}>{children}</Heading1>;
            case 'jetCode':
                return <JetCode style={style}>{children}</JetCode>;
            case 'nameHighlight':
                return <NameHighlight style={style}>{children}</NameHighlight>;
            case 'airportName':
                return <AirportName style={style}>{children}</AirportName>;
            case 'nameNormal':
                return <NameNormal style={style}>{children}</NameNormal>;
            case 'infoNormal':
                return <InfoNormal style={style}>{children}</InfoNormal>;
            case 'paragraphMedium':
                return (
                    <ParagraphMedium style={style}>{children}</ParagraphMedium>
                );
            case 'tableText1':
                return <TableText1 style={style}>{children}</TableText1>;
            case 'tableText2':
                return <TableText2 style={style}>{children}</TableText2>;
            case 'upload':
                return <Upload style={style}>{children}</Upload>;
            case 'typeOfFlight':
                return <TypeOfFlight style={style}>{children}</TypeOfFlight>;
            case 'description':
                return (
                    <DescriptionText style={style}>{children}</DescriptionText>
                );
            case 'dateTimeBold':
                return <DateTimeBold style={style}>{children}</DateTimeBold>;
            default:
                return <Default style={style}>{children}</Default>;
        }
    };

    return renderText();
};

export default Text;

const DateTimeBold = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
`;

const Heading2 = styled.span`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 24px;
    letter-spacing: 1px;
`;

const Heading1 = styled.span`
    color: #121216;
    font-family: AvenirNext-Bold;
    font-size: 36px;
    letter-spacing: 3px;
`;

const TypeOfFlight = styled.span`
    color: #19c0ff;
    font-family: AvenirNext-DemiBold;
    font-size: 9px;
    letter-spacing: 0.7px;
`;

const AirportName = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 13px;
    line-height: 16px;
`;

const Upload = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    letter-spacing: 0.4px;
    line-height: 13px;
`;

const InfoNormal = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
`;

const NameNormal = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.84px;
    line-height: 14px;
`;

const TableText2 = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
`;

const TableText1 = styled.span`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 11px;
    line-height: 13px;
`;

const NameHighlight = styled.span`
    color: #19c0ff;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
`;

const JetCode = styled.div`
    border: 1px solid #dadadb;
    border-radius: 2px;
    width: 58px;
    height: 20px;
    color: #19c0ff;
    font-family: AvenirNext-Bold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    width: 56px;
    text-align: center;
    padding: 3px;
`;

const Heading3 = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 20px;
    letter-spacing: 0.8px;
`;

const Heading4 = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 16px;
    line-height: 26px;
`;

const Heading5 = styled.span`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.7px;
`;

const Heading6 = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 14px;
    letter-spacing: 0.3px;
`;

const FieldLabel = styled.span`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
`;

const ParagraphMedium = styled.span`
    color: #65686f;
    font-family: Lato;
    font-size: 14px;
    line-height: 24px;
`;

const DescriptionText = styled.span`
    color: #65686f;
    font-family: Lato;
    font-size: 13px;
    line-height: 23px;
    width: 466px;
    text-align: left;
`;

const Default = styled.span`
    color: #65686f;
    font-family: Lato;
    font-size: 13px;
`;
