import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { Row, Col, Switch, AutoComplete, Input } from 'antd';
import '../services.css';
import { WrapperComponent } from '../styles';
import { HeaderSerices, AircraftItem } from '../../../components/Cards';
import { getWindowDimensions } from 'utils';
const Aircrafts = (props) => {
    const {
        isEdit,
        onClickEdit,
        all_aircrafts_available,
        setAircrafts,
        aircrafts = [],
        onSearchAircrafts
    } = props;

    const { t, i18n } = useTranslation();

    const [options, setOptions] = useState([]);
    const [aircraft, setAircraft] = useState('');
    const [search, setSearch] = useState([]);

    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const onChangeChecked = () => {
        setAircrafts({
            all_aircrafts_available: !all_aircrafts_available,
            aircrafts: aircrafts
        });
    };

    const onSelect = (value) => {
        let isExist = aircrafts.some((v) => v.name === value);
        if (isExist) return;

        let data = search.find((v) => v.name === value);
        let values = aircrafts;
        values.push(data);

        setAircrafts({
            all_aircrafts_available: all_aircrafts_available,
            aircrafts: values
        });

        setAircraft('');
    };

    const onRemove = (val) => {
        let values = aircrafts.filter((v) => v.id !== val.id);
        setAircrafts({
            all_aircrafts_available: all_aircrafts_available,
            aircrafts: values
        });
    };

    const searchResult = (results) =>
        results.map((val, i) => {
            let text = val.name.toUpperCase() + ' - ' + val.tail_number;
            return {
                value: val.name,
                label: (
                    <DropdownWrapper>
                        <DropdownText>{text}</DropdownText>
                    </DropdownWrapper>
                )
            };
        });

    const handleSearch = async (value) => {
        if (!value) {
            setOptions(searchResult([]));
            return;
        }

        const result = await onSearchAircrafts(value);
        setSearch(result || []);
        setOptions(result ? searchResult(result) : []);
    };

    const _renderEditView = () => {
        return (
            <Component>
                <Row className="row-edit-service">
                    <ItemWrapper>
                        <ItemTitle>
                            {i18n
                                .t('AVAILABLE_FOR_ALL_AIRCRAFTS')
                                .toUpperCase()}
                        </ItemTitle>
                        <Switch
                            checked={all_aircrafts_available}
                            style={{ marginLeft: 5 }}
                            onChange={() => onChangeChecked()}
                        />
                    </ItemWrapper>
                </Row>
                {!all_aircrafts_available && (
                    <>
                        <InfoWrapper>
                            <TypeTitle>{i18n.t('CHOOSE_AIRCRAFTS')}</TypeTitle>
                        </InfoWrapper>
                        <AircraftsWrapper>
                            <Row>
                                <Col lg="3" sm="3" xs="12">
                                    <AutoComplete
                                        dropdownMatchSelectWidth={252}
                                        style={{
                                            width:
                                                windowDimensions.width > 768
                                                    ? 370
                                                    : 300,
                                            marginTop: 25,
                                            marginLeft: 15
                                        }}
                                        options={options}
                                        onSelect={onSelect}
                                        onSearch={handleSearch}
                                        value={aircraft}
                                    >
                                        <Input
                                            onChange={(val) =>
                                                setAircraft(val.target.value)
                                            }
                                            className="base"
                                            placeholder={i18n.t(
                                                'SEARCH_AIRCRAFTS'
                                            )}
                                        />
                                    </AutoComplete>
                                </Col>
                            </Row>
                            <AircraftsList>
                                {aircrafts.map((val, i) => {
                                    return (
                                        <AircraftItem
                                            onRemove={onRemove}
                                            val={val}
                                            key={i}
                                            isFinal={aircrafts.length === i + 1}
                                        />
                                    );
                                })}
                            </AircraftsList>
                        </AircraftsWrapper>
                    </>
                )}
            </Component>
        );
    };

    const _renderDetailView = () => {
        return (
            <Component>
                <Row className="row-detail-service">
                    <ItemWrapper>
                        <ItemTitle>
                            {i18n
                                .t('AVAILABLE_FOR_ALL_AIRCRAFTS')
                                .toUpperCase()}
                        </ItemTitle>
                        <Switch
                            checked={all_aircrafts_available}
                            style={{ marginLeft: 5 }}
                        />
                    </ItemWrapper>
                </Row>
                <AircraftsList style={{ marginLeft: 25 }}>
                    {!all_aircrafts_available &&
                        aircrafts.map((val, i) => {
                            return (
                                <AircraftItem
                                    onRemove={onRemove}
                                    val={val}
                                    key={i}
                                    isFinal={aircrafts.length === i + 1}
                                />
                            );
                        })}
                </AircraftsList>
            </Component>
        );
    };

    return (
        <WrapperComponent>
            <HeaderSerices
                title={i18n.t('AIRCRAFTS').toUpperCase()}
                isUpdate={isEdit}
                onClick={onClickEdit}
            />
            {!isEdit ? _renderDetailView() : _renderEditView()}
        </WrapperComponent>
    );
};

export default Aircrafts;

const Component = styled.div`
    flex: row;
    background-color: #ffffff;
    border-radius: 4px;
    margin-top: 15px;
    justify-content: center;
    align-items: center;
    min-height: 65px;
`;

const ItemWrapper = styled.span`
    min-width: 182px;
    height: 30px;
`;

const ItemTitle = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 18px;
    text-align: left;
    margin-right: 50px;
`;

const InfoWrapper = styled.div`
    background-color: #f6f9fc;
    border-radius: 4px 4px 0 0;
    height: 50px;
    padding-left: 25px;
    padding-top: 15px;
`;

const TypeTitle = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 17px;
    text-align: left;
`;

const DropdownWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const DropdownText = styled.span``;

const AircraftsWrapper = styled.div`
    margin-left: 15px;
`;

const AircraftsList = styled.div`
    margin-bottom: 15px;
    margin-left: 15px;
`;
