import React, { useState } from 'react';
import { Drawer, Button } from 'antd';
import styled from 'styled-components';

import { Text } from 'components';
import moment from 'moment';
import _ from 'lodash';
import { renderAddress } from 'utils';
import { eventIcon } from 'assets/images/icons';
import { ContainerDrawer, WrapperDrawer } from '../../styles';

const Reminder = (props) => {
    const { content = '', createAt = '', address = {} } = props;

    return (
        <ContainerDrawer>
            <WrapperDrawer>
                <div style={{ display: 'flex' }}>
                    <Icon src={eventIcon} />
                    <Text textStyle="tableText2">EVENT</Text>
                </div>
                <TextItem>
                    {createAt
                        ? moment(createAt).fromNow()
                        : ''}
                </TextItem>

                <TextTitle style={{ marginTop: '8px' }}>
                    {content || ''}
                </TextTitle>

                {!_.isEmpty(address) && (
                    <TextItem>{renderAddress(address)}</TextItem>
                )}
            </WrapperDrawer>
        </ContainerDrawer>
    );
};

export default Reminder;

const Icon = styled.img`
    width: 14px;
    height: 14px;
    margin-right: 10px;
`;

const TextItem = styled.div`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 11px;
    line-height: 16px;
    text-align: left;
    margin-top: 3px;
`;

const TextTitle = styled.div`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    letter-spacing: 0.46px;
    line-height: 13px;
    text-align: left;
`;
