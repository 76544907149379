import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Tabs } from 'components';
import { Dashboard, Calendar } from './Tabs';
import { DashboardContainer } from './style';
import { IconCalendar, IconDashboard } from 'assets/images/icons';

const template = (props) => {
    const { t, i18n } = useTranslation();
    const { navigate } = props;

    const renderTabs = () => {
        let tabRenderer = [];

        tabRenderer = [
            {
                key: 'DASHBOARD',
                label: t('DASHBOARD').toUpperCase(),
                // leg: legs[0],
                render: renderDashboard(),
                icon: IconDashboard
            },
            {
                key: 'CALENDAR',
                label: t('CALENDAR').toUpperCase(),
                // leg: legs[1],
                render: renderCalendar(),
                icon: IconCalendar
            }
        ];

        return tabRenderer;
    };

    const renderDashboard = () => {
        return <Dashboard navigate={navigate} />;
    };

    const renderCalendar = () => {
        return <Calendar navigate={navigate} />;
    };

    const onChangeTabs = () => {
        //
    };

    return (
        <DashboardContainer>
            <div style={{ marginBottom: 15 }}>
                <Header
                    title={t('DASHBOARD')}
                    //TODO: bring this quote to en.js and ask for AJ operator from backend side
                    description={'Welcome back to AJ Operator'}
                />
            </div>

            <Tabs tabRenderer={renderTabs()} onChange={onChangeTabs} />
        </DashboardContainer>
    );
};

export { template };
