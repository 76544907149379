import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Row, Col, Table, Image } from 'antd';
import { HeaderInfo } from 'components/Cards';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { WHITELABEL_SETTING, EDIT_WHITELABEL } from 'routes/main';
import { aboutIcon, infoIosIcon } from 'assets/images/icons';
import moment from 'moment';

import { WarningModal } from 'components/Modals';
import { PrimaryButton, SecondaryButton } from 'components';
import { updateIcon } from 'assets/images/icons';

const template = (props) => {
    const { 
        navigate,
        whitelabelList,
        whitelabelSelected,
        onChangeWhitelabelStatus,
        showWhitelabelStatusModal,
        openModal,
        onCancelWarning
    } = props;

    const { title, okText, cancelText, whitelabelId, whitelabelStatus } = whitelabelSelected;

    const { t, i18n } = useTranslation();

    const columns = [
        {
            key: 'avatar',
            fixed: 'left',
            title: function titleHeader() {
                return (
                    <ColumnHeader>
                        {t('AVATAR').toUpperCase()}
                    </ColumnHeader>
                );
            },
            dataIndex: 'avatar',
            render: function eachItemName(name, row) {
                const { manifest = {} } = row;
                const { logo = '' } = manifest;
                return (
                    <WhiteLabelLogoContainer>
                        <CompanyLogo src={logo} />
                    </WhiteLabelLogoContainer>
                );
            }
        },
        {
            key: 'name',
            title: function titleHeader() {
                return (
                    <ColumnHeader>
                        {t('WHITELABELS').toUpperCase()}
                    </ColumnHeader>
                );
            },
            dataIndex: 'name',
            render: function eachItemName(name, row) {
                return (
                    <CompanyName>{name}</CompanyName>
                );
            }
        },
        {
            key: 'domain',
            title: function titleHeader() {
                return <ColumnHeader>{t('URL').toUpperCase()}</ColumnHeader>;
            },
            dataIndex: 'domain',
            render: function eachUrl(domain) {
                return (
                    <Url
                        onClick={() => {
                            window.open('https://' + domain, '_blank');
                        }}
                    >
                        {domain}
                    </Url>
                );
            }
        },
        {
            key: 'created_at',
            title: function titleHeader() {
                return (
                    <ColumnHeader>{t('DATE_CREATED').toUpperCase()}</ColumnHeader>
                );
            },
            dataIndex: 'created_at',
            width: '18%',
            render: function eachCreatedAt(created_at) {
                return (
                    <CreatedAt>
                        {moment(created_at).format('dddd, MMMM DD, YYYY')}
                    </CreatedAt>
                );
            }
        },
        {
            key: 'updated_at',
            title: function titleHeader() {
                return (
                    <ColumnHeader>{t('DATE_MODIFIED').toUpperCase()}</ColumnHeader>
                );
            },
            dataIndex: 'updated_at',
            width: '18%',
            render: function eachCreatedAt(updated_at) {
                return (
                    <CreatedAt>
                        {moment(updated_at).format('dddd, MMMM DD, YYYY')}
                    </CreatedAt>
                );
            }
        },
        {
            key: 'id',
            dataIndex: 'id',
            width: '22%',
            render: function eachItemId(_, row) {
                const { id, name, active } = row;
                return (
                    <ActionContainer>
                        {
                            active ?  <ActionButton onClick={() => openModal(id, name, active)}><ActionText>{t('DISABLE').toUpperCase()}</ActionText></ActionButton> 
                            : <PrimaryButton size='small' title={t('ENABLE').toUpperCase()} onClick={() => openModal(id, name, active)}/>
                        }
                        <UpdateButton onClick={() => navigate(EDIT_WHITELABEL, id)}>
                            <UpdateIcon src={updateIcon} />
                            <ActionText>{t('UPDATE').toUpperCase()}</ActionText>
                        </UpdateButton>
                    </ActionContainer>
                );
            }
        }
    ];

    return (
        <ContainerWrapper>
            <PayoutSection>
                <HeaderInfo title={t('PAYOUTS').toUpperCase()} />
                <ConnectStripeIntro>
                    <InfoIcon src={infoIosIcon} />
                    <PayoutIntroText>{t('PAYOUT_INTRO')}</PayoutIntroText>
                </ConnectStripeIntro>
                <ConnectStripeContainer>
                    <ConnectStripeButton>
                        <ConnectStripeText>
                            {t('CONNECT_STRIPE').toUpperCase()}
                        </ConnectStripeText>
                    </ConnectStripeButton>
                </ConnectStripeContainer>
            </PayoutSection>

            <PayoutSection style={{ marginTop: 40 }}>
                <HeaderInfo title={t('WHITELABEL').toUpperCase()} />
                <WhitelabelContainer>
                    {whitelabelList.length > 0 ? (
                        <>
                            <Table
                                // style={{ marginLeft: 25 }}
                                scroll={{ x: 1200 }}
                                columns={columns}
                                pagination={false}
                                dataSource={whitelabelList}
                                // locale={localeEmptyTable()}
                            ></Table>
                            <SetupWhitelabelButton
                                onClick={() =>
                                    navigate && navigate(WHITELABEL_SETTING)
                                }
                            >
                                <SetupWhitelabelText>
                                    {t('CREATE_NEW_WHITELABEL').toUpperCase()}
                                </SetupWhitelabelText>
                            </SetupWhitelabelButton>
                        </>
                    ) : (
                        <>
                            <NoWhitelabel>{t('NO_WHITELABEL')}</NoWhitelabel>
                            <SetupWhitelabelButton
                                onClick={() =>
                                    navigate && navigate(WHITELABEL_SETTING)
                                }
                            >
                                <SetupWhitelabelText>
                                    {t('SETUP_WHITELABEL').toUpperCase()}
                                </SetupWhitelabelText>
                            </SetupWhitelabelButton>
                        </>
                    )}
                </WhitelabelContainer>
            </PayoutSection>
            <WarningModal
                show={showWhitelabelStatusModal}
                title={title?.toUpperCase()}
                okText={okText?.toUpperCase()}
                cancelText={cancelText?.toUpperCase()}
                onOk={() => onChangeWhitelabelStatus(whitelabelId, whitelabelStatus)}
                onCancel={onCancelWarning}
            />
        </ContainerWrapper>
    );
};

export { template };

const ActionContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
`;

const ActionText = styled.div`
    color: #16161b;
    font-family: AvenirNext-DemiBold;
    letter-spacing: 0.7px;
    line-height: 12px;
    font-size: 10px;
    padding-top: 2px;
`;

const ActionButton = styled.button`
    height: 32px;
    width: 110px;
    border: 1px solid rgba(18, 18, 22, 0.2);
    border-radius: 2px;
    background: transparent;
    background-color: #ffffff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const UpdateButton = styled(ActionButton)`
    margin-left: 20px;
`;

const CreatedAt = styled.span`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 12px;
    line-height: 18px;
`;

const Url = styled.a`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
`;

const CompanyName = styled.span`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.8px;
    line-height: 12px;
    text-transform: uppercase;
`;

const CompanyLogo = styled.img`
    border: 1px solid #dadadb;
    object-fit: contain;
    border-radius: 3px;
    width: 110px;
    height: 70px;
`;

const WhiteLabelLogoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ColumnHeader = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 18px;
`;

const SetupWhitelabelText = styled.span`
    color: #16161b;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
`;

const SetupWhitelabelButton = styled.button`
    background: transparent;
    border: 1px solid rgba(18, 18, 22, 0.2);
    width: 182px;
    height: 32px;
    border-radius: 2px;
    margin-top: 20px;
`;

const WhitelabelContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .ant-table-wrapper {
        width: 100%;
        padding: 0 40px;
    }
`;

const NoWhitelabel = styled.span`
    color: #8e929a;
    font-family: AvenirNext-Medium;
    font-size: 14px;
    line-height: 17px;
`;

const ConnectStripeContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

const ConnectStripeButton = styled.button`
    background: transparent;
    background-color: #19c0ff;
    border: none;
    background-repeat: no-repeat;
    overflow: hidden;
    outline: none;
    border-radius: 2px;
    height: 40px;
    width: 190px;
    &:hover {
        background-color: #12afeb;
    }
`;

const ConnectStripeText = styled.span`
    color: #ffffff;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    letter-spacing: 0.7px;
    line-height: 13px;
    text-align: center;
`;

const PayoutIntroText = styled.span`
    color: #65686f;
    font-family: Lato;
    font-size: 13px;
    line-height: 23px;
    max-width: 1089px;
    white-space: pre-wrap;
`;

const UpdateIcon = styled.img`
    width: 13px;
    height: 13px;
    margin-right: 10px;
`;

const InfoIcon = styled.img`
    margin-top: 5px;
    margin-right: 15px;
    width: 16px;
    height: 16px;
`;

const ConnectStripeIntro = styled.div`
    margin: 25px 25px 0 47px;
    background-color: #f8f9fe;
    border-radius: 4px;
    padding: 15px 20px;
    display: flex;
`;

const ContainerWrapper = styled.div`
    padding-bottom: 40px;
    border-radius: 4px;
    margin-top: 14px;
    .ant-table-cell {
        background-color: #ffffff;
    }
`;

const PayoutSection = styled.div`
    padding-left: 20px;
    padding-bottom: 40px;
    background-color: #ffffff;
    border-radius: 4px;
`;
