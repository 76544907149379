import React, { useState, useEffect } from 'react';
import { template as tmpl } from './AddFBO.tmpl';
import { connect, useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { addHandler } from 'redux/actions/companyAction';
import { uploadImage } from 'redux/actions/appActions';
import { useTranslation } from 'react-i18next';
import { UPLOAD_MODEL, SHOW_LOADING, HIDE_LOADING } from 'redux/Constants';

const AddFBOLogic = (props) => {
    const { companies = [], addHandler, uploadImage, location } = props;
    const { isLoading } = useSelector(state => state.appReducer || {});
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const [visibleModal, setVisibleModal] = useState(false);
    const [error, setError] = useState([]);
    const [successMsg, setSuccessMsg] = useState('');

    const navigate = (route) => {
        props.history.push(route);
    };

    const onSubmit = async (data, photo) => {
        let rs;
        setError([]);
        if (isLoading) return;
        dispatch({ type: SHOW_LOADING });

        rs = await addHandler(data);
        if (!rs || !rs.success) {
            alert('Add New FBO Failed!');
            return;
        }

        // photo (optional)
        if (photo) {
            rs = await uploadImage(rs.handler_id, photo, UPLOAD_MODEL.HANDLER);
            if (!rs || !rs.success) {
                alert('Upload Image FBO Failed!');
                return;
            }
        }

        if (rs && rs.success) {
            setVisibleModal(true);
            setSuccessMsg(t('FBO_SUCCESSFULLY_ADDED').toUpperCase());
        } else if (!rs.success && rs.error.errormsg) {
            setError(rs.error.errormsg);
        }

        setTimeout(() => {
            dispatch({ type: HIDE_LOADING });
        }, 450);
    };

    const cancelModal = () => {
        setVisibleModal(false);
    };

    const initProps = {
        successMsg: successMsg,
        error: error,
        companies: companies,
        visibleModal: visibleModal,
        cancelModal: cancelModal,
        onSubmit: onSubmit,
        navigate: navigate
    };

    return tmpl && tmpl({ ...initProps });
};

class AddFBOContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <AddFBOLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { companies } = companyReducer;
    return {
        companies: companies
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addHandler: (data) => dispatch(addHandler(data)),
        uploadImage: (id, photo, model) =>
            dispatch(uploadImage(id, photo, model))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AddFBOContainer)
);
