import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { HeaderTag, Button } from '../../index';

const template = (props) => {
    const { t, i18n } = useTranslation();
    const { title, description } = props;
    return (
        <StyledHeader>
            <HeaderLeft>
                <HeaderTag title={title} description={description} />
            </HeaderLeft>
            {props.headerRight && (
                <HeaderRight>{props.headerRight()}</HeaderRight>
            )}
        </StyledHeader>
    );
};

const HeaderLeft = styled.div``;

const HeaderRight = styled.div`
    margin-top: auto;
    margin-bottom: auto;
`;

const StyledHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
`;

export { template };
