import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import common_en from './en/en';
import common_vn from './vn/vn';

i18next.use(initReactI18next).init({
    interpolation: { escapeValue: false }, // React already does escaping
    lng: 'en', // language to use
    fallbackLng: 'en',
    resources: {
        en: {
            translation: common_en
        }
        // Other languages will put down here.
        // vn: {
        //     translation: common_vn
        // }
    }
});

export default i18n;
