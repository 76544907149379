import React from 'react';
import styled from 'styled-components';
import { Select, Slider } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * @param {*} props
 * @param {*} textStyle fieldLabel
 * @returns
 */
const SliderComponent = (props) => {
    const { children, marks, defaultValue, width, onAfterChange } = props;
    const { t, i18n } = useTranslation();
    const defaultMarks = {
        0: '0k',
        100: '$100k'
    };

    const defaultOfDefaultValue = [26, 37];
    return (
        <SliderSection>
            <Slider
                className="ant-slider-custom"
                range
                marks={marks || defaultMarks}
                defaultValue={defaultValue || defaultOfDefaultValue}
                onAfterChange={onAfterChange}
            />
        </SliderSection>
    );
};

export default SliderComponent;

const SliderSection = styled.div``;