import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import styled from 'styled-components';

import { Header, Tabs, InvoicePaymentCard } from 'components';
import InvoiceInfoCard from '../../InvoiceInfoCard';
import InvoiceDetailsCard from '../../InvoiceDetailsCard';
import { aboutIcon, flightPostIcon } from 'assets/images/icons';
import { PAYMENT_STATUS, INVOICE_TYPE } from 'redux/Constants';

const template = (props) => {
    const { navigate, loading = false, invoiceDetail = {} } = props;
    const {
        addressee = {},
        booking = {},
        legs = [],
        id = '',
        invoice_code = '',
        status = '',
        totals = {},
        type = '',
        created_at,
        finalised_at,
        partner_invoices = []
    } = invoiceDetail;

    const { t, i18n } = useTranslation();

    const _renderCustomerDetails = () => {
        return (
            <InvoiceDetailsCard
                status={status}
                totals={totals}
                type={type}
                invoiceCode={invoice_code}
                allowEdit={true}
            />
        );
    };

    const _renderOperatorDetails = () => {
        return (
            partner_invoices &&
            partner_invoices.map((invoice = {}, i) => {
                const {
                    invoice_code = '',
                    type = '',
                    status = '',
                    totals = {}
                } = invoice;
                return (
                    <InvoiceDetailsCard
                        key={i}
                        status={status}
                        totals={totals}
                        type={type}
                        invoiceCode={invoice_code}
                        allowEdit={false}
                    />
                );
            })
        );
    };

    const renderReturn = () => {
        const tabRenderer = [
            {
                key: 'CUSTOMER',
                label: t('CUSTOMER INVOICE').toUpperCase(),
                render: _renderCustomerDetails(),
                icon: aboutIcon
            },
            {
                key: 'OPERATOR',
                label: t('OPERATOR INVOICE').toUpperCase(),
                render: _renderOperatorDetails(),
                icon: flightPostIcon
            }
        ];
        return <Tabs tabRenderer={tabRenderer} />;
    };

    return (
        <Container>
            <Header
                title={`${t('INVOICE')} #${invoice_code}`}
                description={`${t('INVOICE_DETAILS')}`}
            />

            {status === PAYMENT_STATUS.AWAITING_PAYMENT ? (
                <InvoicePaymentCard
                    type={booking?.type}
                    booking_code={booking?.booking_code}
                    name={`${addressee?.firstname} ${addressee?.lastname}`}
                    payment_status={status}
                    legs={booking?.legs}
                />
            ) : (
                <InvoiceInfoCard
                    invoiceCode={invoice_code}
                    bookingCode={booking?.booking_code}
                    addressee={addressee}
                    createdAt={created_at}
                    finalisedAt={finalised_at}
                    totals={totals}
                    type={INVOICE_TYPE.CUSTOMER}
                    status={status}
                />
            )}

            <div style={{ marginTop: '40px' }}>{renderReturn()}</div>
        </Container>
    );
};

export { template };

const Container = styled.div`
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    margin-right: 20px;
`;
