import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { IconBoardingPass } from 'assets/images/icons';
import { Text, Calendar, BigCalendar } from 'components';
import { useTranslation } from 'react-i18next';

const template = (props) => {
    const { t, i18n } = useTranslation();
    const { events = [] } = props;

    return <BigCalendar events={events} />;
};

export { template };
