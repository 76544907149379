import React, { useEffect, useState } from 'react';
import { template as tmpl } from './AircraftDetailContainer.tmpl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    getAircraftById,
    removeAircraft,
    updateAircraft
} from '../../../redux/actions/aircarftAction';
import { uploadImage } from '../../../redux/actions/appActions';
import { UPLOAD_MODEL, UPLOAD_IMAGE_TYPE } from '../../../redux/Constants';
import { ADD_NEW_EMPLOYEE, BROKER_CONNECTIONS } from '../../../routes/main';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { getBase64 } from '../../../utils';
import { amenitiesList } from '../../../config/Constants';

const AircraftDetailLogic = (props) => {
    const { t, i18n } = useTranslation();
    const { location, activeCompany, uploadImage } = props;

    const { state, search } = location;

    const [visibleModal, setVisibleModal] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    //for upload images
    const [imageUrl, setImageUrl] = useState('');
    const [photo, setPhoto] = useState([]);
    const [temp_id, setTemp_id] = useState(0);

    const [isEdit, setIsEdit] = useState(false);

    const [aircraft, setAircraft] = useState({});
    const [id, setId] = useState('');

    const [images, setImages] = useState([]);
    const [tempImages, setTempImages] = useState([]);
    const [description, setDescription] = useState('');
    const [current_airport, setCurrentAirport] = useState({});
    const [home_airport, setHomeAirport] = useState({});
    const [price_reflection_airport, setPriceReflectionAirport] = useState({});
    const [crew, setCrew] = useState(0);
    const [max_passengers, setMaxPassenger] = useState(0);
    const [lavatories, setLavatories] = useState(0);
    const [height_ft, setHeight_ft] = useState(0);
    const [length_ft, setLength_ft] = useState(0);
    const [wingspan_ft, setWingspan_ft] = useState(0);
    const [wing_area_ft2, setWing_area_ft2] = useState(0);
    const [empty_weight_lb, setEmpty_weight_lb] = useState(0);
    const [max_takeoff_weight_lb, setMax_takeoff_weight_lb] = useState(0);
    const [luggage_capacity_ft3, setLuggage_capacity_ft3] = useState(0);
    const [max_range_in_nautical_miles, setMax_range_in_nautical_miles] =
        useState(0);
    const [average_speed_mph, setAverage_speed_mph] = useState(0);

    const [cruising_altitude_ft, setCruising_altitude_ft] = useState(0);
    const [cruising_speed_in_knots, setCruising_speed_in_knots] = useState(0);
    const [climb_rate_fpm, setClimb_rate_fpm] = useState(0);
    const [descent_rate_fpm, setDescent_rate_fpm] = useState(0);
    const [climb_speed_in_knots, setClimb_speed_in_knots] = useState(0);
    const [descent_speed_in_knots, setDescent_speed_in_knots] = useState(0);
    const [takeoff_distance_ft, setTakeoff_distance_ft] = useState(0);
    const [landing_distance_ft, setLanding_distance_ft] = useState(0);

    const [tax_percentage, set_tax_percentage] = useState(0);
    const [minimum_booking_time_hours, set_minimum_booking_time_hours] =
        useState(0);
    const [airport_ground_time_minutes, set_airport_ground_time_minutes] =
        useState(0);
    const [
        positioning_flight_per_hour_fee,
        set_positioning_flight_per_hour_fee
    ] = useState(0);
    const [revenue_flight_per_hour_fee, set_revenue_flight_per_hour_fee] =
        useState(0);
    const [
        short_term_increment_percentage_6hrs,
        set_short_term_increment_percentage_6hrs
    ] = useState(0);
    const [
        short_term_increment_percentage_12hrs,
        set_short_term_increment_percentage_12hrs
    ] = useState(0);
    const [
        short_term_increment_percentage_24hrs,
        set_short_term_increment_percentage_24hrs
    ] = useState(0);
    const [
        short_term_increment_percentage_48hrs,
        set_short_term_increment_percentage_48hrs
    ] = useState(0);

    const [departure_fee, set_departure_fee] = useState(0);
    const [landing_fee, set_landing_fee] = useState(0);
    const [overnight_stay_fee, set_overnight_stay_fee] = useState(0);
    const [parking_fee, set_parking_fee] = useState(0);

    const [amenities_extra_leg_room, set_amenities_extra_leg_room] =
        useState(false);
    const [amenities_wifi, set_amenities_wifi] = useState(false);
    const [amenities_bed, set_amenities_bed] = useState(false);
    const [amenities_sink, set_amenities_sink] = useState(false);
    const [amenities_lavatory, set_amenities_lavatory] = useState(false);

    useEffect(() => {
        if (!_.isEmpty(aircraft)) {
            const {
                images,
                description,
                properties = {},
                price_parameters = {},
                current_airport = {},
                home_airport = {},
                price_reflection_airport,
                id,
                amenities = []
            } = aircraft;
            amenities.includes(amenitiesList.EXTRA_LEG_ROOM)
                ? set_amenities_extra_leg_room(true)
                : set_amenities_extra_leg_room(false);
            amenities.includes(amenitiesList.WIFI)
                ? set_amenities_wifi(true)
                : set_amenities_wifi(false);
            amenities.includes(amenitiesList.BED)
                ? set_amenities_bed(true)
                : set_amenities_bed(false);
            amenities.includes(amenitiesList.SINK)
                ? set_amenities_sink(true)
                : set_amenities_sink(false);
            amenities.includes(amenitiesList.LAVATORY)
                ? set_amenities_lavatory(true)
                : set_amenities_lavatory(false);

            const {
                crew,
                max_passengers,
                lavatories,
                height_ft,
                length_ft,
                wingspan_ft,
                wing_area_ft2,
                empty_weight_lb,
                max_takeoff_weight_lb,
                luggage_capacity_ft3,
                max_range_in_nautical_miles,
                average_speed_mph,
                cruising_altitude_ft,
                cruising_speed_in_knots,
                climb_rate_fpm,
                descent_rate_fpm,
                climb_speed_in_knots,
                descent_speed_in_knots,
                takeoff_distance_ft,
                landing_distance_ft
            } = properties;
            setId(id);
            setImages(images || []);
            setTempImages(images || []);
            setDescription(description);
            setCurrentAirport(current_airport);
            setHomeAirport(home_airport);
            setPriceReflectionAirport(price_reflection_airport);
            setCrew(crew);
            setMaxPassenger(max_passengers);
            setLavatories(lavatories);
            setHeight_ft(height_ft);
            setLength_ft(length_ft);
            setWingspan_ft(wingspan_ft);
            setWing_area_ft2(wing_area_ft2);
            setEmpty_weight_lb(empty_weight_lb);
            setMax_takeoff_weight_lb(max_takeoff_weight_lb);
            setLuggage_capacity_ft3(luggage_capacity_ft3);

            setMax_range_in_nautical_miles(max_range_in_nautical_miles);
            setAverage_speed_mph(average_speed_mph);

            setCruising_altitude_ft(cruising_altitude_ft);
            setCruising_speed_in_knots(cruising_speed_in_knots);
            setClimb_rate_fpm(climb_rate_fpm);
            setDescent_rate_fpm(descent_rate_fpm);
            setClimb_speed_in_knots(climb_speed_in_knots);
            setDescent_speed_in_knots(descent_speed_in_knots);
            setTakeoff_distance_ft(takeoff_distance_ft);
            setLanding_distance_ft(landing_distance_ft);

            const {
                tax_percentage,
                minimum_booking_time_hours,
                airport_ground_time_minutes,
                positioning_flight_per_hour_fee,
                revenue_flight_per_hour_fee,
                short_term_increment_percentage_6hrs,
                short_term_increment_percentage_12hrs,
                short_term_increment_percentage_24hrs,
                short_term_increment_percentage_48hrs,
                fees = {}
            } = price_parameters;

            set_tax_percentage(tax_percentage);
            set_minimum_booking_time_hours(minimum_booking_time_hours);
            set_airport_ground_time_minutes(airport_ground_time_minutes);
            set_positioning_flight_per_hour_fee(
                positioning_flight_per_hour_fee
            );
            set_revenue_flight_per_hour_fee(revenue_flight_per_hour_fee);
            set_short_term_increment_percentage_6hrs(
                short_term_increment_percentage_6hrs
            );
            set_short_term_increment_percentage_12hrs(
                short_term_increment_percentage_12hrs
            );
            set_short_term_increment_percentage_24hrs(
                short_term_increment_percentage_24hrs
            );
            set_short_term_increment_percentage_48hrs(
                short_term_increment_percentage_48hrs
            );

            const {
                departure_fee,
                landing_fee,
                overnight_stay_fee,
                parking_fee
            } = fees;

            set_departure_fee(departure_fee);
            set_landing_fee(landing_fee);
            set_overnight_stay_fee(overnight_stay_fee);
            set_parking_fee(parking_fee);
        }
    }, [aircraft]);

    useEffect(() => {
        async function fetchAircraftData() {
            let id = search;
            if (id && id[0] === '?') {
                id = id.substring(1);

                const result = await props.getAircraftById(id);
                if (result.isConnected) {
                    const { aircraft } = result;
                    setAircraft(aircraft);
                }
            }
        }
        fetchAircraftData();
    }, []);

    const navigate = (route, search, state) => {
        props.history.push({
            pathname: route,
            search: search ? `?id=${search}` : '',
            state: state ? { data: state } : null
        });
    };

    const onClickEdit = () => {
        setIsEdit(true);
    };

    const onCancelEdit = () => {
        setIsEdit(false);
    };

    const handleChange = async ({ file, fileList }) => {
        setPhoto([...photo, file]);
        const uploadPhotoResult = await uploadImage(
            id,
            file,
            UPLOAD_MODEL.AIRCRAFT,
            UPLOAD_IMAGE_TYPE.GALLERY
        );

        const index = fileList.length;
        getBase64(fileList[index - 1].originFileObj, (imageUrl) => {
            setImageUrl(imageUrl);
            setTempImages([
                ...tempImages,
                {
                    id: 'temp_id_' + temp_id,
                    url: uploadPhotoResult?.data?.url || imageUrl
                }
            ]);
            setTemp_id(temp_id + 1);
        });
    };

    const onDeletePhoto = async (item) => {
        const removedTempImages = tempImages.filter((image) => {
            return image.id !== item.id;
        });
        setTempImages(removedTempImages);
        const removedImages = images.filter((image) => {
            return image.id !== item.id;
        });
        setImages(removedImages);

        // immediately remove image from backend
        await props.updateAircraft(id, {
            remove_images_urls: [item.url]
        });
    };

    const handleDeleteButton = () => {
        setShowDeleteModal(true);
    }

    const onDeleteAircraft = async () => {
        setLoading(true);
        // setIsDelete(true);
        const result = await props.removeAircraft(id);
        if (result.success) {
            // setSuccessMsg(t('AIRCRAFT_SUCCESSFULLY_DELETED').toUpperCase());
            navigate(BROKER_CONNECTIONS)
            // setVisibleModal(true);
        }
        setLoading(false);
    };

    const onSave = async () => {
        let tempAmenities = [];
        amenities_sink && tempAmenities.push(amenitiesList.SINK);
        amenities_bed && tempAmenities.push(amenitiesList.BED);
        amenities_wifi && tempAmenities.push(amenitiesList.WIFI);
        amenities_extra_leg_room &&
            tempAmenities.push(amenitiesList.EXTRA_LEG_ROOM);
        amenities_lavatory && tempAmenities.push(amenitiesList.LAVATORY);

        let tempAircraft = {
            _id: id,
            amenities: tempAmenities,
            // images: images, // merge each image in photo after await into this property and then send it to API
            home_airport: home_airport,
            home_airport_id: home_airport.id,
            price_reflection_airport_id: price_reflection_airport.id,
            price_reflection_airport: price_reflection_airport,
            current_airport: current_airport,
            current_airport_id: current_airport.id,
            description: description,
            properties: {
                average_speed_mph: average_speed_mph,
                climb_rate_fpm: climb_rate_fpm,
                climb_speed_in_knots: climb_speed_in_knots,
                crew: crew,
                cruising_altitude_ft: cruising_altitude_ft,
                cruising_speed_in_knots: cruising_speed_in_knots,
                descent_rate_fpm: descent_rate_fpm,
                descent_speed_in_knots: descent_speed_in_knots,
                empty_weight_lb: empty_weight_lb,
                height_ft: height_ft,
                landing_distance_ft: landing_distance_ft,
                lavatories: lavatories,
                length_ft: length_ft,
                luggage_capacity_ft3: luggage_capacity_ft3,
                max_passengers: max_passengers,
                max_range_in_nautical_miles: max_range_in_nautical_miles,
                max_takeoff_weight_lb: max_takeoff_weight_lb,
                takeoff_distance_ft: takeoff_distance_ft,
                wing_area_ft2: wing_area_ft2,
                wingspan_ft: wingspan_ft
            },
            price_parameters: {
                airport_ground_time_minutes: airport_ground_time_minutes,
                minimum_booking_time_hours: minimum_booking_time_hours,
                positioning_flight_per_hour_fee:
                    positioning_flight_per_hour_fee,
                revenue_flight_per_hour_fee: revenue_flight_per_hour_fee,
                short_term_increment_percentage_6hrs:
                    short_term_increment_percentage_6hrs,
                short_term_increment_percentage_12hrs:
                    short_term_increment_percentage_12hrs,
                short_term_increment_percentage_24hrs:
                    short_term_increment_percentage_24hrs,
                short_term_increment_percentage_48hrs:
                    short_term_increment_percentage_48hrs,
                tax_percentage: tax_percentage,
                fees: {
                    landing_fee: landing_fee,
                    departure_fee: departure_fee,
                    overnight_stay_fee: overnight_stay_fee,
                    parking_fee: parking_fee
                }
            }
        };

        tempAircraft = {
            ...tempAircraft,
            images: tempImages
        };

        setAircraft({
            ...aircraft,
            ...tempAircraft
        });

        setVisibleModal(true);
        setSuccessMsg(t('AIRCRAFT_SUCCESSFULLY_UPDATED').toUpperCase());

        const result = await props.updateAircraft(id, tempAircraft);
        if (result.success) {
            setSuccessMsg(t('AIRCRAFT_SUCCESSFULLY_UPDATED').toUpperCase());
            setVisibleModal(true);

            setIsEdit(false);
        }
    };

    const onSelectHomeAirport = (value) => {
        setHomeAirport(value);
    };

    const onSelectCurrentAirport = (value) => {
        setCurrentAirport(value);
    };

    const onSelectPriceReflectionAirport = (value) => {
        setPriceReflectionAirport(value);
    };

    const onCancelWarning = () => {
        setShowDeleteModal(false);
    }

    const onRemove = () => {
        onDeleteAircraft()
    }

    const onOkModal = () => {
        setVisibleModal(false);
        // if (isDelete) {
        // navigate(BROKER_CONNECTIONS);
        // }
    };

    const onCancelModal = () => {
        setVisibleModal(false);
        // if (isDelete) {
        //     navigate(BROKER_CONNECTIONS);
        // }
    };

    const initProps = {
        onCancelWarning: onCancelWarning,
        onRemove: onRemove,
        showDeleteModal: showDeleteModal,
        handleDeleteButton: handleDeleteButton,
        loading: loading,
        onOkModal: onOkModal,
        onCancelModal: onCancelModal,
        onDeleteAircraft: onDeleteAircraft,
        successMsg: successMsg,
        visibleModal: visibleModal,
        amenities_extra_leg_room: amenities_extra_leg_room,
        amenities_wifi: amenities_wifi,
        amenities_bed: amenities_bed,
        amenities_sink: amenities_sink,
        amenities_lavatory: amenities_lavatory,
        tempImages: tempImages,
        activeCompany: activeCompany,
        imageUrl: imageUrl,
        departure_fee: departure_fee,
        landing_fee: landing_fee,
        overnight_stay_fee: overnight_stay_fee,
        parking_fee: parking_fee,
        tax_percentage: tax_percentage,
        minimum_booking_time_hours: minimum_booking_time_hours,
        airport_ground_time_minutes: airport_ground_time_minutes,
        positioning_flight_per_hour_fee: positioning_flight_per_hour_fee,
        revenue_flight_per_hour_fee: revenue_flight_per_hour_fee,
        short_term_increment_percentage_6hrs:
            short_term_increment_percentage_6hrs,
        short_term_increment_percentage_12hrs:
            short_term_increment_percentage_12hrs,
        short_term_increment_percentage_24hrs:
            short_term_increment_percentage_24hrs,
        short_term_increment_percentage_48hrs:
            short_term_increment_percentage_48hrs,

        cruising_altitude_ft: cruising_altitude_ft,
        cruising_speed_in_knots: cruising_speed_in_knots,
        climb_rate_fpm: climb_rate_fpm,
        descent_rate_fpm: descent_rate_fpm,
        climb_speed_in_knots: climb_speed_in_knots,
        descent_speed_in_knots: descent_speed_in_knots,
        takeoff_distance_ft: takeoff_distance_ft,
        landing_distance_ft: landing_distance_ft,
        average_speed_mph: average_speed_mph,
        max_range_in_nautical_miles: max_range_in_nautical_miles,
        luggage_capacity_ft3: luggage_capacity_ft3,
        max_takeoff_weight_lb: max_takeoff_weight_lb,
        empty_weight_lb: empty_weight_lb,
        wing_area_ft2: wing_area_ft2,
        wingspan_ft: wingspan_ft,
        length_ft: length_ft,
        height_ft: height_ft,
        lavatories: lavatories,
        max_passengers: max_passengers,
        crew: crew,
        price_reflection_airport: price_reflection_airport,
        current_airport: current_airport,
        home_airport: home_airport,
        description: description,
        images: images,
        isEdit: isEdit,
        aircraft: aircraft,

        onClickEdit: onClickEdit,
        navigate: navigate,
        setCrew: setCrew,
        setDescription: setDescription,
        setMaxPassenger: setMaxPassenger,
        setLavatories: setLavatories,
        setMax_range_in_nautical_miles: setMax_range_in_nautical_miles,
        setAverage_speed_mph: setAverage_speed_mph,
        setCruising_altitude_ft: setCruising_altitude_ft,
        setCruising_speed_in_knots: setCruising_speed_in_knots,
        setClimb_rate_fpm: setClimb_rate_fpm,
        setDescent_rate_fpm: setDescent_rate_fpm,
        setClimb_speed_in_knots: setClimb_speed_in_knots,
        setDescent_speed_in_knots: setDescent_speed_in_knots,
        setTakeoff_distance_ft: setTakeoff_distance_ft,
        setLanding_distance_ft: setLanding_distance_ft,
        setHeight_ft: setHeight_ft,
        setLength_ft: setLength_ft,
        setWingspan_ft: setWingspan_ft,
        setWing_area_ft2: setWing_area_ft2,

        setEmpty_weight_lb: setEmpty_weight_lb,
        setMax_takeoff_weight_lb: setMax_takeoff_weight_lb,
        setLuggage_capacity_ft3: setLuggage_capacity_ft3,

        set_tax_percentage: set_tax_percentage,
        set_minimum_booking_time_hours: set_minimum_booking_time_hours,
        set_airport_ground_time_minutes: set_airport_ground_time_minutes,
        set_positioning_flight_per_hour_fee:
            set_positioning_flight_per_hour_fee,
        set_revenue_flight_per_hour_fee: set_revenue_flight_per_hour_fee,
        set_short_term_increment_percentage_6hrs:
            set_short_term_increment_percentage_6hrs,
        set_short_term_increment_percentage_12hrs:
            set_short_term_increment_percentage_12hrs,
        set_short_term_increment_percentage_24hrs:
            set_short_term_increment_percentage_24hrs,
        set_short_term_increment_percentage_48hrs:
            set_short_term_increment_percentage_48hrs,
        set_departure_fee: set_departure_fee,
        set_landing_fee: set_landing_fee,
        set_overnight_stay_fee: set_overnight_stay_fee,
        set_parking_fee: set_parking_fee,
        setIsEdit: setIsEdit,
        handleChange: handleChange,
        onSave: onSave,
        onDeletePhoto: onDeletePhoto,
        set_amenities_extra_leg_room: set_amenities_extra_leg_room,
        set_amenities_wifi: set_amenities_wifi,
        set_amenities_bed: set_amenities_bed,
        set_amenities_sink: set_amenities_sink,
        set_amenities_lavatory: set_amenities_lavatory,

        onSelectHomeAirport: onSelectHomeAirport,
        onSelectCurrentAirport: onSelectCurrentAirport,
        onSelectPriceReflectionAirport: onSelectPriceReflectionAirport,
        onCancelEdit: onCancelEdit,
        setVisibleModal: setVisibleModal
    };

    return tmpl && tmpl({ ...initProps });
};

class AircraftDetailContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <AircraftDetailLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { userReducer, companyReducer } = state;
    const { firstname, lastname } = userReducer;
    const { activeCompany, companies } = companyReducer;
    return {
        firstname: firstname,
        lastname: lastname,
        companies: companies,
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAircraftById: (id) => dispatch(getAircraftById(id)),
        updateAircraft: (id, data) => dispatch(updateAircraft(id, data)),
        uploadImage: (id, photo, model, type) =>
            dispatch(uploadImage(id, photo, model, type)),
        removeAircraft: (id) => dispatch(removeAircraft(id))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AircraftDetailContainer)
);
