import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Input, AutoComplete, Row } from 'antd';

const template = (props) => {
    const { t, i18n } = useTranslation();
    const {
        label = '',
        company = {},
        onSelectCompany,
        onSearchCompanies,
        dropdownStyle,
        inputStyle
    } = props;

    const [options, setOptions] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [inputSearch, setInputSearch] = useState('');

    const searchResult = (results) =>
        results.map((val, i) => {
            const category = `${val.name}`;
            return {
                key: val.id,
                value: category,
                label: (
                    <DropdownWrapper>
                        <DropdownText>{category}</DropdownText>
                    </DropdownWrapper>
                )
            };
        });

    const onSelect = (value, options) => {
        const { key } = options;
        let data = companies.find((v) => v.id === key);
        setInputSearch(`${data.name}`);
        onSelectCompany(data);
    };

    const handleSearch = async (value) => {
        const result = await onSearchCompanies(value);
        setInputSearch(value);
        setCompanies(result);
        setOptions(value ? searchResult(result) : []);
    };

    return (
        <>
            {label && <Label>{label}</Label>}
            <AutoComplete
                dropdownMatchSelectWidth={252}
                options={options}
                onSelect={onSelect}
                onSearch={handleSearch}
                value={inputSearch}
                allowClear
            >
                <Input
                    style={{ width: 770, height: 50 }} // default
                    placeholder={i18n.t('COMPANY')}
                />
            </AutoComplete>
        </>
    );
};

const Label = styled.div`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 12px;
    margin-left: ${(props) => (props.rightInput ? '30px' : '0px')};
`;

const DropdownWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const DropdownText = styled.span``;

export { template };
