import React, { useState } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { Input, Table, Avatar } from 'antd';
import { useTranslation } from 'react-i18next';
import { Header, ButtonCustom, localeEmptyTable } from '../../components';
import { searchIcon, chatIcon } from '../../assets/images/icons';
import { role } from '../../config/Constants';
import { ADD_NEW_EMPLOYEE, EMPLOYEE_DETAIL } from '../../routes/main';
import { GLOBAL_STYLE_CONSTANTS } from 'config/Constants';
import { ISO_8601 } from 'moment';
import { isUserAdmin } from 'redux/selectors/user';
import { useSelector } from 'react-redux';

const template = (props) => {
    const { t, i18n } = useTranslation();
    const havePermission = useSelector(isUserAdmin());
    const SearchIcon = <img src={searchIcon} />;
    const { employeesList, activeCompany } = props;

    const columns = [
        {
            key: 'name',
            fixed: 'left',
            width: 80,
            sorter: (a, b) => {
                if (a.name.toUpperCase() < b.name.toUpperCase()) {
                    return -1;
                }
                if (a.name.toUpperCase() > b.name.toUpperCase()) {
                    return 1;
                }
                return 0;
            },
            title: function titleHeader() {
                return <ColumnHeader>{t('NAME')}</ColumnHeader>;
            },
            dataIndex: 'name',
            render: function eachItemName(name, row) {
                return (
                    <NameSection
                        onClick={(id) => {
                            props.navigate(EMPLOYEE_DETAIL, null, row.id);
                        }}
                    >
                        {row.image ? (
                            <Avatar
                                className="ant-avatar-custom"
                                src={row.image}
                            />
                        ) : (
                            <Avatar className="ant-avatar-custom">
                                {(
                                    row.firstname.charAt(0) +
                                    row.lastname.charAt(0)
                                ).toUpperCase()}
                            </Avatar>
                        )}
                        <ItemText style={{ marginLeft: '10px' }}>
                            {name}
                        </ItemText>
                    </NameSection>
                );
            }
        },
        {
            key: 'role',
            width: 60,
            title: function titleHeader() {
                return <ColumnHeader>{t('ROLE')}</ColumnHeader>;
            },
            dataIndex: 'role',
            filters: [
                { text: 'ADMINISTRATOR', value: role.ADMIN },
                { text: 'PILOT', value: role.PILOT },
                { text: 'CREW', value: role.CREW },
                { text: 'OPERATIONS', value: role.OPERATIONS }
            ],
            onFilter: (value, record) => record.role.includes(value),
            render: function eachItemRole(role) {
                return <RoleText>{t(role).toUpperCase()}</RoleText>;
            }
        },
        {
            key: 'email',
            width: 80,
            sorter: (a, b) => {
                if (a.email.toUpperCase() < b.email.toUpperCase()) {
                    return -1;
                }
                if (a.email.toUpperCase() > b.email.toUpperCase()) {
                    return 1;
                }
                return 0;
            },
            title: function titleHeader() {
                return <ColumnHeader>{t('EMAIL_ADDRESS')}</ColumnHeader>;
            },
            dataIndex: 'email',
            render: function eachItemEmail(email) {
                return <ItemText>{email}</ItemText>;
            }
        },
        {
            key: 'phone',
            width: 60,
            title: function titleHeader() {
                return <ColumnHeader>{t('PHONE_NUMBER')}</ColumnHeader>;
            },
            dataIndex: 'phone',
            render: function eachItemPhone(phone) {
                return <ItemText>{phone}</ItemText>;
            }
        },
        {
            key: 'detail',
            title: '',
            dataIndex: 'id',
            width: 80,
            render: function eachItem(id) {
                return (
                    <DetailSection>
                        <ChatIconSection
                            onClick={() => {
                                //
                            }}
                        >
                            <ChatIcon src={chatIcon} />
                        </ChatIconSection>
                        <ButtonCustom
                            btnType="secondary"
                            size="small"
                            title={t('VIEW_DETAILS').toUpperCase()}
                            width="120px"
                            onClick={(val) => {
                                props.navigate(EMPLOYEE_DETAIL, id, id);
                            }}
                        />
                    </DetailSection>
                );
            }
        }
    ];

    return (
        <EmployeesContainer>
            <Header
                title={t('EMPLOYEES')}
                description={t('VIEW_EMPLOYEES_LIST')}
                headerRight={() => {
                    return (
                        <>
                            {havePermission ? (
                                <ButtonCustom
                                    btnType="secondary"
                                    width="190px"
                                    onClick={() => {
                                        props.navigate(ADD_NEW_EMPLOYEE);
                                    }}
                                    title={t('ADD_NEW_EMPLOYEE').toUpperCase()}
                                />
                            ) : null}
                        </>
                    );
                }}
            />
            <SearchSection>
                <Input
                    placeholder={t('SEARCH')}
                    allowClear
                    suffix={SearchIcon}
                    onChange={(e) => props.onSearch(e)}
                />
            </SearchSection>
            <TableSection>
                <Table
                    scroll={{ x: 1200 }}
                    columns={columns}
                    dataSource={employeesList}
                    locale={localeEmptyTable()}
                ></Table>
            </TableSection>
        </EmployeesContainer>
    );
};

const NameSection = styled.button`
    display: flex;
    flex-direction: row;
    border: none;
    background: none;
    justify-content: flex-start;
`;

const RoleText = styled.span`
    color: #19c0ff;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
`;

const ChatIconSection = styled.button`
    border: none;
    background-color: transparent;
`;

const ChatIcon = styled.img`
    display: block;
    margin: auto;
    border: 1px solid #d0d0d0;
    border-radius: 2px;
    width: 32px;
    height: 32px;
    padding: 5px;
`;

const DetailSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 5px;
`;

const ItemText = styled.span`
    color: #35383f;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    align-self: center;
`;

const ColumnHeader = styled.span`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 11px;
    line-height: 13px;
`;

const TableSection = styled.div`
    margin-top: 20px;
    @media (max-width: 1024px) {
        .ant-table-pagination-right {
            justify-content: center;
        }
    }
`;

const SearchSection = styled.div`
    margin-top: 30px;
    .ant-input {
        background-color: transparent;
    }
    .ant-input-affix-wrapper {
        border: 1px solid #dadadb;
        border-radius: 3px;
        width: 20vw;
        min-width: 250px;
        height: 40px;
        background-color: transparent;
    }
`;

const EmployeesContainer = styled.div`
    padding: ${GLOBAL_STYLE_CONSTANTS.padding};
`;

export { template };
