import React, { useState, useEffect } from 'react';
import { template as tmpl } from '../AutoComplete.tmpl';
import { connect } from 'react-redux';
import { searchCountries } from '../../../redux/actions/appActions';
import { DropdownWrapper, DropdownText } from '../../../styles/shareStyles';

const SearchCountriesLogic = (props) => {
    const {
        label = '',
        country = {},
        clearAfterSearch = false,
        placeholder = 'Search',
        onSelectCountry,
        dropdownStyle,
        inputStyle,
        countries
    } = props;

    const onSearchCountries = async (key = '') => {
        if (!key) return [];

        const input = key.toLowerCase();
        const result = countries.filter((item) => {
            if (
                item.english_name.toLowerCase().includes(input) ||
                item.short_code.toLowerCase().includes(input)
            ) {
                return item;
            }
        });
        return result || [];
    };

    const [options, setOptions] = useState([]);
    const [items, setItems] = useState([]);
    const [inputSearch, setInputSearch] = useState('');

    useEffect(() => {
        setInputSearch(country ? country.english_name : '');
    }, [country]);

    const searchResult = (results = []) =>
        results.map((val, i) => {
            const category = `${val.english_name}`;
            return {
                key: i,
                value: category,
                label: (
                    <DropdownWrapper>
                        {/* dropdownStyle */}
                        <DropdownText>{val.english_name}</DropdownText>
                    </DropdownWrapper>
                )
            };
        });

    const onSelect = (value) => {
        let data = items.find((v) => v.english_name === value);
        // select country
        onSelectCountry(data);

        if (clearAfterSearch) {
            setInputSearch('');
        } else {
            setInputSearch(value);
        }
    };

    const handleSearch = async (value) => {
        const result = await onSearchCountries(value); // []
        onSelectCountry({});
        setInputSearch(value);
        setItems(result);
        setOptions(result ? searchResult(result) : []);
    };

    return (
        tmpl &&
        tmpl({
            label,
            country,
            placeholder,
            inputStyle,
            onSelect,
            handleSearch,
            options,
            inputSearch
        })
    );
};

class SearchCountries extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <SearchCountriesLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { countries = [] } = companyReducer;
    return {
        countries: countries
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        searchCountries: (key) => dispatch(searchCountries(key))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchCountries);
