import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { Modal, Row, Col, Input } from 'antd';
import { ButtonCustom, Text } from 'components';
import {
    directTransferIcon,
    cashIcon,
    favoriteIcon,
    onlinePaymentIcon,
    directTransferActive,
    cashActive,
    favoriteActive,
    onlinePaymentActive
} from 'assets/images/icons';
import _ from 'lodash';
import { MARK_AS_PAID_TYPE } from 'redux/Constants';

const paymentMethods = [
    {
        id: 0,
        type: MARK_AS_PAID_TYPE.DIRECT_TRANSFER,
        title: 'DIRECT_TRANSFER',
        subtitle: 'DIRECT_TRANSFER_DESCRIPTION',
        requireDesc: false,
        icon: directTransferIcon,
        iconActive: directTransferActive
    },
    // {
    //     id: 1,
    //     type: MARK_AS_PAID_TYPE.CASH,
    //     title: 'CASH',
    //     subtitle: 'PAYMENT_CASH_DESCRIPTION',
    //     requireDesc: false,
    //     icon: cashIcon,
    //     iconActive: cashActive
    // },
    // {
    //     id: 2,
    //     type: MARK_AS_PAID_TYPE.MEMBERSHIP_ACCOUNT,
    //     title: 'MEMBERSHIP_ACCOUNT',
    //     subtitle: 'MEMBERSHIP_ACCOUNT_DESCRIPTION',
    //     requireDesc: false,
    //     icon: favoriteIcon,
    //     iconActive: favoriteActive
    // },
    // {
    //     id: 3,
    //     type: MARK_AS_PAID_TYPE.OTHER,
    //     title: 'OTHER',
    //     subtitle: 'PAYMENT_OTHER_DESCRIPTION',
    //     requireDesc: true,
    //     icon: onlinePaymentIcon,
    //     iconActive: onlinePaymentActive
    // }
];

const MarkAsPaidModal = ({ show = false, onOk, onCancel, onSave }) => {
    const { t, i18n } = useTranslation();

    const [method, setMethod] = useState(null); // MARK_AS_PAID_TYPE
    const [desc, setDesc] = useState(''); // require if MARK_AS_PAID_TYPE = OTHER

    const onSaveItem = () => {
        onSave && onSave(method, desc);
    };

    const checkValidItem = () => {
        let valid = false;
        if (method) {
            valid = true;
        }
        if (method === MARK_AS_PAID_TYPE.OTHER && !desc) {
            valid = false;
        }
        return valid;
    };

    const onCloseModal = () => {
        setMethod(null);
        setDesc('');
        // close
        onCancel && onCancel();
    };

    return (
        <Modal
            centered
            visible={show}
            onOk={() => onOk && onOk()}
            onCancel={() => onCloseModal()}
            footer={null}
            width={"630px"}
        >
            <Row style={{ marginTop: 20, marginBottom: 20 }}>
                <div>
                    <InputItemWrapper style={{ justifyContent: 'center' }}>
                        <Title>{i18n.t('MARK_AS_PAID').toUpperCase()}</Title>
                    </InputItemWrapper>
                    <InputItemWrapper
                        style={{ justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}
                    >
                        <SubTitle>{i18n.t('SELECT_PAYMENT_METHOD')}</SubTitle>
                    </InputItemWrapper>

                    {paymentMethods.map((payment = {}, i) => {
                        const {
                            type,
                            title = '',
                            subtitle = '',
                            requireDesc = false,
                            icon = '',
                            iconActive = ''
                        } = payment;
                        return (
                            <SelectPaymentItem
                                isActive={
                                    method && method === type ? true : false
                                }
                                key={i}
                                onClick={() => setMethod(type)}
                            >
                                <Icon src={ method && method === type ? iconActive : icon} />
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        marginLeft: '15px'
                                    }}
                                >
                                    <PaidType>
                                        {t(title).toUpperCase()}
                                    </PaidType>
                                    <PaidTypeDesc>{t(subtitle)}</PaidTypeDesc>

                                    {method && method === type && requireDesc && (
                                        <Input
                                            placeholder={t(
                                                'ENTER_PAYMENT_METHOD'
                                            )}
                                            value={desc}
                                            style={{
                                                width: '270px',
                                                height: '50px',
                                                marginTop: '5px'
                                            }}
                                            onChange={(val) =>
                                                setDesc(val.target.value)
                                            }
                                        />
                                    )}
                                </div>
                            </SelectPaymentItem>
                        );
                    })}

                    <InputItemWrapper
                        style={{ marginTop: 25, justifyContent: 'center' }}
                    >
                        <Col>
                            <ButtonCustom
                                btnType="primary"
                                width="220px"
                                size="large"
                                title={t('MARK_AS_PAID').toUpperCase()}
                                onClick={onSaveItem}
                                disabled={!checkValidItem()}
                            />
                        </Col>
                    </InputItemWrapper>
                </div>
            </Row>
        </Modal>
    );
};

export default MarkAsPaidModal;

const InputItemWrapper = styled(Row)``;

const SelectPaymentItem = styled.a`
    display: flex;
    margin-top: 10px;
    width: 370px;
    border: ${(props) =>
        props.isActive === true ? '1px solid #19c0ff' : 'none'};
    border-radius: 4px;
    padding: 10px;
    background-color: ${props => props.isActive ? 'none' : '#F8F9FE'};
`;

const Title = styled.div`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 32px;
    text-align: center;
`;

const SubTitle = styled.div`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
`;

const Icon = styled.img`
    width: 28px;
    height: 28px;
`;

const PaidType = styled.div`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 13px;
    line-height: 16px;
    text-align: left;
`;

const PaidTypeDesc = styled.div`
    color: #65686f;
    font-family: Lato;
    font-size: 13px;
    line-height: 23px;
    text-align: left;
`;
