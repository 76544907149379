import { message } from 'antd';
import firebase from 'firebase/app';
import 'firebase/messaging';
import { firebaseConfig } from './config';

const config = {
    apiKey: firebaseConfig.apiKey,
    authDomain: firebaseConfig.authDomain,
    databaseURL: firebaseConfig.databaseURL,
    projectId: firebaseConfig.projectId,
    storageBucket: firebaseConfig.storageBucket,
    messagingSenderId: firebaseConfig.messagingSenderId,
    appId: firebaseConfig.appId
};

console.log('messaging isSupported ', firebase.messaging.isSupported());

let messaging;
if (firebase.messaging.isSupported() === true) {
    if (firebase.apps.length === 0) {
        firebase.initializeApp(config);
        messaging = firebase.messaging();
    }
}

export const isSupportedFirebase = () => {
    return firebase.messaging.isSupported();
};

export const requestFirebaseNotificationPermission = () =>
    new Promise((resolve, reject) => {
        if (!messaging || !firebase.apps.length === 0) {
            return resolve(null);
        }

        messaging
            .requestPermission()
            .then(() => firebase.messaging().getToken())
            .then((firebaseToken) => {
                resolve(firebaseToken);
            })
            .catch((err) => {
                reject(err);
            });
    });

export const onMessageListener = () =>
    new Promise((resolve) => {
        if (messaging) {
            messaging.onMessage((payload) => {
                resolve(payload);
            });
        }
    });

export default firebase;
