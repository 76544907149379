import React, { useEffect, useState } from 'react';
import { template as tmpl } from './EmployeeDetailContainer.tmpl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUserById } from 'redux/actions/appActions';
import { ADD_NEW_EMPLOYEE } from 'routes/main';

const EmployeeDetailLogic = (props) => {
    const { location, activeCompany = {} } = props;
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastName] = useState('');
    const [companies, setCompanies] = useState([]);
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [created_at, setCreated_at] = useState(null);
    const [address, setAddress] = useState({});
    const [image, setImage] = useState('');
    const [role, setRole] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [userId, setUserId] = useState('');
    const { state } = location;

    useEffect(() => {
        async function fetchUserData() {
            if (state.data) {
                let query = {
                    company_id: activeCompany?.id
                };
                const result = await props.getUserById(state.data, query);
                if (result.isConnected) {
                    const { data = {} } = result;
                    const {
                        firstname = '',
                        lastname = '',
                        companies = [],
                        phone = '',
                        email = '',
                        created_at = '',
                        address = '',
                        image,
                        role = '',
                        date_of_birth
                    } = data.user;
                    setFirstname(firstname);
                    setLastName(lastname);
                    setCompanies(companies);
                    setPhone(phone);
                    setEmail(email);
                    setCreated_at(created_at);
                    setAddress(address);
                    setImage(image);
                    setRole(role);
                    setDateOfBirth(date_of_birth);
                }
            }
        }
        setUserId(state.data);
        fetchUserData();
    }, [activeCompany]);

    const handleEditButton = () => {
        const employeeInfo = {
            type: 'EDIT',
            firstname,
            lastname,
            companies,
            phone,
            email,
            created_at,
            address,
            image,
            role,
            dateOfBirth,
            userId
        };
        navigate(ADD_NEW_EMPLOYEE, null, employeeInfo);
    };

    const navigate = (route, search, state) => {
        props.history.push({
            pathname: route,
            search: search ? `?id=${search}` : '',
            state: state ? { data: state } : null
        });
    };

    const initProps = {
        firstname: firstname,
        lastname: lastname,
        companies: companies,
        email: email,
        phone: phone,
        created_at: created_at,
        address: address,
        image: image,
        role: role,
        activeCompanyName: activeCompany.name ? activeCompany.name : '',
        companies: companies,
        userId: userId,
        navigate: navigate,
        handleEditButton: handleEditButton
    };

    return tmpl && tmpl({ ...initProps });
};

class EmployeeDetailContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <EmployeeDetailLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { userReducer, companyReducer } = state;
    const { firstname, lastname } = userReducer;
    const { activeCompany, companies } = companyReducer;
    return {
        firstname: firstname,
        lastname: lastname,
        companies: companies,
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserById: (id, query) => dispatch(getUserById(id, query))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(EmployeeDetailContainer)
);
