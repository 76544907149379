import React, { useState } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { Row, Col, Input, Switch, InputNumber } from 'antd';
import '../services.css';
import { WrapperComponent, Label } from '../styles';
import { HeaderSerices } from '../../../components/Cards';
import { numberFormat } from '../../../utils';

const { TextArea } = Input;

const ItemInfo = (props) => {
    const {
        isEdit,
        onClickEdit,
        setItemInfo,
        description,
        max_quantity = 0,
        price = {},
        name
    } = props;

    const { currency, value } = price;

    const { t, i18n } = useTranslation();

    const onChangeValues = (objValue) => {
        let values = {
            name: name,
            description: description,
            max_quantity: +max_quantity,
            price: price
        };
        setItemInfo(Object.assign(values, objValue));
    };

    const _renderEditView = () => {
        return (
            <>
                <Component style={{ paddingLeft: 25 }}>
                    <Row>
                        <Col lg="3" sm="3" xs="12">
                            <Label>{i18n.t('CHANGE_NAME').toUpperCase()}</Label>
                            <Input
                                onChange={(val) =>
                                    onChangeValues({ name: val.target.value })
                                }
                                className="base "
                                placeholder={i18n.t('CHANGE_NAME')}
                                value={name}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Label>{i18n.t('SHOW_NAME').toUpperCase()}</Label>
                        <Switch
                            style={{ marginLeft: 25, marginTop: 12 }}
                            defaultChecked={true}
                        />
                    </Row>
                </Component>

                <Component>
                    <InfoWrapper>
                        <TypeTitle>{i18n.t('DESCRIPTION')}</TypeTitle>
                    </InfoWrapper>
                    <Row style={{ margin: 15 }}>
                        <TextArea
                            onChange={(val) =>
                                onChangeValues({
                                    description: val.target.value
                                })
                            }
                            rows={4}
                            style={{ margin: 10 }}
                            placeholder={i18n.t('DESCRIPTION')}
                            value={description}
                        />
                    </Row>
                    <InfoWrapper>
                        <TypeTitle>{i18n.t('PRICING')}</TypeTitle>
                    </InfoWrapper>
                    <Row className="detail-item-full">
                        <InputItemWrapper>
                            <ItemTitle>
                                {i18n.t('PRICE_PER_ITEM').toUpperCase()}
                            </ItemTitle>
                            <InputNumber
                                min={0}
                                formatter={(value) =>
                                    `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                    )
                                }
                                parser={(value) =>
                                    value.replace(/\$\s?|(,*)/g, '')
                                }
                                onChange={(val) =>
                                    onChangeValues({
                                        price: {
                                            currency: currency,
                                            value: val
                                        }
                                    })
                                }
                                className="input-item"
                                value={value}
                            />
                        </InputItemWrapper>
                        <InputItemWrapper>
                            <ItemTitle>
                                {i18n.t('MAX_QUANTITY').toUpperCase()}
                            </ItemTitle>
                            <InputNumber
                                min={0}
                                formatter={(value) =>
                                    `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                    )
                                }
                                parser={(value) =>
                                    value.replace(/\$\s?|(,*)/g, '')
                                }
                                onChange={(val) =>
                                    onChangeValues({
                                        max_quantity: val
                                    })
                                }
                                className="input-item"
                                value={max_quantity}
                            />
                        </InputItemWrapper>
                    </Row>
                </Component>
            </>
        );
    };

    const _renderDetailView = () => {
        return (
            <Component>
                <InfoWrapper>
                    <TypeTitle>{i18n.t('DESCRIPTION')}</TypeTitle>
                </InfoWrapper>
                <Row style={{ marginLeft: 25, marginTop: 15 }}>
                    <DescriptionText>{description || '_'}</DescriptionText>
                </Row>
                <InfoWrapper>
                    <TypeTitle>{i18n.t('PRICING')}</TypeTitle>
                </InfoWrapper>
                <Row className="detail-item">
                    <ItemWrapper>
                        <ItemTitle>
                            {i18n.t('PRICE_PER_ITEM').toUpperCase()}
                        </ItemTitle>
                        <ItemValue>{numberFormat(price.value)}</ItemValue>
                    </ItemWrapper>
                    <ItemWrapper>
                        <ItemTitle>
                            {i18n.t('MAX_QUANTITY').toUpperCase()}
                        </ItemTitle>
                        <ItemValue>{max_quantity}</ItemValue>
                    </ItemWrapper>
                </Row>
            </Component>
        );
    };

    return (
        <WrapperComponent>
            <HeaderSerices
                title={i18n.t('SERVICE_ITEM_INFO').toUpperCase()}
                isUpdate={isEdit}
                onClick={onClickEdit}
            />
            {!isEdit ? _renderDetailView() : _renderEditView()}
        </WrapperComponent>
    );
};

export default ItemInfo;

const Component = styled.div`
    flex: row;
    background-color: #ffffff;
    border-radius: 4px;
    margin-top: 15px;
    justify-content: center;
    align-items: center;
    min-height: 166px;
`;

const DescriptionText = styled.div`
    color: #65686f;
    font-family: Lato;
    font-size: 14px;
    line-height: 24px;
    width: 1465px;
    text-align: left;
    margin-bottom: 20px;
    margin-right: 20px;
    margin-top: 5px;
`;

const InfoWrapper = styled.div`
    background-color: #f6f9fc;
    border-radius: 4px 4px 0 0;
    height: 50px;
    padding-left: 25px;
    padding-top: 15px;
`;

const TypeTitle = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 17px;
    text-align: left;
`;

const ItemWrapper = styled.span`
    min-width: 182px;
    min-height: 35px;
`;

const InputItemWrapper = styled.span`
    min-width: 182px;
    min-height: 65px;
`;

const ItemTitle = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 18px;
    text-align: left;
    margin-right: 50px;
`;

const ItemValue = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 18px;
    width: 31px;
    text-align: left;
`;
