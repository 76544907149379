import React, { useEffect, useState } from 'react';
import { template as tmpl } from './Summary.tmpl';
import { submitPackages, updateStepForQuote } from 'redux/actions/quoteAction';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from 'redux/selectors/user';
import { UPDATE_QUOTE_STEP, SHOW_LOADING, HIDE_LOADING } from 'redux/Constants';
import { CREATE_QUOTATION_STEP } from 'config/Constants';
import { QUOTE_REQUEST, QUOTE_REQUEST_DETAIL } from 'routes/main';

const Summary = (props) => {
    const { focusedLeg } = props;
    const user = useSelector((state) => getCurrentUser(state));
    const { isLoading } = useSelector((state) => state.appReducer || {});
    const dispatch = useDispatch();

    const [quoteDetail, setQuoteDetail] = useState(props.quoteDetail || {});
    const [aircraftList, setAircraftList] = useState([]);
    const [activeGoNext, setActiveGoNext] = useState(false);
    // const [loading, setLoading] = useState(false);
    const [totalPage, setTotalPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const formatPackage = (pk) => {
        let services = [];
        if (pk.services) {
            services = pk.services.map((item) => {
                delete item.images;
                return item;
            });
        }
        return {
            aircraft_id: pk.aircraft.id,
            leg_id: pk.leg_id,
            price_components: pk.price,
            extra_fees: [],
            service_items: services
        };
    };

    const navigate = (route, search, state) => {
        props.history.push({
            pathname: route,
            search: search,
            state: state ? { data: state } : null
        });
    };

    const onGoBack = () => {
        dispatch(updateStepForQuote(quoteDetail?.id, CREATE_QUOTATION_STEP.INVOICE));
        dispatch({ 
            type: UPDATE_QUOTE_STEP,
            data: {
                id: quoteDetail.id,
                step: CREATE_QUOTATION_STEP.INVOICE
            }
        });
    };

    const onClickSendQuotes = async () => {
        if (isLoading) {
            return;
        }

        // show loading
        dispatch({ type: SHOW_LOADING });

        const result = await dispatch(submitPackages(quoteDetail.id));
        let modal = {};
        if (result && result.success) {
            modal = {
                show: true,
                type: 'success',
                title: 'Quotes has been sent to Customer!'.toUpperCase(),
                description:
                    'Quotes will be cancel after 24hrs if customer have not accept',
                buttonTitle: 'View All Quotes Request'.toUpperCase(),
                onOk: QUOTE_REQUEST,
                onCancel: QUOTE_REQUEST_DETAIL,
                style: { width: '250px' }
            };
        } else {
            modal = {
                show: true,
                type: 'error',
                // errors: null, // default null
                title: 'Error'.toUpperCase(),
                description: 'There is an error occured. Please try again',
                buttonTitle: 'View All Quotes Request',
                style: { width: '250px' }
            };
        }

        setTimeout(() => {
            dispatch({ type: HIDE_LOADING });
            dispatch({ type: 'SETTING_SHOW_MODAL', data: modal });
        }, 300);
    };

    const initProps = {
        user: user,
        onGoBack: onGoBack,
        onClickSendQuotes: onClickSendQuotes,
        loading: isLoading,
        activeGoNext: activeGoNext,
        onGoBack: onGoBack,
        currentPage: currentPage,
        totalPage: totalPage,
        focusedLeg: focusedLeg,
        aircraftList: aircraftList,
        quoteDetail: quoteDetail,
        navigate: navigate
    };
    return tmpl && tmpl({ ...initProps });
};

export default Summary;
