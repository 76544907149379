import React, { useState, useEffect } from 'react';
import { template as tmpl } from './UpcomingFlightList.tmpl';
import { fetchBookings, searchBooking } from 'redux/actions/bookingAction';
import { fetchFlights, searchFlight } from 'redux/actions/flightAction';
import { connect } from 'react-redux';
import { bookingCategory } from 'config/Constants';

const UpcomingFlightListLogic = (props) => {
    const {
        fetchBookings,
        searchBooking,
        activeCompany,
        fetchFlights,
        searchFlight
    } = props;

    const [key, setKey] = useState(''); // key input search with flight number, ...
    const [searchBy, setSearchBy] = useState(bookingCategory.ALL); // default all
    const [sortBy, setSortBy] = useState('week'); // default all

    const [upcomingFlightsList, setUpcomingFlightsList] = useState([]);
    const [completedFlightsList, setCompletedFlightsList] = useState([]);
    const [inFlightsList, setInFlightsList] = useState([]);
    const [awaitingConfirmationList, setAwaitingConfirmationList] = useState(
        []
    );
    const [cancelledList, setCancelledList] = useState([]);
    const [searchList, setSearchList] = useState([]);

    const initBookings = async () => {
        onChangePageUpcomingFlights({ current: 1, pageSize: 5 });
        onChangePageCompletedFlights({ current: 1, pageSize: 5 });
        onChangePageInFlights({ current: 1, pageSize: 5 });
        onChangePageAwaitingConfirmationFlights({ current: 1, pageSize: 5 });
        onChangePageCancelledFlights({ current: 1, pageSize: 5 });
    };

    /**
     * Get Booking list
     */
    useEffect(() => {
        initBookings();
    }, [activeCompany]);

    /**
     * Function handles searching when user is typing
     * @param {*} e event when user is typing
     */
    const onSearch = async (e) => {
        const input = e ? e.toLowerCase() : '';
        setKey(input);
        if (!input) return;

        let rs = await searchFlight(
            input,
            true,
            5,
            1,
            !searchBy || searchBy === bookingCategory.ALL
                ? undefined
                : searchBy.toLowerCase()
        );
        setSearchList(rs || []);
    };

    const navigate = (route, id, state) => {
        props.history.push({
            pathname: route,
            search: id,
            state: state
        });
    };

    const onChangePageUpcomingFlights = async (pagination = {}) => {
        const { current, pageSize } = pagination;
        let upcomingFlights = await fetchFlights(pageSize, current, {
            upcoming: true,
            sort_direction: 'asc'
        });
        setUpcomingFlightsList(upcomingFlights);
    };

    const onChangePageCompletedFlights = async (pagination = {}) => {
        const { current, pageSize } = pagination;
        let completedFlights = await fetchFlights(pageSize, current, {
            executed: true
        });
        setCompletedFlightsList(completedFlights);
    };

    const onChangePageCancelledFlights = async (pagination = {}) => {
        const { current, pageSize } = pagination;
        let cancelledFlights = await fetchFlights(pageSize, current, {
            cancelled: true
        });
        setCancelledList(cancelledFlights);
    };

    const onChangePageAwaitingConfirmationFlights = async (pagination = {}) => {
        const { current, pageSize } = pagination;
        let awaitingConfirmationFlights = await fetchFlights(
            pageSize,
            current,
            {
                awaiting_confirmation: true
            }
        );
        setAwaitingConfirmationList(awaitingConfirmationFlights);
    };

    const onChangePageInFlights = async (pagination = {}) => {
        const { current, pageSize } = pagination;
        let inFlights = await fetchFlights(pageSize, current, {
            in_flight: true
        });
        setInFlightsList(inFlights);
    };

    const onChangePageSearch = async (pagination = {}) => {
        const { current, pageSize } = pagination;
        let rs = await searchFlight(
            key,
            true,
            pageSize,
            current,
            !searchBy || searchBy === bookingCategory.ALL
                ? undefined
                : searchBy.toLowerCase()
        );
        setSearchList(rs || []);
    };

    const onSelectCategory = (value) => {
        setSearchBy(value);
    };

    const initProps = {
        searchList: searchList,
        upcomingFlightsList: upcomingFlightsList,
        inFlightsList: inFlightsList,
        completedFlightsList: completedFlightsList,
        awaitingConfirmationList: awaitingConfirmationList,
        cancelledList: cancelledList,
        onSearch: onSearch,
        navigate: navigate,
        onChangePageCancelledFlights: onChangePageCancelledFlights,
        onChangePageAwaitingConfirmationFlights:
            onChangePageAwaitingConfirmationFlights,
        onChangePageCompletedFlights: onChangePageCompletedFlights,
        onChangePageUpcomingFlights: onChangePageUpcomingFlights,
        onChangePageInFlights: onChangePageInFlights,
        onChangePageSearch: onChangePageSearch,
        searchBy: searchBy,
        key: key,
        sortBy: sortBy,
        onSelectCategory: onSelectCategory
    };

    return tmpl && tmpl({ ...initProps });
};

class UpcomingFlightListContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <UpcomingFlightListLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { customers, activeCompany } = companyReducer;
    return {
        customers: customers,
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchFlights: (customQuery, limit, page) =>
            dispatch(fetchFlights(customQuery, limit, page)),
        searchFlight: (key, paging, limit, page, status) =>
            dispatch(searchFlight(key, paging, limit, page, status))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpcomingFlightListContainer);
