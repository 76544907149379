import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { Header, Tabs, EmptyComponent } from 'components';
import { EmployeesContainer } from './styles';
import TopRatedAirportList from './TopRatedAirports/list';
import TopDestinationList from './TopDestinations/list';
import {
    departureIcon,
    locationInfoIcon,
    planeIcon
} from 'assets/images/icons';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_PROMOTIONS_CURRENT_TAB } from 'redux/Constants';

const template = (props) => {
    const { navigate, onUpdateTopRatedAirport, onUpdateTopDestiantion } = props;
    const dispatch = useDispatch();
    const { promotionsCurrentTab = '' } = useSelector(
        (state) => state.settingReducer
    );

    const { t, i18n } = useTranslation();

    const onChangeTab = (key) => {
        dispatch({ type: UPDATE_PROMOTIONS_CURRENT_TAB, data: key });
    };

    const _renderPromotions = () => {
        const tabRenderer = [
            {
                key: 'TOP_RATED_AIRPORTS',
                label: t('TOP_RATED_AIRPORTS').toUpperCase(),
                render: <TopRatedAirportList navigate={navigate} />,
                icon: planeIcon,
                onUpdate: onUpdateTopRatedAirport
            },
            {
                key: 'TOP_DESTINATIONS',
                label: t('TOP_DESTINATIONS').toUpperCase(),
                render: <TopDestinationList navigate={navigate} />,
                icon: locationInfoIcon
            },
            {
                key: 'SPECIAL_ROUTES',
                label: t('SPECAIL_ROUTES').toUpperCase(),
                render: <EmptyComponent navigate={navigate} />,
                icon: departureIcon,
                onUpdate: onUpdateTopDestiantion
            }
        ];
        return (
            <Tabs
                currentTab={promotionsCurrentTab}
                tabRenderer={tabRenderer}
                onChange={onChangeTab}
            />
        );
    };

    return (
        <EmployeesContainer>
            <Header
                title={t('PROMOTIONS')}
                description={t('PROMOTIONS_DESCRIPTION')}
            />

            {_renderPromotions()}
        </EmployeesContainer>
    );
};

export { template };
