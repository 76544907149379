import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { Col, Row, Input as AntdInput } from 'antd';
import { useTranslation } from 'react-i18next';
import { Header, ButtonCustom, Input } from 'components';
import { HeaderInfo } from 'components/Cards';
import { CustomerDetailContainer, BottomContainer, Label } from '../../styles';
import _ from 'lodash';
import { getBase64 } from 'utils';
import { UploadComponent } from 'components';
import { SearchAirports } from 'components/Search';

const { TextArea } = AntdInput;

const template = (props) => {
    const { onSubmit } = props;

    const { t, i18n } = useTranslation();

    const [loading, setLoading] = useState(false);

    const [photo, setPhoto] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [destinationName, setDestinationName] = useState('');
    const [description, setDescription] = useState('');
    const [airport, setAirport] = useState({});

    const handleChange = ({ file, fileList }) => {
        setPhoto(file);

        const index = fileList.length;
        getBase64(fileList[index - 1].originFileObj, (imageUrl) => {
            setImageUrl(imageUrl);
        });
    };

    const onValidateForm = () => {
        if (!imageUrl || !destinationName || !description || !airport) {
            return false;
        }
        return true;
    };

    const onSelectAirport = (value) => {
        setAirport(value || {});
    };

    const onSubmitForm = () => {
        try {
            const valid = onValidateForm();
            if (!valid) {
                return;
            }

            let params = {
                photo: photo,
                to_airport_id: airport?.id,
                enable: true,
                name: destinationName,
                description: description,
                destination_only: true
            };

            onSubmit(params);
        } catch (e) {}
    };

    return (
        <>
            <CustomerDetailContainer>
                <Header
                    title={i18n.t('PROMOTIONS')}
                    description={i18n.t('ADD_NEW_AIRPORT')}
                />
            </CustomerDetailContainer>
            <ContainerWrapper>
                <HeaderInfo title={i18n.t('DESTINATION_INFO').toUpperCase()} />
                <Wrapper>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <Label>{i18n.t('DISPLAY_PHOTO').toUpperCase()}</Label>
                        <UploadSection>
                            <UploadComponent
                                imageUrl={imageUrl}
                                handleChange={handleChange}
                                loading={loading}
                            />
                        </UploadSection>
                    </Col>
                </Wrapper>

                <Wrapper style={{ marginTop: 20 }}>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <Input
                            label={t('DESTINATION').toUpperCase()}
                            onChange={setDestinationName}
                            width='100%'
                            placeholder={i18n.t('DESTINATION')}
                            value={destinationName}
                        />
                    </Col>
                </Wrapper>
                <Wrapper>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <Label>{i18n.t('AIRPORT').toUpperCase()}</Label>
                        <SearchAirports
                            width='100%'
                            airport={airport}
                            onSelectAirport={onSelectAirport}
                        />
                    </Col>
                </Wrapper>
                <Wrapper>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <Label>{i18n.t('DESCRIPTION').toUpperCase()}</Label>
                        <TextArea
                            onChange={(val) => setDescription(val.target.value)}
                            className="address "
                            rows={4}
                            placeholder={i18n.t('DESCRIPTION')}
                            value={description}
                        />
                    </Col>
                </Wrapper>
            </ContainerWrapper>
            <BottomContainer>
                <ButtonCustom
                    title={t('SUBMIT_NEW_AIRPORT').toUpperCase()}
                    onClick={() => onSubmitForm()}
                    btnType="primary"
                    size="large"
                    width="250px"
                    disabled={!onValidateForm()}
                />
            </BottomContainer>
        </>
    );
};

const UploadSection = styled.div`
    margin-top: 10px;
`;

const ContainerWrapper = styled.div`
    background-color: #fff;
    border-radius: 4px;
    margin-left: min(40px, 4%);
    margin-right: min(40px, 4%);
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 40px;
`;

const Wrapper = styled(Row)`
    margin-left: 50px;
    margin-right: 50px;
`;

export { template };
