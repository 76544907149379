import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { Row, Col } from 'antd';
import { Text, ButtonCustom } from 'components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getTitleWithFlightType } from 'utils';
import InvoiceCard from './InvoiceCard';
import { plusIcon } from 'assets/images/icons';
import PackageCard from './PackageCard';
import ModalCreatePackage from './ModalCreatePackage';
import { GoBackAndNext } from 'views/QuoteRequest/components';
import _ from 'lodash';
import { FLIGHT_TYPES } from 'config/Constants';

// selected-package : 2-dimensional arrays
const checkDuplicatePackages = (newPackage = [], packages = []) => {
    if (_.isEmpty(packages)) return false;

    let duplicate = false;
    try {
        let arr = [];
        for (let index = 0; index < packages.length; index++) {
            let packagee = packages[index];
            for (let i = 0; i < packagee.length; i++) {
                let key =
                    packagee[i].aircraft?.id === newPackage[i].aircraft?.id
                        ? 1
                        : 0;
                arr.push(key);
            }
            if (!arr.some((v) => v === 0)) {
                duplicate = true;
            } else {
                arr = [];
            }
        }
    } catch (e) {}
    return duplicate;
};

const template = (props) => {
    const { t, i18n } = useTranslation();
    const {
        quoteDetail = {},
        onGoNext,
        onGoBack,
        onCreatePackage,
        onRemovePackage,
        onChangePriceSelectedItem,
        onChangePricePackages
    } = props;
    const { id, legs = [], packages = [], type } = quoteDetail;

    const [legItems, setLegItems] = useState([]);
    const [adding, setAdding] = useState(false);

    useEffect(() => {
        if (legs && legs.length > 0) {
            setLegItems([...legs]);
        }
    }, [quoteDetail]);

    const checkAdded = (aircraftId) => {
        let added = false;
        try {
            if (type === FLIGHT_TYPES.ONEWAY) {
                added = packages.some(
                    (item) => item[0].aircraft.id === aircraftId
                );
            }
        } catch (e) {}

        return added;
    };

    const onCreateNewPackageItem = (newPackage) => {
        let duplicate = checkDuplicatePackages(newPackage, packages);
        if (duplicate) {
            setAdding(false);
            return;
        }

        onCreatePackage(newPackage);
        setAdding(false);
    };

    const onCancelModal = () => {
        setAdding(false);
    };

    const onUpdatePackage = (leg, legIndex, pkIndex) => {
        onChangePricePackages(leg, legIndex, pkIndex);
    };

    const onRemovePackageItem = (index) => {
        onRemovePackage(index);
    };

    const onPressAddPackage = (aircraftItem = {}, services = [], leg_id) => {
        if (type !== FLIGHT_TYPES.ONEWAY) {
            setAdding(true);
            return;
        }

        // if ONEWAY add to package directly
        const { aircraft, price_components = {} } = aircraftItem;
        let newPackage = [
            {
                aircraft: aircraft,
                price_components: price_components,
                services: services,
                leg_id: leg_id
            }
        ];

        onCreatePackage(newPackage);
    };

    const onPressRemovePackage = (item = {}) => {
        try {
            let index = packages.findIndex(
                (v) => v[0].aircraft.id === item.aircraft.id
            );
            onRemovePackage(index);
        } catch (e) {}
    };

    const onChangePrice = (leg_index, selectec_index, price) => {
        try {
            let items = legItems;
            let selectedItem =
                items[leg_index].select_aircrafts[selectec_index];

            let rs = onChangePriceSelectedItem(
                items[leg_index]?.id,
                selectedItem?.aircraft?.id,
                price,
                leg_index,
                selectec_index
            );

            if (type === FLIGHT_TYPES.ONEWAY && packages.length > 0) {
                let pkIndex = packages.findIndex(
                    (v) => v[0].aircraft?.id === selectedItem?.aircraft?.id
                );
                let newPks = packages[pkIndex];
                newPks[0].price_components = price;
                onChangePricePackages(newPks, leg_index, pkIndex);
            }
            return rs;
        } catch (e) {
            return null;
        }
    };

    return (
        <Container>
            <div
                style={{
                    display: 'flex',
                    marginTop: 20,
                    justifyContent: 'space-between'
                }}
            >
                <Text textStyle="heading3">
                    {type !== FLIGHT_TYPES.ONEWAY
                        ? t('CREATE_PACKAGE_QUOTATIONS').toUpperCase()
                        : `${t('QUOTES_CREATED').toUpperCase()} (${
                              packages.length
                          })`}
                </Text>

                {type !== FLIGHT_TYPES.ONEWAY && (
                    <ButtonCustom
                        icon={plusIcon}
                        size="medium"
                        btnType="secondary"
                        width="274px"
                        title={t('CREATE_QUOTATION_PACKAGE').toUpperCase()}
                        onClick={() => setAdding(true)}
                    />
                )}
            </div>

            {type !== FLIGHT_TYPES.ONEWAY && (
                <Row>
                    {packages &&
                        packages.map((item, i) => {
                            return (
                                <Col
                                    span={12}
                                    style={{ marginTop: 20 }}
                                    key={i}
                                >
                                    <PackageCard
                                        quoteId={quoteDetail?.id}
                                        legs={legs}
                                        packagee={item}
                                        index={i}
                                        type={type}
                                        onUpdatePackage={(leg, index) =>
                                            onUpdatePackage(leg, index, i)
                                        }
                                        onRemove={onRemovePackageItem}
                                    />
                                </Col>
                            );
                        })}
                </Row>
            )}

            {legItems &&
                legItems.map((leg = {}, i) => {
                    const {
                        id,
                        select_aircrafts = [],
                        services = [],
                        from_airport_basic_info = {},
                        to_airport_basic_info = {}
                    } = leg;
                    return (
                        <div
                            style={{ marginTop: packages.length > 0 ? 40 : 20 }}
                            key={i}
                        >
                            <Text textStyle="heading3">
                                {getTitleWithFlightType(type, i).toUpperCase()}
                            </Text>
                            {select_aircrafts &&
                                select_aircrafts.map(
                                    (selectAircraft = {}, index) => {
                                        return (
                                            <InvoiceWrapper key={index}>
                                                <Row
                                                    style={{
                                                        paddingLeft: 25,
                                                        paddingTop: 15
                                                    }}
                                                >
                                                    <InvoiceCard
                                                        legId={id}
                                                        selectAircraft={
                                                            selectAircraft
                                                        }
                                                        type={type}
                                                        fromAirport={
                                                            from_airport_basic_info
                                                        }
                                                        toAirport={
                                                            to_airport_basic_info
                                                        }
                                                        leg_index={i}
                                                        invoice_index={index}
                                                        services={services}
                                                        onSavePrice={
                                                            onChangePrice
                                                        }
                                                        added={checkAdded(
                                                            selectAircraft
                                                                ?.aircraft?.id
                                                        )} // flag UI.
                                                        onAddPackage={
                                                            onPressAddPackage
                                                        }
                                                        onRemovePackage={
                                                            onPressRemovePackage
                                                        }
                                                    />
                                                </Row>
                                            </InvoiceWrapper>
                                        );
                                    }
                                )}
                        </div>
                    );
                })}

            <div style={{ marginTop: 40, paddingBottom: 20 }}>
                <GoBackAndNext
                    onGoNext={onGoNext}
                    onGoBack={onGoBack}
                    titleGoNext={t('VIEW_SUMMARY').toUpperCase()}
                    activeGoNext={
                        packages && packages.length > 0 ? true : false
                    }
                    activeGoBack={true}
                />
            </div>

            <ModalCreatePackage
                legs={legItems}
                show={adding}
                onCancel={onCancelModal}
                onSubmit={onCreateNewPackageItem}
                type={type}
            />
        </Container>
    );
};

export { template };

const InvoiceWrapper = styled.div`
    background-color: #ffffff;
    border-radius: 4px;
    margin-top: 30px;
`;

const Container = styled.div`
    padding-top: 20px;
`;
