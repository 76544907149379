import API from '../../modules/httpModule';
import {
    UPDATE_USER_COMPANY_LIST,
    UPDATE_COMPANY,
    UPDATE_ACTIVE_COMPANY,
    COMPANY_TYPE,
    HANDLER_TYPE
} from '../Constants';

/**
 * get list of all companies
 * @returns
 */
export function getTopRatedAirports() {
    return async (dispatch) => {
        try {
            const response = await API.get('/top_rated_airports');
            if (response && response.status === 200 && response.data) {
                return response.data;
            }
        } catch (err) {
            return {
                isConnected: false,
                error: err
            };
        }
    };
}

export function addTopRatedAirport(data) {
    return async (dispatch) => {
        try {
            const response = await API.post('/top_rated_airports', data);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    data: response.data,
                };
            }
        } catch (err) {
            return {
                success: false,
                data: {},
                error: err
            };
        }
    };
}

export function getTopRatedAirport(id) {
    return async (dispatch) => {
        try {
            const response = await API.get(`/top_rated_airports/${id}`);
            if (response && response.status === 200 && response.data) {
                return response.data;
            }
        } catch (err) {
            return {
                isConnected: false,
                error: err
            };
        }
    };
}

export function updateTopRatedAirport(id, data) {
    return async (dispatch) => {
        try {
            const response = await API.put(`/top_rated_airports/${id}`, data);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                data: {},
                error: err
            };
        }
    };
}

export function removeTopRatedAirport(id) {
    return async (dispatch) => {
        try {
            const response = await API.delete(`/top_rated_airports/${id}`);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true
                };
            }
        } catch (err) {
            return {
                success: false,
                error: err
            };
        }
    };
}

// destination
export function getTopDestinations() {
    return async (dispatch) => {
        try {
            const response = await API.get('/routes');
            if (response && response.status === 200 && response.data) {
                // update brief company info into redux
                return response.data;
            }
        } catch (err) {
            return {
                isConnected: false,
                error: err
            };
        }
    };
}

export function addTopDestination(data) {
    return async (dispatch) => {
        try {
            const response = await API.post(`/routes`, data);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    data: response.data,
                };
            }
        } catch (err) {
            return {
                success: false,
                error: err
            };
        }
    };
}

export function getTopDestination(id) {
    return async (dispatch) => {
        try {
            const response = await API.get(`/routes/${id}`);
            if (response && response.status === 200 && response.data) {
                return response.data;
            }
        } catch (err) {
            return {
                isConnected: false,
                error: err
            };
        }
    };
}

export function updateTopDestination(id, data) {
    return async (dispatch) => {
        try {
            const response = await API.put(`/routes/${id}`, data);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                data: {},
                error: err
            };
        }
    };
}

export function removeTopDestination(id) {
    return async (dispatch) => {
        try {
            const response = await API.delete(`/routes/${id}`);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true
                };
            }
        } catch (err) {
            return {
                success: false,
                error: err
            };
        }
    };
}
