import React from 'react';
import styled from 'styled-components';
import {
    EditOutlined,
    InfoCircleFilled,
    CloseOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

/**
 *
 * @param {string} title - the title of the page
 * @param {string} actionText - the title action button (default = EDIT)
 * @param {func} onClick - handle click action button
 * @returns {JSX}
 */
const HeaderSerices = (props) => {
    const { t, i18n } = useTranslation();
    const { title = '', actionText, onClick, isUpdate } = props;

    return (
        <Header>
            <HeaderLeft>
                <EditButton>
                    <Title>{title}</Title>
                </EditButton>
            </HeaderLeft>
            <HeaderRight>
                {actionText &&
                    (!isUpdate ? (
                        <EditButton onClick={() => onClick()}>
                            <EditText>{actionText}</EditText>
                            <EditOutlined />
                        </EditButton>
                    ) : (
                        <EditButton onClick={() => onClick()}>
                            <EditText>
                                {i18n.t('CANCEL_EDIT').toUpperCase()}
                            </EditText>
                            <CloseOutlined />
                        </EditButton>
                    ))}
            </HeaderRight>
        </Header>
    );
};

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 15px;
`;

const HeaderLeft = styled.div`
    margin-top: auto;
    margin-bottom: auto;
`;
const HeaderRight = styled.div`
    margin-top: auto;
    margin-bottom: auto;
`;

const Title = styled.span`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.7px;
    line-height: 16px;
    text-align: left;
    margin-top: 15px;
`;

const EditButton = styled.button`
    background-color: transparent;
    border: none;
`;

const EditText = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    width: 26px;
    text-align: left;
    margin-right: 8px;
`;

export default HeaderSerices;
