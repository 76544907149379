import React from 'react';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { Text } from '../../index';

/**
 *
 * @param {*} props
 * @returns
 */
const CheckboxComponent = (props) => {
    const { width, height, label, onChange, textStyle, checked } = props;
    return (
        <CheckboxSection width={width} height={height}>
            <Checkbox
                checked={checked}
                onChange={(e) => {
                    if (onChange && typeof onChange === 'function') {
                        onChange(e.target.checked);
                    }
                }}
            >
                <Text textStyle={textStyle}>{label}</Text>
            </Checkbox>
        </CheckboxSection>
    );
};

export default CheckboxComponent;

const CheckboxSection = styled.div`
    .ant-checkbox-inner {
        width: 20px;
        height: 20px;
        background-color: #ffffff;
        border-color: rgba(18, 18, 22, 0.1) !important;
    }
    .ant-checkbox-wrapper {
        align-items: center;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
        background-color: #ffffff;
        border-color: #19c0ff;
        left: 25%;
    }
`;
