import React, { useState, useEffect } from 'react';
import { Modal, Spin } from 'antd';
import Lottie from 'react-lottie';
import animationLoading from 'assets/lotties/loading.json';
import { useSelector, useDispatch } from 'react-redux';

const LoadingComponent = () => {
    const dispatch = useDispatch();
    const appReducer = useSelector(state => state.appReducer || {});
    const { isLoading = false } = appReducer;

    const [show, setShow] = useState(false);

    useEffect(() => {
        if (show !== isLoading) {
            setShow(isLoading);
        }
    }, [isLoading]);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationLoading,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    };

    const indicatorLoading = () => {
        return (
            <Lottie 
                options={defaultOptions}
                height={40}
                width={200}
            />
        );
    };

    return (
        <Modal
            className="modal-loading-page"
            centered
            visible={show}
            footer={null}
            closable={false}
            maskStyle={{ backgroundColor: '#fff', opacity: '0.8' }}
        >
            <Spin
                indicator={indicatorLoading()}
            />
        </Modal>
    );
};

export default LoadingComponent;