import React, { useState } from 'react';
import { Popover, Button, Row, Col } from 'antd';
import styled from 'styled-components';
import { Text } from 'components';
import { useTranslation } from 'react-i18next';
import FlightInfoCard from '../FlightInfoCard';
import { checkMarkIcon, infoIcon, moreIcon } from 'assets/images/icons';
import moment from 'moment';
import { Icon } from 'styles/shareStyles';
import { Title, TitleHeader, TextItem, WrapperListNoti } from '../../styles';

const getIconWithStatus = (status) => {
    let icon = infoIcon;
    if (status === 'EXECUTED') {
        icon = checkMarkIcon;
    }
    return icon;
}

const Flights = (props) => {
    const {
        title = '',
        ref_basic_info = {},
        content = '',
        createAt = '',
        _onRef = '',
        onClickReadNoti,
        seen = false
    } = props;
    const { status = '' } = ref_basic_info;
    const { t, i18n } = useTranslation();

    const moreActions = (
        <div
            style={{
                justifyContent: 'center',
                width: '200px'
            }}
        >
            <div>
                <a onClick={() => onClickReadNoti && onClickReadNoti()}>
                    <Text textStyle="tableText2" style={{ fontSize: '12px' }}>
                        {t('MARK_AS_READ')}
                    </Text>
                </a>
            </div>
            <div style={{ marginTop: '5px' }}>
                <a onClick={() => onClickReadNoti && onClickReadNoti()}>
                    <Text textStyle="tableText2" style={{ fontSize: '12px' }}>
                        {t('REMOVE_THIS_NOTI')}
                    </Text>
                </a>
            </div>
        </div>
    );

    const _renderHeader = () => {
        if (!title) {
            return null;
        }
        
        return (
            <Header>
                <HeaderLeft>
                    <TitleSection>
                        <InfoCircleBackground>
                            <Icon
                                style={{ width: 32, height: 32 }}
                                src={getIconWithStatus(status)}
                            />
                        </InfoCircleBackground>
                        <TitleHeader seen={!seen}>{t(title).toUpperCase()}</TitleHeader>
                    </TitleSection>
                </HeaderLeft>
                <HeaderRight>
                    <Popover
                        placement="rightBottom"
                        content={moreActions}
                        trigger="click"
                        style={{ borderRadius: 4, zIndex: 10 }}
                    >
                        <Icon
                            src={moreIcon}
                            style={{ 
                                width: '32px',
                                height: '32px',
                                backgroundColor: '#fff',
                                borderRadius: '100%',
                                padding: 3,
                                zIndex: 999
                            }}
                        />
                    </Popover>
                </HeaderRight>
            </Header>
        );
    };

    return (
        <Container>
            <WrapperListNoti>
                {_renderHeader()}

                <div style={{ marginLeft: 50 }}>
                    <div>
                        <Title seen={!seen}>{content}</Title>
                    </div>

                    <FlightInfoCard ref_basic_info={ref_basic_info} />

                    <TextItem seen={!seen} style={{ marginTop: '10px' }}>
                        {createAt
                            ? moment(createAt).fromNow()
                            : ''}
                    </TextItem>
                </div>
            </WrapperListNoti>
        </Container>
    );
};

export default Flights;

const Container = styled.div`
    margin-top: 20px;
`;

const TitleSection = styled.div`
    display: flex;
    flex-direction: row;
`;

const InfoCircleBackground = styled.div`
    background-color: ${(props) => props.background || '#f0f3f9'};
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const HeaderLeft = styled.div`
    margin-top: auto;
    margin-bottom: auto;
`;
const HeaderRight = styled.div`
    margin-top: auto;
    margin-bottom: auto;
`;