import React, { useState, useEffect } from 'react';
import { template as tmpl } from './NotificationDrawer.tmpl';
import { useSelector } from 'react-redux';
import {
    onReadNotification,
    onReadAllNotification,
    fetchMoreNotifications
} from 'redux/actions/appActions';
import { useDispatch } from 'react-redux';
import {
    BOOKING_DETAIL,
    FLIGHT_DETAIL,
    COMPANY_INVOICE_DETAIL,
    QUOTE_REQUEST_DETAIL
} from 'routes/main';
import { NOTIFICATION_TYPES } from 'config/Constants';
import Firebase, { isSupportedFirebase } from '../../../firebase';
import { notification } from 'antd';
import { RECEIVED_NOTIFICATION } from 'redux/Constants';
import { useTranslation } from 'react-i18next';

const NotificationDrawer = (props) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const { show = false, onClose, navigate } = props;
    const { companyReducer = {} } = useSelector((state) => state);
    const { notifications = {} } = companyReducer;

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        let isSupport = isSupportedFirebase();
        if (!isSupport) {
            return;
        }

        const subscribe = Firebase.messaging().onMessage((payload = {}) => {
            const { data = {} } = payload;

            const {
                _onRef = '',
                _on = '',
                _id = '',
                seen = false,
                content = '',
                text = '',
                created_at = '',
                ref_basic_info = null,
                user_basic_info = null,
                meta = null
            } = data;

            // fetch new noti to redux
            if (_onRef) {
                let noti = {
                    content: content,
                    created_at: created_at || Date.now(),
                    ref_basic_info:
                        (ref_basic_info && JSON.parse(ref_basic_info)) || {},
                    user_basic_info:
                        (user_basic_info && JSON.parse(user_basic_info)) || {},
                    meta: (meta && JSON.parse(meta)) || {},
                    seen: seen,
                    _id: _id,
                    _on: _on,
                    _onRef: _onRef
                };
                dispatch({ type: RECEIVED_NOTIFICATION, data: noti });
            }

            notification.info({
                message: content || text,
                placement: 'bottomLeft',
                style: { width: '300px' }
            });
        });

        return () => {
            subscribe();
        };
    }, []);

    const onClickAllRead = () => {
        dispatch(onReadAllNotification());
    };

    const onLoadMore = async (next, limit, page, pages) => {
        if (next) {
            dispatch(fetchMoreNotifications(next, limit));
        }
    };

    const onClickReadNoti = async (notiId, seen, _onRef, _on) => {
        if (!seen) {
            dispatch(onReadNotification(notiId));

            // update read notification
        }

        switch (_onRef) {
            case NOTIFICATION_TYPES.FLIGHT:
                navigate(FLIGHT_DETAIL, _on);
                break;
            case NOTIFICATION_TYPES.BOOKING:
                navigate(BOOKING_DETAIL, _on);
            case NOTIFICATION_TYPES.COMPANY:
                // navigate('', _on);
                break;
            case NOTIFICATION_TYPES.INVOICE:
                navigate(COMPANY_INVOICE_DETAIL, _on);
                break;
            case NOTIFICATION_TYPES.QUOTE_REQUEST:
                navigate(QUOTE_REQUEST_DETAIL, _on);
                break;
            case NOTIFICATION_TYPES.REQUEST:
                // navigate('', _on);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        setVisible(show);
    }, [show]);

    const initProps = {
        navigate: navigate,
        visible: visible,
        onClose: onClose,
        onClickAllRead: onClickAllRead,
        onClickReadNoti: onClickReadNoti,
        notifications: notifications,
        onLoadMore: onLoadMore
    };

    return tmpl && tmpl({ ...initProps });
};

export default NotificationDrawer;
