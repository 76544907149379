import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { CircleBlue, CircleGreen } from 'assets/images/icons';
import { Text } from 'components';
import 'antd/dist/antd.css';
import { Popover } from 'antd';
import styled from 'styled-components';

const ContentPopup = ({ tail_number, partner = false }) => {
    return (
        <ContentContainer className="test" partner={partner}>
            <Text
                style={{
                    color: '#ffffff',
                    fontFamily: 'AvenirNext-Bold',
                    fontSize: '10px',
                    letterSpacing: '0.7px'
                }}
            >
                {tail_number}
            </Text>
        </ContentContainer>
    );
};

const ArrowContent = () => {
    return <div className="testArrow"></div>;
};

const CurrentAircraftPosition = ({
    text,
    partner = false,
    tail_number = ''
}) => {
    return (
        <AircraftLabel
            style={{
                position: 'absolute',
                height: '14px',
                width: '14px',
                top: -10,
                left: -5
            }}
        >
            <Popover
                overlayClassName="dashboardMapPopover"
                overlayInnerStyle={{
                    padding: 0,
                    borderRadius: 6
                    // backgroundColor: '#19c0ff'
                }}
                trigger="click"
                content={
                    <ContentPopup tail_number={tail_number} partner={partner} />
                }
            >
                {partner ? <CircleGreen /> : <CircleBlue />}
            </Popover>
        </AircraftLabel>
    );
};

export default function DashboardMap(props) {
    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 4,
        position1: {
            lat: 37.773972,
            lng: -122.431297
        },
        position2: {
            lat: 40.7128,
            lng: -74.006
        }
    };
    const {
        listAircraftsPosition = [],
        width = '100%',
        height = '440px'
    } = props;
    const [defaultUserLocation, setDefaultUserLocation] = useState(null);
    const [listAirportCard, setListOfAirportCard] = useState([]);

    const [markers, setMarkers] = useState([]);
    const [map, setMap] = useState(null);

    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    setDefaultUserLocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    });
                },
                function (error) {
                    setDefaultUserLocation({ lat: 39.8283, lng: -98.5795 });
                }
            );
        } else {
            setDefaultUserLocation({ lat: 39.8283, lng: -98.5795 });
        }
    }, []);

    const drawRoutes = (map) => {
        listOfAirports.map((eachAirport) => {
            const { from_airport = {}, to_airport = {} } = eachAirport;

            // draw curve markers for each route
            const from_airport_coordinates = from_airport.location?.geometry
                ?.coordinates || [
                defaultProps.position1.lat,
                defaultProps.position1.lng
            ];
            const to_airport_coordinates = to_airport.location?.geometry
                ?.coordinates || [
                defaultProps.position2.lat,
                defaultProps.position2.lng
            ];

            const pos1 = {
                lat: from_airport_coordinates[1],
                lng: from_airport_coordinates[0]
            };
            const pos2 = {
                lat: to_airport_coordinates[1],
                lng: to_airport_coordinates[0]
            };
            CurveMarker(
                new google.maps.LatLng(pos1),
                new google.maps.LatLng(pos2),
                map.getProjection(),
                map.getZoom(),
                map
            );
        });
    };

    const setMapOnAll = (map) => {
        for (let i = 0; i < markers.length; i++) {
            markers[i].setMap(map);
        }
        setMarkers([]);
    };

    const addMarker = (position, symbol, map) => {
        const marker = new google.maps.Marker({
            position: position,
            icon: symbol,
            map: map
        });
        let newMarkersArray = markers;
        newMarkersArray.push(marker);
        setMarkers(newMarkersArray);
    };

    const CurveMarker = (pos1, pos2, mapProjection, zoom, map) => {
        if (!mapProjection) return;
        var curvature = 0.4;

        const p1 = mapProjection.fromLatLngToPoint(pos1),
            p2 = mapProjection.fromLatLngToPoint(pos2);
        // Calculating the arc.
        const e = new google.maps.Point(p2.x - p1.x, p2.y - p1.y), // endpoint
            m = new google.maps.Point(e.x / 2, e.y / 2), // midpoint
            o = new google.maps.Point(e.y, -e.x), // orthogonal
            c = new google.maps.Point(
                m.x + curvature * o.x,
                m.y + curvature * o.y
            ); //curve control point

        const pathDef =
            'M 0,0 ' + 'q ' + c.x + ',' + c.y + ' ' + e.x + ',' + e.y;

        const scale = 1 / Math.pow(2, -zoom);

        const symbol = {
            path: pathDef,
            scale: scale,
            strokeWeight: 2,
            fillColor: 'none',
            strokeColor: '#19c0ff'
        };

        addMarker(pos1, symbol, map);
    };

    const apiIsLoaded = (map, maps, ref) => {
        if (map) {
            setMap(map);
            // if (listOfAirports.length > 0) {
            //     setMapOnAll(null);
            //     drawRoutes(map);
            // }
        }
    };

    const renderAircraftsPosition = () => {};

    return (
        // Important! Always set the container height explicitly
        <MapContainer style={{ height: height, width: width }}>
            {listAircraftsPosition.length > 0 && defaultUserLocation && (
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: 'AIzaSyA40WAkPpWYZ9iUFidtIcazgOJ-ukxc1Xg'
                    }}
                    defaultCenter={defaultUserLocation}
                    defaultZoom={defaultProps.zoom}
                    options={{
                        styles: mapStyle
                    }}
                    // yesIWantToUseGoogleMapApiInternals
                    // onGoogleApiLoaded={({ map, maps, ref }) =>
                    //     apiIsLoaded(map, maps, ref)
                    // }
                    // onZoomAnimationStart={() => setMapOnAll(null)}
                    // onZoomAnimationEnd={() => {
                    //     drawRoutes(map);
                    // }}
                >
                    {listAircraftsPosition.length > 0 &&
                        listAircraftsPosition.map((eachAircraft, index) => {
                            // const {icao_code, location} = eachAircraft
                            const {
                                current_airport = {},
                                partner = false,
                                tail_number = ''
                            } = eachAircraft;

                            if (
                                !current_airport.location?.geometry?.coordinates
                            )
                                return null;
                            return (
                                <CurrentAircraftPosition
                                    key={index}
                                    lat={
                                        current_airport.location?.geometry
                                            ?.coordinates[1]
                                    }
                                    lng={
                                        current_airport.location?.geometry
                                            ?.coordinates[0]
                                    }
                                    text={current_airport.icao_code || ''}
                                    partner={partner}
                                    tail_number={tail_number}
                                />
                            );
                        })}
                </GoogleMapReact>
            )}
        </MapContainer>
    );
}

const ContentContainer = styled.div`
    padding: 0px 5px 2px 5px;
    background-color: ${(props) => (props.partner ? '#7ED321' : '#19c0ff')};
    border-radius: 4px;
`;

const MapContainer = styled.div``;

const AircraftLabel = styled.div`
    .dashboardMapPopover .ant-popover-arrow .ant-popover-arrow-content {
        background-color: transparent !important;
    }
`;

const mapStyle = [
    {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#65686f'
            }
        ]
    },
    {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
            {
                color: '#f2f2f2'
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'all',
        stylers: [
            {
                saturation: -100
            },
            {
                lightness: 45
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [
            {
                visibility: 'simplified'
            }
        ]
    },
    {
        featureType: 'road.arterial',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'all',
        stylers: [
            {
                color: '#ffffff'
            },
            {
                visibility: 'on'
            }
        ]
    }
];
