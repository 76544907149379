import React, { useState, useEffect } from 'react';
import { template as tmpl } from './CompanyProfile.tmpl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { uploadImage } from 'redux/actions/appActions';
import { updateCompany } from 'redux/actions/companyAction';
import {
    UPLOAD_MODEL,
    UPLOAD_IMAGE_TYPE,
    UPDATE_ACTIVE_COMPANY_IMAGES,
    UPDATE_ACTIVE_COMPANY
} from 'redux/Constants';
import { useDispatch } from 'react-redux';

const CompanyProfileLogic = (props) => {
    const dispatch = useDispatch();
    const {
        user = {},
        employees = [],
        activeCompany = {},
        uploadImage,
        updateCompany
    } = props;
    const { id, description = '' } = activeCompany;

    const [company, setCompany] = useState({});

    // update fields
    const [descriptionCom, setDescription] = useState('');

    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);

    useEffect(() => {
        setCompany(activeCompany);
        setDescription(description);
    }, [activeCompany]);

    const navigate = (route) => {
        props.history.push(route);
    };

    const onSubmitEdit = async () => {
        let data = {
            description: descriptionCom
        };
        let rs = await updateCompany(id, data);
        if (rs && rs.success) {
            let newCompany = {
                ...company,
                ...data
            };

            // update store companyReducer
            dispatch({ type: UPDATE_ACTIVE_COMPANY, activeCompany: newCompany });

            setEdit(false);
            setVisibleModal(true);
        }
    };

    const updateCoverImage = async ({ file, fileList }) => {
        let rs = await uploadImage(
            id,
            file,
            UPLOAD_MODEL.COMPANY,
            UPLOAD_IMAGE_TYPE.BACKGROUND
        );
        if (rs && rs.success) {
            setCompany({ ...company, backgroundImage: rs.url });
        }
    };

    const updateProfileImage = async ({ file, fileList }) => {
        let rs = await uploadImage(
            id,
            file,
            UPLOAD_MODEL.COMPANY,
            UPLOAD_IMAGE_TYPE.MAIN
        );
        if (rs && rs.success) {
            setCompany({ ...company, image: rs.url });
        }
    };

    const addPhotoImages = async ({ file, fileList }) => {
        let rs = await uploadImage(
            id,
            file,
            UPLOAD_MODEL.COMPANY,
            UPLOAD_IMAGE_TYPE.GALLERY
        );
        if (rs && rs.success) {
            let newImages = [
                ...company.images,
                rs.image
            ];

            // update image to redux store companyReducer -> activeCompany
            dispatch({ type: UPDATE_ACTIVE_COMPANY_IMAGES, images: newImages });
            setCompany({ ...company, images: newImages });
        }
    };

    const removePhotoImage = async (image = {}) => {
        const { url } = image;
        if (!url) return;

        try {
            let data = {
                deleted_image_url: url
            };
            let rs = await updateCompany(id, data);
            if (rs && rs.success && company.images) {
                let newImages = company.images.filter(item => item.url !== url);
    
                // update image to redux store companyReducer -> activeCompany
                dispatch({ type: UPDATE_ACTIVE_COMPANY_IMAGES, images: newImages });
                setCompany({ ...company, images: newImages });
            }
        } catch (e) {};
    };

    const closeModal = () => {
        setVisibleModal(false);
    };

    const initProps = {
        user: user,
        navigate: navigate,
        profileDetail: company,
        employees: employees,
        onSubmitEdit: onSubmitEdit,
        edit: edit,
        setEdit: setEdit,
        updateCoverImage: updateCoverImage,
        updateProfileImage: updateProfileImage,
        loading: loading,
        description: descriptionCom,
        visibleModal: visibleModal,
        closeModal: closeModal,
        setDescription: setDescription,
        addPhotoImages: addPhotoImages,
        removePhotoImage: removePhotoImage
    };

    return tmpl && tmpl({ ...initProps });
};

class CompanyProfileContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <CompanyProfileLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { userReducer, companyReducer } = state;
    const { activeCompany, employees } = companyReducer;
    return {
        user: userReducer,
        employees: employees,
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        uploadImage: (id, photo, model, type) =>
            dispatch(uploadImage(id, photo, model, type)),
        updateCompany: (id, data) => dispatch(updateCompany(id, data))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CompanyProfileContainer)
);
