import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { fitBounds } from 'google-map-react';
import { MapMarker, MapMarkerComponent } from 'assets/images/icons';
import { Text } from 'components';
import _ from 'lodash';

const AnyReactComponent = ({ text }) => {
    return (
        <div
            className="test"
            style={{
                position: 'absolute',
                height: '25px',
                width: '64px',
                top: -10,
                left: -5
            }}
        >
            <MapMarkerComponent />
            <Text
                style={{
                    position: 'absolute',
                    top: 5,
                    left: 22,
                    color: '#ffffff'
                }}
            >
                {text}
            </Text>
        </div>
    );
};

export default function SimpleMap2(props) {
    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 4,
        position1: {
            lat: 37.773972,
            lng: -122.431297
        },
        position2: {
            lat: 40.7128,
            lng: -74.006
        }
    };
    const { listOfAirports = [] } = props;
    const [defaultCenter, setDefaultCenter] = useState({
        lat: 39.243386,
        lng: -98.218649
    });
    const [center, setCenter] = useState({
        lat: 39.243386,
        lng: -98.218649
    })
    const [zoomLevel, setZoomLevel] = useState(defaultProps.zoom);
    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const [listAirportCard, setListOfAirportCard] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [map, setMap] = useState(null);
    useEffect(() => {
        let newListAirportCard = [];
        let sumLat = 0;
        let sumLng = 0;
        let numberDestinations = 0;
        listOfAirports.map((eachAirport) => {
            const { from_airport = {}, to_airport = {} } = eachAirport;
            if (!_.isEmpty(from_airport) && !_.isEmpty(to_airport)) {
                newListAirportCard.push(from_airport);
                newListAirportCard.push(to_airport);

                // draw curve markers for each route
                const from_airport_coordinates = from_airport.location?.geometry
                    ?.coordinates || [
                    defaultProps.position1.lat,
                    defaultProps.position1.lng
                ];
                const to_airport_coordinates = to_airport.location?.geometry
                    ?.coordinates || [
                    defaultProps.position2.lat,
                    defaultProps.position2.lng
                ];
                numberDestinations += 1;
                sumLat =
                    sumLat +
                    parseFloat(from_airport_coordinates[1]) +
                    parseFloat(to_airport_coordinates[1]);
                sumLng =
                    sumLng +
                    parseFloat(from_airport_coordinates[0]) +
                    parseFloat(to_airport_coordinates[0]);
                const pos1 = {
                    lat: from_airport_coordinates[1],
                    lng: from_airport_coordinates[0]
                };
                const pos2 = {
                    lat: to_airport_coordinates[1],
                    lng: to_airport_coordinates[0]
                };
                setMapOnAll(null);
            }
        });

        switch (props.type) {
            case 'ONEWAY':
                setDefaultCenter({
                    lat: sumLat / 2,
                    lng: sumLng / 2
                });
                break;
            case 'RETURN':
                setDefaultCenter({
                    lat: sumLat / 4,
                    lng: sumLng / 4
                });
                break;
            case 'MULTI_LEG':
                setDefaultCenter({
                    lat: sumLat / (numberDestinations * 2),
                    lng: sumLng / (numberDestinations * 2)
                });
                break;
            default:
                setDefaultCenter({
                    lat: sumLat / (numberDestinations * 2),
                    lng: sumLng / (numberDestinations * 2)
                });
                break;
        }
        setListOfAirportCard(newListAirportCard);
        if (map) {
            drawRoutes(map);
        }
    }, [listOfAirports]);

    const drawRoutes = (map) => {
        listOfAirports.map((eachAirport) => {
            const { from_airport = {}, to_airport = {} } = eachAirport;

            // draw curve markers for each route
            const from_airport_coordinates = from_airport.location?.geometry
                ?.coordinates || [
                defaultProps.position1.lat,
                defaultProps.position1.lng
            ];
            const to_airport_coordinates = to_airport.location?.geometry
                ?.coordinates || [
                defaultProps.position2.lat,
                defaultProps.position2.lng
            ];

            const pos1 = {
                lat: parseFloat(from_airport_coordinates[1]),
                lng: parseFloat(from_airport_coordinates[0])
            };
            const pos2 = {
                lat: parseFloat(to_airport_coordinates[1]),
                lng: parseFloat(to_airport_coordinates[0])
            };
            CurveMarker(
                new google.maps.LatLng(pos1),
                new google.maps.LatLng(pos2),
                map.getProjection(),
                map.getZoom(),
                map
            );
        });
    };

    const setZoomBasedOnAirportList = () => {
        // calculate central netween markers
        if (listOfAirports.length > 0) {
            listOfAirports.map((eachAirport) => {
                const { from_airport = {}, to_airport = {} } = eachAirport;

                // draw curve markers for each route
                const from_airport_coordinates = from_airport.location?.geometry
                    ?.coordinates || [
                    defaultProps.position1.lat,
                    defaultProps.position1.lng
                ];
                const to_airport_coordinates = to_airport.location?.geometry
                    ?.coordinates || [
                    defaultProps.position2.lat,
                    defaultProps.position2.lng
                ];

                const pos1 = {
                    lat: from_airport_coordinates[1],
                    lng: from_airport_coordinates[0]
                };
                const pos2 = {
                    lat: to_airport_coordinates[1],
                    lng: to_airport_coordinates[0]
                };
                let bounds = new google.maps.LatLngBounds();
                bounds.extend(new google.maps.LatLng(pos1.lat, pos1.lng));
                bounds.extend(new google.maps.LatLng(pos2.lat, pos2.lng));
                const ne = bounds.getNorthEast();
                const sw = bounds.getSouthWest();
                const nw = { lat: ne.lat(), lng: sw.lng() };
                const se = { lat: sw.lat(), lng: ne.lng() };
                const { center, zoom } = fitBounds(
                    {
                        se: { lat: se.lat, lng: se.lng },
                        nw: { lat: nw.lat, lng: nw.lng }
                    },
                    { width: 1590, height: 503 }
                );
                setZoomLevel(zoom - 1 || defaultProps.zoom);
            });
        }
    };

    const setMapOnAll = (map) => {
        for (let i = 0; i < markers.length; i++) {
            markers[i].setMap(map);
        }
        setMarkers([]);
    };

    const addMarker = (position, symbol, map) => {
        const marker = new google.maps.Marker({
            position: position,
            icon: symbol,
            map: map
        });
        let newMarkersArray = markers;
        newMarkersArray.push(marker);
        setMarkers(newMarkersArray);
    };

    const CurveMarker = (pos1, pos2, mapProjection, zoom, map) => {
        if (!mapProjection) return;
        var curvature = 0.2;

        const p1 = mapProjection.fromLatLngToPoint(pos1),
            p2 = mapProjection.fromLatLngToPoint(pos2);
        let xEnpoint = p2.x - p1.x;
        if (
            Math.sqrt(
                Math.pow(256 - Math.abs(p2.x - p1.x), 2) +
                    Math.pow(p2.y - p1.y, 2)
            ) <
            Math.sqrt(
                Math.pow(Math.abs(p2.x - p1.x), 2) + Math.pow(p2.y - p1.y, 2)
            )
        ) {
            if (xEnpoint < 0) {
                xEnpoint = 256 - Math.abs(p2.x - p1.x);
            } else {
                xEnpoint = Math.abs(p2.x - p1.x) - 256;
            }
        }

        const e = new google.maps.Point(xEnpoint, p2.y - p1.y), // endpoint
            // Calculating the arc.
            // const e = new google.maps.Point(p2.x - p1.x, p2.y - p1.y), // endpoint
            m = new google.maps.Point(e.x / 2, e.y / 2), // midpoint
            o = new google.maps.Point(e.y, -e.x), // orthogonal
            c = new google.maps.Point(
                m.x + curvature * o.x,
                m.y + curvature * o.y
            ); //curve control point

        const pathDef =
            'M 0,0 ' + 'q ' + c.x + ',' + c.y + ' ' + e.x + ',' + e.y;

        const scale = 1 / Math.pow(2, -zoom);

        const symbol = {
            path: pathDef,
            scale: scale,
            strokeWeight: 2,
            fillColor: 'none',
            strokeColor: '#19c0ff'
        };

        addMarker(pos1, symbol, map);
    };

    useEffect(() => {
        if (isMapLoaded && listOfAirports.length > 0) {
            setZoomBasedOnAirportList();
        }
    }, [isMapLoaded, listOfAirports]);

    const apiIsLoaded = (map, maps, ref) => {
        if (map) {
            setIsMapLoaded(true);
            setMap(map);
            let bound = new google.maps.LatLngBounds();

            if (listOfAirports.length > 0) {
                setMapOnAll(null);
                drawRoutes(map);
                let pointsArr = [];
                listOfAirports.map((eachAirport) => {
                    const { from_airport = {}, to_airport = {} } = eachAirport;
                        pointsArr.push({
                            lat: from_airport.location?.geometry.coordinates[1],
                            lng: from_airport.location?.geometry?.coordinates[0]
                        });
                        pointsArr.push({
                            lat: to_airport.location?.geometry.coordinates[1],
                            lng: to_airport.location?.geometry?.coordinates[0]
                        });

                })
                pointsArr = pointsArr.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.lat === value.lat && t.lng === value.lng
                    ))
                );
                pointsArr.map((point) => {
                    bound.extend( new google.maps.LatLng( point.lat, point.lng) );
                    
                })
                setCenter({
                    lat: bound.getCenter().lat(),
                    lng: bound.getCenter().lng()
                })
            }
                
        }
    };

    return (
        // Important! Always set the container height explicitly
        <div style={{ height: '503px', width: '100%' }}>
            {listAirportCard.length > 0 && (
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: 'AIzaSyA40WAkPpWYZ9iUFidtIcazgOJ-ukxc1Xg'
                    }}
                    resetBoundsOnResize={true}
                    defaultCenter={defaultCenter}
                    center={center}
                    defaultZoom={defaultProps.zoom}
                    zoom={zoomLevel}
                    options={{
                        styles: mapStyle,
                        minZoomOverride: true,
                        minZoom: 2
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps, ref }) =>
                        apiIsLoaded(map, maps, ref)
                    }
                    onZoomAnimationStart={() => setMapOnAll(null)}
                    onZoomAnimationEnd={() => {
                        drawRoutes(map);
                    }}
                >
                    {listAirportCard.length > 0 &&
                        listAirportCard.map((eachAirport, index) => {
                            return (
                                <AnyReactComponent
                                    key={index}
                                    lat={
                                        eachAirport.location?.geometry
                                            ?.coordinates[1]
                                    }
                                    lng={
                                        eachAirport.location?.geometry
                                            ?.coordinates[0]
                                    }
                                    text={eachAirport.icao_code || ''}
                                />
                            );
                        })}
                </GoogleMapReact>
            )}
        </div>
    );
}

const mapStyle = [
    {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#65686f'
            }
        ]
    },
    {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
            {
                color: '#f2f2f2'
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'all',
        stylers: [
            {
                saturation: -100
            },
            {
                lightness: 45
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [
            {
                visibility: 'simplified'
            }
        ]
    },
    {
        featureType: 'road.arterial',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'all',
        stylers: [
            {
                color: '#ffffff'
            },
            {
                visibility: 'on'
            }
        ]
    }
];
