import React from 'react';
import styled from 'styled-components';
import background from '../../assets/images/BG-image.jpg';
import 'antd/dist/antd.css';
import { Card, Form, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logo/Logo_JetOS_04_horizontal.png';
import '../../assets/fonts/Avenir-Light/Avenir-Light.css';
import { Tabs, Text } from 'components';
import { SearchCountries } from 'components/Search';
import { callIcon, emailIcon } from 'assets/images/icons';

const template = (props) => {
    const { t, i18n } = useTranslation();
    const {
        loading,
        email,
        setEmail,
        phone,
        setPhone,
        errors,
        handleSubmitEmail,
        country,
        type,
        onSelectCountry,
        onChangeTab
    } = props;

    const _renderViaEmailTab = () => {
        return (
            <Form.Item
                label={
                    <LabelText>{t('EMAIL_ADDRESS').toUpperCase()}</LabelText>
                }
            >
                <InputSection>
                    <Input
                        style={inputStyle}
                        value={email}
                        placeholder={t('EMAIL_ADDRESS')}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors && errors.email && (
                        <ErrorText>{t(errors.email)}</ErrorText>
                    )}
                </InputSection>
            </Form.Item>
        );
    };

    const _renderViaPhoneTab = () => {
        return (
            <>
                <Form.Item
                    label={<LabelText>{t('COUNTRY').toUpperCase()}</LabelText>}
                >
                    <InputSection>
                        <SearchCountries
                            country={country}
                            onSelectCountry={onSelectCountry}
                            placeholder={t('COUNTRY')}
                            inputStyle={inputStyle}
                        />
                    </InputSection>
                    {errors && errors.country && (
                        <ErrorText>{t(errors.country)}</ErrorText>
                    )}
                </Form.Item>
                <Form.Item
                    label={
                        <LabelText>{t('PHONE_NUMBER').toUpperCase()}</LabelText>
                    }
                >
                    <InputSection>
                        <Input
                            style={inputStyle}
                            value={phone}
                            placeholder={t('PHONE_NUMBER')}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        {errors && errors.phone && (
                            <ErrorText>{t(errors.phone)}</ErrorText>
                        )}
                    </InputSection>
                </Form.Item>
            </>
        );
    };

    const _renderTabs = () => {
        const tabRenderer = [
            {
                key: 'email',
                label: t('VIA_EMAIL').toUpperCase(),
                render: _renderViaEmailTab(),
                icon: emailIcon
            },
            {
                key: 'sms',
                label: t('VIA_SMS').toUpperCase(),
                render: _renderViaPhoneTab(),
                icon: callIcon
            }
        ];
        return (
            <Tabs
                tabRenderer={tabRenderer}
                currentTab={type}
                onChange={onChangeTab}
            />
        );
    };

    return (
        <StyledBackground>
            <HeaderSection>
                <LogoSection>
                    <Logo src={logo} />
                </LogoSection>
                <CreateAccountSection></CreateAccountSection>
            </HeaderSection>
            <ContentSection>
                <Card className="ant-card-login">
                    <WelcomeSection>
                        <WelcomeText>
                            {t('FORGOT_PASSWORD').toUpperCase()}
                        </WelcomeText>
                    </WelcomeSection>
                    <InstructionSection>
                        <InstructionText>
                            {t('FORGOT_PASSWORD_INSTRUCTION')}
                        </InstructionText>
                    </InstructionSection>

                    <LoginInformationSection>
                        <Form
                            layout="vertical"
                            style={{ width: '100%', maxWidth: 470 }}
                        >
                            {_renderTabs()}

                            <Form.Item
                                style={{
                                    justifyContent: 'center',
                                    display: 'flex'
                                }}
                            >
                                <StyledButton
                                    onClick={() => {
                                        handleSubmitEmail &&
                                            handleSubmitEmail();
                                    }}
                                >
                                    {loading ? (
                                        <LoadingOutlined
                                            style={{
                                                fontSize: '24px',
                                                color: '#ffffff'
                                            }}
                                        />
                                    ) : (
                                        <SubmitButtonText>
                                            {t('SEND_CODE').toUpperCase()}
                                        </SubmitButtonText>
                                    )}
                                </StyledButton>
                            </Form.Item>
                        </Form>
                    </LoginInformationSection>
                </Card>
            </ContentSection>

            <FooterSection></FooterSection>
        </StyledBackground>
    );
};

const InputSection = styled.div`
    width: 100%;
`;

const StyledBackground = styled.div`
    background-image: url(${background});
    height: 110vh;
    /* min-width: 100%; */
    min-height: 100%;
    background-size: cover;
    background-position: center;
    /* display: flex;
    flex-direction: column; */
    /* justify-content: space-between; */
    flex-wrap: wrap;
    .ant-row-center {
        padding-left: 3rem;
        padding-top: 1rem;
        padding-right: 3rem;
    }
`;

const HeaderSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 3rem;
    padding-top: 1rem;
    padding-right: 3rem;
    margin-bottom: 50px;
    width: 100%;
`;

const FooterSection = styled.div``;

const LogoSection = styled.div``;

const CreateAccountSection = styled.div`
    margin-top: auto;
    margin-bottom: auto;
`;

const Logo = styled.img`
    height: 50px;
    width: auto;
`;

const ErrorText = styled.span`
    padding-top: 5px;
    color: #f42020;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
`;

const ContentSection = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    .ant-card-login {
        border-radius: 8px;
        width: 35vw;
        min-width: 500px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .ant-form-item-control {
        min-width: 470px;
    }
    @media (max-width: 1680px) {
        .ant-form-item-control {
            min-width: 390px;
        }
    }
    .ant-form-vertical .ant-form-item-label > label {
        width: 100%;
    }
`;

const StyledButton = styled.button`
    width: 50%;
    min-width: 110px;
    background-color: #19c0ff;
    border-radius: 2px;
    border: none;
    height: 50px;
    margin-bottom: 32px;
    /* &:hover {
        background-color: #12afeb;
    } */
`;

const SubmitButtonText = styled.span`
    color: #ffffff;
    font-family: AvenirNext-DemiBold;
    font-size: 13px;
    letter-spacing: 1px;
    line-height: 16px;
    text-align: center;
`;

const WelcomeSection = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 50px;
`;

const WelcomeText = styled.span`
    font-size: 20px;
    letter-spacing: 1.5px;
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 24px;
    letter-spacing: 2px;
    line-height: 32px;
    text-align: center;
`;

const InstructionSection = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    padding-top: 10px;
`;

const InstructionText = styled.span`
    color: #65686f;
    font-family: Lato;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
`;

const LoginInformationSection = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    .ant-form-item-control-input-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
`;

const LabelText = styled.span`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
`;

const PasswordLabelSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 29.6875vw;
    .forgot-password-button {
        float: right;
    }
    .forgot-password-text {
        text-align: right;
    }
    /* Specific for iPhone 12 Pro Max and  devices have the view port width above */
    @media (max-width: 700px) {
        .forgot-password-button {
            float: left;
            width: 29.6875vw;
            padding-left: 0;
            align-items: flex-start;
            display: flex;
        }
        .forgot-password-text {
            text-align: left;
        }
    }
`;

const inputStyle = {
    height: '50px',
    // border: '1px solid rgba(36, 36, 45, 0.15)',
    borderRadius: '3px'
};

export { template };
