import React, { useState } from 'react';
import { Popover, Row, Col, Avatar } from 'antd';
import styled from 'styled-components';
import { Text, ButtonCustom } from 'components';
import { infoIcon, moreIcon, usdIcon } from 'assets/images/icons';
import { useTranslation } from 'react-i18next';
import { Icon } from 'styles/shareStyles';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getCustomerInfo } from 'redux/selectors/company';
import { Title, TitleHeader, TextItem, WrapperListNoti } from '../../styles';

const Invoice = (props) => {
    const {
        title = '',
        ref_basic_info = {},
        content = '',
        createAt = '',
        _onRef = '',
        onClickReadNoti,
        user = {},
        seen = false
    } = props;
    const { status = '' } = ref_basic_info;
    const { firstname = '', lastname = '', user_id = '' } = user;
    const { t, i18n } = useTranslation();

    let image = '';
    const customer = useSelector((state) => getCustomerInfo(state, user_id));
    if (customer) {
        image = customer.image;
    }

    const moreActions = (
        <div
            style={{
                justifyContent: 'center',
                width: '200px'
            }}
        >
            <div>
                <a onClick={() => onClickReadNoti && onClickReadNoti()}>
                    <Text textStyle="tableText2" style={{ fontSize: '12px' }}>
                        {t('MARK_AS_READ')}
                    </Text>
                </a>
            </div>
            <div style={{ marginTop: '5px' }}>
                <a onClick={() => onClickReadNoti && onClickReadNoti()}>
                    <Text textStyle="tableText2" style={{ fontSize: '12px' }}>
                        {t('REMOVE_THIS_NOTI')}
                    </Text>
                </a>
            </div>
        </div>
    );

    const _renderHeader = () => {
        if (!title) {
            return null;
        }
        
        return (
            <Header>
                <HeaderLeft>
                    <TitleSection>
                        <InfoCircleBackground>
                            <Icon
                                style={{ width: 20, height: 20 }}
                                src={usdIcon}
                            />
                        </InfoCircleBackground>
                        <TitleHeader seen={!seen}>{title.toUpperCase()}</TitleHeader>
                    </TitleSection>
                </HeaderLeft>
                <HeaderRight>
                    <Popover
                        placement="rightBottom"
                        content={moreActions}
                        trigger="click"
                        style={{ borderRadius: 4 }}
                    >
                        <Icon
                            src={moreIcon}
                            style={{ width: '32px', height: '25px' }}
                        />
                    </Popover>
                </HeaderRight>
            </Header>
        );
    };

    return (
        <Container>
            <WrapperListNoti>
                {_renderHeader()}

                <div style={{ marginLeft: 50 }}>
                    <div>
                        <Title seen={!seen}>{content}</Title>
                    </div>

                    <div style={{ marginTop: '10px' }}>
                        {image ? (
                            <Avatar className="ant-avatar-custom" src={image} />
                        ) : (
                            <Avatar className="ant-avatar-custom">
                                {(
                                    firstname.charAt(0) + lastname.charAt(0)
                                ).toUpperCase()}
                            </Avatar>
                        )}
                        <Name>{`${firstname} ${lastname}`}</Name>
                    </div>

                    <div style={{ marginTop: '10px' }}>
                        <ButtonCustom 
                            title={t('VIEW_DETAILS').toUpperCase()} 
                            btnType="secondary" 
                            size="small"
                        />
                    </div>

                    <TextItem seen={!seen} style={{ marginTop: '10px' }}>
                        {createAt
                            ? moment(createAt).fromNow()
                            : ''}
                    </TextItem>
                </div>
            </WrapperListNoti>
        </Container>
    );
};

export default Invoice;

const Container = styled.div`
    margin-top: 20px;
`;

const TitleSection = styled.div`
    display: flex;
    flex-direction: row;
`;

const InfoCircleBackground = styled.div`
    border: 1px solid #F98C05;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const HeaderLeft = styled.div`
    margin-top: auto;
    margin-bottom: auto;
`;
const HeaderRight = styled.div`
    margin-top: auto;
    margin-bottom: auto;
`;

const Name = styled.span`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    margin-left: 10px;
`;
