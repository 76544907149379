import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Collapse } from 'antd';
import {
    Header,
    Tabs,
    Map2,
    AircraftTypeCard,
    PackageDetailCard,
    PassengerInfoContainer,
    ButtonCustom,
    FlightNotes,
    Text
} from 'components';
import { allowedRolesCreateQuote } from 'config/Constants';
import { EditOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { groupBy } from 'utils';
import { CREATE_QUOTATION, BOOKING_DETAIL } from 'routes/main';
import { QUOTE_REQUEST_STATUS } from 'redux/Constants';
import { FLIGHT_TYPES } from 'config/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { GLOBAL_STYLE_CONSTANTS } from 'config/Constants';
import {
    iconArrowUp,
    iconArrowDown,
    infoIcon,
    iconAppearance,
    iconGlobe
} from 'assets/images/icons';
import {
    GeneralInfo,
    Appearance,
    Domain,
    UploadDocument,
    HeroImage,
    Advertisement,
    AppIntro,
    Footer,
    AboutUs,
    ContactUs
} from './components';
import { getWhitelabelInfomation } from 'redux/actions/appActions';
const { Panel } = Collapse;

const template = (props) => {
    const {createWhitelabelPage} = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const [activeWhitelabel, setActiveWhitelabel] = useState(true);
    const [whitelabelName, setWhiteLabelName] = useState('');
    const [description, setDescription] = useState('');
    const [redirectToCompanySite, setRedirectToCompanySite] = useState(false);
    const [requireMembership, setRequireMembership] = useState(false);
    const [hideTailnumber, setHideTailnumber] = useState(false);
    const [showScheduledFlights, setShowScheduledFlights] = useState(false);
    const [showAvailableAircrafts, setShowAvailableAircrafts] = useState(false);
    const [showRoutes, setShowRoutes] = useState(false);
    const [defaultUrl, setDefaultUrl] = useState('');
    const [customUrl, setCustomUrl] = useState('');

    // set logo and colors
    const [logo, setLogo] = useState('');
    const [logoNewUrl, setLogoNewUrl] = useState('');
    const [backgroundColor, setBackgroundColor] = useState('#000000');
    const [textColor, setTextColor] = useState('#000000');
    const [buttonBackgroundColor, setButtonBackgroundColor] =
        useState('#000000');
    const [buttonTextColor, setButtonTextColor] = useState('#000000');
    const [highlightColor, setHighlightColor] = useState('#000000');
    const [lightBackgroundColor, setLightBackgroundColor] = useState('#000000');
    const [primaryColor, setPrimaryColor] = useState('#000000');
    const [secondaryColor, setSecondaryColor] = useState('#000000');
    const [secondaryLightBackgroundColor, setSecondaryLightBackgroundColor] =
        useState('#000000');

    // fonts
    const [primaryFont, setPrimaryFont] = useState({});
    const [secondaryFont, setSecondaryFont] = useState({});

    const [showBackgoundImage, setShowBackgoundImage] = useState(false);
    const [documentName, setDocumentName] = useState('');

    // social
    const [facebook, setFacebook] = useState('');
    const [twitter, setTwitter] = useState('');
    const [instagram, setInstagram] = useState('');
    const [linkedin, setLinkedin] = useState('');

    //header
    const [backgroundImage, setBackgroundImage] = useState('');
    const [newBackgroundImage, setNewBackgroundImage] = useState('');
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');

    // Advertisement
    const [activeAdvertisement, setActiveAdvertisement] = useState(false);
    const [advertisementBackground, setAdvertisementBackground] = useState('');
    const [newAdvertisementBackground, setNewAdvertisementBackground] =
        useState('');
    const [advertisementDescription, setAdvertisementDescription] =
        useState('');
    const [advertisementTitle, setAdvertisementTitle] = useState('');
    const [advertisementImage, setAdvertisementImage] = useState('');

    // App intro
    const [activeAppIntro, setActiveAppIntro] = useState(false);
    const [appIntro_android_app_link, set_appIntro_android_app_link] =
        useState('');
    const [appIntro_background_image, set_appIntro_background_image] =
        useState('');
    const [new_appIntro_background_image, set_new_appIntro_background_image] = useState('');
    const [appIntro_description, set_appIntro_description] = useState('');
    const [appIntro_ios_app_link, set_appIntro_ios_app_link] = useState('');
    const [appIntro_title, set_appIntro_title] = useState('');
    const [appIntro_image, set_appIntro_image] = useState('');

    // About Us
    const [activeAboutUs, setActiveAboutUs] = useState(false);
    const [aboutUsDescription, setAboutUsDescription] = useState('');

    // Contact Us
    const [activeContactUs, setActiveContactUs] = useState(false);
    const [contactUsDescription, setContactUsDescription] = useState('');
    const [companyEmail, setCompanyEmail] = useState('');
    const [companyPhone, setCompanyPhone] = useState('');

    // upload doc legal
    const [legalLink, setLegalLink] = useState('');

    const onPressSaveChanges = () => {
        const newPreferences = {
            hide_tail_number: hideTailnumber,
            redirect_to_url: redirectToCompanySite,
            require_membership: requireMembership
            // show_all_results: showAvailableAircrafts,
            // show_routes: showRoutes,
            // show_scheduled_flights: showScheduledFlights
        };

        const newSettings = {
            fleet: showAvailableAircrafts,
            route: showRoutes,
            schedule: showScheduledFlights,
            show_home_body: activeAdvertisement,
            show_footer_session: activeAppIntro
        };

        const newWhitelabelSetting = {
            active: activeWhitelabel,
            name: whitelabelName,
            introduction_text: description,
            preferences: newPreferences,
            domain: defaultUrl,
            custom_domain: customUrl,
            settings: newSettings,
            // manifest: newManifest
        };
        createWhitelabelPage(newWhitelabelSetting);
    };

    return (
        <DashboardContainer>
            <div style={{ marginBottom: 15 }}>
                <Header
                    title={t('SETTINGS')}
                    //TODO: bring this quote to en.js and ask for AJ operator from backend side
                    description={t('WHITELABEL')}
                />
            </div>
            <Collapse
                defaultActiveKey={['0']}
                expandIconPosition="right"
                expandIcon={({ isActive }) =>
                    isActive ? (
                        <Icon src={iconArrowUp} />
                    ) : (
                        <Icon src={iconArrowDown} />
                    )
                }
            >
                <Panel
                    style={{ marginBottom: 40 }}
                    header={
                        <HeaderInfo title={t('GENERAL_INFO').toUpperCase()} />
                    }
                >
                    <GeneralInfo
                        activeWhitelabel={activeWhitelabel}
                        setActiveWhitelabel={setActiveWhitelabel}
                        whitelabelName={whitelabelName}
                        setWhiteLabelName={setWhiteLabelName}
                        setDefaultUrl={setDefaultUrl}
                        description={description}
                        setDescription={setDescription}
                        redirectToCompanySite={redirectToCompanySite}
                        setRedirectToCompanySite={setRedirectToCompanySite}
                        requireMembership={requireMembership}
                        setRequireMembership={setRequireMembership}
                        hideTailnumber={hideTailnumber}
                        setHideTailnumber={setHideTailnumber}
                        showScheduledFlights={showScheduledFlights}
                        setShowScheduledFlights={setShowScheduledFlights}
                        showAvailableAircrafts={showAvailableAircrafts}
                        setShowAvailableAircrafts={setShowAvailableAircrafts}
                        showRoutes={showRoutes}
                        setShowRoutes={setShowRoutes}
                    />
                </Panel>
                <Panel
                    style={{ marginBottom: 40 }}
                    header={
                        <HeaderInfo
                            title={t('DOMAIN').toUpperCase()}
                            icon={<Icon src={iconGlobe} />}
                        />
                    }
                >
                    <Domain
                        defaultUrl={defaultUrl}
                        setDefaultUrl={setDefaultUrl}
                        customUrl={customUrl}
                        setCustomUrl={setCustomUrl}
                    />
                </Panel>
            </Collapse>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ButtonCustom
                    title={t('SAVE_CHANGES').toUpperCase()}
                    btnType="primary"
                    size="large"
                    disabled={false}
                    onClick={() => onPressSaveChanges()}
                />
            </div>
        </DashboardContainer>
    );
};

export { template };

const HeaderInfo = (props) => {
    const { t, i18n } = useTranslation();
    const { title = '', actionText, onClick, isUpdate, icon } = props;

    return (
        <HeaderContainer>
            <HeaderLeft>
                <TitleSection>
                    <InfoCircleBackground>
                        {icon ? (
                            icon
                        ) : (
                            <Icon
                                style={{ width: 20, height: 20 }}
                                src={infoIcon}
                            />
                        )}
                    </InfoCircleBackground>
                    <Title>{title}</Title>
                </TitleSection>
            </HeaderLeft>
            <HeaderRight>
                {actionText &&
                    (!isUpdate ? (
                        <EditButton onClick={() => onClick()}>
                            <EditText>{actionText}</EditText>
                            <EditOutlined />
                        </EditButton>
                    ) : (
                        <EditButton onClick={() => onClick()}>
                            <EditText>
                                {i18n.t('CANCEL_EDIT').toUpperCase()}
                            </EditText>
                            <CloseOutlined />
                        </EditButton>
                    ))}
            </HeaderRight>
        </HeaderContainer>
    );
};

const Icon = styled.img`
    width: 18px;
    height: 18px;
`;

const TitleSection = styled.div`
    display: flex;
    flex-direction: row;
`;

const InfoCircleBackground = styled.div`
    background-color: #f0f3f9;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const HeaderLeft = styled.div`
    margin-top: auto;
    margin-bottom: auto;
`;
const HeaderRight = styled.div`
    margin-top: auto;
    margin-bottom: auto;
`;

const Title = styled.span`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.7px;
    line-height: 16px;
    text-align: left;
    margin-left: 18px;
    margin-top: auto;
    margin-bottom: auto;
`;

const EditButton = styled.button`
    background-color: transparent;
    border: none;
`;

const EditText = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    width: 26px;
    text-align: left;
    margin-right: 8px;
`;

const DashboardContainer = styled.div`
    padding: ${GLOBAL_STYLE_CONSTANTS.padding};
    .ant-header-custom-employees {
        background-color: #f2f4f7;
    }
    .ant-collapse > .ant-collapse-item {
        border-bottom: none;
        background-color: #ffffff;
        border-radius: 4px;
    }
    .ant-collapse {
        border: none;
        background-color: transparent;
    }
`;
