import React from 'react';
import styled from 'styled-components';
import { Row, Col, Image } from 'antd';
import { Text } from 'components';
import { useTranslation } from 'react-i18next';
import { numberFormat, getLowresUrl } from 'utils';
import { invoiceIcon } from 'assets/images/icons';

const PackageDetailCard = (props) => {
    const { t, i18n } = useTranslation();
    const { listOfAircraftInPackages = [], openModalPackage } = props;

    return (
        <AircraftTypeContainer>
            <Row style={{ paddingBottom: 10 }}>
                <Text textStyle="heading5">
                    {t('AIRCRAFT_SELECTED').toUpperCase() +
                        ' (' +
                        listOfAircraftInPackages.length +
                        ')'}
                </Text>
            </Row>
            <AircraftList>
                {listOfAircraftInPackages.length > 0 &&
                    listOfAircraftInPackages.map((aircraft = {}, index) => {
                        return (
                            <AircraftInfo key={index}>
                                <AircraftHeader justify="space-between" style={{ alignItems: 'center' }}>
                                    <PackageNumber>
                                        {t('PACKAGE')} {aircraft?.packageIndex + 1}
                                    </PackageNumber>
                                    <a onClick={() => openModalPackage && openModalPackage(aircraft?.packageIndex)}>
                                        <Icon src={invoiceIcon} />
                                        <ViewInvocie>
                                            {t('VIEW_PACKAGE_INVOICE').toUpperCase()}
                                        </ViewInvocie>
                                    </a>
                                </AircraftHeader>
                                <AircraftDetailWrap>
                                    <Image
                                        src={getLowresUrl(aircraft.image, aircraft?.images)}
                                        width={345}
                                        height={221}
                                    />
                                    <AircraftDetailInfo>
                                        <Col>
                                            <Row
                                                style={{
                                                    marginBottom: 10,
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <AircraftName>
                                                    {aircraft.name || '-'}
                                                </AircraftName>
                                                <TailorCode>
                                                    {aircraft.tail_number}
                                                </TailorCode>
                                            </Row>
                                            <Row
                                                align="middle"
                                                style={{ marginBottom: 10 }}
                                            >
                                                <OperatedBy>
                                                    {t('OPERATED_BY').toUpperCase()}
                                                </OperatedBy>
                                                <OperationName>
                                                    {aircraft.operator_name || '-'}
                                                </OperationName>
                                            </Row>
                                            <Row
                                                align="middle"
                                                style={{ marginBottom: 10 }}
                                            >
                                                <Text
                                                    textStyle="tableText1"
                                                    style={{ width: 100 }}
                                                >
                                                    {t('NUMBER_OF_SEATS')}
                                                </Text>
                                                <Text textStyle="tableText2">
                                                    {(aircraft.properties
                                                        .max_passengers || '-') +
                                                        ' ' +
                                                        t('PAX').toUpperCase()}
                                                </Text>
                                            </Row>
                                            <Row align="middle">
                                                <Text
                                                    textStyle="tableText1"
                                                    style={{ width: 100 }}
                                                >
                                                    {t('TOTAL_PRICE')}
                                                </Text>
                                                <Text textStyle="heading6">
                                                    {numberFormat(
                                                        aircraft.price
                                                            ?.total_inc_vat || 0
                                                    )}
                                                </Text>
                                            </Row>
                                        </Col>
                                    </AircraftDetailInfo>
                                </AircraftDetailWrap>
                            </AircraftInfo>
                        );
                    })}
            </AircraftList>
        </AircraftTypeContainer>
    );
};

export default PackageDetailCard;

const AircraftHeader = styled(Row)`
    padding-top: 20px;
    padding-bottom: 20px;
`;

const AircraftDetailInfo = styled(Row)`
    padding: 20px 25px 20px 25px;
`;

const AircraftInfo = styled(Col)`
    border-radius: 4px;
    margin-right: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
`;

const AircraftDetailWrap = styled(Col)`
    background-color: #f7f8fb;
`;

const AircraftName = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 16px;
`;

const AircraftTypeContainer = styled.div`
    width: 100%;
`;

const AircraftList = styled(Row)`
    background-color: #ffffff;
    border-radius: 4px;
    padding: 13px 30px 30px 30px;
`;

const TailorCode = styled.div`
    border: 1px solid #dadadb;
    border-radius: 2px;
    width: 58px;
    height: 18px;
    color: #19c0ff;
    font-family: AvenirNext-Bold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    width: 56px;
    text-align: center;
    padding: 3px;
    margin-left: 10px;
    margin-bottom: 4px;
`;

const OperatedBy = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.8px;
    line-height: 12px;
`;
const OperationName = styled.span`
    color: #19c0ff;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.8px;
    line-height: 12px;
    margin-left: 5px;
`;

const PackageNumber = styled.span`
    color: #121216;
    font-family: AvenirNext-Bold;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 18px;
`;

const ViewInvocie = styled.span`
    color: #65686F;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    letter-spacing: 0.4px;
    line-height: 13px;
`;

const Icon = styled.img`
    width: 16px;
    height: 21px;
    margin-right: 8px;
`;