import React, { useState, useEffect } from 'react';
import { template as tmpl } from './CustomerDetailContainer.tmpl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    getUserById,
    getBookings,
    validatePassword,
    uploadIdentification,
    updateIdentificationDoc,
    getIdentification,
    requestDocumentAuth
} from 'redux/actions/appActions';
import { useTranslation } from 'react-i18next';

const CustomerDetailLogic = (props) => {
    const { t, i18n } = useTranslation();

    const {
        activeCompany,
        location,
        getUserById,
        getBookings,
        validatePassword,
        uploadIdentification,
        updateIdentificationDoc,
        getIdentification,
        requestDocumentAuth
    } = props;
    const { search } = location;
    const [customer, setCustomer] = useState({});
    const [bookings, setBookings] = useState([]);
    const [image, setImage] = useState(''); // image identidication

    const [visibleModal, setVisibleModal] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');

    useEffect(() => {
        const initDetail = async () => {
            let id = search;
            if (id && id[0] === '?') {
                id = id.substring(1);
            }

            const rs = await getUserById(id);
            if (rs && rs.success) {
                setCustomer(rs.data);
            }

            let query = { user_id: id };
            const responseBookings = await getBookings(query); // query
            if (responseBookings && responseBookings.success) {
                setBookings(responseBookings.bookings);
            }
        };

        // init customer detail
        initDetail();
    }, []);

    const getAuthenIdentity = async (password, getImage = false) => {
        const rs = await validatePassword(password);
        if (rs && rs.success && getImage) {
            let requestDoc = await requestDocumentAuth(customer?.user?.id, true);
            if (requestDoc && requestDoc.success) {
                const imageUrl = await getIdentification(
                    requestDoc?.data?.id
                );
                setImage(imageUrl);
            }
        }

        return rs;
    };

    const onUploadIdentification = async (data = {}) => {
        try {
            const { user = {} } = customer;
            let rs = await updateIdentificationDoc(user.id, data);
            if (!rs.success) {
                alert('Upload identification failed!');
                return;
            }
    
            // add params imageUrl and update new item
            rs = await uploadIdentification(user.id, data.photo);
            if (!rs.success) {
                alert('Upload identification failed!');
                return;
            }
    
            if (customer.identity) {
                let newData = customer;
                newData.identity.identified = true;
                setCustomer({ ...newData });
            }
    
            setVisibleModal(true);
            setSuccessMsg(t('IDENTIFICATION_SUCCESSFULLY_UPLOAD').toUpperCase());
        } catch (e) {}
    };

    const cancelModal = () => {
        setVisibleModal(false);
    };

    const navigate = (route, id, state) => {
        props.history.push({
            pathname: route,
            search: id,
            state: state
        });
    };

    const initProps = {
        successMsg: successMsg,
        visibleModal: visibleModal,
        cancelModal: cancelModal,
        activeCompanyName: activeCompany.name ? activeCompany.name : '',
        customer: customer,
        bookings: bookings,
        navigate: navigate,
        getAuthenIdentity: getAuthenIdentity,
        uploadIdentification: onUploadIdentification,
        image: image
    };

    return tmpl && tmpl({ ...initProps });
};

class CustomerDetailContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <CustomerDetailLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { userReducer, companyReducer } = state;
    const {} = userReducer;
    const { activeCompany } = companyReducer;
    return {
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserById: (id) => dispatch(getUserById(id)),
        getBookings: (id) => dispatch(getBookings(id)),
        validatePassword: (password) => dispatch(validatePassword(password)),
        getIdentification: (id) => dispatch(getIdentification(id)),
        requestDocumentAuth: (userId, showDocumentId) => dispatch(requestDocumentAuth(userId, showDocumentId)),
        uploadIdentification: (userId, photo) =>
            dispatch(uploadIdentification(userId, photo)),
        updateIdentificationDoc: (userId, data) =>
            dispatch(updateIdentificationDoc(userId, data))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CustomerDetailContainer)
);
