import React from 'react';
import 'antd/dist/antd.css';
import { Row, Input as AntInput } from 'antd';
import { Input } from 'components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Domain = (props) => {
    const {
        defaultUrl = '',
        setDefaultUrl,
        customUrl = '',
        setCustomUrl
    } = props;
    const { t, i18n } = useTranslation();
    return (
        <ContainerWrapper>
            <PayoutSection>
                <WhitelabelContainer>
                    <EachRow>
                        <Input
                            label={t('DEFAULT_URL').toUpperCase()}
                            placeholder={t('HTTPS')}
                            width="100%"
                            value={defaultUrl}
                            onChange={setDefaultUrl}
                            disabled={true}
                        />
                    </EachRow>
                    <EachRow>
                        <Input
                            label={t('CUSTOM_URL').toUpperCase()}
                            placeholder={t('HTTPS')}
                            width="100%"
                            value={customUrl}
                            onChange={setCustomUrl}
                        />
                    </EachRow>
                </WhitelabelContainer>
            </PayoutSection>
        </ContainerWrapper>
    );
};

export default Domain;

const WhitelabelContainer = styled.div`
    padding-left: 51px;
`;

const EachRow = styled(Row)`
    margin-top: 20px;
`;

const ContainerWrapper = styled.div`
    padding-bottom: 40px;
    border-radius: 4px;
    /* margin-top: 14px; */
    .ant-divider {
        border-top: 1px solid rgba(14, 14, 18, 0.08);
    }
    .ant-divider-horizontal {
        margin: 20px 0;
    }
`;

const PayoutSection = styled.div`
    padding-right: 20px;
    padding-bottom: 40px;
    background-color: #ffffff;
    border-radius: 4px;
`;
