import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { CustomerContainer } from '../styles';
import { Header, SearchInput } from 'components';
import InFlightTable from './InFlightTable';
import UpComingFlightTable from './UpComingFlightTable';
import AwaitingConfirmationTable from './AwaitingConfirmationTable';
import CancelledTable from './CancelledTable';
import CompletedTable from './CompletedTable';
import SearchResult from './SearchResult';
import styled from 'styled-components';
import { SearchBooking } from 'components/Commons';
import { bookingCategory } from 'config/Constants';

const template = (props) => {
    const {
        navigate,
        onSearch,
        loading = false,
        upcomingFlightsList = {},
        inFlightsList = {},
        completedFlightsList = {},
        searchList = {},
        awaitingConfirmationList,
        cancelledList,
        onSelectCategory,
        onChangePageUpcomingFlights,
        onChangePageCompletedFlights,
        onChangePageInFlights,
        onChangePageSearch,
        onChangePageAwaitingConfirmationFlights,
        onChangePageCancelledFlights,
        onChangePageConfirmed,
        onChangePageCancelled,
        searchBy = '',
        key = ''
    } = props;
    const { t, i18n } = useTranslation();

    const createCategoryOption = () => {
        let tempArr = [];
        let key;
        for (key in bookingCategory) {
            tempArr.push({
                text: t(bookingCategory[key]),
                value: bookingCategory[key]
            });
        }
        return tempArr;
    };

    return (
        <CustomerContainer>
            <Header title={t('FLIGHTS')} description={t('BOOKINGS_SUBTITLE')} />
            {/* <SearchBooking
                data={createCategoryOption()}
                onSearch={onSearch}
                onSelectCategory={onSelectCategory}
                placeholder={t('PLACEHOLDER_SEARCH_BOOKING')}
            /> */}
            <SearchSection>
                <SearchInput
                    placeholder={t('PLACEHOLDER_SEARCH_BOOKING')}
                    onSearch={onSearch}
                />
            </SearchSection>

            {!key ? (
                <>
                    {/* Awaiting confirmation flights */}
                    <AwaitingConfirmationTable
                        onChangePageAwaitingConfirmationFlights={
                            onChangePageAwaitingConfirmationFlights
                        }
                        list={awaitingConfirmationList}
                        loading={loading}
                        navigate={navigate}
                    />
                    {/* Upcoming Flights */}

                    <UpComingFlightTable
                        onChangePageUpcomingFlights={
                            onChangePageUpcomingFlights
                        }
                        list={upcomingFlightsList}
                        loading={loading}
                        navigate={navigate}
                    />

                    {/* In-Flight */}
                    <InFlightTable
                        onChangePageInFlights={onChangePageInFlights}
                        list={inFlightsList}
                        loading={loading}
                        navigate={navigate}
                    />

                    {/* Completed */}
                    <CompletedTable
                        onChangePageCompletedFlights={
                            onChangePageCompletedFlights
                        }
                        list={completedFlightsList}
                        loading={loading}
                        navigate={navigate}
                    />

                    {/* Cancelled */}
                    <CancelledTable
                        onChangePageCancelledFlights={
                            onChangePageCancelledFlights
                        }
                        list={cancelledList}
                        loading={loading}
                        navigate={navigate}
                    />
                </>
            ) : (
                <SearchResult
                    onChangePageSearch={onChangePageSearch}
                    list={searchList}
                    loading={loading}
                    navigate={navigate}
                />
            )}
        </CustomerContainer>
    );
};

export { template };

const SearchSection = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
    /* .ant-input {
        background-color: transparent;
    }
    .ant-input-affix-wrapper {
        border: 1px solid #dadadb;
        border-radius: 3px;
        width: 20vw;
        height: 40px;
        background-color: transparent;
    } */
`;
