import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { Modal, Row, Col, InputNumber, Input } from 'antd';
import { ButtonCustom } from 'components';
import SearchServiceItems from 'components/Search/SearchServiceItems';
import _ from 'lodash';

const EditModal = ({
    show = false,
    onOk,
    onCancel,
    onSave,
    serviceItem = {},
    type // STANDARD <> CUSTOM
}) => {
    const { t, i18n } = useTranslation();

    const [isEdit, setIsEdit] = useState(false);
    const [item, setItem] = useState({});
    const [price, setPrice] = useState({});

    const onAddItem = (item = {}) => {
        setItem(item);
        setPrice(item?.price || {});
    };

    const onSaveItem = () => {
        let newItem = {
            ...item,
            price: price
        };
        onSave(newItem, type, isEdit);
    };

    useEffect(() => {
        if (!_.isEmpty(serviceItem)) {
            setItem(serviceItem);
            setPrice(serviceItem?.price || {});
            setIsEdit(true);
        } else {
            setItem({});
            setPrice({});
            setIsEdit(false);
        }
    }, [serviceItem]);

    const checkValidItem = () => {
        const { name, quantity = 0 } = item;
        const { value = 0 } = price;
        let valid = false;
        if (name && quantity > 0 && value > 0) {
            valid = true;
        }
        return valid;
    };

    return (
        <Modal
            centered
            visible={show}
            onOk={() => onOk()}
            onCancel={() => onCancel(false)}
            footer={null}
            style={{ margin: 50 }}
            width={'600px'}
        >
            <Row style={{ marginTop: 10 }}>
                <div>
                    <InputItemWrapper style={{ justifyContent: 'center' }}>
                        <Title>
                            {!isEdit
                                ? i18n.t('ADD_NEW_SERVICE_ITEM').toUpperCase()
                                : i18n.t('EDIT_SERIVCE_ITEM').toUpperCase()}
                        </Title>
                    </InputItemWrapper>
                    <InputItemWrapper
                        style={{ marginTop: 25, justifyContent: 'center' }}
                    >
                        <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            <ItemTitle style={{ marginBottom: 10 }}>
                                {i18n.t('NAME').toUpperCase()}
                            </ItemTitle>
                            {isEdit ? (
                                <Input
                                    value={item?.name || ''}
                                    style={{
                                        width: '370px',
                                        height: '50px'
                                    }}
                                    // disabled={true}
                                />
                            ) : type === 'STANDARD' ? (
                                <SearchServiceItems
                                    onSelectItem={onAddItem}
                                    inputStyle={{
                                        width: '370px',
                                        height: '50px'
                                    }}
                                    placeholder={t('ENTER_NAME_ITEMS')}
                                />
                            ) : (
                                <Input
                                    placeholder={t('ENTER_NAME_ITEMS')}
                                    value={item?.name || ''}
                                    style={{
                                        width: '370px',
                                        height: '50px'
                                    }}
                                    onChange={(val) =>
                                        setItem({
                                            ...item,
                                            name: val.target.value
                                        })
                                    }
                                />
                            )}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: 20
                            }}
                        >
                            <ItemTitle style={{ marginBottom: 10 }}>
                                {i18n.t('PRICE').toUpperCase()}
                            </ItemTitle>
                            <InputNumber
                                min={0}
                                formatter={(value) =>
                                    `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                    )
                                }
                                parser={(value) =>
                                    value.replace(/\$\s?|(,*)/g, '')
                                }
                                onChange={(val) =>
                                    setPrice({ ...price, value: val })
                                }
                                className="input-item"
                                value={price?.value || 0}
                                disabled={type === 'STANDARD' ? true : false}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: 20
                            }}
                        >
                            <ItemTitle style={{ marginBottom: 10 }}>
                                {i18n.t('QUANTITY').toUpperCase()}
                            </ItemTitle>
                            <InputNumber
                                min={0}
                                formatter={(value) =>
                                    `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                    )
                                }
                                parser={(value) =>
                                    value.replace(/\$\s?|(,*)/g, '')
                                }
                                onChange={(val) =>
                                    setItem({ ...item, quantity: val })
                                }
                                className="input-item"
                                value={item?.quantity || 0}
                            />
                        </div>
                    </InputItemWrapper>
                    <InputItemWrapper
                        style={{ marginTop: 30, marginBottom: 20, justifyContent: 'center' }}
                    >
                        <Col>
                            <ButtonCustom
                                btnType="secondary"
                                width="150px"
                                size="medium"
                                title={t('CANCEL').toUpperCase()}
                                onClick={onCancel}
                            />
                        </Col>
                        <Col style={{ marginLeft: 25 }}>
                            <ButtonCustom
                                btnType="primary"
                                width="150px"
                                size="medium"
                                title={t('SAVE').toUpperCase()}
                                onClick={onSaveItem}
                                disabled={!checkValidItem()}
                            />
                        </Col>
                    </InputItemWrapper>
                </div>
            </Row>
        </Modal>
    );
};

export default EditModal;

const InputItemWrapper = styled(Row)`
    .input-item {
        border: 1px solid rgba(36, 36, 45, 0.15);
        border-radius: 3px;
        width: 370px;
        height: 50px;
        padding-top: 8px;
    }
`;

const ItemTitle = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 18px;
    text-align: left;
    margin-right: 50px;
`;

const Title = styled.div`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 32px;
    text-align: center;
`;
