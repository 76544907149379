import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

/**
 *
 * @param {string} props.title - the title of the page
 * @param {string} props.description - the description of the page
 * @returns {JSX}
 */
const HeaderTag = (props) => {
    const { title = '', description = '' } = props;

    return (
        <>
            <HeaderTagSection>
                <VerticalLine></VerticalLine>
                <HeaderTitle>
                    <HeaderTitleText>{title}</HeaderTitleText>
                </HeaderTitle>
            </HeaderTagSection>
            <HeaderDescription>
                <HeaderDescriptionText>{description}</HeaderDescriptionText>
            </HeaderDescription>
        </>
    );
};

const HeaderDescriptionText = styled.span`
    margin-left: 12px;
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 12px;
    line-height: 32px;
    text-align: left;
`;

const HeaderDescription = styled.div``;

const HeaderTagSection = styled.div`
    display: flex;
    flex-direction: row;
`;

const VerticalLine = styled.div`
    margin-top: auto;
    margin-bottom: auto;
    background-color: #19c0ff;
    width: 2px;
    height: 16px;
`;

const HeaderTitle = styled.div``;

const HeaderTitleText = styled.span`
    margin-left: 10px;
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 16px;
    line-height: 26px;
`;

export default HeaderTag;
