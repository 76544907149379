import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { Layout, Menu, Input, Avatar, Drawer, Dropdown } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { HIDE_LOADING, SHOW_LOADING, TOGGLE_SIDEBAR } from 'redux/Constants';

import personImage from 'assets/images/random-person.png';
import { symbolOnly, logoPNG } from 'assets/images/logo';
import Loading from 'components/Loading';
import { getWindowDimensions } from 'utils';
import {
    iconMenu,
    searchIcon,
    profileIcon,
    avianPay,
    settingIcon,
    signoutIcon,
    airportIcon,
    bookingsIcon,
    communityIcon,
    companyIcon,
    dashboardIcon,
    flightsIcon,
    messageIcon,
    marketPlaceIcon,
    bellIcon,
    addFriendIcon,
    fleetIcon,
    infoIosIcon
} from 'assets/images/icons';
import MainRoutes from 'routes';
import {
    SETTING_PROFILE,
    CUSTOMER_LIST,
    EMPLOYEES,
    SERVICES,
    BROKER_CONNECTIONS,
    PARTNERSHIP_FLEET,
    COMPANY_FLEET,
    FBO_LIST,
    PARTNERS_LIST,
    COMPANY_PROFILE,
    QUOTE_REQUEST,
    BOOKING_LIST,
    FLIGHT_LIST,
    EMPTY_LEG,
    COMPANY_INVOICES,
    DASHBOARD,
    FLIGHT_PROMOTION_LIST,
    COMPANY_SETTING
} from 'routes/main';
import ModalListener from 'modules/modal';
import NotificationDrawer from 'views/Notifications/drawer';
import DropdownMenu from './DropdownMenu';

const { SubMenu, Divider } = Menu;
const { Header, Content, Sider, Footer } = Layout;

const template = (props) => {
    const {
        firstname,
        lastname,
        image,
        activeCompanyName,
        companies = [],
        userCompanies = [],
        navigate,
        unread
    } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const [collapsed, setCollapsed] = useState(
        getWindowDimensions().width > 1366 ? false : true
    );
    dispatch({ type: TOGGLE_SIDEBAR, data: !collapsed });

    const [expandedCompany, setExpandedCompany] = useState(false);
    const [expandedFlight, setExpandedFlight] = useState(false);
    const [expandedBooking, setExpandedBooking] = useState(false);
    const [expandedCommunity, setExpandedCommunity] = useState(false);
    const [expandedMarketPlace, setExpandedMarketPlace] = useState(false);
    const [expandedFleet, setExpandedFleet] = useState(false);

    const [showNotiDrawer, setShowNotiDrawer] = useState(false);
    const [showSideBarDrawer, setShowSideBarDrawer] = useState(false);
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleSideBarDrawer = () => {
        setShowSideBarDrawer(!showSideBarDrawer);
    };

    // const toggle = () => {
    //     if (windowDimensions.width < 1024) {
    //         // if (!collapsed) {
    //         setCollapsed(!collapsed);
    //         // }
    //         toggleSideBarDrawer();
    //     } else {
    //         setCollapsed(!collapsed);
    //         dispatch({ type: TOGGLE_SIDEBAR, data: collapsed });
    //     }
    // };
    const toggle = () => {
        setCollapsed(!collapsed);
        dispatch({ type: TOGGLE_SIDEBAR, data: collapsed });
        toggleSideBarDrawer();
    };

    const onSearch = (value) => console.log(value);

    const SearchIcon = <img src={searchIcon} />;

    const onNavigateNoti = (route, id) => {
        setShowNotiDrawer(false);
        navigate(route, id);
    };

    const logout = () => {
        props.logout();
    };

    const onCloseNotiDrawer = () => {
        setShowNotiDrawer(false);
    };

    const onClickSettings = () => {
        navigate(SETTING_PROFILE);
    };

    const onChangeCompany = async (company) => {
        dispatch({ type: SHOW_LOADING });

        let rs = await props.switchCompany(company);
        if (rs && rs.success) {
            navigate(DASHBOARD);
        }

        setTimeout(async () => {
            dispatch({ type: HIDE_LOADING });
            // TODO : improve collapse tab menu in sidebar
            window.location.reload();
        }, 300);
    };

    const onNavigate = (route) => {
        navigate(route);
    };

    return (
        <LayoutWrapper style={{ backgroundColor: '#FFFFFF' }}>
            {/* Sidebar Drawer when screen is smaller than 768px (iPad screen size) */}
            {windowDimensions.width < 1024 ? (
                <Drawer
                    className="sidebar-drawer"
                    placement={'left'}
                    width={375}
                    onClose={toggle}
                    visible={showSideBarDrawer}
                    closable={false}
                    footer={
                        <Footer
                            style={{
                                backgroundColor: '#fff',
                                position: 'fixed',
                                bottom: 0,
                                width: '330px'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <img
                                    src={infoIosIcon}
                                    style={{
                                        width: '16px',
                                        height: '16px',
                                        marginRight: '5px'
                                    }}
                                />
                                <ReportAndImprovement>
                                    {t('REPORT_AND_IMPROVEMENT')}
                                </ReportAndImprovement>
                            </div>
                            <CopyRight>
                                {t('COPYRIGHT_AVIAN').toUpperCase()}
                            </CopyRight>
                        </Footer>
                    }
                    bodyStyle={{ padding: 0 }}
                >
                    <MenuWrapper
                        windowDimensions={windowDimensions}
                        onNavigate={onNavigate}
                        setExpandedCompany={setExpandedCompany}
                        expandedCompany={expandedCompany}
                        setExpandedFleet={setExpandedFleet}
                        expandedFleet={expandedFleet}
                        setExpandedFlight={setExpandedFlight}
                        expandedFlight={expandedFlight}
                        setExpandedBooking={setExpandedBooking}
                        expandedBooking={expandedBooking}
                        setExpandedCommunity={setExpandedCommunity}
                        expandedCommunity={expandedCommunity}
                        setExpandedMarketPlace={setExpandedMarketPlace}
                        expandedMarketPlace={expandedMarketPlace}
                        setCollapsed={setCollapsed}
                        collapsed={collapsed}
                    />
                </Drawer>
            ) : (
                <SiderSection
                    collapsed={collapsed}
                    style={{ minHeight: '100vh', position: 'relative' }}
                >
                    {/* Sidebar */}
                    <Sider
                        breakpoint="lg"
                        collapsedWidth={
                            windowDimensions.width < 1024 ? '0' : '95'
                        } // Hide the menu after collapsed
                        onBreakpoint={(broken) => {
                            console.log('broken', broken);
                        }}
                        trigger={null}
                        theme="light"
                        collapsible
                        collapsed={collapsed}
                        className="ant-layout-sider-custom"
                    >
                        <LogoSection className="logo">
                            {collapsed ? (
                                <LogoCollapsed src={symbolOnly} />
                            ) : (
                                <Logo src={logoPNG} />
                            )}
                        </LogoSection>
                        <MenuWrapper
                            windowDimensions={windowDimensions}
                            onNavigate={onNavigate}
                            setExpandedCompany={setExpandedCompany}
                            expandedCompany={expandedCompany}
                            setExpandedFleet={setExpandedFleet}
                            expandedFleet={expandedFleet}
                            setExpandedFlight={setExpandedFlight}
                            expandedFlight={expandedFlight}
                            setExpandedBooking={setExpandedBooking}
                            expandedBooking={expandedBooking}
                            setExpandedCommunity={setExpandedCommunity}
                            expandedCommunity={expandedCommunity}
                            setExpandedMarketPlace={setExpandedMarketPlace}
                            expandedMarketPlace={expandedMarketPlace}
                            setCollapsed={setCollapsed}
                            collapsed={collapsed}
                        />

                        {!collapsed && (
                            <Footer
                                style={{
                                    backgroundColor: '#fff',
                                    position: 'fixed',
                                    bottom: 0,
                                    width: '330px'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <img
                                        src={infoIosIcon}
                                        style={{
                                            width: '16px',
                                            height: '16px',
                                            marginRight: '5px'
                                        }}
                                    />
                                    <ReportAndImprovement>
                                        {t('REPORT_AND_IMPROVEMENT')}
                                    </ReportAndImprovement>
                                </div>
                                <CopyRight>
                                    {t('COPYRIGHT_AVIAN').toUpperCase()}
                                </CopyRight>
                            </Footer>
                        )}
                    </Sider>
                </SiderSection>
            )}

            {/* Header */}
            <Layout className="site-layout">
                <HeaderSection>
                    <Header
                        className="site-layout-background"
                        style={{
                            padding: 0
                        }}
                    >
                        <HeaderLeft>
                            <CollapseButtonSection>
                                <CollapseButton onClick={() => toggle()}>
                                    <CollapseIcon src={iconMenu} />
                                </CollapseButton>
                            </CollapseButtonSection>

                            {/* Search bar */}
                            <Input
                                placeholder={t('SEARCH_KEYWORD')}
                                allowClear
                                bordered={false}
                                onChange={onSearch}
                                prefix={SearchIcon}
                            />
                        </HeaderLeft>
                        <HeaderRight>
                            <Dropdown
                                overlay={
                                    <DropdownMenu
                                        userCompanies={userCompanies}
                                        onChangeCompany={onChangeCompany}
                                        logout={logout}
                                        onClickSettings={onClickSettings}
                                    />
                                }
                                placement="bottomCenter"
                            >
                                <PersonalInfoSection>
                                    <div>
                                        <Avatar src={image} />
                                    </div>
                                    <NameAndCompanySection
                                        expandedSidebar={!collapsed}
                                    >
                                        <NameSection>
                                            <Name>
                                                {firstname + ' ' + lastname}
                                            </Name>
                                        </NameSection>
                                        <CompanySection>
                                            <Company>
                                                {activeCompanyName.toUpperCase()}
                                            </Company>
                                        </CompanySection>
                                    </NameAndCompanySection>
                                </PersonalInfoSection>
                            </Dropdown>
                            <AddContactSection>
                                <AddContactIcon src={addFriendIcon} />
                            </AddContactSection>
                            <AddContactSection
                                style={{
                                    marginRight: '35px',
                                    position: 'relative'
                                }}
                                onClick={() => {
                                    setShowNotiDrawer(!showNotiDrawer);
                                }}
                            >
                                <AddContactIcon src={bellIcon} />
                                {unread > 0 && <BubbleNotification />}
                            </AddContactSection>
                        </HeaderRight>
                    </Header>
                </HeaderSection>

                <Content
                    className="site-layout-background"
                    style={{ minHeight: 310 }}
                >
                    <MainRoutes />
                </Content>
            </Layout>

            {/* modal listener */}
            <ModalListener navigate={navigate} />

            {/* Notification Drawer */}
            <NotificationDrawer
                show={showNotiDrawer}
                onClose={onCloseNotiDrawer}
                navigate={onNavigateNoti}
            />

            {/* Loading */}
            <WrapperLoading>
                <Loading />
            </WrapperLoading>
        </LayoutWrapper>
    );
};

const SideBarItemText = (props) => {
    return <SideBarText>{props.title}</SideBarText>;
};

const MenuIconItem = ({ icon, collapsed = false }) => {
    const menuIconStyle = {
        display: collapsed ? 'block' : 'flex',
        width: '32px',
        height: '32px',
        alignItems: 'center'
        // justifyContent: 'center'
    };

    return (
        <div style={menuIconStyle}>
            <IconStyle src={icon} />
        </div>
    );
};

const MenuWrapper = ({
    windowDimensions,
    onNavigate,
    setExpandedCompany,
    expandedCompany,
    setExpandedFleet,
    expandedFleet,
    setExpandedFlight,
    expandedFlight,
    setExpandedBooking,
    expandedBooking,
    setExpandedCommunity,
    expandedCommunity,
    setExpandedMarketPlace,
    expandedMarketPlace,
    setCollapsed,
    collapsed
}) => {
    const { t, i18n } = useTranslation();
    let openKeys = [];
    if (collapsed) {
        setExpandedCompany(false);
        setExpandedFleet(false);
        setExpandedFlight(false);
        setExpandedBooking(false);
        setExpandedCommunity(false);
        setExpandedMarketPlace(false);
    } else {
        expandedCompany && openKeys.push('company');
        expandedFleet && openKeys.push('fleet');
        expandedFlight && openKeys.push('flights');
        expandedBooking && openKeys.push('bookings');
        expandedCommunity && openKeys.push('community');
        expandedMarketPlace && openKeys.push('marketplace');
    }
    return (
        <Menu mode="inline" defaultSelectedKeys={['1']} openKeys={openKeys}>
            <Menu.Item
                icon={
                    <MenuIconItem collapsed={collapsed} icon={dashboardIcon} />
                }
                key="dashboard"
                style={{
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    height: windowDimensions.width < 1024 ? '37px' : '48px'
                }}
                onClick={() => onNavigate(DASHBOARD)}
            >
                {!collapsed && (
                    <SideBarItemText title={t('DASHBOARD').toUpperCase()} />
                )}
            </Menu.Item>
            {!expandedCompany && <Divider style={dividerStyle} />}
            <CustomSubmenu
                key="company"
                expanded={expandedCompany.toString()}
                expandIcon={
                    !expandedCompany ? (
                        <PlusOutlined style={plusIconStyle} />
                    ) : (
                        <MinusOutlined style={plusIconStyle} />
                    )
                }
                onTitleClick={() => {
                    if (collapsed) {
                        setCollapsed(!collapsed);
                    }
                    setExpandedCompany(!expandedCompany);
                }}
                style={{ height: '100%' }}
                icon={<MenuIconItem collapsed={collapsed} icon={companyIcon} />}
                title={<SideBarItemText title={t('COMPANY').toUpperCase()} />}
            >
                <Menu.Item
                    key="COMPANY_PROFILE"
                    onClick={() => onNavigate(COMPANY_PROFILE)}
                >
                    {t('COMPANY_PROFILE')}
                </Menu.Item>
                <Menu.Item
                    key="CUSTOMERS"
                    onClick={() => onNavigate(CUSTOMER_LIST)}
                >
                    {t('CUSTOMERS')}
                </Menu.Item>
                <Menu.Item
                    key="EMPLOYEES"
                    onClick={() => onNavigate(EMPLOYEES)}
                >
                    {t('EMPLOYEES')}
                </Menu.Item>
                <Menu.Item key="MEMBERSHIPS">{t('MEMBERSHIPS')}</Menu.Item>
                <Menu.Item
                    key="PARTNERS"
                    onClick={() => onNavigate(PARTNERS_LIST)}
                >
                    {t('PARTNERS')}
                </Menu.Item>
                <Menu.Item key="SERVCIES" onClick={() => onNavigate(SERVICES)}>
                    {t('SERVCIES')}
                </Menu.Item>
                <Menu.Item key="DISPUTES">{t('DISPUTES')}</Menu.Item>
                <Menu.Item key="ORDERS">{t('ORDERS')}</Menu.Item>
                <Menu.Item
                    key="INVOICES"
                    onClick={() => onNavigate(COMPANY_INVOICES)}
                >
                    {t('INVOICES')}
                </Menu.Item>
                <Menu.Item key="AVIAN_PAY">{t('AVIAN_PAY')}</Menu.Item>
                <Menu.Item key="SETTINGS" onClick={() => onNavigate(COMPANY_SETTING)} >{t('SETTINGS')}</Menu.Item>
            </CustomSubmenu>
            {!expandedFleet && <Divider style={dividerStyle} />}
            <CustomSubmenu
                key="fleet"
                expanded={expandedFleet.toString()}
                expandIcon={
                    !expandedFleet ? (
                        <PlusOutlined style={plusIconStyle} />
                    ) : (
                        <MinusOutlined style={plusIconStyle} />
                    )
                }
                onTitleClick={() => {
                    if (collapsed) {
                        setCollapsed(!collapsed);
                    }
                    setExpandedFleet(!expandedFleet);
                }}
                icon={<MenuIconItem collapsed={collapsed} icon={fleetIcon} />}
                title={<SideBarItemText title={t('FLEET').toUpperCase()} />}
            >
                <Menu.Item
                    key="YOUR_FLEET"
                    onClick={() => onNavigate(COMPANY_FLEET)}
                >
                    {t('COMPANY_FLEET')}
                </Menu.Item>
                <Menu.Item
                    key="PARTNERSHIP_FLEET"
                    onClick={() => onNavigate(PARTNERSHIP_FLEET)}
                >
                    {t('JETOS_PARTNERS')}
                </Menu.Item>
                <Menu.Item
                    key="BROKER_CONNECTIONS"
                    onClick={() => onNavigate(BROKER_CONNECTIONS)}
                >
                    {t('EXTERNAL_PARTNERS')}
                </Menu.Item>
            </CustomSubmenu>
            {!expandedFlight && <Divider style={dividerStyle} />}
            <CustomSubmenu
                key="flights"
                expanded={expandedFlight.toString()}
                expandIcon={
                    !expandedFlight ? (
                        <PlusOutlined style={plusIconStyle} />
                    ) : (
                        <MinusOutlined style={plusIconStyle} />
                    )
                }
                onTitleClick={() => {
                    if (collapsed) {
                        setCollapsed(!collapsed);
                    }
                    setExpandedFlight(!expandedFlight);
                }}
                icon={<MenuIconItem collapsed={collapsed} icon={flightsIcon} />}
                title={<SideBarItemText title={t('FLIGHTS').toUpperCase()} />}
            >
                <Menu.Item
                    key="FLIGHTS"
                    onClick={() => onNavigate(FLIGHT_LIST)}
                >
                    {t('FLIGHTS')}
                </Menu.Item>
                <Menu.Item key="SCHEDULED_FLIGHTS">
                    {t('SCHEDULED_FLIGHTS')}
                </Menu.Item>
                <Menu.Item
                    key="EMPTY_LEGS"
                    onClick={() => onNavigate(EMPTY_LEG)}
                >
                    {t('EMPTY_LEGS')}
                </Menu.Item>
                <Menu.Item
                    key="PROMOTIONS"
                    onClick={() => onNavigate(FLIGHT_PROMOTION_LIST)}
                >
                    {t('PROMOTIONS')}
                </Menu.Item>
            </CustomSubmenu>
            {!expandedBooking && <Divider style={dividerStyle} />}
            <CustomSubmenu
                key="bookings"
                expanded={expandedBooking.toString()}
                expandIcon={
                    !expandedBooking ? (
                        <PlusOutlined style={plusIconStyle} />
                    ) : (
                        <MinusOutlined style={plusIconStyle} />
                    )
                }
                onTitleClick={() => {
                    if (collapsed) {
                        setCollapsed(!collapsed);
                    }
                    setExpandedBooking(!expandedBooking);
                }}
                icon={
                    <MenuIconItem collapsed={collapsed} icon={bookingsIcon} />
                }
                title={<SideBarItemText title={t('BOOKINGS').toUpperCase()} />}
            >
                <Menu.Item key="9" onClick={() => onNavigate(BOOKING_LIST)}>
                    {t('BOOKING')}
                </Menu.Item>
                <Menu.Item key="10" onClick={() => onNavigate(QUOTE_REQUEST)}>
                    {t('QUOTE_REQUEST')}
                </Menu.Item>
                <Menu.Item key="11">{t('ITINERARIES')}</Menu.Item>
            </CustomSubmenu>
            <Divider style={dividerStyle} />
            <Menu.Item
                icon={<MenuIconItem collapsed={collapsed} icon={messageIcon} />}
                key="messages"
                style={{
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    height: windowDimensions.width < 1024 ? '37px' : '47px'
                }}
            >
                {!collapsed && (
                    <SideBarItemText title={t('MESSAGES').toUpperCase()} />
                )}
            </Menu.Item>
            {!expandedCommunity && <Divider style={dividerStyle} />}
            <CustomSubmenu
                key="community"
                expanded={expandedCommunity.toString()}
                expandIcon={
                    !expandedCommunity ? (
                        <PlusOutlined style={plusIconStyle} />
                    ) : (
                        <MinusOutlined style={plusIconStyle} />
                    )
                }
                onTitleClick={() => {
                    if (collapsed) {
                        setCollapsed(!collapsed);
                    }
                    setExpandedCommunity(!expandedCommunity);
                }}
                icon={
                    <MenuIconItem collapsed={collapsed} icon={communityIcon} />
                }
                title={<SideBarItemText title={t('COMMUNITY').toUpperCase()} />}
            >
                <Menu.Item key="13">{t('NEWSFEEDL')}</Menu.Item>
                <Menu.Item key="14">{t('JOB_POST')}</Menu.Item>
                <Menu.Item onClick={() => onNavigate(FBO_LIST)} key="15">
                    {t('FBO')}
                </Menu.Item>
            </CustomSubmenu>
            {!expandedMarketPlace && <Divider style={dividerStyle} />}
            <CustomSubmenu
                key="marketplace"
                expanded={expandedMarketPlace.toString()}
                expandIcon={
                    !expandedMarketPlace ? (
                        <PlusOutlined style={plusIconStyle} />
                    ) : (
                        <MinusOutlined style={plusIconStyle} />
                    )
                }
                onTitleClick={() => {
                    if (collapsed) {
                        setCollapsed(!collapsed);
                    }
                    setExpandedMarketPlace(!expandedMarketPlace);
                }}
                icon={
                    <MenuIconItem
                        collapsed={collapsed}
                        icon={marketPlaceIcon}
                    />
                }
                title={
                    <SideBarItemText title={t('MARKETPLACE').toUpperCase()} />
                }
            >
                <Menu.Item key="17">option1</Menu.Item>
                <Menu.Item key="18">option2</Menu.Item>
                <Menu.Item key="19">option3</Menu.Item>
                <Menu.Item key="20">option4</Menu.Item>
            </CustomSubmenu>
            <Divider style={dividerStyle} />
            <Menu.Item
                icon={<MenuIconItem collapsed={collapsed} icon={airportIcon} />}
                key="airports"
                style={{
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    height: '100%'
                }}
            >
                {!collapsed && (
                    <SideBarItemText title={t('AIRPORTS').toUpperCase()} />
                )}
            </Menu.Item>
        </Menu>
    );
};

const DrawerWrapper = styled(Layout)``;

const LayoutWrapper = styled(Layout)`
    .ant-drawer-body {
        padding: 0 !important;
    }
`;

const BubbleNotification = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: #cd3723;
    border-radius: 100%;
    width: 7px;
    height: 7px;
`;

const ReportAndImprovement = styled.div`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    line-height: 13px;
`;

const CopyRight = styled.div`
    color: #8e929a;
    font-family: AvenirNext-Medium;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    margin-top: 10px;
`;

const CustomSubmenu = styled(SubMenu)`
    background: ${(props) =>
        props.expanded === 'true' ? '#fafafa' : '#ffffff'};
`;

const dividerStyle = {
    borderBottom: '1px solid #121216',
    opacity: '0.05'
};

const plusIconStyle = {
    color: '#8E929A'
};

const SiderSection = styled.div`
    .ant-layout-sider-custom {
        ${(props) => (!props.collapsed ? 'max-width: 330px !important' : null)};
        ${(props) => (!props.collapsed ? 'min-width: 330px !important' : null)};
        ${(props) => (!props.collapsed ? 'width: 330px !important' : null)};
    }
    .ant-menu-item {
        margin: 0px;
    }
    .ant-menu-inline .ant-menu-item:not(:last-child) {
        margin-bottom: 0px;
    }
    .ant-menu-item-selected {
        background-color: #fbfbfd !important;
    }
    .ant-layout-footer {
        padding-left: 32px;
        padding-right: 32px;
        padding-top: 30px;
        padding-bottom: 15px;
    }
    .ant-menu-submenu-title {
        height: 100%;
    }
`;

const AddContactIcon = styled.img`
    display: block;
    margin: auto;
    width: auto;
    max-width: 80%;
    max-height: 80%;
`;

const AddContactSection = styled.button`
    width: 50px;
    height: 50px;
    background-color: Transparent;
    background-repeat: no-repeat;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    /* border: none; */
    border: 1px solid rgba(101, 104, 111, 0.28);
    border-radius: 4px;
    margin: 0 5px 0 5px;
`;

const SideBarText = styled.span`
    margin-left: 10px;
    color: #121216;
    font-family: AvenirNext-Medium;
    font-size: 11px;
    letter-spacing: 0.77px;
    line-height: 24px;
    width: 208px;
`;

const LogoCollapsed = styled.img`
    max-width: 50%;
    margin-left: 25px;
    height: auto;
`;

const Logo = styled.img`
    max-width: 50%;
    margin-left: 30px;
    height: auto;
`;

const WrapperLoading = styled.div`
    // custom background: transparent;
`;

const LogoSection = styled.div`
    background-image: linear-gradient(224.41deg, #19c0ff 0%, #008be0 100%);
    /* width: 330px; */
    height: 80px;
    display: flex;
    align-items: center;
`;

const ItemButton = styled.button`
    background-color: Transparent;
    background-repeat: no-repeat;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    border: none;
`;

const ItemName = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 24px;
    padding-left: 10px;
`;

const EachItem = styled.button`
    background-color: Transparent;
    background-repeat: no-repeat;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    border: none;
`;

const ItemAvatar = styled.img`
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
`;

const ActingAsText = styled.span`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    letter-spacing: 0.65px;
    line-height: 16px;
`;

const SwitchCompanySection = styled.div`
    background-color: #f7f8fb;
    width: 315px;
    min-height: 100px;
`;

const BoardSection = styled.div`
    height: auto;
    width: 315px;
    background-color: #ffffff;
    z-index: 10;

    .ant-menu-root.ant-menu-inline {
        box-shadow: 0 2px 3px 0px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 0%),
            0 9px 28px 8px rgb(0 0 0 / 2%);
    }
`;

const Name = styled.span`
    color: #35383f;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
`;

const Company = styled.span`
    color: #19c0ff;
    font-family: AvenirNext-DemiBold;
    font-size: 8px;
    letter-spacing: 0.88px;
    line-height: 10px;
`;

const NameAndCompanySection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10px;
    margin-right: 30px;
    margin-bottom: auto;
    margin-top: auto;
    /* @media (max-width: 768px) {
        margin-right: ${(props) => (props.expandedSidebar ? '0' : '30px')};
    } */
`;

const NameSection = styled.div`
    margin-bottom: 4px;
    width: 100%;
    text-align: left;
`;

const CompanySection = styled.div`
    margin-top: 4px;
    width: 100%;
    text-align: left;
`;

const PersonalInfoSection = styled.button`
    background-color: Transparent;
    background-repeat: no-repeat;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    border: none;
    display: flex;
    flex-direction: row;
    line-height: 0;
`;

const IconStyle = styled.img`
    // display: block;
    // margin: auto;
    // width: auto;
    // margin-left: 10px;
`;

const CollapseIcon = styled.img`
    display: block;
    margin: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
`;

const CollapseButton = styled.button`
    width: 50px;
    height: 50px;
    background-color: Transparent;
    background-repeat: no-repeat;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    border: 1px solid rgba(101, 104, 111, 0.28);
    border-radius: 4px;
`;

const CollapseButtonSection = styled.div`
    margin-top: auto;
    margin-bottom: auto;
    width: 50px;
    height: 50px;
    display: flex;
`;

const HeaderLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: min(40px, 4%);
`;

const HeaderRight = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding-right: 35px; */
`;

const HeaderSection = styled.div`
    .site-layout-background {
        background-color: #ffffff;
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.05);
        width: 100%;
        height: 80px;
        background-color: #ffffff;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;

export { template };
