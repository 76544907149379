import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { Header } from 'components';

import General from '../components/general';
import Identification from '../components/identification';
import Payment from '../components/payment';
import Preferences from '../components/preferences';
import { useSelector } from 'react-redux';
import { EditProfileContainer, ContainerWrapper } from '../styles';

const template = (props) => {
    const { t, i18n } = useTranslation();
    const { expandedSidebar } = useSelector(
        (state) => state.settingReducer || false
    );

    return (
        <EditProfileContainer expandedSidebar={expandedSidebar}>
            <Header
                title={i18n.t('PROFILE_SETTINGS')}
                description={i18n.t('SETTINGS')}
            />
            <ContainerWrapper>
                <General {...props} />
            </ContainerWrapper>
            <ContainerWrapper>
                <Identification {...props} />
            </ContainerWrapper>
            <ContainerWrapper>
                <Payment {...props} />
            </ContainerWrapper>
            <ContainerWrapper>
                <Preferences {...props} />
            </ContainerWrapper>
        </EditProfileContainer>
    );
};

export { template };
