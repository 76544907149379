import React from 'react';
import 'antd/dist/antd.css';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { waitingIcon, cancelIcon, confirmedIcon } from 'assets/images/icons';
import { INVOICE_STATUS } from 'redux/Constants';

const InvoiceStatus = ({ status }) => {
    const { t, i18n } = useTranslation();

    let section = null;
    if (status === INVOICE_STATUS.CANCELLED) {
        section = (
            <>
                <Icon src={cancelIcon} />
                <ItemText className="status">{i18n.t(status)}</ItemText>
            </>
        );
    }
    if (
        status === INVOICE_STATUS.AWAITING_PAYMENT ||
        status === INVOICE_STATUS.AWAITING_REFUND
    ) {
        section = (
            <>
                <Icon src={waitingIcon} />
                <ItemText className="status">{i18n.t(status)}</ItemText>
            </>
        );
    }
    if (status === INVOICE_STATUS.CREATED || status === INVOICE_STATUS.PAID) {
        section = (
            <>
                <Icon src={confirmedIcon} />
                <ItemText className="status">{i18n.t(status)}</ItemText>
            </>
        );
    }

    return <StatusContianer>{section}</StatusContianer>;
};

const StatusContianer = styled.div`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`;

const ItemText = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    line-height: 13px;
    width: 124px;
    text-align: left;
`;

const Icon = styled.img``;

export default InvoiceStatus;
