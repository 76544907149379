import React, { useState } from 'react';
import {
    Header,
    ButtonCustom,
    Text,
    SearchInput as SearchInputComponent,
    DropDownInput,
    Tag,
    Slider,
    AutoComplete,
    EmptyComponent
} from 'components';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import {
    filterIcon,
    iconWifi,
    airplaneSeat,
    bathroomSink,
    iconLavatory,
    sleep,
    iconCancel
} from 'assets/images/icons';
import { useTranslation } from 'react-i18next';
import { role, aircraftCategory, amenitiesList } from 'config/Constants';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import {
    PARTNERSHIP_FLEET_ADD_ARICRAFT,
    PARTNERSHIP_FLEET_AIRCRAFT_DETAIL
} from 'routes/main';
import AircraftDetailCardComponent from './components/AircraftDetailCard';
import {
    LoadMoreSection,
    ResultFound,
    TagSection,
    FeatureIcon,
    FeatureTitle,
    CreateQuoteTopLeft,
    CreateQuoteTitle,
    LeftSection,
    EachAmenities,
    FeaturedAmenitiesTitle,
    AircraftDetail,
    CreateQuoteTop,
    FeaturedAmenities,
    CreateQuoteSection,
    EachPlaneSection,
    ClearFilter,
    ClearFilterText,
    ApplyFilterButton,
    ByCategoryEachField,
    SliderField,
    ApplyFilter,
    HourlyRates,
    ByOperator,
    ByCategory,
    Container,
    SearchAndFilterSection,
    FilterDetailSection,
    FilterSection,
    SearchSection,
    SearchInput,
    SearchLabel,
    SearchInputSection,
    FilterButtonSection,
    FilterButton,
    FilterLabel,
    CancelIcon,
    AddNewAircraftSection
} from './style';
import { useSelector } from 'react-redux';
import { AircraftDetailComponent } from 'components/Commons';

const template = (props) => {
    const {
        searchInfo = [],
        aircraftList = [],
        filterAircraft,
        activeCompany = {},
        expandFilter = false,
        expandFilterHandler,
        onCategoryAdd,
        pickedCategory = [],
        onCloseTag,
        onAfterChangeSlider,
        marks = {},
        onSearchOperator,
        onSearch,
        hourRate,
        onClickApplyFilter,
        onSelectOperator,
        applyFilter = false,
        onClickClearFilter,
        pickedOperator,
        onClickLoadMore,
        currentPage,
        totalPage,
        loading = false,
        searching = false,
        searchText = '',
        navigate,
        isView,
        total = 0
    } = props;
    const { t, i18n } = useTranslation();

    const createCategoryOption = () => {
        let tempArr = [];
        let key;
        for (key in aircraftCategory) {
            tempArr.push({
                text: t(aircraftCategory[key]),
                value: aircraftCategory[key]
            });
        }
        return tempArr;
    };

    const _renderHeader = () => {
        return (
            <div style={{ marginBottom: '33px' }}>
                <Header
                    title={t('FLEET')}
                    description={t('PARTNERSHIP_FLEET')}
                />

                <SearchAndFilterSection>
                    <SearchSection>
                        <SearchInputSection>
                            <SearchLabel>
                                <Text textStyle={'fieldLabel'}>
                                    {t('SEARCH_KEYWORD').toUpperCase()}
                                </Text>
                            </SearchLabel>
                            <SearchInput>
                                <SearchInputComponent
                                    placeholder={t('SEARCH_BROKER_CONNECTIONS')}
                                    allowClear
                                    value={searchText}
                                    onSearch={(e) => onSearch(e)}
                                    width={'370px'}
                                    height={'44px'}
                                />
                            </SearchInput>
                        </SearchInputSection>
                        <FilterButtonSection>
                            <FilterLabel>
                                <Text textStyle={'fieldLabel'}>
                                    {t('FILTER').toUpperCase()}
                                </Text>
                            </FilterLabel>
                            <FilterButton onClick={expandFilterHandler}>
                                <img src={filterIcon} />
                            </FilterButton>
                        </FilterButtonSection>
                    </SearchSection>
                </SearchAndFilterSection>

                {/* Conditional based on filter button to open this section */}
                {expandFilter ? (
                    <FilterDetailSection>
                        <FilterSection>
                            <ByCategory>
                                <ByCategoryEachField>
                                    <Text textStyle={'heading6'}>
                                        {t('FILTER')}
                                    </Text>
                                </ByCategoryEachField>
                                {searchInfo.length >= 1 ? (
                                    <ByCategoryEachField
                                        style={{ maxWidth: 400 }}
                                    >
                                        {searchInfo.map((item, index) => {
                                            let label = '';
                                            switch (item.type) {
                                                case 'CATEGORY':
                                                    label = t(item.value);
                                                    break;
                                                case 'OPERATOR':
                                                    label = item.value.name;
                                                    break;
                                            }
                                            return (
                                                <TagSection key={index}>
                                                    <Tag
                                                        label={label}
                                                        closable
                                                        onClose={() =>
                                                            onCloseTag(item)
                                                        }
                                                    />
                                                </TagSection>
                                            );
                                        })}
                                    </ByCategoryEachField>
                                ) : null}
                                <ByCategoryEachField>
                                    <Text textStyle={'fieldLabel'}>
                                        {t('BY_CATEGORY').toUpperCase()}
                                    </Text>
                                </ByCategoryEachField>
                                <ByCategoryEachField>
                                    <DropDownInput
                                        placeholder={t(
                                            'SEARCH_AIRCRAFTS_AND_TAIL_NUMBER'
                                        )}
                                        data={createCategoryOption()}
                                        onChange={onCategoryAdd}
                                        pickedData={pickedCategory}
                                    />
                                </ByCategoryEachField>
                            </ByCategory>
                            <ByOperator>
                                <ByCategoryEachField>
                                    <Text textStyle={'fieldLabel'}>
                                        {t('BY_OPERATOR').toUpperCase()}
                                    </Text>
                                </ByCategoryEachField>
                                <ByCategoryEachField>
                                    <AutoComplete
                                        onSearchItems={onSearchOperator}
                                        onSelectItem={onSelectOperator}
                                        // defaultValue={pickedOperator}
                                    />
                                </ByCategoryEachField>
                            </ByOperator>
                            <HourlyRates>
                                <ByCategoryEachField>
                                    <Text textStyle={'fieldLabel'}>
                                        {t('HOUR_RATES').toUpperCase()}
                                    </Text>
                                </ByCategoryEachField>
                                <SliderField>
                                    <Slider
                                        range
                                        marks={marks}
                                        defaultValue={hourRate}
                                        onAfterChange={onAfterChangeSlider}
                                    />
                                </SliderField>
                            </HourlyRates>
                            <ApplyFilter>
                                <ClearFilter onClick={onClickClearFilter}>
                                    <ClearFilterText>
                                        {t('CLEAR_FILTER').toUpperCase()}
                                    </ClearFilterText>
                                    <CancelIcon src={iconCancel} />
                                </ClearFilter>
                                <ApplyFilterButton>
                                    <ButtonCustom
                                        btnType="primary"
                                        width="190px"
                                        title={t('APPLY_FILTER').toUpperCase()}
                                        onClick={() => onClickApplyFilter()}
                                    />
                                </ApplyFilterButton>
                            </ApplyFilter>
                        </FilterSection>
                    </FilterDetailSection>
                ) : null}

                {applyFilter ? (
                    <ResultFound>
                        <Text textStyle="heading3">
                            {aircraftList.length +
                                ' ' +
                                t('RESULT_FOUND').toUpperCase()}
                        </Text>
                    </ResultFound>
                ) : null}
            </div>
        );
    };

    return (
        <Container
        // style={{
        //     paddingLeft: !isView ? '40px' : '0px',
        //     paddingRight: !isView ? '40px' : '0px'
        // }}
        >
            {/* render header */}
            {!isView && _renderHeader()}

            {!isView && (
                <AddNewAircraftSection>
                    <Text textStyle="heading5" style={{ fontSize: 20 }}>
                        {(t('COMPANY_FLEET') + ' (' + total + ')').toUpperCase()}
                    </Text>
                </AddNewAircraftSection>
            )}

            {/* map each card for each plane here */}
            {aircraftList && aircraftList.length > 0 ? (
                aircraftList.map((item, index) => {
                    return (
                        <AircraftDetailComponent
                            aircraft={item}
                            key={index}
                            navigate={navigate}
                            activeCompany={activeCompany}
                            detailPage={PARTNERSHIP_FLEET_AIRCRAFT_DETAIL}
                        />
                    );
                })
            ) : (
                <EmptyComponent />
            )}

            <LoadMoreSection>
                {currentPage < totalPage ? (
                    loading ? (
                        <LoadingOutlined
                            style={{ fontSize: '24px', color: '#19C0FF' }}
                        />
                    ) : (
                        <ButtonCustom
                            btnType="loadMore"
                            width="190px"
                            title={t('LOAD_MORE').toUpperCase()}
                            onClick={onClickLoadMore}
                        />
                    )
                ) : null}
            </LoadMoreSection>
        </Container>
    );
};

export { template };
