import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';

const NameCell = styled.span`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.8px;
    line-height: 15px;
    text-align: left;
    align-self: center;
`;

const EmailCell = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    width: 208px;
    text-align: left;
    align-self: center;
`;

const ItemCell = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 13px;
    text-align: left;
    align-self: center;
`;

const AirportCell = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 13px;
    line-height: 16px;
    text-align: left;
    align-self: center;
`;

const ColumnHeader = styled.span`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 11px;
    line-height: 13px;
`;

const TableSection = styled.div`
    margin-top: 20px;
    @media (max-width: 1024px) {
        .ant-table-pagination-right {
            justify-content: center;
        }
    }
`;

const Icon = styled.img`
    width: 18px;
    height: 18px;
`;

const ErrorSection = styled.span`
    color: #e2253d;
    font-family: AvenirNext-Medium;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    margin-top: 7px;
`;

const DropdownWrapper = styled.div`
    display: flex;
    justify-content: ${(props) => props.justify || 'space-between'};
`;

const Image = styled.img``;

const DropdownText = styled.span``;

export {
    NameCell,
    EmailCell,
    ItemCell,
    AirportCell,
    ColumnHeader,
    TableSection,
    Icon,
    ErrorSection,
    DropdownWrapper,
    DropdownText,
    Image
};
