import React, { useState, useEffect } from 'react';
import { template as tmpl } from './PartnerBookings.tmpl';
import { fetchBookings, searchBooking } from 'redux/actions/bookingAction';
import { connect } from 'react-redux';
import { bookingCategory } from 'config/Constants';
import { withRouter } from 'react-router-dom';

const BookingListLogic = (props) => {
    const { fetchBookings, searchBooking, activeCompany } = props;

    const [key, setKey] = useState(''); // key input search with flight number, ...
    const [searchBy, setSearchBy] = useState(bookingCategory.ALL); // default all
    const [sortBy, setSortBy] = useState('week'); // default all

    const [newList, setNewList] = useState([]);
    const [awaitingList, setAwaitingList] = useState([]);
    const [confirmedList, setConfirmedList] = useState([]);
    const [cancelledList, setCancelledList] = useState([]);
    const [searchList, setSearchList] = useState([]);

    const initBookings = async () => {
        onChangePageNewBooking({ current: 1, pageSize: 5 });
        onChangePageAwaiting({ current: 1, pageSize: 5 });
        onChangePageConfirmed({ current: 1, pageSize: 5 });
        onChangePageCancelled({ current: 1, pageSize: 5 });
    };

    /**
     * Get Booking list
     */
    useEffect(() => {
        initBookings();
    }, [activeCompany]);

    /**
     * Function handles searching when user is typing
     * @param {*} e event when user is typing
     */
    const onSearch = async (e) => {
        const input = e.target.value.toLowerCase();
        setKey(input);
        if (!input) return;

        let rs = await searchBooking(
            input,
            5,
            1,
            !searchBy || searchBy === bookingCategory.ALL
                ? undefined
                : searchBy.toLowerCase(),
            true
        );
        setSearchList(rs || []);
    };

    const navigate = (route, id, state) => {
        props.history.push({
            pathname: route,
            search: id,
            state: state
        });
    };

    const onChangePageNewBooking = async (pagination = {}) => {
        const { current, pageSize } = pagination;
        let newBookings = await fetchBookings('CREATED', pageSize, current, true);
        setNewList(newBookings);
    };

    const onChangePageAwaiting = async (pagination = {}) => {
        const { current, pageSize } = pagination;
        let awaitingBookings = await fetchBookings(
            'AWAITING_CONFIRMATION',
            pageSize,
            current,
            true
        );
        setAwaitingList(awaitingBookings);
    };

    const onChangePageConfirmed = async (pagination = {}) => {
        const { current, pageSize } = pagination;
        let confirmedBookings = await fetchBookings(
            'CONFIRMED',
            pageSize,
            current,
            true
        );
        setConfirmedList(confirmedBookings);
    };

    const onChangePageCancelled = async (pagination = {}) => {
        const { current, pageSize } = pagination;
        let cencelledBookings = await fetchBookings(
            'CANCELLED',
            pageSize,
            current,
            true
        );
        setCancelledList(cencelledBookings);
    };

    const onChangePageSearch = async (pagination = {}) => {
        const { current, pageSize } = pagination;
        let rs = await searchBooking(
            key,
            pageSize,
            current,
            !searchBy || searchBy === bookingCategory.ALL
                ? undefined
                : searchBy.toLowerCase()
        );
        setSearchList(rs || []);
    };

    const onSelectCategory = (value) => {
        setSearchBy(value);
    };

    const initProps = {
        newList: newList,
        awaitingList: awaitingList,
        confirmedList: confirmedList,
        cancelledList: cancelledList,
        searchList: searchList,
        onSearch: onSearch,
        navigate: navigate,
        onChangePageNewBooking: onChangePageNewBooking,
        onChangePageSearch: onChangePageSearch,
        onChangePageAwaiting: onChangePageAwaiting,
        onChangePageConfirmed: onChangePageConfirmed,
        onChangePageCancelled: onChangePageCancelled,
        searchBy: searchBy,
        key: key,
        sortBy: sortBy,
        onSelectCategory: onSelectCategory
    };

    return tmpl && tmpl({ ...initProps });
};

class BookingListContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <BookingListLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { customers, activeCompany } = companyReducer;
    return {
        customers: customers,
        activeCompany: activeCompany // TODO : fetch booking list on change company
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchBookings: (status, limit, page, partnerBooking) =>
            dispatch(fetchBookings(status, limit, page, partnerBooking)),
        searchBooking: (key, limit, page, status, partnerBooking) =>
            dispatch(searchBooking(key, limit, page, status, partnerBooking))
    };
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(BookingListContainer));
