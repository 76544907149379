import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { template as tmpl } from './ResetPassword.tmpl';
import {
    validateResetPasswordRequest,
    submitResetPassword
} from 'redux/actions/appActions';
import { FORGOT_PASSWORD, LOGIN } from 'routes/main';
import { withRouter } from 'react-router-dom';

const ResetPasswordLogic = (props) => {
    const dispatch = useDispatch();
    const { match = {} } = props;
    const { params = {} } = match;
    const { uid, activeId } = params;
    const [isValidLink, setIsValidLink] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorPassword, setErrorPassword] = useState('');
    const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('');

    const validateLink = async () => {
        const rs = await dispatch(validateResetPasswordRequest(uid, activeId));
        if (rs && rs.success) {
            setIsValidLink(true);
            if (rs.data.firstname || rs.data.lastname) {
                setName(rs.data.firstname + ' ' + rs.data.lastname);
            } else if (rs.data.email) {
                setName(rs.data.email);
            }
        } else {
            setIsValidLink(false);
        }
    };

    const validatePassword = (p) => {
        // let errors = '';
        if (p.length < 8) {
            return 'PASSWORD_AT_LEAST_8_CHARACTERS';
        }
        if (p.search(/[a-z]/i) < 0) {
            return 'PASSWORD_AT_LEAST_CONTAIN_1_LETTER';
        }
        if (p.search(/[0-9]/) < 0) {
            return 'PASSWORD_AT_LEAST_CONTAIN_1_DIGIT';
        }
        return '';
    };

    useEffect(() => {
        validateLink();
        setLoading(false);
    }, []);

    // validate password rule
    useEffect(() => {
        if (validatePassword(password)) {
            setErrorPassword(validatePassword(password));
        } else {
            setErrorPassword('');
        }
    }, [password]);

    // validate confirmPassword is match with password
    // useEffect(() => {}, [confirmPassword]);

    const handleResetPassword = async () => {
        setLoading(true);
        if (password === confirmPassword) {
            const rs = await dispatch(
                submitResetPassword(uid, {
                    password,
                    avt: activeId
                })
            );
            setLoading(false);
            if (rs.success) {
                props.history.push(LOGIN);
            } else if (rs.error) {
                setIsValidLink(false);
            } else {
                setErrorPassword('ERROR_ACCOUNT_VALIDATION');
            }
            setErrorConfirmPassword('');
        } else {
            setErrorConfirmPassword('CONFIRM_PASSWORD_DOES_NOT_MATCH');
            setLoading(false);
        }
    };

    const handleBackToForgotPassword = () => {
        props.history.push(FORGOT_PASSWORD);
    };

    const initProps = {
        handleBackToForgotPassword: handleBackToForgotPassword,
        name: name,
        loading: loading,
        isValidLink: isValidLink,
        password: password,
        confirmPassword: confirmPassword,
        setPassword: setPassword,
        setConfirmPassword: setConfirmPassword,
        errorPassword: errorPassword,
        errorConfirmPassword: errorConfirmPassword,
        handleResetPassword: handleResetPassword
    };
    return tmpl && tmpl({ ...initProps });
};

class ResetPassword extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <ResetPasswordLogic {...initProps} />;
    }
}

export default withRouter(ResetPassword);
