import React from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { Select, Checkbox, DatePicker, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { Header, Popup, ButtonCustom } from 'components';
import { HeaderInfo } from 'components/Cards';
import { role as roleConstant } from 'config/Constants';
import { iconArrowDown } from 'assets/images/icons';
import {
    EmployeeDetailContainer,
    ContainerWrapper,
    BottomContainer,
    Label,
    SelectPlaceholder,
    FormSection,
    ChooseRoleDropDown,
    ChooseAdmin,
    CheckboxSection,
    BufferChooseAdmin,
    FirstNameSection,
    LabelText,
    LastNameSection
} from './AddEmployeeComponentStyle';
import { EMPLOYEES } from 'routes/main';
import { EMAIL_EXISTS } from 'redux/Constants';
import moment from 'moment';
import { useSelector } from 'react-redux';

const { Option } = Select;

const template = (props) => {
    const { expandedSidebar } = useSelector(
        (state) => state.settingReducer || false
    );
    const { t, i18n } = useTranslation();
    const {
        successMsg,
        firstName = '',
        lastName = '',
        email = '',
        phone = '',
        dateOfBirth,
        role,
        buttonValid,
        visibleModal,
        error,
        isAdminIncluded = false,
        setIsAdminIncluded
    } = props;

    return (
        <Wrapper expandedSidebar={expandedSidebar}>
            <EmployeeDetailContainer>
                <Header
                    title={t('EMPLOYEES')}
                    description={t('VIEW_EMPLOYEES_LIST')}
                />
            </EmployeeDetailContainer>
            <ContainerWrapper>
                <HeaderInfo title={t('EMPLOYEE_INFORMATION').toUpperCase()} />
                <FormSection>
                    <ChooseRoleSection>
                        <ChooseRoleDropDown>
                            <Label>{t('ROLE').toUpperCase()}</Label>
                            <Select
                                suffixIcon={
                                    <DropDownIcon src={iconArrowDown} />
                                }
                                className="ant-select-custom"
                                value={role || null}
                                style={{ height: '50px', border: 'none' }}
                                placeholder={
                                    <SelectPlaceholder>
                                        {t('CHOOSE_A_ROLE')}
                                    </SelectPlaceholder>
                                }
                                onChange={(value) => {
                                    props.setRole(value);
                                }}
                            >
                                <Option value={roleConstant.ADMIN}>
                                    {t(roleConstant.ADMIN)}
                                </Option>
                                <Option value={roleConstant.OPERATIONS}>
                                    {t(roleConstant.OPERATIONS)}
                                </Option>
                                <Option value={roleConstant.PILOT}>
                                    {t(roleConstant.PILOT)}
                                </Option>
                                <Option value={roleConstant.CREW}>
                                    {t(roleConstant.CREW)}
                                </Option>
                            </Select>
                        </ChooseRoleDropDown>
                        <ChooseAdmin>
                            <BufferChooseAdmin></BufferChooseAdmin>
                            <CheckboxSection>
                                <Checkbox
                                    checked={isAdminIncluded}
                                    onChange={(e) => {
                                        setIsAdminIncluded(e.target.checked)
                                    }}
                                >
                                    {t('ADMINISTRATION_CHECK').toUpperCase()}
                                </Checkbox>
                            </CheckboxSection>
                        </ChooseAdmin>
                    </ChooseRoleSection>
                    <ChooseRoleSection>
                        <FirstNameSection>
                            <LabelText>{t('NAME').toUpperCase()}</LabelText>
                            <Input
                                className="employee-input input-firstname"
                                placeholder={t('FIRST_NAME')}
                                value={firstName}
                                allowClear={true}
                                onChange={(e) => {
                                    props.setFirstName(e.target.value);
                                }}
                            />
                        </FirstNameSection>
                        <LastNameSection>
                            <Input
                                className="employee-input input-lastname"
                                placeholder={t('LAST_NAME')}
                                value={lastName}
                                allowClear={true}
                                onChange={(e) => {
                                    props.setLastName(e.target.value);
                                }}
                            />
                        </LastNameSection>
                    </ChooseRoleSection>
                    <ChooseRoleSection>
                        <DateOfBirthWrapper expandedSidebar={expandedSidebar}>
                            <LabelText>
                                {t('DATE_OF_BIRTH').toUpperCase()}
                            </LabelText>
                            <DatePicker
                                placeholder={t('DATE_FORMAT')}
                                style={{ height: 50 }}
                                value={
                                    dateOfBirth ? moment.utc(dateOfBirth) : null
                                }
                                onChange={(date, dateString) => {
                                    props.setDateOfBirth(dateString);
                                }}
                            />
                        </DateOfBirthWrapper>
                    </ChooseRoleSection>
                    <ChooseRoleSection expandedSidebar={expandedSidebar}>
                        <FirstNameSection>
                            <LabelText>
                                {t('EMAIL_ADDRESS').toUpperCase()}
                            </LabelText>
                            <Input
                                className="employee-input"
                                placeholder={t('ENTER_EMAIL')}
                                value={email}
                                allowClear={true}
                                onChange={(e) => {
                                    props.setEmail(e.target.value);
                                }}
                            />
                            {error.includes(EMAIL_EXISTS) && (
                                <ErrorSection>{t(EMAIL_EXISTS)}</ErrorSection>
                            )}
                        </FirstNameSection>

                        <FirstNameSection
                            className="wrapper-input-phone-number"
                            style={{ paddingRight: 0 }}
                        >
                            <LabelText>
                                {t('PHONE_NUMBER').toUpperCase()}
                            </LabelText>
                            <Input
                                className="employee-input"
                                value={phone}
                                allowClear={true}
                                onChange={(e) => {
                                    props.setPhone(e.target.value);
                                }}
                            />
                        </FirstNameSection>
                    </ChooseRoleSection>
                </FormSection>
            </ContainerWrapper>
            <BottomContainer>
                <ButtonCustom
                    btnType="primary"
                    size="large"
                    disabled={!buttonValid}
                    width={'240px'}
                    title={t('SUBMIT_EMPLOYEE_INFO').toUpperCase()}
                    onClick={() => props.submit()}
                />
            </BottomContainer>
            <Popup
                cancelModal={() => {
                    props.cancelModal();
                }}
                onOk={() => {
                    props.navigate(EMPLOYEES);
                }}
                visibleModal={visibleModal}
                successText={successMsg}
                buttonTitle={t('VIEW_EMPLOYEES_LIST').toUpperCase()}
            />
        </Wrapper>
    );
};

const DropDownIcon = styled.img`
    height: 15px;
    width: 15px;
`;

const Wrapper = styled.div`
    @media (max-width: 1024px) {
        .wrapper-input-phone-number {
            margin-top: ${(props) => (props.expandedSidebar ? '20px' : '0')};
        }
    }
    @media (max-width: 768px) {
        .wrapper-input-phone-number {
            margin-top: 0;
        }
    }
`;

const ChooseRoleSection = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 20px;
    width: 100%;
    max-width: 790px;
`;

const DateOfBirthWrapper = styled.div`
    display: flex;
    flex-direction: column;
    /* width: 41.2vw;
    max-width: 760px; */
    width: 59vw;
    max-width: 770px;
    min-width: 570px;
    /* @media (max-width: 1366px) {
        width: 620px;
    }
    @media (max-width: 1024px) {
        width: ${(props) => (props.expandedSidebar ? '300px' : '620px')};
    } */
`;

const PhoneNumberSection = styled.div`
    @media (max-width: 1024px) {
        padding-top: ${(props) => (props.expandedSidebar ? '20px' : '0')};
    }
`;

const ErrorSection = styled.span`
    color: #e2253d;
    font-family: AvenirNext-Medium;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    margin-top: 7px;
`;

export { template };
