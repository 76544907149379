import React, { useState, useEffect } from 'react';
import { template as tmpl } from './CompanyInvoiceDetail.tmpl';
import { getInvoiceDetail, markAsPaidInvoice } from 'redux/actions/companyAction';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PAYMENT_STATUS, SHOW_LOADING, HIDE_LOADING } from 'redux/Constants';
import { message } from 'antd';

const InvoiceDetailLogic = (props) => {
    const { location = {}, getInvoiceDetail, markAsPaidInvoice } = props;
    const dispatch = useDispatch();
    const { search } = location;

    const [invoiceDetail, setInvoiceDetail] = useState({});

    /**
     * Get booking detail from backend
     */
    useEffect(() => {
        async function fetchInvoiceDetail() {
            dispatch({ type: SHOW_LOADING });

            let id = search;
            if (id && id[0] === '?') {
                id = id.substring(1);
            }

            const result = await getInvoiceDetail(id);
            if (result.success) {
                setInvoiceDetail(result.data || {});
            }

            setTimeout(() => {
                dispatch({ type: HIDE_LOADING });
            }, 450);
        }
        fetchInvoiceDetail();
    }, [props.location.search]);

    const navigate = (route, id, state) => {
        props.history.push({
            pathname: route,
            search: id,
            state: state
        });
    };

    const onSubmitMarkAsPaid = async () => {
        let rs = await markAsPaidInvoice(invoiceDetail?.id);
        if (rs && rs.success) {
            message.success('Mark as paid successfully!');
            setInvoiceDetail({ ...invoiceDetail, status: PAYMENT_STATUS.PAID });
        } else {
            // show message error
        }
    };

    const initProps = {
        invoiceDetail: invoiceDetail,
        navigate: navigate,
        onSubmitMarkAsPaid: onSubmitMarkAsPaid
    };

    return tmpl && tmpl({ ...initProps });
};

class InvoiceInvoiceContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <InvoiceDetailLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { customers, activeCompany } = companyReducer;
    return {
        customers: customers,
        activeCompany: activeCompany // TODO : fetch booking list on change company
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getInvoiceDetail: (id) => dispatch(getInvoiceDetail(id)),
        markAsPaidInvoice: (id) => dispatch(markAsPaidInvoice(id))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(InvoiceInvoiceContainer)
);
