import React, { useState } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { Input, Modal } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { ButtonCustom } from 'components';

const InputPasswordModal = ({
    title = '',
    show = false,
    onOk,
    onCancel,
    onSubmit,
    error
}) => {
    const { t, i18n } = useTranslation();
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const onPressSubmit = () => {
        if (loading) return;
        
        setLoading(true);
        onSubmit(password);

        setTimeout(() => {
            setLoading(false);
        }, 300);
    };

    return (
        <Modal
            centered
            className="ant-modal-custom"
            visible={show}
            onOk={() => onOk()}
            onCancel={() => onCancel(false)}
            footer={null}
            style={{
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'center'
            }}
        >
            <ModalContainer>
                <Title>{title || i18n.t('ENTER_PASSWORD').toUpperCase()}</Title>
                <InputWrapper>
                    <Input.Password
                        style={{ height: 50, width: 370 }}
                        autoComplete="new-password"
                        autoFocus={true}
                        enterKeyHint="enter"
                        onPressEnter={(e) => onPressSubmit()}
                        placeholder={i18n.t('ENTER_PASSWORD')}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                    />
                </InputWrapper>
                {(error && !loading) && <ErrorText>{t(error)}</ErrorText>}

                <div style={{ marginTop: 10 }}>
                    <ButtonCustom
                        btnType="primary"
                        width="190px"
                        onClick={() => onPressSubmit()}
                        disabled={!password}
                        title={i18n.t('SUBMIT').toUpperCase()}
                    />
                </div>
            </ModalContainer>
        </Modal>
    );
};

export default InputPasswordModal;

const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const InputWrapper = styled.div`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    margin: 25px;
`;

const Title = styled.div`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 32px;
    text-align: center;
`;

const ErrorText = styled.span`
    padding-top: 5px;
    color: #f42020;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
`;
