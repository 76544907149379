import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Input, AutoComplete } from 'antd';

/**
 *
 * @param {string} label - optional
 * @param {function} onSelect - required - select value
 * @param {function} handleSearch -
 * @param {object} inputStyle -
 * @param {string} inputSearch -
 * @param {string} placeholder -
 * @returns {JSX}
 */
const template = (props) => {
    const { t, i18n } = useTranslation();
    const {
        label = '',
        inputStyle,
        options,
        inputSearch,
        placeholder = '',
        onSelect,
        handleSearch
    } = props;

    return (
        <>
            {label && <Label>{label}</Label>}
            <AutoComplete
                dropdownMatchSelectWidth={252}
                options={options}
                onSelect={onSelect}
                onSearch={handleSearch}
                value={inputSearch}
                allowClear
            >
                <Input
                    style={inputStyle ? inputStyle : styles} // default styles
                    placeholder={placeholder}
                    className="custom-auto-complete"
                />
            </AutoComplete>
        </>
    );
};

const styles = {
    width: 370,
    height: 50
};

const Label = styled.div`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 12px;
    margin-left: ${(props) => (props.rightInput ? '30px' : '0px')};
`;

export { template };
