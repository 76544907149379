import React, { useState } from 'react';
import { template as tmpl } from './LoginContainer.tmpl';
import { Link } from 'react-router-dom';

const LoginContainerLogic = (props) => {
    const initProps = props;
    const [isRequestAccount, setIsRequestAccount] = useState(false);
    return (
        tmpl && tmpl({ ...initProps, isRequestAccount, setIsRequestAccount })
    );
};

class LoginContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            title: 'Login',
            ...this.props
        };
        return <LoginContainerLogic {...initProps} />;
    }
}

export { LoginContainer };
