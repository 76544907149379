import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { Row, Col } from 'antd';
import {
    Text,
    EmptyLegFlightDetailStatusCard,
    ServiceItem,
    FlightNotes,
    Map,
    Map2,
    TotalPayout,
    EmptyComponent,
    PrimaryButton,
    SecondaryButton,
    PassengersInfo,
    ConnectFlight,
    CrewInfo,
    AircraftInfo,
    Tabs
} from 'components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BOOKING_INVOICE } from 'routes/main';

const template = (props) => {
    const { t, i18n } = useTranslation();
    const [listOfAirports, setListOfAirports] = useState([]);

    const {
        flightDetail = {},
        addCrew,
        removeCrew,
        onAddFBO,
        navigate,
        onConfirmBooking
    } = props;
    const { booking = {}, from_airport = {}, to_airport = {} } = flightDetail;
    const {
        id = '',
        user = {},
        status = '',
        operator = {},
        booking_code = '',
        payment_status = '',
        quote_request_id = '',
        quote_request_code = ''
    } = booking;
    const { firstname = '', lastname = '' } = user;

    useEffect(() => {
        const { from_airport = {}, to_airport = {} } = flightDetail;

        const listPositions = [
            {
                from_airport: from_airport,
                to_airport: to_airport
            }
        ];
        setListOfAirports(listPositions);
    }, [flightDetail]);

    const FilghtInfo = (leg_info) => {
        const {
            from_airport = {},
            to_airport = {},
            services = [],
            price_components,
            passenger_list = [],
            luggage = [],
            passengers = 0,
            flight = {},
            flight_id = ''
        } = leg_info;
        const {
            aircraft = {},
            crew = [],
            arrival = {},
            departure = {},
            distance = {},
            flight_code,
            flight_time_minutes,
            notes = []
        } = flight;

        const getServicesByType = (type = '') => {
            let list = services.filter((item) => item.type === type);
            return list;
        };

        return (
            <>
                <FlightInfoWrapper>
                    {/* flight info */}
                    <FlightStatusCardContainer>
                        <EmptyLegFlightDetailStatusCard
                            bookingStatus={status} // booking status
                            flightId={flight_id}
                            flightType={
                                flightDetail.type
                                    ? t(flightDetail.type).toUpperCase()
                                    : ''
                            }
                            requestCode={flightDetail.request_code || ''}
                            status={flightDetail.status || ''}
                            reqeustSendBy={firstname + ' ' + lastname}
                            from_airport={from_airport}
                            to_airport={to_airport}
                            onAddFBO={onAddFBO}
                            arrival={arrival}
                            departure={departure}
                            aircraft={aircraft}
                            operator={operator}
                            booking_code={booking_code}
                            payment_status={payment_status}
                            flightTimeMinutes={flight_time_minutes}
                            quoteRequestId={quote_request_id}
                            quoteRequestCode={quote_request_code}
                            navigate={navigate}
                        />
                    </FlightStatusCardContainer>

                    {/* Crew Info and Aircraft Information */}
                    <Row style={{ marginTop: 40 }}>
                        <EachCol style={{ flex: 1 / 3, marginBottom: 40 }}>
                            <CrewInfo
                                flight_id={flight_id}
                                addCrew={addCrew}
                                removeCrew={removeCrew}
                                crew={crew}
                                label={t('CREW_INFO').toUpperCase()}
                                bookingStatus={status}
                            />
                        </EachCol>
                        <EachCol style={{ flex: 2 / 3, marginBottom: 40 }}>
                            <AircraftInfo
                                aircraft={aircraft}
                                label={t('AIRCRAFT_INFO').toUpperCase()}
                                navigate={navigate}
                            />
                        </EachCol>
                    </Row>

                    {/* Service items / Special Request / Flight Notes */}
                    <Row style={{ marginTop: 40 }}>
                        <EachCol style={{ marginRight: 10, marginBottom: 40 }}>
                            <ServiceItem
                                services={getServicesByType('STANDARD')}
                                label={t('SERVICE_ITEMS').toUpperCase()}
                            />
                        </EachCol>
                        <EachCol
                            style={{
                                marginLeft: 10,
                                marginRight: 10,
                                marginBottom: 40
                            }}
                        >
                            <ServiceItem
                                services={getServicesByType('CUSTOM')}
                                label={t('CUSTOM_SERVICE').toUpperCase()}
                            />
                        </EachCol>
                        <EachCol style={{ marginLeft: 10 }}>
                            <FlightNotes
                                id={flight_id}
                                notes={notes}
                                label={t('FLIGHT_NOTES').toUpperCase()}
                                type="flight"
                            />
                        </EachCol>
                    </Row>

                    {/* Passengers */}
                    <Row style={{ marginTop: 40 }}>
                        <Container style={{ width: '100%' }}>
                            <Text textStyle="heading5">
                                {t('PASSENGERS_INFO').toUpperCase()}
                            </Text>
                            {/* this is an empty leg, so no customer and luggages information */}
                            <PassengersInfo
                                passengers={[]}
                                passengerAmount={0}
                                luggage={[]}
                                bookingStatus={status}
                            />
                        </Container>
                    </Row>
                </FlightInfoWrapper>

                <FlightInfoWrapper>
                    <Row style={{ marginTop: 20 }}>
                        <Text textStyle="heading5">
                            {t('SALE_INFO').toUpperCase()}
                        </Text>
                        <EmptyComponent
                            description={t('NO_SALE_INFO').toUpperCase()}
                            iconSize={28}
                        />
                    </Row>
                </FlightInfoWrapper>

                <FlightInfoWrapper>
                    <TotalPayout price_components={{}} />
                </FlightInfoWrapper>
            </>
        );
    };

    const renderReturn = (legs) => {
        const tabRenderer = [
            {
                key: 'DEPARTURE',
                label: t('DEPARTURE').toUpperCase(),
                leg: legs[0],
                render: FilghtInfo(legs[0])
            },
            {
                key: 'RETURN',
                label: t('RETURN').toUpperCase(),
                leg: legs[1],
                render: FilghtInfo(legs[1])
            }
        ];
        return <Tabs tabRenderer={tabRenderer} />;
    };

    const renderMultiLegs = (legs) => {
        const tabRenderer = legs.map((leg, index) => {
            return {
                key: 'LEG_' + index,
                label: 'LEG ' + (index + 1),
                leg: leg,
                render: FilghtInfo(leg)
            };
        });
        return <Tabs tabRenderer={tabRenderer} />;
    };

    const renderContent = () => {
        const { booking = {} } = flightDetail;
        const { legs = [] } = booking;

        switch (booking.type) {
            case 'ONEWAY':
                return FilghtInfo(legs[0]);
            case 'RETURN':
                return renderReturn(legs);
            case 'MULTI_LEG':
                return renderMultiLegs(legs);
            default:
                return;
        }
    };
    return (
        <Container>
            {/* Map */}
            {/* <Map from_airport={from_airport} to_airport={to_airport} /> */}
            <Map2
                listOfAirports={listOfAirports}
                type={flightDetail.booking?.type || 'ONEWAY'}
            />

            {/* Card Flight Info */}
            <div style={{ paddingLeft: 40, paddingRight: 40, paddingTop: 40 }}>
                {renderContent()}
            </div>

            <Wrapper>
                <Wrappper>
                    {status === 'AWAITING_CONFIRMATION' && (
                        <div style={{ marginRight: 25 }}>
                            <PrimaryButton
                                onClick={() =>
                                    onConfirmBooking(id, new Date(), false)
                                }
                                size="large"
                                title={t('CONFIRM_BOOKING').toUpperCase()}
                            />
                        </div>
                    )}
                    {/* <SecondaryButton
                        onClick={() => navigate(BOOKING_INVOICE, id)}
                        size="large"
                        title={t('VIEW_INVOICE').toUpperCase()}
                    /> */}
                </Wrappper>
            </Wrapper>
        </Container>
    );
};

export { template };

const Container = styled.div``;

const Wrappper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
`;

const EachCol = styled(Col)`
    padding-right: 10px;
    flex: 1;
`;

const FlightStatusCardContainer = styled.div`
    padding-top: 20px;
`;

const FlightInfoWrapper = styled.div``;

const Wrapper = styled.div`
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
`;
