import React from 'react';
import styled from 'styled-components';
import { Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { uploadIcon } from 'assets/images/icons';
import { getBase64 } from 'utils';

/**
 *
 */
const UploadAvatar = (props) => {
    const { loading = false, imageUrl = '', setImageUrl, setPhoto } = props;
    const { t, i18n } = useTranslation();

    const handleChange = ({ file, fileList }) => {
        setPhoto(file);

        const index = fileList.length;
        getBase64(fileList[index - 1].originFileObj, (imageUrl) => {
            setImageUrl(imageUrl);
        });
    };

    const uploadButton = (
        <UploadSection>
            {loading ? (
                <UploadIcon src={uploadIcon} />
            ) : (
                <UploadIcon src={uploadIcon} />
            )}
            <UploadText style={{ marginTop: 8 }}>
                {t('UPLOAD_PHOTO').toUpperCase()}
            </UploadText>
        </UploadSection>
    );

    return (
        <Upload
            className="upload-avatar-section"
            listType="picture-card"
            showUploadList={false}
            beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
            onChange={handleChange}
            multiple={false}
        >
            {imageUrl ? (
                <Image src={imageUrl} className="upload-avatar-section" />
            ) : (
                uploadButton
            )}
        </Upload>
    );
};

const UploadIcon = styled.img`
    width: 18px;
    height: 16px;
`;

const UploadText = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.4px;
    line-height: 13px;
    text-align: left;
`;

const UploadSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
`;

const Image = styled.img``;

export default UploadAvatar;
