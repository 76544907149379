import React from 'react';
import './icon.css';

const PendingIcon = () => {
    return (
        <span className="pending-icon">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 24 24"
            >
                <title>ic_sync_24px</title>
                <g className="nc-icon-wrapper" fill="#fff">
                    <path d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46C19.54 15.03 20 13.57 20 12c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74C4.46 8.97 4 10.43 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3z" />
                </g>
            </svg>
        </span>
    );
};
export default PendingIcon;
