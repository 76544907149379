import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import {
    removeIcon,
    checkIcon,
    uncheckIcon,
    iconArrowDown
} from 'assets/images/icons';

import { Header, Text, ButtonCustom } from 'components';
import { getPaymentStatus } from 'utils';
import BookingInvoiceCard from './BookingInvoiceCard';
import { PaymentStatus } from 'components/Commons';
import moment from 'moment';

const VisibleAddressee = ({ visible = false, onChangeType }) => {
    const { t, i18n } = useTranslation();

    let icon = !visible ? uncheckIcon : checkIcon;
    let title = !visible ? t('NON_VISIBLE') : t('VISIBLE');
    return (
        <VisibleWrap
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignSelf: 'center',
                alignItems: 'center'
            }}
        >
            <div style={{ padding: 5 }}>
                <Icon style={{ width: 16, height: 16 }} src={icon} />
                <VisibleText color={!visible ? '#DD4558' : '#8DD64F'}>
                    {title}
                </VisibleText>
            </div>
            <div>
                <Icon
                    style={{
                        width: 20,
                        height: 20,
                        right: 5
                    }}
                    src={iconArrowDown}
                />
            </div>
        </VisibleWrap>
    );
};

const BookingCancelTime = ({ legs = [] }) => {
    const { t, i18n } = useTranslation();

    const [timeLeft, setTimeLeft] = useState(-1);

    useEffect(() => {
        try {
            if (!_.isEmpty(legs)) {
                let leg = legs[0] || {};
                let departure = leg.departure || {};
                const then = moment.utc(departure.utc_datetime).valueOf();
                const now = moment.utc().valueOf();
                const time = then - now - 1800; // 30 minutes before departure

                if (time > 0) {
                    setTimeLeft(time);
                }
            }
        } catch (e) {}
    }, [legs]);

    useEffect(() => {
        if (timeLeft > 0) {
            setTimeout(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);
        }
    });

    const getTimer = () => {
        return {
            days: Math.floor(timeLeft / (1000 * 60 * 60 * 24)),
            hours: Math.floor((timeLeft / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((timeLeft / 1000 / 60) % 60),
            seconds: Math.floor((timeLeft / 1000) % 60)
        };
    };

    const _rednerTimer = () => {
        let timer = {};
        if (timeLeft > 0) {
            timer = getTimer();
        }
        return (
            <CanceledText size="10px">{`${
                timer.days ? timer.days : ''
            } DAYS : ${timer.hours || '00'} HRS : ${
                timer.minutes || '00'
            } MINS : ${timer.seconds || '00'} SECS`}</CanceledText>
        );
    };

    return (
        <>
            <CanceledWrap>
                <CanceledText size="10px" color="#121216">
                    {t('BOOKING_CANCEL_IN').toUpperCase()}
                </CanceledText>
            </CanceledWrap>
            <CanceledWrap background="rgba(255, 102, 121, 0.13)">
                {_rednerTimer()}
            </CanceledWrap>
        </>
    );
};

const template = (props) => {
    const { navigate, loading = false, bookingDetail = {} } = props;
    const {
        legs = [],
        price_components = {},
        booking_code = '',
        type = '',
        status = '',
        payment_status = '',
        user = {}
    } = bookingDetail;
    const { name = '', lastname = '', firstname = '' } = user;

    const { t, i18n } = useTranslation();

    const _renderBookingStatus = () => {
        return (
            <BookingStatusWrapper>
                <Col span={12}>
                    <Row style={{ justifyContent: 'flex-start' }}>
                        <Col style={{ marginRight: 40 }}>
                            <Text textStyle="tableText1">
                                {t('BOOKING_TYPE')}
                            </Text>
                            <div>
                                <Text
                                    textStyle="tableText2"
                                    style={{
                                        color: '#19C0FF',
                                        fontSize: '11px'
                                    }}
                                >
                                    {type ? t(type).toUpperCase() : '_'}
                                </Text>
                            </div>
                        </Col>
                        <Col style={{ marginRight: 40 }}>
                            <Text textStyle="tableText1">
                                {t('BOOKING') + ' #'}
                            </Text>
                            <div>
                                <Text
                                    textStyle="tableText2"
                                    style={{
                                        fontSize: '11px'
                                    }}
                                >
                                    {booking_code
                                        ? booking_code.toUpperCase()
                                        : '_'}
                                </Text>
                            </div>
                        </Col>
                        <Col style={{ marginRight: 40 }}>
                            <Text textStyle="tableText1">{t('BOOKED_BY')}</Text>
                            <div>
                                <Text textStyle="heading5">{name}</Text>
                            </div>
                        </Col>
                        <Col style={{ marginRight: 40 }}>
                            <Text textStyle="tableText1">
                                {t('PAYMENT_STATUS')}
                            </Text>
                            <div>
                                <PaymentStatus
                                    status={getPaymentStatus(payment_status)}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={12} style={{ alignSelf: 'center' }}>
                    <Row
                        style={{
                            justifyContent: 'flex-end'
                        }}
                    >
                        {payment_status && payment_status !== 'PAID' && (
                            <BookingCancelTime legs={legs} />
                        )}
                    </Row>
                </Col>
            </BookingStatusWrapper>
        );
    };

    return (
        <Container>
            <Header
                title={`${t('BOOKINGS')} #${booking_code}`}
                description={t('INVOICE_DETAILS')}
            />

            {payment_status === 'PAID' && (
                <EditStatusWrap>
                    <StatusEditText>
                        {t('STATUS_INVOICE_PAYMENT_RECEIVED')}
                    </StatusEditText>
                    <Icon style={{ marginRight: 15 }} src={removeIcon} />
                </EditStatusWrap>
            )}

            {_renderBookingStatus()}

            {legs &&
                legs.map((leg, i) => {
                    return (
                        <BookingInvoiceCard
                            key={i}
                            leg={leg}
                            paymentStatus={payment_status}
                        />
                    );
                })}

            {/* TODO : Confirm Booking for Operater */}
            {/* <Wrapper style={{ marginTop: 40 }}>
                <Wrappper>
                    <ButtonCustom
                        btnType="primary"
                        size="large"
                        title={t('SEND_INVOICE').toUpperCase()}
                    />
                </Wrappper>
            </Wrapper> */}
        </Container>
    );
};

export { template };

const Icon = styled.img`
    margin-right: 5px;
`;

const BookingStatusWrapper = styled(Row)`
    background-color: #fcfcfe;
    border-radius: 0 4px 0 0;
    padding: 10px;
    padding-left: 20px;
    margin-top: 20px;
`;

const Container = styled.div`
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 20px;

    width: 100%;
    margin-right: 20px;
`;

const RequestStatus = styled.span`
    color: #f5a623;
    font-family: AvenirNext-Medium;
    font-size: 12px;
`;
const Wrapper = styled.div`
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
`;

const Wrappper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
`;

const VisibleWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-self: center;
    align-items: center;
    border: 1px solid #cdd1d8;
    border-radius: 2px;
    max-width: 124px;
    min-height: 32px;
`;

const EditStatusWrap = styled.div`
    border-left-style: solid;
    border-left-color: #8dd64f;
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 2px;
    width: 100%;
    min-height: 44px;
    margin-top: 10px;
    margin-bottom: 20px;
`;

const StatusEditText = styled.span`
    align-self: center;
    margin-left: 15px;
    color: #8dd64f;
    font-family: AvenirNext-DemiBold;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
`;

const CanceledWrap = styled.div`
    background-color: ${(props) => props.background || '#f8f9fe'};
    border-radius: ${(props) => props.borderRadius || '2px 0 0 2px'};
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    height: 33px;
`;

const CanceledText = styled.span`
    color: ${(props) => props.color || '#dd4558'};
    font-family: AvenirNext-DemiBold;
    font-size: ${(props) => props.size || '14px'};
    letter-spacing: 1px;
    line-height: 18px;
`;

const VisibleText = styled.span`
    color: ${(props) => props.color || '#121216'};
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    line-height: 13px;
    text-align: left;
`;
