import React, { useEffect, useState } from 'react';
import { template as tmpl } from './Popup.tmpl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const PopupLogic = (props) => {
    const {
        visibleModal,
        errorMessage,
        successText,
        buttonTitle,
        successSecondLine
    } = props;

    // const [visibleModal, setVisibleModal] = useState(false);
    // const [errorMessage, setErrorMessage] = useState('');

    const cancelModal = () => {
        if (props.cancelModal && typeof props.cancelModal === 'function') {
            props.cancelModal();
        }
    };

    const onOk = () => {
        if (props.onOk && typeof props.onOk === 'function') {
            props.onOk();
        }
    };

    const initProps = {
        visibleModal: visibleModal,
        errorMessage: errorMessage,
        buttonTitle: buttonTitle,
        successText: successText,
        successSecondLine: successSecondLine,
        cancelModal: cancelModal,
        onOk: onOk
    };
    return tmpl && tmpl({ ...initProps });
};

class Popup extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <PopupLogic {...initProps} />;
    }
}

export default Popup;
