import React from 'react';
import styled from 'styled-components';
import { GLOBAL_STYLE_CONSTANTS } from 'config/Constants';

const BackgroundProfile = styled.div`
    position: relative;
    background-image: url(${(props) => props.background});
    height: 40vh;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-wrap: wrap;
    .ant-row-center {
        padding-left: 3rem;
        padding-top: 1rem;
        padding-right: 3rem;
    }
    .overlay-background {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: ${(props) =>
            !props.edit ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.65)'};
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
`;

const ProfileInfo = styled.div`
    display: flex;
    margin-top: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-botttom: 75px;
    background-color: #ffffff;
    min-height: 65px;
    padding-left: 200px;
`;

const TabBarNavigator = styled.div`
    display: flex;
    align-items: center;
    margin: 40px min(40px, 4%);
    margin-top: 20px;

    .ant-tabs-tab {
        padding-bottom: 6px;
    }
    .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 0;
    }

    .ant-tabs-top > .ant-tabs-nav::before {
        border-color: #65686f;
        border-width: 2px;
        opacity: 0.2;
    }
`;

const AboutWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const AboutContent = styled.span`
    font-family: AvenirNext-DemiBold;
    background-color: #fff;
    font-size: 14px;
    line-height: 22px;
    padding: 20px;
    border-radius: 5px;
    margin-top: 10px;
`;

const AboutText = styled.div`
    color: #65686f;
    font-family: Lato;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
`;

const TimelineWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const ConnectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const EditButton = styled.button`
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 2px;
    width: 180px;
    height: 32px;
    margin-bottom: 25px;
    position: absolute;
    right: 25px;
    cursor: pointer;

    &:hover {
        background-color: #12afeb;
    }
`;

const MenuTitle = styled.div`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.7px;
    line-height: 16px;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
`;

const UploadButton = styled.button`
    border: 1px solid rgba(18, 18, 22, 0.2);
    border-radius: 2px;
    width: 242px;
    height: 32px;
    background-color: transparent;
    margin-top: 17px;
`;

const WrapperComponent = styled.div`
    display: flex;
    flex-direction: column;

    .ant-select-custom {
        cursor: pointer;
        width: 370px;
        height: 50px;
    }
    .ant-select-selection-item {
        align-self: center;
    }
    .ant-select-selection-placeholder {
        align-self: center;
    }
`;

const ButtonText = styled.span`
    color: #16161b;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: center;
`;

const TabText = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    margin-top: 15px;
    margin-left: 8px;
`;

const Title = styled.div`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    line-height: 13px;
    text-align: left;
`;

const TitleSection = styled.span`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 11px;
    line-height: 13px;
    text-align: left;
`;

const TitleDescription = styled.div`
    color: ${(props) => (props.color ? props.color : '#121216')};
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 18px;
    text-align: left;
`;

const EditText = styled.div`
    color: #ffffff;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: center;
`;

const ItemAvatar = styled.img`
    background-image: url(${(props) => props.url});
    background-size: 100%;
    border-radius: 100%;
    width: 152px;
    height: 152px;
    margin-left: 25px;
    margin-right: 25px;
`;

const Name = styled.span`
    color: ${(props) => (props.color ? props.color : '#ffffff')};
    font-family: AvenirNext-DemiBold;
    font-size: 20px;
    letter-spacing: 1.5px;
    line-height: 25px;
    text-align: left;
`;

const Comapy = styled.span`
    color: #19c0ff;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
`;

const AvatarWrapper = styled.div`
    height: 148px;
    position: absolute;
    bottom: -84px;
    left: 15px;
`;

const PhotoWrapper = styled.div`
    height: 321;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-self: center;
    width: 100%;
`;

const ExpandWrapper = styled.div`
    height: 321;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-self: center;
`;

const Photo = styled.img`
    background-image: url(${(props) => props.url});
    border-radius: 4px;
    width: 335px;
    height: 220px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const ExpandButton = styled.button`
    background-color: #fff;
    border: 1px solid rgba(18, 18, 22, 0.2);
    border-radius: 2px;
    width: 220px;
    height: 32px;
    margin: 25px;
`;

const ExpandText = styled.span`
    color: #16161b;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: center;
`;

const EditProfileContainer = styled.div`
    padding: ${GLOBAL_STYLE_CONSTANTS.padding};
    .address {
        /* border: 1px solid rgba(36, 36, 45, 0.15); */
        border-radius: 3px;
        width: 770px;
        height: 50px;
    }
    .base {
        /* border: 1px solid rgba(36, 36, 45, 0.15); */
        border-radius: 3px;
        width: 20vw;
        max-width: 370px;
        min-width: 370px;
        height: 50px;
        /* margin-bottom: 10px; */
    }
    .right {
        margin-left: 30px;
    }

    .left {
        margin-right: 30px;
    }

    .add-customer-item {
        margin-left: 50px;
        margin-top: 5px;
    }

    @media (max-width: 1024px) {
        .address {
            width: ${(props) => (props.expandedSidebar ? '370px' : '770px')};
        }
    }
    @media (max-width: 768px) {
        .address {
            width: 370px;
        }
    }
`;

const ContainerWrapper = styled.div`
    background-color: #ffffff;
    border-radius: 4px;
`;

const Container = styled.div`
    margin: 15px;
`;

const Avatar = styled.img`
    background-image: url(${(props) => props.url});
    background-size: 100%;
    border-radius: 100%;
    width: 120px;
    height: 120px;
    margin-left: 35px;
`;

const Label = styled.div`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 15px;
    margin-left: ${(props) => (props.rightInput ? '30px' : '0px')};
`;

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 35px;
    padding-bottom: 45px;
`;

const UploadAvatar = styled.div`
    color: #ffffff;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: right;
    position: absolute;
    align-self: center;
`;

const SwitchWrapper = styled.div`
    flex-direction: row;
    margin: 5px;
    align-self: flex-start;
`;

const Text = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
`;

const StatusWrapper = styled.div`
    background-color: #f7f8fb;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 2px;
    width: 770px;
`;

const UploadText = styled.div`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
    margin: 15px;
`;

const Body = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
`;

const IdentificationCard = styled.img`
    background-image: url(${(props) => props.url});
    background-size: 100%;
    border-radius: 2px;
    width: 220px;
    height: 133px;
    margin-top: 15px;
`;

const Wrapper = styled.div`
    justify-content: space-around;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 25px;
    margin-top: 10px;
    margin-bottom: 10px;

    .label-detail {
        @media (max-width: 1830px) {
            width: 25%;
        }
        @media (max-width: 1600px) {
            width: 30%;
        }
        @media (max-width: 1366px) {
            width: 35%;
        }
        @media (max-width: 1024px) {
            width: 40%;
        }
        @media (max-width: 768px) {
            width: 45%;
        }
    }
`;

const InfoWrapper = styled.div`
    flex-direction: row;
    margin-top: 5px;
    margin-bottom: 10px;
    align-self: flex-start;
`;

const TextSection = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 18px;
    text-align: left;
    margin-left: 25px;
    margin-top: 5px;
`;

const TypeTitle = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 17px;
    text-align: left;
    margin-top: 12px;
`;

export {
    BackgroundProfile,
    ProfileInfo,
    TabBarNavigator,
    AboutWrapper,
    AboutContent,
    AboutText,
    TimelineWrapper,
    ConnectionWrapper,
    EditButton,
    MenuTitle,
    TabText,
    Title,
    TitleSection,
    TitleDescription,
    EditText,
    ItemAvatar,
    Name,
    Comapy,
    AvatarWrapper,
    PhotoWrapper,
    Photo,
    ExpandButton,
    ExpandText,
    EditProfileContainer,
    ContainerWrapper,
    Container,
    WrapperComponent,
    ButtonText,
    UploadButton,
    Avatar,
    Label,
    BottomContainer,
    UploadAvatar,
    SwitchWrapper,
    Text,
    StatusWrapper,
    UploadText,
    Body,
    IdentificationCard,
    Wrapper,
    InfoWrapper,
    TextSection,
    TypeTitle,
    ExpandWrapper
};
