import React from 'react';
import styled from 'styled-components';
import { Image } from 'antd';
import { GLOBAL_STYLE_CONSTANTS } from 'config/Constants';

const EditProfileContainer = styled.div`
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 40px;
`;

const ContainerWrapper = styled.div`
    background-color: #ffffff;
    border-radius: 4px;
`;

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 35px;
    padding-bottom: 45px;
`;

const Container = styled.div`
    margin: 0px;
`;

const Title = styled.div`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 11px;
    line-height: 13px;
    text-align: left;
`;

const TitleSection = styled.span`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 11px;
    line-height: 13px;
    text-align: left;
`;

const TitleDescription = styled.div`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 18px;
    text-align: left;
`;

const Label = styled.div`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 15px;
    margin-left: ${(props) => (props.rightInput ? '30px' : '0px')};
`;

const MenuTitle = styled.div`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.7px;
    line-height: 16px;
    text-align: left;
    margin-top: 15px;
`;

const WrapperComponent = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 25px;
`;

const CustomerDetailContainer = styled.div`
    /* padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 40px; */
    padding: ${GLOBAL_STYLE_CONSTANTS.padding};
`;

const NameSection = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    .ant-image-custom {
        max-width: 120px;
        background-size: 100%;
    }
`;

const RoleText = styled.span`
    color: #19c0ff;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
`;

const IcaoIataCode = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 13px;
    line-height: 16px;
    text-align: left;
`;

const ChatIconSection = styled.button`
    border: none;
    background-color: transparent;
`;

const ChatIcon = styled.img`
    display: block;
    margin: auto;
    border: 1px solid #d0d0d0;
    border-radius: 2px;
    width: 32px;
    height: 32px;
    padding: 5px;
`;

const DetailSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 5px;
`;

const ItemText = styled.span`
    color: #121216;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 17px;
    font-family: AvenirNext-DemiBold;
`;

const ColumnHeader = styled.span`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 11px;
    line-height: 13px;
`;

const TableSection = styled.div`
    margin-top: 20px;
`;

const SearchText = styled.span`
    color: #65686f;
    font-family: Lato;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
`;

const SearchSection = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ant-input {
        background-color: transparent;
    }
    .ant-input-affix-wrapper {
        border: 1px solid #dadadb;
        border-radius: 3px;
        width: 20vw;
        height: 40px;
        background-color: transparent;
    }
`;

const HeaderTitleText = styled.span`
    margin-left: 10px;
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 16px;
    line-height: 26px;
`;

const VerticalLine = styled.div`
    margin-top: auto;
    margin-bottom: auto;
    background-color: #19c0ff;
    width: 2px;
    height: 16px;
`;

const HeaderTitle = styled.div``;

const HeaderDescriptionText = styled.span`
    margin-left: 12px;
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 12px;
    line-height: 32px;
    text-align: left;
`;

const HeaderTagSection = styled.div`
    display: flex;
    flex-direction: row;
`;

const HeaderDescription = styled.div``;

const HeaderLeft = styled.div``;

const HeaderRight = styled.div`
    margin-top: auto;
    margin-bottom: auto;
`;

const EmployeesContainer = styled.div`
    padding: ${GLOBAL_STYLE_CONSTANTS.padding};
    min-height: 100vh;
`;

const StyledHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const DescriptionService = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
`;

const ItemDescription = styled.span`
    margin-top: 10px;
    max-width: 370px;
    width: 100%;
    color: #65686f;
    font-family: Lato;
    font-size: 13px;
    line-height: 23px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
`;

const ServiceImage = styled.div`
    max-width: 120px;
    width: 6.25vw;
`;

const AvailabilitySection = styled.div`
    .ant-switch-checked {
        background-color: #19c0ff;
    }
`;

const AvailableText = styled.span`
    color: ${(props) => (props.enable ? '#121216' : '#65686F')};
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    line-height: 13px;
    margin-left: 5px;
`;

const ItemTitle = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 20px;
    letter-spacing: 0.8px;
    line-height: 32px;
    width: 842px;
    text-align: left;
    margin-bottom: 25px;
`;

const ImageCustom = styled(Image)`
    height: 84px;
    width: 120px;
    object-fit: cover;
`;

export {
    AvailableText,
    AvailabilitySection,
    ServiceImage,
    ItemDescription,
    DescriptionService,
    EditProfileContainer,
    ContainerWrapper,
    BottomContainer,
    Container,
    Title,
    TitleSection,
    TitleDescription,
    Label,
    MenuTitle,
    WrapperComponent,
    CustomerDetailContainer,
    NameSection,
    RoleText,
    IcaoIataCode,
    ChatIconSection,
    ChatIcon,
    DetailSection,
    ItemText,
    ColumnHeader,
    TableSection,
    SearchText,
    SearchSection,
    HeaderTitleText,
    VerticalLine,
    HeaderTitle,
    HeaderDescriptionText,
    HeaderTagSection,
    HeaderDescription,
    HeaderLeft,
    HeaderRight,
    EmployeesContainer,
    StyledHeader,
    ItemTitle,
    ImageCustom
};
