import React from 'react';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import '../customer.css';
import {
    WrapperComponent,
    MenuTitle,
    ColumnHeader,
    MembershipText,
    ItemText,
    DetailSection
} from '../styles';
import styled from 'styled-components';
import { ButtonCustom, localeEmptyTable } from 'components';
import { formatTimeBooking, numberFormat } from 'utils';
import { jetIcon } from 'assets/images/icons';
import { BookingStatus } from 'components/Commons';
import { BOOKING_DETAIL } from 'routes/main';

const JetLine = () => {
    return (
        <>
            <Line2> ----- </Line2>
            <JetIcon src={jetIcon} />
            <Line2> ----- </Line2>
        </>
    );
};

const JetInfo = ({ leg = {} }) => {
    const { aircraft = {} } = leg;
    const { name = '', tail_number = '' } = aircraft;
    return (
        <JetContainer>
            <JetName>{name ? name.toUpperCase() : '_'}</JetName>
            <TailorCode>
                {tail_number ? tail_number.toUpperCase() : '_'}
            </TailorCode>
        </JetContainer>
    );
};

const FlightInfo = ({ leg = {} }) => {
    const { from_airport, to_airport } = leg;
    return (
        <JetContainer>
            <IataCode>{from_airport ? from_airport.icao_code : '_'}</IataCode>
            <JetLine />
            <IataCode>{to_airport ? to_airport.icao_code : '_'}</IataCode>
        </JetContainer>
    );
};

const Bookings = (props) => {
    const { docs, navigate } = props;
    const { t, i18n } = useTranslation();

    const columns = [
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('BOOKING') + ' #'}</ColumnHeader>;
            },
            width: 80,
            dataIndex: 'booking_code',
            key: 'booking_code',
            fixed: 'left',
            render: function eachItemBooking(booking_code) {
                return <BookingText>{booking_code}</BookingText>;
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('CREATED_AT')}</ColumnHeader>;
            },
            width: 120,
            dataIndex: 'created_at',
            key: 'created_at',
            render: function eachItemCreatedAt(created_at) {
                return (
                    <TimeText>
                        {created_at ? formatTimeBooking(created_at) : '_'}
                    </TimeText>
                );
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('TYPE')}</ColumnHeader>;
            },
            width: 60,
            dataIndex: 'type',
            key: 'type',
            render: function eachType(type) {
                return <MembershipText>{type}</MembershipText>;
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('FLIGHT_INFO')}</ColumnHeader>;
            },
            width: 180,
            dataIndex: 'legs',
            key: 'legs',
            render: function eachItemType(legs) {
                return <FlightInfo leg={legs[0]} />;
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('JET_INFO')}</ColumnHeader>;
            },
            width: 80,
            dataIndex: 'legs',
            key: 'legs',
            render: function eachItemJetInfo(legs) {
                return <JetInfo leg={legs[0]} />;
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('BOOKING_STATUS')}</ColumnHeader>;
            },
            width: 140,
            dataIndex: 'status',
            key: 'status',
            render: function eachItemStatus(status) {
                return <BookingStatus status={status} type="BOOKING" />;
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('PRICE')}</ColumnHeader>;
            },
            width: 90,
            dataIndex: 'price_components',
            key: 'price_components',
            render: function eachItemPrice(price) {
                return (
                    <ItemText>
                        {price ? numberFormat(price.total_inc_vat) : '_'}
                    </ItemText>
                );
            }
        },
        {
            key: 'action',
            width: 100,
            render: function setAction(val) {
                return (
                    <DetailSection>
                        <ButtonCustom
                            btnType="secondary"
                            size="small"
                            title={t('VIEW_DETAILS').toUpperCase()}
                            width="120px"
                            onClick={(e) => navigate(BOOKING_DETAIL, val._id)}
                        />
                    </DetailSection>
                );
            }
        }
    ];

    return (
        <WrapperComponent>
            <MenuTitle>{i18n.t('BOOKINGS').toUpperCase()}</MenuTitle>
            <Table
                scroll={{ x: 1200 }}
                columns={columns}
                dataSource={docs}
                pagination={{ showSizeChanger: false }}
                locale={localeEmptyTable()}
            />
        </WrapperComponent>
    );
};

export default Bookings;

const BookingText = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.8px;
    line-height: 12px;
    text-align: left;
`;

const TimeText = styled.span`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 11px;
    line-height: 18px;
    width: 147px;
    text-align: left;
`;

const JetContainer = styled.div``;

const JetName = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    line-height: 12px;
    width: 152px;
    text-align: left;
`;

const TailorCode = styled.div`
    border: 1px solid #dadadb;
    border-radius: 2px;
    width: 58px;
    height: 20px;
    color: #19c0ff;
    font-family: AvenirNext-Bold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    width: 56px;
    text-align: center;
    padding: 3px;
`;

const IataCode = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 13px;
    line-height: 16px;
    width: 37px;
    text-align: left;
`;

const Line2 = styled.span`
    color: #0e0e12;
    opacity: 0.15;
`;

const JetIcon = styled.img``;
