import React from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { Card, Form, Input, Row, Col, Radio, message } from 'antd';
import { Popup } from 'components';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
const template = (props) => {
    console.log(props);
    const { t, i18n } = useTranslation();
    const { visibleModal = false, setVisibleModal } = props;
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!'
        }
    };

    const title = {
        MR: 'Mr',
        MRS: 'Mrs',
        MS: 'Ms'
    };

    const onSubmitRequest = async (value) => {
        const result = await props.requestAccount(value);
        await submitRequestToASLandingPage({
            company: value.company_name,
            email: value.email,
            first_name: value.first_name,
            last_name: value.last_name,
            message: `The request from JetOS: ${value.message}`,
            phone: value.phone,
            service: 'SERVICES',
            service_detail: 'development'
        });

        if (result?.success) {
            setVisibleModal(true);
        } else {
            message.error({
                content:
                    'Apologies! We are experiencing an issue. Our team is on it. For assistance, email us at it@aviansolutions.aero. Thanks for your patience!',
                duration: 10
            });
        }
    };

    const submitRequestToASLandingPage = async (data) => {
        try {
            const options = {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            };
            return await axios.post(
                'https://api.aviansolutions.aero/contacts',
                data,
                options
            );
        } catch (err) {
            message.error({
                content:
                    'Apologies! We are experiencing an issue. Our team is on it. For assistance, email us at it@aviansolutions.aero. Thanks for your patience!',
                duration: 10
            });
        }
    };

    return (
        <ContentSection>
            <Card className="ant-card-login">
                <WelcomeSection>
                    <WelcomeText>
                        {t('REQUEST_ACCOUNT').toUpperCase()}
                    </WelcomeText>
                </WelcomeSection>
                <InstructionSection>
                    <InstructionText>
                        {t('REQUEST_ENTER_DETAIL')}
                    </InstructionText>
                </InstructionSection>
                <LoginInformationSection className="login-information-section">
                    <Form
                        layout="vertical"
                        validateMessages={validateMessages}
                        style={{ width: '100%' }}
                        onFinish={(value) => {
                            console.log(value);
                            // setVisibleModal(true);
                            onSubmitRequest(value);
                        }}
                    >
                        <Form.Item
                            name="title"
                            initialValue={title.MR}
                            style={{ padding: '0 24px' }}
                        >
                            <Radio.Group>
                                <Radio value={title.MR}>{title.MR}</Radio>
                                <Radio value={title.MRS}>{title.MRS}</Radio>
                                <Radio value={title.MS}>{title.MS}</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Row style={{ width: '100%' }}>
                            <Col
                                lg={{ span: 12 }}
                                sm={{ span: 24 }}
                                xs={{ span: 24 }}
                                style={{ padding: '0 24px' }}
                            >
                                <Form.Item
                                    name={'first_name'}
                                    label={t('FIRST_NAME')}
                                    rules={[{ required: true }]}
                                >
                                    <Input placeholder={t('YOUR_FIRST_NAME')} />
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                sm={{ span: 24 }}
                                xs={{ span: 24 }}
                                style={{ padding: '0 24px' }}
                            >
                                <Form.Item
                                    name={'last_name'}
                                    label={t('LAST_NAME')}
                                    rules={[{ required: true }]}
                                >
                                    <Input placeholder={t('YOUR_LAST_NAME')} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            style={{ padding: '0 24px' }}
                            name={'email'}
                            label={t('EMAIL_ADDRESS')}
                            rules={[{ required: true, type: 'email' }]}
                        >
                            <Input
                                placeholder={t('ENTER_YOUR_EMAIL_ADDRESS')}
                            />
                        </Form.Item>
                        <Row style={{ width: '100%' }}>
                            <Col
                                lg={{ span: 12 }}
                                sm={{ span: 24 }}
                                xs={{ span: 24 }}
                                style={{ padding: '0 24px' }}
                            >
                                <Form.Item
                                    name={'country'}
                                    label={t('COUNTRY')}
                                    rules={[{ required: true }]}
                                >
                                    <Input placeholder={t('SELECT_COUNTRY')} />
                                </Form.Item>
                            </Col>
                            <Col
                                lg={{ span: 12 }}
                                sm={{ span: 24 }}
                                xs={{ span: 24 }}
                                style={{ padding: '0 24px' }}
                            >
                                <Form.Item
                                    name={'phone'}
                                    label={t('PHONE_NUMBER')}
                                    rules={[{ required: true }]}
                                >
                                    <Input placeholder={t('PHONE_NUMBER')} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            style={{ padding: '0 24px' }}
                            name={'company_name'}
                            label={t('COMPANY').toUpperCase()}
                        >
                            <Input placeholder={t('COMPANY_NAME')} />
                        </Form.Item>
                        <Form.Item
                            style={{ padding: '0 24px' }}
                            name={'message'}
                            label={t('MESSAGE')}
                        >
                            <Input.TextArea
                                placeholder={t('ENTER_YOUR_MESSAGE')}
                                rows={6}
                            />
                        </Form.Item>
                        <Form.Item style={{ padding: '0 24px' }}>
                            <StyledButton htmlType="submit">
                                <SubmitButtonText>
                                    {t('SUBMIT_REQUEST').toUpperCase()}
                                </SubmitButtonText>
                            </StyledButton>
                        </Form.Item>
                    </Form>
                </LoginInformationSection>
            </Card>
            <Popup
                cancelModal={() => {
                    setVisibleModal(false);
                }}
                onOk={() => {
                    setVisibleModal(false);
                }}
                visibleModal={visibleModal}
                successText={t('THANK_YOU_REGISTER').toUpperCase()}
                successSecondLine={t('CONTACT_SOON')}
                buttonTitle={t('OK').toUpperCase()}
            />
        </ContentSection>
    );
};

const CountryPhoneSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
`;

const NameSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
`;

const ContentSection = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    .ant-card-login {
        border-radius: 8px;
        width: 60vw;
        min-width: 620px;
        max-width: 860px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .ant-radio-wrapper span {
        font-family: AvenirNext-DemiBold;
        font-size: 11px;
        text-transform: uppercase;
        color: #6c6f76;
    }
    /* .ant-form-item-control {
        min-width: 550px;
    } */
    /* @media (max-width: 1366px) {
        .ant-form-item-control {
            min-width: 400px;
        }
    }
    .ant-form-vertical .ant-form-item-label > label {
        width: 100%;
    } */
`;

const StyledButton = styled.button`
    width: 100%;
    background-color: #19c0ff;
    border-radius: 2px;
    border: none;
    height: 50px;
`;

const SubmitButtonText = styled.span`
    color: #ffffff;
    font-family: AvenirNext-DemiBold;
    font-size: 13px;
    letter-spacing: 1px;
    line-height: 16px;
    text-align: center;
`;

const WelcomeSection = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
`;

const WelcomeText = styled.span`
    font-size: 20px;
    letter-spacing: 1.5px;
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 24px;
    letter-spacing: 2px;
    line-height: 32px;
    text-align: center;
`;

const InstructionSection = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
`;

const InstructionText = styled.span`
    color: #65686f;
    font-family: Lato;
    font-size: 13px;
    line-height: 24px;
`;

const LoginInformationSection = styled.div`
    display: flex;
    justify-content: center;
    /* .ant-checkbox-input {
        border-radius: 7px;
    } */
    .request-account-input-wrapper {
        width: 47%;
    }
    label {
        color: #6c6f76;
        font-family: AvenirNext-DemiBold;
        font-size: 10px;
        letter-spacing: 0.7px;
        line-height: 12px;
        text-align: left;
        text-transform: uppercase;
    }
    label.ant-form-item-required {
        position: relative;
    }

    .ant-form-item-label
        > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        position: absolute;
        right: -11px;
    }
    input {
        height: 50px;
        width: 100%;
        font-family: Lato;
        color: rgb(18, 18, 22);
        border: 1px solid rgba(36, 36, 45, 0.15);
        border-radius: 3px;
    }
`;

const LabelText = styled.span`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
`;

const PasswordLabelSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 29.6875vw;
    .forgot-password-button {
        float: right;
    }
    .forgot-password-text {
        text-align: right;
    }
    /* Specific for iPhone 12 Pro Max and  devices have the view port width above */
    @media (max-width: 700px) {
        .forgot-password-button {
            float: left;
            width: 29.6875vw;
            padding-left: 0;
            align-items: flex-start;
            display: flex;
        }
        .forgot-password-text {
            text-align: left;
        }
    }
`;

const ForgotPasswordSection = styled.button`
    background-color: Transparent;
    background-repeat: no-repeat;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    border: none;
    padding-right: 0;
`;

const ForgotPasswordText = styled.span`
    font-family: AvenirNext-DemiBold;

    color: #65686f;
    font-size: 11px;
    line-height: 14px;
    text-align: right;
    position: relative;
`;

const inputStyle = {
    height: '50px',
    border: '1px solid rgba(36, 36, 45, 0.15)',
    borderRadius: '3px'
};
export { template };
