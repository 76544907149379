import React, { useState } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { Col, Row, Switch, Image } from 'antd';
import '../services.css';
import {
    Container,
    Title,
    TitleDescription,
    AvailabilitySection,
    AvailableText
} from '../styles';
import { numberFormat } from 'utils';
import { fallbackImage } from 'config/Constants';
import { ButtonCustom, Text } from 'components';
import { deleteIcon, editIcon, iconCancel } from 'assets/images/icons';
import { WarningModal } from 'components/Modals';
import { useDispatch, useSelector } from 'react-redux';
import { removeServiceItemById } from 'redux/actions/appActions';
import { SERVICES } from 'routes/main';
import { SETTING_SHOW_MODAL } from 'redux/Constants';

const EachInfo = (props) => {
    const { label, color, detail } = props;
    return (
        <>
            <Title
                style={{
                    marginBottom: 3,
                    marginTop: 10
                }}
            >
                {label}
            </Title>
            <TitleDescription color={color}>{detail}</TitleDescription>
        </>
    );
};

const General = (props) => {
    const {
        id,
        name,
        serving,
        item_type,
        onChangeAvailability,
        price,
        available,
        image = {},
        isEdit,
        onClickEdit
    } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { expandedSidebar } = useSelector(
        (state) => state.settingReducer || false
    );

    const [showWarning, setShowWarning] = useState(false);

    const onRemoveService = async () => {
        let rs = await dispatch(removeServiceItemById(id));
        setShowWarning(false);

        let modal = {
            show: true,
            type: rs.success ? 'success' : 'error',
            description: '',
            title: rs.success
                ? t('CONFIRM_DELETE_SERVICE_SUCCESS')
                : t('CONFIRM_DELETE_SERVICE_ERROR'),
            buttonTitle: 'OK',
            onOk: rs.success ? SERVICES : '',
            onCancel: rs.success ? SERVICES : ''
        };
        dispatch({ type: SETTING_SHOW_MODAL, data: modal });
    };

    const onCancelWarning = () => {
        setShowWarning(false);
    };

    const _renderDetailView = () => {
        return (
            <Component className="wrapper" expandedSidebar={expandedSidebar}>
                <Row>
                    <GeneralSection>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                height: '100%'
                            }}
                        >
                            <Image
                                style={{
                                    objectFit: 'cover',
                                    maxWidth: 270,
                                    marginTop: 25
                                }}
                                src={image.url}
                                className="item-image"
                                fallback={fallbackImage}
                            />
                        </div>
                        <InfoDetailSection expandedSidebar={expandedSidebar}>
                            <NameSection>
                                <ItemName color="#121216">
                                    {name ? name.toUpperCase() : '_'}
                                </ItemName>
                            </NameSection>
                            <InfoDetail>
                                <ItemWrapper>
                                    <EachInfo
                                        label={t('TYPE')}
                                        detail={
                                            item_type
                                                ? i18n
                                                      .t(item_type)
                                                      .toUpperCase()
                                                : '_'
                                        }
                                    />
                                </ItemWrapper>
                                <ItemWrapper>
                                    <Title
                                        style={{
                                            marginBottom: 3,
                                            marginTop: 10
                                        }}
                                    >
                                        {i18n.t('AVAILABILITY')}
                                    </Title>
                                    <TitleDescription>
                                        <AvailabilitySection
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                paddingTop: '3px'
                                            }}
                                        >
                                            <Switch
                                                checked={available}
                                                onChange={() =>
                                                    onChangeAvailability()
                                                }
                                            />
                                            <AvailableText
                                                enable={available}
                                                style={{ paddingLeft: '5px' }}
                                            >
                                                {available
                                                    ? t(
                                                          'AVAILABLE'
                                                      ).toUpperCase()
                                                    : t(
                                                          'NOT_AVAILABLE'
                                                      ).toUpperCase()}
                                            </AvailableText>
                                        </AvailabilitySection>
                                    </TitleDescription>
                                </ItemWrapper>
                            </InfoDetail>
                            <InfoDetail>
                                <ItemWrapper>
                                    <EachInfo
                                        label={t('PRICE_PER_SERVING')}
                                        detail={
                                            price
                                                ? numberFormat(price.value)
                                                : '_'
                                        }
                                    />
                                </ItemWrapper>
                            </InfoDetail>
                        </InfoDetailSection>
                    </GeneralSection>

                    <Col style={{ marginTop: 25 }}>
                        <EachButton>
                            {isEdit ? (
                                <CancelEditButton onClick={() => onClickEdit()}>
                                    <Text
                                        textStyle="tableText2"
                                        style={{ marginTop: '2px' }}
                                    >
                                        {t('CANCEL_EDIT').toUpperCase()}
                                    </Text>
                                    <Icon
                                        src={iconCancel}
                                        style={{ marginLeft: '3px' }}
                                    />
                                </CancelEditButton>
                            ) : (
                                <ButtonIcon
                                    className="service-edit-icon"
                                    // style={{ marginLeft: '25px' }}
                                    onClick={() => onClickEdit()}
                                >
                                    <Icon src={editIcon} />
                                </ButtonIcon>
                            )}
                            <ButtonIcon
                                // style={{ marginLeft: '25px' }}
                                onClick={() => setShowWarning(true)}
                            >
                                <Icon src={deleteIcon} />
                            </ButtonIcon>
                        </EachButton>
                    </Col>
                </Row>

                {/* modal warning delete employee */}
                <WarningModal
                    show={showWarning}
                    title={t('CONFIRM_DELETE_SERVICE').toUpperCase()}
                    okText={t('YES_REMOVE_IT').toUpperCase()}
                    onOk={onRemoveService}
                    cancelText={t('NO_DISREGARD').toUpperCase()}
                    onCancel={onCancelWarning}
                />
            </Component>
        );
    };

    return <Container>{_renderDetailView()}</Container>;
};

export default General;

const ItemName = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 20px;
    letter-spacing: 0.8px;
    line-height: 25px;
    text-align: left;
    margin-bottom: 15px;
`;

const Component = styled.div`
    background-color: #ffffff;
    border-radius: 4px;
    /* padding: 25px; */
    padding-left: 25px;
    padding-bottom: 25px;
    padding-right: 25px;
    padding-top: 0;
    margin-top: 15px;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
    .service-edit-icon {
        margin-left: ${(props) => (props.expandedSidebar ? '0px' : '25px')};
    }

    @media (max-width: 768px) {
        .service-edit-icon {
            margin-left: 0;
        }
    }
`;

const GeneralSection = styled(Col)`
    flex: 1 1 200px;
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    margin-left: 5px;
`;

const InfoDetailSection = styled(Col)`
    margin-left: 25px;
    margin-top: 40px;
    height: 100%;
    /* @media (max-width: 1024px) {
        margin-left: ${(props) => (props.expandedSidebar ? '0' : '25px')};
    } */
    /* @media (max-width: 768px) {
        margin-left: 0;
    } */
`;

const NameSection = styled(Row)``;

const InfoDetail = styled(Row)``;

const ItemWrapper = styled(Col)`
    padding-right: 120px;
`;

const EachButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ButtonIcon = styled.a`
    border: 1px solid #cdd1d8;
    border-radius: 2px;
    width: 32px;
    height: 32px;
    /* margin-left: 20px; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 25px;
`;

const Icon = styled.img`
    width: 16px;
    height: 16px;
`;

const CancelEditButton = styled.button`
    background-color: transparent;
    border: 1px solid #dadadb;
    border-radius: 2px;
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
`;
