import React, { useState } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { Col, Row, Avatar } from 'antd';
import { ButtonCustom } from 'components';
import '../customer.css';
import { renderAddress } from 'utils';
import moment from 'moment';
import { editIcon, deleteIcon } from 'assets/images/icons';
import { WarningModal } from 'components/Modals';
import { useDispatch, useSelector } from 'react-redux';
import { deleteEmployee } from 'redux/actions/appActions';
import { SETTING_SHOW_MODAL } from 'redux/Constants';
import { EMPLOYEES } from 'routes/main';
import { role as CompanyRole } from 'config/Constants';
import { isUserAdmin } from 'redux/selectors/user';

const EachInfo = (props) => {
    return (
        <>
            <Title
                style={{
                    marginBottom: 3,
                    marginTop: 10
                }}
            >
                {props.label}
            </Title>
            <TitleDescription color={props.color}>
                {props.detail}
            </TitleDescription>
        </>
    );
};

const General = (props) => {
    const {
        firstname,
        lastname,
        companies,
        phone,
        email,
        created_at,
        address,
        image,
        role,
        activeCompanyName,
        userId
    } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { activeCompany = {} } = useSelector(
        (state) => state.companyReducer || {}
    );
    const isAdminRole = useSelector(isUserAdmin())

    const [showWarning, setShowWarning] = useState(false);

    const onRemoveEmployee = async () => {
        let rs = await dispatch(deleteEmployee(userId));
        setShowWarning(false);

        let modal = {
            show: true,
            type: rs.success ? 'success' : 'error',
            description: '',
            title: rs.success
                ? t('CONFIRM_DELETE_EMPLOYEE_SUCCESS')
                : t('CONFIRM_DELETE_EMPLOYEE_ERROR'),
            buttonTitle: 'OK',
            onOk: rs.success ? EMPLOYEES : '',
            onCancel: rs.success ? EMPLOYEES : ''
        };
        dispatch({ type: SETTING_SHOW_MODAL, data: modal });
    };

    const onCancelWarning = () => {
        setShowWarning(false);
    };

    const _renderDetailView = () => {
        return (
            <Component className="wrapper">
                <Row>
                    <GeneralSection>
                        <AvatarSection>
                            {image ? (
                                <Avatar
                                    className="ant-avatar-custom ant-avatar-custom-big"
                                    src={image}
                                    size={100}
                                />
                            ) : (
                                <Avatar
                                    className="ant-avatar-custom ant-avatar-custom-big"
                                    size={100}
                                >
                                    {(
                                        firstname.charAt(0) + lastname.charAt(0)
                                    ).toUpperCase()}
                                </Avatar>
                            )}
                        </AvatarSection>
                        <InfoDetailSection>
                            <NameSection>
                                <Name>
                                    {(firstname + ' ' + lastname).toUpperCase()}
                                </Name>
                            </NameSection>
                            <InfoDetail>
                                <EmailAndRole>
                                    <EachInfo
                                        label={t('EMAIL_ADDRESS')}
                                        detail={email.toUpperCase()}
                                    />
                                    <EachInfo
                                        label={t('ROLE')}
                                        detail={role}
                                        color={'#19C0FF'}
                                    />
                                </EmailAndRole>
                                <PhoneAndCompany>
                                    <EachInfo
                                        label={t('PHONE_NUMBER')}
                                        detail={phone ? phone : '-'}
                                    />
                                    <EachInfo
                                        label={t('COMPANY')}
                                        detail={activeCompanyName.toUpperCase()}
                                        color={'#19C0FF'}
                                    />
                                </PhoneAndCompany>
                                <AddressAndSince>
                                    <EachInfo
                                        label={t('ADDRESS')}
                                        detail={renderAddress(
                                            address
                                        ).toUpperCase()}
                                    />
                                    <EachInfo
                                        label={t('EMPLOYEE_SINCE')}
                                        detail={moment(created_at)
                                            .format('MMMM DD, YYYY')
                                            .toUpperCase()}
                                    />
                                </AddressAndSince>
                            </InfoDetail>
                        </InfoDetailSection>
                    </GeneralSection>
                    <Col flex="0 1 300px">
                        {/* handleEditButton */}
                        <ButtonSection>
                            <EachButton>
                                <ButtonCustom
                                    btnType="primary"
                                    onClick={() => {}}
                                    title={t('SEND_MESSAGE').toUpperCase()}
                                    width={'150px'}
                                    size="small"
                                />
                            </EachButton>
                            {isAdminRole && (
                                <>
                                    <EachButton>
                                        <ButtonIcon
                                            style={{ marginLeft: '25px' }}
                                            onClick={() =>
                                                props.handleEditButton()
                                            }
                                        >
                                            <Icon src={editIcon} />
                                        </ButtonIcon>
                                    </EachButton>
                                    <EachButton>
                                        <ButtonIcon
                                            style={{ marginLeft: '25px' }}
                                            onClick={() => setShowWarning(true)}
                                        >
                                            <Icon src={deleteIcon} />
                                        </ButtonIcon>
                                    </EachButton>
                                </>
                            )}
                        </ButtonSection>
                    </Col>
                </Row>

                {/* modal warning delete employee */}
                <WarningModal
                    show={showWarning}
                    title={t('CONFIRM_DELETE_EMPLOYEE').toUpperCase()}
                    okText={t('YES_REMOVE_IT').toUpperCase()}
                    onOk={onRemoveEmployee}
                    cancelText={t('NO_DISREGARD').toUpperCase()}
                    onCancel={onCancelWarning}
                />
            </Component>
        );
    };

    return <Container>{_renderDetailView()}</Container>;
};

export default General;

const EachCol = styled(Col)`
    margin-left: 40px;
    margin-right: 40px;
`;

const PhoneAndCompany = styled(Col)`
    padding-right: 120px;
`;

const AddressAndSince = styled(Col)``;

const EmailAndRole = styled(Col)`
    padding-right: 120px;
`;

const InfoDetail = styled(Row)``;

const NameSection = styled(Row)``;

const InfoDetailSection = styled(Col)`
    margin-left: 20px;
`;

const AvatarSection = styled(Col)`
    height: 100%;
    align-items: flex-start;
`;

const GeneralSection = styled(Col)`
    flex: 1 1 200px;
    display: flex;
    align-items: center;
    margin-left: 5px;
`;

const EachButton = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
`;

const ButtonSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 5px;
    align-items: center;
`;

const Container = styled.div`
    /* margin-top: 15px; */
`;

const Title = styled.div`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    line-height: 13px;
    text-align: left;
`;

const TitleDescription = styled.div`
    color: ${(props) => (props.color ? props.color : '#121216')};
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 18px;
    text-align: left;
`;

const Name = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 20px;
    letter-spacing: 0.8px;
    line-height: 25px;
    text-align: left;
`;

const Component = styled.div`
    background-color: #ffffff;
    border-radius: 4px;
    padding: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
`;

const ButtonIcon = styled.a`
    border: 1px solid #cdd1d8;
    border-radius: 2px;
    width: 32px;
    height: 32px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Icon = styled.img`
    width: 16px;
    height: 16px;
`;
