import React from 'react';
import styled from 'styled-components';
import { jetIcon } from '../../assets/images/icons';

const JetLine = () => {
    return (
        <>
            <Line2> ---- </Line2>
            <JetIcon src={jetIcon} />
            <Line2> ---- </Line2>
        </>
    );
};

const FlightInfo = ({ leg = {} }) => {
    const { from_airport, to_airport } = leg;
    return (
        <JetContainer>
            <IataCode>{from_airport ? from_airport.icao_code : '_'}</IataCode>
            <JetLine />
            <IataCode>{to_airport ? to_airport.icao_code : '_'}</IataCode>
        </JetContainer>
    );
};

const JetContainer = styled.div``;

const IataCode = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 13px;
    line-height: 16px;
    width: 45px;

    display: inline-block;
    text-align: left;
`;

const Line2 = styled.span`
    color: #0e0e12;
    opacity: 0.15;
`;

const JetIcon = styled.img``;

export default FlightInfo;
