import React, { useState, useEffect } from 'react';
import { template as tmpl } from './EmptyLegList.tmpl';
import { fetchBookings, searchBooking } from 'redux/actions/bookingAction';
import { fetchFlightsByType, searchFlight } from 'redux/actions/flightAction';
import { connect } from 'react-redux';
import { bookingCategory, FLIGHT_SALE_TYPE } from 'config/Constants';

const EmptyLegListLogic = (props) => {
    const {
        fetchBookings,
        searchBooking,
        activeCompany,
        fetchFlightsByType,
        searchFlight
    } = props;

    const [key, setKey] = useState(''); // key input search with flight number, ...
    const [searchBy, setSearchBy] = useState(bookingCategory.ALL); // default all
    const [sortBy, setSortBy] = useState('week'); // default all

    const [upcomingFlightsList, setUpcomingFlightsList] = useState([]);
    const [searchList, setSearchList] = useState([]);

    const initBookings = async () => {
        onChangePageUpcomingFlights({ current: 1, pageSize: 10 });
    };

    /**
     * Get Booking list
     */
    useEffect(() => {
        initBookings();
    }, [activeCompany]);

    /**
     * Function handles searching when user is typing
     * @param {*} e event when user is typing
     */
    const onSearch = async (e) => {
        const input = e.toLowerCase();
        setKey(input);
        if (!input) return;

        let rs = await searchFlight(
            input,
            true,
            5,
            1,
            !searchBy || searchBy === bookingCategory.ALL
                ? undefined
                : searchBy.toLowerCase()
        );
        setSearchList(rs || []);
    };

    const navigate = (route, id, state) => {
        props.history.push({
            pathname: route,
            search: id,
            state: state
        });
    };

    const onChangePageUpcomingFlights = async (pagination = {}) => {
        const { current, pageSize } = pagination;
        let upcomingFlights = await fetchFlightsByType(pageSize, current);
        setUpcomingFlightsList(upcomingFlights);
    };

    const onChangePageSearch = async (pagination = {}) => {
        const { current, pageSize } = pagination;
        let rs = await searchFlight(
            key,
            true,
            pageSize,
            current,
            !searchBy || searchBy === bookingCategory.ALL
                ? undefined
                : searchBy.toLowerCase()
        );
        setSearchList(rs || []);
    };

    const onSelectCategory = (value) => {
        setSearchBy(value);
    };

    const initProps = {
        searchList: searchList,
        upcomingFlightsList: upcomingFlightsList,
        onSearch: onSearch,
        navigate: navigate,
        onChangePageUpcomingFlights: onChangePageUpcomingFlights,
        onChangePageSearch: onChangePageSearch,
        searchBy: searchBy,
        key: key,
        sortBy: sortBy,
        onSelectCategory: onSelectCategory
    };

    return tmpl && tmpl({ ...initProps });
};

class EmptyLegListContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <EmptyLegListLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { customers, activeCompany } = companyReducer;
    return {
        customers: customers,
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchFlightsByType: (limit, page, type) =>
            dispatch(fetchFlightsByType(limit, page, type)),
        searchFlight: (key, paging, limit, page, status) =>
            dispatch(searchFlight(key, paging, limit, page, status))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmptyLegListContainer);
