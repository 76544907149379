import React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { ButtonCustom, Text, localeEmptyTable } from 'components';
import { ButtonType } from 'components/Antd/Button';
import { DetailSection, TimeText, IcaoCode, AirportName } from '../styles';
import { ColumnHeader } from 'styles/shareStyles';
import { formatDepartureTime, getPaymentStatus, numberFormat } from 'utils';
import {
    BookingStatus,
    JetInfo,
    FlightInfo,
    Booker,
    PaymentStatus,
    FlightStatus
} from 'components/Commons';
import { EMPTY_LEG_DETAIL } from 'routes/main';

const EmptyLegTable = (props) => {
    const {
        navigate,
        list = {},
        key,
        setKey,
        loading = false,
        onChangePageUpcomingFlights
    } = props;
    const { docs = [], limit, page, pages, total = 1 } = list;
    const { t, i18n } = useTranslation();

    const columns = [
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('DEPARTURE_TIME')}</ColumnHeader>;
            },
            width: '12%',
            dataIndex: 'departure',
            key: 'departure',
            render: function eachItemDepartureTime(departure) {
                const { date = '', time = '', utc_offset = 0 } = departure;
                return (
                    <TimeText>
                        {formatDepartureTime(date, time, utc_offset)}
                    </TimeText>
                );
            }
        },

        {
            title: function titleHeader() {
                return <ColumnHeader>{t('DEPARTURE')}</ColumnHeader>;
            },
            dataIndex: 'from_airport',
            key: 'from_airport',
            width: '20%',
            render: function eachItemFromTo(from_airport, record) {
                const { icao_code = '', name = '' } = from_airport || {};
                return (
                    <div>
                        <IcaoCode>
                            <Text textStyle="airportName">{icao_code}</Text>
                        </IcaoCode>
                        <AirportName>
                            <Text textStyle="tableText1">{name}</Text>
                        </AirportName>
                    </div>
                );
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('RETURN')}</ColumnHeader>;
            },
            dataIndex: 'to_airport',
            key: 'to_airport',
            width: '20%',
            render: function eachItemFromTo(to_airport, record) {
                const { icao_code = '', name = '' } = to_airport || {};
                return (
                    <div>
                        <IcaoCode>
                            <Text textStyle="airportName">{icao_code}</Text>
                        </IcaoCode>
                        <AirportName>
                            <Text textStyle="tableText1">{name}</Text>
                        </AirportName>
                    </div>
                );
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('JET_INFO')}</ColumnHeader>;
            },
            dataIndex: 'aircraft',
            key: 'aircraft',
            width: '10%',
            render: function eachItemJetInfo(aircraft) {
                const leg = { aircraft: aircraft };
                return (
                    <div>
                        <JetInfo leg={leg} />
                    </div>
                );
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('PRICE')}</ColumnHeader>;
            },
            dataIndex: 'price',
            key: 'price',
            width: '10%',
            render: function eachItemPrice(price) {
                return (
                    <Text textStyle="dateTimeBold">{numberFormat(12000)}</Text>
                );
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{t('NUMBER_OF_SEATS')}</ColumnHeader>;
            },
            dataIndex: 'aircraft',
            key: 'aircraft',
            width: '10%',
            render: function eachItemMaxPassenger(aircraft) {
                const { properties = {} } = aircraft || {};
                const { max_passengers } = properties;
                return (
                    <Text textStyle="nameNormal">{max_passengers || '-'}</Text>
                );
            }
        },

        {
            key: 'action',
            width: '10%',
            render: function setAction(val) {
                return (
                    <DetailSection>
                        <ButtonCustom
                            btnType={ButtonType.PRIMARY}
                            title={t('VIEW_DETAIL').toUpperCase()}
                            size="small"
                            borderColor="1px solid rgba(18, 18, 22, 0.2)"
                            width="115px"
                            onClick={(e) => navigate(EMPTY_LEG_DETAIL, val.id)}
                        />
                    </DetailSection>
                );
            }
        }
    ];

    return (
        <Table
            columns={columns}
            dataSource={docs}
            style={{ marginTop: 20 }}
            scroll={{ x: 1000 }}
            loading={loading}
            pagination={{
                defaultPageSize: 10,
                total: pages * 10 - 1,
                hideOnSinglePage: false,
                showSizeChanger: false
            }}
            locale={localeEmptyTable()}
            onChange={(e) => onChangePageUpcomingFlights(e)}
        />
    );
};

export default EmptyLegTable;
