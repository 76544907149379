import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { Modal, Row, Col } from 'antd';
import { ButtonCustom, Text } from 'components';
import InvoiceCard from './InvoiceCard';
import _ from 'lodash';
import { getTitleWithFlightType } from 'utils';
import { calcPriceComponents } from 'redux/actions/quoteAction';

const ModalPackageInvoice = ({
    packages=[],
    type = '',
    fromAirport = {},
    toAirport = {},
    show = false,
    onCancel
}) => {
    const { t, i18n } = useTranslation();

    const onChangePrice = async () => {
        //
    };

    const _renderInvoiceCard = (leg = {}, legIndex) => {
        const { services, id, aircraft = {}, price_components = {} } = leg || {};
        return (
            <InvoiceCard
                legId={id}
                fromAirport={fromAirport}
                toAirport={toAirport}
                leg_index={legIndex}
                services={services}
                onSavePrice={onChangePrice}
                showAddBtn={false}
                selectAircraft={{ aircraft: aircraft, price_components: price_components }}
                isView={true}
            />
        );
    };

    return (
        <Modal
            className="modal-invoice-custom"
            centered
            visible={show}
            onCancel={() => onCancel(false)}
            footer={null}
            style={{ margin: 50 }}
            width={'auto'}
        >
            <ModalContainer>
                <div style={{ marginTop: 10 }}>
                    <Title>{i18n.t('PACKAGE_INVOICE').toUpperCase()}</Title>
                </div>

                { !_.isEmpty(packages) && packages.map((pk, i) => {
                    return (
                        <Row
                            style={{ paddingLeft: 25 }}
                            key={i}
                        >
                            <Text textStyle="heading3">
                                {getTitleWithFlightType(type, i).toUpperCase()}
                            </Text>
                            {_renderInvoiceCard(pk, i)}
                        </Row>
                    );
                })}
            </ModalContainer>
            <div style={{ marginTop: 25, marginBottom: 25 }}>
                <ButtonCustom
                    title={t('CLOSE').toUpperCase()}
                    btnType="primary"
                    size="medium"
                    width="250px"
                    onClick={onCancel}
                />
            </div>
        </Modal>
    );
};

export default ModalPackageInvoice;

const ModalContainer = styled.div`
    .modal-invoice-custom {
        width: auto;
    }
`;

const Title = styled.div`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 20px;
    letter-spacing: 0.7px;
    text-align: center;
`;