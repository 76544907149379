import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { useAuth } from './ProvideAuth';
import auth from '../../services/auth';

const hasUserId = (user) => user && user.id;

// screen if you're not yet authenticated.
class PrivateRoute extends React.Component {
    static propTypes = {
        location: PropTypes.object,
        history: PropTypes.object,
        component: PropTypes.any,
        path: PropTypes.string
    };

    isAuthenicated = () => {
        return auth.getToken() && hasUserId(auth.getUserInfo());
    };

    isExpired = () => {
        return auth.isExpiredToken(auth.getToken());
    };

    render() {
        const { component: Component, location, ...rest } = this.props;

        const isUserAuthenicated = this.isAuthenicated();
        const isTokenExpired = this.isExpired();

        return (
            <Route
                {...rest}
                render={(props) =>
                    !isTokenExpired && isUserAuthenicated ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: location }
                            }}
                        />
                    )
                }
            />
        );
    }
}

export default withRouter(PrivateRoute);
