import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { Header, Popup, ButtonCustom } from 'components';

import General from '../components/General';
import ItemInfo from '../components/ItemInfo';
import Aircrafts from '../components/Aircrafts';
import Availability from '../components/Availability';

import {
    EditProfileContainer,
    ContainerWrapper,
    BottomContainer
} from '../styles';
import { SERVICES } from 'routes/main';

const template = (props) => {
    const {
        service,
        onSearchCountries,
        onSearchAircrafts,
        onChangeAvailability,
        onSubmit,
        successMsg,
        visibleModal
    } = props;
    const {
        id,
        name,
        type,
        image = {},
        serving,
        description,
        max_quantity,
        item_type,
        price = {},
        available,
        availability = {},
        all_aircrafts_available,
        all_countries_available
    } = service;

    const { t, i18n } = useTranslation();

    const [isEdit, setIsEdit] = useState(false);

    const [general, setGeneral] = useState({});
    const [itemInfo, setItemInfo] = useState({});
    const [aircrafts, setAircrafts] = useState({});
    const [availabilityValues, setAvailabilityValues] = useState({});

    useEffect(() => {
        setGeneral({
            id: id,
            name: name,
            serving: serving,
            item_type: item_type,
            price: price,
            available: available,
            image: image
        });

        setItemInfo({
            name: name,
            description: description,
            max_quantity: +max_quantity,
            price: price
        });

        setAircrafts({
            all_aircrafts_available: all_aircrafts_available,
            aircrafts: availability.aircrafts
        });

        setAvailabilityValues({
            all_countries_available: all_countries_available,
            countries: availability.countries
        });
    }, [props.service]);

    const onClickEdit = () => setIsEdit(!isEdit);

    const onSubmitForm = () => {
        try {
            let countries = availabilityValues.countries.map(
                (v) => v.short_code
            );
            let aircraftsCode = aircrafts.aircrafts.map((v) => v.id);

            let data = {
                all_aircrafts_available: aircrafts.all_aircrafts_available,
                all_countries_available:
                    availabilityValues.all_countries_available,
                available: available,
                description: itemInfo.description,
                max_quantity: itemInfo.max_quantity,
                name: itemInfo.name,
                serving: serving,
                type: type,
                price: itemInfo.price,
                availability: {
                    aircrafts: aircraftsCode,
                    countries: countries
                }
            };

            onSubmit(data);
            setIsEdit(false);
        } catch (e) {}
    };

    return (
        <EditProfileContainer>
            <Header
                title={i18n.t('SERVICE_ITEMS')}
                description={service.name}
            />
            <ContainerWrapper>
                <General
                    isEdit={isEdit}
                    onClickEdit={onClickEdit}
                    {...general}
                    name={itemInfo.name}
                    onChangeAvailability={onChangeAvailability}
                />
            </ContainerWrapper>
            <ItemInfo
                setItemInfo={setItemInfo}
                isEdit={isEdit}
                onClickEdit={onClickEdit}
                {...itemInfo}
            />
            <Aircrafts
                setAircrafts={setAircrafts}
                isEdit={isEdit}
                onClickEdit={onClickEdit}
                onSearchAircrafts={onSearchAircrafts}
                {...aircrafts}
            />
            <Availability
                setAvailability={setAvailabilityValues}
                isEdit={isEdit}
                onClickEdit={onClickEdit}
                onSearchCountries={onSearchCountries}
                {...availabilityValues}
            />
            {isEdit && (
                <BottomContainer>
                    <ButtonCustom
                        btnType="primary"
                        width="190px"
                        onClick={() => onSubmitForm()}
                        title={i18n.t('SAVE_CHANGES').toUpperCase()}
                    />
                </BottomContainer>
            )}
            <Popup
                cancelModal={() => {
                    props.cancelModal();
                }}
                onOk={() => {
                    props.navigate(SERVICES);
                }}
                visibleModal={visibleModal}
                successText={successMsg}
                buttonTitle={t('VIEW_SERVICE_LIST').toUpperCase()}
            />
        </EditProfileContainer>
    );
};

export { template };
