import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Row, Col, Switch, Input as AntInput, Divider } from 'antd';
import {
    Input,
    Map2,
    AircraftTypeCard,
    PackageDetailCard,
    PassengerInfoContainer,
    ButtonCustom,
    FlightNotes,
    Text
} from 'components';
import { HeaderInfo } from 'components/Cards';
import { appConfig } from 'config';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { groupBy } from 'utils';
import { WHITELABEL_SETTING } from 'routes/main';
import { QUOTE_REQUEST_STATUS } from 'redux/Constants';
import { FLIGHT_TYPES } from 'config/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { GLOBAL_STYLE_CONSTANTS } from 'config/Constants';
import { aboutIcon, infoIosIcon } from 'assets/images/icons';
const { TextArea } = AntInput;

const GeneralInfo = (props) => {
    const {
        whitelabelName = '',
        setWhiteLabelName,
        description = '',
        setDescription,
        redirectToCompanySite = false,
        setRedirectToCompanySite,
        requireMembership = false,
        setRequireMembership,
        hideTailnumber = false,
        setHideTailnumber,
        showScheduledFlights = false,
        setShowScheduledFlights,
        showAvailableAircrafts = false,
        setShowAvailableAircrafts,
        showRoutes = false,
        setShowRoutes,
        introduction_text = '',
        setDefaultUrl,
    } = props;
    const { t, i18n } = useTranslation();

    return (
        <ContainerWrapper>
            <PayoutSection>
                {/* <HeaderInfo title={t('GENERAL_INFO').toUpperCase()} /> */}
                <WhitelabelContainer>
                    <EachRow>
                        <Input
                            label={t('NAME').toUpperCase()}
                            placeholder={t('WHITELABEL_NAME')}
                            width="100%"
                            value={whitelabelName}
                            onChange={(value) => {
                                setDefaultUrl(value + '.' + appConfig.DOMAIN);
                                setWhiteLabelName(value);
                            }}
                        />
                    </EachRow>
                    <EachRow style={{ flexDirection: 'column' }}>
                        <TitleText>{t('INTRODUCTION')}</TitleText>
                        <DescriptionArea
                            placeholder={t('WRITE_DESCRIPTION')}
                            autoSize={{ minRows: 5, maxRows: 100 }}
                            onChange={(e) => {
                                setDescription(e.target.value);
                            }}
                            value={description}
                        />
                    </EachRow>
                    <EachRow>
                        <GeneralSetting>{t('GENERAL_SETTINGS')}</GeneralSetting>
                    </EachRow>
                    <EachRow style={{ marginTop: 0 }}>
                        <GeneralSettingsCol style={{ marginRight: 30 }}>
                            <GeneralSettingRow
                                align={'middle'}
                                justify="space-between"
                            >
                                <TitleText>{t('REDIRECT')}</TitleText>
                                <Switch
                                    checked={redirectToCompanySite}
                                    style={{ marginLeft: 10 }}
                                    onChange={setRedirectToCompanySite}
                                />
                            </GeneralSettingRow>
                            <Divider />
                            <GeneralSettingRow
                                align={'middle'}
                                justify="space-between"
                            >
                                <TitleText>{t('REQUIRE_MEMBERSHIP')}</TitleText>
                                <Switch
                                    checked={requireMembership}
                                    style={{ marginLeft: 10 }}
                                    onChange={setRequireMembership}
                                />
                            </GeneralSettingRow>
                            <Divider />
                            <GeneralSettingRow
                                align={'middle'}
                                justify="space-between"
                            >
                                <TitleText>{t('HIDE_TAILNUMBER')}</TitleText>
                                <Switch
                                    checked={hideTailnumber}
                                    style={{ marginLeft: 10 }}
                                    onChange={setHideTailnumber}
                                />
                            </GeneralSettingRow>
                        </GeneralSettingsCol>
                        <GeneralSettingsCol>
                            <GeneralSettingRow
                                align={'middle'}
                                justify="space-between"
                            >
                                <TitleText>
                                    {t('SHOW_SCHEDULED_FLIGHT')}
                                </TitleText>
                                <Switch
                                    checked={showScheduledFlights}
                                    style={{ marginLeft: 10 }}
                                    onChange={setShowScheduledFlights}
                                />
                            </GeneralSettingRow>
                            <Divider />
                            <GeneralSettingRow
                                align={'middle'}
                                justify="space-between"
                            >
                                <TitleText>
                                    {t('SHOW_AVAILABLE_AIRCRAFTS')}
                                </TitleText>
                                <Switch
                                    checked={showAvailableAircrafts}
                                    style={{ marginLeft: 10 }}
                                    onChange={setShowAvailableAircrafts}
                                />
                            </GeneralSettingRow>
                            <Divider />
                            <GeneralSettingRow
                                align={'middle'}
                                justify="space-between"
                            >
                                <TitleText>{t('SHOW_ROUTES')}</TitleText>
                                <Switch
                                    checked={showRoutes}
                                    style={{ marginLeft: 10 }}
                                    onChange={setShowRoutes}
                                />
                            </GeneralSettingRow>
                        </GeneralSettingsCol>
                    </EachRow>
                </WhitelabelContainer>
            </PayoutSection>
        </ContainerWrapper>
    );
};

export default GeneralInfo;

const GeneralSettingRow = styled(Row)`
    width: 370px;
    padding-right: 20px;
    /* height: 46px; */
`;

const GeneralSettingsCol = styled(Col)`
    margin-top: 20px;
`;

const GeneralSetting = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 17px;
`;

const DescriptionArea = styled(TextArea)`
    border: 1px solid rgba(36, 36, 45, 0.15);
    border-radius: 4px;
    margin-top: 10px;
    width: 770px;
`;

const TitleText = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 18px;
    text-transform: uppercase;
`;

const WhitelabelContainer = styled.div`
    padding-left: 51px;
`;

const EachRow = styled(Row)`
    margin-top: 20px;
`;

const ConnectStripeButton = styled.button`
    background: transparent;
    background-color: #19c0ff;
    border: none;
    background-repeat: no-repeat;
    overflow: hidden;
    outline: none;
    border-radius: 2px;
    height: 40px;
    width: 190px;
    &:hover {
        background-color: #12afeb;
    }
`;

const ConnectStripeText = styled.span`
    color: #ffffff;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    letter-spacing: 0.7px;
    line-height: 13px;
    text-align: center;
`;

const PayoutIntroText = styled.span`
    color: #65686f;
    font-family: Lato;
    font-size: 13px;
    line-height: 23px;
    max-width: 1089px;
    white-space: pre-wrap;
`;

const Icon = styled.img`
    width: 16px;
    height: 16px;
    margin-top: 5px;
    margin-right: 15px;
`;

const ConnectStripeIntro = styled.div`
    margin: 25px 25px 0 47px;
    background-color: #f8f9fe;
    border-radius: 4px;
    padding: 15px 20px;
    display: flex;
`;

const ContainerWrapper = styled.div`
    padding-bottom: 40px;
    border-radius: 4px;
    /* margin-top: 14px; */
    .ant-divider {
        border-top: 1px solid rgba(14, 14, 18, 0.08);
    }
    .ant-divider-horizontal {
        margin: 20px 0;
    }
`;

const PayoutSection = styled.div`
    padding-right: 20px;
    padding-bottom: 40px;
    background-color: #ffffff;
    border-radius: 4px;
`;
