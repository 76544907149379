import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Image } from 'antd';
import { Text, EmptyComponent } from 'components';
import { useTranslation } from 'react-i18next';
import { iconTray } from 'assets/images/icons';
import { getLowresUrl, numberFormat } from 'utils';
import _ from 'lodash';

const ServiceItem = (props) => {
    const { label = 'SERVICE ITEMS', services = [], type } = props;
    const { t, i18n } = useTranslation();

    return (
        <ServiceItemContainer>
            <Label>
                <Text textStyle="heading5">
                    {label + ' (' + services.length + ')'}{' '}
                </Text>
            </Label>
            <ItemList>
                {services.length > 0 ? (
                    services.map((item = {}, index) => {
                        const {
                            image = {},
                            images = [],
                            type = '',
                            price = {}
                        } = item;
                        return (
                            <ItemContainer
                                key={index}
                                style={{ marginTop: index > 0 ? 20 : 0 }}
                            >
                                <Col
                                    style={{
                                        marginLeft: 10,
                                        marginTop: 10,
                                        marginBottom: 10
                                    }}
                                >
                                    {type === 'CUSTOM' ? (
                                        <img
                                            src={iconTray}
                                            style={{
                                                width: 90,
                                                height: 64,
                                                padding: 15,
                                                backgroundColor: '#ffffff'
                                            }}
                                        />
                                    ) : (
                                        <Image
                                            width={90}
                                            height={64}
                                            src={getLowresUrl(image, images)}
                                        />
                                    )}
                                </Col>
                                <Col
                                    style={{
                                        margin: 10,
                                        marginTop: 5
                                    }}
                                >
                                    <Row>
                                        <Text textStyle="heading6">
                                            {item.name}
                                        </Text>
                                    </Row>
                                    <Row style={{ marginTop: 8 }}>
                                        <Text textStyle="tableText1">
                                            {'x ' +
                                                item.quantity +
                                                ' ' +
                                                (item.quantity > 1
                                                    ? t('ITEMS')
                                                    : t('ITEM'))}
                                        </Text>
                                    </Row>
                                    <Row style={{ marginTop: 8 }}>
                                        {type === 'CUSTOM' ? (
                                            <Text>{item.remarks}</Text>
                                        ) : (
                                            <Text
                                                style={{
                                                    color: '#121216',
                                                    fontFamily:
                                                        'AvenirNext-Bold',
                                                    fontSize: '14px',
                                                    lineHeight: '18px'
                                                }}
                                            >
                                                {numberFormat(price?.value)}
                                            </Text>
                                        )}
                                    </Row>
                                </Col>
                            </ItemContainer>
                        );
                    })
                ) : (
                    <EmptyComponent
                        description={t('NO_ITEM_ADDED').toUpperCase()}
                        iconSize={28}
                    />
                )}
            </ItemList>
        </ServiceItemContainer>
    );
};

export default ServiceItem;

const ItemContainer = styled(Row)`
    background-color: #f7f8fb;
    border-radius: 4px;
    padding: 5px;
`;

const ItemList = styled.div`
    background-color: #ffffff;
    border-radius: 4px;
    padding: 20px;
    margin-top: 20px;
    height: 100%;
`;

const ServiceItemContainer = styled.div`
    width: 100%;
    height: 90%;
`;

const Label = styled.div``;
