import React, { useEffect, useState } from 'react';
import { template as tmpl } from './AddEmployeeComponent.tmpl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    addNewEmployee,
    updateEmployee,
    getCurrentUserInformation
} from 'redux/actions/appActions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const AddEmployeeLogic = (props) => {
    const { activeCompany, companies = [], location, id = '' } = props;
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [buttonValid, setButtonValid] = useState(false);
    const [avatar, setAvatar] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [postCode, setPostCode] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [role, setRole] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [userId, setUserId] = useState('');
    const [visibleModal, setVisibleModal] = useState(false);
    const [error, setError] = useState([]);
    const [successMsg, setSuccessMsg] = useState('');
    const [isAdminIncluded, setIsAdminIncluded] = useState(false);

    useEffect(() => {
        // Edit case
        if (props.location.state?.data?.type === 'EDIT') {
            const {
                firstname,
                lastname,
                companies,
                phone,
                email,
                created_at,
                address,
                image,
                role,
                dateOfBirth,
                userId
            } = props.location.state.data;
            const companyRoles = companies.filter(company => company.id === activeCompany._id)
            if (companyRoles[0] && companyRoles[0].roles.includes('ADMIN') ) {
                setIsAdminIncluded(true);
            }
            setFirstName(firstname);
            setLastName(lastname);
            setPhone(phone);
            setEmail(email);
            setAvatar(image);
            setRole(role);
            setIsEdit(true);
            setUserId(userId);
            if (dateOfBirth) {
                setDateOfBirth(dateOfBirth);
            }
        }
    }, []);

    /**
     * Validate required fields
     */
    useEffect(() => {
        if (
            firstName &&
            lastName &&
            lastName.length >= 2 &&
            role &&
            dateOfBirth &&
            email &&
            phone
        ) {
            setButtonValid(true);
        } else {
            setButtonValid(false);
        }
    }, [firstName, lastName, role, dateOfBirth, email, phone]);

    const navigate = (route) => {
        props.history.push(route);
    };

    /**
     * Submit new employee information to create new.
     */
    const submit = async () => {
        //TODO: do the validation after API response and show the proper message
        if (buttonValid) {
            setLoading(true);
            // Clear error before call API
            setError([]);

            if (isEdit) {
                const result = await props.updateEmployee(userId, {
                    email: email,
                    firstname: firstName,
                    lastname: lastName,
                    phone: phone,
                    role: role,
                    date_of_birth: dateOfBirth,
                    role_includes_admin: isAdminIncluded
                });
                if (result.isConnected) {
                    setVisibleModal(true);
                    setSuccessMsg(
                        t('EMPLOYEE_SUCCESSFULLY_UPDATED').toUpperCase()
                    );
                    if (userId === id) {
                        props.getCurrentUserInformation();
                    }
                } else if (!result.isConnected && result.error.errormsg) {
                    setError(result.error.errormsg);
                }
            } else {
                const result = await props.addNewEmployee({
                    email: email,
                    firstname: firstName,
                    lastname: lastName,
                    phone: phone,
                    type: role,
                    date_of_birth: dateOfBirth,
                    role_includes_admin: isAdminIncluded
                });
                if (result.isConnected && result.data.id) {
                    setVisibleModal(true);
                    setSuccessMsg(
                        t('EMPLOYEE_SUCCESSFULLY_ADDED').toUpperCase()
                    );
                } else if (!result.isConnected && result.error.errormsg) {
                    setError(result.error.errormsg);
                }
            }
            setLoading(false);
        }
    };

    const cancelModal = () => {
        setVisibleModal(false);
    };

    const initProps = {
        successMsg: successMsg,
        error: error,
        visibleModal: visibleModal,
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        postCode: postCode,
        country: country,
        state: state,
        dateOfBirth: dateOfBirth,
        avatar: avatar,
        loading: loading,
        role,
        buttonValid,
        setRole,
        setLoading: setLoading,
        setAvatar: setAvatar,
        setFirstName: setFirstName,
        setLastName: setLastName,
        setEmail: setEmail,
        setPhone: setPhone,
        setAddressLine1: setAddressLine1,
        setAddressLine2: setAddressLine2,
        setPostCode: setPostCode,
        setCity: setCity,
        setCountry: setCountry,
        setState: setState,
        setDateOfBirth: setDateOfBirth,
        navigate: navigate,
        submit: submit,
        cancelModal: cancelModal,
        activeCompanyName: activeCompany.name ? activeCompany.name : '',
        companies: companies,
        isAdminIncluded: isAdminIncluded,
        setIsAdminIncluded: setIsAdminIncluded
    };

    return tmpl && tmpl({ ...initProps });
};

class AddEmployeeContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <AddEmployeeLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { userReducer, companyReducer } = state;
    const { firstname, lastname, id } = userReducer;
    const { activeCompany, companies } = companyReducer;
    return {
        firstname: firstname,
        lastname: lastname,
        companies: companies,
        activeCompany: activeCompany,
        id: id
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addNewEmployee: (data) => dispatch(addNewEmployee(data)),
        updateEmployee: (id, data) => dispatch(updateEmployee(id, data)),
        getCurrentUserInformation: () => dispatch(getCurrentUserInformation())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AddEmployeeContainer)
);
