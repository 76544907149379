import API from '../../modules/httpModule';
import { FLIGHT_SALE_TYPE } from 'config/Constants';

/**
 * get flights
 * @returns
 */
export function fetchFlights(limit = 5, page = 1, customQuery) {
    return async (dispatch) => {
        try {
            let result = {};
            let query = {
                limit: limit,
                page: page
                // sort_column: 'departure',
                // sort_direction: 'asc'
            };
            if (customQuery) query = { ...query, ...customQuery };
            const response = await API.get('/flights', query);
            if (response && response.status === 200 && response.data) {
                result = response.data;
            }
            return result;
        } catch (err) {
            return {}; // empty
        }
    };
}

/**
 * get flights by type
 * @returns
 */
export function fetchFlightsByType(
    limit = 5,
    page = 1,
    type = FLIGHT_SALE_TYPE.EMPTY_LEG
) {
    return async (dispatch) => {
        try {
            let result = {};
            let query = {
                limit: limit,
                page: page,
                // sort_column: 'departure',
                // sort_direction: 'asc',
                type: type
            };

            const response = await API.get('/flights', query);
            if (response && response.status === 200 && response.data) {
                result = response.data;
            }
            return result;
        } catch (err) {
            return {}; // empty
        }
    };
}

/**
 * Search Flight by Flight code or booker name
 * @param {String} key - input search
 * @param {Boolean} paging - enable pagination
 * @param {String} status - type of FLIGHT_STATUS
 * @param {String} type - type of FLIGHT_SALE_TYPE
 * @returns flights
 */
export function searchFlight(
    key,
    paging = true,
    limit = 5,
    page = 1,
    status,
    type
) {
    return async (dispatch) => {
        try {
            let query = {
                key: key,
                paging: paging,
                limit: limit,
                page: page
                // sort_column: 'departure',
                // sort_direction: 'asc'
            };
            if (status) query['status'] = status;
            if (type) query['type'] = type;

            const response = await API.get(`/search/flights`, query);
            if (response && response.status === 200) {
                return response.data;
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * Get Flight detail by id
 * @param {String} id - booking id in string
 * @returns
 */
export function getFlightById(id) {
    return async (dispatch) => {
        try {
            const response = await API.get(`/flights/${id}`);
            if (response && response.status === 200) {
                return {
                    success: true,
                    isConnected: true,
                    data: response.data
                };
            }
        } catch (err) {
            // dispatch errors
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * get top rated airports
 * @returns
 */
export function getTopRatedAirports(limit = 5, page = 1, customQuery) {
    return async (dispatch) => {
        try {
            let result = {};
            let query = {
                limit: limit,
                page: page
            };
            if (customQuery) query = { ...query, ...customQuery };
            const response = await API.get('/top_rated_airports', {});
            if (response && response.status === 200 && response.data) {
                result = response.data;
            }
            return result;
        } catch (err) {
            return {}; // empty
        }
    };
}

/**
 * get top rated airport by id
 * @returns
 */
export function getTopRatedAirportById(id) {
    return async (dispatch) => {
        try {
            const response = await API.get(`/top_rated_airports/${id}`);
            if (response && response.status === 200) {
                return {
                    success: true,
                    isConnected: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * update top rated airport
 * @param {Object} data - item object with modified data, only some field is editable
 * @returns - status success and code is 200
 */
export function updateTopAirportById(id, data) {
    return async (dispatch) => {
        try {
            const response = await API.put(`/top_rated_airports/${id}`, data);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    isConnected: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * get top destinations
 * @returns
 */
export function getTopDestinations(limit = 5, page = 1, customQuery) {
    return async (dispatch) => {
        try {
            let result = {};
            let query = {
                limit: limit,
                page: page
            };
            if (customQuery) query = { ...query, ...customQuery };
            const response = await API.get('/routes', {});
            if (response && response.status === 200 && response.data) {
                result = response.data;
            }
            return result;
        } catch (err) {
            return {}; // empty
        }
    };
}

/**
 * get top destination by id detail
 * @returns
 */
export function getTopDestinationById(id) {
    return async (dispatch) => {
        try {
            const response = await API.get(`/routes/${id}`);
            if (response && response.status === 200) {
                return {
                    success: true,
                    isConnected: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * update top rated airport
 * @param {Object} data - item object with modified data, only some field is editable
 * @returns - status success and code is 200
 */
export function updateTopDestinationById(id, data) {
    return async (dispatch) => {
        try {
            const response = await API.put(`/routes/${id}`, data);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    isConnected: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * confirm flight
 * @param {String} id - id of Flight needs to be confirmed
 * @returns - status success and code is 200
 */
export function confirmFlightById(id) {
    return async (dispatch) => {
        try {
            const response = await API.put(`/flights/${id}/confirm`);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    isConnected: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}
