import React, { useEffect, useState } from 'react';
import { template as tmpl } from './FlightDetail.tmpl';
import {
    getBookingById,
    addCrew,
    removeCrew,
    addFbo,
    confirmBooking
} from 'redux/actions/bookingAction';
import { getFlightById } from 'redux/actions/flightAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BOOKING_STATUS } from 'redux/Constants';

const FlightDetailLogic = (props) => {
    const {
        activeCompany,
        employees,
        location = {},
        addCrew,
        removeCrew,
        addFbo,
        confirmBooking
    } = props;
    const [bookingDetail, setBookingDetail] = useState({});
    const [flightDetail, setFlightDetail] = useState({});

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { search } = location;
    /**
     * Get booking detail from backend
     */
    useEffect(() => {
        async function fetchFlightDetail() {
            let id = search;
            if (id && id[0] === '?') {
                id = id.substring(1);
            }
            const result = await props.getFlightById(id);
            if (result.success && result.data) {
                setFlightDetail(result.data);
            }
        }
        fetchFlightDetail();
    }, [activeCompany]);

    const changeAvailable = async (id) => {
        let index = serviceList.findIndex((obj) => {
            return obj.id === id;
        });
        let tempServiceList = [...serviceList];
        tempServiceList[index].available = !tempServiceList[index].available;
        const result = await props.updateServiceItemById(
            id,
            tempServiceList[index]
        );
        if (result.isConnected) {
            setServiceList(tempServiceList);
        } else {
            //TODO: show dialog error when fail at calling API
        }
    };

    const onAddCrew = async (flightId, crew) => {
        if (crew && crew.user_id) {
            await addCrew(flightId, { user_id: crew.user_id, role: crew.role });
        }
    };

    const onRemoveCrew = async (flightId, crew) => {
        if (crew && crew.user_id) {
            let rs = await removeCrew(flightId, {
                user_id: crew.user_id
            });
            return rs;
        }
    };

    const onAddFBO = async (flightId, fboId, type) => {
        if (flightId && fboId) {
            let params = {};
            if (type === 'departure') {
                params.departure_fbo = fboId;
            } else {
                params.arrival_fbo = fboId;
            }
            await addFbo(flightId, params);
        }
    };

    const onConfirmBooking = async (
        bookingId = '',
        date = new Date(),
        markAsPaid = false
    ) => {
        let body = {
            date: date,
            marked_as_paid: markAsPaid
        };
        let rs = await confirmBooking(bookingId, body);
        if (rs.success) {
            let modal = {
                show: true,
                type: 'success',
                description: t('BOOKING_HAVE_BEEN_CONFIRMED'),
                title: t('CONFIRM_BOOKING_SUCCESS'),
                buttonTitle: t('VIEW_ITINERARIES').toUpperCase()
            };

            // update status booking CONFIRMED
            setBookingDetail({
                ...bookingDetail,
                status: BOOKING_STATUS.CONFIRMED
            });

            dispatch({ type: 'SETTING_SHOW_MODAL', data: modal });
        } else {
            let modal = {
                show: true,
                type: 'error',
                title: t('CONFIRM_BOOKING_FAILED'),
                buttonTitle: 'OK'
            };
            dispatch({ type: 'SETTING_SHOW_MODAL', data: modal });
        }
    };

    const navigate = (route, search, state) => {
        props.history.push({
            pathname: route,
            search: search,
            state: state ? { data: state } : null
        });
    };

    const initProps = {
        flightDetail: flightDetail,
        activeCompany: activeCompany,
        navigate: navigate,
        changeAvailable: changeAvailable,
        addCrew: onAddCrew,
        removeCrew: onRemoveCrew,
        onAddFBO: onAddFBO,
        onConfirmBooking: onConfirmBooking
    };
    return tmpl && tmpl({ ...initProps });
};

class FlightDetailContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <FlightDetailLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { employees, activeCompany } = companyReducer;
    return {
        employees: employees,
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getBookingById: (id) => dispatch(getBookingById(id)),
        addCrew: (id, data) => dispatch(addCrew(id, data)),
        removeCrew: (id, data) => dispatch(removeCrew(id, data)),
        addFbo: (flight_id, data) => dispatch(addFbo(flight_id, data)),
        confirmBooking: (bookingId, data) =>
            dispatch(confirmBooking(bookingId, data)),
        getFlightById: (id) => dispatch(getFlightById(id))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(FlightDetailContainer)
);
