import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { Col, Row, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { Header, Popup, UploadAvatar, ButtonCustom } from 'components';
import { ButtonType } from 'components/Antd/Button';
import '../fbo.css';
import {
    CustomerDetailContainer,
    ContainerWrapper,
    BottomContainer,
    Label
} from '../styles';
import { HeaderInfo } from 'components/Cards';
import { SearchAirports } from 'components/Search';
import _ from 'lodash';
import { FBO_LIST } from 'routes/main';
import { EMAIL_EXISTS, COMPANY_TYPE, SELECT_TYPE } from 'redux/Constants';
import { AutoComplete } from 'components';
import { useSelector } from 'react-redux';
import { getWindowDimensions } from 'utils';

const template = (props) => {
    const {
        onSubmit,
        visibleModal = false,
        error = '',
        successMsg = ''
    } = props;

    const { companyReducer = {} } = useSelector((state) => state);
    const { expandedSidebar } = useSelector(
        (state) => state.settingReducer || false
    );
    const [airportInputWidth, setAirportInputWidth] = useState('770px');
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (expandedSidebar && windowDimensions.width <= 1024) {
            setAirportInputWidth('370px');
        } else if (windowDimensions.width <= 768) {
            setAirportInputWidth('370px');
        } else {
            setAirportInputWidth('770px');
        }
    }, [expandedSidebar]);

    const { t, i18n } = useTranslation();

    const [imageUrl, setImageUrl] = useState('');
    const [photo, setPhoto] = useState(null);

    const [loading, setLoading] = useState(false);

    const [fboName, setFBOName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [airport, setAirport] = useState({});

    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [postCode, setPostCode] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState({});
    const [state, setState] = useState('');
    const [frequency, setFrequency] = useState('');

    const validateData = () => {
        if (
            !fboName ||
            !email ||
            !phone ||
            !addressLine1 ||
            !postCode ||
            !city
        ) {
            return false;
        }

        if (_.isEmpty(country) || (!country.id && !country.short_code)) {
            return false;
        }
        if (_.isEmpty(airport) || !airport.id) {
            return false;
        }

        return true;
    };

    const onSelectCountry = (value) => {
        setCountry(value);
    };

    const onSelectAirport = (value) => {
        setAirport(value);
    };

    const onSearchCountries = (key) => {
        const { countries } = companyReducer;

        const input = key.toLowerCase();
        const result = countries.filter((item) => {
            if (
                item.english_name.toLowerCase().includes(input) ||
                item.short_code.toLowerCase().includes(input)
            ) {
                return item;
            }
        });
        return result || [];
    };

    const onSubmitForm = () => {
        let valid = validateData();
        if (!valid) return;

        const data = {
            airport_id: airport.id,
            contact: {
                email: email,
                phone: phone
            },
            location: {
                address_line1: addressLine1,
                address_line2: addressLine2,
                altitude: airport.location?.altitude,
                city: city,
                country: country,
                state: state,
                geometry: airport.location?.geometry,
                postalcode: postCode,
                timezone: airport.location?.timezone
            },
            frequency: frequency,
            name: fboName,
            type: COMPANY_TYPE.HANDLER
        };

        onSubmit(data, photo);
    };

    return (
        <AddFboContainer expandedSidebar={expandedSidebar}>
            <CustomerDetailContainer>
                <Header
                    title={i18n.t('FBO')}
                    description={i18n.t('ADD_NEW_FBO')}
                />
            </CustomerDetailContainer>
            <ContainerWrapper>
                <HeaderInfo title={i18n.t('FBO_INFO').toUpperCase()} />
                <Row className="add-fbo-item">
                    <UploadSection>
                        <UploadAvatar
                            imageUrl={imageUrl}
                            loading={loading}
                            setImageUrl={setImageUrl}
                            setPhoto={setPhoto}
                        />
                    </UploadSection>
                </Row>

                <Row className="add-fbo-item">
                    <Col>
                        <Label>{i18n.t('FBO_NAME').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setFBOName(val.target.value)}
                            className="base left"
                            placeholder={i18n.t('FBO_NAME')}
                            value={fboName}
                        />
                    </Col>
                    <Col>
                        <Label>{i18n.t('FREQ').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setFrequency(val.target.value)}
                            className="base"
                            placeholder={i18n.t('FREQUENCY')}
                            value={frequency}
                        />
                    </Col>
                </Row>
                <Row className="add-fbo-item">
                    <Col>
                        <Row>
                            <Label>
                                {i18n.t('EMAIL_ADDRESS').toUpperCase()}
                            </Label>
                        </Row>
                        <Row>
                            <Input
                                onChange={(val) => setEmail(val.target.value)}
                                className="base left"
                                placeholder={i18n.t('EMAIL_ADDRESS')}
                                value={email}
                            />
                        </Row>
                        <Row>
                            {error.includes(EMAIL_EXISTS) ? (
                                <ErrorSection>{t('EMAIL_EXISTS')}</ErrorSection>
                            ) : null}
                        </Row>
                    </Col>
                    <Col>
                        <Label>{i18n.t('PHONE_NUMBER').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setPhone(val.target.value)}
                            className="base"
                            placeholder={i18n.t('PHONE_NUMBER')}
                            value={phone}
                        />
                    </Col>
                </Row>
                <Row className="add-fbo-item">
                    <Col>
                        <Label>{i18n.t('AIRPORT').toUpperCase()}</Label>
                        <SearchAirports
                            airport={airport}
                            onSelectAirport={onSelectAirport}
                            inputStyle={{
                                width: airportInputWidth
                                // minWidth: '370px',
                                // maxWidth: '770px'
                            }}
                        />
                    </Col>
                </Row>
                <Row className="add-fbo-item">
                    <Col>
                        <Label>
                            {i18n.t('FBO_ADDRES_LINE_1').toUpperCase()}
                        </Label>
                        <Input
                            onChange={(val) =>
                                setAddressLine1(val.target.value)
                            }
                            className="fbo-address-input address"
                            placeholder={i18n.t('FBO_ADDRES_LINE_1')}
                            value={addressLine1}
                        />
                    </Col>
                </Row>
                <Row className="add-fbo-item">
                    <Col>
                        <Label>
                            {i18n.t('FBO_ADDRES_LINE_2').toUpperCase()}
                        </Label>
                        <Input
                            onChange={(val) =>
                                setAddressLine2(val.target.value)
                            }
                            className="fbo-address-input address"
                            placeholder={i18n.t('FBO_ADDRES_LINE_2')}
                            value={addressLine2}
                        />
                    </Col>
                </Row>
                <Row className="add-fbo-item">
                    <Col>
                        <Label>{i18n.t('POST_CODE').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setPostCode(val.target.value)}
                            className="base left"
                            placeholder={i18n.t('POST_CODE')}
                            value={postCode}
                        />
                    </Col>
                    <Col>
                        <Label>{i18n.t('CITY').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setCity(val.target.value)}
                            className="base"
                            placeholder={i18n.t('CITY')}
                            value={city}
                        />
                    </Col>
                </Row>
                <Row className="add-fbo-item">
                    <Col>
                        <Label>{i18n.t('STATE').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setState(val.target.value)}
                            className="base left"
                            placeholder={i18n.t('STATE')}
                            value={state}
                        />
                    </Col>
                    <Col>
                        <Label>{i18n.t('COUNTRY').toUpperCase()}</Label>
                        <AutoComplete
                            // defaultValue={country}
                            onSelectItem={onSelectCountry}
                            type={SELECT_TYPE.COUNTRY}
                            onSearchItems={onSearchCountries}
                            inputStyle={{
                                minWidth: '300px',
                                maxWidth: '370px',
                                width: '40vw',
                                height: '50px'
                            }}
                        />
                    </Col>
                </Row>
            </ContainerWrapper>
            <BottomContainer>
                <ButtonCustom
                    size="large"
                    btnType={ButtonType.PRIMARY}
                    width="255px"
                    disabled={!validateData()}
                    title={i18n.t('SUBMIT_FBO_INFO').toUpperCase()}
                    onClick={onSubmitForm}
                />
            </BottomContainer>
            <Popup
                cancelModal={() => {
                    props.cancelModal();
                }}
                onOk={() => {
                    props.navigate(FBO_LIST);
                }}
                visibleModal={visibleModal}
                successText={successMsg}
                buttonTitle={t('VIEW_FBO_LIST').toUpperCase()}
            />
        </AddFboContainer>
    );
};

const AddFboContainer = styled.div`
    .fbo-address-input {
        /* border: 1px solid rgba(36, 36, 45, 0.15); */
        border-radius: 3px;
        height: 50px;
        width: 75vw;
        min-width: 370px;
        max-width: 770px;
    }
    .right {
        margin-left: 30px;
    }
    .left {
        margin-right: 30px;
    }
    .input-add-item {
        /* border: 1px solid rgba(36, 36, 45, 0.15); */
        border-radius: 3px;
        width: 370px;
        height: 50px;
        padding-top: 8px;
    }
    .base {
        /* border: 1px solid rgba(36, 36, 45, 0.15); */
        border-radius: 3px;
        width: 40vw;
        max-width: 370px;
        min-width: 300px;
        height: 50px;
        /* margin-bottom: 10px; */
    }
    .address {
        /* border: 1px solid rgba(36, 36, 45, 0.15); */
        border-radius: 3px;
        width: 770px;
        height: 50px;
        /* margin-bottom: 10px; */
    }
    @media (max-width: 1024px) {
        .address {
            width: ${(props) => (props.expandedSidebar ? '370px' : '770px')};
        }
    }
    @media (max-width: 768px) {
        .address {
            width: 370px;
        }
    }
`;

const UploadSection = styled.div`
    margin-top: 10px;
`;

const ErrorSection = styled.span`
    color: #e2253d;
    font-family: AvenirNext-Medium;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    margin-top: 7px;
`;

export { template };
