import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { InputNumber } from 'antd';
import { Text, ButtonCustom } from 'components';
import { useTranslation } from 'react-i18next';
import {
    numberFormat,
    getTotalInvoice,
    getFlightName,
    getPaymentStatus
} from 'utils';
import _ from 'lodash';
import { checkedIcon } from 'assets/images/icons';

const RowItemComponent = ({
    title = '',
    subtitle = '',
    price = 0,
    quantity = 1,
    total = 0
}) => {
    return (
        <tr className="invoice-row-item">
            <td className="invoice-item">
                <Title>{title}</Title>
                <SubTitle>{subtitle}</SubTitle>
            </td>
            <td className="invoice-price">
                <Price>{numberFormat(price, 'USD', 1)}</Price>
            </td>
            <td className="invoice-quantity">
                <Quantity>{quantity}</Quantity>
            </td>
            <td className="invoice-total-price">
                <TotalPrice>{numberFormat(total, 'USD', 1)}</TotalPrice>
            </td>
            {/* <td className="invoice-visible-addressee">
                        <VisibleAddressee visible={false} />
                    </td> */}
        </tr>
    );
};

const BookingInvoiceCard = (props) => {
    const { leg = {}, paymentStatus } = props;
    const {
        id,
        index = 0,
        type,
        flight_id,
        flight_code,
        from_airport = {},
        to_airport = {},
        price_components = {},
        flight = {}
    } = leg;
    const { operator = {} } = flight;
    const { t, i18n } = useTranslation();

    const renderTableHeader = () => {
        return (
            <>
                <th style={{ paddingLeft: 22 }}>
                    {t('DESCRIPTION').toUpperCase()}
                </th>
                <th>{t('PRICE').toUpperCase()}</th>
                <th>{t('QUANTITY').toUpperCase()}</th>
                <th>{t('TOTAL').toUpperCase()}</th>
                {/* <th>{t('VISIBLE_ADDRESSEE').toUpperCase()}</th> */}
            </>
        );
    };

    const renderTableData = () => {
        const {
            positioning_fee = 0,
            revenue_fee = 0,
            landing_fee = 0,
            parking_fee = 0,
            departure_fee = 0,
            short_term_increment = 0,
            service_items_fee = 0
        } = price_components;
        const flightName = getFlightName(type, index, from_airport, to_airport);
        return (
            <>
                {/* POSITIONING_FEE */}
                <RowItemComponent
                    title={t('POSITIONING_FEE').toUpperCase()}
                    subtitle={t('OPERATOR_FEES')}
                    price={positioning_fee}
                    quantity={1}
                    total={positioning_fee}
                />

                {/* LANDING_FEE */}
                <RowItemComponent
                    title={t('LANDING_FEE').toUpperCase()}
                    subtitle={t('AIRPORT_FESS')}
                    price={landing_fee}
                    quantity={1}
                    total={landing_fee}
                />

                {/* REVENUE_FEE */}
                <RowItemComponent
                    title={t('REVENUE_FEE').toUpperCase()}
                    subtitle={t('OPERATOR_FEES')}
                    price={revenue_fee}
                    quantity={1}
                    total={revenue_fee}
                />

                {/* PARKING_FEE */}
                <RowItemComponent
                    title={t('PARKING_FEE').toUpperCase()}
                    subtitle={t('AIRPORT_FESS')}
                    price={parking_fee}
                    quantity={1}
                    total={parking_fee}
                />

                {/* DEPARTURE_FEE */}
                <RowItemComponent
                    title={t('DEPARTURE_FEE').toUpperCase()}
                    subtitle={t('AIRPORT_FESS')}
                    price={departure_fee}
                    quantity={1}
                    total={departure_fee}
                />

                {/* SHORT_TERM_BOOKING_FEE */}
                <RowItemComponent
                    title={t('SHORT_TERM_BOOKING_FEE').toUpperCase()}
                    subtitle={t('OPERATOR_FEES')}
                    price={short_term_increment}
                    quantity={1}
                    total={short_term_increment}
                />

                {/* SERVICES */}
                <RowItemComponent
                    title={t('SERVICES').toUpperCase()}
                    subtitle={t('CATERING_AND_CHAMPAGGE')}
                    price={service_items_fee}
                    quantity={1}
                    total={service_items_fee}
                />

                {/* FLIGHTS */}
                <RowItemComponent
                    title={t('FLIGHTS').toUpperCase()}
                    subtitle={flightName}
                    price={revenue_fee}
                    quantity={1}
                    total={revenue_fee}
                />
            </>
        );
    };

    const _renderTotal = () => {
        const { total_ex_vat, total_inc_vat, total_vat } = price_components;
        return (
            <TotalWrapper>
                <div style={{ width: '35%' }}>
                    <RowWrapper>
                        <LabelLight>
                            {t('TOTAL_EX_VAT').toUpperCase()}
                        </LabelLight>
                        <ValueItem>
                            {numberFormat(total_ex_vat, 'USD', 1)}
                        </ValueItem>
                    </RowWrapper>
                </div>
                <div style={{ width: '35%' }}>
                    <RowWrapper style={{ marginTop: 10 }}>
                        <LabelLight>{t('VAT').toUpperCase()}</LabelLight>
                        <ValueItem>
                            {numberFormat(total_vat, 'USD', 1)}
                        </ValueItem>
                    </RowWrapper>
                </div>
                <div style={{ width: '35%' }}>
                    <RowWrapper style={{ marginTop: 10 }}>
                        <LabelLight>
                            {t('TOTAL_ICN_VAT').toUpperCase()}
                        </LabelLight>
                        <ValueItem>
                            {numberFormat(total_inc_vat, 'USD', 1)}
                        </ValueItem>
                    </RowWrapper>
                </div>
                {/* TOTAL_ICN_VAT */}

                <Divider />
                <div style={{ width: '35%' }}>
                    <RowWrapper style={{ marginTop: 10 }}>
                        <LabelLight size="14px">
                            {t('TOTAL_PAYOUT').toUpperCase()}
                        </LabelLight>
                        <ValueItem size="14px">
                            {numberFormat(total_inc_vat, 'USD', 1)}
                        </ValueItem>
                    </RowWrapper>
                </div>
            </TotalWrapper>
        );
    };

    return (
        <Container>
            <div
                style={{
                    display: 'flex',
                    marginTop: 20,
                    justifyContent: 'space-between'
                }}
            >
                <div style={{ alignSelf: 'center' }}>
                    <Text textStyle="heading5">
                        {t(`INVOICE`).toUpperCase()}
                    </Text>
                </div>

                <div>
                    {paymentStatus && paymentStatus !== 'PAID' && (
                        <ButtonWrapper>
                            <Text textStyle="tableText2">
                                <Icon src={checkedIcon} />
                                {t('MARK_AS_PAID').toUpperCase()}
                            </Text>
                        </ButtonWrapper>
                    )}
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    paddingBottom: 10,
                    justifyContent: 'space-between'
                }}
            >
                <div style={{ alignSelf: 'center' }}>
                    <Text textStyle="heading5">
                        {`${t('INVOICE_SENT_TO')} ${operator?.name || ''}`}
                    </Text>
                </div>
            </div>

            <InvoiceWrapper>
                <table id="invoices">
                    <tbody>
                        <tr>{renderTableHeader()}</tr>
                        {renderTableData()}
                    </tbody>
                </table>
            </InvoiceWrapper>
            <InvoiceWrapper>{_renderTotal()}</InvoiceWrapper>
        </Container>
    );
};

export default BookingInvoiceCard;

const Container = styled.div`
    padding-top: 10px;

    #invoices {
        width: 100%;
        margin-right: 20px;
    }

    #invoices td,
    #invoices th {
        padding: 8px;
    }

    #invoices tr:hover {
        background-color: #f7f8fb;
    }

    #invoices th {
        padding-top: 12px;
        font-family: AvenirNext-DemiBold;
        font-size: 10px;
        letter-spacing: 0.7px;
        line-height: 18px;
        background-color: #f7f8fb;
        color: #65686f;
        text-align: left;
    }

    .invoice-row-item {
        border-bottom: 1px solid rgba(14, 14, 18, 0.1);
    }
    .invoice-item {
        width: 40%;
    }
    .invoice-price {
        width: 15%;
    }
    .invoice-quantity {
        width: 15%;
    }
    .invoice-total-price {
        width: 15%;
    }
    .invoice-visible-addressee {
        width: 15%;
    }
`;

const ButtonWrapper = styled.button`
    border: 1px solid #cdd1d8;
    background-color: transparent;
    border-radius: 2px;
    min-width: 124px;
    height: 32px;
`;

const InvoiceWrapper = styled.div`
    background-color: #fff;
    border-radius: 2px;
`;

const Icon = styled.img`
    margin-right: 5px;
`;

const Title = styled.div`
    color: #121216;
    margin-left: 15px;
    font-family: AvenirNext-Bold;
    font-size: 10px;
    letter-spacing: 0.8px;
    line-height: 12px;
    margin-top: 5px;
    margin-bottom: 10px;
`;

const SubTitle = styled.div`
    color: #65686f;
    font-family: Lato;
    font-size: 14px;
    line-height: 24px;
    margin-left: 15px;
`;

const Price = styled.span`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 14px;
    line-height: 17px;
`;

const Quantity = styled.span`
    color: #65686f;
    font-family: Lato;
    font-size: 14px;
    line-height: 17px;
`;

const TotalPrice = styled.span`
    color: #121216;
    font-family: AvenirNext-Medium;
    font-size: 14px;
    line-height: 17px;
`;

const TotalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 3px;
    margin-bottom: 20px;
    padding: 20px;
`;

const RowWrapper = styled.div`
    justify-content: space-between;
    width: 65%;
    display: flex;
    align-items: center;
`;

const LabelLight = styled.div`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: ${(props) => props.size || '10px'};
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
`;

const ValueItem = styled.div`
    color: #121216;
    font-family: AvenirNext-Bold;
    font-size: ${(props) => props.size || '12px'};
    letter-spacing: 0.4px;
    line-height: ${(props) => props.lineHeight || '24px'};
    text-align: right;
    margin-left: 25px;
`;

const Divider = styled.div`
    background-color: #0e0e12;
    opacity: 0.1;
    margin-top: 10px;
    width: 100%;
    height: 2px;
`;
