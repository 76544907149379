export const findCountryWithCode = (code = '') => {
    const country = dataCountries.find(
        (v) => v.code.toLowerCase() === code.toLowerCase()
    );
    return country;
};

export const findCountries = (input) => {
    const countries = dataCountries.filter(
        (v) => input === v.code.toLowerCase() || input === v.value.toLowerCase()
    );
    return countries;
};

export const dataCountries = [
    {
        short_code: 'AF',
        english_name: 'Afghanistan',
        native_name: 'Afganistan/Afqanestan',
        calling_code: '+93'
    },
    {
        short_code: 'AX',
        english_name: 'Åland Islands',
        native_name: 'Åland Islands',
        calling_code: null
    },
    {
        short_code: 'AL',
        english_name: 'Albania',
        native_name: 'Shqipëria',
        calling_code: '+355'
    },
    {
        short_code: 'DZ',
        english_name: 'Algeria',
        native_name: 'Al-Jazair/Algérie',
        calling_code: '+213'
    },
    {
        short_code: 'AS',
        english_name: 'American Samoa',
        native_name: 'Amerika Samoa',
        calling_code: '+1 684'
    },
    {
        short_code: 'AD',
        english_name: 'Andorra',
        native_name: 'Andorra',
        calling_code: '+376'
    },
    {
        short_code: 'AO',
        english_name: 'Angola',
        native_name: 'Angola',
        calling_code: '+244'
    },
    {
        short_code: 'AI',
        english_name: 'Anguilla',
        native_name: 'Anguilla',
        calling_code: '+1 264'
    },
    {
        short_code: 'AQ',
        english_name: 'Antarctica',
        native_name: 'Antarctica',
        calling_code: null
    },
    {
        short_code: 'AG',
        english_name: 'Antigua and Barbuda',
        native_name: 'Antigua and Barbuda',
        calling_code: '+1268'
    },
    {
        short_code: 'AR',
        english_name: 'Argentina',
        native_name: 'Argentina',
        calling_code: '+54'
    },
    {
        short_code: 'AM',
        english_name: 'Armenia',
        native_name: 'Hajastan',
        calling_code: '+374'
    },
    {
        short_code: 'AW',
        english_name: 'Aruba',
        native_name: 'Aruba',
        calling_code: '+297'
    },
    {
        short_code: 'AU',
        english_name: 'Australia',
        native_name: 'Australia',
        calling_code: '+61'
    },
    {
        short_code: 'AT',
        english_name: 'Austria',
        native_name: 'Österreich',
        calling_code: '+43'
    },
    {
        short_code: 'AZ',
        english_name: 'Azerbaijan',
        native_name: 'Azärbaycan',
        calling_code: '+994'
    },
    {
        short_code: 'BS',
        english_name: 'Bahamas',
        native_name: 'The Bahamas',
        calling_code: '+1 242'
    },
    {
        short_code: 'BH',
        english_name: 'Bahrain',
        native_name: 'Al-Bahrayn',
        calling_code: '+973'
    },
    {
        short_code: 'BD',
        english_name: 'Bangladesh',
        native_name: 'Bangladesh',
        calling_code: '+880'
    },
    {
        short_code: 'BB',
        english_name: 'Barbados',
        native_name: 'Barbados',
        calling_code: '+1 246'
    },
    {
        short_code: 'BY',
        english_name: 'Belarus',
        native_name: 'Belarus',
        calling_code: '+375'
    },
    {
        short_code: 'BE',
        english_name: 'Belgium',
        native_name: 'België/Belgique',
        calling_code: '+32'
    },
    {
        short_code: 'BZ',
        english_name: 'Belize',
        native_name: 'Belize',
        calling_code: '+501'
    },
    {
        short_code: 'BJ',
        english_name: 'Benin',
        native_name: 'Bénin',
        calling_code: '+229'
    },
    {
        short_code: 'BM',
        english_name: 'Bermuda',
        native_name: 'Bermuda',
        calling_code: '+1 441'
    },
    {
        short_code: 'BT',
        english_name: 'Bhutan',
        native_name: 'Druk-Yul',
        calling_code: '+975'
    },
    {
        short_code: 'BO',
        english_name: 'Bolivia',
        native_name: 'Bolivia',
        calling_code: '+591'
    },
    {
        short_code: 'BA',
        english_name: 'Bosnia and Herzegovina',
        native_name: 'Bosna i Hercegovina',
        calling_code: '+387'
    },
    {
        short_code: 'BW',
        english_name: 'Botswana',
        native_name: 'Botswana',
        calling_code: '+267'
    },
    {
        short_code: 'BR',
        english_name: 'Brazil',
        native_name: 'Brasil',
        calling_code: '+55'
    },
    {
        short_code: 'IO',
        english_name: 'British Indian Ocean Territory',
        native_name: 'British Indian Ocean Territory',
        calling_code: '+246'
    },
    {
        short_code: 'BN',
        english_name: 'Brunei Darussalam',
        native_name: 'Brunei Darussalam',
        calling_code: '+673'
    },
    {
        short_code: 'BG',
        english_name: 'Bulgaria',
        native_name: 'Balgarija',
        calling_code: '+359'
    },
    {
        short_code: 'BF',
        english_name: 'Burkina Faso',
        native_name: 'Burkina Faso',
        calling_code: '+226'
    },
    {
        short_code: 'BI',
        english_name: 'Burundi',
        native_name: 'Burundi/Uburundi',
        calling_code: '+257'
    },
    {
        short_code: 'KH',
        english_name: 'Cambodia',
        native_name: 'Kâmpuchéa',
        calling_code: '+855'
    },
    {
        short_code: 'CM',
        english_name: 'Cameroon',
        native_name: 'Cameroun/Cameroon',
        calling_code: '+237'
    },
    {
        short_code: 'CA',
        english_name: 'Canada',
        native_name: 'Canada',
        calling_code: '+1'
    },
    {
        short_code: 'CV',
        english_name: 'Cape Verde',
        native_name: 'Cabo Verde',
        calling_code: '+238'
    },
    {
        short_code: 'KY',
        english_name: 'Cayman Islands',
        native_name: 'Cayman Islands',
        calling_code: '+ 345'
    },
    {
        short_code: 'CF',
        english_name: 'Central African Republic',
        native_name: 'Centrafrique/Bê-Afrîka',
        calling_code: '+236'
    },
    {
        short_code: 'TD',
        english_name: 'Chad',
        native_name: 'Tchad/Tshad',
        calling_code: '+235'
    },
    {
        short_code: 'CL',
        english_name: 'Chile',
        native_name: 'Chile',
        calling_code: '+56'
    },
    {
        short_code: 'CN',
        english_name: 'China',
        native_name: 'Zhongquo',
        calling_code: '+86'
    },
    {
        short_code: 'CX',
        english_name: 'Christmas Island',
        native_name: 'Christmas Island',
        calling_code: '+61'
    },
    {
        short_code: 'CC',
        english_name: 'Cocos (Keeling) Islands',
        native_name: 'Cocos (Keeling) Islands',
        calling_code: '+61'
    },
    {
        short_code: 'CO',
        english_name: 'Colombia',
        native_name: 'Colombia',
        calling_code: '+57'
    },
    {
        short_code: 'KM',
        english_name: 'Comoros',
        native_name: 'Komori/Comores',
        calling_code: '+269'
    },
    {
        short_code: 'CG',
        english_name: 'Congo',
        native_name: 'Congo',
        calling_code: '+242'
    },
    {
        short_code: 'CD',
        english_name: 'Congo, The Democratic Republic of the',
        native_name: 'République Démocratique du Congo',
        calling_code: '+243'
    },
    {
        short_code: 'CK',
        english_name: 'Cook Islands',
        native_name: 'The Cook Islands',
        calling_code: '+682'
    },
    {
        short_code: 'CR',
        english_name: 'Costa Rica',
        native_name: 'Costa Rica',
        calling_code: '+506'
    },
    {
        short_code: 'CI',
        english_name: "Cote D'Ivoire",
        native_name: 'Côte dIvoire',
        calling_code: '+225'
    },
    {
        short_code: 'HR',
        english_name: 'Croatia',
        native_name: 'Hrvatska',
        calling_code: '+385'
    },
    {
        short_code: 'CU',
        english_name: 'Cuba',
        native_name: 'Cuba',
        calling_code: '+53'
    },
    {
        short_code: 'CY',
        english_name: 'Cyprus',
        native_name: 'Kýpros/Kibris',
        calling_code: '+537'
    },
    {
        short_code: 'CZ',
        english_name: 'Czech Republic',
        native_name: 'Czech Republic',
        calling_code: '+420'
    },
    {
        short_code: 'DK',
        english_name: 'Denmark',
        native_name: 'Danmark',
        calling_code: '+45'
    },
    {
        short_code: 'DJ',
        english_name: 'Djibouti',
        native_name: 'Djibouti/Jibuti',
        calling_code: '+253'
    },
    {
        short_code: 'DM',
        english_name: 'Dominica',
        native_name: 'Dominica',
        calling_code: '+1 767'
    },
    {
        short_code: 'DO',
        english_name: 'Dominican Republic',
        native_name: 'República Dominicana',
        calling_code: '+1 849'
    },
    {
        short_code: 'EC',
        english_name: 'Ecuador',
        native_name: 'Ecuador',
        calling_code: '+593'
    },
    {
        short_code: 'EG',
        english_name: 'Egypt',
        native_name: 'Misr',
        calling_code: '+20'
    },
    {
        short_code: 'SV',
        english_name: 'El Salvador',
        native_name: 'El Salvador',
        calling_code: '+503'
    },
    {
        short_code: 'GQ',
        english_name: 'Equatorial Guinea',
        native_name: 'Guinea Ecuatorial',
        calling_code: '+240'
    },
    {
        short_code: 'ER',
        english_name: 'Eritrea',
        native_name: 'Ertra',
        calling_code: '+291'
    },
    {
        short_code: 'EE',
        english_name: 'Estonia',
        native_name: 'Eesti',
        calling_code: '+372'
    },
    {
        short_code: 'ET',
        english_name: 'Ethiopia',
        native_name: 'YeItyop´iya',
        calling_code: '+251'
    },
    {
        short_code: 'FK',
        english_name: 'Falkland Islands (Malvinas)',
        native_name: 'Falkland Islands',
        calling_code: '+500'
    },
    {
        short_code: 'FO',
        english_name: 'Faroe Islands',
        native_name: 'Føroyar',
        calling_code: '+298'
    },
    {
        short_code: 'FJ',
        english_name: 'Fiji',
        native_name: 'Fiji Islands',
        calling_code: '+679'
    },
    {
        short_code: 'FI',
        english_name: 'Finland',
        native_name: 'Suomi',
        calling_code: '+358'
    },
    {
        short_code: 'FR',
        english_name: 'France',
        native_name: 'France',
        calling_code: '+33'
    },
    {
        short_code: 'GF',
        english_name: 'French Guiana',
        native_name: 'Guyane française',
        calling_code: '+594'
    },
    {
        short_code: 'PF',
        english_name: 'French Polynesia',
        native_name: 'Polynésie française',
        calling_code: '+689'
    },
    {
        short_code: 'GA',
        english_name: 'Gabon',
        native_name: 'Le Gabon',
        calling_code: '+241'
    },
    {
        short_code: 'GM',
        english_name: 'Gambia',
        native_name: 'The Gambia',
        calling_code: '+220'
    },
    {
        short_code: 'GE',
        english_name: 'Georgia',
        native_name: 'Sakartvelo',
        calling_code: '+995'
    },
    {
        short_code: 'DE',
        english_name: 'Germany',
        native_name: 'Deutschland',
        calling_code: '+49'
    },
    {
        short_code: 'GH',
        english_name: 'Ghana',
        native_name: 'Ghana',
        calling_code: '+233'
    },
    {
        short_code: 'GI',
        english_name: 'Gibraltar',
        native_name: 'Gibraltar',
        calling_code: '+350'
    },
    {
        short_code: 'GR',
        english_name: 'Greece',
        native_name: 'Elláda',
        calling_code: '+30'
    },
    {
        short_code: 'GL',
        english_name: 'Greenland',
        native_name: 'Kalaallit Nunaat/Grønland',
        calling_code: '+299'
    },
    {
        short_code: 'GD',
        english_name: 'Grenada',
        native_name: 'Grenada',
        calling_code: '+1 473'
    },
    {
        short_code: 'GP',
        english_name: 'Guadeloupe',
        native_name: 'Guadeloupe',
        calling_code: '+590'
    },
    {
        short_code: 'GU',
        english_name: 'Guam',
        native_name: 'Guam',
        calling_code: '+1 671'
    },
    {
        short_code: 'GT',
        english_name: 'Guatemala',
        native_name: 'Guatemala',
        calling_code: '+502'
    },
    {
        short_code: 'GG',
        english_name: 'Guernsey',
        native_name: 'Bailiwick of Guernsey',
        calling_code: '+44'
    },
    {
        short_code: 'GN',
        english_name: 'Guinea',
        native_name: 'Guinée',
        calling_code: '+224'
    },
    {
        short_code: 'GW',
        english_name: 'Guinea-Bissau',
        native_name: 'Guiné-Bissau',
        calling_code: '+245'
    },
    {
        short_code: 'GY',
        english_name: 'Guyana',
        native_name: 'Guyana',
        calling_code: '+595'
    },
    {
        short_code: 'HT',
        english_name: 'Haiti',
        native_name: 'Haïti/Dayti',
        calling_code: '+509'
    },
    {
        short_code: 'VA',
        english_name: 'Holy See (Vatican City State)',
        native_name: 'Santa Sede/Città del Vaticano',
        calling_code: '+379'
    },
    {
        short_code: 'HN',
        english_name: 'Honduras',
        native_name: 'Honduras',
        calling_code: '+504'
    },
    {
        short_code: 'HK',
        english_name: 'Hong Kong',
        native_name: 'Xianggang/Hong Kong',
        calling_code: '+852'
    },
    {
        short_code: 'HU',
        english_name: 'Hungary',
        native_name: 'Magyarország',
        calling_code: '+36'
    },
    {
        short_code: 'IS',
        english_name: 'Iceland',
        native_name: 'Ísland',
        calling_code: '+354'
    },
    {
        short_code: 'IN',
        english_name: 'India',
        native_name: 'Bharat/India',
        calling_code: '+91'
    },
    {
        short_code: 'ID',
        english_name: 'Indonesia',
        native_name: 'Indonesia',
        calling_code: '+62'
    },
    {
        short_code: 'IR',
        english_name: 'Iran, Islamic Republic Of',
        native_name: 'Iran',
        calling_code: '+98'
    },
    {
        short_code: 'IQ',
        english_name: 'Iraq',
        native_name: 'Al-´Iraq',
        calling_code: '+964'
    },
    {
        short_code: 'IE',
        english_name: 'Ireland',
        native_name: 'Ireland/Éire',
        calling_code: '+353'
    },
    {
        short_code: 'IM',
        english_name: 'Isle of Man',
        native_name: 'Isle of Man',
        calling_code: '+44'
    },
    {
        short_code: 'IL',
        english_name: 'Israel',
        native_name: 'Yisrael/Israil',
        calling_code: '+972'
    },
    {
        short_code: 'IT',
        english_name: 'Italy',
        native_name: 'Italia',
        calling_code: '+39'
    },
    {
        short_code: 'JM',
        english_name: 'Jamaica',
        native_name: 'Jamaica',
        calling_code: '+1 876'
    },
    {
        short_code: 'JP',
        english_name: 'Japan',
        native_name: 'Nihon/Nippon',
        calling_code: '+81'
    },
    {
        short_code: 'JE',
        english_name: 'Jersey',
        native_name: 'Bailiwick of Jersey',
        calling_code: '+44'
    },
    {
        short_code: 'JO',
        english_name: 'Jordan',
        native_name: 'Al-Urdunn',
        calling_code: '+962'
    },
    {
        short_code: 'KZ',
        english_name: 'Kazakhstan',
        native_name: 'Qazaqstan',
        calling_code: '+7 7'
    },
    {
        short_code: 'KE',
        english_name: 'Kenya',
        native_name: 'Kenya',
        calling_code: '+254'
    },
    {
        short_code: 'KI',
        english_name: 'Kiribati',
        native_name: 'Kiribati',
        calling_code: '+686'
    },
    {
        short_code: 'KP',
        english_name: "Korea, Democratic People'S Republic of",
        native_name: 'Choson Minjujuui In´min Konghwaguk (Bukhan)',
        calling_code: '+850'
    },
    {
        short_code: 'KR',
        english_name: 'Korea, Republic of',
        native_name: 'Taehan Minguk (Namhan)',
        calling_code: '+82'
    },
    {
        short_code: 'KW',
        english_name: 'Kuwait',
        native_name: 'Al-Kuwayt',
        calling_code: '+965'
    },
    {
        short_code: 'KG',
        english_name: 'Kyrgyzstan',
        native_name: 'Kyrgyzstan',
        calling_code: '+996'
    },
    {
        short_code: 'LA',
        english_name: "Lao People'S Democratic Republic",
        native_name: 'Lao',
        calling_code: '+856'
    },
    {
        short_code: 'LV',
        english_name: 'Latvia',
        native_name: 'Latvija',
        calling_code: '+371'
    },
    {
        short_code: 'LB',
        english_name: 'Lebanon',
        native_name: 'Lubnan',
        calling_code: '+961'
    },
    {
        short_code: 'LS',
        english_name: 'Lesotho',
        native_name: 'Lesotho',
        calling_code: '+266'
    },
    {
        short_code: 'LR',
        english_name: 'Liberia',
        native_name: 'Liberia',
        calling_code: '+231'
    },
    {
        short_code: 'LY',
        english_name: 'Libyan Arab Jamahiriya',
        native_name: 'Libiya',
        calling_code: '+218'
    },
    {
        short_code: 'LI',
        english_name: 'Liechtenstein',
        native_name: 'Liechtenstein',
        calling_code: '+423'
    },
    {
        short_code: 'LT',
        english_name: 'Lithuania',
        native_name: 'Lietuva',
        calling_code: '+370'
    },
    {
        short_code: 'LU',
        english_name: 'Luxembourg',
        native_name: 'Luxembourg/Lëtzebuerg',
        calling_code: '+352'
    },
    {
        short_code: 'MO',
        english_name: 'Macao',
        native_name: 'Macau/Aomen',
        calling_code: '+853'
    },
    {
        short_code: 'MK',
        english_name: 'Macedonia, The Former Yugoslav Republic of',
        native_name: 'Makedonija',
        calling_code: '+389'
    },
    {
        short_code: 'MG',
        english_name: 'Madagascar',
        native_name: 'Madagasikara/Madagascar',
        calling_code: '+261'
    },
    {
        short_code: 'MW',
        english_name: 'Malawi',
        native_name: 'Malawi',
        calling_code: '+265'
    },
    {
        short_code: 'MY',
        english_name: 'Malaysia',
        native_name: 'Malaysia',
        calling_code: '+60'
    },
    {
        short_code: 'MV',
        english_name: 'Maldives',
        native_name: 'Dhivehi Raajje/Maldives',
        calling_code: '+960'
    },
    {
        short_code: 'ML',
        english_name: 'Mali',
        native_name: 'Mali',
        calling_code: '+223'
    },
    {
        short_code: 'MT',
        english_name: 'Malta',
        native_name: 'Malta',
        calling_code: '+356'
    },
    {
        short_code: 'MH',
        english_name: 'Marshall Islands',
        native_name: 'Marshall Islands/Majol',
        calling_code: '+692'
    },
    {
        short_code: 'MQ',
        english_name: 'Martinique',
        native_name: 'Martinique',
        calling_code: '+596'
    },
    {
        short_code: 'MR',
        english_name: 'Mauritania',
        native_name: 'Muritaniya/Mauritanie',
        calling_code: '+222'
    },
    {
        short_code: 'MU',
        english_name: 'Mauritius',
        native_name: 'Mauritius',
        calling_code: '+230'
    },
    {
        short_code: 'YT',
        english_name: 'Mayotte',
        native_name: 'Mayotte',
        calling_code: '+262'
    },
    {
        short_code: 'MX',
        english_name: 'Mexico',
        native_name: 'México',
        calling_code: '+52'
    },
    {
        short_code: 'FM',
        english_name: 'Micronesia, Federated States of',
        native_name: 'Micronesia',
        calling_code: '+691'
    },
    {
        short_code: 'MD',
        english_name: 'Moldova, Republic of',
        native_name: 'Moldova',
        calling_code: '+373'
    },
    {
        short_code: 'MC',
        english_name: 'Monaco',
        native_name: 'Monaco',
        calling_code: '+377'
    },
    {
        short_code: 'MN',
        english_name: 'Mongolia',
        native_name: 'Mongol Uls',
        calling_code: '+976'
    },
    {
        short_code: 'MS',
        english_name: 'Montserrat',
        native_name: 'Montserrat',
        calling_code: '+1664'
    },
    {
        short_code: 'MA',
        english_name: 'Morocco',
        native_name: 'Al-Maghrib',
        calling_code: '+212'
    },
    {
        short_code: 'MZ',
        english_name: 'Mozambique',
        native_name: 'Moçambique',
        calling_code: '+258'
    },
    {
        short_code: 'MM',
        english_name: 'Myanmar',
        native_name: 'Myanma Pye',
        calling_code: '+95'
    },
    {
        short_code: 'NA',
        english_name: 'Namibia',
        native_name: 'Namibia',
        calling_code: '+264'
    },
    {
        short_code: 'NR',
        english_name: 'Nauru',
        native_name: 'Naoero/Nauru',
        calling_code: '+674'
    },
    {
        short_code: 'NP',
        english_name: 'Nepal',
        native_name: 'Nepal',
        calling_code: '+977'
    },
    {
        short_code: 'NL',
        english_name: 'Netherlands',
        native_name: 'Nederland',
        calling_code: '+31'
    },
    {
        short_code: 'AN',
        english_name: 'Netherlands Antilles',
        native_name: 'Nederlandse Antillen',
        calling_code: '+599'
    },
    {
        short_code: 'NC',
        english_name: 'New Caledonia',
        native_name: 'Nouvelle-Calédonie',
        calling_code: '+687'
    },
    {
        short_code: 'NZ',
        english_name: 'New Zealand',
        native_name: 'New Zealand/Aotearoa',
        calling_code: '+64'
    },
    {
        short_code: 'NI',
        english_name: 'Nicaragua',
        native_name: 'Nicaragua',
        calling_code: '+505'
    },
    {
        short_code: 'NE',
        english_name: 'Niger',
        native_name: 'Niger',
        calling_code: '+227'
    },
    {
        short_code: 'NG',
        english_name: 'Nigeria',
        native_name: 'Nigeria',
        calling_code: '+234'
    },
    {
        short_code: 'NU',
        english_name: 'Niue',
        native_name: 'Niue',
        calling_code: '+683'
    },
    {
        short_code: 'NF',
        english_name: 'Norfolk Island',
        native_name: 'Norfolk Island',
        calling_code: '+672'
    },
    {
        short_code: 'MP',
        english_name: 'Northern Mariana Islands',
        native_name: 'Northern Mariana Islands',
        calling_code: '+1 670'
    },
    {
        short_code: 'NO',
        english_name: 'Norway',
        native_name: 'Norge',
        calling_code: '+47'
    },
    {
        short_code: 'OM',
        english_name: 'Oman',
        native_name: '´Uman',
        calling_code: '+968'
    },
    {
        short_code: 'PK',
        english_name: 'Pakistan',
        native_name: 'Pakistan',
        calling_code: '+92'
    },
    {
        short_code: 'PW',
        english_name: 'Palau',
        native_name: 'Belau/Palau',
        calling_code: '+680'
    },
    {
        short_code: 'PS',
        english_name: 'Palestinian Territory, Occupied',
        native_name: 'Filastin',
        calling_code: '+970'
    },
    {
        short_code: 'PA',
        english_name: 'Panama',
        native_name: 'Panamá',
        calling_code: '+507'
    },
    {
        short_code: 'PG',
        english_name: 'Papua New Guinea',
        native_name: 'Papua New Guinea/Papua Niugini',
        calling_code: '+675'
    },
    {
        short_code: 'PY',
        english_name: 'Paraguay',
        native_name: 'Paraguay',
        calling_code: '+595'
    },
    {
        short_code: 'PE',
        english_name: 'Peru',
        native_name: 'Perú/Piruw',
        calling_code: '+51'
    },
    {
        short_code: 'PH',
        english_name: 'Philippines',
        native_name: 'Pilipinas',
        calling_code: '+63'
    },
    {
        short_code: 'PN',
        english_name: 'Pitcairn',
        native_name: 'Pitcairn',
        calling_code: '+872'
    },
    {
        short_code: 'PL',
        english_name: 'Poland',
        native_name: 'Polska',
        calling_code: '+48'
    },
    {
        short_code: 'PT',
        english_name: 'Portugal',
        native_name: 'Portugal',
        calling_code: '+351'
    },
    {
        short_code: 'PR',
        english_name: 'Puerto Rico',
        native_name: 'Puerto Rico',
        calling_code: '+1 939'
    },
    {
        short_code: 'QA',
        english_name: 'Qatar',
        native_name: 'Qatar',
        calling_code: '+974'
    },
    {
        short_code: 'RO',
        english_name: 'Romania',
        native_name: 'România',
        calling_code: '+40'
    },
    {
        short_code: 'RU',
        english_name: 'Russian Federation',
        native_name: 'Rossija',
        calling_code: '+7'
    },
    {
        short_code: 'RW',
        english_name: 'RWANDA',
        native_name: 'Rwanda/Urwanda',
        calling_code: '+250'
    },
    {
        short_code: 'SH',
        english_name: 'Saint Helena',
        native_name: 'Saint Helena',
        calling_code: '+290'
    },
    {
        short_code: 'KN',
        english_name: 'Saint Kitts and Nevis',
        native_name: 'Saint Kitts and Nevis',
        calling_code: '+1 869'
    },
    {
        short_code: 'LC',
        english_name: 'Saint Lucia',
        native_name: 'Saint Lucia',
        calling_code: '+1 758'
    },
    {
        short_code: 'PM',
        english_name: 'Saint Pierre and Miquelon',
        native_name: 'Saint-Pierre-et-Miquelon',
        calling_code: '+508'
    },
    {
        short_code: 'VC',
        english_name: 'Saint Vincent and the Grenadines',
        native_name: 'Saint Vincent and the Grenadines',
        calling_code: '+1 784'
    },
    {
        short_code: 'WS',
        english_name: 'Samoa',
        native_name: 'Samoa',
        calling_code: '+685'
    },
    {
        short_code: 'SM',
        english_name: 'San Marino',
        native_name: 'San Marino',
        calling_code: '+378'
    },
    {
        short_code: 'ST',
        english_name: 'Sao Tome and Principe',
        native_name: 'São Tomé e Príncipe',
        calling_code: '+239'
    },
    {
        short_code: 'SA',
        english_name: 'Saudi Arabia',
        native_name: 'Al-´Arabiya as-Sa´udiya',
        calling_code: '+966'
    },
    {
        short_code: 'SN',
        english_name: 'Senegal',
        native_name: 'Sénégal/Sounougal',
        calling_code: '+221'
    },
    {
        short_code: 'CS',
        english_name: 'Serbia and Montenegro',
        calling_code: '+382'
    },
    {
        short_code: 'SC',
        english_name: 'Seychelles',
        native_name: 'Sesel/Seychelles',
        calling_code: '+248'
    },
    {
        short_code: 'SL',
        english_name: 'Sierra Leone',
        native_name: 'Sierra Leone',
        calling_code: '+232'
    },
    {
        short_code: 'SG',
        english_name: 'Singapore',
        native_name: 'Singapore/Singapura/Xinjiapo/Singapur',
        calling_code: '+65'
    },
    {
        short_code: 'SK',
        english_name: 'Slovakia',
        native_name: 'Slovensko',
        calling_code: '+421'
    },
    {
        short_code: 'SI',
        english_name: 'Slovenia',
        native_name: 'Slovenija',
        calling_code: '+386'
    },
    {
        short_code: 'SB',
        english_name: 'Solomon Islands',
        native_name: 'Solomon Islands',
        calling_code: '+677'
    },
    {
        short_code: 'SO',
        english_name: 'Somalia',
        native_name: 'Soomaaliya',
        calling_code: '+252'
    },
    {
        short_code: 'ZA',
        english_name: 'South Africa',
        native_name: 'South Africa',
        calling_code: '+27'
    },
    {
        short_code: 'GS',
        english_name: 'South Georgia and the South Sandwich Islands',
        native_name: 'South Georgia and the South Sandwich Islands',
        calling_code: '+500'
    },
    {
        short_code: 'ES',
        english_name: 'Spain',
        native_name: 'España',
        calling_code: '+34'
    },
    {
        short_code: 'LK',
        english_name: 'Sri Lanka',
        native_name: 'Sri Lanka/Ilankai',
        calling_code: '+94'
    },
    {
        short_code: 'SD',
        english_name: 'Sudan',
        native_name: 'As-Sudan',
        calling_code: '+249'
    },
    {
        short_code: 'SR',
        english_name: 'Suriname',
        native_name: 'Suriname',
        calling_code: '+597'
    },
    {
        short_code: 'SJ',
        english_name: 'Svalbard and Jan Mayen',
        native_name: 'Svalbard og Jan Mayen',
        calling_code: '+47'
    },
    {
        short_code: 'SZ',
        english_name: 'Swaziland',
        native_name: 'kaNgwane',
        calling_code: '+268'
    },
    {
        short_code: 'SE',
        english_name: 'Sweden',
        native_name: 'Sverige',
        calling_code: '+46'
    },
    {
        short_code: 'CH',
        english_name: 'Switzerland',
        native_name: 'Schweiz/Suisse/Svizzera/Svizra',
        calling_code: '+41'
    },
    {
        short_code: 'SY',
        english_name: 'Syrian Arab Republic',
        native_name: 'Suriya',
        calling_code: '+963'
    },
    {
        short_code: 'TW',
        english_name: 'Taiwan, Province of China',
        native_name: 'Tai-wan',
        calling_code: '+886'
    },
    {
        short_code: 'TJ',
        english_name: 'Tajikistan',
        native_name: 'Toçikiston',
        calling_code: '+992'
    },
    {
        short_code: 'TZ',
        english_name: 'Tanzania, United Republic of',
        native_name: 'Tanzania',
        calling_code: '+255'
    },
    {
        short_code: 'TH',
        english_name: 'Thailand',
        native_name: 'Prathet Thai',
        calling_code: '+66'
    },
    {
        short_code: 'TL',
        english_name: 'Timor-Leste',
        native_name: 'Timor Timur',
        calling_code: '+670'
    },
    {
        short_code: 'TG',
        english_name: 'Togo',
        native_name: 'Togo',
        calling_code: '+228'
    },
    {
        short_code: 'TK',
        english_name: 'Tokelau',
        native_name: 'Tokelau',
        calling_code: '+690'
    },
    {
        short_code: 'TO',
        english_name: 'Tonga',
        native_name: 'Tonga',
        calling_code: '+676'
    },
    {
        short_code: 'TT',
        english_name: 'Trinidad and Tobago',
        native_name: 'Trinidad and Tobago',
        calling_code: '+1 868'
    },
    {
        short_code: 'TN',
        english_name: 'Tunisia',
        native_name: 'Tunis/Tunisie',
        calling_code: '+216'
    },
    {
        short_code: 'TR',
        english_name: 'Turkey',
        native_name: 'Türkiye',
        calling_code: '+90'
    },
    {
        short_code: 'TM',
        english_name: 'Turkmenistan',
        native_name: 'Türkmenostan',
        calling_code: '+993'
    },
    {
        short_code: 'TC',
        english_name: 'Turks and Caicos Islands',
        native_name: 'The Turks and Caicos Islands',
        calling_code: '+1 649'
    },
    {
        short_code: 'TV',
        english_name: 'Tuvalu',
        native_name: 'Tuvalu',
        calling_code: '+688'
    },
    {
        short_code: 'UG',
        english_name: 'Uganda',
        native_name: 'Uganda',
        calling_code: '+256'
    },
    {
        short_code: 'UA',
        english_name: 'Ukraine',
        native_name: 'Ukrajina',
        calling_code: '+380'
    },
    {
        short_code: 'AE',
        english_name: 'United Arab Emirates',
        native_name: 'Al-Imarat al-´Arabiya al-Muttahida',
        calling_code: '+971'
    },
    {
        short_code: 'GB',
        english_name: 'United Kingdom',
        native_name: 'United Kingdom',
        calling_code: '+44'
    },
    {
        short_code: 'US',
        english_name: 'United States',
        native_name: 'United States',
        calling_code: '+1'
    },
    {
        short_code: 'UY',
        english_name: 'Uruguay',
        native_name: 'Uruguay',
        calling_code: '+598'
    },
    {
        short_code: 'UZ',
        english_name: 'Uzbekistan',
        native_name: 'Uzbekiston',
        calling_code: '+998'
    },
    {
        short_code: 'VU',
        english_name: 'Vanuatu',
        native_name: 'Vanuatu',
        calling_code: '+678'
    },
    {
        short_code: 'VE',
        english_name: 'Venezuela',
        native_name: 'Venezuela',
        calling_code: '+58'
    },
    {
        short_code: 'VN',
        english_name: 'Vietnam',
        native_name: 'Việt Nam',
        calling_code: '+84'
    },
    {
        short_code: 'VG',
        english_name: 'Virgin Islands, British',
        native_name: 'British Virgin Islands',
        calling_code: '+1 284'
    },
    {
        short_code: 'VI',
        english_name: 'Virgin Islands, U.S.',
        native_name: 'Virgin Islands of the United States',
        calling_code: '+1 340'
    },
    {
        short_code: 'WF',
        english_name: 'Wallis and Futuna',
        native_name: 'Wallis-et-Futuna',
        calling_code: '+681'
    },
    {
        short_code: 'EH',
        english_name: 'Western Sahara',
        native_name: 'As-Sahrawiya'
    },
    {
        short_code: 'YE',
        english_name: 'Yemen',
        native_name: 'Al-Yaman',
        calling_code: '+967'
    },
    {
        short_code: 'ZM',
        english_name: 'Zambia',
        native_name: 'Zambia',
        calling_code: '+260'
    },
    {
        short_code: 'ZW',
        english_name: 'Zimbabwe',
        native_name: 'Zimbabwe',
        calling_code: '+263'
    },
    {
        short_code: 'RS',
        english_name: 'Republic of Serbia',
        native_name: 'Serbia',
        calling_code: '+381'
    }
];
export default dataCountries;
