import React, { useEffect, useState } from 'react';
import { template as tmpl } from './TopRatedAirportList.tmpl';
import { getTopRatedAirports } from 'redux/actions/flightAction';
import { updateTopRatedAirport } from 'redux/actions/airportAction';
import { connect } from 'react-redux';

const TopRatedListLogic = (props) => {
    const { activeCompany, getTopRatedAirports, navigate, updateTopRatedAirport } = props;
    const [originTopAirports, setOriginTopAirports] = useState([]);
    const [topAirports, setTopAirports] = useState([]);
    const [total, setTotal] = useState(0);

    /**
     * Get Top Rated Airports
     */
    useEffect(() => {
        async function fetchTopAirports() {
            const result = await getTopRatedAirports();
            if (result) {
                setOriginTopAirports(result.docs || []);
                setTopAirports(result.docs || []);
                setTotal(result.total || 0);
            }
        }
        fetchTopAirports();
    }, []);

    /**
     * Function handles searching when user is typing
     * @param {*} e event when user is typing
     */
    const onSearch = (e) => {
        const input = e.target.value.toLowerCase();
        const result = originTopAirports.filter((item = {}) => {
            const { airport = {} } = item;
            const { iata_code = '', icao_code = '', name = '' } = airport;
            if ((iata_code.toLowerCase().includes(input)) ||
                (icao_code.toLowerCase().includes(input)) || 
                (name.toLowerCase().includes(input))
            ) {
                return item;
            }
        });
        setTopAirports([ ...result ]);
    };

    const changeDisplay = async (data) => {
        let item = {
            ...data,
            enable: !data.enable
        }
        const result = await updateTopRatedAirport(data.id, item);
        if (result.success) {
            // update originTopAirports
            let index = originTopAirports.findIndex(item => item.id === data.id);
            if (index > -1) {
                let newOrigins = originTopAirports;
                newOrigins[index] = item;
                setOriginTopAirports([ ...newOrigins ]); 
            }

            // update topAirports
            let index2 = topAirports.findIndex(item => item.id === data.id);
            if (index2 > -1) {
                let newAirports = topAirports;
                newAirports[index2] = item;
                setTopAirports([ ...newAirports ]);
            }
        }
    };

    const initProps = {
        activeCompany: activeCompany,
        topAirports: topAirports,
        onSearch: onSearch,
        navigate: navigate,
        changeDisplay: changeDisplay,
        total: total
    };
    return tmpl && tmpl({ ...initProps });
};

class TopRatedContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <TopRatedListLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { employees, activeCompany } = companyReducer;
    return {
        employees: employees,
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTopRatedAirports: () => dispatch(getTopRatedAirports()),
        updateTopRatedAirport: (id, data) =>
            dispatch(updateTopRatedAirport(id, data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopRatedContainer);
