import React, { useState, useEffect } from 'react';
import { template as tmpl } from './Dashboard.tmpl';
import { connect } from 'react-redux';
import { switchCompany } from 'redux/actions/appActions';
import { fetchFlights } from 'redux/actions/flightAction';
import { fetchDashboardBookings } from 'redux/actions/bookingAction';
import { getQuotes } from 'redux/actions/quoteAction';
import {
    fetchReport,
    fetchListAircraftsPosition
} from 'redux/actions/dashboardAction';
import { QUOTE_REQUEST_STATUS } from 'redux/Constants';
import { Link } from 'react-router-dom';

const DashboardLogic = (props) => {
    const {
        firstname,
        lastname,
        activeCompany,
        companies = [],
        fetchUpcomingFlights,
        getQuotes,
        fetchDashboardBookings,
        fetchReports,
        fetchListAircraftsPosition,
        navigate
    } = props;
    const [upcomingFlightsList, setUpcomingFlightsList] = useState([]);
    const [newQuotes, setNewQuotes] = useState([]);
    const [newBookings, setNewBookings] = useState([]);
    const [reports, setReports] = useState({
        cancelled_flights: 0,
        completed_flights: 0,
        new_bookings: 0,
        upcoming_flights: 0,
        cancelled_bookings: 0
    });
    const [listAircraftsPosition, setListAircraftsPosition] = useState([]);

    useEffect(() => {
        initDashboard();
    }, [activeCompany]);

    const initDashboard = async () => {
        onChangePageUpcomingFlights({ current: 1, pageSize: 5 });
        onChangePageNewQuotes({ current: 1, pageSize: 5 });
        onChangePageNewBooking({ current: 1, pageSize: 5 });
        getReports();
        getListAircraftsPosition();
    };

    const getListAircraftsPosition = async () => {
        let listAircraftsPosition = [];
        let ourFleet = [];
        let partnerFleet = [];
        // our fleets
        listAircraftsPosition = await fetchListAircraftsPosition(false);
        if (listAircraftsPosition) {
            ourFleet = listAircraftsPosition.map((eachaircraft, index) => {
                return {
                    ...eachaircraft,
                    partner: false
                };
            });
        }

        //partner fleets
        listAircraftsPosition = await fetchListAircraftsPosition(true);
        if (listAircraftsPosition) {
            partnerFleet = listAircraftsPosition.map((eachaircraft, index) => {
                return {
                    ...eachaircraft,
                    partner: true
                };
            });
        }
        setListAircraftsPosition([...ourFleet, ...partnerFleet]);
    };

    const getReports = async () => {
        const reports = await fetchReports();
        if (reports) {
            setReports(reports);
        }
    };

    const onChangePageUpcomingFlights = async (pagination = {}) => {
        const { current, pageSize } = pagination;
        const upcomingFlights = await fetchUpcomingFlights(pageSize, current, {
            upcoming: true,
            sort_direction: 'asc'
        });
        setUpcomingFlightsList(upcomingFlights);
    };

    const onChangePageNewQuotes = async (pagination = {}) => {
        const { current, pageSize } = pagination;
        let newQuotes = await getQuotes(
            QUOTE_REQUEST_STATUS.ACTIVE,
            pageSize,
            current
        );
        if (newQuotes.success && newQuotes.isConnected && newQuotes.data) {
            setNewQuotes(newQuotes.data);
        }
    };

    const onChangePageNewBooking = async (pagination = {}) => {
        const { current, pageSize } = pagination;
        let newBookings = await fetchDashboardBookings(pageSize, current);
        setNewBookings(newBookings);
    };

    const switchCompany = (company) => {
        props.switchCompany({
            company_id: company.id
        });
    };

    const initProps = {
        listAircraftsPosition: listAircraftsPosition,
        reports: reports,
        upcomingFlightsList: upcomingFlightsList,
        newQuotes: newQuotes,
        newBookings: newBookings,
        navigate: navigate,
        firstname: firstname,
        lastname: lastname,
        activeCompanyName: activeCompany.name ? activeCompany.name : '',
        companies: companies
    };

    return tmpl && tmpl({ ...initProps, switchCompany: switchCompany });
};

class Dashboard extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <DashboardLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { userReducer, companyReducer } = state;
    const { firstname, lastname } = userReducer;
    const { activeCompany, companies } = companyReducer;
    return {
        firstname: firstname,
        lastname: lastname,
        companies: companies,
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        switchCompany: (data) => dispatch(switchCompany(data)),
        fetchUpcomingFlights: (customQuery, limit, page) =>
            dispatch(fetchFlights(customQuery, limit, page)),
        getQuotes: (status, limit, page) =>
            dispatch(getQuotes(status, limit, page)),
        fetchDashboardBookings: (limit, page) =>
            dispatch(fetchDashboardBookings(limit, page)),
        fetchReports: () => dispatch(fetchReport()),
        fetchListAircraftsPosition: (partner) =>
            dispatch(fetchListAircraftsPosition(partner))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
