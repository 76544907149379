import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Divider, Avatar } from 'antd';
import { Text, TextArea } from 'components';
import { useTranslation } from 'react-i18next';
import { imageIcon } from '../../../assets/images/icons';
import { ButtonCustom } from '../../../components';

const FlightNote = (props) => {
    const { label = 'SERVICE ITEMS', services = [] } = props;
    const { t, i18n } = useTranslation();
    const iconStyle = {
        width: '32px',
        height: '16px'
    };
    return (
        <FlightNoteContainer>
            <Label>
                <Text textStyle="heading5">{label}</Text>
            </Label>
            <ItemList>
                <TextArea
                    placeholder={t('WRITE_NOTES')}
                    style={{ height: 125 }}
                />
                <AddMoreSection>
                    <ButtonCustom
                        btnType={'secondary'}
                        title={t('ADD_NOTES').toUpperCase()}
                    />
                </AddMoreSection>
            </ItemList>
        </FlightNoteContainer>
    );
};

export default FlightNote;

const AddMoreSection = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
`;

const ServiceImage = styled.img``;

const ItemContainer = styled(Row)`
    background-color: #f7f8fb;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 10px;
`;

const ItemList = styled.div`
    background-color: #ffffff;
    border-radius: 4px;
    padding: 10px;
    margin-top: 20px;
    height: 100%;
`;

const FlightNoteContainer = styled.div`
    width: 100%;
    height: 100%;
`;

const Label = styled.div``;
