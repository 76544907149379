import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col, Dropdown, Menu } from 'antd';
import { Text } from 'components';
import {
    settingIcon,
    locationIcon,
    callIcon,
    iconArrowDown,
    emailIcon,
    mapMarkerIcon,
    settingSharpIcon
} from 'assets/images/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { searchFBO } from 'redux/actions/companyAction';
import { EXCEPT_EDIT_BOOKING } from 'config/Constants';
import _ from 'lodash';
import { renderAddress, getWindowDimensions } from 'utils';

const allowEdit = (status) => !EXCEPT_EDIT_BOOKING.includes(status);

const FBOInfoCard = (props) => {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const {
        type = '', // requied : departure | arrival
        title = '', // Departure | Arrival
        fbo = {},
        airport = {},
        bookingStatus = '',
        onSelectFBO
    } = props;

    const [fbos, setFBOs] = useState([]);
    const [fboInfo, setFBOInfo] = useState({});

    useEffect(() => {
        const fetchFBOs = async () => {
            const { id } = airport;
            if (!id) return;

            const rs = await dispatch(searchFBO('', { airport_id: id }));
            if (rs) {
                setFBOs(rs.data || []);
            }
        };

        // fet fbos
        fetchFBOs();
    }, [airport]);

    useEffect(() => {
        if (!_.isEmpty(fbo)) {
            setFBOInfo(fbo);
        }
    }, [fbo]);

    const onSelectFBOItem = (value) => {
        setFBOInfo(value);
        onSelectFBO && onSelectFBO(value.id, type);
    };

    const menu = () => {
        return (
            <Menu>
                {fbos &&
                    fbos.length > 0 &&
                    fbos.map((v = {}, i) => {
                        const { id, name, airport = {} } = v;
                        const { icao_code } = airport;
                        return (
                            <Menu.Item key={i}>
                                <SelectItem onClick={() => onSelectFBOItem(v)}>
                                    {`${icao_code} - ${name}`}
                                </SelectItem>
                            </Menu.Item>
                        );
                    })}
            </Menu>
        );
    };

    return (
        <Container className="fbo-wrapper">
            <RowTitle
                style={{
                    borderLeftStyle: 'solid',
                    borderLeftColor: '#19C0FF'
                }}
            >
                <Col
                    // span={12}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        minWidth: 180
                    }}
                >
                    <WrapperItem>
                        <Text textStyle="tableText2">
                            {title.toUpperCase()}
                        </Text>
                    </WrapperItem>
                </Col>
                <SelectFboCol
                // span={12}
                // style={{
                //     display: 'flex',
                //     flexDirection: 'column',
                //     alignItems: 'flex-end',
                //     justifyContent: 'center'
                // }}
                >
                    {allowEdit(bookingStatus) && (
                        <WrapperItem>
                            <Dropdown
                                overlay={menu}
                                placement="bottomLeft"
                                arrow
                            >
                                <SelectButton>
                                    {windowDimensions.width > 1024 ? (
                                        <FboItem style={{ marginRight: 5 }}>
                                            {t('SELECT_FBO').toUpperCase()}
                                        </FboItem>
                                    ) : null}
                                    <Icon
                                        src={iconArrowDown}
                                        style={{ margin: 0, color: '#f7f8fb' }}
                                    />
                                </SelectButton>
                            </Dropdown>
                        </WrapperItem>
                    )}
                </SelectFboCol>
            </RowTitle>
            <RowDetail>
                {!_.isEmpty(fboInfo) && (
                    <>
                        <Col
                            span={12}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-around',
                                minWidth: 220
                            }}
                        >
                            <WrapperItem>
                                <Row style={{ alignItems: 'center' }}>
                                    <Col span={4}>
                                        <Icon src={settingSharpIcon} />
                                    </Col>
                                    <Col span={20}>
                                        <Item>{fboInfo?.name || '_'}</Item>
                                    </Col>
                                </Row>
                            </WrapperItem>
                            <WrapperItem>
                                <Row style={{ alignItems: 'center' }}>
                                    <Col span={4}>
                                        <Icon src={mapMarkerIcon} />
                                    </Col>
                                    <Col span={20}>
                                        <Item>
                                            {renderAddress(fboInfo?.address)}
                                        </Item>
                                    </Col>
                                </Row>
                            </WrapperItem>
                        </Col>
                        <Col
                            span={12}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-around',
                                minWidth: 220
                            }}
                        >
                            <WrapperItem>
                                <Row style={{ alignItems: 'center' }}>
                                    <Col span={4}>
                                        <Icon src={callIcon} />
                                    </Col>
                                    <Col span={20}>
                                        <Item>
                                            {fboInfo?.contact?.phone || '_'}
                                        </Item>
                                    </Col>
                                </Row>
                            </WrapperItem>
                            <WrapperItem>
                                <Row style={{ alignItems: 'center' }}>
                                    <Col span={4}>
                                        <Icon src={emailIcon} />
                                    </Col>
                                    <Col span={20}>
                                        <Item>
                                            {fboInfo?.contact?.email || '_'}
                                        </Item>
                                    </Col>
                                </Row>
                            </WrapperItem>
                        </Col>
                    </>
                )}
            </RowDetail>
        </Container>
    );
};

export default FBOInfoCard;

const Container = styled.div`
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;

    .item-fbo-wrapper {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const SelectFboCol = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    /* width: 125px; */
`;

const RowTitle = styled(Row)`
    background-color: #f8f9fe;
    border-left-color: #19c0ff;
    border-left-width: 2px;
    border-radius: 2px 2px 0 0;
    /* height: 40px; */
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const RowDetail = styled(Row)`
    border: 1px solid #dadadb;
    border-radius: 0 0 2px 2px;
    display: flex;
    flex-wrap: wrap;
    /* min-width: 440px; */
    /* min-height: 102px; */
`;

const SelectButton = styled.button`
    border: none;
    background-color: transparent;
    background-repeat: no-repeat;
    align-self: center;
    overflow: hidden;
    outline: none;
`;

const SelectItem = styled.button`
    border: none;
    background-color: transparent;
    background-repeat: no-repeat;
    align-self: center;
    overflow: hidden;
    outline: none;
`;

const FboItem = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: right;
`;

const Item = styled.span`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
`;

const WrapperItem = styled.div`
    padding: 10px;
`;

const Icon = styled.img`
    margin: 5px;
    width: 18px;
    height: 18px;
`;
