import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getColorAndFontManifest } from 'redux/selectors/manifest';

/**
 *
 * @param {*} props
 * @returns
 */
const TextMedium = (props) => {
    const { children, style = {}, className } = props;
    const { fonts = {}, colors = {} } = useSelector(getColorAndFontManifest());

    const { primary: primaryFont = {} } = fonts;
    const { component_text_color } = colors;

    return (
        <CustomText
            className={className}
            color={component_text_color}
            font={primaryFont.family}
            style={{ ...style }}
        >
            {children}
        </CustomText>
    );
};

export default TextMedium;

const CustomText = styled.span`
    color: ${props => props.color || '#121216'};
    font-family: ${props => props.font || 'AvenirNext-Medium'};
    font-weight: 500;
    font-size: 13px;
`;
