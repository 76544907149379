import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { Modal, Row, Col, Input } from 'antd';
import { ButtonCustom, Text, TextArea } from 'components';
import {
    directTransferIcon,
    cashIcon,
    favoriteIcon,
    onlinePaymentIcon,
    directTransferActive,
    cashActive,
    favoriteActive,
    onlinePaymentActive
} from 'assets/images/icons';
import _ from 'lodash';
import { MARK_AS_PAID_TYPE } from 'redux/Constants';

const CancelRequestModal = ({ show = false, onOk, onCancel, onSave }) => {
    const { t, i18n } = useTranslation();

    const [cancelDescription, setCancelDescription] = useState('');

    return (
        <Modal
            centered
            visible={show}
            onCancel={() => onCancel && onCancel()}
            footer={null}
            width={'630px'}
        >
            <Row style={{ marginTop: 20, marginBottom: 20 }}>
                <div>
                    <InputItemWrapper style={{ justifyContent: 'center' }}>
                        <Title>
                            {i18n
                                .t('CANCEL_REQUEST_INTERROGATIVE')
                                .toUpperCase()}
                        </Title>
                    </InputItemWrapper>
                    <InputItemWrapper
                        style={{
                            justifyContent: 'center',
                            marginTop: '20px',
                            marginBottom: '20px'
                        }}
                    >
                        <Text
                            textStyle="paragraphMedium"
                            style={{ fontFamily: 'AvenirNext-Medium' }}
                        >
                            {i18n.t('THIS_CANT_BE_UNDONE')}
                        </Text>
                    </InputItemWrapper>
                    <InputItemWrapper style={{ paddingTop: 5 }}>
                        <Col>
                            <Row>
                                <Text textStyle="fieldLabel">
                                    {t('WRITE_NOTE_TO_CUSTOMER').toUpperCase()}
                                </Text>
                            </Row>
                            <Row style={{ paddingTop: 15 }}>
                                <TextArea
                                    onChange={(val) =>
                                        setCancelDescription(val)
                                    }
                                    numberOfRow={3}
                                    style={{ width: 517 }}
                                    placeholder={t('WRITE_SOME_NOTES')}
                                    value={cancelDescription}
                                />
                            </Row>
                        </Col>
                    </InputItemWrapper>

                    <InputItemWrapper
                        style={{
                            marginTop: 25,
                            justifyContent: 'center'
                        }}
                    >
                        <Col style={{ paddingRight: 10 }}>
                            <ButtonCustom
                                btnType="secondary"
                                width="120px"
                                size="medium"
                                title={t('NO').toUpperCase()}
                                onClick={() => onCancel && onCancel()}
                                // disabled={!checkValidItem()}
                            />
                        </Col>
                        <Col style={{ paddingLeft: 10 }}>
                            <ButtonCustom
                                btnType="primary"
                                width="120px"
                                size="medium"
                                title={t('YES_IM_SURE').toUpperCase()}
                                onClick={() =>
                                    onSave && onSave(cancelDescription)
                                }
                                // disabled={!checkValidItem()}
                            />
                        </Col>
                    </InputItemWrapper>
                </div>
            </Row>
        </Modal>
    );
};

export default CancelRequestModal;

const InputItemWrapper = styled(Row)``;

const SelectPaymentItem = styled.a`
    display: flex;
    margin-top: 10px;
    width: 370px;
    border: ${(props) =>
        props.isActive === true ? '1px solid #19c0ff' : 'none'};
    border-radius: 4px;
    padding: 10px;
    background-color: ${(props) => (props.isActive ? 'none' : '#F8F9FE')};
`;

const Title = styled.div`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 32px;
    text-align: center;
`;

const SubTitle = styled.div`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
`;

const Icon = styled.img`
    width: 28px;
    height: 28px;
`;

const PaidType = styled.div`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 13px;
    line-height: 16px;
    text-align: left;
`;

const PaidTypeDesc = styled.div`
    color: #65686f;
    font-family: Lato;
    font-size: 13px;
    line-height: 23px;
    text-align: left;
`;
