import React, { useEffect, useState } from 'react';
import { template as tmpl } from './Calendar.tmpl';
import { connect } from 'react-redux';
import { switchCompany } from 'redux/actions/appActions';
import { fetchEvents } from 'redux/actions/dashboardAction';
import moment from 'moment';
import { Link } from 'react-router-dom';

const CalendarLogic = (props) => {
    const {
        firstname,
        lastname,
        activeCompany,
        companies = [],
        fetchEvents
    } = props;

    const [events, setEvents] = useState([]);

    useEffect(() => {
        initCalendar();
    }, [activeCompany]);

    const initCalendar = async () => {
        const listEvents = await fetchEvents();

        if (listEvents && listEvents.length > 0) {
            const convertedEvents = listEvents.map((eachEvent, index) => {
                let newFormatEvent = {
                    id: index,
                    title: eachEvent.flight_code,
                    start: moment(eachEvent.departure.utc_datetime).toDate(),
                    end: moment(eachEvent.arrival.utc_datetime).toDate(),
                    from_airport: eachEvent.from_airport,
                    to_airport: eachEvent.to_airport,
                    aircraft: eachEvent.aircraft || {}
                };
                return newFormatEvent;
            });

            setEvents(convertedEvents);
        }
    };

    const switchCompany = (company) => {
        props.switchCompany({
            company_id: company.id
        });
    };

    const navigate = (route, id, state) => {
        props.history.push({
            pathname: route,
            search: id,
            state: state
        });
    };

    const initProps = {
        events: events,
        navigate: navigate,
        firstname: firstname,
        lastname: lastname,
        activeCompanyName: activeCompany.name ? activeCompany.name : '',
        companies: companies
    };

    return tmpl && tmpl({ ...initProps, switchCompany: switchCompany });
};

class Calendar extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <CalendarLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { userReducer, companyReducer } = state;
    const { firstname, lastname } = userReducer;
    const { activeCompany, companies } = companyReducer;
    return {
        firstname: firstname,
        lastname: lastname,
        companies: companies,
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        switchCompany: (data) => dispatch(switchCompany(data)),
        fetchEvents: (from_time, to_time) =>
            dispatch(fetchEvents(from_time, to_time))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
