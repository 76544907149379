import React, { useState } from 'react';
import { template as tmpl } from './Header.tmpl';
import { Link } from 'react-router-dom';

const HeaderLogic = (props) => {
    const { title, description, headerRight } = props;
    return tmpl && tmpl({ title, description, headerRight });
};

class Header extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <HeaderLogic {...initProps} />;
    }
}

export { Header };
