import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { Row, Col, Avatar } from 'antd';
import { Text } from 'components';
import { amenitiesList } from 'config/Constants';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
    iconWifi,
    airplaneSeat,
    bathroomSink,
    iconLavatory,
    sleep,
    personIcon
} from 'assets/images/icons';
// import { AircraftDetailCardComponent } from 'views/BrokerConnections/components';
import { AircraftDetailCardComponent } from 'components/Cards';
import { GoBackAndNext } from '../../components';
import { getSummaryPriceWithFlights, numberFormat, renderAddress } from 'utils';
import { useSelector } from 'react-redux';
const template = (props) => {
    const { t, i18n } = useTranslation();
    const { expandedSidebar } = useSelector(
        (state) => state.settingReducer || false
    );
    const {
        quoteDetail = {},
        navigate,
        onGoBack,
        onClickSendQuotes,
        user = {}
    } = props;
    const { image = '', firstname = '', lastname = '' } = user;

    const { aircraft_categories = [], type, packages = [] } = quoteDetail;

    const renderAmenities = (item, index) => {
        switch (item) {
            case amenitiesList.EXTRA_LEG_ROOM:
                return (
                    <>
                        <FeatureIcon src={airplaneSeat}></FeatureIcon>
                        <FeatureTitle>
                            <Text textStyle="tableText1">
                                {t('EXTRA_LEG_ROOM')}
                            </Text>
                        </FeatureTitle>
                    </>
                );
            case amenitiesList.WIFI:
                return (
                    <>
                        <FeatureIcon src={iconWifi}></FeatureIcon>
                        <FeatureTitle>
                            <Text textStyle="tableText1">{t('WIFI')}</Text>
                        </FeatureTitle>
                    </>
                );
            case amenitiesList.BED:
                return (
                    <>
                        <FeatureIcon src={sleep}></FeatureIcon>
                        <FeatureTitle>
                            <Text textStyle="tableText1">{t('SLEEPS')}</Text>
                        </FeatureTitle>
                    </>
                );
            case amenitiesList.SINK:
                return (
                    <>
                        <FeatureIcon src={bathroomSink}></FeatureIcon>
                        <FeatureTitle>
                            <Text textStyle="tableText1">
                                {t('SINK_AND_LIGHT')}
                            </Text>
                        </FeatureTitle>
                    </>
                );
            case amenitiesList.LAVATORY:
                return (
                    <>
                        <FeatureIcon src={iconLavatory}></FeatureIcon>
                        <FeatureTitle>
                            <Text textStyle="tableText1">{t('LAVATORY')}</Text>
                        </FeatureTitle>
                    </>
                );
            default:
                return;
        }
    };

    const renderAircrafts = (eachPackage = []) => {
        switch (type) {
            case 'ONEWAY':
                return (
                    <PackageContainer>
                        <AircraftContainer>
                            <LegTitle>
                                <AircraftInformationContainer>
                                    <AircraftCard
                                        aircraft={eachPackage[0].aircraft || {}}
                                        price_components={
                                            eachPackage[0].price_components ||
                                            {}
                                        }
                                    />
                                </AircraftInformationContainer>
                            </LegTitle>
                        </AircraftContainer>
                    </PackageContainer>
                );

            case 'RETURN':
                return (
                    <PackageContainer>
                        <AircraftContainer>
                            <LegTitle>
                                <Text
                                    textStyle="heading4"
                                    // style={{ marginBottom: 25 }}
                                >
                                    {t('DEPARTURE')}
                                </Text>
                                <AircraftInformationContainer>
                                    <AircraftCard
                                        aircraft={eachPackage[0].aircraft || {}}
                                        price_components={
                                            eachPackage[0].price_components ||
                                            {}
                                        }
                                    />
                                </AircraftInformationContainer>
                            </LegTitle>
                        </AircraftContainer>
                        <AircraftContainer>
                            <LegTitle>
                                <Text
                                    textStyle="heading4"
                                    // style={{ marginBottom: 25 }}
                                >
                                    {t('RETURN')}
                                </Text>
                                <AircraftInformationContainer>
                                    <AircraftCard
                                        aircraft={eachPackage[1].aircraft || {}}
                                        price_components={
                                            eachPackage[1].price_components ||
                                            {}
                                        }
                                    />
                                </AircraftInformationContainer>
                            </LegTitle>
                        </AircraftContainer>
                    </PackageContainer>
                );
            case 'MULTI_LEG':
                return (
                    <PackageContainer>
                        {eachPackage.length > 0 &&
                            eachPackage.map((packageInfo, index) => {
                                return (
                                    <AircraftContainer key={index}>
                                        <LegTitle>
                                            <Text
                                                textStyle="heading4"
                                                // style={{ marginBottom: 25 }}
                                            >
                                                {'Leg ' + (index + 1)}
                                            </Text>
                                            <AircraftInformationContainer>
                                                <AircraftCard
                                                    aircraft={
                                                        packageInfo.aircraft ||
                                                        {}
                                                    }
                                                    price_components={
                                                        packageInfo.price_components ||
                                                        {}
                                                    }
                                                />
                                            </AircraftInformationContainer>
                                        </LegTitle>
                                    </AircraftContainer>
                                );
                            })}
                    </PackageContainer>
                );
            default:
                return;
        }
    };

    const AircraftCard = ({ aircraft, key, price_components = {} }) => {
        const { t, i18n } = useTranslation();
        const { amenities = [] } = aircraft;

        return (
            <EachAircraft key={key}>
                <LeftSection>
                    <AircraftDetailCardComponent aircraft={aircraft} />
                </LeftSection>
                <CreateQuoteSection expandedSidebar={expandedSidebar}>
                    <div>
                        <CreateQuoteTop>
                            <CreateQuoteTopLeft>
                                <Text textStyle="nameNormal">
                                    {t('PRICE').toUpperCase()}
                                </Text>
                                <Text
                                    textStyle="heading3"
                                    style={{
                                        fontFamily: 'AvenirNext-Bold',
                                        marginLeft: 10
                                    }}
                                >
                                    {numberFormat(
                                        price_components.total_inc_vat,
                                        'USD',
                                        1
                                    )}
                                </Text>
                            </CreateQuoteTopLeft>
                        </CreateQuoteTop>
                        <FeaturedAmenities>
                            <FeaturedAmenitiesTitle>
                                <Text textStyle="heading6">
                                    {t('FEATURED_AMENITIES')}
                                </Text>
                            </FeaturedAmenitiesTitle>
                            {amenities.map((item, index) => {
                                return (
                                    <EachAmenities key={index}>
                                        {renderAmenities(item, index)}
                                    </EachAmenities>
                                );
                            })}
                        </FeaturedAmenities>
                    </div>
                </CreateQuoteSection>
            </EachAircraft>
        );
    };

    return (
        <SummaryContainer>
            <UserCreated>
                <CreatedByTitle>
                    <IconContainer>
                        <UserIcon src={personIcon}></UserIcon>
                    </IconContainer>
                    <Text textStyle="heading5" style={{ marginLeft: 15 }}>
                        {t('QUOTE_CREATED_BY').toUpperCase()}
                    </Text>
                </CreatedByTitle>
                <CreatedByDetail>
                    <NameSection>
                        <Text textStyle="tableText1">{t('NAME')}</Text>
                        <AvatarSection>
                            {image ? (
                                <Avatar
                                    src={image}
                                    size={28}
                                    className="ant-avatar-custom"
                                />
                            ) : (
                                <Avatar size={28} className="ant-avatar-custom">
                                    {(
                                        firstname.charAt(0) + lastname.charAt(0)
                                    ).toUpperCase()}
                                </Avatar>
                            )}
                            <Text
                                textStyle="tableText2"
                                style={{ marginLeft: 12 }}
                            >
                                {(
                                    (user.firstname || ' ') +
                                    ' ' +
                                    (user.lastname || ' ')
                                ).toUpperCase()}
                            </Text>
                        </AvatarSection>
                    </NameSection>
                    <EmailAndPhone>
                        <Email>
                            <Text textStyle="tableText1">
                                {t('EMAIL_ADDRESS')}
                            </Text>
                            <Text
                                textStyle="tableText2"
                                style={{ marginTop: 10 }}
                            >
                                {user.email.toUpperCase()}
                            </Text>
                        </Email>
                        {user.phone && (
                            <Phone>
                                <Text textStyle="tableText1">
                                    {t('PHONE_NUMBER')}
                                </Text>
                                <Text
                                    textStyle="tableText2"
                                    style={{ marginTop: 10 }}
                                >
                                    {(user.phone || '-').toUpperCase()}
                                </Text>
                            </Phone>
                        )}
                    </EmailAndPhone>
                    {(user.address.address_line2 ||
                        user.address.address_line2 ||
                        user.address) && (
                        <Address>
                            <Text textStyle="tableText1">{t('ADDRESS')}</Text>
                            <Text
                                textStyle="tableText2"
                                style={{ marginTop: 10 }}
                            >
                                {(
                                    user.address.address_line1 +
                                    ', ' +
                                    user.address.address_line2 +
                                    ', ' +
                                    renderAddress(user.address)
                                ).toUpperCase()}
                            </Text>
                        </Address>
                    )}
                </CreatedByDetail>
            </UserCreated>
            {Array.isArray(packages) &&
                packages.length > 0 &&
                packages.map((eachPackage, index) => {
                    const totalPrice = getSummaryPriceWithFlights(eachPackage);
                    return (
                        <EachPackage key={index}>
                            <PackageSummary>
                                <Text textStyle="heading3">
                                    {t('PACKAGE').toUpperCase() +
                                        ' ' +
                                        (index + 1)}
                                </Text>
                                <TotalPrice>
                                    <Text
                                        textStyle="InfoNormal"
                                        style={{
                                            fontSize: 14,
                                            letterSpacing: 0.98
                                        }}
                                    >
                                        {t('TOTAL_PRICE').toUpperCase() + ':'}
                                    </Text>
                                    <Text
                                        textStyle="heading3"
                                        style={{
                                            letterSpacing: 0.4,
                                            marginLeft: 15
                                        }}
                                    >
                                        {numberFormat(totalPrice, 'USD', 1) ||
                                            '-'}
                                    </Text>
                                </TotalPrice>
                            </PackageSummary>

                            {renderAircrafts(eachPackage)}
                        </EachPackage>
                    );
                })}
            <SendQuotesSection>
                <GoBackAndNext
                    onGoNext={() => {
                        onClickSendQuotes();
                    }}
                    onGoBack={onGoBack}
                    activeGoNext={true}
                    activeGoBack={true}
                    titleGoNext={t('SEND_QUOTES').toUpperCase()}
                />
            </SendQuotesSection>
        </SummaryContainer>
    );
};

export { template };

const FeatureIcon = styled.img`
    margin-top: 10px;
    margin-right: 10px;
`;

const FeatureTitle = styled.div`
    margin-top: 5px;
`;

const SendQuotesSection = styled.div`
    margin-top: 40px;
`;

const EachAmenities = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const FeaturedAmenities = styled.div`
    padding: 20px;
`;

const FeaturedAmenitiesTitle = styled.div`
    margin-bottom: 5px;
`;

const CreateQuoteTopLeft = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
`;

const CreateQuoteTop = styled.div`
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(14, 14, 18, 0.05);
    padding-bottom: 20px;
`;

const CreateQuoteSection = styled.div`
    max-width: 300px;
    width: 16vw;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    border-left: 1px solid rgba(14, 14, 18, 0.1);
    @media (max-width: 1366px) {
        ${(props) => props.expandedSidebar && 'border-left: none'};
    }
    padding-top: 20px;
`;

const LeftSection = styled.div`
    display: flex;
    flex-direction: row;
`;

const EachAircraft = styled.div`
    background-color: #fff;
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const AircraftInformationContainer = styled.div`
    background-color: #ffffff;
    margin-top: 25px;
`;

const PackageContainer = styled.div``;

const LegTitle = styled.div``;

const AircraftContainer = styled.div`
    margin-top: 25px;
`;

const TotalPrice = styled.div``;

const PackageSummary = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 2px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
`;

const Address = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 20px;
`;

const Email = styled.div`
    display: flex;
    flex-direction: column;
`;

const Phone = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 40px;
`;

const EmailAndPhone = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 20px;
`;

const AvatarSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
`;

const NameSection = styled.div`
    display: flex;
    flex-direction: column;
`;

const IconContainer = styled.div`
    background-color: #f0f3f9;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CreatedByDetail = styled.div`
    margin-left: 80px;
    background-color: #fcfcfe;
    border-radius: 2px;
    max-width: 770px;
    padding: 30px;
`;

const UserIcon = styled.img`
    width: 27px;
    height: 16px;
`;

const CreatedByTitle = styled.div`
    padding-left: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const SummaryContainer = styled.div``;

const UserCreated = styled.div`
    background-color: #ffffff;
    border-radius: 4px;
    padding-top: 20px;
    padding-bottom: 25px;
`;

const EachPackage = styled.div`
    margin-top: 40px;
`;
