import React, { useState, useEffect, useRef } from 'react';
import 'antd/dist/antd.css';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Text } from 'components';
import { Col, Row, Avatar } from 'antd';
import { plusIcon, defaultAvarta, removeIcon } from 'assets/images/icons';
import SearchEmployees from 'components/Search/SearchEmployees';
import { EXCEPT_EDIT_BOOKING } from 'config/Constants';
import { WarningModal } from 'components/Modals';
import { getLowresUrl } from 'utils';

const allowEdit = (status) => !EXCEPT_EDIT_BOOKING.includes(status);
const warningInitState = {
    show: false,
    value: {}
};

const CrewInfo = ({
    flight_id,
    crew = [],
    label = '',
    addCrew,
    removeCrew,
    bookingStatus = ''
}) => {
    const { t, i18n } = useTranslation();
    const ref = useRef(null);

    const [adding, setAdding] = useState(false);
    const [crewInfo, setCrewInfo] = useState([]);
    const [warning, setWarning] = useState(warningInitState);

    const [widthSearch, setWidthSearch] = useState(0);

    // useEffect(() => {
    //     console.log('ef.current >>>  ', ref.current);
    //     setWidthSearch(ref.current?.offsetWidth || '100%');
    // }, [ref.current]);

    useEffect(() => {
        if (crew) {
            setCrewInfo(crew);
        }
    }, [crew]);

    const onAddCrew = (value) => {
        if (!value || crewInfo.some((item) => item.user_id === value.id)) {
            setAdding(false);
            return;
        }

        let newCrew = {
            can_remove: true,
            image: value.image,
            email: value.email,
            name: value.name,
            role: value.role,
            status: 'AWAITING_CONFIRMATION',
            user_id: value.id
        };
        setCrewInfo([...crewInfo, newCrew]);
        addCrew && addCrew(flight_id, newCrew);
        setAdding(false);
    };

    const onRemoveCrew = () => {
        let value = warning.value || {};
        if (value && value.can_remove) {
            removeCrew && removeCrew(flight_id, value);

            // update UI
            let newCrewInfo = crewInfo || [];
            let index = crewInfo.findIndex(
                (item) => item.user_id === value.user_id
            );
            if (index > -1) {
                newCrewInfo.splice(index, 1);
                setCrewInfo([...newCrewInfo]);
            }
        }

        // reset warning
        setWarning(warningInitState);
    };

    const onCancelWarning = () => {
        setWarning(warningInitState);
    };

    return (
        <Container
            style={{ paddingRight: 17, height: '95%' }}
            className="crew-info"
        >
            <Label>
                <Text textStyle="heading5">{label}</Text>
            </Label>
            <Wrapper style={{ height: '100%' }}>
                {crewInfo &&
                    crewInfo.length > 0 &&
                    crewInfo.map((v, i) => {
                        const {
                            can_remove,
                            email,
                            name,
                            role,
                            image,
                            status,
                            user_id
                        } = v;
                        let img = image || defaultAvarta;
                        return (
                            <CrewWrap key={i}>
                                <div style={{ marginLeft: 25 }}>
                                    <Avatar size={40} src={img} />
                                </div>
                                <div style={{ marginLeft: 25 }}>
                                    <Text textStyle="heading5">{name}</Text>
                                    <Role>{role}</Role>
                                </div>

                                {can_remove && allowEdit(bookingStatus) && (
                                    <RemoveButton
                                        onClick={() =>
                                            setWarning({
                                                show: true,
                                                value: v
                                            })
                                        }
                                    >
                                        <Icon src={removeIcon} />
                                    </RemoveButton>
                                )}
                            </CrewWrap>
                        );
                    })}
                {allowEdit(bookingStatus) &&
                    (!adding ? (
                        <PlusWrapper onClick={() => setAdding(true)}>
                            <Icon
                                src={plusIcon}
                                style={{
                                    width: 12,
                                    height: 12,
                                    marginRight: 10
                                }}
                            />
                            <CrewItem font="AvenirNext-DemiBold">
                                {t('ADD_CREW').toUpperCase()}
                            </CrewItem>
                        </PlusWrapper>
                    ) : (
                        <div ref={ref} style={{ width: '100%' }}>
                            <SearchEmployees
                                onSelectEmployee={onAddCrew}
                                inputStyle={{
                                    minWidth: '100%',
                                    height: '61px'
                                }}
                            />
                        </div>
                    ))}
            </Wrapper>

            {/* modal warning remove crew */}
            <WarningModal
                show={warning.show}
                title={t('CONFIRM_REMOVE_CREW').toUpperCase()}
                okText={t('YES_REMOVE_IT').toUpperCase()}
                onOk={onRemoveCrew}
                cancelText={t('NO_DISREGARD').toUpperCase()}
                onCancel={onCancelWarning}
            />
        </Container>
    );
};

const PlusWrapper = styled.button`
    background-color: transparent;
    border: 1px solid #65686f;
    border-radius: 4px;
    border-style: dashed;
    width: 100%;
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Wrapper = styled.div`
    background-color: #ffffff;
    width: 100%;
    min-height: 300px;
    justify-content: center;
    align-self: center;
    border-radius: 4px;
    padding: 25px;
    margin-top: 20px;
`;

const Container = styled.div`
    width: 100%;

    .ant-select {
        width: 100%;
    }
    .ant-select-auto-complete {
        width: 100%;
    }
    .ant-select-single {
        width: 100%;
    }
    .ant-select-allow-clear {
        width: 100%;
    }
    .ant-select-customize-input {
        width: 100%;
    }
    .ant-select-show-search {
        width: 100%;
    }
`;

const Label = styled.div``;

const Icon = styled.img`
    margin: 5px;
`;

const CrewItem = styled.span`
    color: #65686f;
    font-family: ${(props) => props.font || 'AvenirNext-Medium'};
    font-size: 11px;
    line-height: 14px;
    text-align: left;
`;

const CrewWrap = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    background-color: #f8f9fe;
    border-radius: 2px;
    height: 61px;
    margin-bottom: 15px;
    align-items: center;
`;

const RemoveButton = styled.button`
    border: none;
    position: absolute;
    background-color: transparent;
    top: 0;
    bottom: 0;
    right: 15px;
`;

const Role = styled.div`
    color: #8e929a;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    line-height: 13px;
    width: 24px;
    text-align: left;
    margin-top: 3px;
`;

export default CrewInfo;
