import React, { useEffect, useState } from 'react';
import { template as tmpl } from './Invoice.tmpl';
import { useDispatch, useSelector } from 'react-redux';
import { 
    calcPriceSelectedAircraft,
    updatePackageForQuote,
    deletePackageForQuote,
    calcPricePackageForQuote,
    updateStepForQuote
} from 'redux/actions/quoteAction';
import { message } from 'antd';
import { CREATE_QUOTATION_STEP } from 'config/Constants';
import { UPDATE_QUOTE_STEP, CREATE_QUOTE_PACKAGES } from 'redux/Constants';

const CreateQuoteInvoices = (props) => {
    const dispatch = useDispatch();
    const { quotes = [] } = useSelector(state => state.quoteReducer);

    const { requestCode = '' } = props;
    const [quoteDetail, setQuoteDetail] = useState({});

    useEffect(() => {
        if (!_.isEmpty(quotes)) {
            const result = quotes.find(item => item.request_code === requestCode);
            if (!_.isEmpty(result)) {
                setQuoteDetail({ ...result });
            }
        }
    }, [quotes]);

    const onGoBack = () => {
        dispatch(updateStepForQuote(quoteDetail?.id, CREATE_QUOTATION_STEP.SERVICES));
        dispatch({ 
            type: UPDATE_QUOTE_STEP,
            data: {
                id: quoteDetail.id,
                step: CREATE_QUOTATION_STEP.SERVICES
            }
        });
    };

    const onGoNext = () => {
        dispatch(updateStepForQuote(quoteDetail?.id, CREATE_QUOTATION_STEP.SUMMARY));
        dispatch({ 
            type: UPDATE_QUOTE_STEP,
            data: {
                id: quoteDetail.id,
                step: CREATE_QUOTATION_STEP.SUMMARY
            }
        });
    };

    const onCreatePackage = async (newPackage = []) => {
        try {
            let data = newPackage.map((item = {}, i) => {
                const { leg_id, aircraft = {}, price_components = {}, services = [] } = item;
                return {
                    aircraft_id: aircraft?.id,
                    leg_id: leg_id,
                    price_components: price_components,
                    service_items: services
                };
            });
    
            let rs = await dispatch(updatePackageForQuote(quoteDetail.id, { package: data }));
            if (rs && rs.success) {
                dispatch({
                    type: CREATE_QUOTE_PACKAGES,
                    data: {
                        id: quoteDetail.id,
                        packages: newPackage
                    }
                });
            } else {
                message.error('Update package failed. Please try again!');
            }
        } catch (e) {}
    };

    const onRemovePackage = (index) => {
        try {
            let newQuote = quoteDetail;
            let packages = newQuote.packages[index] || [];
            let data = packages.map((item = {}, i) => {
                return {
                    leg_id: item.leg_id,
                    aircraft_id: item?.aircraft?.id || item._aircraft
                }
            });

            dispatch(deletePackageForQuote(quoteDetail?.id, data, index));
        } catch (e) {}
    };

    const onChangePricePackages = async (pks, legIndex, pkIndex) => {
        if (!pks) {
            return;
        }

        try {
            let body = pks.map((pk = {}) => {
                return {
                    aircraft_id: pk.aircraft.id,
                    leg_id: pk.leg_id,
                    price_components: pk.price_components,
                    services: pk.services
                };
            })
            dispatch(calcPricePackageForQuote(
                quoteDetail.id, 
                body,
                pkIndex
            ));
        } catch (e) {

        }
    }

    const onChangePriceSelectedItem = async (legId, aircraftId, price, legIndex, selectedIndex) => {
        if (!legId || !aircraftId|| !price) {
            return;
        }

        try {
            let body = {
                leg_id: legId,
                aircraft_id: aircraftId,
                price_components: price
            };
            return await dispatch(calcPriceSelectedAircraft(
                quoteDetail.id, 
                body,
                legIndex,
                selectedIndex
            ));
        } catch (e) {
            return null;
        }
    }

    const navigate = (route, search, state) => {
        props.history.push({
            pathname: route,
            search: search,
            state: state ? { data: state } : null
        });
    };

    const initProps = {
        quoteDetail: quoteDetail,
        navigate: navigate,
        onGoNext: onGoNext,
        onGoBack: onGoBack,
        onCreatePackage: onCreatePackage,
        onRemovePackage: onRemovePackage,
        onChangePriceSelectedItem: onChangePriceSelectedItem,
        onChangePricePackages: onChangePricePackages
    };
    return tmpl && tmpl({ ...initProps });
};

export default CreateQuoteInvoices;
