import React, { useEffect, useState } from 'react';
import { template as tmpl } from './LoginCard.tmpl';
import { connect } from 'react-redux';
import { identify, signIn } from 'redux/actions/appActions';
import { DASHBOARD, FORGOT_PASSWORD, REGISTER } from 'routes/main';
const LoginCardLogic = (props) => {
    const initProps = props;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState('');

    useEffect(() => {
        props.getIdentify();
    }, []);

    const SignIn = async () => {
        const loginResult = await props.signIn({
            email: email,
            password: password
        });
        if (loginResult.success) {
            // Call get user API
            props.history.push(DASHBOARD);
        } else {
            let errMsg =
                loginResult && loginResult.errors ? loginResult.errors : '';
            setErrors(errMsg);
        }
        // TODO:
        // 1. Check connection error or timeout
        // 2. Check errors login returned from Backend side
    };

    const requestAccount = () => {
        props.history.push(REGISTER);
    };

    const forgotPassword = () => {
        props.history.push(FORGOT_PASSWORD);
    };

    const onChangePassword = (e) => {
        setPassword(e.target.value);
    };

    const onChangeEmail = (e) => {
        setEmail(e.target.value);
    };

    return (
        tmpl &&
        tmpl({
            forgotPassword,
            email,
            onChangeEmail,
            password,
            errors,
            onChangePassword,
            SignIn,
            requestAccount,
            ...initProps
        })
    );
};

class LoginCard extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    render() {
        const initProps = {
            ...this.props
        };
        return <LoginCardLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const data = state;
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        getIdentify: () => dispatch(identify()),
        signIn: (data) => dispatch(signIn(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginCard);
