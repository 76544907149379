import React from 'react';
import 'antd/dist/antd.css';
import { List, Input } from 'antd';
const { TextArea } = Input;
import { useTranslation } from 'react-i18next';
import {
    Header,
    PrimaryButton,
    Text,
    Checkbox,
    UploadComponent,
    Popup,
} from 'components';
import { WarningModal } from 'components/Modals';
import { SearchAirports } from 'components/Search';
import { Properties, QuickToolBarComponent, EstimateAircraftModal } from '../components';
import { AircraftDetailCardComponent } from 'components/Cards';
import { CloseOutlined } from '@ant-design/icons';
import { AIRCRAFT_DETAIL } from 'routes/main';

import {
    Container,
    AircraftDetailSummary,
    PhotosSection,
    ListPhotosSection,
    EachImage,
    EachSection,
    PropertiesContainer,
    EachCheckbox,
    CheckboxSection,
    FirstCheckboxColumn,
    SecondCheckboxColumn,
    DescriptionDetail,
    DescriptionTitle,
    DescriptionSection,
    Column,
    PropertyLabelSection,
    Property,
    Value,
    SaveChangesSection,
    ImageContainer,
    DeletePhoto,
} from '../style';

const template = (props) => {
    const { t, i18n } = useTranslation();
    const {
        handleDeleteButton,
        onRemove,
        onCancelWarning,
        showDeleteModal = false,
        visibleEstimateAircraftModal = false,
        onOpenEstimateAircraftModal,
        onCancelEstimateAircraftModal,
        onSelectFromAirport,
        onSelectToAirport,
        onEstimateAircraft,
        fromAirport,
        toAirport,
        estimateAircraft,
        estimateAircraftErrMsg,
        loading,
        onCancelModal,
        onOkModal,
        onDeleteAircraft,
        visibleModal = false,
        setVisibleModal,
        successMsg,
        onDeletePhoto,
        tempImages,
        activeCompany,
        onSave,
        handleChange,
        imageUrl,
        aircraft,
        onClickEdit,
        isEdit,
        setIsEdit,
        images,
        description,
        home_airport,
        current_airport,
        price_reflection_airport,
        crew,
        max_passengers,
        lavatories,
        height_ft,
        length_ft,
        wingspan_ft,
        wing_area_ft2,
        empty_weight_lb,
        max_takeoff_weight_lb,
        luggage_capacity_ft3,
        max_range_in_nautical_miles,
        average_speed_mph,
        setDescription,
        setCrew,
        setMaxPassenger,
        setLavatories,
        setMax_range_in_nautical_miles,

        cruising_altitude_ft,
        cruising_speed_in_knots,
        climb_rate_fpm,
        descent_rate_fpm,
        climb_speed_in_knots,
        descent_speed_in_knots,
        takeoff_distance_ft,
        landing_distance_ft,

        setCruising_altitude_ft,
        setCruising_speed_in_knots,
        setClimb_rate_fpm,
        setDescent_rate_fpm,
        setClimb_speed_in_knots,
        setDescent_speed_in_knots,
        setTakeoff_distance_ft,
        setLanding_distance_ft,
        setHeight_ft,
        setLength_ft,
        setWingspan_ft,
        setWing_area_ft2,
        setEmpty_weight_lb,
        setMax_takeoff_weight_lb,
        setLuggage_capacity_ft3,
        setAverage_speed_mph,
        tax_percentage,
        minimum_booking_time_hours,
        airport_ground_time_minutes,
        positioning_flight_per_hour_fee,
        revenue_flight_per_hour_fee,
        short_term_increment_percentage_6hrs,
        short_term_increment_percentage_12hrs,
        short_term_increment_percentage_24hrs,
        short_term_increment_percentage_48hrs,
        set_tax_percentage,
        set_minimum_booking_time_hours,
        set_airport_ground_time_minutes,
        set_positioning_flight_per_hour_fee,
        set_revenue_flight_per_hour_fee,
        set_short_term_increment_percentage_6hrs,
        set_short_term_increment_percentage_12hrs,
        set_short_term_increment_percentage_24hrs,
        set_short_term_increment_percentage_48hrs,

        departure_fee,
        landing_fee,
        overnight_stay_fee,
        parking_fee,
        set_departure_fee,
        set_landing_fee,
        set_overnight_stay_fee,
        set_parking_fee,
        set_amenities_extra_leg_room,
        set_amenities_wifi,
        set_amenities_bed,
        set_amenities_sink,
        set_amenities_lavatory,
        amenities_extra_leg_room,
        amenities_wifi,
        amenities_bed,
        amenities_sink,
        amenities_lavatory,

        onSelectHomeAirport,
        onSelectCurrentAirport,
        onSelectPriceReflectionAirport,
        onCancelEdit,
        navigate
    } = props;

    const { company } = aircraft;

    const firstAircraftColumn = [
        {
            label: t('CREW').toUpperCase(),
            value: crew || '-',
            onChange: setCrew
        },
        {
            label: t('MAX_PASSENGERS').toUpperCase(),
            value: max_passengers || '-',
            onChange: setMaxPassenger
        },
        {
            label: t('LAVATORIES').toUpperCase(),
            value: lavatories || '-',
            onChange: setLavatories
        }
    ];

    const secondAircraftColumn = [
        {
            label: t('HEIGHT').toUpperCase(),
            value: height_ft || '-',
            onChange: setHeight_ft
        },
        {
            label: t('LENGTH').toUpperCase(),
            value: length_ft || '-',
            onChange: setLength_ft
        },
        {
            label: t('WINGSPAN').toUpperCase(),
            value: wingspan_ft || '-',
            onChange: setWingspan_ft
        },
        {
            label: t('WING_AREA').toUpperCase(),
            value: wing_area_ft2 || '-',
            onChange: setWing_area_ft2
        }
    ];

    const thirdAircraftColumn = [
        {
            label: t('EMPTY_WEIGHT').toUpperCase(),
            value: empty_weight_lb || '-',
            onChange: setEmpty_weight_lb
        },
        {
            label: t('MAX_TAKEOFF_WEIGHT').toUpperCase(),
            value: max_takeoff_weight_lb || '-',
            onChange: setMax_takeoff_weight_lb
        },
        {
            label: t('LUGGAGE_CAPACITY_3').toUpperCase(),
            value: luggage_capacity_ft3 || '-',
            onChange: setLuggage_capacity_ft3
        }
    ];

    const firstPerfomanceColumn = [
        {
            label: t('MAX_RANGE_IN_NAUTICAL_MILES').toUpperCase(),
            value: max_range_in_nautical_miles || '-',
            onChange: setMax_range_in_nautical_miles
        },
        {
            label: t('AVERAGE_SPEED').toUpperCase(),
            value: average_speed_mph || '-',
            onChange: setAverage_speed_mph
        },
        {
            label: t('CRUISING_ALTITUDE').toUpperCase(),
            value: cruising_altitude_ft || '-',
            onChange: setCruising_altitude_ft
        },
        {
            label: t('CRUISING_SPEED_IN_KNOTS').toUpperCase(),
            value: cruising_speed_in_knots || '-',
            onChange: setCruising_speed_in_knots
        }
    ];

    const secondPerfomanceColumn = [
        {
            label: t('CLIMB_RATE').toUpperCase(),
            value: climb_rate_fpm || '-',
            onChange: setClimb_rate_fpm
        },
        {
            label: t('DESCENT_RATE').toUpperCase(),
            value: descent_rate_fpm || '-',
            onChange: setDescent_rate_fpm
        },
        {
            label: t('CLIMB_SPEED_IN_KNOTS').toUpperCase(),
            value: climb_speed_in_knots || '-',
            onChange: setClimb_speed_in_knots
        },
        {
            label: t('DESCENT_SPEED_IN_KNOTS').toUpperCase(),
            value: descent_speed_in_knots || '-',
            onChange: setDescent_speed_in_knots
        }
    ];

    const thirdPerfomanceColumn = [
        {
            label: t('TAKEOFF_DISTANCE').toUpperCase(),
            value: takeoff_distance_ft || '-',
            onChange: setTakeoff_distance_ft
        },
        {
            label: t('LANDING_DISTANCE').toUpperCase(),
            value: landing_distance_ft || '-',
            onChange: setLanding_distance_ft
        }
    ];

    const firstGeneralColumn = [
        {
            label: t('TAX').toUpperCase(),
            value: tax_percentage || '-',
            unit: '%',
            onChange: set_tax_percentage
        },
        {
            label: t('MIN_BOOKING_TIME').toUpperCase(),
            value: minimum_booking_time_hours || '-',
            unit: 'HOURS',
            onChange: set_minimum_booking_time_hours
        },
        {
            label: t('GROUND_TIME_MINUTES').toUpperCase(),
            value: airport_ground_time_minutes || '-',
            unit: 'MINUTES',
            onChange: set_airport_ground_time_minutes
        }
    ];

    const secondGeneralColumn = [
        {
            label: t('POSITION_FEE_PER_HOUR').toUpperCase(),
            value: positioning_flight_per_hour_fee || '-',

            unit: 'USD',
            onChange: set_positioning_flight_per_hour_fee
        },
        {
            label: t('REVENUE_FEE_PER_HOUR').toUpperCase(),
            value: revenue_flight_per_hour_fee || '-',

            unit: 'USD',
            onChange: set_revenue_flight_per_hour_fee
        }
    ];

    const firstShortTermPriceColumn = [
        {
            label: t('UNDER_6_HOURS').toUpperCase(),
            value: short_term_increment_percentage_6hrs || '-',

            unit: '%',
            onChange: set_short_term_increment_percentage_6hrs
        },
        {
            label: t('FROM_6HRS_TO_UNDER_12HRS').toUpperCase(),
            value: short_term_increment_percentage_12hrs || '-',

            unit: '%',
            onChange: set_short_term_increment_percentage_12hrs
        },
        {
            label: t('FROM_12HRS_TO_UNDER_24HRS').toUpperCase(),
            value: short_term_increment_percentage_24hrs || '-',

            unit: '%',
            onChange: set_short_term_increment_percentage_24hrs
        },
        {
            label: t('FROM_24HRS_TO_UNDER_48HRS').toUpperCase(),
            value: short_term_increment_percentage_48hrs || '-',
            unit: '%',
            onChange: set_short_term_increment_percentage_48hrs
        }
    ];

    const firstDefaultAirportColumn = [
        {
            label: t('LANDING_FEE').toUpperCase(),
            value: landing_fee || '-',
            onChange: set_landing_fee
        },
        {
            label: t('DEPARTURE_FEE').toUpperCase(),
            value: departure_fee || '-',
            onChange: set_departure_fee
        }
    ];

    const secondDefaultAirportColumn = [
        {
            label: t('PARKING_FEE').toUpperCase(),
            value: parking_fee || '-',
            onChange: set_parking_fee
        },
        {
            label: t('OVERNIGHT_STAY_FEE').toUpperCase(),
            value: overnight_stay_fee || '-',
            onChange: set_overnight_stay_fee
        }
    ];

    const renderDetailView = () => {
        return (
            <>
                <PhotosSection>
                    <Text textStyle={'heading5'}>
                        {t('PHOTOS').toUpperCase() +
                            ' (' +
                            (tempImages ? tempImages.length : '0') +
                            ')'}
                    </Text>
                    <ListPhotosSection>
                        <List
                            className="ant-list-items-custom"
                            itemLayout="horizontal"
                            dataSource={[
                                ...tempImages,
                                ...[{ uploadComponent: true }]
                            ]}
                            renderItem={(item, index) => {
                                return (
                                    <>
                                        {item.hasOwnProperty(
                                            'uploadComponent'
                                        ) && isEdit ? (
                                            <UploadComponent
                                                uploadMorePhotos={true}
                                                imageUrl={imageUrl}
                                                handleChange={handleChange}
                                            />
                                        ) : (
                                            <>
                                                {!item.hasOwnProperty(
                                                    'uploadComponent'
                                                ) ? (
                                                    <ImageContainer className="image-container">
                                                        <EachImage
                                                            src={item.url}
                                                            width={225}
                                                            height={142}
                                                        />
                                                        {isEdit && (
                                                            <DeletePhoto
                                                                onClick={() =>
                                                                    onDeletePhoto(
                                                                        item
                                                                    )
                                                                }
                                                            >
                                                                <CloseOutlined
                                                                    style={{
                                                                        color: '#7C7C7C',
                                                                        fontSize:
                                                                            '10px',
                                                                        fontWeight:
                                                                            'bolder'
                                                                    }}
                                                                />
                                                            </DeletePhoto>
                                                        )}
                                                    </ImageContainer>
                                                ) : null}
                                            </>
                                        )}
                                    </>
                                );
                            }}
                        />
                        <div className="test"></div>
                    </ListPhotosSection>
                </PhotosSection>
                <EachSection>
                    <Text textStyle={'heading5'}>
                        {t('PROPERTIES').toUpperCase()}
                    </Text>
                    <PropertiesContainer>
                        <DescriptionSection>
                            <DescriptionTitle>
                                <Text textStyle="heading6">
                                    {t('DESCRIPTION')}
                                </Text>
                            </DescriptionTitle>
                            <DescriptionDetail>
                                {isEdit ? (
                                    <TextArea
                                        onChange={(val) => {
                                            setDescription(val.target.value);
                                        }}
                                        rows={4}
                                        style={{ margin: 10 }}
                                        placeholder={i18n.t('DESCRIPTION')}
                                        value={description}
                                    />
                                ) : (
                                    <Text textStyle="paragraphMedium">
                                        {description || t('NO_DESCRIPTION')}
                                    </Text>
                                )}
                            </DescriptionDetail>
                        </DescriptionSection>

                        <Properties
                            isEdit={isEdit}
                            label={t('AIRCRAFT_PROPERTIES')}
                            firstColumn={firstAircraftColumn}
                            secondColumn={secondAircraftColumn}
                            thirdColumn={thirdAircraftColumn}
                        />

                        <Properties
                            isEdit={isEdit}
                            label={t('PERFORMANCE_PROPERTIES')}
                            firstColumn={firstPerfomanceColumn}
                            secondColumn={secondPerfomanceColumn}
                            thirdColumn={thirdPerfomanceColumn}
                        />
                    </PropertiesContainer>
                </EachSection>

                <EachSection>
                    <Text textStyle={'heading5'}>
                        {t('AMENITIES').toUpperCase()}
                    </Text>
                    <PropertiesContainer backgroundColor={'#ffffff'}>
                        <DescriptionSection>
                            <CheckboxSection>
                                <FirstCheckboxColumn>
                                    <EachCheckbox>
                                        <Checkbox
                                            checked={amenities_extra_leg_room}
                                            label={t(
                                                'EXTRA_LEG_ROOM'
                                            ).toUpperCase()}
                                            textStyle="infoNormal"
                                            onChange={(value) => {
                                                isEdit &&
                                                    set_amenities_extra_leg_room(
                                                        value
                                                    );
                                            }}
                                        />
                                    </EachCheckbox>
                                    <EachCheckbox>
                                        <Checkbox
                                            checked={amenities_wifi}
                                            label={t('WIFI').toUpperCase()}
                                            textStyle="infoNormal"
                                            onChange={(value) => {
                                                isEdit &&
                                                    set_amenities_wifi(value);
                                            }}
                                        />
                                    </EachCheckbox>
                                    <EachCheckbox>
                                        <Checkbox
                                            checked={amenities_bed}
                                            label={t('BED').toUpperCase()}
                                            textStyle="infoNormal"
                                            onChange={(value) => {
                                                isEdit &&
                                                    set_amenities_bed(value);
                                            }}
                                        />
                                    </EachCheckbox>
                                </FirstCheckboxColumn>

                                <SecondCheckboxColumn>
                                    <EachCheckbox>
                                        <Checkbox
                                            checked={amenities_sink}
                                            label={t('SINK').toUpperCase()}
                                            textStyle="infoNormal"
                                            onChange={(value) => {
                                                isEdit &&
                                                    set_amenities_sink(value);
                                            }}
                                        />
                                    </EachCheckbox>
                                    <EachCheckbox>
                                        <Checkbox
                                            checked={amenities_lavatory}
                                            onChange={(value) => {
                                                isEdit &&
                                                    set_amenities_lavatory(
                                                        value
                                                    );
                                            }}
                                            label={t('LAVATORY').toUpperCase()}
                                            textStyle="infoNormal"
                                        />
                                    </EachCheckbox>
                                </SecondCheckboxColumn>
                            </CheckboxSection>
                        </DescriptionSection>
                    </PropertiesContainer>
                </EachSection>

                <EachSection>
                    <Text textStyle={'heading5'}>
                        {t('PRICE_PARAMETERS').toUpperCase()}
                    </Text>
                    <PropertiesContainer>
                        <Properties
                            isEdit={isEdit}
                            label={t('GENERAL')}
                            firstColumn={firstGeneralColumn}
                            secondColumn={secondGeneralColumn}
                        />

                        <Properties
                            isEdit={isEdit}
                            label={t('SHORT_TERM_PRICE_INCREMENT')}
                            firstColumn={firstShortTermPriceColumn}
                        />

                        <Properties
                            isEdit={isEdit}
                            label={t('DEFAULT_AIRPORT_FEES')}
                            firstColumn={firstDefaultAirportColumn}
                            secondColumn={secondDefaultAirportColumn}
                        />
                    </PropertiesContainer>
                </EachSection>

                <EachSection>
                    <Text textStyle={'heading5'}>
                        {t('LOCATION').toUpperCase()}
                    </Text>
                    <PropertiesContainer backgroundColor={'#ffffff'}>
                        <DescriptionDetail>
                            <Column width={'40vw'}>
                                <Property>
                                    <PropertyLabelSection isEdit={isEdit}>
                                        <Text textStyle="infoNormal">
                                            {t('HOME_AIRPORT').toUpperCase()}
                                        </Text>
                                    </PropertyLabelSection>
                                    <PropertyLabelSection isEdit={isEdit}>
                                        <Text textStyle="infoNormal">
                                            {t('CURRENT_AIRPORT').toUpperCase()}
                                        </Text>
                                    </PropertyLabelSection>
                                    <PropertyLabelSection>
                                        <Text textStyle="infoNormal">
                                            {t(
                                                'PRICE_REFLECTION_AIRPORT'
                                            ).toUpperCase()}
                                        </Text>
                                    </PropertyLabelSection>
                                    {/* buffer when edit */}
                                    {isEdit && (
                                        <div style={{ height: 15 }}></div>
                                    )}
                                    <PropertyLabelSection>
                                        <Text textStyle="infoNormal">
                                            {t(
                                                'CURRENT_LOCATION'
                                            ).toUpperCase()}
                                        </Text>
                                    </PropertyLabelSection>
                                </Property>
                                <Value>
                                    <PropertyLabelSection>
                                        {isEdit ? (
                                            <SearchAirports
                                                airport={home_airport}
                                                width={370}
                                                onSelectAirport={
                                                    onSelectHomeAirport
                                                }
                                                defaultValue={home_airport}
                                            />
                                        ) : (
                                            <>
                                                <Text
                                                    textStyle="airportName"
                                                    style={{ marginRight: 10 }}
                                                >
                                                    {home_airport?.icao_code ||
                                                        '-'}
                                                </Text>
                                                <Text textStyle="tableText2">
                                                    {home_airport.name || 'N/A'}
                                                </Text>
                                            </>
                                        )}
                                    </PropertyLabelSection>
                                    <PropertyLabelSection>
                                        {isEdit ? (
                                            <SearchAirports
                                                airport={current_airport}
                                                width={370}
                                                // placeholder={'Binh'}
                                                onSelectAirport={
                                                    onSelectCurrentAirport
                                                }
                                                defaultValue={current_airport}
                                            />
                                        ) : (
                                            <>
                                                <Text
                                                    textStyle="airportName"
                                                    style={{ marginRight: 10 }}
                                                >
                                                    {current_airport?.icao_code ||
                                                        '-'}
                                                </Text>
                                                <Text textStyle="tableText2">
                                                    {current_airport.name ||
                                                        'N/A'}
                                                </Text>
                                            </>
                                        )}
                                    </PropertyLabelSection>
                                    <PropertyLabelSection>
                                        {isEdit ? (
                                            <SearchAirports
                                                airport={
                                                    price_reflection_airport
                                                }
                                                width={370}
                                                onSelectAirport={
                                                    onSelectPriceReflectionAirport
                                                }
                                                defaultValue={
                                                    price_reflection_airport
                                                }
                                            />
                                        ) : (
                                            <>
                                                <Text
                                                    textStyle="airportName"
                                                    style={{ marginRight: 10 }}
                                                >
                                                    {price_reflection_airport?.icao_code ||
                                                        '-'}
                                                </Text>
                                                <Text textStyle="tableText2">
                                                    {price_reflection_airport
                                                        ? price_reflection_airport.name
                                                        : 'N/A'}
                                                </Text>
                                            </>
                                        )}
                                    </PropertyLabelSection>
                                    <PropertyLabelSection>
                                        <Text textStyle="tableText2">
                                            {current_airport?.location?.geometry
                                                ?.coordinates[0] +
                                                ' , ' +
                                                current_airport?.location
                                                    ?.geometry
                                                    ?.coordinates[1] || '-'}
                                        </Text>
                                    </PropertyLabelSection>
                                </Value>
                            </Column>
                        </DescriptionDetail>
                    </PropertiesContainer>
                </EachSection>
            </>
        );
    };

    return (
        <>
            <Container>
                <Header title={t('FLEET')} description={t('AIRCRAFT_DETAIL')} />
                <AircraftDetailSummary>
                    <AircraftDetailCardComponent 
                        activeCompany={activeCompany}
                        aircraft={aircraft}
                        from={AIRCRAFT_DETAIL}
                    />
                    <QuickToolBarComponent 
                        isEdit={isEdit}
                        onCancelEdit={onCancelEdit}
                        onClickEdit={onClickEdit}
                        handleDeleteButton={handleDeleteButton}
                        loading={loading}
                        activeCompany={activeCompany}
                        aircraft={aircraft}
                        onOpenEstimateAircraftModal={onOpenEstimateAircraftModal}
                    />
                </AircraftDetailSummary>
                {renderDetailView()}
                <SaveChangesSection>
                    {isEdit ? (
                        <PrimaryButton
                            title={'SAVE CHANGES'}
                            size="large"
                            // backgroundColor="#19C0FF"
                            // textColor="#FFFFFF"
                            // height="50px"
                            width="300px"
                            onClick={onSave}
                        />
                    ) : null}
                </SaveChangesSection>
            </Container>
            <Popup
                cancelModal={() => {
                    onCancelModal && onCancelModal();
                    // setVisibleModal(false);
                }}
                onOk={() => {
                    onOkModal && onOkModal();
                    // setVisibleModal(false);
                    // navigate(BROKER_CONNECTIONS);
                }}
                visibleModal={visibleModal}
                successText={successMsg || ''}
                buttonTitle={t('OK').toUpperCase()}
            />
            <EstimateAircraftModal
                show={visibleEstimateAircraftModal}
                okText={t('ESTIMATE_AIRCRAFT').toUpperCase()}
                onOk={onEstimateAircraft}
                onCancel={onCancelEstimateAircraftModal}
                onSelectFromAirport={onSelectFromAirport}
                onSelectToAirport={onSelectToAirport}
                fromAirport={fromAirport}
                toAirport={toAirport}
                estimateAircraft={estimateAircraft}
                estimateAircraftErrMsg={estimateAircraftErrMsg}
            />
            <WarningModal
                show={showDeleteModal}
                title={t('DELETE_AIRCRAFT_CONFIRMATION').toUpperCase()}
                okText={t('YES_REMOVE_IT').toUpperCase()}
                cancelText={t('NO_DISREGARD').toUpperCase()}
                onOk={onRemove}
                onCancel={onCancelWarning}
            />
        </>
    );
};

export { template };
