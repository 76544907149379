// import { createSelector } from 'reselect';
export const allowed_roles = ['PILOT', 'COPILOT', 'HOST'];

export const getActiveCompany = (state) =>
    state.companyReducer.activeCompany || {};

export const getCompanies = (state) => state.companyReducer.companies || {};

export const getEmployees = (state) => state.companyReducer.employees || {};

export const getCustomers = (state) => state.companyReducer.customers || {};

export const getCustomerInfo = (state, user_id) =>
    state.companyReducer.customers.find((customer) => customer.id === user_id);

export const getEmployeeInfo = (state, user_id) =>
    state.companyReducer.employees.find((employee) => employee.id === user_id);

export const getEmployeesForCrew = (state) => {
    state.companyReducer.employees.find((employee) =>
        allowed_roles.includes(employee.role)
    );
};

export const getCompanyInfo = (state, _company) => 
    state.companyReducer.companies.find((company) => company.id === _company);