import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { Upload, Col, Row, Input, Switch, message, Avatar, Image } from 'antd';
import { HeaderInfo } from 'components/Cards';
import { ButtonCustom } from 'components';
import { imageIcon } from 'assets/images/icons';
import '../setting.css';
import {
    UploadText,
    StatusWrapper,
    SwitchWrapper,
    Text,
    Label,
    Container,
    Title,
    TitleDescription,
    BottomContainer
} from '../styles';
import { renderAddress } from 'utils';

const EachInfo = (props) => {
    return (
        <>
            <Title
                style={{
                    marginBottom: 3,
                    marginTop: 10
                }}
            >
                {props.label}
            </Title>
            <TitleDescription color={props.color}>
                {props.detail}
            </TitleDescription>
        </>
    );
};

const General = (props) => {
    const {
        userInfo = {},
        onSearchCountries,
        onSubmit,
        updateProfileImage
    } = props;
    const {
        address = {},
        companies = {},
        preferences = {},
        settings = {}
    } = userInfo;

    const { t, i18n } = useTranslation();

    const [isUpdate, setIsUpdate] = useState(false);
    const [loading, setLoading] = useState(false);

    const [image, setImage] = useState('');

    const [firstname, setFirstName] = useState('');
    const [name, setName] = useState('');
    const [lastname, setLastName] = useState('');
    const [role, setRole] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [company, setCompany] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [postCode, setPostCode] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');

    const [visibleOperator, setVisibleOperator] = useState(false);
    const [lookingOperator, setLookingOperator] = useState(false);

    useEffect(() => {
        setImage(userInfo.image);
        setName(userInfo.name);
        setFirstName(userInfo.firstname || '');
        setLastName(userInfo.lastname || '');
        setEmail(userInfo.email || '');
        setPhone(userInfo.phone || '');
        setRole(userInfo.role || '');
        setCompany(userInfo.meta?.company_name || '');
        setAddressLine1(address?.address_line1 || '');
        setAddressLine2(address?.address_line2 || '');
        setPostCode(address?.postalcode || '');
        setCity(address?.city || '');
        setCountry(address?.country?.english_name || '');
        setState(address?.state || '');

        setVisibleOperator(settings.visible_to_operators);
        setLookingOperator(settings.looking_for_operator);
    }, [props.userInfo]);

    const onSubmitForm = () => {
        const data = {
            email: email,
            firstname: firstname,
            lastname: lastname,
            phone: phone,
            type: role,
            meta: {
                company_name: company
            }
        };
        onSubmit(data);
    };

    const onClickEdit = () => {
        setIsUpdate(!isUpdate);
    };

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
    };

    const _renderUpdateView = () => {
        return (
            <WrapperUpdate>
                <Row style={{ marginTop: 10 }}>
                    <Upload
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                        onChange={updateProfileImage}
                        multiple={false}
                    >
                        <BackgroundAvatar
                            edit={true}
                            url={image}
                            className="ant-avatar-custom"
                            size={100}
                            style={{
                                backgroundColor: '#F0F3F9',
                                width: '100px',
                                height: '100px',
                                borderRadius: '100%'
                            }}
                        >
                            <div className="overlay-avatar">
                                <Image
                                    src={imageIcon}
                                    preview={false}
                                    style={{
                                        width: 22,
                                        height: 18
                                    }}
                                />
                            </div>
                        </BackgroundAvatar>
                    </Upload>
                </Row>
                <Row style={{ marginTop: 10 }}>
                    <StatusWrapper>
                        <Col lg="3" sm="3" xs="12">
                            <UploadText>
                                {i18n.t('STATUS').toUpperCase()}
                            </UploadText>
                        </Col>
                        <Col lg="3" sm="3" xs="12">
                            <SwitchWrapper>
                                <Text>{i18n.t('LOOKING_FOR_WORK')}</Text>
                                <Switch
                                    checked={lookingOperator}
                                    style={{ marginLeft: 5 }}
                                />
                            </SwitchWrapper>
                        </Col>
                        <Col lg="3" sm="3" xs="12">
                            <SwitchWrapper>
                                <Text>{i18n.t('VISIBLE_TO_USERS')}</Text>
                                <Switch
                                    checked={visibleOperator}
                                    style={{ marginLeft: 5 }}
                                />
                            </SwitchWrapper>
                        </Col>
                    </StatusWrapper>
                </Row>
                <Row style={{ marginTop: 5 }}>
                    <Col lg="3" sm="3" xs="12">
                        <Label>{i18n.t('FIRST_NAME').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setFirstName(val.target.value)}
                            className="base left"
                            style={{ fontFamily: 'Lato', color: '#65686F' }}
                            placeholder={i18n.t('FIRST_NAME')}
                            value={firstname}
                        />
                    </Col>
                    <Col lg="3" sm="3" xs="12">
                        <Label>{i18n.t('LAST_NAME').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setLastName(val.target.value)}
                            className="base"
                            style={{ fontFamily: 'Lato', color: '#65686F' }}
                            placeholder={i18n.t('LAST_NAME')}
                            value={lastname}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: 5 }}>
                    <Col lg="3" sm="3" xs="12">
                        <Label>{i18n.t('EMAIL_ADDRESS').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setEmail(val.target.value)}
                            className="base left"
                            style={{ fontFamily: 'Lato', color: '#65686F' }}
                            placeholder={i18n.t('EMAIL_ADDRESS')}
                            value={email}
                        />
                    </Col>
                    <Col lg="3" sm="3" xs="12">
                        <Label>{i18n.t('PHONE_NUMBER').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setPhone(val.target.value)}
                            className="base"
                            style={{ fontFamily: 'Lato', color: '#65686F' }}
                            placeholder={i18n.t('PHONE_NUMBER')}
                            value={phone}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: 5 }}>
                    <Col lg="6" sm="6" xs="12">
                        <Label>{i18n.t('COMPANY').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setCompany(val.target.value)}
                            className="base"
                            style={{ fontFamily: 'Lato', color: '#65686F' }}
                            placeholder={i18n.t('COMPANY')}
                            value={company}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: 5 }}>
                    <Col lg="6" sm="6" xs="12">
                        <Label>{i18n.t('ADDRESS_LINE_1').toUpperCase()}</Label>
                        <Input
                            onChange={(val) =>
                                setAddressLine1(val.target.value)
                            }
                            className="address"
                            style={{ fontFamily: 'Lato', color: '#65686F' }}
                            placeholder={i18n.t('ADDRESS_LINE_1')}
                            value={addressLine1}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: 5 }}>
                    <Col lg="6" sm="6" xs="12">
                        <Label>{i18n.t('ADDRESS_LINE_2').toUpperCase()}</Label>
                        <Input
                            onChange={(val) =>
                                setAddressLine2(val.target.value)
                            }
                            className="address "
                            style={{ fontFamily: 'Lato', color: '#65686F' }}
                            placeholder={i18n.t('ADDRESS_LINE_2')}
                            value={addressLine2}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: 5 }}>
                    <Col lg="3" sm="3" xs="12">
                        <Label>{i18n.t('POST_CODE').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setPostCode(val.target.value)}
                            className="base left"
                            style={{ fontFamily: 'Lato', color: '#65686F' }}
                            placeholder={i18n.t('POST_CODE')}
                            value={postCode}
                        />
                    </Col>
                    <Col lg="3" sm="3" xs="12">
                        <Label>{i18n.t('CITY').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setCity(val.target.value)}
                            className="base"
                            style={{ fontFamily: 'Lato', color: '#65686F' }}
                            placeholder={i18n.t('CITY')}
                            value={city}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: 5 }}>
                    <Col lg="3" sm="3" xs="12">
                        <Label>{i18n.t('COUNTRY').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setCountry(val.target.value)}
                            className="base left"
                            style={{ fontFamily: 'Lato', color: '#65686F' }}
                            placeholder={i18n.t('COUNTRY')}
                            value={country}
                        />
                    </Col>
                    <Col lg="3" sm="3" xs="12">
                        <Label>{i18n.t('STATE').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setState(val.target.value)}
                            className="base"
                            style={{ fontFamily: 'Lato', color: '#65686F' }}
                            placeholder={i18n.t('STATE')}
                            value={state}
                        />
                    </Col>
                </Row>
                <BottomContainer>
                    <ButtonCustom
                        btnType="primary"
                        width="190px"
                        onClick={() => onSubmitForm()}
                        title={i18n.t('SAVE_CHANGES').toUpperCase()}
                    />
                </BottomContainer>
            </WrapperUpdate>
        );
    };

    const _renderDetailView = () => {
        const resCol = {
            xxl: 6,
            xl: 6,
            lg: 7,
            md: 10,
            sm: 10,
            xs: 12
        };
        return (
            <Component className="wrapper">
                <Row>
                    <GeneralSection>
                        <AvatarSection style={{ height: '100%' }}>
                            {image ? (
                                <Avatar
                                    className="ant-avatar-custom ant-avatar-custom-big"
                                    src={image}
                                    size={100}
                                />
                            ) : (
                                <Avatar
                                    className="ant-avatar-custom ant-avatar-custom-big"
                                    size={100}
                                >
                                    {(
                                        firstname.charAt(0) + lastname.charAt(0)
                                    ).toUpperCase()}
                                </Avatar>
                            )}
                        </AvatarSection>
                        <InfoDetailSection style={{ width: '100%' }}>
                            <NameSection>
                                <Name>
                                    {(firstname + ' ' + lastname).toUpperCase()}
                                </Name>
                            </NameSection>
                            <InfoDetail>
                                <Col
                                    { ...resCol }
                                    style={{ marginRight: '20px' }}
                                >
                                    <EachInfo
                                        label={t('EMAIL_ADDRESS')}
                                        detail={email.toUpperCase()}
                                    />
                                </Col>
                                <Col
                                    { ...resCol }
                                    style={{ marginRight: '20px' }}
                                >
                                    <EachInfo
                                        label={t('PHONE_NUMBER')}
                                        detail={phone || '-'}
                                    />
                                </Col>
                                <Col
                                    { ...resCol }
                                    style={{ marginRight: '20px' }}
                                >
                                    <EachInfo
                                        label={t('ADDRESS')}
                                        detail={renderAddress(
                                            address
                                        ).toUpperCase()}
                                    />
                                </Col>
                                <Col
                                    { ...resCol }
                                    style={{ marginRight: '20px' }}
                                >
                                    <EachInfo
                                        label={t('COMPANY')}
                                        detail={company.toUpperCase()}
                                        color={'#19C0FF'}
                                    />
                                </Col>
                                <Col
                                    { ...resCol }
                                    style={{ marginRight: '20px' }}
                                >
                                    <EachInfo
                                        label={t('ROLE')}
                                        detail={role.toUpperCase()}
                                        color={'#19C0FF'}
                                    />
                                </Col>
                            </InfoDetail>
                        </InfoDetailSection>
                    </GeneralSection>
                </Row>
            </Component>
        );
    };

    return (
        <Container>
            <HeaderInfo
                title={i18n.t('GENERAL_INFO').toUpperCase()}
                actionText={i18n.t('EDIT').toUpperCase()}
                isUpdate={isUpdate}
                onClick={onClickEdit}
            />
            {!isUpdate ? _renderDetailView() : _renderUpdateView()}
        </Container>
    );
};

export default General;

const Component = styled.div`
    background-color: #ffffff;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
`;

const GeneralSection = styled(Col)`
    flex: 1 1 200px;
    display: flex;
    align-items: center;
    margin-left: 5px;
`;

const AvatarSection = styled(Col)``;

const InfoDetailSection = styled(Col)`
    margin-left: 40px;
`;

const NameSection = styled(Row)``;

const InfoDetail = styled(Row)`
    width: 100%;
`;

const Name = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 20px;
    letter-spacing: 0.8px;
    line-height: 32px;
    width: 261px;
    text-align: left;
`;

const WrapperUpdate = styled.div`
    padding-left: 45px;
`;

const BackgroundAvatar = styled.div`
    position: relative;
    background-image: url(${(props) => props.url});
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-wrap: wrap;

    .overlay-avatar {
        position: absolute;
        height: 100px;
        width: 100px;
        border-radius: 100%;
        top: 0;
        left: 0;
        background: ${(props) =>
            !props.edit ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, 0.35)'};
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
`;
