import React, { useState, useEffect } from 'react';
import { template as tmpl } from './TopRatedAirportDetail.tmpl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getTopRatedAirportById } from 'redux/actions/flightAction';
import { updateTopRatedAirport, removeTopRatedAirport } from 'redux/actions/airportAction';
import { uploadImage } from 'redux/actions/appActions';
import { useTranslation } from 'react-i18next';
import { FLIGHT_PROMOTION_LIST } from 'routes/main';
import { useDispatch, useSelector } from 'react-redux';
import { UPLOAD_MODEL, UPLOAD_IMAGE_TYPE, SHOW_LOADING, HIDE_LOADING } from 'redux/Constants';

const TopAirportDetailLogic = (props) => {
    const {
        getTopRatedAirportById,
        updateTopRatedAirport,
        removeTopRatedAirport,
        uploadImage,
        location,
    } = props;
    const { search } = location;
    const dispatch = useDispatch();
    const { isLoading } = useSelector(state => state.appReducer || {});
    const { t, i18n } = useTranslation();

    const [detail, setDetail] = useState({});
    const [image, setImage] = useState('');
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        const initDetail = async () => {
            try {
                let id = search;
                if (id && id[0] === '?') {
                    id = id.substring(1);
                }
    
                const rs = await getTopRatedAirportById(id);
                if (rs && rs.success) {
                    setDetail(rs.data);
                    setEnabled(rs.data.enable);

                    let img = rs.data.image?.lowres?.url || rs.data.image?.url;
                    setImage(img);
                }
            } catch {};
        };

        // init customer detail
        initDetail();
    }, []);

    const navigate = (route) => {
        props.history.push(route);
    };

    const onSubmit = async (data) => {
        // 
    };

    const onChangeEnable = async () => {
        const rs = await updateTopRatedAirport(detail?.id, { enable: !enabled });
        if (rs && rs.success) {
            setEnabled(!enabled);
        } 
    }

    const onDeleteAirport = async () => {
        let rs = await removeTopRatedAirport(detail?.id);
        if (rs.success) {
            let modal = {
                show: true,
                title: t('DELETE_TOP_RATED_SUCCESS'),
                buttonTitle: 'OK',
                onOk: FLIGHT_PROMOTION_LIST,
                onCancel: FLIGHT_PROMOTION_LIST
            };
            dispatch({ type: 'SETTING_SHOW_MODAL', data: modal });
        } else {
            let modal = {
                show: true,
                type: 'error',
                title: t('DELETE_TOP_RATED_ERROR'),
                buttonTitle: 'OK',
            };
            dispatch({ type: 'SETTING_SHOW_MODAL', data: modal });
        }
    }

    const onSaveChanges = async (data = {}) => {
        let itemUpdated = {
            name: data.name,
            description: data.description,
            airport: data.airport
        }
        const rs = await updateTopRatedAirport(detail?.id, itemUpdated);
        if (rs && rs.success) {
            setDetail({ ...detail, ...itemUpdated });
            let modal = {
                show: true,
                title: t('UPDATE_TOP_RATED_SUCCESS'),
                buttonTitle: 'OK',
            };
            dispatch({ type: 'SETTING_SHOW_MODAL', data: modal });
        } else {
            let modal = {
                show: true,
                type: 'error',
                title: t('UPDATE_TOP_RATED_ERROR'),
                buttonTitle: 'OK',
            };
            dispatch({ type: 'SETTING_SHOW_MODAL', data: modal });
        }
    }

    const updateImage = async ({ file, fileList }) => {
        if (isLoading) return;
        dispatch({ type: SHOW_LOADING });
        
        try {
            let rs = await uploadImage(
                detail?.id,
                file,
                UPLOAD_MODEL.TOP_RATE_AIRPORT,
                UPLOAD_IMAGE_TYPE.MAIN
            );
            if (rs && rs.success) {
                setImage(rs.url);
            }
        } catch (e) {};

        setTimeout(() => {
            dispatch({ type: HIDE_LOADING });
        }, 300);
    };

    const initProps = {
        detail: detail,
        image: image,
        enabled: enabled,
        onSubmit: onSubmit,
        navigate: navigate,
        onChangeEnable: onChangeEnable,
        onDeleteAirport: onDeleteAirport,
        onSaveChanges: onSaveChanges,
        updateImage: updateImage
    };

    return tmpl && tmpl({ ...initProps });
};

class TopAirportDetailContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <TopAirportDetailLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { userReducer, companyReducer } = state;
    const { activeCompany, companies } = companyReducer;
    return {
        user: userReducer,
        companies: companies,
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTopRatedAirportById: (id) => dispatch(getTopRatedAirportById(id)),
        removeTopRatedAirport: (id) => dispatch(removeTopRatedAirport(id)),
        updateTopRatedAirport: (id, data) =>
            dispatch(updateTopRatedAirport(id, data)),
        uploadImage: (id, photo, model, type) =>
            dispatch(uploadImage(id, photo, model, type))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(TopAirportDetailContainer)
);
