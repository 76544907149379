import API from '../../modules/httpModule';
import {
    UPDATE_USER_COMPANY_LIST,
    UPDATE_COMPANY,
    UPDATE_ACTIVE_COMPANY,
    COMPANY_TYPE,
    HANDLER_TYPE
} from '../Constants';

/**
 * Update companies list after API returns
 * @param {object} companies - companies that user is belong to
 * @returns true if success update to reducer. Otherwise will return false
 */
export function updateCompaniesList(companies) {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: UPDATE_USER_COMPANY_LIST,
                userCompanies: companies
            });
            return true;
        } catch (err) {
            return false;
        }
    };
}

/**
 * get list of all companies
 * @returns
 */
export function fetchCompanies() {
    return async (dispatch) => {
        try {
            const response = await API.get('/companies', { brief_info: true });
            if (response && response.status === 200 && response.data) {
                // update brief company info into redux
                dispatch({
                    type: UPDATE_COMPANY,
                    companies: response.data
                });
            }
        } catch (err) {
            return {
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * Update the active company
 * @param {object} company - company that user is active
 * @returns true if success update to reducer. Otherwise will return false
 */
// export function updateActiveCompany(activeCompany) {
//     return async (dispatch, getState) => {
//         try {
//             dispatch({
//                 type: UPDATE_ACTIVE_COMPANY,
//                 activeCompany: activeCompany
//             });
//             return true;
//         } catch (err) {
//             return false;
//         }
//     };
// }

/**
 * search company by name,id,address
 * @param {String} input - company name
 * @param {String} type - type of HANDLER_TYPE
 * @param {String} company_id - company id, this is to search non head quarter handler
 * @returns - companies as pagination
 */
export function searchCompanies(input, type) {
    return async (dispatch) => {
        try {
            let query = { key: input };
            if (type) query.type = type;

            const response = await API.get('/search/companies', query);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    isConnected: true,
                    companies: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * get list of all companies
 * @param {Object} query - query data
 * @param {String} query.type - COMPANY_TYPE , if only want to get 1 type of company
 * @param {Boolean} query.show_brokers - set to 'false' if to skip BROKER type
 * @param {Boolean} query.show_operators - set to 'false' if to skip OPERATOR type
 * @param {Boolean} query.show_handlers - set to 'false' if to skip HANDLER type
 * @param {Boolean} query.brief_info - set to 'true' if want to get only brief info of companies without paging.
 * @param {Boolean} query.partnership - set to 'true' if want to get only partnership.
 * @returns
 */
export function getCompanies(query) {
    return async (dispatch) => {
        try {
            const response = await API.get('/companies', query);
            if (response && response.status === 200 && response.data) {
                return {
                    isConnected: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

export function getCompanyById(id) {
    return async (dispatch) => {
        try {
            const query = {
                expanded: true
            };
            const response = await API.get(`/companies/${id}`, query);
            if (response && response.status === 200) {
                return {
                    success: true,
                    isConnected: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * Update the active company
 * @param {object} id - company id that user is active
 * @returns true if success update to reducer. Otherwise will return false
 */
export function updateActiveCompany(id) {
    return async (dispatch) => {
        try {
            const query = {
                expanded: true
            };
            const response = await API.get(`/v2/companies/${id}`, query);
            if (response && response.status === 200) {
                // return {
                //     success: true,
                //     isConnected: true,
                //     data: response.data
                // };
                dispatch({
                    type: UPDATE_ACTIVE_COMPANY,
                    activeCompany: response.data
                });
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * get FBO companies
 * @param {Object} query - query data
 * @param {String} query.type - COMPANY_TYPE.FBO
 * @returns
 */
export function getFBOs(query) {
    return async (dispatch) => {
        try {
            let params = { type: COMPANY_TYPE.FBO };
            if (query) {
                params = Object.assign(params, query);
            }
            const response = await API.get('/handlers', params);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    isConnected: true,
                    data: response.data.docs || []
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * get a FBO
 * @param {Object} id - FBO id in string
 * @returns
 */
export function getFBOById(id) {
    return async (dispatch) => {
        try {
            const query = {
                expanded: true
            };
            const response = await API.get(`/handlers/${id}`, query);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    isConnected: true,
                    data: response.data
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * Search FBO by name
 * @param {String} input - name,id or icao_code code
 * @param {String} type - type of HANDLER_TYPE
 * @param {String} company_id - company id, this is to search non head quarter handler
 * @returns {Object} FBO info
 */
export function searchFBO(input, query) {
    return async (dispatch) => {
        try {
            let params = { key: input };
            if (query) {
                params = Object.assign(params, query);
            }
            const response = await API.get('/search/handlers', params);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    isConnected: true,
                    data: response.data || []
                };
            }
        } catch (err) {
            return {
                success: false,
                data: [],
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * update FBO
 * @param {Object} id - FBO id in string
 * @param {Object} data - fbo payload to update
 * @returns
 */
export function updateFBO(id, data) {
    return async (dispatch) => {
        try {
            const response = await API.put(`/handlers/${id}`, data);
            if (response && response.status === 200 && response.data) {
                //
            }
        } catch (err) {
            return {
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * Add new handler
 * @param {*} data
 * @returns
 */
export function addCompanyHandler(data) {
    return async (dispatch) => {
        try {
            const response = await API.post('/handlers', data);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    isConnected: true,
                    id: response.data?.id
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * Add new handler
 * @param {*} data
 * @returns
 */
export function addHandler(data) {
    return async (dispatch) => {
        try {
            const response = await API.post('/companies/companies', data);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    isConnected: true,
                    company_id: response.data?.id,
                    handler_id: response.data?.handler_id
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * Update company
 * @param {*} data
 * @returns
 */
export function updateCompany(id, data) {
    return async (dispatch) => {
        try {
            const response = await API.put(`/companies/${id}`, data);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    isConnected: true
                };
            }
        } catch (err) {
            return {
                success: false,
                isConnected: false,
                error: err
            };
        }
    };
}

/**
 * get invoice list
 * @param {Object} query - query data
 * @param {String} query.type - COMPANY_TYPE.FBO
 * @returns
 */
export function getInvoices(query) {
    return async (dispatch) => {
        try {
            const response = await API.get('/invoices', query);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    data: response.data.docs || []
                };
            }
        } catch (err) {
            return {
                success: false,
                error: err
            };
        }
    };
}

/**
 * get invoice list
 * @param {Object} query - query data
 * @param {String} query.type - COMPANY_TYPE.FBO
 * @returns
 */
export function getInvoiceDetail(id) {
    return async (dispatch) => {
        try {
            const response = await API.get(`/invoices/${id}`);
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    data: response.data || {}
                };
            }
        } catch (err) {
            return {
                success: false,
                error: err
            };
        }
    };
}

/**
 * mark as paid invoice
 * @param {String} id - invoice id
 * @returns
 */
 export function markAsPaidInvoice(id) {
    return async (dispatch) => {
        try {
            const response = await API.put(`/invoices/${id}`, { marked_as_paid: true });
            if (response && response.status === 200 && response.data) {
                return {
                    success: true,
                    data: response.data || {}
                };
            }
        } catch (err) {
            return {
                success: false,
                error: err
            };
        }
    };
}
