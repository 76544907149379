import React from 'react';
import 'antd/dist/antd.css';
import { Table } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ColumnHeader } from 'styles/shareStyles';
import { ButtonCustom, Text, BookingStatus, localeEmptyTable } from 'components';
import { ButtonType } from 'components/Antd/Button';

const modelTest = [
    {
        id: 1,
        departure: {
            time: '07:30 AM, May 25, 2020',
            airport: {
                icao: 'LAS',
                name: 'Las Vegas International Airport'
            }
        },
        arrival: {
            time: '09:00 AM, May 25, 2020',
            airport: {
                icao: 'SAN',
                name: 'San Diego International Airport'
            }
        },
        booking_status: 'Awaiting Confirmation'
    },
    {
        id: 2,
        departure: {
            time: '06:30 AM, May 26, 2020',
            airport: {
                icao: 'SFO',
                name: 'San Francisco International Airport'
            }
        },
        arrival: {
            time: '08:30 AM, May 26, 2020',
            airport: {
                icao: 'LAS',
                name: 'Las Vegas International Airport'
            }
        },
        booking_status: 'Awaiting Confirmation'
    }
];

const ConnectFlight = ({ connect_flights = [] }) => {
    const { t, i18n } = useTranslation();
    const columns = [
        {
            title: function titleHeader() {
                return <ColumnHeader>{'Departure Time'}</ColumnHeader>;
            },
            width: '15%',
            dataIndex: 'departure',
            key: 'departure',
            render: function eachItemBooking(departure, row) {
                return (
                    <Text textStyle="tableText1">
                        {departure ? departure.time : '_'}
                    </Text>
                );
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{'Arrival Time'}</ColumnHeader>;
            },
            width: '15%',
            dataIndex: 'arrival',
            key: 'arrival',
            render: function eachItemBooking(arrival, row) {
                return (
                    <Text textStyle="tableText1">
                        {arrival ? arrival.time : '_'}
                    </Text>
                );
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{'Departure'}</ColumnHeader>;
            },
            width: '20%',
            dataIndex: 'departure',
            key: 'departure',
            render: function eachItemBooking(departure, row) {
                return (
                    <div>
                        <Text textStyle="airportName">
                            {departure ? departure.airport.icao : '_'}
                        </Text>
                        <div />
                        <Text textStyle="tableText1">
                            {departure ? departure.airport.name : '_'}
                        </Text>
                    </div>
                );
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{'Arrival'}</ColumnHeader>;
            },
            width: '20%',
            dataIndex: 'arrival',
            key: 'arrival',
            render: function eachItemBooking(arrival, row) {
                return (
                    <div>
                        <Text textStyle="airportName">
                            {arrival ? arrival.airport.icao : '_'}
                        </Text>
                        <div />
                        <Text textStyle="tableText1">
                            {arrival ? arrival.airport.name : '_'}
                        </Text>
                    </div>
                );
            }
        },
        {
            title: function titleHeader() {
                return <ColumnHeader>{'Booking Status'}</ColumnHeader>;
            },
            width: '15%',
            width: 120,
            dataIndex: 'booking_status',
            key: 'booking_status',
            render: function eachItemBooking(booking_status, row) {
                //TODO: This is a dummy data for connected flights
                return (
                    <BookingStatus
                        type="BOOKING"
                        status="AWAITING_CONFIRMATION"
                    />
                );
            }
        },
        {
            key: 'action',
            width: '15%',
            render: function setAction(val) {
                return (
                    <DetailSection>
                        <ButtonCustom
                            btnType={ButtonType.SECONDARY}
                            title={t('VIEW_DETAIL').toUpperCase()}
                            size="small"
                            borderColor="1px solid rgba(18, 18, 22, 0.2)"
                            width="115px"
                            onClick={(e) => {}}
                        />
                    </DetailSection>
                );
            }
        }
    ];

    return (
        <Table
            columns={columns}
            dataSource={modelTest}
            style={{ marginTop: 20 }}
            scroll={{ x: 1000 }}
            pagination={{
                hideOnSinglePage: true,
                showSizeChanger: false
            }}
            locale={localeEmptyTable()}
        />
    );
};

const DetailSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 5px;
`;

export default ConnectFlight;
