import React from 'react';
import styled from 'styled-components';
import background from '../../assets/images/BG-image.png';
import 'antd/dist/antd.css';
import { Layout, Tabs, Col, Row, Avatar } from 'antd';
import { useTranslation } from 'react-i18next';
import { EDIT_SETTING_PROFILE } from '../../routes/main';
import {
    BackgroundProfile,
    ProfileInfo,
    TabBarNavigator,
    AboutWrapper,
    AboutContent,
    AboutText,
    TimelineWrapper,
    ConnectionWrapper,
    EditButton,
    MenuTitle,
    TabText,
    Title,
    TitleDescription,
    EditText,
    ExpandWrapper,
    Name,
    Comapy,
    AvatarWrapper,
    PhotoWrapper,
    Photo,
    ExpandButton,
    ExpandText
} from './styles';
import { renderAddress } from '../../utils';
import {
    aboutIcon,
    connectionIcon,
    timelineIcon
} from '../../assets/images/icons';
import { EmptyComponent } from '../../components';

const TabItems = ({ title, icon }) => {
    return (
        <span style={{ paddingLeft: 25, paddingRight: 25 }}>
            <Icon src={icon} />
            <TabText>{title}</TabText>
        </span>
    );
};

const { TabPane } = Tabs;

const template = (props) => {
    const { navigate, user = {}, activeCompanyName = '' } = props;
    const {
        image = '',
        images = [],
        address,
        email = '',
        firstname = '',
        lastname = '',
        phone = '',
        description = '',
        membership = {},
        gender,
        identification
    } = user;

    const { t, i18n } = useTranslation();

    const _renderTabAbout = () => {
        return (
            <AboutWrapper>
                <MenuTitle>{i18n.t('ABOUT').toUpperCase()}</MenuTitle>
                {description ? (
                    <AboutContent>
                        <AboutText>{description}</AboutText>
                    </AboutContent>
                ) : (
                    <EmptyComponent />
                )}

                <MenuTitle style={{ marginTop: 35 }}>{`${i18n
                    .t('PHOTOS')
                    .toUpperCase()} (${images.length})`}</MenuTitle>
                {images && images.length > 0 ? (
                    <>
                        <PhotoWrapper>
                            {images.map((v, i) => (
                                <Photo key={i} url={v.url} />
                            ))}
                        </PhotoWrapper>
                        {images.length > 4 && (
                            <ExpandWrapper>
                                <ExpandButton>
                                    <ExpandText>
                                        {i18n
                                            .t('EXPAND_ALL_PHOTOS')
                                            .toUpperCase()}
                                    </ExpandText>
                                </ExpandButton>
                            </ExpandWrapper>
                        )}
                    </>
                ) : (
                    <EmptyComponent />
                )}
            </AboutWrapper>
        );
    };

    const _renderProfile = () => {
        return (
            <ProfileInfo>
                <Col span={6} style={{ justifyContent: 'space-around' }}>
                    <Title style={{ marginBottom: 10 }}>
                        {i18n.t('ADDRESS')}
                    </Title>
                    <TitleDescription>
                        {renderAddress(address).toUpperCase()}
                    </TitleDescription>
                </Col>
                <Col span={6}>
                    <Title style={{ marginBottom: 10 }}>
                        {i18n.t('PHONE_NUMBER')}
                    </Title>
                    <TitleDescription>
                        {phone ? phone.toUpperCase() : '_'}
                    </TitleDescription>
                </Col>
                <Col span={6}>
                    <Title style={{ marginBottom: 10 }}>
                        {i18n.t('EMAIL_ADDRESS')}
                    </Title>
                    <TitleDescription>
                        {email ? email.toUpperCase() : '_'}
                    </TitleDescription>
                </Col>
            </ProfileInfo>
        );
    };

    return (
        <Layout style={{ backgroundColor: 'transperent' }}>
            <BackgroundProfile background={background}>
                <div className="overlay-background">
                    <AvatarWrapper>
                        <Row>
                            <Col>
                                {image ? (
                                    <Avatar
                                        className="ant-avatar-custom ant-avatar-custom-big avatar-item"
                                        src={image}
                                        size={120}
                                    />
                                ) : (
                                    <Avatar
                                        className="ant-avatar-custom ant-avatar-custom-big avatar-item"
                                        size={120}
                                    >
                                        {(
                                            firstname.charAt(0) +
                                            lastname.charAt(0)
                                        ).toUpperCase()}
                                    </Avatar>
                                )}
                            </Col>
                            <Col className="display-name">
                                <div>
                                    <Name>{`${firstname.toUpperCase()} ${lastname.toUpperCase()}`}</Name>
                                </div>
                                <div>
                                    <Comapy>
                                        {activeCompanyName.toUpperCase()}
                                    </Comapy>
                                </div>
                            </Col>
                        </Row>
                    </AvatarWrapper>
                </div>
                <EditButton onClick={() => navigate(EDIT_SETTING_PROFILE)}>
                    <EditText>{i18n.t('EDIT_PROFILE').toUpperCase()}</EditText>
                </EditButton>
            </BackgroundProfile>
            {_renderProfile()}

            <TabBarNavigator>
                <Tabs defaultActiveKey="ABOUT" style={{ width: '100%' }}>
                    <TabPane
                        key="ABOUT"
                        tab={
                            <TabItems
                                icon={aboutIcon}
                                title={i18n.t('ABOUT').toUpperCase()}
                            />
                        }
                    >
                        {_renderTabAbout()}
                    </TabPane>
                    <TabPane
                        key="TIMELINE"
                        tab={
                            <TabItems
                                icon={timelineIcon}
                                title={i18n.t('TIMELINE').toUpperCase()}
                            />
                        }
                    >
                        <EmptyComponent />
                    </TabPane>
                    <TabPane
                        key="CONNECTION"
                        tab={
                            <TabItems
                                icon={connectionIcon}
                                title={i18n.t('CONNECTION').toUpperCase()}
                            />
                        }
                    >
                        <EmptyComponent />
                    </TabPane>
                </Tabs>
            </TabBarNavigator>
        </Layout>
    );
};

export { template };

const Icon = styled.img`
    margin: 5px;
`;
