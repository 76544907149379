import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tabs } from 'antd';
import 'antd/dist/antd.css';
import { IconBoardingPass } from 'assets/images/icons';
import { Text } from 'components';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;

/**
 *
 * @param {*} props
 * @param {*} textStyle fieldLabel
 * @returns
 */
const TabsComponent = (props) => {
    const { children, tabRenderer = [], onChange, currentTab } = props;
    const { t, i18n } = useTranslation();
    const [activeKey, setActiveKey] = useState('');

    useEffect(() => {
        if (Array.isArray(tabRenderer) && tabRenderer.length > 0) {
            let tabKey = !currentTab ? tabRenderer[0].key : currentTab;
            
            setActiveKey(tabKey);
            if (onChange && typeof onChange === 'function') {
                onChange(tabKey);
            }
        }
    }, [currentTab]);

    const renderTabBar = (label, key, icon) => {
        return (
            <TabBarContainer key={key}>
                {activeKey === key ? (
                    icon ? (
                        <Icon src={icon} />
                    ) : (
                        <IconBoardingPass
                            fill="#19C0FF"
                            style={{ marginRight: 5 }}
                        />
                    )
                ) : icon ? (
                    <Icon src={icon} />
                ) : (
                    <IconBoardingPass
                        fill="#65686F"
                        style={{ marginRight: 5, width: 20, height: 15 }}
                    />
                )}
                <Text style={{ marginLeft: 5 }} textStyle="dateTimeBold">
                    {label}
                </Text>
            </TabBarContainer>
        );
    };

    return (
        <TabContainer>
            <Tabs
                defaultActiveKey={activeKey}
                activeKey={activeKey}
                onChange={(activeKey) => {
                    if (onChange && typeof onChange === 'function') {
                        onChange(activeKey);
                    }

                    setActiveKey(activeKey);
                }}
            >
                {Array.isArray(tabRenderer) &&
                    tabRenderer.length > 0 &&
                    tabRenderer.map((tab, index) => {
                        return (
                            <TabPane
                                tab={renderTabBar(
                                    tab.label || index,
                                    tab.key,
                                    tab.icon
                                )}
                                key={tab.key || index}
                            >
                                {tab.render}
                            </TabPane>
                        );
                    })}
            </Tabs>
        </TabContainer>
    );
};

export default TabsComponent;

const TabContainer = styled.div`
    .ant-tabs-tab {
        padding-bottom: 8px;
    }
    .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 0;
    }

    .ant-tabs-top > .ant-tabs-nav::before {
        border-color: rgba(56, 56, 65, 0.05);
        border-width: 2px;
    }
`;

const TabBarContainer = styled.div`
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const TagText = styled.span`
    color: #35383f;
    font-family: Lato;
    font-size: 14px;
`;

const Icon = styled.img`
    margin: 5px;
    width: 24px;
    height: 16px;
`;
