import React, { useState } from 'react';
import styled from 'styled-components';
import background from 'assets/images/default_background.png';
import 'antd/dist/antd.css';
import { Layout, Tabs, Col, Row, Upload, Image } from 'antd';
import { useTranslation } from 'react-i18next';
import {
    BackgroundProfile,
    ProfileInfo,
    TabBarNavigator,
    TabText,
    Title,
    TitleDescription,
    Name,
    Comapy,
    AvatarWrapper
} from './styles';
import { renderAddress, formatHttpStr } from 'utils';
import {
    aboutIcon,
    timelineIcon,
    aircraftListingIcon,
    flightPostIcon,
    fleetsIcon,
    imageIcon,
    followwingIcon
} from 'assets/images/icons';
import { EmptyComponent, Popup } from 'components';
import { CompanyFleet } from 'views/CompanyFleet/index';
import { WarningModal } from 'components/Modals';
import { AboutComponent } from './components';

const warningInitState = {
    show: false,
    image: {}
};

const TabItems = ({ title, icon }) => {
    return (
        <span style={{ paddingLeft: 25, paddingRight: 25 }}>
            <Icon src={icon} />
            <TabText>{title}</TabText>
        </span>
    );
};

const { TabPane } = Tabs;

const template = (props) => {
    const {
        navigate,
        user = {},
        profileDetail = {},
        employees = [],
        onSubmitEdit,
        edit,
        setEdit,
        loading,
        updateCoverImage,
        updateProfileImage,
        addPhotoImages,
        removePhotoImage,
        setDescription,
        description = '',
        visibleModal = false
    } = props;

    const {
        name = '',
        role = '',
        image = '',
        backgroundImage = '',
        images = [],
        location = {},
        airport = {},
        company = {},
        contact = {},
        id,
        products = [],
        services = [],
        show,
        type = '',
        settings = {},
        style = {}
    } = profileDetail;
    const { t, i18n } = useTranslation();

    const [showWarning, setShowWarning] = useState(warningInitState);

    const onRemoveImage = (image = {}) => {
        setShowWarning({
            show: true,
            image: image
        });
    };

    const onRemoveImageCompany = async () => {
        await removePhotoImage(showWarning.image);
        setShowWarning(warningInitState);
    };

    const onCancelWarning = () => {
        setShowWarning(warningInitState);
    };

    const _renderProfile = () => {
        return (
            <ProfileInfo>
                <Row>
                    <Col
                        xl={5}
                        lg={10}
                        md={10}
                        sm={10}
                        xs={20}
                        style={{ marginRight: '20px', paddingTop: '20px' }}
                    >
                        <Title style={{ marginBottom: 10 }}>
                            {i18n.t('ADDRESS')}
                        </Title>
                        <TitleDescription>
                            {renderAddress(location).toUpperCase()}
                        </TitleDescription>
                    </Col>
                    <Col
                        xl={5}
                        lg={10}
                        md={10}
                        sm={10}
                        xs={20}
                        style={{ marginRight: '20px', paddingTop: '20px' }}
                    >
                        <Title style={{ marginBottom: 10 }}>
                            {i18n.t('PHONE_NUMBER')}
                        </Title>
                        <TitleDescription>
                            {contact && contact.phone
                                ? contact.phone.toUpperCase()
                                : '_'}
                        </TitleDescription>
                    </Col>
                    <Col
                        xl={5}
                        lg={10}
                        md={10}
                        sm={10}
                        xs={20}
                        style={{ marginRight: '20px', paddingTop: '20px' }}
                    >
                        <Title style={{ marginBottom: 10 }}>
                            {i18n.t('EMAIL_ADDRESS')}
                        </Title>
                        <TitleDescription>
                            {contact && contact.email
                                ? contact.email.toUpperCase()
                                : '_'}
                        </TitleDescription>
                    </Col>
                    <Col
                        xl={5}
                        lg={10}
                        md={10}
                        sm={10}
                        xs={20}
                        style={{ marginRight: '20px', paddingTop: '20px' }}
                    >
                        <Title style={{ marginBottom: 10 }}>
                            {i18n.t('COMPANY_WEBSITE')}
                        </Title>
                        <TabLink
                            rel="noopener noreferrer"
                            href={contact.website}
                            target="_blank"
                        >
                            <TitleDescription color="#19C0FF">
                                {contact &&
                                    contact.website &&
                                    formatHttpStr(
                                        contact.website
                                    ).toUpperCase()}
                            </TitleDescription>
                        </TabLink>
                    </Col>
                </Row>
            </ProfileInfo>
        );
    };

    const _renderInformation = () => {
        return (
            <>
                <BackgroundProfile
                    edit={edit}
                    background={backgroundImage || background}
                >
                    <div className="overlay-background">
                        <AvatarWrapper>
                            <Row>
                                <Col>
                                    <Upload
                                        listType="picture-card"
                                        showUploadList={false}
                                        beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                                        onChange={updateProfileImage}
                                        multiple={false}
                                        disabled={!edit}
                                    >
                                        {image ? (
                                            <BackgroundAvatar
                                                edit={edit}
                                                className="ant-avatar-custom avatar-item"
                                                url={image}
                                                size={152}
                                            >
                                                <div className="overlay-avatar" />
                                            </BackgroundAvatar>
                                        ) : (
                                            <BackgroundAvatar
                                                edit={edit}
                                                className="ant-avatar-custom avatar-item"
                                                size={152}
                                                style={{
                                                    backgroundColor: '#F0F3F9'
                                                }}
                                            >
                                                <div className="overlay-avatar">
                                                    <Image
                                                        src={imageIcon}
                                                        style={{
                                                            width: 32,
                                                            height: 28
                                                        }}
                                                    />
                                                </div>
                                            </BackgroundAvatar>
                                        )}
                                        {edit && (
                                            <UploadAvatar>
                                                {t(
                                                    'CHANGE_PROFILE_IMAGE'
                                                ).toUpperCase()}
                                            </UploadAvatar>
                                        )}
                                    </Upload>
                                </Col>
                                <Col className="fbo-display-name">
                                    <Wrapper>
                                        <Name color="#FFFFFF">{`${name.toUpperCase()}`}</Name>
                                        <FollowingWrapper
                                            style={{ marginLeft: 25 }}
                                        >
                                            <Icon
                                                style={{
                                                    width: 32,
                                                    height: 18
                                                }}
                                                src={followwingIcon}
                                            />
                                            <FollowText>
                                                {t('FOLLOWING').toUpperCase()}
                                            </FollowText>
                                        </FollowingWrapper>
                                    </Wrapper>
                                    <div>
                                        <Comapy>
                                            {type.toUpperCase() || '_'}
                                        </Comapy>
                                    </div>
                                </Col>
                            </Row>
                        </AvatarWrapper>
                    </div>

                    <ButtonWrapper>
                        {role === 'ADMIN' && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                {edit && (
                                    <Upload
                                        showUploadList={false}
                                        beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                                        onChange={updateCoverImage}
                                        multiple={false}
                                        disabled={!edit}
                                    >
                                        <ChangeCoverWrapprer>
                                            <Icon
                                                src={imageIcon}
                                                style={{
                                                    width: 16,
                                                    height: 14,
                                                    alignSelf: 'center'
                                                }}
                                            />
                                            <EditText style={{ marginTop: 2 }}>
                                                {t(
                                                    'CHANGE_COVER_IMAGE'
                                                ).toUpperCase()}
                                            </EditText>
                                        </ChangeCoverWrapprer>
                                    </Upload>
                                )}

                                <EditButton
                                    background={!edit ? 'transparent' : '#fff'}
                                    onClick={() => setEdit(!edit)}
                                >
                                    <EditText
                                        color={!edit ? '#fff' : '#19C0FF'}
                                    >
                                        {!edit
                                            ? t(
                                                  'EDIT_COMPANY_PROFILE'
                                              ).toUpperCase()
                                            : t('CANCEL_EDIT').toUpperCase()}
                                    </EditText>
                                </EditButton>
                            </div>
                        )}
                    </ButtonWrapper>
                </BackgroundProfile>
                {_renderProfile()}
            </>
        );
    };

    const _renderTabs = () => {
        return (
            <TabBarNavigator>
                <Tabs
                    defaultActiveKey="ABOUT"
                    className="company-profile-tabs"
                    style={{ width: '100%' }}
                >
                    <TabPane
                        key="ABOUT"
                        tab={
                            <TabItems
                                icon={aboutIcon}
                                title={i18n.t('ABOUT').toUpperCase()}
                            />
                        }
                    >
                        <AboutComponent
                            navigate={navigate}
                            setDescription={setDescription}
                            description={description}
                            employees={employees}
                            edit={edit}
                            images={images}
                            addPhotoImages={addPhotoImages}
                            onSubmitEdit={onSubmitEdit}
                            onRemoveImage={onRemoveImage}
                        />
                    </TabPane>
                    <TabPane
                        key="TIMELINE"
                        tab={
                            <TabItems
                                icon={timelineIcon}
                                title={i18n.t('TIMELINE').toUpperCase()}
                            />
                        }
                    >
                        <EmptyComponent description={t('NO_DATA')} />
                    </TabPane>
                    <TabPane
                        key="FLIGHT_POSTED"
                        tab={
                            <TabItems
                                icon={flightPostIcon}
                                title={i18n.t('FLIGHT_POSTED').toUpperCase()}
                            />
                        }
                    >
                        <EmptyComponent description={t('NO_DATA')} />
                    </TabPane>
                    <TabPane
                        key="AIRCRAFT_LISTING"
                        tab={
                            <TabItems
                                icon={aircraftListingIcon}
                                title={i18n.t('AIRCRAFT_LISTING').toUpperCase()}
                            />
                        }
                    >
                        <EmptyComponent description={t('NO_DATA')} />
                    </TabPane>
                    {type === 'OPERATOR' && (
                        <TabPane
                            key="FLEET"
                            tab={
                                <TabItems
                                    icon={fleetsIcon}
                                    title={i18n.t('FLEET').toUpperCase()}
                                />
                            }
                        >
                            <CompanyFleet isView={true} />
                        </TabPane>
                    )}
                </Tabs>
            </TabBarNavigator>
        );
    };

    return (
        <Layout style={{ backgroundColor: 'transperent' }}>
            {_renderInformation()}
            {_renderTabs()}

            {/* modal */}
            <Popup
                cancelModal={() => {
                    props.closeModal();
                }}
                onOk={() => {
                    props.closeModal();
                }}
                visibleModal={visibleModal}
                successText={t('COMPANY_PROFILE_SUCCESSFULLY_UPDATED')}
                buttonTitle={t('CLOSE').toUpperCase()}
            />
            <WarningModal
                show={showWarning.show}
                title={t('CONFIRM_REMOVE_ITEM').toUpperCase()}
                okText={t('YES_REMOVE_IT').toUpperCase()}
                onOk={onRemoveImageCompany}
                cancelText={t('NO_DISREGARD').toUpperCase()}
                onCancel={onCancelWarning}
            />
        </Layout>
    );
};

export { template };

const TabLink = styled.a``;

const Icon = styled.img`
    margin: 5px;
`;

const ButtonWrapper = styled.div`
    min-width: 180px;
    min-height: 32px;
    margin-bottom: 25px;
    position: absolute;
    right: 25px;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FollowingWrapper = styled.div`
    display: flex;
    background-color: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 2px;
    width: 116px;
    height: 32px;
    justify-content: center;
    align-items: center;
`;

const FollowText = styled.span`
    color: #19c0ff;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: center;
    margin-right: 10px;
`;

const EditButton = styled.button`
    background-color: ${(props) => props.background || 'transparent'};
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 2px;
    width: ${(props) => [props.width ? props.width : '180px']};
    height: 32px;
    cursor: pointer;

    &:hover {
        background-color: #12afeb;
    }
`;

const EditText = styled.div`
    color: ${(props) => props.color || '#ffffff'};
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: center;
`;

const ChangeCoverWrapprer = styled.button`
    background-color: transparent;
    border: none;
    width: 180px;
    height: 32px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
`;

const UploadAvatar = styled.span`
    color: #ffffff;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: right;
    position: absolute;
    align-self: center;
`;

const BackgroundAvatar = styled.div`
    position: relative;
    background-image: url(${(props) => props.url});
    height: 152px;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-wrap: wrap;

    .overlay-avatar {
        position: absolute;
        height: 152px;
        width: 152px;
        border-radius: 100%;
        top: 0;
        left: 0;
        background: ${(props) =>
            !props.edit ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, 0.35)'};
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
`;
