import {
    CLEAR_DATA_APP,
    INDENTIFY_TOKEN,
    SIGN_IN,
    USER_INFO,
    SHOW_LOADING,
    HIDE_LOADING
} from '../Constants';

const INITIAL_STATE = {
    isLoading: false,
    userToken: null,
    user_id: null
};

export default function appReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case INDENTIFY_TOKEN:
            return {
                ...state,
                userToken: action.token, // JWT_TOKEN
                user_id: action.user_id || null,
                isLoading: false
            };
        case SIGN_IN:
            return {
                ...state,
                userToken: action.token, // JWT_TOKEN
                user_id: action.user_id,
                isLoading: false
            };
        case CLEAR_DATA_APP:
            return {
                ...state,
                INITIAL_STATE
            };
        case USER_INFO:
            return {
                ...state
            };
        case SHOW_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case HIDE_LOADING:
            return {
                ...state,
                isLoading: false
            };
        default:
            return { ...state };
    }
}
