import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { Header, Tabs } from 'components';
import { CustomerContainer } from '../styles';
import _ from 'lodash';
import PartnerInvoices from '../Partners/list';
import CustomerInvoices from '../Customers/list';
import { aboutIcon, flightPostIcon } from 'assets/images/icons';

const template = (props) => {
    const { navigate } = props;
    const { t, i18n } = useTranslation();

    const _renderInvoiceWithType = () => {
        const tabRenderer = [
            {
                key: 'MY_INVOICES',
                label: t('MY_INVOICES').toUpperCase(),
                render: <CustomerInvoices />,
                icon: aboutIcon
            },
            {
                key: 'PARTNER_INVOICES',
                label: t('PARTNER_INVOICES').toUpperCase(),
                render: <PartnerInvoices />,
                icon: flightPostIcon
            }
        ];
        return <Tabs tabRenderer={tabRenderer} />;
    }

    return (
        <CustomerContainer>
            <Header
                title={t('INVOICES')}
                description={t('LIST_ALL_INVOICE')}
            />
            <div style={{ marginTop: '20px', minHeight: '100vh' }}>
                { _renderInvoiceWithType() }
            </div>
        </CustomerContainer>
    );
};

export { template };
