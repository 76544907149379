import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Collapse } from 'antd';
import { Header, Popup, ButtonCustom } from 'components';
import { EditOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { GLOBAL_STYLE_CONSTANTS } from 'config/Constants';
import {
    iconArrowUp,
    iconArrowDown,
    infoIcon,
    iconAppearance,
    iconGlobe
} from 'assets/images/icons';
import {
    GeneralInfo,
    Appearance,
    Domain,
    UploadDocument,
    HeroImage,
    Advertisement,
    AppIntro,
    Footer,
    AboutUs,
    ContactUs
} from './components';
import CustomModal from './components/CustomModal';
const { Panel } = Collapse;

const template = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const {
        whitelabelSetting = {},
        updateWhitelabelSetting,
        updatePreview,
        visibleModal = false,
        setVisibleModal
    } = props;

    const [whitelabelName, setWhiteLabelName] = useState('');
    const [description, setDescription] = useState('');
    const [redirectToCompanySite, setRedirectToCompanySite] = useState(false);
    const [requireMembership, setRequireMembership] = useState(false);
    const [hideTailnumber, setHideTailnumber] = useState(false);
    const [showScheduledFlights, setShowScheduledFlights] = useState(false);
    const [showAvailableAircrafts, setShowAvailableAircrafts] = useState(false);
    const [showRoutes, setShowRoutes] = useState(false);
    const [defaultUrl, setDefaultUrl] = useState('');
    const [customUrl, setCustomUrl] = useState('');

    // set logo and colors
    const [logo, setLogo] = useState('');
    const [app_logo, set_app_logo] = useState('');
    const [logoNewUrl, setLogoNewUrl] = useState('');
    const [app_logo_new_url, set_app_logo_new_url] = useState('');
    const [backgroundColor, setBackgroundColor] = useState('#000000');
    const [textColor, setTextColor] = useState('#000000');
    const [buttonBackgroundColor, setButtonBackgroundColor] =
        useState('#000000');
    const [buttonTextColor, setButtonTextColor] = useState('#000000');
    const [highlightColor, setHighlightColor] = useState('#000000');
    const [lightBackgroundColor, setLightBackgroundColor] = useState('#000000');
    const [primaryColor, setPrimaryColor] = useState('#000000');
    const [secondaryColor, setSecondaryColor] = useState('#000000');
    const [secondaryLightBackgroundColor, setSecondaryLightBackgroundColor] =
        useState('#000000');
    const [header_background_color, set_header_background_color] =
        useState('#F7F9FD');
    const [footer_background_color, set_footer_background_color] =
        useState('#F7F9FD');

    // fonts
    const [primaryFont, setPrimaryFont] = useState({});
    const [secondaryFont, setSecondaryFont] = useState({});

    const [showBackgoundImage, setShowBackgoundImage] = useState(false);

    // social
    const [facebook, setFacebook] = useState('');
    const [twitter, setTwitter] = useState('');
    const [instagram, setInstagram] = useState('');
    const [linkedin, setLinkedin] = useState('');
    const [copyrightText, setCopyrightText] = useState('');

    //header
    const [backgroundImage, setBackgroundImage] = useState('');
    const [newBackgroundImage, setNewBackgroundImage] = useState('');
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');

    // Advertisement
    const [activeAdvertisement, setActiveAdvertisement] = useState(false);
    const [advertisementBackground, setAdvertisementBackground] = useState('');
    const [newAdvertisementBackground, setNewAdvertisementBackground] =
        useState('');
    const [advertisementDescription, setAdvertisementDescription] =
        useState('');
    const [advertisementTitle, setAdvertisementTitle] = useState('');
    const [advertisementImage, setAdvertisementImage] = useState('');

    // App intro
    const [activeAppIntro, setActiveAppIntro] = useState(false);
    const [appIntro_android_app_link, set_appIntro_android_app_link] =
        useState('');
    const [appIntro_background_image, set_appIntro_background_image] =
        useState('');
    const [new_appIntro_background_image, set_new_appIntro_background_image] =
        useState('');
    const [appIntro_description, set_appIntro_description] = useState('');
    const [appIntro_ios_app_link, set_appIntro_ios_app_link] = useState('');
    const [appIntro_title, set_appIntro_title] = useState('');
    const [appIntro_image, set_appIntro_image] = useState('');
    const [new_appIntro_image, set_new_appIntro_image] = useState('');

    // About Us
    const [activeAboutUs, setActiveAboutUs] = useState(false);
    const [aboutUsDescription, setAboutUsDescription] = useState('');

    // Contact Us
    const [activeContactUs, setActiveContactUs] = useState(false);
    const [contactUsDescription, setContactUsDescription] = useState('');
    const [companyEmail, setCompanyEmail] = useState('');
    const [companyPhone, setCompanyPhone] = useState('');

    // upload doc legal
    const [legalLink, setLegalLink] = useState('');
    const [documentName, setDocumentName] = useState('');
    const [termsAndConditionsLink, setTermsAndConditionsLink] = useState('');
    const [termsAndConditionsName, setTermsAndConditionsName] = useState('');
    const [charterAgreementsLink, setCharterAgreementsLink] = useState('');
    const [charterAgreementsName, setCharterAgreementsName] = useState('');

    const [isValidColors, setIsvalidColors] = useState(true);
    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        const {
            active = false,
            domain = '',
            image = '',
            manifest = {},
            name = '',
            custom_domain = '',
            introduction_text = '',
            preferences = {},
            settings = {}
        } = whitelabelSetting;
        const {
            features = {},
            socials = {},
            colors = {},
            header = {},
            logo = '',
            app_logo = '',
            fonts = {},
            footer = {},
            home_body = {},
            footer_session = {},
            documents = {}
        } = manifest;

        const {
            hide_tail_number = false,
            redirect_to_url = false,
            require_membership = false,
            show_all_results = false,
            show_routes = false,
            show_scheduled_flights = false
        } = preferences;
        // const { fleet = false } = features;
        //general info
        setRedirectToCompanySite(redirect_to_url);
        setRequireMembership(require_membership);
        setHideTailnumber(hide_tail_number);
        setWhiteLabelName(name);
        setDescription(introduction_text);
        // setShowAvailableAircrafts(fleet);
        setDefaultUrl(domain);
        setCustomUrl(custom_domain);

        const {
            facebook = '',
            twitter = '',
            instagram = '',
            linkedin = ''
        } = socials;
        // set socials
        setFacebook(facebook);
        setTwitter(twitter);
        setLinkedin(linkedin);
        setInstagram(instagram);
        setCopyrightText(footer.copyright_text || '');

        const {
            button_background_color = '',
            button_text_color = '',
            component_background_color = '',
            component_text_color = '',
            highlight_color = '',
            light_background = '',
            primary = '',
            secondary = '',
            secondary_light_background = ''
        } = colors;

        // set colors
        setBackgroundColor(component_background_color);
        setTextColor(component_text_color);
        setButtonBackgroundColor(button_background_color);
        setButtonTextColor(button_text_color);
        setHighlightColor(highlight_color);
        setLightBackgroundColor(light_background);
        setPrimaryColor(primary);
        setSecondaryColor(secondary);
        setSecondaryLightBackgroundColor(secondary_light_background);
        set_header_background_color(header?.background_color || '#F7F9FD');
        set_footer_background_color(footer?.background_color || '#F7F9FD');

        // logo
        setLogo(logo);
        set_app_logo(app_logo);

        //header
        const { background_image = '', title = '', sub_title = '' } = header;
        setBackgroundImage(background_image);
        setTitle(title);
        setSubTitle(sub_title);

        //fonts
        setPrimaryFont(fonts.primary);
        setSecondaryFont(fonts.secondary);
        // setPrimaryColor({
        //     text: fonts.primary?.family || '',
        //     value: fonts.primary || {}
        // })
        // setSecondaryFont({
        //     text: fonts.secondary?.family || '',
        //     value: fonts.secondary || {}
        // })

        // Adverstisement
        const {
            show_home_body = false,
            show_footer_session = false,
            fleet = false,
            route = true,
            schedule = true
        } = settings;
        setShowScheduledFlights(schedule);
        setShowAvailableAircrafts(fleet);
        setShowRoutes(route);
        setActiveAdvertisement(show_home_body);
        setAdvertisementBackground(home_body.background_image || '');
        setAdvertisementDescription(home_body.description || '');
        setAdvertisementTitle(home_body.title || '');
        setAdvertisementImage(home_body.background_image || '');

        // App intro
        setActiveAppIntro(show_footer_session);
        set_appIntro_android_app_link(footer_session.android_app_link || '');
        set_appIntro_background_image(footer_session.background_image || '');
        set_appIntro_description(footer_session.description || '');
        set_appIntro_ios_app_link(footer_session.ios_app_link || '');
        set_appIntro_title(footer_session.title || '');
        set_appIntro_image(footer_session.image || '');

        //About us
        setActiveAboutUs(footer.about_us?.show || false);
        setAboutUsDescription(footer.about_us?.text || '');

        // Contact us
        setActiveContactUs(footer.contact_us?.show || false);
        setContactUsDescription(footer.contact_us?.text || '');
        setCompanyEmail(footer.contact_us?.email || '');
        setCompanyPhone(footer.contact_us?.phone || '');

        // Legal link
        const { url = '', file_name = '' } = footer.legal || {};
        const { charter_agreements = {}, terms_and_conditions = {}} = documents;
        setDocumentName(file_name);
        setLegalLink(url);
        setTermsAndConditionsLink(terms_and_conditions.url || '');
        setTermsAndConditionsName(terms_and_conditions.file_name || '')
        setCharterAgreementsLink(charter_agreements.url || '');
        setCharterAgreementsName(charter_agreements.file_name || '');
    }, [whitelabelSetting]);

    const onPressSaveChanges = () => {
        if (!isValidColors) {
            setErrorMsg('Please update proper colors at Appearance section.');
            setShowError(true);
            return;
        }
        if (!termsAndConditionsLink) {
            setErrorMsg('Please upload terms and conditions pdf file.');
            setShowError(true);
            return;
        }
        if (!charterAgreementsLink) {
            setErrorMsg('Please upload charter agreements pdf file.');
            setShowError(true);
            return;
        }
        const newPreferences = {
            // ...whitelabelSetting.preferences,
            hide_tail_number: hideTailnumber,
            redirect_to_url: redirectToCompanySite,
            require_membership: requireMembership
            // show_all_results: showAvailableAircrafts,
            // show_routes: showRoutes,
            // show_scheduled_flights: showScheduledFlights
        };

        const newSettings = {
            // ...whitelabelSetting.settings,
            fleet: showAvailableAircrafts,
            route: showRoutes,
            schedule: showScheduledFlights,
            show_home_body: activeAdvertisement,
            show_footer_session: activeAppIntro
        };

        const newColors = {
            // ...whitelabelSetting.manifest?.colors,
            // light_background: lightBackgroundColor,
            component_background_color: backgroundColor,
            component_text_color: textColor,
            primary: primaryColor,
            secondary: secondaryColor
        };

        const newHeader = {
            // ...whitelabelSetting.manifest?.header,
            background_image: newBackgroundImage
                ? newBackgroundImage
                : backgroundImage,
            sub_title: subTitle,
            title: title,
            background_color: header_background_color
        };

        const newHomeBody = {
            // ...whitelabelSetting.manifest?.home_body,
            background_image: newAdvertisementBackground
                ? newAdvertisementBackground
                : advertisementBackground,
            description: advertisementDescription,
            title: advertisementTitle
        };

        const newFooterSession = {
            // ...whitelabelSetting.manifest?.footer_session,
            android_app_link: appIntro_android_app_link,
            ios_app_link: appIntro_ios_app_link,
            background_image: new_appIntro_background_image
                ? new_appIntro_background_image
                : appIntro_background_image,
            image: new_appIntro_image ? new_appIntro_image : appIntro_image,
            description: appIntro_description,
            title: appIntro_title
        };

        const newSocials = {
            facebook: facebook,
            twitter: twitter,
            linkedin: linkedin,
            instagram: instagram
        };

        const newAboutUs = {
            // ...whitelabelSetting.manifest?.footer?.about_us,
            show: activeAboutUs,
            text: aboutUsDescription
        };

        const newContactUs = {
            // ...whitelabelSetting.manifest?.footer?.contact_us,
            show: activeContactUs,
            text: contactUsDescription,
            email: companyEmail,
            phone: companyPhone
        };

        const newFooter = {
            about_us: newAboutUs,
            contact_us: newContactUs,
            legal: {
                file_name: documentName,
                url: legalLink
            },
            background_color: footer_background_color,
            copyright_text: copyrightText
        };

        const newDocuments = {
            charter_agreements: {
                file_name: charterAgreementsName,
                url: charterAgreementsLink
            },
            terms_and_conditions: {
                file_name: termsAndConditionsName,
                url: termsAndConditionsLink
            }
        }

        const newFonts = {
            // ...whitelabelSetting.manifest?.fonts,
            primary: primaryFont,
            secondary: secondaryFont
        };

        const newManifest = {
            // ...whitelabelSetting.manifest,
            logo: logoNewUrl ? logoNewUrl : logo,
            app_logo: app_logo_new_url ? app_logo_new_url : app_logo,
            colors: newColors,
            header: newHeader,
            home_body: newHomeBody,
            footer_session: newFooterSession,
            socials: newSocials,
            footer: newFooter,
            fonts: newFonts,
            documents: newDocuments
        };

        const newWhitelabelSetting = {
            // ...whitelabelSetting,
            name: whitelabelName,
            introduction_text: description,
            preferences: newPreferences,
            domain: defaultUrl,
            custom_domain: customUrl,
            settings: newSettings,
            manifest: newManifest
        };

        updateWhitelabelSetting(newWhitelabelSetting);
    };

    const onPressPreviewSetting = () => {
        if (!isValidColors) {
            setErrorMsg('Please update proper colors at Appearance section.');
            setShowError(true);
            return;
        }
        if (!termsAndConditionsLink) {
            setErrorMsg('Please upload terms and conditions pdf file.');
            setShowError(true);
            return;
        }
        if (!charterAgreementsLink) {
            setErrorMsg('Please upload charter agreements pdf file.');
            setShowError(true);
            return;
        }
        const newPreferences = {
            // ...whitelabelSetting.preferences,
            hide_tail_number: hideTailnumber,
            redirect_to_url: redirectToCompanySite,
            require_membership: requireMembership
            // show_all_results: showAvailableAircrafts,
            // show_routes: showRoutes,
            // show_scheduled_flights: showScheduledFlights
        };

        const newSettings = {
            // ...whitelabelSetting.settings,
            fleet: showAvailableAircrafts,
            route: showRoutes,
            schedule: showScheduledFlights,
            show_home_body: activeAdvertisement,
            show_footer_session: activeAppIntro
        };

        const newColors = {
            // ...whitelabelSetting.manifest?.colors,
            // light_background: lightBackgroundColor,
            component_background_color: backgroundColor,
            component_text_color: textColor,
            primary: primaryColor,
            secondary: secondaryColor
        };

        const newHeader = {
            // ...whitelabelSetting.manifest?.header,
            background_image: newBackgroundImage
                ? newBackgroundImage
                : backgroundImage,
            sub_title: subTitle,
            title: title,
            background_color: header_background_color
        };

        const newHomeBody = {
            // ...whitelabelSetting.manifest?.home_body,
            background_image: newAdvertisementBackground
                ? newAdvertisementBackground
                : advertisementBackground,
            description: advertisementDescription,
            title: advertisementTitle
        };

        const newFooterSession = {
            // ...whitelabelSetting.manifest?.footer_session,
            android_app_link: appIntro_android_app_link,
            ios_app_link: appIntro_ios_app_link,
            background_image: new_appIntro_background_image
                ? new_appIntro_background_image
                : appIntro_background_image,
            image: new_appIntro_image ? new_appIntro_image : appIntro_image,
            description: appIntro_description,
            title: appIntro_title
        };

        const newSocials = {
            facebook: facebook,
            twitter: twitter,
            linkedin: linkedin,
            instagram: instagram
        };

        const newAboutUs = {
            // ...whitelabelSetting.manifest?.footer?.about_us,
            show: activeAboutUs,
            text: aboutUsDescription
        };

        const newContactUs = {
            // ...whitelabelSetting.manifest?.footer?.contact_us,
            show: activeContactUs,
            text: contactUsDescription,
            email: companyEmail,
            phone: companyPhone
        };

        const newFooter = {
            about_us: newAboutUs,
            contact_us: newContactUs,
            legal: {
                file_name: documentName,
                url: legalLink
            },
            background_color: footer_background_color,
            copyright_text: copyrightText
        };
        const newFonts = {
            ...whitelabelSetting.manifest?.fonts,
            primary: primaryFont,
            secondary: secondaryFont
        };

        const newDocuments = {
            charter_agreements: {
                file_name: charterAgreementsName,
                url: charterAgreementsLink
            },
            terms_and_conditions: {
                file_name: termsAndConditionsName,
                url: termsAndConditionsLink
            }
        }

        const preview_manifest = {
            // ...whitelabelSetting.manifest,
            logo: logoNewUrl ? logoNewUrl : logo,
            app_logo: app_logo_new_url ? app_logo_new_url : app_logo,
            colors: newColors,
            header: newHeader,
            home_body: newHomeBody,
            footer_session: newFooterSession,
            socials: newSocials,
            footer: newFooter,
            fonts: newFonts,
            documents: newDocuments
        };
        const previewSetting = {
            // ...whitelabelSetting,
            preview_manifest: preview_manifest,
            preview_settings: newSettings
        };
        updatePreview(previewSetting);
    };

    return (
        <DashboardContainer>
            <div style={{ marginBottom: 15 }}>
                <Header
                    title={t('SETTINGS')}
                    //TODO: bring this quote to en.js and ask for AJ operator from backend side
                    description={t('WHITELABEL')}
                />
            </div>
            <Collapse
                defaultActiveKey={['0']}
                expandIconPosition="right"
                expandIcon={({ isActive }) =>
                    isActive ? (
                        <Icon src={iconArrowUp} />
                    ) : (
                        <Icon src={iconArrowDown} />
                    )
                }
            >
                <Panel
                    style={{ marginBottom: 40 }}
                    header={
                        <HeaderInfo title={t('GENERAL_INFO').toUpperCase()} />
                    }
                >
                    <GeneralInfo
                        whitelabelName={whitelabelName}
                        setWhiteLabelName={setWhiteLabelName}
                        setDefaultUrl={setDefaultUrl}
                        description={description}
                        setDescription={setDescription}
                        redirectToCompanySite={redirectToCompanySite}
                        setRedirectToCompanySite={setRedirectToCompanySite}
                        requireMembership={requireMembership}
                        setRequireMembership={setRequireMembership}
                        hideTailnumber={hideTailnumber}
                        setHideTailnumber={setHideTailnumber}
                        showScheduledFlights={showScheduledFlights}
                        setShowScheduledFlights={setShowScheduledFlights}
                        showAvailableAircrafts={showAvailableAircrafts}
                        setShowAvailableAircrafts={setShowAvailableAircrafts}
                        showRoutes={showRoutes}
                        setShowRoutes={setShowRoutes}
                    />
                </Panel>
                <Panel
                    style={{ marginBottom: 40 }}
                    header={
                        <HeaderInfo
                            title={t('DOMAIN').toUpperCase()}
                            icon={<Icon src={iconGlobe} />}
                        />
                    }
                >
                    <Domain
                        defaultUrl={defaultUrl}
                        setDefaultUrl={setDefaultUrl}
                        customUrl={customUrl}
                        setCustomUrl={setCustomUrl}
                    />
                </Panel>
                <Panel
                    style={{ marginBottom: 40 }}
                    header={
                        <HeaderInfo
                            title={t('APPEARANCE').toUpperCase()}
                            icon={<Icon src={iconAppearance} />}
                        />
                    }
                >
                    <Appearance
                        whitelabelId={whitelabelSetting.id || ''}
                        logo={logo}
                        setLogo={setLogo}
                        setLogoNewUrl={setLogoNewUrl}
                        app_logo={app_logo}
                        set_app_logo={set_app_logo}
                        set_app_logo_new_url={set_app_logo_new_url}
                        backgroundColor={backgroundColor}
                        setBackgroundColor={setBackgroundColor}
                        textColor={textColor}
                        setTextColor={setTextColor}
                        buttonBackgroundColor={buttonBackgroundColor}
                        setButtonBackgroundColor={setButtonBackgroundColor}
                        buttonTextColor={buttonTextColor}
                        setButtonTextColor={setButtonTextColor}
                        highlightColor={highlightColor}
                        setHighlightColor={setHighlightColor}
                        showBackgoundImage={showBackgoundImage}
                        setShowBackgoundImage={setShowBackgoundImage}
                        primaryColor={primaryColor}
                        setPrimaryColor={setPrimaryColor}
                        secondaryColor={secondaryColor}
                        setSecondaryColor={setSecondaryColor}
                        lightBackgroundColor={lightBackgroundColor}
                        setLightBackgroundColor={setLightBackgroundColor}
                        secondaryLightBackgroundColor={
                            secondaryLightBackgroundColor
                        }
                        setSecondaryLightBackgroundColor={
                            setSecondaryLightBackgroundColor
                        }
                        primaryFont={primaryFont}
                        setPrimaryFont={setPrimaryFont}
                        secondaryFont={secondaryFont}
                        setSecondaryFont={setSecondaryFont}
                        header_background_color={header_background_color}
                        set_header_background_color={
                            set_header_background_color
                        }
                        footer_background_color={footer_background_color}
                        set_footer_background_color={
                            set_footer_background_color
                        }
                        isValidColors={isValidColors}
                        setIsvalidColors={setIsvalidColors}
                    />
                </Panel>
                <Panel
                    style={{ marginBottom: 40 }}
                    header={
                        <HeaderInfo
                            title={t('COMPONENT_HERO_IMAGE').toUpperCase()}
                            icon={<Icon src={iconAppearance} />}
                        />
                    }
                >
                    <HeroImage
                        whitelabelId={whitelabelSetting.id || ''}
                        backgroundImage={backgroundImage}
                        setBackgroundImage={setBackgroundImage}
                        setNewBackgroundImage={setNewBackgroundImage}
                        title={title}
                        setTitle={setTitle}
                        subTitle={subTitle}
                        setSubTitle={setSubTitle}
                    />
                </Panel>
                <Panel
                    style={{ marginBottom: 40 }}
                    header={
                        <HeaderInfo
                            title={t('COMPONENT_ADVERTISEMENT').toUpperCase()}
                            icon={<Icon src={iconAppearance} />}
                        />
                    }
                >
                    <Advertisement
                        whitelabelId={whitelabelSetting.id || ''}
                        activeAdvertisement={activeAdvertisement}
                        setActiveAdvertisement={setActiveAdvertisement}
                        advertisementBackground={advertisementBackground}
                        setAdvertisementBackground={setAdvertisementBackground}
                        setNewAdvertisementBackground={
                            setNewAdvertisementBackground
                        }
                        advertisementDescription={advertisementDescription}
                        setAdvertisementDescription={
                            setAdvertisementDescription
                        }
                        advertisementTitle={advertisementTitle}
                        setAdvertisementTitle={setAdvertisementTitle}
                        advertisementImage={advertisementImage}
                        setAdvertisementImage={setAdvertisementImage}
                    />
                </Panel>
                <Panel
                    style={{ marginBottom: 40 }}
                    header={
                        <HeaderInfo
                            title={t('COMPONENT_APP_INTRO').toUpperCase()}
                            icon={<Icon src={iconAppearance} />}
                        />
                    }
                >
                    <AppIntro
                        whitelabelId={whitelabelSetting.id || ''}
                        activeAppIntro={activeAppIntro}
                        setActiveAppIntro={setActiveAppIntro}
                        appIntro_android_app_link={appIntro_android_app_link}
                        set_appIntro_android_app_link={
                            set_appIntro_android_app_link
                        }
                        appIntro_background_image={appIntro_background_image}
                        set_appIntro_background_image={
                            set_appIntro_background_image
                        }
                        set_new_appIntro_background_image={
                            set_new_appIntro_background_image
                        }
                        appIntro_description={appIntro_description}
                        set_appIntro_description={set_appIntro_description}
                        appIntro_ios_app_link={appIntro_ios_app_link}
                        set_appIntro_ios_app_link={set_appIntro_ios_app_link}
                        appIntro_title={appIntro_title}
                        set_appIntro_title={set_appIntro_title}
                        appIntro_image={appIntro_image}
                        set_appIntro_image={set_appIntro_image}
                        new_appIntro_image={new_appIntro_image}
                        set_new_appIntro_image={set_new_appIntro_image}
                    />
                </Panel>
                <Panel
                    style={{ marginBottom: 40 }}
                    header={
                        <HeaderInfo
                            title={t('COMPONENT_FOOTER').toUpperCase()}
                            icon={<Icon src={iconAppearance} />}
                        />
                    }
                >
                    <Footer
                        facebook={facebook}
                        setFacebook={setFacebook}
                        instagram={instagram}
                        setInstagram={setInstagram}
                        linkedin={linkedin}
                        setLinkedin={setLinkedin}
                        twitter={twitter}
                        setTwitter={setTwitter}
                        copyrightText={copyrightText}
                        setCopyrightText={setCopyrightText}
                    />
                </Panel>
                <Panel
                    style={{ marginBottom: 40 }}
                    header={
                        <HeaderInfo title={t('PAGE_ABOUT_US').toUpperCase()} />
                    }
                >
                    <AboutUs
                        activeAboutUs={activeAboutUs}
                        setActiveAboutUs={setActiveAboutUs}
                        aboutUsDescription={aboutUsDescription}
                        setAboutUsDescription={setAboutUsDescription}
                    />
                </Panel>
                <Panel
                    style={{ marginBottom: 40 }}
                    header={
                        <HeaderInfo
                            title={t('PAGE_CONTACT_US').toUpperCase()}
                        />
                    }
                >
                    <ContactUs
                        activeContactUs={activeContactUs}
                        setActiveContactUs={setActiveContactUs}
                        contactUsDescription={contactUsDescription}
                        setContactUsDescription={setContactUsDescription}
                        companyEmail={companyEmail}
                        setCompanyEmail={setCompanyEmail}
                        companyPhone={companyPhone}
                        setCompanyPhone={setCompanyPhone}
                    />
                </Panel>
                <Panel
                    style={{ marginBottom: 40 }}
                    header={
                        <HeaderInfo
                            title={t('UPLOAD_DOCUMENT').toUpperCase()}
                            icon={<Icon src={iconGlobe} />}
                        />
                    }
                >
                    <UploadDocument
                        whitelabelId={whitelabelSetting.id || ''}
                        documentName={documentName}
                        setDocumentName={setDocumentName}
                        legalLink={legalLink}
                        setLegalLink={setLegalLink}
                        termsAndConditionsLink={termsAndConditionsLink}
                        setTermsAndConditionsLink={setTermsAndConditionsLink}
                        termsAndConditionsName={termsAndConditionsName}
                        setTermsAndConditionsName={setTermsAndConditionsName}
                        charterAgreementsLink={charterAgreementsLink}
                        setCharterAgreementsLink={setCharterAgreementsLink}
                        charterAgreementsName={charterAgreementsName}
                        setCharterAgreementsName={setCharterAgreementsName}
                    />
                </Panel>
            </Collapse>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ButtonCustom
                    title={t('PUBLISH').toUpperCase()}
                    btnType="primary"
                    size="large"
                    disabled={false}
                    onClick={() => onPressSaveChanges()}
                />
                <ButtonCustom
                    title={t('PREVIEW').toUpperCase()}
                    btnType="secondary"
                    size="large"
                    wrapStyle={{ marginLeft: 40, background: 'transparent' }}
                    disabled={false}
                    onClick={() => onPressPreviewSetting()}
                />
            </div>
            <Popup
                cancelModal={() => {
                    setVisibleModal(false);
                }}
                onOk={() => {
                    window.open('https://' + defaultUrl, '_blank');
                    setVisibleModal(false);
                }}
                visibleModal={visibleModal}
                successText={t('WHITELABEL_UPDATE_SUCCESSFULLY').toUpperCase()}
                buttonTitle={t('VISIT_WHITELABEL').toUpperCase()}
            />
            <CustomModal
                visibleModal={showError}
                errorMessage={
                    errorMsg
                }
                buttonTitle={t('OK').toUpperCase()}
                onOk={() => setShowError(false)}
                cancelModal={() => setShowError(false)}
            />
        </DashboardContainer>
    );
};

export { template };

const HeaderInfo = (props) => {
    const { t, i18n } = useTranslation();
    const { title = '', actionText, onClick, isUpdate, icon } = props;

    return (
        <HeaderContainer>
            <HeaderLeft>
                <TitleSection>
                    <InfoCircleBackground>
                        {icon ? (
                            icon
                        ) : (
                            <Icon
                                style={{ width: 20, height: 20 }}
                                src={infoIcon}
                            />
                        )}
                    </InfoCircleBackground>
                    <Title>{title}</Title>
                </TitleSection>
            </HeaderLeft>
            <HeaderRight>
                {actionText &&
                    (!isUpdate ? (
                        <EditButton onClick={() => onClick()}>
                            <EditText>{actionText}</EditText>
                            <EditOutlined />
                        </EditButton>
                    ) : (
                        <EditButton onClick={() => onClick()}>
                            <EditText>
                                {i18n.t('CANCEL_EDIT').toUpperCase()}
                            </EditText>
                            <CloseOutlined />
                        </EditButton>
                    ))}
            </HeaderRight>
        </HeaderContainer>
    );
};

const Icon = styled.img`
    width: 18px;
    height: 18px;
`;

const TitleSection = styled.div`
    display: flex;
    flex-direction: row;
`;

const InfoCircleBackground = styled.div`
    background-color: #f0f3f9;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const HeaderLeft = styled.div`
    margin-top: auto;
    margin-bottom: auto;
`;
const HeaderRight = styled.div`
    margin-top: auto;
    margin-bottom: auto;
`;

const Title = styled.span`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.7px;
    line-height: 16px;
    text-align: left;
    margin-left: 18px;
    margin-top: auto;
    margin-bottom: auto;
`;

const EditButton = styled.button`
    background-color: transparent;
    border: none;
`;

const EditText = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    width: 26px;
    text-align: left;
    margin-right: 8px;
`;

const DashboardContainer = styled.div`
    padding: ${GLOBAL_STYLE_CONSTANTS.padding};
    .ant-header-custom-employees {
        background-color: #f2f4f7;
    }
    .ant-collapse > .ant-collapse-item {
        border-bottom: none;
        background-color: #ffffff;
        border-radius: 4px;
    }
    .ant-collapse {
        border: none;
        background-color: transparent;
    }
    .ant-collapse-content {
        border-top: none;
    }
`;
