import React, { useEffect, useState } from 'react';
import { template as tmpl } from './EditWhiteLabel.tmpl';
import {
    getWhitelabelById,
    updateWhitelabelInfomation
} from 'redux/actions/appActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { SHOW_LOADING, HIDE_LOADING } from 'redux/Constants';
import { message } from 'antd';

const EditWhiteLabelLogic = (props) => {
    const { activeCompany, location } = props;
    const { search } = location;
    const [whitelabelSetting, setWhitelabelSetting] = useState({});
    const [visibleModal, setVisibleModal] = useState(false);

    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.appReducer || {});

    useEffect(() => {
        fetchWhitelabelSetting();
    }, []);

    const fetchWhitelabelSetting = async () => {
        let id = search;
        if (id && id[0] === '?') {
            id = id.substring(1);
        }

        const result = await dispatch(getWhitelabelById(id));
        if (result.success) {
            setWhitelabelSetting(result.data || {});
        }
    };

    const updateWhitelabelSetting = async (data) => {
        let id = search;
        if (id && id[0] === '?') {
            id = id.substring(1);
        }
        const result = await dispatch(
            updateWhitelabelInfomation(id || whitelabelSetting.id, data)
        );
        if (result.success) {
            setVisibleModal(true);
        } else {
            message.error('Update Whitelabel fail');
        }
    };

    const updatePreview = async (data) => {
        let id = search;
        if (id && id[0] === '?') {
            id = id.substring(1);
        }
        const result = await dispatch(
            updateWhitelabelInfomation(id || whitelabelSetting.id, data)
        );
        if (result) {
            const { domain = '' } = whitelabelSetting;
            window.open('http://' + domain + '/explore?mode=preview', '_blank');
        }
    };

    const navigate = (route, search, state) => {
        props.history.push({
            pathname: route,
            search: search,
            state: state ? { data: state } : null
        });
    };

    const initProps = {
        ...props,
        activeCompany: activeCompany,
        whitelabelSetting: whitelabelSetting,
        navigate: navigate,
        updateWhitelabelSetting: updateWhitelabelSetting,
        updatePreview: updatePreview,
        visibleModal: visibleModal,
        setVisibleModal: setVisibleModal
    };
    return tmpl && tmpl({ ...initProps });
};

class EditWhiteLabel extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <EditWhiteLabelLogic {...initProps} />;
    }
}

export default EditWhiteLabel;
