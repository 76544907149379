import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Header } from 'components';
import QuoteAccepted from './list/QuoteAccepted';
import AwaitingQuote from './list/AwaitingPayment';
import NewQuoteRequest from './list/NewQuoteRequest';
import QuoteDeclined from './list/QuoteDeclined';
import SearchResult from './list/SearchResult';
import { SearchBooking } from 'components/Commons';
import { QUOTE_REQUEST_STATUS } from '../../redux/Constants';
import { QuotesListContainer } from './styles';

const template = (props) => {
    const { t, i18n } = useTranslation();
    const {
        loading = false,
        onSearch,
        onSelectCategory,
        key = '',
        searchBy = '',
        newList,
        awaitingList,
        acceptedList,
        declinedList,
        searchList,
        navigate,
        onChangePageNewQuotes,
        onChangePageAwaiting,
        onChangePageAccepted,
        onChangePageDeclined,
        onChangePageSearch
    } = props;

    const createCategoryOption = () => {
        let tempArr = [
            {
                text: t('ALL'),
                value: QUOTE_REQUEST_STATUS.ALL
            },
            {
                text: t('NEW_QUOTE_REQUEST'),
                value: QUOTE_REQUEST_STATUS.ACTIVE
            },
            {
                text: t('AWAITING_CUSTOMER_RESPONSE'),
                value: QUOTE_REQUEST_STATUS.WAITING
            },
            {
                text: t('QUOTE_ACCEPTED'),
                value: QUOTE_REQUEST_STATUS.ACCEPTED
            },
            // {
            //     text: t('QUOTE_ACCEPTED'),
            //     value: QUOTE_REQUEST_STATUS.COMPLETED
            // },
            // {
            //     text: t('DECLINED'),
            //     value: QUOTE_REQUEST_STATUS.REJECTED
            // },
            {
                text: t('DECLINED'),
                value: QUOTE_REQUEST_STATUS.EXPIRED
            }
        ];

        return tempArr;
    };

    return (
        <QuotesListContainer>
            <Header
                title={t('QUOTE_REQUESTS')}
                description={t('BOOKINGS_SUBTITLE')}
            />
            <SearchBooking
                onSearch={onSearch}
                onSelectCategory={onSelectCategory}
                placeholder={t('PLACEHOLDER_SEARCH_BOOKING')}
                data={createCategoryOption()}
            />

            {!key ? (
                <>
                    {/* New Bookings */}
                    {(searchBy === QUOTE_REQUEST_STATUS.ALL ||
                        searchBy === QUOTE_REQUEST_STATUS.ACTIVE) && (
                        <NewQuoteRequest
                            onChangePageNewBooking={onChangePageNewQuotes}
                            list={newList}
                            loading={loading}
                            navigate={navigate}
                        />
                    )}

                    {/* Awaiting Confirmation */}
                    {(searchBy === QUOTE_REQUEST_STATUS.ALL ||
                        searchBy === QUOTE_REQUEST_STATUS.WAITING) && (
                        <AwaitingQuote
                            onChangePageAwaiting={onChangePageAwaiting}
                            list={awaitingList}
                            loading={loading}
                            navigate={navigate}
                        />
                    )}

                    {/* Booking Confirmed */}
                    {(searchBy === QUOTE_REQUEST_STATUS.ALL ||
                        searchBy === QUOTE_REQUEST_STATUS.ACCEPTED) && (
                        <QuoteAccepted
                            onChangePageConfirmed={onChangePageAccepted}
                            list={acceptedList}
                            loading={loading}
                            navigate={navigate}
                        />
                    )}

                    {/* Booking Cancelled */}
                    {(searchBy === QUOTE_REQUEST_STATUS.ALL ||
                        searchBy === QUOTE_REQUEST_STATUS.EXPIRED) && (
                        <QuoteDeclined
                            onChangePageCancelled={onChangePageDeclined}
                            list={declinedList}
                            loading={loading}
                            navigate={navigate}
                        />
                    )}
                </>
            ) : (
                <SearchResult
                    onChangePageSearch={onChangePageSearch}
                    list={searchList}
                    loading={loading}
                    navigate={navigate}
                />
            )}
        </QuotesListContainer>
    );
};

export { template };
