import React, { Component, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from 'react-google-maps';
import {
    MapMarker,
    MapMarkerComponent,
    IconDeparture_2,
    IconLand_2
} from 'assets/images/icons';
import { compose, withProps, lifecycle } from 'recompose';
import _ from 'lodash';

const SimpleMap = compose(
    withProps({
        googleMapURL:
            'https://maps.googleapis.com/maps/api/js?key=AIzaSyA40WAkPpWYZ9iUFidtIcazgOJ-ukxc1Xg&v=3.exp&libraries=geometry,drawing,places',
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `503px` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    lifecycle({
        componentDidMount() {
            const refs = {};

            this.setState({
                mapProjection: null,
                zoom: 3,
                onMapMounted: (ref) => {
                    refs.map = ref;
                    if (ref) {
                        this.setState({
                            mapProjection: ref.getProjection()
                        });
                    }
                },
                projectionChanged: () => {
                    this.setState({
                        mapProjection: refs.map.getProjection()
                    });
                },
                onZoomChanged: () => {
                    this.setState({
                        zoom: refs.map.getZoom()
                    });
                }
            });
        }
    }),
    withScriptjs,
    withGoogleMap
)((props) => {
    return (
        <>
            {!_.isEmpty(props.from_airport) && !_.isEmpty(props.to_airport) && (
                <GoogleMap
                    defaultCenter={{ lat: 39.8283, lng: -98.5795 }}
                    ref={props.onMapMounted}
                    onProjectionChanged={props.projectionChanged}
                    zoom={props.zoom}
                    onZoomChanged={props.onZoomChanged}
                    options={{
                        styles: mapStyle
                    }}
                >
                    <Markers
                        mapProjection={props.mapProjection}
                        zoom={props.zoom}
                        from_airport={props.from_airport || {}}
                        to_airport={props.to_airport || {}}
                        key={'binh'}
                    ></Markers>
                </GoogleMap>
            )}
        </>
    );
});

class Markers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            position1: new google.maps.LatLng(
                props.from_airport?.location?.geometry?.coordinates[1] ||
                    37.773972,
                props.from_airport?.location?.geometry?.coordinates[0] ||
                    -122.431297
            ),
            position2: new google.maps.LatLng(
                props.to_airport?.location?.geometry?.coordinates[1] || 40.7128,
                props.to_airport?.location?.geometry?.coordinates[0] || -74.006
            )
        };
    }

    // static getDerivedStateFromProps(props, current_state) {
    //     console.log('next props', props);
    //     let newState = null;
    //     if (
    //         current_state.from_airport !== props.from_airport ||
    //         current_state.to_airport !== props.to_airport
    //     ) {
    //         newState = {
    //             position1: props.from_airport || {},
    //             position2: props.to_airport || {}
    //         };
    //     }
    //     return newState;
    // }

    iconMarker = new window.google.maps.MarkerImage(
        MapMarker,
        null /* size is determined at runtime */,
        null /* origin is 0,0 */,
        null /* anchor is bottom center of the scaled image */,
        new window.google.maps.Size(32, 32)
    );

    iconMarkerLand = new window.google.maps.MarkerImage(
        IconLand_2,
        null /* size is determined at runtime */,
        null /* origin is 0,0 */,
        null /* anchor is bottom center of the scaled image */,
        new window.google.maps.Size(32, 32)
    );
    iconMarkerDeparture = new window.google.maps.MarkerImage(
        IconDeparture_2,
        null /* size is determined at runtime */,
        null /* origin is 0,0 */,
        null /* anchor is bottom center of the scaled image */,
        new window.google.maps.Size(32, 32)
    );

    render() {
        return (
            <div>
                {/* <Marker position={this.state.position1} icon={this.iconMarker}> */}
                {/* <InfoWindow position={this.state.position1}> */}
                {/* <MapMarkerComponent /> */}
                <Marker
                    position={this.state.position1}
                    // position={
                    //     new google.maps.LatLng(
                    //         this.props.from_airport?.location?.geometry
                    //             ?.coordinates[0] || 37.773972,
                    //         this.props.from_airport?.location?.geometry
                    //             ?.coordinates[1] || -122.431297
                    //     )
                    // }
                    icon={this.iconMarkerDeparture}
                />
                {/* </InfoWindow> */}
                {/* </Marker> */}
                <Marker
                    position={this.state.position2}
                    // position={
                    //     new google.maps.LatLng(
                    //         this.props.to_airport?.location?.geometry
                    //             ?.coordinates[0] || 40.7128,
                    //         this.props.to_airport?.location?.geometry
                    //             ?.coordinates[1] || -74.006
                    //     )
                    // }
                    icon={this.iconMarkerLand}
                />
                {this.props.mapProjection && (
                    <CurveMarker
                        pos1={this.state.position1}
                        pos2={this.state.position2}
                        mapProjection={this.props.mapProjection}
                        zoom={this.props.zoom}
                    />
                )}
            </div>
        );
    }
}

const CurveMarker = ({ pos1, pos2, mapProjection, zoom }) => {
    if (!mapProjection) return <div />;

    const calculateSymbol = () => {
        const curvature = 0.4;

        const p1 = mapProjection.fromLatLngToPoint(pos1),
            p2 = mapProjection.fromLatLngToPoint(pos2);

        // Calculating the arc.
        const e = new google.maps.Point(p2.x - p1.x, p2.y - p1.y), // endpoint
            m = new google.maps.Point(e.x / 2, e.y / 2), // midpoint
            o = new google.maps.Point(e.y, -e.x), // orthogonal
            c = new google.maps.Point(
                m.x + curvature * o.x,
                m.y + curvature * o.y
            ); //curve control point

        const pathDef =
            'M 0,0 ' + 'q ' + c.x + ',' + c.y + ' ' + e.x + ',' + e.y;

        const scale = 1 / Math.pow(2, -zoom);
        return {
            path: pathDef,
            scale: scale,
            strokeWeight: 2,
            fillColor: 'none',
            strokeColor: '#19c0ff'
        };
    };

    const [symbol, setSymbol] = useState(calculateSymbol());

    useEffect(() => {
        setSymbol(calculateSymbol());
    }, [zoom]);

    return (
        <Marker position={pos1} clickable={false} icon={symbol} zIndex={0} />
    );
};

export default SimpleMap;

const Dot = styled.div`
    background-color: #19c0ff;
    border: 4px solid rgba(255, 255, 255, 0.14);
    border-radius: 100%;
    width: 10px;
    height: 10px;
`;

const mapStyle = [
    {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#65686f'
            }
        ]
    },
    {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
            {
                color: '#f2f2f2'
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'all',
        stylers: [
            {
                saturation: -100
            },
            {
                lightness: 45
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [
            {
                visibility: 'simplified'
            }
        ]
    },
    {
        featureType: 'road.arterial',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'all',
        stylers: [
            {
                color: '#ffffff'
            },
            {
                visibility: 'on'
            }
        ]
    }
];

const DotMarker = styled.div`
    background-color: #19c0ff;
    border: 4px solid rgba(255, 255, 255, 0.14);
    border-radius: 100%;
    width: 10px;
    height: 10px;
`;
