import React from 'react';
import styled from 'styled-components';

const JetInfo = ({ leg = {}, vertical = false }) => {
    const { aircraft = {} } = leg;
    const { name = '', tail_number = '' } = aircraft;
    return (
        <>
            {vertical ? (
                <VerticalJetContainer>
                    {(name || tail_number) && (
                        <>
                            {/* <JetName>{name ? name.toUpperCase() : '_'}</JetName> */}
                            <TailorCode>
                                {tail_number ? tail_number.toUpperCase() : '_'}
                            </TailorCode>
                        </>
                    )}
                </VerticalJetContainer>
            ) : (
                <JetContainer>
                    {(name || tail_number) && (
                        <>
                            {/* <JetName>{name ? name.toUpperCase() : '_'}</JetName> */}
                            <TailorCode>
                                {tail_number ? tail_number.toUpperCase() : '_'}
                            </TailorCode>
                        </>
                    )}
                </JetContainer>
            )}
        </>
    );
};

const VerticalJetContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 3px;
    margin-bottom: 3px;
`;

const JetContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 3px;
    margin-bottom: 3px;
`;

const JetName = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    line-height: 12px;
    /* width: 152px; */
    text-align: left;
    margin-right: 10px;
`;

const TailorCode = styled.div`
    border: 1px solid #dadadb;
    border-radius: 2px;
    width: 58px;
    height: 18px;
    color: #19c0ff;
    font-family: AvenirNext-Bold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    width: 56px;
    text-align: center;
    padding: 3px;
`;

export default JetInfo;
