import React from 'react';
import styled from 'styled-components';
import background from '../../../assets/images/default_background.png';
import 'antd/dist/antd.css';
import { Layout, Tabs, Col, Row, Avatar, Input, Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import {
    BackgroundProfile,
    ProfileInfo,
    TabBarNavigator,
    AboutWrapper,
    AboutContent,
    AboutText,
    MenuTitle,
    TabText,
    Title,
    TitleDescription,
    ExpandWrapper,
    Name,
    Comapy,
    AvatarWrapper,
    PhotoWrapper,
    Photo,
    ExpandButton,
    ExpandText,
    SearchSection
} from '../styles';
import { renderAddress } from '../../../utils';
import {
    aboutIcon,
    timelineIcon,
    locationIcon,
    servicesIcon,
    aircraftListingIcon,
    flightPostIcon,
    fleetsIcon,
    arrowDownIcon,
    arrowUpIcon,
    callIcon,
    emailIcon,
    starIcon,
    searchIcon,
    createdIcon,
    imageIcon
} from '../../../assets/images/icons';
import {
    ButtonCustom,
    Text,
    Button,
    EmptyComponent
} from '../../../components';
import _ from 'lodash';
import { ButtonType } from '../../../components/Antd/Button';
import { numberFormat, formatHttpStr } from '../../../utils';
import { FBO_DETAIL } from '../../../routes/main';
import { PARTNERSHIP_TYPE } from '../../../redux/Constants';
import { PrimaryButton, SecondaryButton } from '../../../components';

const { TabPane } = Tabs;
const { TextArea } = Input;

function ImageDefault() {
    return (
        <BackgroundDefault>
            <img
                width={90}
                height={60}
                src={imageIcon}
            />
        </BackgroundDefault>
    );
}

const FuelServiceItem = ({ value = '', ratio = '' }) => {
    return (
        <div>
            <FuelServicePrice color={ratio >= 0 ? '#8DD64F' : '#DD4558'}>
                {numberFormat(value)}
            </FuelServicePrice>
            {ratio !== 0 && (
                <Icon
                    style={{ width: 25, height: 20 }}
                    src={ratio > 0 ? arrowUpIcon : arrowDownIcon}
                />
            )}
        </div>
    );
};

const EachAirport = ({ label, value }) => {
    return (
        <EachProperty>
            <PropertyLabel>
                <Text textStyle="tableText1">{label}</Text>
            </PropertyLabel>
            <PropertyValue>
                <Text textStyle="tableText2">{value}</Text>
            </PropertyValue>
        </EachProperty>
    );
};

const TabItems = ({ title, icon }) => {
    return (
        <span style={{ paddingLeft: 25, paddingRight: 25 }}>
            <Icon src={icon} />
            <TabText>{title}</TabText>
        </span>
    );
};

const template = (props) => {
    const {
        navigate,
        fboDetail = {},
        fboLocations = [],
        fetchFBOLocations,
        onSearchFBO,
        activeCompanyId
    } = props;

    const {
        name = '',
        image = {},
        backgroundImage = '',
        images = [],
        partnership = '', // PARTNERSHIP_TYPE
        address = {},
        airport = {},
        company = {},
        contact = {},
        frequency,
        id,
        created_by_company_id = '',
        opening_times,
        products = [],
        services = [],
        head_quarter = false,
        employees = []
    } = fboDetail;
    const { t, i18n } = useTranslation();
    const SearchIcon = <img src={searchIcon} />;

    const onChangeTabs = (activeKey) => {
        if (activeKey === 'LOCATIONS') {
            fetchFBOLocations && fetchFBOLocations(company?.id);
        }
    };

    const _renderTabAbout = () => {
        return (
            <AboutWrapper>
                <MenuTitle>{i18n.t('ABOUT_US').toUpperCase()}</MenuTitle>
                {company && company.description ? (
                    <AboutContent>
                        {/* <AboutText>{company.description}</AboutText> */}
                        <TextArea
                            style={{
                                color: '#65686f',
                                marginTop: 20,
                                borderRadius: 4,
                                padding: 20,
                                border: 'none',
                                fontFamily: 'Lato'
                            }}
                            autoSize={{ minRows: 1, maxRows: 100 }}
                            value={company.description}
                        />
                    </AboutContent>
                ) : (
                    <EmptyComponent />
                )}
                <MenuTitle style={{ marginTop: 35 }}>{`${i18n
                    .t('OUR_TEAM')
                    .toUpperCase()} (${employees.length})`}</MenuTitle>
                {employees && employees.length > 0 ? (
                    <PhotoWrapper>
                        <Row style={{ width: '100%' }}>
                            {employees.map((v, i) => (
                                <Col xxl={6} xl={8} lg={12} md={12} sm={18} xs={24} key={i}>
                                    <TeamWrapper>
                                        <Col style={{ alignSelf: 'center', marginRight: '15px' }}>
                                            {v.details && v.details.image ? (
                                                <Avatar
                                                    className="ant-avatar-custom avatar-item"
                                                    src={v.details.image}
                                                    size={86}
                                                />
                                            ) : (
                                                <Avatar
                                                    className="ant-avatar-custom avatar-item"
                                                    size={86}
                                                >
                                                    {(
                                                        v.details.firstname.charAt(
                                                            0
                                                        ) +
                                                        v.details.lastname.charAt(0)
                                                    ).toUpperCase()}
                                                </Avatar>
                                            )}
                                        </Col>
                                        <Col style={{ alignSelf: 'center' }}>
                                            <div>
                                                <TeamName>
                                                    {v.details && v.details.name
                                                        ? v.details.name.toUpperCase()
                                                        : '_'}
                                                </TeamName>
                                            </div>
                                            <div>
                                                <TeamRole>{v.role}</TeamRole>
                                            </div>
                                            <Wrapper>
                                                <Icon
                                                    style={{
                                                        width: 12,
                                                        height: 12,
                                                        marginLeft: 0
                                                    }}
                                                    src={callIcon}
                                                />
                                                <TeamItem>
                                                    {v.details
                                                        ? v.details.phone
                                                        : '_'}
                                                </TeamItem>
                                            </Wrapper>
                                            <Wrapper>
                                                <Icon
                                                    style={{
                                                        width: 12,
                                                        height: 12,
                                                        marginLeft: 0
                                                    }}
                                                    src={emailIcon}
                                                />
                                                <TeamItem>
                                                    {v.details
                                                        ? v.details.email
                                                        : '_'}
                                                </TeamItem>
                                            </Wrapper>
                                        </Col>
                                    </TeamWrapper>
                                </Col>
                            ))}
                        </Row>
                    </PhotoWrapper>
                ) : (
                    <EmptyComponent />
                )}

                <MenuTitle style={{ marginTop: 35 }}>{`${i18n
                    .t('PHOTOS')
                    .toUpperCase()} (${images.length})`}</MenuTitle>
                {images && images.length > 0 ? (
                    <PhotoWrapper>
                        <Row style={{ width: '100%' }}>
                            {images.map((v, i) => (
                                <Col xxl={6} xl={8} lg={12} md={12} sm={18} xs={24} key={i}>
                                    <Photo url={v?.lowres?.url} />
                                </Col>
                            ))}
                        </Row>
                    </PhotoWrapper>
                ) : (
                    <EmptyComponent />
                )}
            </AboutWrapper>
        );
    };

    const _renderTabLocation = () => {
        return (
            <div>
                <SearchSection>
                    <Row>
                        <Col xl={6} lg={8} md={10} sm={12} xs={12}>
                            <Input
                                placeholder={t('SEARCH')}
                                allowClear
                                suffix={SearchIcon}
                                onChange={(e) => onSearchFBO(company?.id, e)}
                            />
                        </Col>
                    </Row>
                </SearchSection>
                {fboLocations.map((item, i) => (
                    <LocationDetail key={i}>
                        <Row style={{ width: '100%' }}>
                            <Col xl={20} lg={18} md={18} sm={16} xs={24}>
                                <LeftSection>
                                    <Row>
                                        <Col xl={8} lg={8} md={8} sm={12} xs={24}>
                                            <ImageSection>
                                                <Carousel
                                                    className="carousel-custom"
                                                    thumbWidth={84}
                                                    showStatus={false}
                                                >
                                                    {item.images && item.images.length > 0 ? (
                                                        item.images.map((eachImage, index) => {
                                                            const { lowres = {} } = eachImage;
                                                            return (
                                                                <div key={index}>
                                                                    <img src={lowres.url} />
                                                                </div>
                                                            );
                                                        })
                                                    ) : (
                                                        <ImageDefault />
                                                    )}
                                                </Carousel>
                                            </ImageSection>
                                        </Col>
                                        <Col xl={16} lg={16} md={16} sm={12} xs={24}>
                                            <DetailInformationSection>
                                                <AirportNameSection>
                                                    <Row style={{ width: '100%' }}>
                                                        <TabLink
                                                            onClick={() =>
                                                                navigate(FBO_DETAIL, item.id)
                                                            }
                                                        >
                                                            <AirportName>
                                                                <Text textStyle="heading3">
                                                                    {item &&
                                                                        item.airport &&
                                                                        `${item.airport.icao_code.toUpperCase()} - ${item.airport.name.toUpperCase()}`}
                                                                </Text>
                                                            </AirportName>
                                                        </TabLink>
                                                    </Row>
                                                    <Row style={{ width: '100%' }}>
                                                        <Description>
                                                            {item ? item.description : ''}
                                                        </Description>
                                                    </Row>
                                                    <AirportItem>
                                                        <Row style={{ width: '100%' }}>
                                                            <Col xl={6} lg={6} md={8} sm={24} xs={24}>
                                                                <EachAirport
                                                                    label={t('ADDRESS')}
                                                                    value={
                                                                        item && item.address
                                                                            ? renderAddress(
                                                                                item.address
                                                                            ).toUpperCase()
                                                                            : '_'
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col xl={6} lg={6} md={8} sm={24} xs={24}>
                                                                <EachAirport
                                                                    label={t('PHONE_NUMBER')}
                                                                    value={
                                                                        item.contact && item.contact
                                                                            ? item.contact.phone.toUpperCase()
                                                                            : '_'
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col xl={6} lg={6} md={8} sm={24} xs={24}>
                                                                <EachAirport
                                                                    label={t('FAX')}
                                                                    value={
                                                                        item.contact && item.contact.fax
                                                                            ? item.contact.fax.toUpperCase()
                                                                            : '_'
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col xl={6} lg={6} md={8} sm={24} xs={24}>
                                                                <EachAirport
                                                                    label={t('EMAIL_ADDRESS')}
                                                                    value={
                                                                        item.contact &&
                                                                        item.contact.email
                                                                            ? item.contact.email.toUpperCase()
                                                                            : '_'
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </AirportItem>
                                                </AirportNameSection>
                                                <AirportButton>
                                                    <PrimaryButton
                                                        width="170px"
                                                        onClick={() => {}}
                                                        size="medium"
                                                        title={t('VIEW_DETAILS').toUpperCase()}
                                                    />
                                                    <div style={{ width: '25px' }} />
                                                    <SecondaryButton
                                                        width="170px"
                                                        onClick={() => {}}
                                                        size="medium"
                                                        title={t(
                                                            'REQUEST_SERVICES'
                                                        ).toUpperCase()}
                                                    />
                                                </AirportButton>
                                            </DetailInformationSection>
                                        </Col>
                                    </Row>
                                </LeftSection>
                            </Col>
                            <Col xl={4} lg={6} md={6} sm={8} xs={24}>
                                <FuelServiceInfo>
                                    <FuelServiceContainer>
                                        <FuelServiceTitle>
                                            {i18n.t('FUEL_SERVICE')}
                                        </FuelServiceTitle>
                                        <FuelServiceItemWrapprer>
                                            <Wrapper flex="space-around">
                                                <Title>{'JET A'}</Title>
                                                <FuelServiceItem
                                                    value={6.25}
                                                    ratio={-1}
                                                />
                                            </Wrapper>
                                            <Wrapper flex="space-around">
                                                <Title>{'JET A'}</Title>
                                                <FuelServiceItem
                                                    value={6.0}
                                                    ratio={-1}
                                                />
                                            </Wrapper>
                                            <Wrapper flex="space-around">
                                                <Title>{'100LL'}</Title>
                                                <FuelServiceItem
                                                    value={7.8}
                                                    ratio={1}
                                                />
                                            </Wrapper>
                                        </FuelServiceItemWrapprer>
                                    </FuelServiceContainer>
                                    <AircraftDetail>
                                        <div
                                            style={{
                                                paddingLeft: 20,
                                                paddingRight: 20
                                            }}
                                        >
                                            <ButtonCustom
                                                size="small"
                                                btnType={ButtonType.SECONDARY}
                                                onClick={() => {}}
                                                title={t('MAINTENANCE').toUpperCase()}
                                                width="100%"
                                                fontSize={'10px'}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                marginTop: '10px',
                                                paddingLeft: 20,
                                                paddingRight: 20
                                            }}
                                        >
                                            <ButtonCustom
                                                size="small"
                                                btnType={ButtonType.SECONDARY}
                                                onClick={() => {}}
                                                title={t('CATERING').toUpperCase()}
                                                width="100%"
                                            />
                                        </div>
                                    </AircraftDetail>
                                </FuelServiceInfo>
                            </Col>
                        </Row>
                    </LocationDetail>
                ))}
            </div>
        );
    };

    const _renderProfile = () => {
        return (
            <ProfileInfo>
                <Row style={{ width: '100%' }}>
                    <Col xxl={3} xl={4} lg={6} md={7} sm={10} xs={20} style={{ marginRight: '20px', paddingTop: '20px' }}>
                        <Title style={{ marginBottom: 10 }}>
                            {i18n.t('ADDRESS')}
                        </Title>
                        <TitleDescription>
                            {renderAddress(address).toUpperCase()}
                        </TitleDescription>
                    </Col>
                    <Col xxl={3} xl={4} lg={6} md={7} sm={10} xs={20} style={{ marginRight: '20px', paddingTop: '20px' }}>
                        <Title style={{ marginBottom: 10 }}>
                            {i18n.t('PHONE_NUMBER')}
                        </Title>
                        <TitleDescription>
                            {contact && contact.phone || '_'}
                        </TitleDescription>
                    </Col>
                    <Col xxl={3} xl={4} lg={6} md={7} sm={10} xs={20} style={{ marginRight: '20px', paddingTop: '20px' }}>
                        <Title style={{ marginBottom: 10 }}>
                            {i18n.t('EMAIL_ADDRESS')}
                        </Title>
                        <TitleDescription>
                            {contact &&
                                contact.email &&
                                contact.email.toUpperCase()}
                        </TitleDescription>
                    </Col>
                    <Col xxl={3} xl={4} lg={6} md={7} sm={10} xs={20} style={{ marginRight: '20px', paddingTop: '20px' }}>
                        <Title style={{ marginBottom: 10 }}>
                            {i18n.t('COMPANY_WEBSITE')}
                        </Title>
                        <TabLink
                            rel="noopener noreferrer"
                            href={contact.website}
                            target="_blank"
                        >
                            <TitleDescription color="#19C0FF">
                                {contact &&
                                    contact.website &&
                                    formatHttpStr(contact.website).toUpperCase() || '_'}
                            </TitleDescription>
                        </TabLink>
                    </Col>
                    <Col xxl={3} xl={4} lg={6} md={7} sm={10} xs={20} style={{ marginRight: '20px', paddingTop: '20px' }}>
                        <Title style={{ marginBottom: 10 }}>{i18n.t('FREQ')}</Title>
                        <TitleDescription color="#19C0FF" size="20px">
                            {frequency || '_'}
                        </TitleDescription>
                    </Col>
                </Row>
            </ProfileInfo>
        );
    };

    const _renderFuelService = () => {
        return (
            <FuelService>
                <Row align="middle" style={{ width: '100%' }}>
                    <Col style={{ width: '165px' }}>
                        <FuelServiceTitle>
                            {i18n.t('FUEL_SERVICE')}
                        </FuelServiceTitle>
                    </Col>
                    <Col span={18}>
                        <Row>
                            <Col xxl={4} xl={4} lg={6} md={6} sm={6} xs={24}>
                                <Wrapper flex="left">
                                    <Title>{'JET A'}</Title>
                                    <FuelServiceItem value={6.25} ratio={-1} />
                                </Wrapper>
                            </Col>
                            <Col xxl={4} xl={4} lg={6} md={6} sm={6} xs={24}>
                                <Wrapper flex="left">
                                    <Title>{'JET A'}</Title>
                                    <FuelServiceItem value={6.0} ratio={-1} />
                                </Wrapper>
                            </Col>
                            <Col xxl={4} xl={4} lg={6} md={6} sm={6} xs={24}>
                                <Wrapper flex="left">
                                    <Title>{'100LL'}</Title>
                                    <FuelServiceItem value={7.8} ratio={1} />
                                </Wrapper>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </FuelService>
        );
    };

    const _renderInformation = () => {
        return (
            <>
                <BackgroundProfile background={backgroundImage || background}>
                    <div className="overlay-background">
                        <AvatarWrapper>
                            <Row>
                                <Col>
                                    {image && image.url ? (
                                        <Avatar
                                            className="ant-avatar-custom avatar-item"
                                            src={image.url}
                                            size={152}
                                        />
                                    ) : (
                                        <Avatar
                                            className="ant-avatar-custom avatar-item"
                                            size={152}
                                            style={{
                                                backgroundColor: '#F0F3F9'
                                            }}
                                        >
                                            <Icon
                                                src={imageIcon}
                                                style={{
                                                    width: 32,
                                                    height: 28
                                                }}
                                            />
                                        </Avatar>
                                    )}
                                </Col>
                                <Col className="fbo-display-name">
                                    <Row>
                                        <Name
                                            className="fbo-name-custom"
                                            style={{ marginRight: '25px', minWidth: '100px', marginBottom: '10px' }}
                                            color="#FFFFFF"
                                        >{`${name.toUpperCase()}`}</Name>
                                        {partnership !==
                                        PARTNERSHIP_TYPE.NEW ? (
                                            <ButtonCustom
                                                btnType={ButtonType.FOLLOW_BTN}
                                                onClick={() => {}}
                                                title={t(
                                                    'FOLLOW'
                                                ).toUpperCase()}
                                            />
                                        ) : (
                                            <div>
                                                <Icon src={createdIcon} />
                                                <StatusTypeText>
                                                    {t('FBO_STATUS_NEW')}
                                                </StatusTypeText>
                                            </div>
                                        )}
                                    </Row>
                                    <Row>
                                        {!head_quarter ||
                                        partnership === PARTNERSHIP_TYPE.NEW ? (
                                            <Comapy>
                                                {!_.isEmpty(airport) &&
                                                    `${airport.icao_code.toUpperCase()} - ${airport.name.toUpperCase()}`}
                                            </Comapy>
                                        ) : (
                                            <Comapy>
                                                <Icon
                                                    style={{
                                                        width: 16,
                                                        height: 16,
                                                        marginRight: '8px',
                                                        marginLeft: 0
                                                    }}
                                                    src={starIcon}
                                                />
                                                {t('HEADQUATER').toUpperCase()}
                                            </Comapy>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        </AvatarWrapper>
                    </div>

                    {activeCompanyId &&
                        created_by_company_id &&
                        activeCompanyId !== created_by_company_id && (
                            <ButtonWrapper>
                                <div>
                                    <PrimaryButton
                                        width="180px"
                                        onClick={() => {}}
                                        size="small"
                                        title={t('SEND_MESSAGE').toUpperCase()}
                                    />
                                </div>
                                {partnership === PARTNERSHIP_TYPE.NEW && (
                                    <div className="btn-wrapper">
                                        <ButtonCustom
                                            btnType={ButtonType.EDIT_BTN}
                                            onClick={() => {}}
                                            fontSize={'10px'}
                                            title={t(
                                                'REQUEST_PARTPERSHIP'
                                            ).toUpperCase()}
                                        />
                                    </div>
                                )}
                            </ButtonWrapper>
                        )}
                </BackgroundProfile>
                {_renderProfile()}
                <Divider />
                {_renderFuelService()}
            </>
        );
    };

    const _renderTabs = () => {
        return (
            <TabBarNavigator>
                <Tabs
                    defaultActiveKey="ABOUT"
                    onChange={onChangeTabs}
                    style={{ width: '100%' }}
                >
                    <TabPane
                        key="ABOUT"
                        tab={
                            <TabItems
                                icon={aboutIcon}
                                title={i18n.t('ABOUT').toUpperCase()}
                            />
                        }
                    >
                        {partnership === PARTNERSHIP_TYPE.NEW ? (
                            <EmptyComponent description={t('NO_DATA_FBO').toUpperCase()} />
                        ) : (
                            _renderTabAbout()
                        )}
                    </TabPane>
                    {head_quarter && (
                        <TabPane
                            key="LOCATIONS"
                            tab={
                                <TabItems
                                    icon={locationIcon}
                                    title={i18n.t('LOCATIONS').toUpperCase()}
                                />
                            }
                        >
                            {_renderTabLocation()}
                        </TabPane>
                    )}
                    <TabPane
                        key="SERVICES"
                        tab={
                            <TabItems
                                icon={servicesIcon}
                                title={i18n.t('SERVICES').toUpperCase()}
                            />
                        }
                    >
                        <EmptyComponent
                            description={
                                partnership === PARTNERSHIP_TYPE.NEW
                                    ? t('NO_DATA_FBO')
                                    : t('NO_DATA')
                            }
                        />
                    </TabPane>
                    <TabPane
                        key="AIRCRAFT_LISTING"
                        tab={
                            <TabItems
                                icon={aircraftListingIcon}
                                title={i18n.t('AIRCRAFT_LISTING').toUpperCase()}
                            />
                        }
                    >
                        <EmptyComponent
                            description={
                                partnership === PARTNERSHIP_TYPE.NEW
                                    ? t('NO_DATA_FBO')
                                    : t('NO_DATA')
                            }
                        />
                    </TabPane>
                    <TabPane
                        key="FLIGHT_POSTED"
                        tab={
                            <TabItems
                                icon={flightPostIcon}
                                title={i18n.t('FLIGHT_POSTED').toUpperCase()}
                            />
                        }
                    >
                        <EmptyComponent
                            description={
                                partnership === PARTNERSHIP_TYPE.NEW
                                    ? t('NO_DATA_FBO')
                                    : t('NO_DATA')
                            }
                        />
                    </TabPane>
                    <TabPane
                        key="TIMELINE"
                        tab={
                            <TabItems
                                icon={timelineIcon}
                                title={i18n.t('TIMELINE').toUpperCase()}
                            />
                        }
                    >
                        <EmptyComponent
                            description={
                                partnership === PARTNERSHIP_TYPE.NEW
                                    ? t('NO_DATA_FBO')
                                    : t('NO_DATA')
                            }
                        />
                    </TabPane>
                    <TabPane
                        key="FLEET"
                        tab={
                            <TabItems
                                icon={fleetsIcon}
                                title={i18n.t('FLEET').toUpperCase()}
                            />
                        }
                    >
                        <EmptyComponent
                            description={
                                partnership === PARTNERSHIP_TYPE.NEW
                                    ? t('NO_DATA_FBO')
                                    : t('NO_DATA')
                            }
                        />
                    </TabPane>
                </Tabs>
            </TabBarNavigator>
        );
    };

    return (
        <Layout style={{ backgroundColor: 'transperent' }}>
            {_renderInformation()}
            {_renderTabs()}
        </Layout>
    );
};

export { template };

const Icon = styled.img`
    margin: 5px;
`;

const TabLink = styled.a``;

const Divider = styled.div`
    background-color: #0e0e12 - 10%;
    width: 100%;
    height: 1px;
`;

const ButtonWrapper = styled.div`
    min-width: 180px;
    margin-bottom: 25px;
    position: absolute;
    right: 25px;
`;

const TeamWrapper = styled.div`
    background-color: #ffffff;
    border-radius: 4px;
    min-width: 320px;
    min-height: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    margin-top: 20px;
`;

const TeamName = styled.span`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.8px;
    line-height: 12px;
    width: 199px;
    text-align: left;
`;

const TeamRole = styled.span`
    color: #19c0ff;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    line-height: 13px;
    width: 199px;
    text-align: left;
`;

const TeamItem = styled.span`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 11px;
    line-height: 14px;
    width: 180px;
    text-align: left;
`;

const FuelService = styled.div`
    display: flex;
    align-items: center;
    background-color: #ffffff;
    min-height: 45px;
    padding-left: 60px;
`;

const FuelServiceTitle = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
`;

const FuelServicePrice = styled.span`
    color: ${(props) => props.color || '#dd4558'};
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.84px;
    line-height: 18px;
    text-align: left;
    margin-left: 10px;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: ${(props) => props.flex || 'center'};
    align-items: center;
`;

const LeftSection = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 20px;
`;

const AircraftDetail = styled.div`
    justify-content: center;
    align-self: center;
    width: 100%;
`;

const FuelServiceContainer = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
`;

const FuelServiceItemWrapprer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 2px;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: space-around;
`;

const PropertyLabel = styled.div`
    height: 18px;
`;

const PropertyValue = styled.div`
    height: 18px;
    -webkit-line-clamp: 2; /* number of lines to show */
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 18px;
    width: 170px;
    text-align: left;
    margin-top: 10px;
`;

const EachProperty = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 170px;
`;

const AirportItem = styled.div`
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const AirportButton = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
`;

const Description = styled.span`
    margin-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
    color: #65686f;
    font-family: Lato;
    font-size: 14px;
    line-height: 24px;
`;

const AirportName = styled.div``;

const AirportNameSection = styled.div`
    justify-content: flex-start;
    align-items: center;
`;

const ImageSection = styled.div`
    max-width: 350px;
    .carousel-custom .carousel .thumbs-wrapper {
        margin-top: 20px;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        overflow: hidden;
        ul {
            padding-inline-start: 0;
        }
    }
    .thumb {
        transition: border 0.15s ease-in;
        display: inline-block;
        margin-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        border: 3px solid #fff;
        padding: 0;
        width: 84px;
        height: 53px;
    }
    .carousel .thumb.selected,
    .carousel .thumb:hover {
        border: 1px solid #19c0ff;
    }
    .carousel .slide {
        width: 270px;
        height: 170px;
    }
`;
const DetailInformationSection = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;
`;
const FuelServiceInfo = styled.div`
    background-color: #f7f8fb;
    border-radius: 0 4px 4px 0;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: space-between;
    min-width: 180px;
`;

const LocationDetail = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 40px;
    padding-left: 20px;
    background-color: #ffffff;
    border-radius: 4px;
`;

const StatusTypeText = styled.span`
    color: #ffffff;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    line-height: 13px;
    width: 42px;
    text-align: left;
    margin-left: 5px;
`;

const BackgroundDefault = styled.div`
    display: flex;
    min-width: 270px;
    min-height: 170px;
    background-color: #f0f3f9;
    justify-content: center;
    align-items: center;
`;
