import React, { useState, useEffect } from 'react';
import { template as tmpl } from './TopDestinationDetail.tmpl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getTopDestinationById } from 'redux/actions/flightAction';
import { updateTopDestination, removeTopDestination } from 'redux/actions/airportAction';
import { uploadImage } from 'redux/actions/appActions';
import { useTranslation } from 'react-i18next';
import { FLIGHT_PROMOTION_LIST } from 'routes/main';
import { useDispatch, useSelector } from 'react-redux';
import { UPLOAD_MODEL, UPLOAD_IMAGE_TYPE, SHOW_LOADING, HIDE_LOADING } from 'redux/Constants';

const TopDestinationDetailLogic = (props) => {
    const {
        getTopDestinationById,
        updateTopDestination,
        removeTopDestination,
        uploadImage,
        location,
    } = props;
    const { search } = location;
    const dispatch = useDispatch();
    const { isLoading } = useSelector(state => state.appReducer || {});

    const { t, i18n } = useTranslation();
    const [detail, setDetail] = useState({});
    const [image, setImage] = useState('');
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        const initDetail = async () => {
            try {
                let id = search;
                if (id && id[0] === '?') {
                    id = id.substring(1);
                }
    
                const rs = await getTopDestinationById(id);
                if (rs && rs.success) {
                    setDetail(rs.data);
                    setEnabled(rs.data.enable);
    
                    let img = rs.data.image?.lowres?.url || rs.data.image?.url;
                    setImage(img);
                }
            } catch (e) {};
        };

        // init customer detail
        initDetail();
    }, []);

    const navigate = (route) => {
        props.history.push(route);
    };

    const onChangeEnable = async () => {
        let item = detail;
        item.enable = !enabled;
        const rs = await updateTopDestination(detail.id, item);
        if (rs && rs.success) {
            setEnabled(!enabled);
        } 
    }

    const onSaveChanges = async (data = {}) => {
        try {
            let itemUpdated = {
                name: data.name,
                description: data.description,
                to_airport_id: data.to_airport?.id
            }
            const rs = await updateTopDestination(detail?.id, itemUpdated);
            if (rs && rs.success) {
                itemUpdated = {
                    name: data.name,
                    description: data.description,
                    to_airport: data.to_airport
                }
                setDetail({ ...detail, ...itemUpdated });
                let modal = {
                    show: true,
                    title: t('UPDATE_TOP_DESTINATION_SUCCESS'),
                    buttonTitle: 'OK',
                };
                dispatch({ type: 'SETTING_SHOW_MODAL', data: modal });
            } else {
                let modal = {
                    show: true,
                    type: 'error',
                    title: t('UPDATE_TOP_DESTINATION_ERROR'),
                    buttonTitle: 'OK',
                };
                dispatch({ type: 'SETTING_SHOW_MODAL', data: modal });
            }
        } catch (e) {
            //
        }
    }

    const updateImage = async ({ file, fileList }) => {
        if (isLoading) return;
        dispatch({ type: SHOW_LOADING });

        let rs = await uploadImage(
            detail?.id,
            file,
            UPLOAD_MODEL.ROUTE,
            UPLOAD_IMAGE_TYPE.MAIN
        );
        if (rs && rs.success) {
            setImage(rs.url);
        }

        setTimeout(() => {
            dispatch({ type: HIDE_LOADING });
        }, 300);
    };

    const onDeleteDestination = async () => {
        let rs = await removeTopDestination(detail?.id);
        if (rs.success) {
            let modal = {
                show: true,
                title: t('DELETE_TOP_DESTINATION_SUCCESS'),
                buttonTitle: 'OK',
                onOk: FLIGHT_PROMOTION_LIST,
                onCancel: FLIGHT_PROMOTION_LIST
            };
            dispatch({ type: 'SETTING_SHOW_MODAL', data: modal });
        } else {
            let modal = {
                show: true,
                type: 'error',
                title: t('DELETE_TOP_DESTINATION_ERROR'),
                buttonTitle: 'OK',
            };
            dispatch({ type: 'SETTING_SHOW_MODAL', data: modal });
        }
    }

    const initProps = {
        detail: detail,
        image: image,
        enabled: enabled,
        navigate: navigate,
        onChangeEnable: onChangeEnable,
        onDeleteDestination: onDeleteDestination,
        onSaveChanges: onSaveChanges,
        updateImage: updateImage
    };

    return tmpl && tmpl({ ...initProps });
};

class TopDestinationDetailContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <TopDestinationDetailLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { userReducer, companyReducer } = state;
    const { activeCompany } = companyReducer;
    return {
        user: userReducer,
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTopDestination: (id, data) =>
            dispatch(updateTopDestination(id, data)),
        getTopDestinationById: (id) => dispatch(getTopDestinationById(id)),
        removeTopDestination: (id) => dispatch(removeTopDestination(id)),
        uploadImage: (id, photo, model, type) =>
            dispatch(uploadImage(id, photo, model, type))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(TopDestinationDetailContainer)
);
