import React, { useState } from 'react';
import { Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import ReactCropImage from './ReactCropImage';

const UploadWLImage = ({ children, ...uploadProps }) => {
    const {
        isCropImage = false,
        aspectRatio, // 16/9
        maxWidthCrop,
        onChange
    } = uploadProps;
    delete uploadProps.onChange;
    const { t, i18n } = useTranslation();
    const [visibleModalCrop, setVisibleModalCrop] = useState(false);
    const [uploadFile, setUploadFile] = useState({});

    const handleChangeImage = ({ file, fileList }) => {
        setVisibleModalCrop(false);
        onChange?.({ file, fileList });
    };

    const handleUploadChange = ({ file, fileList }) => {
        if (isCropImage) {
            setUploadFile({ file, fileList });
            setVisibleModalCrop(true);
        } else {
            handleChangeImage({ file, fileList });
        }
    };

    return (
        <>
            <Upload
                {...uploadProps}
                onChange={handleUploadChange}
            >
                {children}
            </Upload>
            {isCropImage && !isEmpty(uploadFile) &&
                <ReactCropImage
                    aspectRatio={aspectRatio}
                    uploadFile={uploadFile}
                    isVisible={visibleModalCrop}
                    maxWidthCrop={maxWidthCrop}
                    onOk={handleChangeImage}
                    onCancel={() => setVisibleModalCrop(false)}
                />
            }
        </>
    );
};

export default UploadWLImage;
