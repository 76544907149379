import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Row, Col } from 'antd';
import {
    Text,
    BookingStatusCard,
    ServiceItem,
    FlightNotes,
    Map,
    Map2,
    TotalPayout,
    EmptyComponent,
    PrimaryButton,
    SecondaryButton,
    PassengersInfo,
    ConnectFlight,
    CrewInfo,
    AircraftInfo,
    Tabs
} from 'components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { COMPANY_INVOICE_DETAIL } from 'routes/main';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Wrapper, BookingInfo } from '../styles';

const template = (props) => {
    const { expandedSidebar } = useSelector(
        (state) => state.settingReducer || false
    );
    const { t, i18n } = useTranslation();
    const [listOfAirports, setListOfAirports] = useState([]);
    const {
        bookingDetail = {},
        addCrew,
        removeCrew,
        onAddFBO,
        navigate,
        onConfirmBooking,
        activeCompany = {}
    } = props;
    const {
        id = '',
        user = {},
        status = '',
        invoice = {},
        is_operator = false,
        // operator = {},
        booking_code = '',
        payment_status = '',
        quote_request_id = '',
        quote_request_code = '',
        legs = [],
        company = {}
    } = bookingDetail;

    useEffect(() => {
        if (legs.length > 0) {
            const listPositions = legs.map((leg) => {
                const { from_airport = {}, to_airport = {} } = leg;
                return {
                    from_airport: from_airport,
                    to_airport: to_airport
                };
            });
            setListOfAirports(listPositions);
        }
    }, [legs]);

    const { firstname = '', lastname = '' } = user;

    const FilghtInfo = (leg_info) => {
        const {
            from_airport = {},
            to_airport = {},
            services = [],
            price_components,
            passenger_list = [],
            luggage = [],
            passengers = 0,
            flight = {},
            flight_id = ''
        } = leg_info;
        const {
            aircraft = {},
            crew = [],
            arrival = {},
            departure = {},
            distance = {},
            flight_code,
            flight_time_minutes,
            notes = [],
            operator = {}
        } = flight;

        const getServicesByType = (type = '') => {
            let list = services.filter((item) => item.type === type);
            return list;
        };

        return (
            <>
                <FlightInfoWrapper expandedSidebar={expandedSidebar}>
                    {/* flight info */}
                    <FlightStatusCardContainer>
                        <BookingStatusCard
                            bookingStatus={status} // booking status
                            flightId={flight_id}
                            flightCode={flight_code}
                            flightType={
                                bookingDetail.type
                                    ? t(bookingDetail.type).toUpperCase()
                                    : ''
                            }
                            requestCode={bookingDetail.request_code || ''}
                            status={bookingDetail.status || ''}
                            reqeustSendBy={firstname + ' ' + lastname}
                            from_airport={from_airport}
                            to_airport={to_airport}
                            is_operator={is_operator}
                            onAddFBO={onAddFBO}
                            arrival={arrival}
                            departure={departure}
                            aircraft={aircraft}
                            operator={operator}
                            booking_code={booking_code}
                            payment_status={payment_status}
                            flightTimeMinutes={flight_time_minutes}
                            quoteRequestId={quote_request_id}
                            quoteRequestCode={quote_request_code}
                            navigate={navigate}
                        />
                    </FlightStatusCardContainer>

                    {/* Connect flights */}
                    <Row style={{ marginTop: 40 }}>
                        <Container style={{ width: '100%' }}>
                            <Text textStyle="heading5">
                                {t('CONNECT_FLIGHT').toUpperCase()}
                            </Text>
                            <ConnectFlight />
                        </Container>
                    </Row>

                    {/* Crew Info and Aircraft Information */}
                    <Row>
                        <EachCol
                            className="booking-detail-crew-info"
                            style={{
                                // flex: 1 / 3,
                                marginBottom: 20,
                                marginTop: 40,
                                paddingRight: 10
                            }}
                        >
                            <CrewInfo
                                flight_id={flight_id}
                                addCrew={addCrew}
                                removeCrew={removeCrew}
                                crew={crew}
                                label={t('CREW_INFO').toUpperCase()}
                                bookingStatus={status}
                            />
                        </EachCol>
                        <EachCol
                            className="booking-detail-aircraft-info"
                            style={{
                                // flex: 2 / 3,
                                marginBottom: 20,
                                marginTop: 40
                            }}
                        >
                            <AircraftInfo
                                aircraft={aircraft}
                                label={t('AIRCRAFT_INFO').toUpperCase()}
                                navigate={navigate}
                            />
                        </EachCol>
                    </Row>

                    {/* Service items / Special Request / Flight Notes */}
                    <Row
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            flexDirection: 'row'
                        }}
                    >
                        <ServiceCol
                            style={{
                                marginTop: 40,
                                minWidth: 330
                            }}
                        >
                            <ServiceItem
                                services={getServicesByType('STANDARD')}
                                label={t('SERVICE_ITEMS').toUpperCase()}
                            />
                        </ServiceCol>
                        <CustomServiceCol
                            style={{
                                marginTop: 40,
                                minWidth: 330
                            }}
                        >
                            <ServiceItem
                                services={getServicesByType('CUSTOM')}
                                label={t('CUSTOM_SERVICE').toUpperCase()}
                            />
                        </CustomServiceCol>
                        <NoteCol
                            style={{
                                marginTop: 40,
                                minWidth: 330
                            }}
                        >
                            <FlightNotes
                                id={flight_id}
                                notes={notes}
                                label={t('FLIGHT_NOTES').toUpperCase()}
                                type="flight"
                            />
                        </NoteCol>
                    </Row>
                </FlightInfoWrapper>

                <FlightInfoWrapper>
                    <Row style={{ marginTop: 30 }}>
                        <Container style={{ width: '100%', marginTop: 30 }}>
                            <Text textStyle="heading5">
                                {t('PASSENGERS_INFO').toUpperCase()}
                            </Text>
                            <PassengersInfo
                                passengers={passenger_list}
                                passengerAmount={passengers}
                                luggage={luggage}
                                bookingStatus={status}
                            />
                        </Container>
                    </Row>
                </FlightInfoWrapper>
                {/* Hide the sale information */}
                {/* <FlightInfoWrapper>
                    <Row style={{ marginTop: 20 }}>
                        <Text textStyle="heading5">
                            {t('SALE_INFO').toUpperCase()}
                        </Text>
                        <EmptyComponent
                            description={t('NO_SALE_INFO').toUpperCase()}
                            iconSize={28}
                        />
                    </Row>
                </FlightInfoWrapper> */}

                <FlightInfoWrapper>
                    <TotalPayout price_components={price_components} />
                </FlightInfoWrapper>
            </>
        );
    };

    const renderReturn = (legs) => {
        const tabRenderer = [
            {
                key: 'DEPARTURE',
                label: t('DEPARTURE').toUpperCase(),
                leg: legs[0],
                render: FilghtInfo(legs[0])
            },
            {
                key: 'RETURN',
                label: t('RETURN').toUpperCase(),
                leg: legs[1],
                render: FilghtInfo(legs[1])
            }
        ];
        return <Tabs tabRenderer={tabRenderer} />;
    };

    const renderMultiLegs = (legs) => {
        const tabRenderer = legs.map((leg, index) => {
            return {
                key: 'LEG_' + index,
                label: 'LEG ' + (index + 1),
                leg: leg,
                render: FilghtInfo(leg)
            };
        });
        return <Tabs tabRenderer={tabRenderer} />;
    };

    const renderOperatorFlight = (legs) => {
        const tabRenderer = legs.map((leg = {}, index) => {
            const { flight_code = '' } = leg;
            return {
                key: 'LEG_' + index,
                label: 'FLIGHT #' + flight_code,
                leg: leg,
                render: FilghtInfo(leg)
            };
        });
        return <Tabs tabRenderer={tabRenderer} />;
    };

    const renderContent = () => {
        const { legs = [] } = bookingDetail;

        if (is_operator) {
            return renderOperatorFlight(legs);
        } else {
            switch (bookingDetail.type) {
                case 'ONEWAY':
                    return FilghtInfo(legs[0]);
                case 'RETURN':
                    return renderReturn(legs);
                case 'MULTI_LEG':
                    return renderMultiLegs(legs);
                default:
                    return;
            }
        }
    };

    return (
        <Container>
            <Map2 listOfAirports={listOfAirports} type={bookingDetail.type} />

            {/* Card Flight Info */}
            <BookingInfo style={{ paddingBottom: 10 }}>
                {renderContent()}
            </BookingInfo>

            <Wrapper style={{ paddingTop: 0 }}>
                {!_.isEmpty(bookingDetail) && (
                    <Wrappper>
                        {status === 'AWAITING_CONFIRMATION' &&
                            activeCompany?.id === company?.id && (
                                <div style={{ marginRight: 25 }}>
                                    <PrimaryButton
                                        onClick={() =>
                                            onConfirmBooking(
                                                id,
                                                new Date(),
                                                false
                                            )
                                        }
                                        size="large"
                                        title={t(
                                            'CONFIRM_BOOKING'
                                        ).toUpperCase()}
                                    />
                                </div>
                            )}
                        {!_.isEmpty(invoice) && (
                            <SecondaryButton
                                onClick={() =>
                                    navigate(
                                        COMPANY_INVOICE_DETAIL,
                                        invoice?.id
                                    )
                                }
                                size="large"
                                title={t('VIEW_INVOICE').toUpperCase()}
                            />
                        )}
                    </Wrappper>
                )}
            </Wrapper>
        </Container>
    );
};

export { template };

const ServiceCol = styled(Col)`
    flex: 1 0 30%;
    margin: 0 30px 0 0;
    /* @media (max-width: 768px) {
        margin-right: 0;
    } */
`;

const CustomServiceCol = styled(Col)`
    flex: 1 0 30%;
    margin: 0 30px 0 0;
    @media (max-width: 1024px) {
        margin-right: 0;
    }
`;

const NoteCol = styled(Col)`
    flex: 1 0 30%;
    margin: 0 0 0 0;
`;

const Container = styled.div``;

const Wrappper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
`;

const EachCol = styled(Col)`
    /* padding-right: 10px; */
    flex: 1;
`;

const FlightStatusCardContainer = styled.div`
    padding-top: 20px;
`;

const FlightInfoWrapper = styled.div`
    .booking-detail-crew-info {
        flex: 0.333333 1 0%;
        min-width: 300px;
    }

    .booking-detail-aircraft-info {
        flex: 0.666667 1 0%;
    }
    @media (max-width: 1024px) {
        .booking-detail-crew-info {
            flex: 1;
        }

        .booking-detail-aircraft-info {
            flex: 1;
        }
        .crew-info {
            ${(props) =>
                props.expandedSidebar && 'padding-right: 0 !important'};
        }
    }
`;

// const Wrapper = styled.div`
//     padding-left: 40px;
//     padding-right: 40px;
//     padding-bottom: 40px;
// `;
