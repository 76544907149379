import React from 'react';
import styled from 'styled-components';
import background from 'assets/images/default_background.png';
import 'antd/dist/antd.css';
import { Layout, Tabs, Col, Row, Avatar } from 'antd';
import { useTranslation } from 'react-i18next';
import {
    BackgroundProfile,
    ProfileInfo,
    TabBarNavigator,
    AboutWrapper,
    AboutContent,
    AboutText,
    MenuTitle,
    TabText,
    Title,
    TitleDescription,
    Name,
    Comapy,
    AvatarWrapper,
    PhotoWrapper,
    Photo
} from '../styles';
import { renderAddress, formatHttpStr } from 'utils';
import {
    aboutIcon,
    timelineIcon,
    aircraftListingIcon,
    flightPostIcon,
    fleetsIcon,
    callIcon,
    emailIcon,
    imageIcon,
    followwingIcon
} from 'assets/images/icons';
import { ButtonCustom, EmptyComponent } from 'components';
import { ButtonType } from 'components/Antd/Button';
import { PartnershipFleet } from 'views/PartnershipFleet';

const TabItems = ({ title, icon }) => {
    return (
        <span style={{ paddingLeft: 25, paddingRight: 25 }}>
            <Icon src={icon} />
            <TabText>{title}</TabText>
        </span>
    );
};

const { TabPane } = Tabs;

const template = (props) => {
    const { navigate, user = {}, partnerDetail = {} } = props;
    const {
        name = '',
        image = '',
        backgroundImage = '',
        images = [],
        location = {},
        airport = {},
        company = {},
        contact = {},
        description = '',
        id,
        employees = [],
        products = [],
        services = [],
        show,
        type = ''
    } = partnerDetail;
    const { t, i18n } = useTranslation();

    const _renderTabAbout = () => {
        return (
            <AboutWrapper>
                <MenuTitle>{i18n.t('ABOUT_US').toUpperCase()}</MenuTitle>
                {description ? (
                    <AboutContent>
                        <AboutText>{description}</AboutText>
                    </AboutContent>
                ) : (
                    <EmptyComponent />
                )}

                <MenuTitle style={{ marginTop: 35 }}>{`${i18n
                    .t('OUR_TEAM')
                    .toUpperCase()} (${employees.length})`}</MenuTitle>
                {employees && employees.length > 0 ? (
                    <PhotoWrapper>
                        <Row>
                            {employees.map((v, i) => (
                                <Col xxl={6} xl={8} lg={12} md={12} sm={18} xs={24} key={i}>
                                    <TeamWrapper>
                                        <Col style={{ alignSelf: 'center', marginRight: '15px' }}>
                                            {v.image ? (
                                                <Avatar
                                                    className="ant-avatar-custom"
                                                    src={v.image}
                                                    size={86}
                                                />
                                            ) : (
                                                <Avatar
                                                    className="ant-avatar-custom"
                                                    size={86}
                                                >
                                                    {(
                                                        v.firstname.charAt(0) +
                                                        v.lastname.charAt(0)
                                                    ).toUpperCase()}
                                                </Avatar>
                                            )}
                                        </Col>
                                        <Col style={{ alignSelf: 'center' }}>
                                            <div>
                                                <TeamName>
                                                    {v.name
                                                        ? v.name.toUpperCase()
                                                        : '_'}
                                                </TeamName>
                                            </div>
                                            <div>
                                                <TeamRole>{v.role}</TeamRole>
                                            </div>
                                            <Wrapper>
                                                <Icon
                                                    style={{
                                                        width: 12,
                                                        height: 12,
                                                        marginLeft: 0
                                                    }}
                                                    src={callIcon}
                                                />
                                                <TeamItem>{v.phone}</TeamItem>
                                            </Wrapper>
                                            <Wrapper>
                                                <Icon
                                                    style={{
                                                        width: 12,
                                                        height: 12,
                                                        marginLeft: 0
                                                    }}
                                                    src={emailIcon}
                                                />
                                                <TeamItem>{v.email}</TeamItem>
                                            </Wrapper>
                                        </Col>
                                    </TeamWrapper>
                                </Col>
                            ))}
                        </Row>
                    </PhotoWrapper>
                ) : (
                    <EmptyComponent />
                )}

                <MenuTitle style={{ marginTop: 35 }}>{`${i18n
                    .t('PHOTOS')
                    .toUpperCase()} (${images.length})`}</MenuTitle>
                {images && images.length > 0 ? (
                    <PhotoWrapper>
                        <Row>
                            {images.map((v, i) => (
                                <Col xxl={6} xl={8} lg={12} md={12} sm={18} xs={24} key={i}>
                                    <Photo url={v?.lowres?.url} />
                                </Col>
                            ))}
                        </Row>
                    </PhotoWrapper>
                ) : (
                    <EmptyComponent />
                )}
            </AboutWrapper>
        );
    };

    const _renderProfile = () => {
        return (
            <ProfileInfo>
                <Row style={{ width: '100%' }}>
                    <Col xl={4} lg={10} md={10} sm={10} xs={20} style={{ marginRight: '20px', paddingTop: '20px' }}>
                        <Title style={{ marginBottom: 10 }}>
                            {i18n.t('ADDRESS')}
                        </Title>
                        <TitleDescription>
                            {renderAddress(location).toUpperCase()}
                        </TitleDescription>
                    </Col>
                    <Col xl={4} lg={10} md={10} sm={10} xs={20} style={{ marginRight: '20px', paddingTop: '20px' }}>
                        <Title style={{ marginBottom: 10 }}>
                            {i18n.t('PHONE_NUMBER')}
                        </Title>
                        <TitleDescription>
                            {contact && contact.phone
                                ? contact.phone.toUpperCase()
                                : '_'}
                        </TitleDescription>
                    </Col>
                    <Col xl={4} lg={10} md={10} sm={10} xs={20} style={{ marginRight: '20px', paddingTop: '20px' }}>
                        <Title style={{ marginBottom: 10 }}>
                            {i18n.t('EMAIL_ADDRESS')}
                        </Title>
                        <TitleDescription>
                            {contact && contact.email
                                ? contact.email.toUpperCase()
                                : '_'}
                        </TitleDescription>
                    </Col>
                    <Col xl={4} lg={10} md={10} sm={10} xs={20} style={{ marginRight: '20px', paddingTop: '20px' }}>
                        <Title style={{ marginBottom: 10 }}>
                            {i18n.t('COMPANY_WEBSITE')}
                        </Title>
                        <TabLink
                            rel="noopener noreferrer"
                            href={contact.website}
                            target="_blank"
                        >
                            <TitleDescription color="#19C0FF">
                                {contact &&
                                    contact.website &&
                                    formatHttpStr(contact.website).toUpperCase()}
                            </TitleDescription>
                        </TabLink>
                    </Col>
                </Row>
            </ProfileInfo>
        );
    };

    const _renderInformation = () => {
        return (
            <>
                <BackgroundProfile background={backgroundImage || background}>
                    <div className="overlay-background">
                        <AvatarWrapper>
                            <Row>
                                <Col>
                                    {image ? (
                                        <Avatar
                                            className="ant-avatar-custom avatar-item"
                                            src={image}
                                            size={152}
                                        />
                                    ) : (
                                        <Avatar
                                            className="ant-avatar-custom avatar-item"
                                            size={152}
                                            style={{
                                                backgroundColor: '#F0F3F9'
                                            }}
                                        >
                                            <Icon
                                                src={imageIcon}
                                                style={{
                                                    width: 32,
                                                    height: 28
                                                }}
                                            />
                                        </Avatar>
                                    )}
                                </Col>
                                <Col className="fbo-display-name">
                                    <Wrapper>
                                        <Name color="#FFFFFF">{`${name.toUpperCase()}`}</Name>
                                        <FollowingWrapper
                                            style={{ marginLeft: 25 }}
                                        >
                                            <Icon
                                                style={{
                                                    width: 32,
                                                    height: 18
                                                }}
                                                src={followwingIcon}
                                            />
                                            <FollowText>
                                                {t('FOLLOWING').toUpperCase()}
                                            </FollowText>
                                        </FollowingWrapper>
                                    </Wrapper>
                                    <div>
                                        <Comapy>
                                            {type.toUpperCase() || '_'}
                                        </Comapy>
                                    </div>
                                </Col>
                            </Row>
                        </AvatarWrapper>
                    </div>

                    <ButtonWrapper>
                        <div>
                            <ButtonCustom
                                size="small"
                                width="165px"
                                btnType={ButtonType.PRIMARY}
                                onClick={() => {}}
                                title={t('SEND_MESSAGE').toUpperCase()}
                            />
                        </div>
                        <div className="btn-wrapper">
                            <ButtonCustom
                                btnType={ButtonType.EDIT_BTN}
                                width="165px"
                                onClick={() => {}}
                                title={t('CANCEL_PARTNERSHIP').toUpperCase()}
                            />
                        </div>
                    </ButtonWrapper>
                </BackgroundProfile>
                {_renderProfile()}
            </>
        );
    };

    const _renderTabs = () => {
        return (
            <TabBarNavigator>
                <Tabs
                    defaultActiveKey="ABOUT"
                    className="partner-profile-tabs"
                    style={{ width: '100%' }}
                >
                    <TabPane
                        key="ABOUT"
                        tab={
                            <TabItems
                                icon={aboutIcon}
                                title={i18n.t('ABOUT').toUpperCase()}
                            />
                        }
                    >
                        {_renderTabAbout()}
                    </TabPane>
                    <TabPane
                        key="TIMELINE"
                        tab={
                            <TabItems
                                icon={timelineIcon}
                                title={i18n.t('TIMELINE').toUpperCase()}
                            />
                        }
                    >
                        <EmptyComponent description={t('NO_DATA')} />
                    </TabPane>
                    <TabPane
                        key="FLIGHT_POSTED"
                        tab={
                            <TabItems
                                icon={flightPostIcon}
                                title={i18n.t('FLIGHT_POSTED').toUpperCase()}
                            />
                        }
                    >
                        <EmptyComponent description={t('NO_DATA')} />
                    </TabPane>
                    <TabPane
                        key="AIRCRAFT_LISTING"
                        tab={
                            <TabItems
                                icon={aircraftListingIcon}
                                title={i18n.t('AIRCRAFT_LISTING').toUpperCase()}
                            />
                        }
                    >
                        <EmptyComponent description={t('NO_DATA')} />
                    </TabPane>
                    {type === 'OPERATOR' && (
                        <TabPane
                            key="FLEET"
                            tab={
                                <TabItems
                                    icon={fleetsIcon}
                                    title={i18n.t('FLEET').toUpperCase()}
                                />
                            }
                        >
                            {/* <EmptyComponent description={t('NO_DATA')} /> */}
                            <PartnershipFleet 
                                isView={true}
                                partnerId={id}
                            />
                        </TabPane>
                    )}
                </Tabs>
            </TabBarNavigator>
        );
    };

    return (
        <Layout style={{ backgroundColor: 'transperent' }}>
            {_renderInformation()}
            {_renderTabs()}
        </Layout>
    );
};

export { template };

const TabLink = styled.a``;

const Icon = styled.img`
    margin: 5px;
`;

const ButtonWrapper = styled.div`
    min-width: 165px;
    padding-bottom: 25px;
    position: absolute;
    right: 25px;
`;

const TeamWrapper = styled.div`
    background-color: #ffffff;
    border-radius: 4px;
    min-width: 320px;
    min-height: 128px;
    display: flex;
    justify-content: center;
    align-self: center;
    margin-right: 20px;
    margin-top: 20px;
`;

const TeamName = styled.span`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.8px;
    line-height: 12px;
    min-width: 120x;
    text-align: left;
`;

const TeamRole = styled.span`
    color: #19c0ff;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    line-height: 13px;
    width: 199px;
    text-align: left;
`;

const TeamItem = styled.span`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 11px;
    line-height: 14px;
    width: 180px;
    text-align: left;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FollowingWrapper = styled.div`
    display: flex;
    background-color: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 2px;
    width: 116px;
    height: 32px;
    justify-content: center;
    align-items: center;
`;

const FollowText = styled.span`
    color: #19c0ff;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: center;
    margin-right: 10px;
`;
