import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import {
    imageIcon_2,
    dollarIcon
} from '../../../assets/images/icons';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
    AutoComplete,
    Header,
    Popup,
    Text,
    DropDownInput,
    UploadComponent
} from '../../../components';
import { Input } from '../../../components';
import { Input as AntdInput, List, Row as AntDRow } from 'antd';
const { TextArea } = AntdInput;
import 'antd/dist/antd.css';
import { HeaderInfo, InputText } from '../../../components/Cards';

import { Button } from '../../../components';
import { SearchAirports } from '../../../components/Search';
import { AIRCRAFT_DETAIL } from '../../../routes/main';
import { aircraftCategory } from '../../../config/Constants';
import {
    Container,
    ContainerWrapper,
    GeneralSection,
    GeneralInformation,
    FirstRow,
    Row,
    AircraftCategory,
    DescriptionRow,
    PropertySection,
    UploadInformation,
    BottomContainer,
    AirportSection,
    ListPhotosSection,
    ImageContainer,
    EachImage,
    DeletePhoto,
    ListPhotosUpload,
    ImageAdded,
    ImageContainerAdded
} from '../style';

const template = (props) => {
    const { t, i18n } = useTranslation();
    const {
        id,
        buttonValid = false,
        loading = false,
        visibleModal = false,
        successMsg = '',
        onSearchGenericAircraft,
        onSelectAircraft,
        setVisibleModal,
        onDeletePhoto,
        tempImages,
        activeCompany,
        onSave,
        handleChange,
        imageUrl,
        aircraft,
        onClickEdit,
        isEdit,
        setIsEdit,
        images,
        description,
        home_airport,
        current_airport,
        price_reflection_airport,
        crew,
        max_passengers,
        lavatories,
        height_ft,
        length_ft,
        wingspan_ft,
        wing_area_ft2,
        empty_weight_lb,
        max_takeoff_weight_lb,
        luggage_capacity_ft3,
        max_range_in_nautical_miles,
        average_speed_mph,
        setDescription,
        setCrew,
        setMaxPassenger,
        setLavatories,
        setMax_range_in_nautical_miles,

        cruising_altitude_ft,
        cruising_speed_in_knots,
        climb_rate_fpm,
        descent_rate_fpm,
        climb_speed_in_knots,
        descent_speed_in_knots,
        takeoff_distance_ft,
        landing_distance_ft,

        setCruising_altitude_ft,
        setCruising_speed_in_knots,
        setClimb_rate_fpm,
        setDescent_rate_fpm,
        setClimb_speed_in_knots,
        setDescent_speed_in_knots,
        setTakeoff_distance_ft,
        setLanding_distance_ft,
        setHeight_ft,
        setLength_ft,
        setWingspan_ft,
        setWing_area_ft2,
        setEmpty_weight_lb,
        setMax_takeoff_weight_lb,
        setLuggage_capacity_ft3,
        setAverage_speed_mph,
        tax_percentage,
        minimum_booking_time_hours,
        positioning_flight_per_hour_fee,
        revenue_flight_per_hour_fee,
        set_tax_percentage,
        set_minimum_booking_time_hours,
        set_positioning_flight_per_hour_fee,
        set_revenue_flight_per_hour_fee,

        overnight_stay_fee,

        set_overnight_stay_fee,

        onSelectHomeAirport,
        onSelectCurrentAirport,
        onSelectPriceReflectionAirport,
        onCancelEdit,
        navigate,
        name,
        setName,
        tail_number,
        set_tail_number,
        operator_name,
        set_operator_name,
        classtype,
        set_classtype,
        manufacturer,
        set_manufacturer,
        year_of_manufacture,
        set_year_of_manufacture,
        model,
        setModel,
        last_refurbished_at,
        set_last_refurbished_at,
        buttonClicked
    } = props;

    const createCategoryOption = () => {
        let tempArr = [];
        let key;
        for (key in aircraftCategory) {
            if (aircraftCategory[key] !== aircraftCategory.ALL) {
                tempArr.push({
                    text: t(aircraftCategory[key]),
                    value: aircraftCategory[key]
                });
            }
        }
        return tempArr;
    };

    const checkValidField = (value) => {
        if (buttonClicked) {
            if (value) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    return (
        <>
            <Container>
                <Header title={t('FLEET')} description={t('ADD_AIRCRAFT')} />
                <ContainerWrapper>
                    <GeneralSection>
                        <HeaderInfo
                            title={i18n.t('GENERAL_INFO').toUpperCase()}
                        />
                        <GeneralInformation>
                            <FirstRow>
                                <Text textStyle="fieldLabel">
                                    {t('GENERIC_AIRCRAFT').toUpperCase()}
                                </Text>
                                <Text
                                    textStyle="tableText1"
                                    style={{
                                        marginTop: '10px',
                                        marginBottom: '15px'
                                    }}
                                >
                                    {t('GENERIC_DESCRIPTION')}
                                </Text>
                                <AutoComplete
                                    inputStyle={{
                                        width: '770px',
                                        height: '50px'
                                    }}
                                    placeholder={t('SELECT_GENERIC_AIRCRAFT')}
                                    onSearchItems={onSearchGenericAircraft}
                                    onSelectItem={onSelectAircraft}
                                />
                            </FirstRow>
                            <Row>
                                <Input
                                    label={t('AIRCRAFT_NAME').toUpperCase()}
                                    placeholder={t('ENTER_AIRCRAFT_NAME')}
                                    width={'370px'}
                                    value={model}
                                    onChange={setModel}
                                    isError={checkValidField(model)}
                                />
                                <Input
                                    label={t('TAIL_NUMBER').toUpperCase()}
                                    placeholder={t('ENTER_TAIL_NUMBER')}
                                    containerStyle={{ marginLeft: '30px' }}
                                    width={'370px'}
                                    value={tail_number}
                                    onChange={set_tail_number}
                                    isError={checkValidField(tail_number)}
                                />
                                <Input
                                    label={t('OPERATED_BY').toUpperCase()}
                                    placeholder={t('ENTER_OPERATOR_NAME')}
                                    containerStyle={{ marginLeft: '30px' }}
                                    width={'370px'}
                                    value={operator_name}
                                    onChange={set_operator_name}
                                    isError={checkValidField(operator_name)}
                                />
                            </Row>
                            <Row>
                                <AircraftCategory>
                                    <Text
                                        textStyle={'fieldLabel'}
                                        style={{ marginBottom: '10px' }}
                                    >
                                        {t('AIRCRAFT_CATEGORY').toUpperCase()}
                                    </Text>
                                    <DropDownInput
                                        width="370px"
                                        placeholder={t(
                                            'SELECT_AIRCRAFT_CATEGORY'
                                        )}
                                        data={createCategoryOption()}
                                        onChange={(value) => {
                                            set_classtype(value);
                                        }}
                                        pickedData={classtype}
                                    />
                                </AircraftCategory>

                                <AirportSection>
                                    <Text
                                        textStyle="fieldLabel"
                                        style={{ marginBottom: 10 }}
                                    >
                                        {t('HOME_AIRPORT').toUpperCase()}
                                    </Text>
                                    <SearchAirports
                                        airport={home_airport}
                                        width={370}
                                        onSelectAirport={onSelectHomeAirport}
                                        // defaultValue={home_airport}
                                    />
                                </AirportSection>

                                <AirportSection>
                                    <Text
                                        textStyle="fieldLabel"
                                        style={{ marginBottom: 10 }}
                                    >
                                        {t(
                                            'PRICE_REFLECTION_AIRPORT'
                                        ).toUpperCase()}
                                    </Text>
                                    <SearchAirports
                                        airport={price_reflection_airport}
                                        width={370}
                                        onSelectAirport={
                                            onSelectPriceReflectionAirport
                                        }
                                        // defaultValue={home_airport}
                                    />
                                </AirportSection>
                            </Row>
                            <Row>
                                <Input
                                    label={t('MANUFACTURER').toUpperCase()}
                                    placeholder={t('NAME_OF_MANUFACTURER')}
                                    width={'370px'}
                                    value={manufacturer}
                                    onChange={set_manufacturer}
                                    isError={checkValidField(manufacturer)}
                                />
                                <Input
                                    label={t(
                                        'YEAR_OF_MANUFACTURE'
                                    ).toUpperCase()}
                                    placeholder={t('YEAR')}
                                    containerStyle={{ marginLeft: '30px' }}
                                    width={'370px'}
                                    value={year_of_manufacture}
                                    onChange={set_year_of_manufacture}
                                    optional={true}
                                    // isNumber={true}
                                />
                                <Input
                                    label={t('LAST_REFURBISHED').toUpperCase()}
                                    placeholder={t('DATE')}
                                    containerStyle={{ marginLeft: '30px' }}
                                    width={'370px'}
                                    value={last_refurbished_at}
                                    onChange={set_last_refurbished_at}
                                    optional={true}
                                    isNumber={true}
                                />
                            </Row>
                            <Row>
                                <Input
                                    label={t('CREW').toUpperCase()}
                                    placeholder={t('AMOUNT')}
                                    width={'370px'}
                                    value={crew}
                                    onChange={setCrew}
                                    isNumber={true}
                                    isError={checkValidField(crew)}
                                />
                                <Input
                                    label={t('MAX_PASSENGERS').toUpperCase()}
                                    placeholder={t('AMOUNT')}
                                    containerStyle={{ marginLeft: '30px' }}
                                    width={'370px'}
                                    value={max_passengers}
                                    onChange={setMaxPassenger}
                                    isNumber={true}
                                    isError={checkValidField(max_passengers)}
                                />
                                <Input
                                    label={t('LAVATORIES').toUpperCase()}
                                    placeholder={t('AMOUNT')}
                                    containerStyle={{ marginLeft: '30px' }}
                                    width={'370px'}
                                    value={lavatories}
                                    onChange={setLavatories}
                                    optional={true}
                                    isNumber={true}
                                />
                            </Row>
                            <DescriptionRow>
                                <Text textStyle={'fieldLabel'}>
                                    {t('DESCRIPTION').toUpperCase()}
                                </Text>
                                <TextArea
                                    onChange={(val) => {
                                        setDescription(val.target.value);
                                    }}
                                    style={{
                                        marginTop: '13px',
                                        maxWidth: ' 1170px'
                                    }}
                                    rows={4}
                                    // style={{ margin: 10 }}
                                    placeholder={i18n.t('DESCRIBE_AIRCRAFT')}
                                    value={description}
                                />
                                {/* <TextArea
                                    onChange={(val) => {
                                        setDescription(val);
                                    }}
                                    style={{
                                        marginTop: '13px',
                                        maxWidth: ' 1170px'
                                    }}
                                    numberOfRow={4}
                                    // style={{ margin: 10 }}
                                    placeholder={i18n.t('DESCRIBE_AIRCRAFT')}
                                    value={description}
                                /> */}
                            </DescriptionRow>
                        </GeneralInformation>
                    </GeneralSection>
                    <PropertySection>
                        <HeaderInfo
                            title={i18n
                                .t('UPLOAD_AIRCRAFT_PHOTOS')
                                .toUpperCase()}
                            icon={<img src={imageIcon_2} />}
                        />
                        <UploadInformation>
                            <Text>{t('UPLOAD_FORMAT')}</Text>
                            <ListPhotosUpload>
                                <AntDRow>
                                    <UploadComponent
                                        // multiple={true}
                                        uploadMorePhotos={true}
                                        imageUrl={imageUrl}
                                        handleChange={handleChange}
                                    />
                                    {tempImages.map((item, index) => {
                                        return (
                                            <ImageContainerAdded key={index}>
                                                <ImageAdded src={item.url} />
                                                <DeletePhoto
                                                    style={{
                                                        marginLeft: '215px'
                                                    }}
                                                    onClick={() =>
                                                        onDeletePhoto(item)
                                                    }
                                                >
                                                    <CloseOutlined
                                                        style={{
                                                            color: '#7C7C7C',
                                                            fontSize: '10px',
                                                            fontWeight: 'bolder'
                                                        }}
                                                    />
                                                </DeletePhoto>
                                            </ImageContainerAdded>
                                        );
                                    })}
                                </AntDRow>
                            </ListPhotosUpload>
                        </UploadInformation>
                    </PropertySection>
                    <PropertySection>
                        <HeaderInfo
                            title={i18n.t('AIRCRAFT_PROPERTIES').toUpperCase()}
                        />
                        <GeneralInformation>
                            <Row>
                                <Input
                                    label={t('HEIGHT').toUpperCase()}
                                    width={'270px'}
                                    value={height_ft}
                                    onChange={setHeight_ft}
                                    isNumber={true}
                                    isError={checkValidField(height_ft)}
                                />
                                <Input
                                    label={t('LENGTH').toUpperCase()}
                                    containerStyle={{ marginLeft: '30px' }}
                                    width={'270px'}
                                    value={length_ft}
                                    onChange={setLength_ft}
                                    isNumber={true}
                                    isError={checkValidField(length_ft)}
                                />
                            </Row>
                            <Row>
                                <Input
                                    label={t('WINGSPAN').toUpperCase()}
                                    width={'270px'}
                                    value={wingspan_ft}
                                    onChange={setWingspan_ft}
                                    isNumber={true}
                                    isError={checkValidField(wingspan_ft)}
                                />
                                <Input
                                    label={t('WING_AREA').toUpperCase()}
                                    containerStyle={{ marginLeft: '30px' }}
                                    width={'270px'}
                                    value={wing_area_ft2}
                                    onChange={setWing_area_ft2}
                                    isNumber={true}
                                    isError={checkValidField(wing_area_ft2)}
                                />
                            </Row>
                            <Row>
                                <Input
                                    label={t('EMPTY_WEIGHT').toUpperCase()}
                                    width={'270px'}
                                    value={empty_weight_lb}
                                    onChange={setEmpty_weight_lb}
                                    isNumber={true}
                                    isError={checkValidField(empty_weight_lb)}
                                />
                                <Input
                                    label={t(
                                        'MAX_TAKEOFF_WEIGHT'
                                    ).toUpperCase()}
                                    containerStyle={{ marginLeft: '30px' }}
                                    width={'270px'}
                                    value={max_takeoff_weight_lb}
                                    onChange={setMax_takeoff_weight_lb}
                                    isNumber={true}
                                    isError={checkValidField(
                                        max_takeoff_weight_lb
                                    )}
                                />
                                <Input
                                    label={t(
                                        'LUGGAGE_CAPACITY_3'
                                    ).toUpperCase()}
                                    containerStyle={{ marginLeft: '30px' }}
                                    width={'270px'}
                                    value={luggage_capacity_ft3}
                                    onChange={setLuggage_capacity_ft3}
                                    isNumber={true}
                                    isError={checkValidField(
                                        luggage_capacity_ft3
                                    )}
                                />
                            </Row>
                        </GeneralInformation>
                    </PropertySection>
                    <PropertySection>
                        <HeaderInfo
                            title={i18n
                                .t('PERFORMANCE_PROPERTIES')
                                .toUpperCase()}
                        />
                        <GeneralInformation>
                            <Row>
                                <Input
                                    label={t('TAKEOFF_DISTANCE').toUpperCase()}
                                    width={'270px'}
                                    value={takeoff_distance_ft}
                                    onChange={setTakeoff_distance_ft}
                                    isNumber={true}
                                    isError={checkValidField(
                                        takeoff_distance_ft
                                    )}
                                />
                                <Input
                                    label={t('LANDING_DISTANCE').toUpperCase()}
                                    containerStyle={{ marginLeft: '30px' }}
                                    width={'270px'}
                                    value={landing_distance_ft}
                                    onChange={setLanding_distance_ft}
                                    isNumber={true}
                                    isError={checkValidField(
                                        landing_distance_ft
                                    )}
                                />
                            </Row>
                            <Row>
                                <Input
                                    label={t(
                                        'MAX_RANGE_IN_NAUTICAL_MILES'
                                    ).toUpperCase()}
                                    width={'270px'}
                                    value={max_range_in_nautical_miles}
                                    onChange={setMax_range_in_nautical_miles}
                                    isNumber={true}
                                    isError={checkValidField(
                                        max_range_in_nautical_miles
                                    )}
                                />
                                <Input
                                    label={t('AVERAGE_SPEED').toUpperCase()}
                                    containerStyle={{ marginLeft: '30px' }}
                                    width={'270px'}
                                    value={average_speed_mph}
                                    onChange={setAverage_speed_mph}
                                    isNumber={true}
                                    isError={checkValidField(average_speed_mph)}
                                />
                                <Input
                                    label={t(
                                        'CRUISING_SPEED_IN_KNOTS'
                                    ).toUpperCase()}
                                    containerStyle={{ marginLeft: '30px' }}
                                    width={'270px'}
                                    value={cruising_speed_in_knots}
                                    onChange={setCruising_speed_in_knots}
                                    isNumber={true}
                                    isError={checkValidField(
                                        cruising_speed_in_knots
                                    )}
                                />
                                <Input
                                    label={t('CRUISING_ALTITUDE').toUpperCase()}
                                    containerStyle={{ marginLeft: '30px' }}
                                    width={'270px'}
                                    value={cruising_altitude_ft}
                                    onChange={setCruising_altitude_ft}
                                    isNumber={true}
                                    isError={checkValidField(
                                        cruising_altitude_ft
                                    )}
                                />
                            </Row>
                            <Row>
                                <Input
                                    label={t('CLIMB_RATE').toUpperCase()}
                                    width={'270px'}
                                    value={climb_rate_fpm}
                                    onChange={setClimb_rate_fpm}
                                    isNumber={true}
                                    isError={checkValidField(climb_rate_fpm)}
                                />
                                <Input
                                    label={t('DESCENT_RATE').toUpperCase()}
                                    containerStyle={{ marginLeft: '30px' }}
                                    width={'270px'}
                                    value={descent_rate_fpm}
                                    onChange={setDescent_rate_fpm}
                                    isNumber={true}
                                    isError={checkValidField(descent_rate_fpm)}
                                />
                                <Input
                                    label={t(
                                        'CLIMB_SPEED_IN_KNOTS'
                                    ).toUpperCase()}
                                    containerStyle={{ marginLeft: '30px' }}
                                    width={'270px'}
                                    value={climb_speed_in_knots}
                                    onChange={setClimb_speed_in_knots}
                                    isNumber={true}
                                    isError={checkValidField(
                                        climb_speed_in_knots
                                    )}
                                />
                                <Input
                                    label={t(
                                        'DESCENT_SPEED_IN_KNOTS'
                                    ).toUpperCase()}
                                    containerStyle={{ marginLeft: '30px' }}
                                    width={'270px'}
                                    value={descent_speed_in_knots}
                                    onChange={setDescent_speed_in_knots}
                                    isNumber={true}
                                    isError={checkValidField(
                                        descent_speed_in_knots
                                    )}
                                />
                            </Row>
                        </GeneralInformation>
                    </PropertySection>
                    <PropertySection>
                        <HeaderInfo
                            title={i18n.t('PRICING').toUpperCase()}
                            icon={<img src={dollarIcon} />}
                        />
                        <GeneralInformation>
                            <Row>
                                <Input
                                    label={t(
                                        'HOURLY_RATES_DOLLAR'
                                    ).toUpperCase()}
                                    width={'270px'}
                                    value={revenue_flight_per_hour_fee}
                                    onChange={set_revenue_flight_per_hour_fee}
                                    isNumber={true}
                                    isError={checkValidField(
                                        revenue_flight_per_hour_fee
                                    )}
                                />
                                <Input
                                    label={t(
                                        'OVERNIGHT_FEES_DOLLAR'
                                    ).toUpperCase()}
                                    containerStyle={{ marginLeft: '30px' }}
                                    width={'270px'}
                                    value={overnight_stay_fee}
                                    onChange={set_overnight_stay_fee}
                                    isNumber={true}
                                    isError={checkValidField(
                                        overnight_stay_fee
                                    )}
                                />
                                <Input
                                    label={t(
                                        'DAILY_MINIMUM_HOURS'
                                    ).toUpperCase()}
                                    containerStyle={{ marginLeft: '30px' }}
                                    width={'270px'}
                                    value={minimum_booking_time_hours}
                                    onChange={set_minimum_booking_time_hours}
                                    isNumber={true}
                                    isError={checkValidField(
                                        minimum_booking_time_hours
                                    )}
                                />
                            </Row>
                        </GeneralInformation>
                    </PropertySection>
                </ContainerWrapper>
                <BottomContainer>
                    <Button
                        backgroundColor={buttonValid ? '#19C0FF' : '#8e929a'}
                        textColor={buttonValid ? '#FFFFFF' : '#F2F4F7'}
                        width={'240px'}
                        height={'50px'}
                        fontSize={'13px'}
                        borderColor={'none'}
                        title={t('SUBMIT_AIRCRAFT_INFO').toUpperCase()}
                        onClick={() => props.submit()}
                        loading={loading}
                    />
                </BottomContainer>
                <Popup
                    cancelModal={() => {
                        props.cancelModal();
                    }}
                    onOk={() => {
                        navigate(AIRCRAFT_DETAIL, id, null);
                    }}
                    visibleModal={visibleModal}
                    successText={successMsg || ''}
                    successSecondLine={t('NEW_AIRCRAFT_ADDED')}
                    buttonTitle={t('VIEW_AIRCRAFT_DETAILS').toUpperCase()}
                />
            </Container>
        </>
    );
};

export { template };
