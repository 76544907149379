import React from 'react';
import styled from 'styled-components';
import { Calendar, Badge } from 'antd';
import { useTranslation } from 'react-i18next';
import { Text } from '../../index';
import 'antd/dist/antd.css';

/**
 *
 * @param {*} props
 * @returns
 */
const CalendarComponent = (props) => {
    const { width, height, label, onChange, textStyle, checked } = props;
    function getListData(value) {
        let listData;
        switch (value.date()) {
            case 8:
                listData = [
                    { type: 'warning', content: 'This is warning event.' },
                    { type: 'success', content: 'This is usual event.' }
                ];
                break;
            case 10:
                listData = [
                    { type: 'warning', content: 'This is warning event.' },
                    { type: 'success', content: 'This is usual event.' },
                    { type: 'error', content: 'This is error event.' }
                ];
                break;
            case 15:
                listData = [
                    { type: 'warning', content: 'This is warning event' },
                    {
                        type: 'success',
                        content: 'This is very long usual event。。....'
                    },
                    { type: 'error', content: 'This is error event 1.' },
                    { type: 'error', content: 'This is error event 2.' },
                    { type: 'error', content: 'This is error event 3.' },
                    { type: 'error', content: 'This is error event 4.' }
                ];
                break;
            default:
        }
        return listData || [];
    }

    function dateCellRender(value) {
        const listData = getListData(value);
        return (
            <ul className="dateaaaaa">
                {listData.map((item) => (
                    <li key={item.content}>
                        <span>hi</span>
                        {/* <Badge status={item.type} text={item.content} /> */}
                    </li>
                ))}
            </ul>
        );
    }

    function getMonthData(value) {
        if (value.month() === 8) {
            return 1394;
        }
    }

    function monthCellRender(value) {
        const num = getMonthData(value);
        return num ? (
            <div className="notes-month">
                <section>{num}</section>
                <span>Backlog number</span>
            </div>
        ) : null;
    }
    return (
        <CalendarContainer>
            <Calendar
                dateCellRender={dateCellRender}
                monthCellRender={monthCellRender}
            />
        </CalendarContainer>
    );
};

export default CalendarComponent;

const CalendarContainer = styled.div`
    .ant-picker-calendar-date-value {
        text-align: left;
        color: #121216;
        font-family: AvenirNext-DemiBold;
        font-size: 12px;
        opacity: 0.4989809782608696;
    }

    .ant-picker-cell:hover {
        .ant-picker-calendar-date-value {
            opacity: 1;
        }
    }

    .ant-picker-cell-selected {
        .ant-picker-calendar-date-value {
            opacity: 1;
            color: #121216;
        }
    }
`;
