import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Row, Col } from 'antd';
import {
    Header,
    Tabs,
    Map2,
    AircraftTypeCard,
    PackageDetailCard,
    PassengerInfoContainer,
    ButtonCustom,
    FlightNotes,
    Text
} from 'components';
import { allowedRolesCreateQuote } from 'config/Constants';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { GLOBAL_STYLE_CONSTANTS } from 'config/Constants';
import { aboutIcon, iconSetting, iconBriefcase, iconWallet, iconTelevision } from 'assets/images/icons';
import WhiteLabel from './Whitelabel/Whitelabel';

const template = (props) => {
    const {navigate} = props;
    const { t, i18n } = useTranslation();
    const tabRenderer = [
        {
            key: 'WHITELABEL',
            label: t('WHITELABEL').toUpperCase(),
            icon: iconTelevision,
            render: <WhiteLabel navigate={navigate}/>
        },
        {
            key: 'PAYMENT_MEDTHODS',
            label: t('PAYMENT_MEDTHODS').toUpperCase(),
            icon: iconWallet,
            render: <div>3333</div>
        },
        {
            key: 'SUBCRIPTIONS',
            label: t('SUBCRIPTIONS').toUpperCase(),
            icon: iconBriefcase,
            render: <div>4444</div>
        },
        {
            key: 'PREFERENCES',
            label: t('PREFERENCES').toUpperCase(),
            icon: iconSetting,
            render: <div>1111</div>
        }
    ];

    return (
        <DashboardContainer>
            <div style={{ marginBottom: 15 }}>
                <Header
                    title={t('SETTINGS')}
                    //TODO: bring this quote to en.js and ask for AJ operator from backend side
                    description={t('WHITELABEL')}
                />
            </div>
            <Tabs
                tabRenderer={tabRenderer}
                // onChange={setFocusedLeg}
            />
        </DashboardContainer>
    );
};

export { template };

const DashboardContainer = styled.div`
    padding: ${GLOBAL_STYLE_CONSTANTS.padding};
    .ant-header-custom-employees {
        background-color: #f2f4f7;
    }
`;
