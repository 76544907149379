import React from 'react';
import 'antd/dist/antd.css';
import { Row, Col, Switch, Input as AntInput } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
const { TextArea } = AntInput;

const AboutUs = (props) => {
    const {
        activeAboutUs = false,
        setActiveAboutUs,
        aboutUsDescription = '',
        setAboutUsDescription
    } = props;
    const { t, i18n } = useTranslation();

    return (
        <ContainerWrapper>
            <PayoutSection>
                {/* <HeaderInfo title={t('GENERAL_INFO').toUpperCase()} /> */}
                <WhitelabelContainer>
                    <EachRow align={'middle'}>
                        <TitleText>{t('SHOW_SECTION')}</TitleText>
                        <Switch
                            checked={activeAboutUs}
                            style={{ marginLeft: 10 }}
                            onChange={setActiveAboutUs}
                        />
                    </EachRow>
                    <EachRow style={{ flexDirection: 'column' }}>
                        <GeneralSetting>{t('INTRODUCTION')}</GeneralSetting>
                        <IntroText>{t('ABOUT_US_INTRO')}</IntroText>
                        <DescriptionArea
                            placeholder={t('WRITE_DESCRIPTION')}
                            autoSize={{ minRows: 5, maxRows: 100 }}
                            onChange={(e) => {
                                setAboutUsDescription(e.target.value);
                            }}
                            value={aboutUsDescription}
                        />
                    </EachRow>
                </WhitelabelContainer>
            </PayoutSection>
        </ContainerWrapper>
    );
};

export default AboutUs;

const GeneralSettingRow = styled(Row)`
    width: 370px;
    padding-right: 20px;
    /* height: 46px; */
`;

const GeneralSettingsCol = styled(Col)`
    margin-top: 20px;
`;

const GeneralSetting = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 17px;
`;

const DescriptionArea = styled(TextArea)`
    border: 1px solid rgba(36, 36, 45, 0.15);
    border-radius: 4px;
    margin-top: 10px;
    width: 100%;
    max-width: 1300px !important;
`;

const TitleText = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 18px;
    text-transform: uppercase;
`;

const WhitelabelContainer = styled.div`
    padding-left: 51px;
`;

const EachRow = styled(Row)`
    margin-top: 20px;
`;

const IntroText = styled.span`
    color: #65686f;
    font-family: Lato;
    font-size: 13px;
    line-height: 23px;
    max-width: 1089px;
    white-space: pre-wrap;
`;

const ContainerWrapper = styled.div`
    border-radius: 4px;
    /* margin-top: 14px; */
    .ant-divider {
        border-top: 1px solid rgba(14, 14, 18, 0.08);
    }
    .ant-divider-horizontal {
        margin: 20px 0;
    }
`;

const PayoutSection = styled.div`
    padding-right: 20px;
    padding-bottom: 40px;
    background-color: #ffffff;
    border-radius: 4px;
`;
