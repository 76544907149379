import styled from 'styled-components';
import 'antd/dist/antd.css';
import { List, Image } from 'antd';
import { imageIcon } from '../../assets/images/icons';
import { GLOBAL_STYLE_CONSTANTS } from 'config/Constants';

const LoadMoreSection = styled.div`
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ResultFound = styled.div`
    margin-top: 40px;
`;

const TagSection = styled.div`
    margin-right: 10px;
`;

const FeatureIcon = styled.img`
    margin-top: 10px;
    margin-right: 10px;
`;

const FeatureTitle = styled.div`
    margin-top: 5px;
`;

const CreateQuoteTopRight = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const CreateQuoteTopLeft = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
`;

const IconBorder = styled.button`
    border: 1px solid #dadadb;
    border-radius: 2px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    background-color: transparent;
    cursor: pointer;
`;

const CreateQuoteTitle = styled.div`
    margin-left: 15px;
`;

const LeftSection = styled.div`
    display: flex;
    flex-direction: row;
    padding-right: 30px;
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */
    /* border: 1px solid #000; */
    max-width: 1520px;
`;

const EachAmenities = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const FeaturedAmenitiesTitle = styled.div`
    margin-bottom: 5px;
`;

const AircraftDetail = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CreateQuoteTop = styled.div`
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(14, 14, 18, 0.05);
    padding-bottom: 20px;
`;

const FeaturedAmenities = styled.div`
    padding: 20px;
`;

const PropertyLabel = styled.div`
    height: 18px;
`;

const PropertyValue = styled.div`
    height: 18px;
`;

const EachProperty = styled.div`
    display: flex;
    flex-direction: column;
    width: 9vw;
    max-width: 170px;
`;

const DetailPropertiesLine1 = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
`;

const DetailPropertiesLine2 = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
`;

const Description = styled.span`
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
    color: #65686f;
    font-family: Lato;
    font-size: 14px;
    line-height: 24px;
`;

const EachRateProperty = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 40px;
    align-items: center;
`;

const RateValue = styled.div`
    margin-left: 5px;
`;

const RateLabel = styled.div``;

const RateSection = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
`;

const OperationName = styled.div``;
const OperatedBy = styled.div``;
const OperatedBySection = styled.div`
    margin-top: 10px;
`;
const PlaneCode = styled.div`
    margin-left: 15px;
`;

const PlaneName = styled.div``;

const PlaneNameSection = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
`;

const ArrowBackground = styled.button`
    background-color: #f0f3f9;
    border-radius: 50%;
    width: 32px;
    height: 32px;
`;

const ImageSection = styled.div`
    width: ${(props) => props.width || '18.3vw'};
    max-width: 350px;
    .carousel-custom .carousel .thumbs-wrapper {
        margin-top: 10px;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        overflow: hidden;
        ul {
            padding-inline-start: 0;
        }
    }
    .thumb {
        transition: border 0.15s ease-in;
        display: inline-block;
        margin-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        border: 3px solid #fff;
        padding: 0;
        height: ${(props) => props.thumbImageHeight || '70px'};
    }
    .carousel .thumb.selected,
    .carousel .thumb:hover {
        border: 1px solid #19c0ff;
    }
    .img-carousel {
        background-image: url(${imageIcon});
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .thumb img {
        width: ${(props) => (props.thumbImageWidth || '350') + 'px !important'};
        height: ${(props) =>
            (props.thumbImageHeight || '70px') + ' !important'};
    }
    /* .control-arrow {
        background-color: #f0f3f9;
        border-radius: 100%;
        width: 32px;
        height: 32px;
    } */
`;
const DetailInformationSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 41.875vw;
    /* width: 60vw; */
    /* width: ${(props) => (props.expanded ? '41.875vw' : '60vw')}; */
    max-width: 1100px;
    padding-left: 30px;
    padding-right: 30px;
    /* border: 1px solid #f00; */
`;
const CreateQuoteSection = styled.div`
    width: 15.625vw;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    border-left: 1px solid rgba(14, 14, 18, 0.1);
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: space-between;
`;

const EachPlaneSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
    padding-left: 20px;
    background-color: #ffffff;
    /* padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px; */
`;

const ClearFilter = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: transparent;
    border: none;
`;

const ClearFilterText = styled.span`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    letter-spacing: 0.4px;
`;

const ApplyFilterButton = styled.div``;

const ByCategoryEachField = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 5px;
    margin-bottom: 5px;
`;

const SliderField = styled.div`
    margin-top: 5px;
    margin-bottom: 5px;
`;

const ApplyFilter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const HourlyRates = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 19.5vw;
    max-width: 373px;
`;

const ByOperator = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

const ByCategory = styled.div`
    display: flex;
    flex-direction: column;
`;

const FilterLabel = styled.div``;

const FilterButton = styled.button`
    border: 1px solid #dadadb;
    border-radius: 4px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    background-color: transparent;
`;

const FilterButtonSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const SearchInputSection = styled.div``;

const SearchLabel = styled.div``;

const SearchInput = styled.div`
    margin-top: 15px;
`;

const SearchSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #ffffff;
    width: 100%;
`;

const FilterSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #ffffff;
    width: 100%;
    margin-top: 5px;
`;

const FilterDetailSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    padding-left: 20px;
    background-color: #ffffff;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
`;

const SearchAndFilterSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding-left: 20px;
    background-color: #ffffff;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
`;

const Container = styled.div`
    padding: ${GLOBAL_STYLE_CONSTANTS.padding};
`;

const AircraftDetailSummary = styled.div`
    background-color: #ffffff;
    padding-left: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
`;

const QuickToolBar = styled.div`
    background-color: #ffffff;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
`;

const CreateQuoteTopLeftDetail = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
`;

const PhotosSection = styled.div`
    margin-top: 45px;
`;

const ListPhotosSection = styled.div`
    margin-top: 20px;
    padding: 20px;
    padding-bottom: 10px;
    background-color: #ffffff;
    .ant-list-items-custom .ant-image {
        margin-right: 20px;
    }
    .ant-list-items {
        display: flex;
        flex-wrap: wrap;
    }
`;

const EachImage = styled(Image)`
    margin-right: 20px;
    height: 142px;
    width: 225px;
`;

const EachSection = styled.div`
    margin-top: 45px;
`;

const PropertiesContainer = styled.div`
    background-color: ${(props) => props.backgroundColor || '#f6f9fc'};
    border-radius: 4px;
    /* padding-top: 5px; */
`;

const DescriptionSection = styled.div`
    /* margin: 15px 20px 15px 20px; */
    margin-top: 8px;
    padding: 8px 0 0 0;
`;

const DescriptionTitle = styled.div`
    margin-left: 20px;
`;

const DescriptionDetail = styled.div`
    margin-top: 20px;
    background-color: #ffffff;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Column = styled.div`
    display: flex;
    flex-direction: row;
    width: ${(props) => props.width || '25vw'};
`;

const PropertyLabelSection = styled.div`
    margin-bottom: ${(props) => (props.isEdit ? '50px' : '20px')};
    margin-top: ${(props) => (props.isEdit ? '10px' : '0')};
`;

const PropertyValueSection = styled.div`
    margin-bottom: 20px;
`;

const Value = styled.div``;

const Property = styled.div`
    margin-right: 30px;
`;

const EachCheckbox = styled.div`
    margin-bottom: 25px;
`;

const CheckboxSection = styled.div`
    padding: 15px 25px 0 25px;
    display: flex;
    flex-direction: row;
`;

const FirstCheckboxColumn = styled.div`
    width: 10.4vw;
    max-width: 200px;
`;

const SecondCheckboxColumn = styled.div`
    width: 10.4vw;
    max-width: 200px;
`;

const SaveChangesSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
`;

const UploadMore = styled.div`
    width: 225px;
    height: 142px;
    border: 1px dashed #8e929a;
    display: flex;

    justify-content: center;
    align-items: center;
`;

const ImageContainer = styled.div`
    display: flex;
    margin-bottom: 10px;
    /* flex-direction: row; */
    /* flex-wrap: wrap; */
`;

const ImageContainerAdded = styled.div`
    display: flex;
    width: 250px;
    height: 158px;
    margin-top: 20px;
    margin-right: 30px;
`;

const DeletePhoto = styled.button`
    position: absolute;
    margin-left: 195px;
    margin-top: 5px;
    background-color: #f0f3f9;
    border: none;
    border-radius: 100%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const OperatedByText = styled.span`
    color: #8e929a;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
`;

const AirportLabel = styled.div``;

const CancelEditButton = styled.button`
    background-color: transparent;
    border: 1px solid #dadadb;
    border-radius: 2px;
    margin-right: 10px;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
`;

const ContainerWrapper = styled.div`
    background-color: #ffffff;
    padding-bottom: 40px;
    border-radius: 4px;
    margin-top: 40px;
`;

const GeneralSection = styled.div`
    margin-left: 20px;
`;

const GeneralInformation = styled.div`
    margin-left: 50px;
    margin-top: 30px;
`;

const FirstRow = styled.div`
    display: flex;

    flex-direction: column;
`;

const Row = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
`;

const AircraftCategory = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-right: 30px;

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        width: ${(props) => (props.inputWidth ? props.inputWidth : '370px')};
    }
`;

const DescriptionRow = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
`;

const PropertySection = styled.div`
    margin-left: 20px;
    margin-top: 30px;
`;

const UploadInformation = styled.div`
    margin-left: 50px;
`;

const CancelText = styled.div`
    margin-top: 2px;
`;

const BottomContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    margin: 35px;
    margin-bottom: 60px;
`;

const AirportSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-right: 30px;
`;

const ListPhotosUpload = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    /* margin-top: 20px; */
    .upload-more-photo-section {
        height: 157px;
        width: 250px;
        margin-top: 20px;
        margin-right: 30px;
    }
`;

const ImageAdded = styled.img`
    background-size: 100%;
    border-radius: 4px;
    width: 250px;
    height: 158px;
    /* margin-right: 30px; */
`;

const ImageCarousel = styled.img``;

const NextButton = styled.button`
    background-color: #f0f3f9;
    border-radius: 100%;
    width: 32px;
    height: 32px;
`;

const CancelIcon = styled.img`
    color: #8e929a;
    width: 15px;
    height: 15px;
`;

const AddNewAircraftSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid #000; */
    padding: 20px 0;
    padding-top: 30px;
`;

export {
    AddNewAircraftSection,
    ImageContainerAdded,
    CancelIcon,
    NextButton,
    ImageCarousel,
    ImageAdded,
    ListPhotosUpload,
    AirportSection,
    BottomContainer,
    CancelText,
    UploadInformation,
    PropertySection,
    DescriptionRow,
    AircraftCategory,
    Row,
    FirstRow,
    GeneralInformation,
    GeneralSection,
    ContainerWrapper,
    CancelEditButton,
    AirportLabel,
    OperatedByText,
    DeletePhoto,
    ImageContainer,
    UploadMore,
    SaveChangesSection,
    SecondCheckboxColumn,
    FirstCheckboxColumn,
    CheckboxSection,
    EachCheckbox,
    Property,
    Value,
    PropertyValueSection,
    PropertyLabelSection,
    Column,
    DescriptionDetail,
    DescriptionTitle,
    DescriptionSection,
    PropertiesContainer,
    EachSection,
    EachImage,
    ListPhotosSection,
    PhotosSection,
    CreateQuoteTopLeftDetail,
    QuickToolBar,
    AircraftDetailSummary,
    LoadMoreSection,
    ResultFound,
    TagSection,
    FeatureIcon,
    FeatureTitle,
    CreateQuoteTopRight,
    CreateQuoteTopLeft,
    IconBorder,
    CreateQuoteTitle,
    LeftSection,
    EachAmenities,
    FeaturedAmenitiesTitle,
    AircraftDetail,
    CreateQuoteTop,
    FeaturedAmenities,
    PropertyLabel,
    PropertyValue,
    EachProperty,
    DetailPropertiesLine1,
    DetailPropertiesLine2,
    Description,
    EachRateProperty,
    RateValue,
    RateLabel,
    RateSection,
    OperationName,
    OperatedBy,
    OperatedBySection,
    PlaneCode,
    PlaneName,
    PlaneNameSection,
    ArrowBackground,
    ImageSection,
    DetailInformationSection,
    CreateQuoteSection,
    EachPlaneSection,
    ClearFilter,
    ClearFilterText,
    ApplyFilterButton,
    ByCategoryEachField,
    SliderField,
    ApplyFilter,
    HourlyRates,
    ByOperator,
    ByCategory,
    Container,
    SearchAndFilterSection,
    FilterDetailSection,
    FilterSection,
    SearchSection,
    SearchInput,
    SearchLabel,
    SearchInputSection,
    FilterButtonSection,
    FilterButton,
    FilterLabel
};
