import React, { useState } from 'react';
import { Avatar, Button, Row, Col } from 'antd';
import styled from 'styled-components';

import { ButtonCustom } from 'components';
import moment from 'moment';
import { usdIcon, infoIcon, invoiceIcon } from 'assets/images/icons';
import { useSelector } from 'react-redux';
import { getCustomerInfo } from 'redux/selectors/company';
import { useTranslation } from 'react-i18next';
import { ContainerDrawer, WrapperDrawer } from '../../styles';

const Invoice = (props) => {
    const {
        title = '',
        createAt = '',
        content = '',
        _onRef = '',
        _on = {},
        ref_basic_info = {},
        user = {},
        seen = false
    } = props;
    const { status = '', code = '' } = ref_basic_info;
    const { firstname = '', lastname = '', user_id = '' } = user;
    const { t, i18n } = useTranslation();

    let image = '';
    const customer = useSelector((state) => getCustomerInfo(state, user_id));
    if (customer) {
        image = customer.image;
    }

    return (
        <ContainerDrawer>
            <WrapperDrawer>
                { title && 
                    <div style={{ display: 'flex' }}>
                        <InfoCircleBackground>
                            <Icon src={usdIcon} />
                        </InfoCircleBackground>
                        <TitleHeader seen={!seen}>
                            {title.toUpperCase()}
                        </TitleHeader>
                    </div>
                }
               
                <TextItem seen={!seen} style={{ marginTop: '8px' }}>
                    {content || ''}
                </TextItem>

                <div style={{ marginTop: '10px' }}>
                    {image ? (
                        <Avatar className="ant-avatar-custom" src={image} />
                    ) : (
                        <Avatar className="ant-avatar-custom">
                            {(
                                firstname.charAt(0) + lastname.charAt(0)
                            ).toUpperCase()}
                        </Avatar>
                    )}
                    <Name>{`${firstname} ${lastname}`}</Name>
                </div>

                <div style={{ marginTop: '10px' }}>
                    <ButtonCustom 
                        title={t('VIEW_DETAILS').toUpperCase()} 
                        btnType="secondary" 
                        size="small"
                    />
                </div>

                <TextItem seen={!seen} style={{ marginTop: '10px' }}>
                    {createAt
                        ? moment(createAt).fromNow()
                        : ''}
                </TextItem>
            </WrapperDrawer>
        </ContainerDrawer>
    );
};

export default Invoice;

const InfoCircleBackground = styled.div`
    border: 1px solid #F98C05;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
`;

const Icon = styled.img`
    width: 10px;
    height: 10px;
`;

const TextItem = styled.div`
    color: ${(props) => props.seen ? '#0E0E12' : '#65686f'};
    font-family: ${(props) => props.seen ? 'AvenirNext-DemiBold' : 'Lato'};
    font-size: 11px;
    line-height: 16px;
    text-align: left;
    margin-top: 3px;
`;

const Name = styled.span`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    margin-left: 10px;
`;

const TitleHeader = styled.span`
    color: ${(props) => props.seen ? '#0E0E12' : '#65686f'};
    font-family: ${(props) => props.seen ? 'AvenirNext-Bold' : 'AvenirNext-DemiBold'};
    font-size: 10px;
    letter-spacing: 0.7px;
`;