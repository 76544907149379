import React, { useState, useEffect } from 'react';
import { template as tmpl } from './InvoiceList.tmpl';
import { searchBooking } from 'redux/actions/bookingAction';
import { getInvoices } from 'redux/actions/companyAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const InvoiceListLogic = (props) => {
    const { activeCompany, getInvoices } = props;

    const [key, setKey] = useState('');
    const [sortBy, setSortBy] = useState('week'); // default all
    const [invoices, setInvoices] = useState([]);
    const [searchList, setSearchList] = useState([]);

    const initInvoices = async () => {
        let query = { operator_invoice: true };
        let rs = await getInvoices(query);

        if (rs.success) {
            setInvoices(rs.data || []);
            setSearchList(rs.data || []);
        }
    };

    /**
     * Get Invoice list
     */
    useEffect(() => {
        initInvoices();
    }, [activeCompany]);

    /**
     * Function handles searching when user
     * @param {*} e event when user is typing
     */
    const onSearch = async (e) => {
        const input = e.target.value.toLowerCase();
        const result = searchList.filter((item = {}) => {
            const { invoice_code = '', addressee = {} } = item;
            const { company_name = '' } = addressee;
            if (
                (invoice_code && invoice_code.toLowerCase().includes(input)) ||
                (company_name && company_name.toLowerCase().includes(input))
            ) {
                return item;
            }
        });
        setInvoices(result);
    };

    const navigate = (route, id, state) => {
        props.history.push({
            pathname: route,
            search: id,
            state: state
        });
    };

    const initProps = {
        invoices: invoices,
        onSearch: onSearch,
        navigate: navigate,
        key: key,
        sortBy: sortBy
    };

    return tmpl && tmpl({ ...initProps });
};

class InvoiceListContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <InvoiceListLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { customers, activeCompany } = companyReducer;
    return {
        customers: customers,
        activeCompany: activeCompany // TODO : fetch booking list on change company
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        searchBooking: (key, limit, page, status) =>
            dispatch(searchBooking(key, limit, page, status)),
        getInvoices: (query) => dispatch(getInvoices(query))
    };
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoiceListContainer));
