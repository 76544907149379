import React, { useEffect, useState } from 'react';
import { template as tmpl } from './PartnershipFleetContainer.tmpl';
import { connect } from 'react-redux';
import { switchCompany } from 'redux/actions/appActions';
import { getAircrafts, searchAircrafts } from 'redux/actions/aircarftAction';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

const PartnershipFleetContainerLogic = (props) => {
    const { activeCompany, companies = [], isView = false, partnerId } = props;

    const [originCompanyList, setOriginCompanyList] = useState(companies);
    const [aircraftList, setAircraftList] = useState([]);

    const defaultFilterAircraft = {
        on_platform_partner: true,
        limit: 6,
        page: 1
    };
    const [searchText, setSearchText] = useState('');
    const [filterAircraft, setFilterAircraft] = useState(defaultFilterAircraft);
    const [searching, setSearching] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [applyFilter, setApplyFilter] = useState(false);
    const [expandFilter, setExpandFilter] = useState(false);
    const [pickedCategory, setPickedCategory] = useState([]);
    const [total, setTotal] = useState(0);
    const [pickedOperator, setPickedOperator] = useState([]);
    const [marks, setMarks] = useState({
        0: '0k',
        100: '$100k'
    });

    const [hourRate, setHourRate] = useState([0, 100]);
    const [searchInfo, setSearchInfo] = useState([]);

    useEffect(() => {
        setOriginCompanyList(companies);
    }, [companies]);

    const fetchAircraftList = async () => {
        setCurrentPage(1);
        setTotalPage(1);
        let params = defaultFilterAircraft;
        if (partnerId) {
            params.operator = [partnerId];
        }
        const result = await props.getAircrafts(params);
        if (result.isConnected && result.success && result.aircrafts) {
            setAircraftList(result.aircrafts);
            setTotalPage(result.pages);
            setTotal(result.total);
        } else {
            // Fail to call API
        }
    };

    useEffect(() => {
        fetchAircraftList();
    }, []);

    const expandFilterHandler = () => {
        setExpandFilter(!expandFilter);
    };

    const onCloseTag = (item) => {
        switch (item.type) {
            case 'CATEGORY':
                if (pickedCategory.includes(item.value)) {
                    let tempArr = pickedCategory;
                    setPickedCategory(
                        tempArr.filter((category) => category !== item.value)
                    );

                    let tempSearchInfo = searchInfo;

                    setSearchInfo(
                        tempSearchInfo.filter(
                            (category) => category.value !== item.value
                        )
                    );
                }
                break;
            case 'OPERATOR':
                if (pickedOperator.includes(item.value)) {
                    let tempArr = pickedOperator;
                    setPickedOperator(
                        tempArr.filter((category) => category !== item.value)
                    );

                    let tempSearchInfo = searchInfo;
                    setSearchInfo(
                        tempSearchInfo.filter(
                            (category) => category.value !== item.value
                        )
                    );
                }
                break;
        }
    };

    const onCategoryAdd = (value) => {
        if (value && !pickedCategory.includes(value)) {
            setPickedCategory([...pickedCategory, value]);
            setSearchInfo([
                ...searchInfo,
                {
                    type: 'CATEGORY',
                    value: value
                }
            ]);
        }
    };

    const onAfterChangeSlider = (value) => {
        setHourRate(value);
        const valueLower = value[0];
        const valueHigher = value[1];

        let tempMarks = { 0: '0k', 100: '$100k' };

        tempMarks[50] = {
            style: {
                color: '#121216',
                fontFamily: 'Lato',
                fontSize: '14px'
            },
            label: '$' + valueLower + 'k' + ' - ' + '$' + valueHigher + 'k'
        };
        setMarks(tempMarks);
    };

    const onSearch = async (value) => {
        setSearchText(value);
        if (value) {
            let data = {
                ...filterAircraft,
                key: value
            };
            setCurrentPage(1);

            const result = await props.getAircrafts(data);
            if (result.isConnected && result.success && result.aircrafts) {
                setAircraftList(result.aircrafts);
                setTotalPage(result.pages);
                setTotal(result.total);
            } else {
                // Fail to call API
            }
        } else {
            fetchAircraftList();

            setSearching(false);
        }
    };

    const onSearchOperator = (value) => {
        const input = value.toLowerCase();
        const result = originCompanyList.filter((item) => {
            if (item.name.toLowerCase().includes(input)) {
                return item;
            }
        });
        return result || [];
    };

    const onSelectOperator = (value) => {
        if (!_.isEmpty(value) && !pickedOperator.includes(value)) {
            setPickedOperator([value, ...pickedOperator]);
            setSearchInfo([
                ...searchInfo,
                {
                    type: 'OPERATOR',
                    value: value
                }
            ]);
        }
    };

    const onClickApplyFilter = async () => {
        // setSearchText('');
        setApplyFilter(true);
        let data = {
            key: searchText,
            from_rate: hourRate[0] * 1000,
            to_rate: hourRate[1] * 1000,
            ...defaultFilterAircraft
        };
        if (pickedCategory.length > 0) {
            data['category'] = pickedCategory;
        }

        if (pickedOperator.length > 0) {
            let operatorIdArr = pickedOperator.map((operator) => {
                return operator.id;
            });
            data['operator'] = operatorIdArr;
        }

        setFilterAircraft(data);
        setCurrentPage(1);

        const result = await props.getAircrafts(data);
        if (result.isConnected && result.success && result.aircrafts) {
            setAircraftList(result.aircrafts);
            setTotalPage(result.pages);
            setTotal(result.total);
        } else {
            // Fail to call API
        }
    };

    const onClickClearFilter = async () => {
        let data = {
            key: searchText,
            ...defaultFilterAircraft
        };
        // const result = await props.searchAircrafts(value);
        //     if (result.isConnected && result.success && result.aircrafts) {
        //         setAircraftList(result.aircrafts);
        //     } else {
        //         // Fail to call API
        //     }
        // await fetchAircraftList();
        const result = await props.getAircrafts(data);
        if (result.isConnected && result.success && result.aircrafts) {
            setAircraftList(result.aircrafts);
            setTotalPage(result.pages);
            setSearching(false);
            setTotal(result.total);
        } else {
            // Fail to call API
        }
        setFilterAircraft(defaultFilterAircraft);
        setApplyFilter(false);
        setPickedCategory([]);
        setPickedOperator([]);
        setHourRate([0, 100]);
    };

    const onClickLoadMore = async () => {
        setLoading(true);
        if (currentPage < totalPage) {
            setCurrentPage(currentPage + 1);
            let data = filterAircraft;
            data['page'] = currentPage + 1;
            setFilterAircraft(data);
            const result = await props.getAircrafts(data);
            setLoading(false);
            if (result.isConnected && result.success && result.aircrafts) {
                setAircraftList([...aircraftList, ...result.aircrafts]);
                // let tempList = [...aircraftList, ...result.aircrafts];
                setTotalPage(result.pages);
                setTotal(result.total);
            } else {
                // Fail to call API
            }
        }
    };

    const navigate = (route, id, state) => {
        props.history.push({
            pathname: route,
            search: id,
            state: state
        });
    };

    const initProps = {
        total: total,
        isView: isView,
        searchInfo: searchInfo,
        searchText: searchText,
        currentPage: currentPage,
        totalPage: totalPage,
        applyFilter: applyFilter,
        hourRate: hourRate,
        marks: marks,
        pickedCategory: pickedCategory,
        expandFilter: expandFilter,
        aircraftList: aircraftList,
        filterAircraft: filterAircraft,
        activeCompany: activeCompany,
        pickedOperator: pickedOperator,
        loading: loading,
        searching: searching,
        expandFilterHandler: expandFilterHandler,
        onCategoryAdd: onCategoryAdd,
        onCloseTag: onCloseTag,
        onAfterChangeSlider: onAfterChangeSlider,
        onSearch: onSearch,
        onSearchOperator: onSearchOperator,
        onClickApplyFilter: onClickApplyFilter,
        onSelectOperator: onSelectOperator,
        onClickClearFilter: onClickClearFilter,
        onClickLoadMore: onClickLoadMore,
        navigate: navigate
    };

    return tmpl && tmpl({ ...initProps });
};

class PartnershipFleetContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <PartnershipFleetContainerLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { companyReducer } = state;
    const { activeCompany, companies } = companyReducer;
    return {
        companies: companies,
        activeCompany: activeCompany
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        switchCompany: (data) => dispatch(switchCompany(data)),
        getAircrafts: (query) => dispatch(getAircrafts(query)),
        searchAircrafts: (input) => dispatch(searchAircrafts(input))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PartnershipFleetContainer)
);
