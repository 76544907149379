import styled from 'styled-components';
import { GLOBAL_STYLE_CONSTANTS } from 'config/Constants';

export const EmployeeDetailContainer = styled.div`
    padding: ${GLOBAL_STYLE_CONSTANTS.padding};
`;

export const ContainerWrapper = styled.div`
    background-color: #fff;
    border-radius: 4px;
    margin: 0 min(40px, 4%);
    padding: 10px 20px 40px;

    .ant-select-custom {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: 'tnum', 'tnum';
        position: relative;
        display: inline-block;
        cursor: pointer;
        width: 28vw;
        max-width: 370px;
        min-width: 270px;
        border: 1px solid rgba(36, 36, 45, 0.15);
        border-radius: 3px;
        height: 50px;
    }
    .ant-select-selector {
        border: none;
        height: 50px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        width: 100%;
        height: 50px;
        padding: 0 11px;
    }
    .ant-select-selection-item {
        align-self: center;
    }
    .ant-select-selection-placeholder {
        align-self: center;
    }
    .ant-checkbox + span {
        padding-right: 8px;
        padding-left: 8px;
        color: #65686f;
        font-family: AvenirNext-DemiBold;
        font-size: 10px;
        letter-spacing: 0.7px;
        line-height: 18px;
    }
    .employee-input {
        border-radius: 3px;
        height: 50px;
        width: 28vw;
        max-width: 370px;
        min-width: 270px;
    }
    .input-lastname {
    }
`;

export const BottomContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    margin: 35px;
    margin-bottom: 60px;
`;

export const NetworkError = styled.div``;

export const NetworkErrorText = styled.span`
    color: red;
`;

export const WrapperText = styled.div`
    justify-content: flex-start;
    align-self: flex-start;
    flex-wrap: wrap;
`;

export const Label = styled.div`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    /* margin-top: 20px; */
    margin-bottom: 12px;
    margin-left: ${(props) => (props.rightInput ? '30px' : '0px')};
`;

export const UploadText = styled.div`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
    margin: 15px;
`;

export const Avatar = styled.div`
    background-color: #f0f3f9;
    border-radius: 100%;
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 20px;
`;

export const SelectPlaceholder = styled.span`
    color: #8e929a;
    font-family: Lato;
    font-size: 14px;
    line-height: 17px;
`;

export const FormSection = styled.div`
    margin-top: 20px;
    margin-left: 50px;
`;

export const ChooseRoleDropDown = styled.div`
    padding-right: 30px;
`;

export const ChooseAdmin = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 190px;
    /* padding-left: 20px; */
`;

export const CheckboxSection = styled.div`
    margin-top: auto;
    margin-bottom: auto;
    .ant-select-selection-search-input::placeholder {
        color: #8e929a;
        font-family: Lato;
        font-size: 14px;
        line-height: 17px;
    }
`;

export const BufferChooseAdmin = styled.div`
    height: 50%;
`;

export const FirstNameSection = styled.div`
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const LastNameSection = styled.div`
    padding-top: 25px;
`;

export const LabelText = styled.span`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
    padding-bottom: 13px;
    width: 100%;
`;
