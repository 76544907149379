import React from 'react';
import { Col, Row, Avatar } from 'antd';
import styled from 'styled-components';
import { GLOBAL_STYLE_CONSTANTS } from 'config/Constants';

const CustomerContainer = styled.div`
    padding: ${GLOBAL_STYLE_CONSTANTS.padding};
`;

const SearchAndSort = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const CustomerDetailContainer = styled.div`
    padding: ${GLOBAL_STYLE_CONSTANTS.padding};
`;

const ContainerWrapper = styled.div`
    background-color: #fff;
    border-radius: 4px;
    /* margin-left: 40px;
    margin-right: 40px; */
    margin: 0 min(40px, 4%);
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 40px;

    .address {
        /* border: 1px solid rgba(36, 36, 45, 0.15); */
        border-radius: 3px;
        width: 59vw;
        max-width: 770px;
        min-width: 570px;
        height: 50px;
    }
    .base {
        /* border: 1px solid rgba(36, 36, 45, 0.15); */
        border-radius: 3px;
        width: 28vw;
        max-width: 370px;
        min-width: 270px;
        height: 50px;
        /* margin-bottom: 10px; */
    }
    .right {
        margin-left: 30px;
    }

    .left {
        margin-right: 30px;
    }

    .add-customer-item {
        margin-left: 50px;
        margin-top: 5px;
    }

    @media (max-width: 1024px) {
        .address {
            max-width: ${(props) =>
                props.expandedSidebar ? '570px' : '770px'};
        }
    }
    /* @media (max-width: 768px) {
        .address {
            width: 370px;
        }
    } */
`;

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 40px;
`;

const WrapperText = styled.div`
    justify-content: flex-start;
    align-self: flex-start;
    background-color: orange;
    flex-wrap: wrap;
`;

const Label = styled.div`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 12px;
    margin-left: ${(props) => (props.rightInput ? '30px' : '0px')};
`;

const UploadText = styled.div`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
    margin: 15px;
`;

const WrapperComponent = styled.div`
    display: flex;
    flex-direction: column;

    .ant-select-custom {
        cursor: pointer;
        width: 370px;
        height: 50px;
    }
    .ant-select-selection-item {
        align-self: center;
    }
    .ant-select-selection-placeholder {
        align-self: center;
    }
`;

const MenuTitle = styled.div`
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    letter-spacing: 0.7px;
    line-height: 16px;
    text-align: left;
    margin-top: 35px;
    margin-bottom: 25px;
`;

const Title = styled.div`
    color: #65686f;
    font-family: AvenirNext-DemiBold;
    font-size: 11px;
    line-height: 13px;
    text-align: left;
`;

const Component = styled.div`
    background-color: #ffffff;
    border-radius: 4px;
    padding: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
`;

const UploadButton = styled.button`
    border: 1px solid rgba(18, 18, 22, 0.2);
    border-radius: 2px;
    width: 242px;
    height: 32px;
    background-color: transparent;
    margin-top: 17px;
`;

const ButtonText = styled.span`
    color: #16161b;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: center;
`;

const Container = styled.div``;

const TitleDescription = styled.div`
    color: ${(props) => (props.color ? props.color : '#121216')};
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 18px;
    text-align: left;
`;

const Name = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 20px;
    letter-spacing: 0.8px;
    line-height: 32px;
    width: 261px;
    text-align: left;
`;

const SearchSection = styled.div`
    margin-top: 30px;
    .ant-input {
        background-color: transparent;
    }
    .ant-input-affix-wrapper {
        border: 1px solid #dadadb;
        border-radius: 3px;
        width: 20vw;
        min-width: 250px;
        height: 40px;
        background-color: transparent;
    }
`;

const MembershipText = styled.span`
    color: #19c0ff;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
`;

const ItemText = styled.span`
    color: #35383f;
    font-family: AvenirNext-DemiBold;
    font-size: 12px;
    line-height: 14px;
    align-self: center;
`;

const ColumnHeader = styled.span`
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 11px;
    line-height: 13px;
`;

const NameSectionButton = styled.button`
    display: flex;
    flex-direction: row;
    border: none;
    background-color: transparent;
`;

const TableSection = styled.div`
    margin-top: 20px;
    @media (max-width: 1024px) {
        .ant-table-pagination-right {
            justify-content: center;
        }
    }
`;

const DetailSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 5px;
`;

const ButtonWrapper = styled.div`
    margin: 20px;
`;

const EditText = styled.span`
    color: #16161b;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: center;
`;

const SendText = styled.span`
    color: #ffffff;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: center;
`;

const EachCol = styled(Col)`
    margin-left: 40px;
    margin-right: 40px;
`;

const PhoneAndCompany = styled(Col)`
    padding-right: 120px;
`;

const AddressAndSince = styled(Col)`
    /* padding-left: 60px; */
`;

const EmailAndRole = styled(Col)`
    padding-right: 120px;
`;

const InfoDetail = styled(Row)``;

const NameSection = styled(Row)``;

const InfoDetailSection = styled(Col)`
    margin-left: 20px;
`;

const AvatarSection = styled(Col)`
    height: 100%;
    align-items: flex-start;
`;

const GeneralSection = styled(Col)`
    flex: 1 1 200px;
    display: flex;
    align-items: center;
    margin-left: 5px;
`;

const EachButton = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
`;

const ButtonSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 5px;
    align-items: center;
`;

const ButtonIcon = styled.a`
    border: 1px solid #cdd1d8;
    border-radius: 2px;
    width: 32px;
    height: 32px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Icon = styled.img`
    width: 16px;
    height: 16px;
`;

export {
    Icon,
    ButtonIcon,
    AddressAndSince,
    EachButton,
    EmailAndRole,
    PhoneAndCompany,
    ButtonSection,
    NameSection,
    GeneralSection,
    AvatarSection,
    InfoDetail,
    InfoDetailSection,
    Container,
    CustomerContainer,
    SearchAndSort,
    CustomerDetailContainer,
    ContainerWrapper,
    BottomContainer,
    WrapperText,
    Label,
    UploadText,
    WrapperComponent,
    MenuTitle,
    Title,
    Component,
    UploadButton,
    ButtonText,
    TitleDescription,
    Name,
    SearchSection,
    MembershipText,
    ItemText,
    ColumnHeader,
    TableSection,
    DetailSection,
    NameSectionButton
};
