import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { Col, Row, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { Header, Popup, ButtonCustom } from 'components';
import { HeaderInfo } from 'components/Cards';
import '../customer.css';
import {
    CustomerDetailContainer,
    ContainerWrapper,
    BottomContainer,
    Label
} from '../styles';
import _ from 'lodash';
import { CUSTOMER_LIST } from 'routes/main';
import { EMAIL_EXISTS } from 'redux/Constants';
import { SearchCountries } from 'components/Search';
import { useSelector } from 'react-redux';

const template = (props) => {
    const { expandedSidebar } = useSelector(
        (state) => state.settingReducer || false
    );
    const {
        onSearchCountries,
        onSubmit,
        customer,
        isEdit,
        visibleModal,
        error,
        successMsg
    } = props;

    const { t, i18n } = useTranslation();

    const [loading, setLoading] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [postCode, setPostCode] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState({});
    const [state, setState] = useState('');

    useEffect(() => {
        if (isEdit) {
            setFirstName(customer.firstname);
            setLastName(customer.lastname);
            setEmail(customer.email);
            setPhone(customer.phone);
            setAddressLine1(customer.address?.address_line1);
            setAddressLine2(customer.address?.address_line2);
            setPostCode(customer.address?.postalcode);
            setCity(customer.address?.city);
            setCountry(customer.address?.country);
            setState(customer.address?.state);
        }
    }, [props.customer, props.isEdit]);

    const validateData = () => {
        if (
            !firstName ||
            !lastName ||
            !email ||
            !phone ||
            !addressLine1 ||
            !postCode ||
            !city
        ) {
            return false;
        }

        if (_.isEmpty(country) || (!country.id && !country.short_code)) {
            return false;
        }

        return true;
    };

    const onSelectCountry = (value) => {
        setCountry(value);
    };

    const onSubmitForm = () => {
        let valid = validateData();
        if (!valid) return;

        const data = {
            address: {
                address_line1: addressLine1,
                postalcode: postCode,
                city: city,
                country: country,
                state: state
            },
            meta: {},
            type: 'CLIENT',
            firstname: firstName,
            lastname: lastName,
            email: email,
            phone: phone,
            membership_id: null
        };

        onSubmit(data);
    };

    return (
        <>
            <CustomerDetailContainer>
                <Header
                    title={i18n.t('CUSTOMER')}
                    description={
                        !isEdit
                            ? i18n.t('ADD_NEW_CUSTOMER')
                            : i18n.t('EDIT_CUSTOMER')
                    }
                />
            </CustomerDetailContainer>
            <ContainerWrapper expandedSidebar={expandedSidebar}>
                <HeaderInfo
                    title={i18n.t('CUSTOMER_INFORMATION').toUpperCase()}
                />
                <Row className="add-customer-item">
                    <Col lg="3" sm="3" xs="12">
                        <Label>{i18n.t('FIRST_NAME').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setFirstName(val.target.value)}
                            className="base left"
                            placeholder={i18n.t('FIRST_NAME')}
                            value={firstName}
                            required
                        />
                    </Col>
                    <Col lg="3" sm="3" xs="12">
                        <Label>{i18n.t('LAST_NAME').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setLastName(val.target.value)}
                            className="base"
                            placeholder={i18n.t('LAST_NAME')}
                            value={lastName}
                        />
                    </Col>
                </Row>
                <Row className="add-customer-item">
                    <Col lg="3" sm="3" xs="12">
                        <Row>
                            <Label>
                                {i18n.t('EMAIL_ADDRESS').toUpperCase()}
                            </Label>
                        </Row>
                        <Row>
                            <Input
                                onChange={(val) => setEmail(val.target.value)}
                                className="base left"
                                placeholder={i18n.t('EMAIL_ADDRESS')}
                                value={email}
                            />
                        </Row>
                        <Row>
                            {error.includes(EMAIL_EXISTS) ? (
                                <ErrorSection>{t('EMAIL_EXISTS')}</ErrorSection>
                            ) : null}
                        </Row>
                    </Col>
                    <Col lg="3" sm="3" xs="12">
                        <Label>{i18n.t('PHONE_NUMBER').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setPhone(val.target.value)}
                            className="base"
                            placeholder={i18n.t('PHONE_NUMBER')}
                            value={phone}
                        />
                    </Col>
                </Row>
                <Row className="add-customer-item">
                    <Col lg="3" sm="6" xs="12">
                        <Label>{i18n.t('ADDRESS_LINE_1').toUpperCase()}</Label>
                        <Input
                            onChange={(val) =>
                                setAddressLine1(val.target.value)
                            }
                            className="address"
                            placeholder={i18n.t('ADDRESS_LINE_1')}
                            value={addressLine1}
                        />
                    </Col>
                </Row>
                <Row className="add-customer-item">
                    <Col lg="3" sm="3" xs="12">
                        <Label>{i18n.t('ADDRESS_LINE_2').toUpperCase()}</Label>
                        <Input
                            onChange={(val) =>
                                setAddressLine2(val.target.value)
                            }
                            className="address "
                            placeholder={i18n.t('ADDRESS_LINE_2')}
                            value={addressLine2}
                        />
                    </Col>
                </Row>
                <Row className="add-customer-item">
                    <Col lg="3" sm="3" xs="12">
                        <Label>{i18n.t('POST_CODE').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setPostCode(val.target.value)}
                            className="base left"
                            placeholder={i18n.t('POST_CODE')}
                            value={postCode}
                        />
                    </Col>
                    <Col lg="3" sm="3" xs="12">
                        <Label>{i18n.t('CITY').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setCity(val.target.value)}
                            className="base"
                            placeholder={i18n.t('CITY')}
                            value={city}
                        />
                    </Col>
                </Row>
                <Row className="add-customer-item">
                    <Col lg="3" sm="3" xs="12">
                        <Label>{i18n.t('STATE').toUpperCase()}</Label>
                        <Input
                            onChange={(val) => setState(val.target.value)}
                            className="base left"
                            placeholder={i18n.t('STATE')}
                            value={state}
                        />
                    </Col>
                    <Col lg="3" sm="3" xs="12">
                        <Label>{i18n.t('COUNTRY').toUpperCase()}</Label>
                        <SearchCountries
                            inputStyle={{
                                width: '28vw',
                                maxWidth: '370px',
                                minWidth: '270px',
                                height: '50px'
                            }}
                            country={country}
                            onSelectCountry={onSelectCountry}
                        />
                    </Col>
                </Row>
            </ContainerWrapper>
            <BottomContainer>
                <ButtonCustom
                    btnType="primary"
                    size="large"
                    width="255px"
                    onClick={() => onSubmitForm()}
                    disabled={!validateData()}
                    title={
                        !isEdit
                            ? i18n.t('SUBMIT_CUSTOMER_INFO').toUpperCase()
                            : i18n.t('SAVE_CHANGES').toUpperCase()
                    }
                />
            </BottomContainer>
            <Popup
                cancelModal={() => {
                    props.cancelModal();
                }}
                onOk={() => {
                    props.navigate(CUSTOMER_LIST);
                }}
                visibleModal={visibleModal}
                successText={successMsg}
                buttonTitle={t('VIEW_CUSTOMER_LIST').toUpperCase()}
            />
        </>
    );
};

const ErrorSection = styled.span`
    color: #e2253d;
    font-family: AvenirNext-Medium;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    margin-top: 7px;
`;

const SuccessIcon = styled.img`
    width: 64px;
    height: 64px;
    margin-bottom: 25px;
`;

const SuccessText = styled.span`
    color: #121216;
    font-family: AvenirNext-DemiBold;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 32px;
    margin-bottom: 40px;
`;

const DropdownWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const DropdownText = styled.span``;

export { template };
