import React, { useEffect } from 'react';
import { template as tmpl } from './App.tmpl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    signOut,
    switchCompany,
    getCurrentUserInformation,
    updateUserDeviceToken
} from '../../redux/actions/appActions';
import { fetchCompanies } from '../../redux/actions/companyAction';
import { LOGIN } from '../../routes/main';
import { requestFirebaseNotificationPermission } from '../../firebase';
import auth from '../../services/auth';
import { getBrowser } from 'utils';

const AppContainerLogic = (props) => {
    const {
        image,
        firstname,
        lastname,
        activeCompany,
        companies = [],
        userCompanies = [],
        unread = 0
    } = props;
    const initProps = {
        firstname: firstname,
        lastname: lastname,
        image: image,
        activeCompanyName: activeCompany.name ? activeCompany.name : '',
        companies: companies,
        userCompanies: userCompanies,
        unread: unread
    };

    const requestNotification = () => {
        const fcmToken = auth.getFCMToken();
        const browser = getBrowser();

        if (!fcmToken && browser) {
            const user = auth.getUserInfo();
            const isChrome =
                !!window.chrome &&
                (!!window.chrome.webstore || !!window.chrome.runtime);
            console.log('isChrome ', isChrome);

            requestFirebaseNotificationPermission()
                .then(async(firebaseToken) => {
                    if (!firebaseToken) {
                        console.log('Browser does not support cloud messaging');
                        return;
                    }
                    if (user) {
                        let params = {
                            token: firebaseToken,
                            os: 'sw',
                            browser: browser.toLocaleLowerCase(),
                            device_id: '',
                            enabled: true
                        };

                        let rs = await props.updateUserDeviceToken(user?.id, params);
                        if (rs.success) {
                            auth.setFCMToken(params);
                        }
                    }
                })
                .catch((err) => {});
        }
    };

    useEffect(() => {
        props.getCurrentUserInformation();
        props.fetchCompanies();
        requestNotification();
    }, []);

    const logout = async () => {
        await props.signOut();
        navigate(LOGIN);
    };

    const navigate = (route, id, state) => {
        props.history.push({
            pathname: route,
            search: id,
            state: state
        });
    };

    const switchCompany = async (company) => {
        return await props.switchCompany({
            company_id: company.id
        });
    };

    return (
        tmpl &&
        tmpl({
            ...initProps,
            logout: logout,
            switchCompany: switchCompany,
            navigate: navigate
        })
    );
};

class AppContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const initProps = {
            ...this.props
        };
        return <AppContainerLogic {...initProps} />;
    }
}

const mapStateToProps = (state) => {
    const { userReducer, companyReducer } = state;
    const { firstname, lastname, image } = userReducer;
    const { activeCompany, companies, userCompanies, notifications = {} } = companyReducer;
    const { unread = 0 } = notifications;
    return {
        firstname: firstname,
        lastname: lastname,
        companies: companies,
        userCompanies: userCompanies,
        activeCompany: activeCompany,
        image: image,
        unread: unread
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
        switchCompany: (data) => dispatch(switchCompany(data)),
        getCurrentUserInformation: () => dispatch(getCurrentUserInformation()),
        fetchCompanies: () => dispatch(fetchCompanies()),
        updateUserDeviceToken: (id, data) =>
            dispatch(updateUserDeviceToken(id, data))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AppContainer)
);
